<div class="buttons">
	<ng-content></ng-content>
</div>
<form [formGroup]="form">
	<div class="column">
		<h2 class="title">{{'LIGHTS' | translate}}</h2>
		<div perfectScrollbar>
			<ng-container *ngTemplateOutlet="list;context:{type: 'light'}"></ng-container>
		</div>
	</div>
	<div class="column">
		<h2 class="title">{{'ALARM' | translate}}</h2>
		<div perfectScrollbar>
			<ng-container *ngTemplateOutlet="list;context:{type: 'alarm'}"></ng-container>
		</div>
	</div>
	<div class="column">
		<h2 class="title">{{'AC' | translate}}</h2>
		<div perfectScrollbar>
			<ng-container *ngTemplateOutlet="list;context:{type: 'ac'}"></ng-container>
		</div>
	</div>
</form>

<ng-template #list let-type="type">
	<ng-container *ngFor="let device of form.get('devices')['controls']">
		<div class="widget" *ngIf="device.get('name').value.toLowerCase().startsWith(type)">
			<div class="widget-body" (click)="updateDevice(device, true)">
				<div class="circle" [class.active]="device.get('armed') && device.get('status').value"
					 [class.enabled]="device.get('armed') ? device.get('armed').value : device.get('status').value">
					<img [src]="getDeviceImage(device)">
					<label *ngIf="device.get('temperature')" class="temperature" (click)="$event.stopPropagation()">
						<input type="text" [formControl]="device.get('temperature')" (keydown)="validateKeyDown($event)"
							   (blur)="updateDevice(device)"> °F</label>
					<span class="widget-title">{{getRoomName(device.get('name').value)}} {{device.get('name').value}}</span>
				</div>
			</div>
		</div>
	</ng-container>
</ng-template>
