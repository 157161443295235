import {Component, OnInit} from '@angular/core';
import {FirebaseService} from '../../services/firebase.service';
import {MatDialog} from '@angular/material/dialog';
import {SensorsService} from '../../services/system/sensors.service';
import {Router} from '@angular/router';
import {BusEventService} from '../../services/bus-event.service';
import {StorageService} from '../../services/storage.service';
import {ModalService} from '../../services/modal.service';

/**
 * A block with user's data on the right top in BaseViewComponent or SensorListComponent.
 * Showed only in online mode (environment.offline=false).
 */
@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

	isPopupOpened = false;

	constructor(public firebaseService: FirebaseService,
				private dialog: MatDialog,
				private sensorsService: SensorsService,
				private router: Router,
				private busEventService: BusEventService,
				private storageService: StorageService,
				private modalService: ModalService) {
	}

	ngOnInit() {
		document.addEventListener('click', () => {
			this.isPopupOpened = false;
		});
	}

	open() {
		this.modalService.showSettings({
			isUserSettings: true
		}).afterClosed().toPromise().then(spaceChanged => {
			if (spaceChanged) {
				this.sensorsService.removeSensorSocketsAll(false).then(() => {
					this.storageService.removeItem('spaceInfo');
					this.storageService.removeItem('socketPool');
					this.busEventService.reloadPool.emit();
					this.router.navigate(['/connections']);
				});
			}
		});
	}

	signOut() {
		this.storageService.removeItem('lastLoggedInUser');
		this.storageService.clearLocalStorage();
		this.firebaseService.signOut();
		this.router.navigate(['/login']);
	}
}
