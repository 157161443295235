// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {environmentBase} from './envinronment-base';

export const environment = {
	...environmentBase, // should be the first line
	production: true,
	showWelcomeFlow: false,

	useAzure: false,
	isSmartRetailEnabled: false,
	isFloorPlanPageEnabled: false,
	get isSmartBuildingsModuleEnabled() {
		return this.isSmartRetailEnabled;
	},
	isFlowProcessorModuleEnabled: true,
	disableRemoteConnToRESTServer: false,
	defaultPage: 'sensor',
};
