<div class="container">
	<div class="button-container button-container-left">
		<!--
			This button was not a request from users.
			It is hidden until a full decision is made as to what to do with it.
		-->
		<!--<app-toolbar-button
			(onClick)="clearAll()"
			[label]="'CLEAR_CACHE' | translate"
			dataSelector="toolbar_clear-cache_button"
			icon="settings_backup_restore"
		></app-toolbar-button>-->
		<app-toolbar-button
			(onClick)="onClickShowSettings('add', 'location')"
			[label]="'ADD_LOCATION' | translate"
			dataSelector="toolbar_add-location_button"
			id="addBuilding"
			icon="business"
			[cssClass]="busEventService.showSubToolbar.value.open && busEventService.showSubToolbar.value.mode === 'add' && busEventService.showSubToolbar.value.type === 'location' ? 'button-active' : ''"
		></app-toolbar-button>
		<app-toolbar-button
			(onClick)="onClickShowSettings('edit', 'location')"
			[label]="'EDIT_LOCATION' | translate"
			[disabled]="toolbarService.floorPlanFiltersForm.controls.building_id.invalid"
			dataSelector="toolbar_edit-location_button"
			icon="edit"
			[cssClass]="busEventService.showSubToolbar.value.open && busEventService.showSubToolbar.value.mode === 'edit' && busEventService.showSubToolbar.value.type === 'location' ? 'button-active' : ''"
		></app-toolbar-button>
		<app-toolbar-button
			(onClick)="onClickShowSettings('add', 'floor')"
			[label]="'ADD_FLOOR' | translate"
			[disabled]="floors.length >= 1"
			dataSelector="toolbar_add-floor_button"
			icon="aspect_ratio"
			[cssClass]="busEventService.showSubToolbar.value.open && busEventService.showSubToolbar.value.mode === 'add' && busEventService.showSubToolbar.value.type === 'floor' ? 'button-active' : ''"
		></app-toolbar-button>
		<app-toolbar-button
			(onClick)="onClickShowSettings('edit', 'floor')"
			[label]="'EDIT_FLOOR' | translate"
			[disabled]="toolbarService.floorPlanFiltersForm.controls.floor_id.invalid"
			dataSelector="toolbar_edit-floor_button"
			icon="edit"
			[cssClass]="busEventService.showSubToolbar.value.open && busEventService.showSubToolbar.value.mode === 'edit' && busEventService.showSubToolbar.value.type === 'floor' ? 'button-active' : ''"
		></app-toolbar-button>

	</div>

	<ng-container *ngIf="environment.useAzure">
		<div class="right-container">
			<div class="filters-form">
				<form [formGroup]="toolbarService.floorPlanFiltersForm">
					<mat-form-field [attr.data-value]="toolbarService.floorPlanFiltersForm.get('building_id').value" data-selector="toolbar_location-name_select"  matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor" class="mr-2">
						<mat-label>{{'ALL_LOCATIONS' | translate:{count: locations.length} }}</mat-label>
						<mat-select formControlName="building_id"
									[disableControl]="!isConnectionConnected(true) || (busEventService.showSubToolbar.value.mode === 'add' && busEventService.showSubToolbar.value.open)"
									(valueChange)="onBuildingChanged($event)" [placeholder]="'LOCATION' | translate">
							<mat-option *ngFor="let building of locations" [value]="building.id">
								{{building.building_name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field data-selector="toolbar_floor-name_select" [attr.data-value]="toolbarService.floorPlanFiltersForm.get('floor_id').value" matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor">
						<mat-label>{{'ALL_FLOORS' | translate:{count: floors.length} }}</mat-label>
						<mat-select formControlName="floor_id" [placeholder]="'FLOOR' | translate"
									[disableControl]="!isConnectionConnected(true) || busEventService.showSubToolbar.value.mode === 'add' && busEventService.showSubToolbar.value.open">
							<mat-option *ngFor="let floor of floors" [value]="floor.id">
								{{floor.floor_name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</form>
			</div>

			<div class="button-container-right">
				<app-toolbar-button
					cssClass="button-active border-accent"
					class="mr-10"
					(onClick)="stopLiveData()"
					[disabled]="isConnectionConnected(true)"
					[label]="'Stop' | translate"
					dataSelector="toolbar-floor-plan-stop-button"
					icon="stop"
				></app-toolbar-button>

				<app-toolbar-button
					cssClass="button-active border-accent"
					(onClick)="startLiveData()"
					[disabled]="isConnectionConnected()"
					[label]="'Start' | translate"
					dataSelector="toolbar-floor-plan-start-button"
					icon="play_arrow"
				></app-toolbar-button>
			</div>
		</div>
	</ng-container>
</div>

