import * as THREE from 'three';
import {PointsMaterial, Scene} from 'three';
import {getMaxOccOfEl, scalePoints} from '../utils/IsoUtils';
import {
	BASE_COLORS_AS_OBJ,
	HEATMAP_COLORS,
	HEATMAP_RESOLUTION,
	makeGetColor,
	POINT_CLOUD_LAYER_FLOOR_COLOR,
	rgbToThreeHexColor,
	TARGET_CENTER_COLOR,
} from '../utils/ImageUtils';
import {getSensorTranslation, getThreeJsScale, mapEVKaxesToThreeJS, matrix4} from '../utils/utils';
import {ic, updateTargetColorAllocationDataStructures} from '../utils/ColorTablesUtils';

declare const require: any;

const InstancedMesh = require('@bitowl/three-instanced-mesh')(THREE);
const OrbitControls = require('three-orbit-controls')(THREE);
require('src/assets/js/InfiniteGridHelper')(THREE);

const MAX_NUM_OF_PCL_VER_TO_DISPLAY = 500000;
const MAX_NUM_OF_TARGET_CENTER_VER_TO_DISPLAY = 1000;
const BACKGROUND_COLOR = 0x262d47;
const PLANE_COLOR = 0xffffff;
const DEVICE_COLOR_ON_CONFIG = 0x3bb1cd;
const DEVICE_COLOR_ON_IMAGING = 0x8a8a8a;
const INF_PLANE_DIVISIONS = 500;
const INF_PLANE_SIZE = INF_PLANE_DIVISIONS * 10;
const SHADOW_COLOR = {r: 140, g: 140, b: 140};
const TARGET_CENTER_RADIUS = 10;
const DEFAULT_TRAIL_LENGTH = 10;
export const DEFAULT_POINT_SIZE = 6;
export const POINT_LOCATION = {
	DEFAULT: 1,
	RIGHT_PLANE_SHADOW: 2,
	LEFT_PLANE_SHADOW: 3,
	SENSOR_PLANE_SHADOW: 4,
	FRONT_PLANE_SHADOW: 5,
	FLOOR_SHADOW: 6,
};

/**
 * [x, y, z, targetID, UID]
 */
export type PointCloudPoint = [number, number, number, number, number?];

/**
 * Base class for rendering scenes using Three.js lib.
 */
export class PointCloudStageBase {

	container;
	scale;
	oldArena;
	arena;
	origArena;
	arenaPlanes;
	oldStatus;
	status;
	shouldUpdateArenaPlanesAccordingToPOV;
	arenaPlanesStatus;
	multipleArenasPlanesStatus;
	data;
	targetCenterData;
	isDisplayCenterTarget;
	isDisplayPlaneShadow;
	isDisplayFloorShadow;
	animFrameReqId;
	gridHelper;
	targetCenterColor;
	sensorColorOnConfig;
	sensorColorOnImaging;
	getColor;
	desiredWebFps;
	fpsInterval;
	then;
	scene: Scene | null;
	camera;
	renderer;
	mainGroup;
	sensor;
	AmbientLight;
	controls;
	pointCloudGeo: THREE.BufferGeometry | null;
	pointCloudMat: PointsMaterial | null;
	pointCloudMesh;
	targetCentersInstancedMesh;
	trails;
	trailLength;
	isRaw;
	isCeilingEnabled = false;
	cameraType = 'Perspective';
	showFloor = true;
	isMultipleSensors = false;

	WALL_WIDTH = 1;
	// Uses to avoid wall intersection
	PLANE_MARGIN = this.WALL_WIDTH / 2;
	FLOOR_HEIGHT = 0;
	FLOOR_Y_POSITION = -this.FLOOR_HEIGHT / 2 - this.PLANE_MARGIN;
	FLOOR_COLOR = rgbToThreeHexColor(POINT_CLOUD_LAYER_FLOOR_COLOR.r, POINT_CLOUD_LAYER_FLOOR_COLOR.g, POINT_CLOUD_LAYER_FLOOR_COLOR.b);
	SCALING_FACTOR = 100;
	PLANE_GEOMETRY = new THREE.BoxGeometry(1, 1, 1);

	protected sensors: Array<any> | null = [];
	protected arenas: Array<any> = [];
	isCameraTargetNeedUpdate = true;
	protected isSensorVisible = true;
	protected parameters;
	protected connectedSensorsParameters = [];
	protected tO;
	protected tI;

	constructor(div, arena, status, parameters, shouldUpdateArenaPlanesAccordingToPOV = true, cameraType = 'Perspective', protected pointSize = DEFAULT_POINT_SIZE) {
		this.parameters = parameters;
		this.container = div;
		this.scale = null;
		this.oldArena = null;
		this.arena = this.oldArena;
		this.arenaPlanes = null;
		this.oldStatus = null;
		this.status = this.oldStatus;
		this.shouldUpdateArenaPlanesAccordingToPOV = shouldUpdateArenaPlanesAccordingToPOV;
		this.arenaPlanesStatus = null;
		this.multipleArenasPlanesStatus = [];
		this.data = [];
		this.targetCenterData = [];
		this.isDisplayCenterTarget = false;
		this.isDisplayPlaneShadow = false;
		this.isDisplayFloorShadow = false;
		this.animFrameReqId = null;
		this.gridHelper = null;
		this.targetCenterColor = new THREE.Color(TARGET_CENTER_COLOR);
		this.sensorColorOnConfig = new THREE.Color(DEVICE_COLOR_ON_CONFIG);
		this.sensorColorOnImaging = new THREE.Color(DEVICE_COLOR_ON_IMAGING);
		this.getColor = makeGetColor(
			this.targetCenterColor,
			value => {
				let index = value === 1 ? HEATMAP_COLORS.length - 1 : Math.floor(value * HEATMAP_RESOLUTION);
				if (index >= HEATMAP_COLORS.length) {
					index = HEATMAP_COLORS.length - 1;
				}
				return HEATMAP_COLORS[index];
			},
			ic
		);
		this.cameraType = cameraType;
		this.init(arena, status);
		this.desiredWebFps = 20;
		this.fpsInterval = 1000 / this.desiredWebFps;
		this.then = Date.now();
	}

	updateSceneOnConfiguring() {
	}

	init(arena, status) {
		let mainArena = arena;
		this.origArena = arena;
		this.isMultipleSensors = arena[0] instanceof Array;

		if (this.isMultipleSensors) {
			mainArena = arena[0];
		}
		this.arena = mainArena;
		try {
			this.scene = new THREE.Scene();
			this.scene.background = new THREE.Color(BACKGROUND_COLOR);
		} catch (e) {
			console.error(e);
		}
		if (this.cameraType === 'Orthographic') {
			this.camera = new THREE.OrthographicCamera(
				-this.container.clientWidth / 1.9,
				this.container.clientWidth / 1.9,
				this.container.clientHeight / 1.9,
				-this.container.clientHeight / 1.9,
				-5000,
				5000
			);
		} else {
			this.camera = new THREE.PerspectiveCamera(
				75,
				this.container.clientWidth / this.container.clientHeight,
				0.2,
				20000
			);
		}
		try {
			this.renderer = new THREE.WebGLRenderer({antialias: true});
			this.renderer.setPixelRatio(window.devicePixelRatio);
			this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
			this.container.appendChild(this.renderer.domElement);
		} catch (e) {
			console.error(e);
		}
		this.mainGroup = new THREE.Group();
		if (this.scene) {
			this.scene.add(this.mainGroup);
		}

		// Build grid
		this.gridHelper = new (THREE as any).InfiniteGridHelper(100, 100);
		this.gridHelper.position.y = this.FLOOR_Y_POSITION - this.PLANE_MARGIN - this.FLOOR_HEIGHT - 20;
		this.mainGroup.add(this.gridHelper);

		// Build arena planes
		let floorMaterial = new THREE.MeshBasicMaterial({
			color: this.getFloorColor(false)
		});
		let planeMaterial = new THREE.MeshBasicMaterial({
			color: PLANE_COLOR,
			transparent: true,
			opacity: 0.25,
			depthWrite: false,
			depthTest: false,
			side: THREE.FrontSide
		});

		let planeGeometry = new THREE.BoxGeometry(1, 1, 1);
		let floorGeometry = new THREE.BoxGeometry(1, this.FLOOR_HEIGHT, 1);

		let floor = new THREE.Mesh(floorGeometry, floorMaterial);
		// Only the floor is added here to the main group. The rest of the planes will be added/removed on updateArenaPlanesAccordingToPOV
		if (this.showFloor) {
			this.mainGroup.add(floor);
		}

		let frontPlane = new THREE.Mesh(planeGeometry, planeMaterial);
		let sensorPlane = new THREE.Mesh(planeGeometry, planeMaterial);
		let rightPlane = new THREE.Mesh(planeGeometry, planeMaterial);
		let leftPlane = new THREE.Mesh(planeGeometry, planeMaterial);
		let bottomPlane = new THREE.Mesh(planeGeometry, planeMaterial);
		let topPlane = new THREE.Mesh(planeGeometry, planeMaterial);
		floor.name = 'Floor';
		frontPlane.name = 'Front Plane';
		sensorPlane.name = 'Sensor Plane';
		rightPlane.name = 'Right Plane';
		leftPlane.name = 'Left Plane';
		bottomPlane.name = 'Bottom Plane';
		topPlane.name = 'Top Plane';
		this.arenaPlanes = [floor, frontPlane, sensorPlane, rightPlane, leftPlane, bottomPlane];
		if (this.isCeilingEnabled) {
			this.arenaPlanes.push(topPlane);
		}
		this.arenas.push(this.arenaPlanes);
		this.updateFloor();
		this.arenaPlanesStatus = [false, false, false, false, false, false, false];
		if (this.shouldUpdateArenaPlanesAccordingToPOV) {
			this.mainGroup.add(this.arenaPlanes[5]);
			if (this.isCeilingEnabled) {
				this.mainGroup.add(this.arenaPlanes[6]);
			}
		}

		this.sensor = this.getSensor();
		this.mainGroup.add(this.sensor);

		// Init lights
		this.AmbientLight = new THREE.AmbientLight(0xffffff, 2);
		this.mainGroup.add(this.AmbientLight);

		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

		// Set scale
		this.setScale(mainArena);

		// Init camera and controls
		this.initCamera(arena);
		this.controls = new OrbitControls(this.camera, this.container);
		this.updateControlsPolarAngle();
		this.updateOrbitControlsMaxDistance();
		this.controls.minDistance = this.scale[2] / 2;
		this.controls.update();

		// Update scene
		this.updateScene(arena, this.parameters);

		// First call to animation()
		this.animation();

		// Update status
		this.updateStatus(status);

		// Update arena planes
		if (this.shouldUpdateArenaPlanesAccordingToPOV) {
			this.updateArenaPlanesAccordingToPOV(arena);
		}

		/** --------- Point cloud Mesh -----------*/
		this.pointCloudGeo = new THREE.BufferGeometry();
		this.pointCloudGeo.setAttribute(
			'position',
			new THREE.BufferAttribute(new Float32Array(MAX_NUM_OF_PCL_VER_TO_DISPLAY), 3)
		);
		this.pointCloudGeo.setAttribute(
			'color',
			new THREE.BufferAttribute(new Uint8Array(MAX_NUM_OF_PCL_VER_TO_DISPLAY), 3, true)
		);

		this.pointCloudMat = new THREE.PointsMaterial({
			vertexColors: THREE.VertexColors,
			size: this.pointSize,
			blending: THREE.NoBlending,
		});

		this.pointCloudMesh = new THREE.Points(this.pointCloudGeo, this.pointCloudMat);
		this.pointCloudMesh.name = 'Point Cloud Mesh';
		/** ------------Target Centers instanced mesh ---------- */
		this.targetCentersInstancedMesh = new InstancedMesh(
			new THREE.SphereBufferGeometry(TARGET_CENTER_RADIUS, 32, 32), // Geometry
			new THREE.MeshBasicMaterial({
				// Material
				depthTest: false,
				side: THREE.DoubleSide,
			}),
			MAX_NUM_OF_TARGET_CENTER_VER_TO_DISPLAY, // instance count
			true, // is it dynamic
			true, // does it have color
			true // uniform scale
		);
		this.targetCentersInstancedMesh.name = 'Target Centers Instanced Mesh';
		// Length of BASE_COLORS_AS_OBJ is an indication of how many people we're considering in the arena
		// TODO: Document the purpose of this array
		this.trails = new Array(BASE_COLORS_AS_OBJ.length).fill(0);
		/** ---------------------------------------------------- */
		this.controls.addEventListener('change', () => {
			// Use actual arena, not initial one.
			this.updateArenaPlanesAccordingToPOV(this.arena);
		}, false);
	}

	initCamera(arena) {
		let cameraCenterX,
			cameraCenterZ,
			isMultiSensor = arena[0] instanceof Array;

		if (isMultiSensor) {
			cameraCenterX = (Math.max(...arena.map(a => a[1])) + Math.min(...arena.map(a => a[0]))) / 2 * this.SCALING_FACTOR;
			cameraCenterZ = (Math.max(...arena.map(a => a[5])) + Math.min(...arena.map(a => a[4]))) / 2 * this.SCALING_FACTOR;
		} else {
			cameraCenterX = (arena[1] + arena[0]) / 2 * this.SCALING_FACTOR;
			cameraCenterZ = (arena[5] + arena[4]) / 2 * this.SCALING_FACTOR;
		}
		let z = (this.arena[5] + this.arena[4]) / 2 * this.SCALING_FACTOR;
		if (this.cameraType === 'Perspective') {
			this.camera.position.set(cameraCenterX, this.scale[1] * 3, cameraCenterZ);
		} else {
			this.camera.position.set(this.scale[1] * 0.75, this.scale[1] * 0.75, this.scale[2] + 200);
			this.camera.zoom = 0.85;
		}
		this.camera.lookAt(cameraCenterX, 0, cameraCenterZ);
	}

	isSensorLocatedOnTheFloor = () => this.arena.sensorPlane === '';

	addAxesHelper() {
		const axesHelper = new THREE.AxesHelper(100);
		axesHelper.position.set(0, 0, 0);
		this.mainGroup.add(axesHelper);
	}

	/**
	 * Animate point Cloud - Called in loop
	 */
	animation() {
		const now = Date.now();
		const elapsed = now - this.then;

		if (elapsed > this.fpsInterval) {
			switch (this.status) {
				case 'CONFIGURING':
					if (JSON.stringify(this.arena) !== JSON.stringify(this.oldArena)) {
						this.updateScene(this.arena, this.parameters);
						if (this.updateSceneOnConfiguring) {
							this.updateSceneOnConfiguring();
						}
					}
					break;
				case 'IMAGING':
					/**
					 * Update structures that used to calculate target color
					 * in {@link this.updateTargetCenterPoints} and {@link this.updatePoints}
					 */
					if (this.targetCenterData) {
						this.updateTargetColorAllocationDataStructures(this.targetCenterData);
					}
					if (this.isDisplayCenterTarget) {
						if (!this.mainGroup.getObjectByName('Target Centers Instanced Mesh')) {
							this.mainGroup.add(this.targetCentersInstancedMesh);
						}
						this.updateTargetCenterPoints(this.targetCenterData);
					} else {
						this.mainGroup.remove(this.targetCentersInstancedMesh);
					}
					if (this.data) {
						if (!this.mainGroup.getObjectByName('Point Cloud Mesh')) {
							this.mainGroup.add(this.pointCloudMesh);
						}
						this.updatePoints(this.data);
					}
					break;
				default:
					break;
			}
			if (this.scene && this.renderer) {
				this.renderer.render(this.scene, this.camera);
			}
			this.then = now - (elapsed % this.fpsInterval);
		}
		this.animFrameReqId = requestAnimationFrame(this.animation.bind(this));
	}

	updateOnInit(data, targetCenterData, arena, isDisplayPlaneShadow, isDisplayCenterTarget, isDisplayFloorShadow, isSensorVisible) {
		let mainArena = arena;
		if (arena[0] instanceof Array) {
			mainArena = arena[0];
		}
		this.isSensorVisible = isSensorVisible;
		this.status = 'IMAGING';
		this.arena = mainArena;
		this.origArena = arena;
		this.updateData(data, targetCenterData, isDisplayPlaneShadow, isDisplayCenterTarget, isDisplayFloorShadow);

		this.updateSensorVisible(isSensorVisible);
	}

	/**
	 * This function is the main function of this class.
	 * It updates the point cloud in the arena according to data given from Matlab.
	 * We're giving the user the control over showing shadows and targets` centers
	 */
	update(data, targetCenterData, arena, status, isDisplayPlaneShadow, isDisplayCenterTarget, isDisplayFloorShadow, isSensorVisible) {
		let mainArena = arena;
		if (arena[0] instanceof Array) {
			mainArena = arena[0];
		}
		this.oldStatus = this.status;
		this.status = status;
		this.oldArena = this.arena;
		this.arena = mainArena;
		this.origArena = arena;
		this.isSensorVisible = isSensorVisible;
		if (status === 'IMAGING' && data) {
			this.updateData(data, targetCenterData, isDisplayPlaneShadow, isDisplayCenterTarget, isDisplayFloorShadow);
		}
		// Update on changing status - For instance from "CONFIGURING" to "IMAGING"
		if (this.oldStatus !== this.status) {
			this.updateStatus(this.status);
			this.trailLength = null;
		}

		this.updateSensorVisible(isSensorVisible);
	}

	updateData(data, targetCenterData, isDisplayPlaneShadow, isDisplayCenterTarget, isDisplayFloorShadow) {
		// In case we got one point which is stripped from the wrapper array
		this.data = data.length && !data[0].length ? [data] : data;

		// We rely on this.targetCenterData as our indicator whether we're in tracker or raw mode
		// See animation function for reference
		this.targetCenterData = targetCenterData?.length && !targetCenterData[0].length ? [targetCenterData] : targetCenterData;
		this.isRaw = !(targetCenterData && targetCenterData.length);
		this.isDisplayCenterTarget = isDisplayCenterTarget;

		if (!targetCenterData) {
			scalePoints(this.data);
		}

		this.isDisplayPlaneShadow = isDisplayPlaneShadow;
		this.isDisplayFloorShadow = isDisplayFloorShadow;
	}

	/**
	 * Updates Point Cloud points and their shadows
	 */
	updatePoints(data) {
		let positions: Array<any> = [];
		let colors: Array<any> = [];
		let numOfVerticesToDisplay = data.length;
		for (let i = 0; i < data.length; ++i) {
			let point = data[i];
			let color = this.getPointColor(point);
			let pointPosition = this.getPointPosition(point);
			positions.push(pointPosition.x, pointPosition.y, pointPosition.z);
			colors.push(color.r, color.g, color.b);
			// Handle plane shadows
			if (this.isDisplayPlaneShadow) {
				let xRightPlane = this.scale[0] / 4;
				let xLeftPlane = -this.scale[0] / 4;
				let zSensorPlane = -this.scale[2] / 2;
				let zFrontPlane = this.scale[2] / 2;

				if (this.camera.position.z > zSensorPlane) {
					// Sensor plane is visible
					this.updateShadowPoint(this.scale, point, POINT_LOCATION.SENSOR_PLANE_SHADOW, positions, colors);
					numOfVerticesToDisplay++;
				} else if (this.camera.position.z < zFrontPlane) {
					this.updateShadowPoint(this.scale, point, POINT_LOCATION.FRONT_PLANE_SHADOW, positions, colors);
					numOfVerticesToDisplay++;
				}

				if (Math.min(xRightPlane, xLeftPlane) > this.camera.position.x) {
					// Right plane is no longer visible - Display ONLY left plane shadow
					this.updateShadowPoint(this.scale, point, POINT_LOCATION.LEFT_PLANE_SHADOW, positions, colors);
					numOfVerticesToDisplay++;
				} else if (Math.max(xRightPlane, xLeftPlane) < this.camera.position.x) {
					// Left plane is no longer visible - Display ONLY right plane shadow
					this.updateShadowPoint(this.scale, point, POINT_LOCATION.RIGHT_PLANE_SHADOW, positions, colors);
					numOfVerticesToDisplay++;
				} else {
					this.updateShadowPoint(this.scale, point, POINT_LOCATION.RIGHT_PLANE_SHADOW, positions, colors);
					numOfVerticesToDisplay++;
				}
			}
			// Handle floor shadows
			if (this.isDisplayFloorShadow) {
				this.updateShadowPoint(this.scale, point, POINT_LOCATION.FLOOR_SHADOW, positions, colors);
				numOfVerticesToDisplay++;
			}
		}

		(<THREE.BufferAttribute>this.pointCloudGeo!.attributes.position).set(positions);
		(<THREE.BufferAttribute>this.pointCloudGeo!.attributes.position).needsUpdate = true;
		(<THREE.BufferAttribute>this.pointCloudGeo!.attributes.color).set(colors);
		(<THREE.BufferAttribute>this.pointCloudGeo!.attributes.color).needsUpdate = true;
		this.pointCloudGeo!.setDrawRange(0, numOfVerticesToDisplay);
	}

	updateShadowPoint(scale, point, pointLocation, positions, colors) {
		let pointShadowPos = this.getPointPosition(point, pointLocation);
		positions.push(pointShadowPos.x, pointShadowPos.y, pointShadowPos.z);
		colors.push(SHADOW_COLOR.r, SHADOW_COLOR.g, SHADOW_COLOR.b);
	}

	/**
	 * Updates target center points
	 */
	updateTargetCenterPoints(targetCenterData: any[] = []) {// TODO check why targetCenterData sometimes is undefined
		if (!this.trailLength && targetCenterData.length > 0) {
			this.trailLength = getMaxOccOfEl(targetCenterData.map(point => point[3]));
		}
		let v3 = new THREE.Vector3();

		// Clear all buffers (remove last frame target centers)
		this.targetCentersInstancedMesh.geometry.attributes.instancePosition.array.set(
			new Float32Array(MAX_NUM_OF_TARGET_CENTER_VER_TO_DISPLAY * 3)
		);
		this.targetCentersInstancedMesh.geometry.attributes.instanceColor.array.set(
			new Uint8Array(MAX_NUM_OF_TARGET_CENTER_VER_TO_DISPLAY * 3)
		);
		this.targetCentersInstancedMesh.geometry.attributes.instanceScale.array.set(
			new Float32Array(MAX_NUM_OF_TARGET_CENTER_VER_TO_DISPLAY * 3)
		);
		this.targetCentersInstancedMesh.needsUpdate();

		for (let i = 0; i < targetCenterData.length; i++) {
			let point = targetCenterData[i];
			if (!point.includes('NaN')) {
				// TODO: Check why there're NaN coming from Matlab
				let pointPosition = this.getPointPosition(point);
				this.targetCentersInstancedMesh.setPositionAt(
					i,
					v3.set(pointPosition.x, pointPosition.y, pointPosition.z)
				);

				let color;
				if (this.isMultipleSensors) {
					let rgbObj = this.getPointColor(point);
					color = `rgb(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b})`;
				} else {
					color = TARGET_CENTER_COLOR;
				}
				this.targetCentersInstancedMesh.setColorAt(i, new THREE.Color(color));
				// Trail effect - Different scaling scale
				let trailIndex = point[3] - 1;
				let trailMeshScale = (this.trails[trailIndex] + 1) / (this.trailLength || DEFAULT_TRAIL_LENGTH);
				this.targetCentersInstancedMesh.setScaleAt(i, v3.set(trailMeshScale, trailMeshScale, trailMeshScale));
				this.targetCentersInstancedMesh.needsUpdate();
				this.trails[trailIndex] += 1;
			}
		}
		// TODO: Document this array
		this.trails.fill(0);
	}

	updateTargetColorAllocationDataStructures(targetCenterData: any[] = []) {
		let getTargetID = target => this.isMultipleSensors && typeof target[4] === 'number' ? target[4] : target[3];
		updateTargetColorAllocationDataStructures(targetCenterData, getTargetID);
	}

	updateStatus(status) {
		let color;
		if (status === 'CONFIGURING') {
			color = this.sensorColorOnConfig;
		} else if (status === 'IMAGING') {
			if (this.oldStatus === 'CALIBRATING') {
				// Clean up data only when starting imaging
				this.mainGroup.remove(this.pointCloudMesh);
				this.mainGroup.remove(this.targetCentersInstancedMesh);
				this.data = [];
				this.targetCenterData = [];
				this.cleanupGeo(this.pointCloudGeo);
				this.cleanupTargetCenters(this.targetCentersInstancedMesh);
			}
			color = this.sensorColorOnImaging;
		}
		this.updateSensorColor(color);
		this.oldStatus = this.status;
		this.status = status;
	}

	cleanupGeo(geo) {
		geo.attributes.position.array.set([]);
		geo.attributes.position.needsUpdate = true;
		geo.attributes.color.array.set([]);
		geo.attributes.color.needsUpdate = true;
		geo.setDrawRange(0, 0);
	}

	cleanupTargetCenters(targetCentersInstancedMesh) {
		targetCentersInstancedMesh.geometry.attributes.instancePosition.array.set(
			new Float32Array(MAX_NUM_OF_TARGET_CENTER_VER_TO_DISPLAY * 3)
		);
		targetCentersInstancedMesh.geometry.attributes.instanceColor.array.set(
			new Uint8Array(MAX_NUM_OF_TARGET_CENTER_VER_TO_DISPLAY * 3)
		);
		targetCentersInstancedMesh.geometry.attributes.instanceScale.array.set(
			new Float32Array(MAX_NUM_OF_TARGET_CENTER_VER_TO_DISPLAY * 3)
		);
		targetCentersInstancedMesh.needsUpdate();
	}

	/**
	 * Whenever the controls are used and a plane is blocking the view, the plane is removed
	 * It is restored once it's no longer blocking the view
	 */
	updateArenaPlanesAccordingToPOV(arena) {
		if (this.shouldUpdateArenaPlanesAccordingToPOV) {
			let cb = (a, arenaPlanesStatus, arenaPlanes) => {
				let axes = ['y', 'z', 'z', 'x', 'x'];
				let bounds = [0, -Math.max(a[4], a[5]) * this.SCALING_FACTOR, Math.min(a[4], a[5]) * this.SCALING_FACTOR, -Math.max(a[0], a[1]) * this.SCALING_FACTOR, Math.min(a[0], a[1]) * this.SCALING_FACTOR];
				for (let i = 1; i < axes.length; ++i) {
					// Going right == -1
					// Going left == 1
					let dir = i % 2 === 0 ? 1 : -1;
					if (dir * this.camera.position[axes[i]] < bounds[i] && arenaPlanesStatus[i]) {
						this.mainGroup.remove(arenaPlanes[i]);
					} else if (dir * this.camera.position[axes[i]] > bounds[i] && !arenaPlanesStatus[i]) {
						this.mainGroup.add(arenaPlanes[i]);
					}
					arenaPlanesStatus[i] = dir * this.camera.position[axes[i]] > bounds[i];
				}
				if (this.isCeilingEnabled) {
					let cameraMax = this.scale[1] + this.scale[1] / 2;
					if (this.camera.position['y'] < cameraMax && arenaPlanesStatus[6]) {
						this.mainGroup.add(arenaPlanes[6]);
					} else if (this.camera.position['y'] > cameraMax && !arenaPlanesStatus[6]) {
						this.mainGroup.remove(arenaPlanes[6]);
					}
					arenaPlanesStatus[6] = this.camera.position['y'] < cameraMax;
				}
			};

			if (arena[0] instanceof Array) {
				if (this.multipleArenasPlanesStatus) {
					arena.forEach((a, i) => cb(a, this.multipleArenasPlanesStatus[i], this.arenas[i]));
				}
			} else {
				cb(arena, this.arenaPlanesStatus, this.arenaPlanes);
			}
		}
	}

	setControlsTarget(arena) {
		let cameraCenterX,
			cameraCenterZ,
			isMultiSensor = arena[0] instanceof Array;

		if (isMultiSensor) {
			cameraCenterX = (Math.max(...arena.map(a => a[1])) + Math.min(...arena.map(a => a[0]))) / 2 * this.SCALING_FACTOR;
			cameraCenterZ = (Math.max(...arena.map(a => a[5])) + Math.min(...arena.map(a => a[4]))) / 2 * this.SCALING_FACTOR;
		} else {
			cameraCenterX = (arena[1] + arena[0]) / 2 * this.SCALING_FACTOR;
			cameraCenterZ = (arena[5] + arena[4]) / 2 * this.SCALING_FACTOR;
		}

		this.controls.target = new THREE.Vector3(cameraCenterX, 0, cameraCenterZ);
		this.controls.update();
	}

	setScale(arena) {
		this.scale = getThreeJsScale(arena, this.SCALING_FACTOR);
		this.updateOrbitControlsMaxDistance();
	}

	getLiftAboveGround(arena) {
		return arena[2] * this.SCALING_FACTOR;
	}

	getFloorColor(isTextLayerColor = false): any {
		return parseInt(this.FLOOR_COLOR, 16);
	}

	/**
	 * Called when status is CONFIGURING:
	 */
	updateScene(arena, parameters) {
		let mainArena = arena,
			mainParameters = parameters,
			isMultipleSensors = arena[0] instanceof Array;

		if (isMultipleSensors) {
			mainArena = arena[0];
		}
		if (parameters instanceof Array) {
			this.connectedSensorsParameters = parameters as any;
			mainParameters = parameters[0];
		}
		this.oldArena = this.arena;
		this.arena = mainArena;
		this.origArena = arena;
		this.parameters = mainParameters;
		this.setScale(mainArena);
		let cb = (a, i) => {
			let scale = getThreeJsScale(a, this.SCALING_FACTOR),
				liftAboveGround = this.getLiftAboveGround(a),
				xRightPlane = scale[0] / 2 + this.WALL_WIDTH / 2,
				xLeftPlane = -scale[0] / 2 - this.WALL_WIDTH / 2,
				zFrontPlane = scale[2] / 2 + this.WALL_WIDTH / 2,
				zSensorPlane = -scale[2] / 2 - this.WALL_WIDTH / 2,
				aboveGround = liftAboveGround + scale[1] / 2 - this.WALL_WIDTH / 2,
				{x, z} = this.getPositionMargin(a);

			// Room floor
			this.arenas[i][0].position.set(x, this.FLOOR_Y_POSITION, z);
			// Front plane
			this.arenas[i][1].position.set(x, aboveGround, Math.max(a[5], a[4]) * this.SCALING_FACTOR);
			// Sensor plane
			this.arenas[i][2].position.set(x, aboveGround, Math.min(a[5], a[4]) * this.SCALING_FACTOR);
			// Right plane
			this.arenas[i][3].position.set(Math.max(a[1], a[0]) * this.SCALING_FACTOR, aboveGround, z);
			// Left plane
			this.arenas[i][4].position.set(Math.min(a[1], a[0]) * this.SCALING_FACTOR, aboveGround, z);
			// Bottom plane
			this.arenas[i][5].position.set(x, liftAboveGround, z);
			// Top
			if (this.isCeilingEnabled) {
				this.arenas[i][6].position.set(x, liftAboveGround + scale[1] + this.WALL_WIDTH / 2, z);
			}

			// Room floor
			this.arenas[i][0].scale.set(xRightPlane - xLeftPlane, this.WALL_WIDTH, zFrontPlane - zSensorPlane);
			// Bottom plane
			this.arenas[i][5].scale.set(xRightPlane - xLeftPlane, this.WALL_WIDTH, zFrontPlane - zSensorPlane);
			// Top
			if (this.isCeilingEnabled) {
				this.arenas[i][6].scale.set(xRightPlane - xLeftPlane, this.WALL_WIDTH, zFrontPlane - zSensorPlane);
			}

			this.arenas[i][1].scale.set(scale[0] + 2, scale[1] + 2, this.WALL_WIDTH);
			this.arenas[i][2].scale.set(scale[0] + 2, scale[1] + 2, this.WALL_WIDTH);
			this.arenas[i][3].scale.set(this.WALL_WIDTH, scale[1] + 1, scale[2]);
			this.arenas[i][4].scale.set(this.WALL_WIDTH, scale[1] + 1, scale[2]);
		};

		if (isMultipleSensors) {
			arena.forEach((a, i) => {
				let arenaPlanes;
				if (this.shouldUpdateArenaPlanesAccordingToPOV) {
					if (this.arenas[i]) {
						arenaPlanes = this.arenas[i];
					} else {
						arenaPlanes = [];
						for (let j = 0; j < this.arenaPlanes.length; j++) {
							arenaPlanes[j] = this.arenaPlanes[j].clone();
							arenaPlanes[j].material = this.arenaPlanes[j].material.clone();
							/**
							 * Uncomment to make each arena use own wall color
							 */
							// arenaPlanes[j].material.color = new THREE.Color(BASE_COLORS[(i) % BASE_COLORS.length]);
							arenaPlanes[j].material.needsUpdate = true;
						}
						this.arenas.push(arenaPlanes);
						arenaPlanes.forEach(p => p && this.mainGroup.add(p));
					}
				}
				this.multipleArenasPlanesStatus.push([false, false, false, false, false, false, false]);
				cb(a, i);
			});
			this.isCameraTargetNeedUpdate = true;
		} else {
			cb(arena, 0);
		}

		// For scenarios where sensor is on the floor
		this.updateFloor();

		this.updateSensor(parameters);

		// Update controls to center on the vertical center of the front plane
		if (this.isCameraTargetNeedUpdate) {
			this.updateControlsPolarAngle();
			this.setControlsTarget(arena);
		}
		this.controls.update();
	}

	updateControlsPolarAngle() {
		if (!this.isSensorLocatedOnTheFloor()) {
			// If this.sensorPlane === "" it means the sensor is located on the floor (relevant for breathing)
			// This means there's tracking going on below the floor
			// So, disable controls going below the floor only if we're sensor is not located on the floor
			this.controls.maxPolarAngle = Math.PI / 2.25;
		} else {
			this.controls.maxPolarAngle = Infinity;
		}
		this.controls.update();
	}

	updateFloor() {
		if (this.isSensorLocatedOnTheFloor()) {
			this.arenaPlanes[0].material.blending = THREE.NormalBlending;
			this.arenaPlanes[0].material.opacity = 0.4;
			this.arenaPlanes[0].material.transparent = true;
			this.arenaPlanes[0].material.side = THREE.DoubleSide;
		} else {
			this.arenaPlanes[0].material.blending = THREE.NoBlending;
			this.arenaPlanes[0].material.opacity = 1;
			this.arenaPlanes[0].material.transparent = false;
		}
		this.arenaPlanes[0].material.needsUpdate = true;
	}

	getPointPosition(point, pointLocation = POINT_LOCATION.DEFAULT) {
		let yArenaGap = this.arena[3] / 2, // TODO: Add this to scale
			[xPointPosition, yPointPosition, zPointPosition] = this.mapEVKaxesToThreeJS(point),
			result;

		switch (pointLocation) {
			case POINT_LOCATION.DEFAULT:
			default:
				result = [xPointPosition, yPointPosition, zPointPosition];
				break;
			case POINT_LOCATION.RIGHT_PLANE_SHADOW:
				result = [this.arena[1] * this.SCALING_FACTOR, yPointPosition, zPointPosition];
				break;
			case POINT_LOCATION.LEFT_PLANE_SHADOW:
				result = [this.arena[0] * this.SCALING_FACTOR, yPointPosition, zPointPosition];
				break;
			case POINT_LOCATION.SENSOR_PLANE_SHADOW:
				result = [xPointPosition, yPointPosition, this.arena[4] * this.SCALING_FACTOR];
				break;
			case POINT_LOCATION.FRONT_PLANE_SHADOW:
				result = [xPointPosition, yPointPosition, this.arena[5] * this.SCALING_FACTOR];
				break;
			case POINT_LOCATION.FLOOR_SHADOW:
				result = [xPointPosition, -yArenaGap + this.FLOOR_HEIGHT, zPointPosition];
				break;
		}
		return {x: result[0], y: result[1], z: result[2]};
	}

	/**
	 * Get color for point depending on whether there is a UID or target ID
	 * @param point
	 */
	getPointColor(point: PointCloudPoint): THREE.Color {
		let targetID = this.isMultipleSensors && typeof point[4] === 'number' ? point[4] : point[3];
		return this.getColor(this.isRaw, targetID);
	}

	onResize() {
		if (this.container) {
			this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
			this.camera.updateProjectionMatrix();
			this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
		}
	}

	updateSensor(parameters) {
		let callback = (sensor, params) => {
			let matrix = new THREE.Matrix4(),
				boardToWebGUITransMat: matrix4 = params['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'];

			if (boardToWebGUITransMat) {
				boardToWebGUITransMat = boardToWebGUITransMat.flat() as matrix4;

				matrix.set(...boardToWebGUITransMat);
				sensor.setRotationFromMatrix(matrix);

				let sensorPosition = new THREE.Vector3(
					...getSensorTranslation(params).map(v => v * this.SCALING_FACTOR)
				);

				sensor.position.set(sensorPosition.x, sensorPosition.y, sensorPosition.z);
			}
		};

		if (parameters instanceof Array) {
			this.connectedSensorsParameters = parameters as any;
			this.mainGroup.remove(this.sensor);
			parameters.forEach((v, index) => {
				let sensor;

				if (this.sensors![index]) {
					sensor = this.sensors![index];
				} else {
					sensor = this.getSensor();
					this.sensors!.push(sensor);
					if (this.isSensorVisible) {
						this.mainGroup.add(sensor);
					}
				}
				callback(sensor, v);
			});
		} else {
			callback(this.sensor, parameters);
		}
	}

	cleanup() {
		cancelAnimationFrame(this.animFrameReqId);
		this.animFrameReqId = undefined;

		// The cleanupHelper undefines all of the class fields
		// It seems as if this might only be needed for threejs objects (Materials, Geometries, Programms...)
		this.cleanupHelper();

		this.renderer.forceContextLoss();
		this.renderer.domElement = undefined;
		// Started crashed since Chrome 79.0.3945.79
		try {
			if (this.renderer.dispose) {
				this.renderer.dispose();
			}
		} catch (e) {
			console.warn(e);
		}

		this.renderer = undefined;
	}

	cleanupHelper() {
		this.mainGroup.remove(this.sensor);
		this.mainGroup.remove(this.AmbientLight);
		this.mainGroup.remove(this.gridHelper);
		this.mainGroup.remove(this.arenaPlanes[0]);
		this.mainGroup.remove(this.arenaPlanes[1]);
		this.mainGroup.remove(this.arenaPlanes[2]);
		this.mainGroup.remove(this.arenaPlanes[3]);
		this.mainGroup.remove(this.arenaPlanes[4]);
		this.mainGroup.remove(this.arenaPlanes[5]);
		this.sensors!.forEach(sensor => {
			this.mainGroup.remove(sensor);
			sensor.children[0].geometry.dispose();
			sensor.children[0].material.dispose();
			sensor = undefined;
		});
		this.sensors = null;
		if (this.status !== 'IMAGING') {
			this.mainGroup.remove(this.pointCloudMesh);
			this.mainGroup.remove(this.targetCentersInstancedMesh);
			this.mainGroup = undefined;
			this.pointCloudGeo!.dispose();
			this.pointCloudGeo = null;
			this.pointCloudMat!.dispose();
			this.pointCloudMat = null;
			this.pointCloudMesh = undefined;
			this.targetCentersInstancedMesh.geometry.dispose();
			// TODO: Check why this fails
			// this.targetCentersInstancedMesh.geometry = undefined
			this.targetCentersInstancedMesh.material.dispose();
			// TODO: Check why this fails
			// this.targetCentersInstancedMesh.material = undefined
			this.targetCentersInstancedMesh = undefined;
		}
		this.container = undefined;
		this.scale = undefined;
		this.oldArena = undefined;
		this.arena = undefined;
		this.arenaPlanes.forEach(mesh => {
			mesh.material.dispose();
			mesh.geometry.dispose();
		});
		this.arenaPlanes = undefined;
		this.oldStatus = undefined;
		this.status = undefined;
		this.arenaPlanesStatus = undefined;
		this.data = undefined;
		this.targetCenterData = undefined;
		this.isDisplayCenterTarget = undefined;
		this.isDisplayPlaneShadow = undefined;
		this.isDisplayFloorShadow = undefined;
		this.getColor = undefined;
		this.targetCenterColor = undefined;
		this.arena = undefined;
		this.scene!.background = null;
		// this.scene.dispose(); TODO check
		this.scene = null;
		this.camera = undefined;
		this.controls.dispose();
		this.controls = undefined;
		this.gridHelper.material.dispose();
		this.gridHelper.geometry.dispose();
		this.gridHelper = undefined;
		this.arenaPlanes = undefined;
		this.arenaPlanesStatus = undefined;
		this.multipleArenasPlanesStatus = undefined;
		this.sensor.children[0].geometry.dispose();
		this.sensor.children[0].material.dispose();
		this.sensor = undefined;
	}

	// webgui_point = rotationMatrix * evk_engine_point + translationVector
	mapEVKaxesToThreeJS(value: [number, number, number], translate = true, scale = true): [number, number, number] {
		// console.log((<any>value).index);
		return mapEVKaxesToThreeJS(value, this.connectedSensorsParameters[(<any>value).index] || this.parameters, translate, scale, this.SCALING_FACTOR);
	}

	protected updateSensorColor(color) {
		this.updateSensorColorHelper(color, this.sensor);
		this.sensors!.forEach(sensor => {
			this.updateSensorColorHelper(color, sensor);
		});
	}

	protected updateSensorColorHelper(color, sensor) {
		sensor.children[0].material.color = color;
	}

	protected getSensor() {
		let arrowDirectionVector = new THREE.Vector3(0, 0, 1),
			origin = new THREE.Vector3(0, 0, 0),
			length = 0.2 * this.SCALING_FACTOR,
			hex = 0x34eb95;

		arrowDirectionVector.normalize();
		let arrowHelper = new THREE.ArrowHelper(arrowDirectionVector, origin, length, hex, 0.1 * this.SCALING_FACTOR, 0.05 * this.SCALING_FACTOR),
			group = new THREE.Group(),
			planeGeometry = new THREE.BoxGeometry(10, 1, 10),
			planeMaterial = new THREE.MeshLambertMaterial({color: this.sensorColorOnConfig, side: THREE.DoubleSide}),
			sensorPlane = new THREE.Mesh(planeGeometry, planeMaterial);

		/**
		 * The sensor Z axis should be aligned with ThreeJS world Z axis
		 * to apply "ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat" correctly
		 */
		sensorPlane.rotateX(Math.PI / 2);
		group.add(sensorPlane, arrowHelper);

		return group;
	}

	protected updateSensorVisible(isSensorVisible) {
		if (this.sensors!.length) {
			this.sensors!.forEach(sensor => {
				if (isSensorVisible) {
					if (!this.mainGroup.children.includes(sensor)) {
						this.mainGroup.add(sensor);
					}
				} else {
					this.mainGroup.remove(sensor);
				}
			});
		} else {
			if (isSensorVisible) {
				if (!this.mainGroup.children.includes(this.sensor)) {
					this.mainGroup.add(this.sensor);
				}
			} else {
				this.mainGroup.remove(this.sensor);
			}
		}
	}

	protected getPositionMargin(arena) {
		let x = (arena[1] - (arena[1] - arena[0]) / 2) * this.SCALING_FACTOR,
			z = (arena[5] - (arena[5] - arena[4]) / 2) * this.SCALING_FACTOR;

		return {x, z};
	}

	protected updateOrbitControlsMaxDistance() {
		if (this.controls) {
			this.controls.maxDistance = Math.max(...this.scale) * 3;
			this.controls.needsUpdate = true;
		}
	}
}
