import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GraphsComponent} from './components/graphs/graphs.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import {RouterModule, Routes} from '@angular/router';
import {PageGuard} from '../../guards/page.guard';
import {ToolbarAnalyticsComponent} from './components/toolbars/toolbar-analytics/toolbar-analytics.component';
import {BackendConnectorRestComponent} from '../../components/shared/backend-connector/backend-connector-rest/backend-connector-rest.component';
import {environment} from '../../../environments/environment';
import {LayersContainerComponent} from './components/layers/layers-container.component';
import {SmartRetailComponent} from './components/layers/analytics-graphs/smart-retail/smart-retail.component';
import {C3GraphComponent} from '../../components/shared/c3-graph/c3-graph.component';
import {FloorPlanLayerComponent} from './components/layers/floor-plan/floor-plan.component';
import {FloorPlanComponent} from './components/base-view/floor-plan/floor-plan.component';
import {ToolbarFloorPlanComponent} from './components/toolbars/toolbar-floor-plan/toolbar-floor-plan.component';
import {BackendConnectorMqttComponent} from '../../components/shared/backend-connector/backend-connector-mqtt/backend-connector-mqtt.component';
import {SharedModule} from '../../modules/shared/shared.module';
import {MODULE_PATH} from '../../app.module';
import {ToolbarButtonComponent} from '../../components/shared/toolbar-button/toolbar-button.component';
import {RetailStatsComponent} from './components/layers/retail-stats/retail-stats.component';
import {ShopperInsightsComponent} from './components/layers/shopper-insights/shopper-insights.component';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {AddGeoFenceComponent} from './components/add-location/add-geo-fence.component';
import {MatRippleModule} from '@angular/material/core';
import {AnalyticsGraphBaseComponent} from './components/layers/analytics-graphs/analyticsGraph.base.component';
import { GeoFencesComponent } from './components/layers/analytics-graphs/geo-fences/geo-fences.component';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../../../assets/i18n/${lang}.json`));
	}
}

const routes: Routes = [
	{
		path: 'floor-plan',
		component: FloorPlanComponent,
		canActivate: [PageGuard],
		data: {
			isFloorPlan: true,
			toolbar: ToolbarFloorPlanComponent,
			sensorConnector: !environment.useAzure && BackendConnectorMqttComponent,
			showBackendConnector: !environment.disableRemoteConnToRESTServer && !environment.useAzure
		}
	}
];

if (environment.isSmartRetailEnabled) {
	routes.push({
		path: 'analytics',
		component: GraphsComponent,
		canActivate: [PageGuard],
		data: {
			isRetailAnalytics: true,
			toolbar: ToolbarAnalyticsComponent,
			useCustomToolbarTemplate: true,
			sensorConnector: BackendConnectorRestComponent,
			showBackendConnector: !environment.disableRemoteConnToRESTServer && !environment.useAzure
		}
	}, {
		path: 'geo-fences',
		component: GraphsComponent,
		canActivate: [PageGuard],
		data: {
			isGeoFences: true,
			toolbar: ToolbarAnalyticsComponent,
			useCustomToolbarTemplate: true,
			sensorConnector: BackendConnectorRestComponent,
			showBackendConnector: !environment.disableRemoteConnToRESTServer && !environment.useAzure
		}
	});
}

routes.push({
	path: '**',
	pathMatch: 'full',
	redirectTo: 'floor-plan'
});

@NgModule({
	declarations: [
		GraphsComponent,
		LayersContainerComponent,
		SmartRetailComponent,
		C3GraphComponent,
		FloorPlanLayerComponent,
		FloorPlanComponent,
		ToolbarAnalyticsComponent,
		ToolbarFloorPlanComponent,
		ToolbarButtonComponent,
		RetailStatsComponent,
		ShopperInsightsComponent,
		AddGeoFenceComponent,
		AnalyticsGraphBaseComponent,
		GeoFencesComponent,
	],
	providers: [
		{
			provide: MODULE_PATH,
			useValue: environment.SMART_BUILDINGS_MODULE_PATH
		},
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule.forChild(routes),
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		NgxMatDatetimePickerModule,
		NgxMatTimepickerModule,
		MatRippleModule
	]
})
export class SmartBuildingModule {
}
