<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

	<!--- Note that these columns can be defined in any order.
		  The actual rendered columns are set as a property on the row definition" -->

	<!-- Position Column -->
	<ng-container matColumnDef="position">
		<th mat-header-cell *matHeaderCellDef></th>
		<td mat-cell *matCellDef="let element">
			<div [ngStyle]="{backgroundColor: element.color}" style="width: 10px; height: 10px;"></div>
		</td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="range">
		<th mat-header-cell *matHeaderCellDef> Range (m) </th>
		<td mat-cell *matCellDef="let element"> {{element.range}} </td>
	</ng-container>

	<!-- Weight Column -->
	<ng-container matColumnDef="speed">
		<th mat-header-cell *matHeaderCellDef> Speed </th>
		<td mat-cell *matCellDef="let element"> {{element.speed}} </td>
	</ng-container>

	<!-- Symbol Column -->
	<ng-container matColumnDef="angle">
		<th mat-header-cell *matHeaderCellDef> Angle (°) </th>
		<td mat-cell *matCellDef="let element"> {{element.angle}} </td>
	</ng-container>

	<ng-container matColumnDef="ageClassification">
		<th mat-header-cell *matHeaderCellDef> Age Classification </th>
		<td mat-cell *matCellDef="let element"> {{element.ageClassification}} </td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

