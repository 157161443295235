import {Injectable} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {environment} from '../../environments/environment';
import {makeDeepCopy} from '../utils/utils';
import {VALUE_TO_DELETE} from './settings.service';

declare const require: any;
const merge = require('deepmerge');
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
const overwriteOptions = {arrayMerge: overwriteMerge};

/**
 * Shell for working with localStorage (offline) or Firebase (online).
 */
@Injectable({
	providedIn: 'root'
})
export class StorageService {

	private storage = {};

	constructor(private firebaseService: FirebaseService) {
		if (environment.offline) {
			this.storage = JSON.parse(JSON.stringify(localStorage));
		}
	}

	setItem(key: string, data: any, waitForFirebase = false): Promise<boolean> {
		// Merge local data like we do in firebase service
		let dataToSave;
		if (typeof data === 'object') {
			let tmpData = makeDeepCopy(data),
				currentData = JSON.parse(typeof this.storage[key] === 'undefined' ? null : this.storage[key]);
			dataToSave = merge(currentData, tmpData, overwriteOptions);

			Object.values(dataToSave).forEach((parameters: any) => {
				if (typeof parameters === 'object') {
					Object.keys(parameters).forEach(parameter => {
						if (parameters[parameter] === VALUE_TO_DELETE) {
							delete parameters[parameter];
						}
					});
				}
			});
		} else {
			dataToSave = data;
		}
		this.storage[key] = JSON.stringify(dataToSave);
		if (environment.offline) {
			try {
				localStorage.setItem(key, this.storage[key]);
			} catch (_) {
			}
		} else {
			switch (true) {
				case key.startsWith(environment.parametersNamePrefix):
					let [ip, port] = key.replace(environment.parametersNamePrefix, '').split(':'),
						request = this.firebaseService.setParameters(ip, port, data);
					if (waitForFirebase) {
						return request;
					}
					break;
				case key === 'spaceInfo':
					this.firebaseService.updateSpaceInfo(data);
					break;
				case key === 'spaces':
					// We need to wait while current space will be updated to use it in future requests
					// because of most of requests to Firebase use current space
					return this.firebaseService.setSpaces(data);
				case key === 'socketPool':
					break;
				default:
					try {
						localStorage.setItem(key, JSON.stringify(data));
					} catch (_) {
					}
			}
		}
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	}

	getItem(key: string): Promise<any | null> {
		if (environment.offline || this.storage[key]) {
			return new Promise((resolve, reject) => {
				let data = JSON.parse(typeof this.storage[key] === 'undefined' ? null : this.storage[key]);

				switch (key) {
					case 'socketPool':
						if (!data) {
							data = [];
						}
						break;
					case 'spaceInfo':
						if (!data) {
							data = {};
						}
						if (!('devices' in data)) {
							data.devices = {};
						}
						if (!('rules' in data)) {
							data.rules = [];
						}
						break;
				}
				resolve(data);
			});
		} else {
			switch (true) {
				case key === 'socketPool':
					return this.firebaseService.getRooms().then(rooms => {
						let pool = rooms.filter(r => r.engine && r.engine.ip && r.engine.port).map(r => `${r['engine']['ip']}:${r['engine']['port']}`);
						this.storage[key] = JSON.stringify(pool);
						return pool;
					});
				case key === 'spaces':
					return this.firebaseService.getSpaces().then(data => {
						this.storage[key] = JSON.stringify(data);
						return data;
					});
				case key.startsWith(environment.parametersNamePrefix):
					let [ip, port] = key.replace(environment.parametersNamePrefix, '').split(':');
					return this.firebaseService.getParameters(ip, port).then(data => {
						this.storage[key] = JSON.stringify(data);
						return data;
					});
				case key === 'spaceInfo':
					return this.firebaseService.getSpaceInfo().then(data => {
						if (!('devices' in data!)) {
							data!.devices = {};
						}
						if (!('rules' in data!)) {
							data!.rules = [];
						}
						this.storage[key] = JSON.stringify(data);
						return data;
					});
				default:
					var ret = null;

					try {
						ret = JSON.parse(localStorage.getItem(key)!);
					} catch (_) {
						// no-op Safari private mode
						ret = JSON.parse(typeof this.storage[key] === 'undefined' ? null : this.storage[key]);
					}

					return new Promise((resolve, reject) => {
						resolve(ret);
					});
			}
		}
	}

	removeItem(key: string): Promise<boolean> {
		delete this.storage[key];
		if (environment.offline) {
			delete this.storage[key];
			try {
				localStorage.removeItem(key);
			} catch (_) {
				// no-op Safari private mode
				delete this.storage[key];
			}
			return Promise.resolve(true);
		} else {
			switch (true) {
				case key.startsWith(environment.parametersNamePrefix):
					let [ip, port] = key.replace(environment.parametersNamePrefix, '').split(':');
					return this.firebaseService.removeRoom(ip, port) as any;
				default:
					try {
						localStorage.removeItem(key);
					} catch (_) {
						// no-op Safari private mode
						delete this.storage[key];
					}

					return Promise.resolve(true);
			}
		}
	}

	clearLocalStorage() {
		this.storage = {};
	}

	clearStorage() {
		this.clearLocalStorage();
		localStorage.clear();
	}
}
