import {SensorSocket} from './sensor-socket';
import {SensorsService} from './sensors.service';
import {ConnectionStatus} from './connection';
import {OutputType} from '../../models/models';
import {environment} from '../../../environments/environment';

/**
 * Class for working with multiple sockets.
 */
export class MultiSensorsSocket extends SensorSocket {

	readonly ip;
	readonly port;
	readonly isMultipleSensors: boolean = true;

	get url() {
		return `multi`;
	}

	get lastErrorMessage() {
		return this._lastErrorMessage;
	}

	get connectionStatus() {
		return this._connectionStatus;
	}

	set connectionStatus(status) {
		if (this.sockets.find(sensorSocket => sensorSocket.connectionStatus === ConnectionStatus.IMAGING)) {
			status = ConnectionStatus.IMAGING;
		} else {
			status = ConnectionStatus.CONFIGURING;
		}
		this._connectionStatus = status;
		this.status.next(status);
	}

	set lastError(lastError) {
		this._lastErrorMessage = lastError;
	}

	private sockets: Array<SensorSocket> = [];

	constructor(private sensorsService: SensorsService) {
		super();

		this.ip = 'multi';
		this.port = 'sensors';
		this.sensorsService.sensorSockets.subscribe(sockets => {
			this.sockets = sockets;
		});
		this._connectionStatus = ConnectionStatus.CONFIGURING;
	}

	setOutputs(type?: OutputType, outputs?: Array<string>) {
		this.sockets.filter(sensorSocket => sensorSocket.isConnected()).forEach(sensorSocket => {
			sensorSocket.setOutputs(environment.outputsType as OutputType, outputs);
		});
	}

	setSensorParameters(parameters, notify) {
		let pool = this.sockets.filter(sensorSocket => sensorSocket.isConnected()).map(sensorSocket => {
			return sensorSocket.setSensorParameters(parameters, notify);
		});

		return Promise.all(pool);
	}

	retrieveStatus() {
		this.status.next(this._connectionStatus);
		return true;
	}

	updateAverageFPS() {
		let FPSs: Array<number> = [];
		this.sockets.filter(sensorSocket => sensorSocket.isRunned()).forEach(sensorSocket => {
			FPSs.push(sensorSocket.fps);
		});
		this.fps = FPSs.reduce((p, c) => p + c, 0) / FPSs.length;
	}

	stop() {
		this.connectionStatus = ConnectionStatus.STOPPED;
		this.sockets.filter(sensorSocket => sensorSocket.isRunned()).forEach(sensorSocket => {
			sensorSocket.stop();
		});
		return true;
	}

	isConnected() {
		return this.sockets.some(sensorSocket => sensorSocket.isConnected());
	}
}
