import {Injectable, Injector} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {SingleSensorComponent} from '../applications/flow-processor/components/base-view/single-sensor/single-sensor.component';
import {ToolbarEvkComponent} from '../components/shared/toolbars/toolbar-evk/toolbar-evk.component';
import {BackendConnectorEvkComponent} from '../components/shared/backend-connector/backend-connector-evk/backend-connector-evk.component';
import {SensorsService} from './system/sensors.service';
import {MultiSensorsComponent} from '../applications/flow-processor/components/base-view/multi-sensors/multi-sensors.component';
import {SettingsService} from './settings.service';

export interface Layer {
	name: string;
	displayName: string;
	componentName: string;
	dataDict: {
		[key: string]: string
	};
	staticDataDict?: {
		[key: string]: string
	};
	metadata?: {
		[key: string]: any
	};
	cfgDict?: {
		[key: string]: any
	};
	controls: Array<IConfigurableLayerControl>;
	selected: boolean;
	alternative: string[];
	sensor?: boolean;
	flip?: boolean;
	rotate?: boolean;
	reset3d?: boolean;
	ruler?: boolean;
	sensitivity?: boolean;
	camera?: boolean;
	isRaw?: boolean;
	tracker?: boolean;
	isSmartTailor?: boolean;
	isXAxisInverted?: boolean;
	axes?: number[];
	axesEnabled?: boolean;
}

export enum ConfigurablePageControlType {
	PAGE_TABBED_FORM_CONTROL = 'page_tabbed_form_control',
	PAGE_DUPLICATE = 'page_duplicate',
	PAGE_CLEAR_CACHE = 'page_clear_cache',
	PAGE_LOAD_AND_SAVE = 'page_load_and_save',
	PAGE_STOP_SENSOR = 'page_stop_sensor',
	PAGE_RUN_SENSOR = 'page_run_sensor',
	PAGE_RECORD = 'page_record',
	PAGE_LOAD_RECORDING = 'page_load_recording',
	PAGE_FPS = 'page_fps',
	PAGE_SET_VALUE_CONTROL = 'page_set_value_control',
	PAGE_SHOW_VALUE_CONTROL = 'page_show_value_control',
	PAGE_SWITCH_CONTROL = 'page_switch_control',
	PAGE_FRAME_NUMBER_DISPLAY = 'page_frame_number_display',
	PAGE_SWITCH_MULTIPLE_CONTROL = 'page_switch_multiple_control',
}

export enum ConfigurableLayerControlType {
	LAYER_SWITCH = 'layer_switch',
	LAYER_BTN_CFG_BKG_IMG = 'layer_btn_cfg_bkg_img',
	LAYER_INFO = 'layer_info',
	LAYER_DROPDOWN = 'layer_dropdown'
}

export enum ConfigurableTabControlType {
	TAB_SWITCH = 'tab_switch',
	TAB_BUTTON_SWITCH = 'tab_buttons_switch',
	TAB_NUMBER_INPUT = 'tab_number_input',
	TAB_TEXT_INPUT = 'tab_text_input',
	TAB_DROPDOWN = 'tab_dropdown',
	TAB_MONITORED_ROOM_DIMS = 'tab_monitored_room_dims',
	TAB_SENSOR_HEIGHT_INPUT = 'tab_sensor_height_input',
	TAB_BACKGROUND_IMAGE = 'tab_background_image'
}

export enum ConfigurableTabTemplate {
	ARENA_TAB = 'arena_tab',
	TARGETS_TAB = 'targets_tab',
	DEFAULT = 'default',
}

export type ConfigurableControlNumberInputConfig = number | '-inf' | 'inf' | undefined;

export interface IConfigurableLayerControl {
	enable: boolean;
	label: string;
	icon: string;
	controllerId?: string;
	off_tooltip?: string;
	on_tooltip?: string;
	type: ConfigurableLayerControlType;
	param: string;
	multiple?: boolean;
	options?: Array<IConfigurableControlOption>;
	title?: string;
}

export interface IConfigurableControlOption {
	label: string;
	value: any;
	id?: string;
	controllerId?: string;
	disabling?: Array<string>;
	enabling?: Array<string>;
}

export interface IConfigurableTabControl {
	enable: boolean;
	label: string;
	description?: string;
	type: ConfigurableTabControlType;
	param: string;
	off_tooltip?: string;
	on_tooltip?: string;
	tooltip?: string;
	options?: Array<IConfigurableControlOption>;
	range?: Array<ConfigurableControlNumberInputConfig>;
	rangeX?: Array<ConfigurableControlNumberInputConfig>;
	rangeY?: Array<ConfigurableControlNumberInputConfig>;
	rangeZ?: Array<ConfigurableControlNumberInputConfig>;
	boardToWebGUITransMat?: string;
	paramToSetHeightTo?: string;
	sensorHeightNumberInput?: IConfigurableTabControl;
	step?: number;
	multiple?: boolean;
}

export interface IConfigurableTab {
	label: string;
	icon: string;
	enable: boolean;
	template?: {
		type: ConfigurableTabTemplate;
		enablePreview?: boolean;
	};
	controls: Array<IConfigurableTabControl>;
}

export interface IConfigurablePageControl {
	enable: boolean;
	label: string;
	icon: string | Array<string>;
	type: ConfigurablePageControlType;
	disabled?: boolean;
	param?: string  | Array<string>;
	value?: any;
	save_data_params?: Array<string>;
	tabs?: Array<IConfigurableTab>;
	controls?: Array<IConfigurableTabControl>;
	tooltip?: string;
	off_tooltip?: string;
	on_tooltip?: string;
	changeColorUponClick?: boolean;
	active?: boolean;
}

export interface IConfigurablePage {
	enable?: boolean;
	name: string;
	icon: string;
	url?: string;
	displayName?: string;
	layers?: Array<Layer>;
	controls?: Array<IConfigurablePageControl>;
	isMultiSensor?: boolean;
}

/**
 * Service for work with Configurable configuration (CoCo).
 * CoCo - structure with list of WebGUI pages and their description (see interfaces above)
 */

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {

	get pages(): Array<IConfigurablePage> {
		return this._pages;
	}

	set pages(pages: Array<IConfigurablePage>) {
		this._pages = pages;
		this.updatePageMap();
	}

	pageMap: {
		[pageName: string]: IConfigurablePage
	} = {};

	readonly configurationByUrl: {
		[url: string]: {
			pages: Array<IConfigurablePage>,
			modulePath: string
		}
	} = {};

	private _pages: Array<IConfigurablePage> = [];
	private updateConfigurationQueue: Array<Promise<any>> = [];

	constructor(private router: Router,
				private settingsService: SettingsService) {

		this.pages = this.getFixedPages();
	}

	async addConfiguration(url: string, pages: Array<IConfigurablePage>, MODULE_PATH: string) {
		let configurationIsValid = this.validateConfiguration(pages);

		if (configurationIsValid) {
			this.configurationByUrl[url] = {
				pages,
				modulePath: MODULE_PATH
			};
			await this.updateConfiguration();
		}
	}

	async removeConfiguration(url: string) {
		delete this.configurationByUrl[url];
		await this.updateConfiguration();
	}

	async updateConfiguration() {
		let resolve,
			promise = new Promise(r => {
				resolve = r;
			});

		this.updateConfigurationQueue.push(promise);
		await Promise.all(this.updateConfigurationQueue.filter(p => p !== promise));
		try {
			/**
			 * Update pages list with new ones from configuration.
			 * In case we retrieve page with already existed name - replace with new one
			 */
			let configurationList = Object.values(this.configurationByUrl),
				configurationListMap = new Map(Object.entries(this.configurationByUrl).map(v => [v[1], v[0]])),
				pageNames = {},
				allPages = configurationList.map(configuration => configuration.pages).flat(),
				fixedPages = this.getFixedPages().filter(fixedPage => !allPages.find(page => fixedPage.name === page.name)),
				pagesWithTheSameNameCount = {};

			let promises: Array<Promise<any>> = [];
			Object.keys(this.configurationByUrl).forEach(url => {
				promises.push(this.settingsService.getSocketInfo(url).then(socketInfo => {
					if (socketInfo.name) {
						pageNames[url] = socketInfo.name;
					}
				}));
			});
			await Promise.all(promises);

			allPages.filter(page => !page.isMultiSensor).forEach(page => {
				if (!(page.name in pagesWithTheSameNameCount)) {
					pagesWithTheSameNameCount[page.name] = [];
				}
				pagesWithTheSameNameCount[page.name].push(page);
			});
			let updatedPagesTmp: any = [],
				updatedPages = configurationList.map(configuration => {
					return configuration.pages.filter(page => {
						return !page.isMultiSensor || !updatedPagesTmp.find(p => p.isMultiSensor);
					}).map(page => {
						let url = `/${configuration.modulePath}/${this.generateSlug(page.name)}`;

						if (page.name === 'Sensor') {
							url += `/${configurationListMap.get(configuration)}`;
						}
						this.updateRouterConfiguration(page, configuration.modulePath);

						let displayName;
						if (page.name === 'Sensor' && pageNames[configurationListMap.get(configuration) as string]) {
							displayName = pageNames[configurationListMap.get(configuration) as string];
						} else {
							displayName = page.name;
							if (!page.isMultiSensor && pagesWithTheSameNameCount[page.name].length > 1) {
								displayName += ` ${pagesWithTheSameNameCount[page.name].indexOf(page) + 1}`;
							}
						}
						updatedPagesTmp.push(page);
						return Object.assign({
							url
						}, page, {
							displayName
						});
					});
				}).flat().sort((a, b) => {
					// Put Multi Sensor page at the end, before Connection page
					if (!a.isMultiSensor && b.isMultiSensor) {
						return -1;
					}
					if (a.isMultiSensor && !b.isMultiSensor) {
						return 1;
					}
					return 0;
				});

			this.pages = (<Array<IConfigurablePage>>[]).concat(updatedPages, fixedPages);
		} finally {
			resolve();
		}
	}

	private getFixedPages(): Array<IConfigurablePage> {
		let pages: Array<IConfigurablePage> = [];

		if (environment.isFlowProcessorModuleEnabled) {
			pages.push({
				name: 'Sensor',
				displayName: 'Sensor',
				url: `/${environment.FLOW_PROCESSOR_MODULE_PATH}/sensor`,
				icon: 'svg_Dashboard'
			}, {
				name: 'CONNECTIONS',
				displayName: 'CONNECTIONS',
				url: `/${environment.FLOW_PROCESSOR_MODULE_PATH}/connections`,
				icon: 'svg_Devices'
			});
		}
		if (environment.isSmartBuildingsModuleEnabled) {
			pages.push({
				name: 'FLOOR_PLAN',
				displayName: 'FLOOR_PLAN',
				url: `/${environment.SMART_BUILDINGS_MODULE_PATH}/floor-plan`,
				icon: 'svg_MultiSensor'
			});
		}
		if (environment.isSmartRetailEnabled) {
			pages.push({
				name: 'ANALYTICS',
				displayName: 'ANALYTICS',
				url: `/${environment.SMART_BUILDINGS_MODULE_PATH}/analytics`,
				icon: 'svg_Retail'
			}, {
				name: 'GEO_FENCES',
				displayName: 'GEO_FENCES',
				url: `/${environment.SMART_BUILDINGS_MODULE_PATH}/geo-fences`,
				icon: 'material_picture_in_picture'
			});
		}
		if (environment.isSmartHomeModuleEnabled) {
			pages.push({
				name: 'SMART_HOME',
				displayName: 'SMART_HOME',
				url: `/${environment.SMART_HOME_MODULE_PATH}`,
				icon: 'svg_MultiSensor'
			}, {
				name: 'ROOM_OCCUPANCY',
				displayName: 'ROOM_OCCUPANCY',
				url: `/${environment.SMART_HOME_MODULE_PATH}/room-occupancy`,
				icon: 'svg_MultiSensor'
			});
		}
		if (environment.isVitalsModuleEnabled) {
			pages.push({
				name: 'COVID',
				displayName: 'COVID',
				url: `/${environment.VITALS_MODULE_PATH}`,
				icon: 'svg_Virus'
			});
		}
		if (environment.isSmartCoolerModuleEnabled) {
			pages.push({
				name: 'SMART_COOLER',
				displayName: 'SMART_COOLER',
				url: `/${environment.SMART_COOLER_MODULE_PATH}`,
				icon: 'svg_Cooler'
			});
		}
		if (environment.isSmartTailorModuleEnabled) {
			pages.push({
				name: 'SMART_TAILOR',
				displayName: 'SMART_TAILOR',
				url: `/${environment.SMART_TAILOR_MODULE_PATH}`,
				icon: 'svg_MultiSensor'
			});
		}

		return pages;
	}

	private generateSlug(pageName: string) {
		return pageName.toLowerCase().replace(' ', '-');
	}

	private updateRouterConfiguration(page, MODULE_PATH) {
		this.router.config.forEach(root => {
			if (root.children) {
				let foundChild: any = root.children.find((child: any) => child._loadedConfig && child.path === MODULE_PATH);
				if (foundChild) {
					let slug = this.generateSlug(page.name);

					if (!foundChild._loadedConfig.routes.find(route => route.path === slug)) {
						if (page.isMultiSensor) {
							foundChild._loadedConfig.routes.unshift({
								path: slug,
								component: MultiSensorsComponent,
								data: {
									pageName: page.name,
									slug,
									toolbar: ToolbarEvkComponent,
								}
							});
						} else {
							foundChild._loadedConfig.routes.unshift({
								path: slug,
								component: SingleSensorComponent,
								data: {
									pageName: page.name,
									slug,
									toolbar: ToolbarEvkComponent,
									sensorConnector: BackendConnectorEvkComponent
								}
							}, {
								path: `${slug}/:url`,
								component: SingleSensorComponent,
								data: {
									pageName: page.name,
									slug,
									toolbar: ToolbarEvkComponent,
									sensorConnector: BackendConnectorEvkComponent
								}
							});
						}
					}
				}
			}
		});
	}

	private validateConfiguration(pages: Array<IConfigurablePage>): boolean {
		try {
			if (!(pages instanceof Array)) {
				throw new TypeError('Configuration is not an array');
			}
			pages.forEach(page => {

			});
		} catch (e) {
			console.warn(e);
			return false;
		}

		return true;
	}

	private updatePageMap() {
		let newPageMap = {};

		this.pages.forEach(page => {
			newPageMap[page.name] = page;
		});

		this.pageMap = newPageMap;
	}
}
