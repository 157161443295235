import {Component, Injector, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AnalyticsGraphBaseComponent} from '../analyticsGraph.base.component';

@Component({
	selector: 'app-geo-fences',
	templateUrl: './geo-fences.component.html',
	styleUrls: ['../analyticsGraph.base.component.scss']
})
export class GeoFencesComponent extends AnalyticsGraphBaseComponent implements OnInit, OnChanges {

	constructor(protected injector: Injector) {
		super(injector);
	}

	async ngOnInit(): Promise<void> {
		this.toolbarService.isAnalyticsFormShown = true;
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('sensorSocketSettings' in changes && this.sensorSocketSettings.socketInfo?.globalRoomHeight) {
			this.selectWidget(this.graphs[0]);
		}
	}
}
