import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Injector,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {LeafletLayerComponent} from '../../../../../components/layers/base-classes/leaflet/leaflet.component';
import * as L from 'leaflet';
import {LatLng, LatLngTuple, LeafletMouseEvent} from 'leaflet';
import {RetailGeoFence, RetailGeoFenceGeometry, RetailGeoFenceType} from '../../../../../models/models';
import 'leaflet-draw';
import {RestService} from '../../../../../services/system/rest.service';
import {ModalService} from '../../../../../services/modal.service';
import {ToolbarService} from '../../../../../services/toolbar.service';
import {getMax, getMin, LatLngType, makeDeepCopy, rotateLeafletGeometry} from '../../../../../utils/utils';

const HeatmapOverlay = require('../../../../../../assets/js/leaflet-heatmap');
require('../../../../../../assets/js/leaflet-ruler');
const Turf = require('../../../../../../assets/js/turf.min');

@Component({
	selector: 'app-floor-plan-layer',
	template: `
		<app-layer-controls
			*ngIf="showMenu"
			style="z-index: 2"
			[opened]="!subToolbarIsAnimated"
			[hideViewButton]="true"
			[hideFlipButton]="true"
			[hideResetViewButton]="true"
			[hideRulerButton]="true"
			[hideSensitivityButton]="true"
			[hideSensorButton]="true"
			[isViewDisabled]="true"
			[isRotateEnable]="true"
			[rotateValue]="rotationDegree === 90 ? 3 : rotationDegree === 180 ? 2 : rotationDegree === 270 ? 1 : 0"
			(rotate)="rotate(90)"
		></app-layer-controls>
		<div style="z-index: 1" #map data-selector="floor-plan_layer" (click)="onContainerClick()"></div>
		<div #editToolbar class="edit-geofence" (mouseleave)="hideEditGeoFenceToolbar()">
			<button mat-icon-button [matTooltip]="'REDEFINE_GEO_FENCE' | translate" (click)="editGeoFence()">
				<mat-icon>edit</mat-icon>
			</button>
			<button mat-icon-button data-selector="geofence_delete_button" [matTooltip]="'DELETE_GEO_FENCE' | translate" (click)="deleteGeoFence()">
				<mat-icon>delete</mat-icon>
			</button>
			<div style="position: absolute; right: -30px;">
				<button mat-icon-button [matTooltip]="'MOVE_GEO_FENCE' | translate" (click)="resizeGeoFence()">
					<mat-icon>aspect_ratio</mat-icon>
				</button>
			</div>
		</div>
		<div #resizeToolbar class="edit-geofence">
			<button mat-icon-button [matTooltip]="'CANCEL' | translate" (click)="cancelResizeGeoFence()">
				<mat-icon>close</mat-icon>
			</button>
			<button mat-icon-button [matTooltip]="'SAVE' | translate" (click)="saveResizeGeoFence()">
				<mat-icon>check</mat-icon>
			</button>
		</div>
		<app-loading *ngIf="isLoading" type="circle"></app-loading>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		:host {
			display: block;
			width: 100%;
			height: 100%;
		}

		app-loading {
			height: 100%;
			display: flex;
			align-items: center;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 1001;
			background: rgba(255, 255, 255, 0.5);
		}

		.edit-geofence {
			position: absolute;
			z-index: 1001;
			background: #2c428161;
			border-radius: 2px 2px 0 0;
		}

		.edit-geofence div {
			background: #2c428161;
		}

		button {
			width: 24px;
			height: 24px;
			margin: 0 5px;
			line-height: 24px;
		}

		button mat-icon {
			font-size: 16px;
			color: #ffffff;
		}
	`],
})
export class FloorPlanLayerComponent extends LeafletLayerComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() geoFences: Array<RetailGeoFence> = [];
	@Input() showTargets = true;
	@Input() showHeatMap = true;
	@Input() showGeoFences = true;
	@Input() showMenu = true;
	@Input() isGeoFenceEditable = true;
	@Input() showGeoFenceConversion = false;
	@Input() heatmapColors;
	@Input() subToolbarIsAnimated = false;

	@ViewChild('editToolbar') editToolbar: ElementRef;
	@ViewChild('resizeToolbar') resizeToolbar: ElementRef;

	isLoading = false;

	private currentDots: Array<L.Marker> = [];
	private heatmap: any;
	private geoFenceLayersGroup = new L.FeatureGroup();
	private tooltipsLayersGroup = new L.FeatureGroup();
	private geoFenceLayers: Map<L.Polygon, L.FeatureGroup> = new Map();
	private geoFenceLayerMap: Map<L.Polygon, RetailGeoFence> = new Map();
	private layerGeoFenceMap: {
		[geoFenceId: number]: L.Polygon
	} = {};
	private activeGeoFence: L.Polygon;
	private retailGeoFenceTypes: Array<RetailGeoFenceType> = [];
	private editMode = false;
	private mode;
	private geoFencesToAdd: Array<RetailGeoFence> = [];
	private isEditToolbarDisplayed = false;
	private conversionLabelMap: {
		[geoFenceId: number]: L.Tooltip
	} = {};

	private currentEditToolbar;
	private currentRulerControl;
	private currentNewFeoFenceControl;
	private isClickEventPrevented = false;

	constructor(protected injector: Injector,
				private restService: RestService,
				private modalService: ModalService,
				private toolbarService: ToolbarService,
				private renderer: Renderer2,
				private changeDetectorRef: ChangeDetectorRef) {
		super(injector);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	async ngAfterViewInit() {
		await super.ngAfterViewInit();
		this.map.addLayer(this.geoFenceLayersGroup);
		this.map.addLayer(this.tooltipsLayersGroup);
		if (this.showHeatMap) {
			this.zoomPromise.then(() => {
				this.updateHeatmap();
			});
		}
		if (this.showGeoFences) {
			this.zoomPromise.then(() => {
				this.updateGeoFences();
			});
			this.subscriptions.push(this.busEventService.multipleRoomsParamsChanged.subscribe(() => {
				this.exitNewGeofenceMode();
				this.exitFloorDimensionMode();
			}));
			if (this.isGeoFenceEditable) {
				this.subscriptions.push(this.busEventService.newGeoFence.subscribe(() => {
					this.exitNewGeofenceMode();
					this.exitFloorDimensionMode();
					this.currentNewFeoFenceControl = new L.Draw.Polygon(this.map as any);
					this.currentNewFeoFenceControl.enable();
				}));
				this.subscriptions.push(this.busEventService.defineFloorDimensions.subscribe(() => {
					this.exitNewGeofenceMode();
					this.exitFloorDimensionMode();
					this.currentRulerControl = L.control['ruler']({
						position: 'topleft',
						lengthUnit: {
							factor: 1,
							display: 'meters',
							decimal: 1,
							label: 'Distance:'
						}
					});
					this.currentRulerControl.onAdd(this.map);
					this.currentRulerControl._toggleMeasure();
				}));
				this.subscriptions.push(this.busEventService.showSubToolbar.subscribe(next => {
					this.mode = next.mode;
					this.geoFencesToAdd = [];
					this.exitNewGeofenceMode();
					this.exitFloorDimensionMode();
				}));
				this.subscriptions.push(this.busEventService.uploadGeoFencesForNewFloor.subscribe(floorId => {
					this.uploadNewGeoFences(floorId);
				}));
				this.registerMapGeoFenceEvents();
			}
			if (this.showGeoFenceConversion || this.showHeatMap) {
				this.map.on('zoomstart', () => {
					document.querySelectorAll('.conversion-tooltip').forEach((e: any) => e.style.display = 'none');
				});
				this.map.on('zoomend', () => {
					this.updateGeoFencesConversion();
				});
			}
		}
		this.map.on('zoomend', () => {
			this.currentDots.forEach(dot => {
				dot.setIcon(this.getDotIcon());
			});
		});
		this.map.on('dragend', e => {
			this.isClickEventPrevented = true;
		});
		this.subscriptions.push(this.busEventService.floorUpdating.subscribe(next => {
			this.isLoading = next;
			this.changeDetectorRef.detectChanges();
		}));
		if (this.showGeoFenceConversion || this.showHeatMap) {
			this.updateGeoFencesConversion();
		}
	}

	async ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);
		if (this.showTargets) {
			this.updateDots();
		}
		if (this.showHeatMap && changes.data) {
			await this.updateHeatmap();
		}
		if (this.showGeoFences && changes.geoFences) {
			this.updateGeoFences();
		}
		if (this.showGeoFenceConversion || this.showHeatMap) {
			this.updateGeoFencesConversion();
		}
	}

	editGeoFence() {
		if (this.activeGeoFence) {
			this.hideEditGeoFenceToolbar();
			this.restService.getGeoFences(this.toolbarService.floorPlanFiltersForm.getRawValue().floor_id).then(geoFences => {
				this.modalService.floorPlanEditGeoFence('Redefine geo-fence', {
					mode: 'edit',
					geoFence: this.geoFenceLayerMap.get(this.activeGeoFence),
					location_type_ids: this.retailGeoFenceTypes,
					geoFences: geoFences
				}).afterClosed().toPromise().then(data => {
					if (data) {
						if (this.mode === 'edit') {
							this.isLoading = true;
							this.restService.updateGeoFence(Object.assign({}, this.geoFenceLayerMap.get(this.activeGeoFence), data)).finally(() => {
								const geoFence = this.geoFences.find(x => x.id === this.geoFenceLayerMap.get(this.activeGeoFence)?.id) as RetailGeoFence;
								geoFence.location_name = data.location_name;
								geoFence.location_type = data.location_type;
								this.updateGeoFences();
							});
						} else {
							this.activeGeoFence.setTooltipContent(data['location_name']);
							this.geoFenceLayerMap.get(this.activeGeoFence)!.location_name = data['location_name'];
							this.geoFenceLayerMap.get(this.activeGeoFence)!.location_type = data['location_type'];
						}
					}
				});
			});
		}
	}

	async rotate(degree) {
		super.rotate(degree);
		if (this.showTargets) {
			this.updateDots();
		}
		if (this.showHeatMap) {
			await this.updateHeatmap();
		}
		if (this.showGeoFences) {
			this.updateGeoFences();
		}
		if (this.showGeoFenceConversion || this.showHeatMap) {
			this.updateGeoFencesConversion();
		}
	}

	resizeGeoFence() {
		if (this.activeGeoFence) {
			this.hideEditGeoFenceToolbar();
			this.editMode = true;

			let featureGroup = this.geoFenceLayers.get(this.activeGeoFence);
			this.currentEditToolbar = (new L.EditToolbar.Edit(this.map as any, {
				featureGroup: featureGroup
			} as any) as any);
			this.currentEditToolbar.enable();
		}
	}

	deleteGeoFence() {
		if (this.activeGeoFence) {
			this.editMode = true;
			this.hideEditGeoFenceToolbar();
			this.modalService.confirm('REMOVE_GEO_FENCE_CONFIRM', {
				geo_fence_name: this.geoFenceLayerMap.get(this.activeGeoFence)?.location_name
			}).afterClosed().toPromise().then(res => {
				if (res) {
					if (this.mode === 'edit') {
						this.isLoading = true;
						let locationToDelete = this.geoFenceLayerMap.get(this.activeGeoFence);
						this.restService.deleteGeoFences([locationToDelete?.id as number]).finally(() => {
							this.geoFences.splice(this.geoFences.indexOf(this.geoFenceLayerMap.get(this.activeGeoFence) as RetailGeoFence), 1);
							this.updateGeoFences();
						});
					} else {
						this.geoFencesToAdd.splice(this.geoFencesToAdd.indexOf(this.geoFenceLayerMap.get(this.activeGeoFence) as RetailGeoFence), 1);
						this.geoFenceLayerMap.delete(this.activeGeoFence);
						this.geoFenceLayersGroup.removeLayer(this.activeGeoFence);
					}
				} else {
					this.editMode = false;
				}
			});
		}
	}

	cancelResizeGeoFence() {
		this.currentEditToolbar.revertLayers();
		this.currentEditToolbar.disable();
	}

	saveResizeGeoFence() {
		let intersection = this.getIntersection(this.currentEditToolbar.options.featureGroup.getLayers()[0] as L.Polygon);
		if (intersection) {
			this.hideResizeGeoFenceToolbar();
			return this.modalService.showError('GEO_FENCE_INTERSECTION_ERROR').afterClosed().toPromise().then(() => {
				this.showResizeGeoFenceToolbar();
			});
		}
		this.currentEditToolbar.save();
		this.currentEditToolbar.disable();
	}

	hideEditGeoFenceToolbar(exit = true) {
		this.renderer.setStyle(this.editToolbar.nativeElement, 'display', `none`);
		if (exit) {
			this.isEditToolbarDisplayed = false;
		}
	}

	onContainerClick() {
		let shouldCloseSubToolbar = !this.isClickEventPrevented && !this.currentRulerControl?._choice &&
			!this.currentNewFeoFenceControl?.enabled() && this.busEventService.showSubToolbar.value.open;

		if (shouldCloseSubToolbar) {
			this.busEventService.showSubToolbar.next({
				open: false,
				mode: this.busEventService.showSubToolbar.value.mode,
				type: this.busEventService.showSubToolbar.value.type,
				cancel: true
			});
		}
		this.isClickEventPrevented = false;
	}

	private removeOldDots() {
		this.currentDots.forEach(dot => {
			this.map.removeLayer(dot);
		});
	}

	private addNewDots() {
		if (this.map) {
			this.localData.locations?.filter(location => typeof location[0] === 'number' && typeof location[1] === 'number').forEach(location => {
				let b = rotateLeafletGeometry(
						this.rotationDegree,
						[location],
					),
					dot = L.marker(b[0], {
						icon: this.getDotIcon()
					});

				dot.addTo(this.map);
				this.currentDots.push(dot);
			});
		}
	}

	private updateDots() {
		this.ngZone.runOutsideAngular(() => {
			this.removeOldDots();
			this.addNewDots();
		});
	}

	private async updateHeatmap() {
		await Promise.resolve();
		await this.ngZone.runOutsideAngular(async () => {
			if (this.map && this.localData && this.localData.data) {
				if (this.heatmap) {
					this.map.removeLayer(this.heatmap);
				}
				// @ts-ignore
				this.heatmap = new HeatmapOverlay({
					// radius should be small ONLY if scaleRadius is true (or small radius is intended)
					// if scaleRadius is false it will be the constant radius used in pixels
					blur: 0,
					radius: 0.1,
					maxOpacity: .8,
					// scales the radius based on map zoom
					scaleRadius: true,
					// if set to false the heatmap uses the global maximum for colorization
					// if activated: uses the data maximum within the current map boundaries
					//   (there will always be a red spot with useLocalExtremas true)
					useLocalExtrema: false,
					// which field name in your data represents the latitude - default "lat"
					latField: 'lat',
					// which field name in your data represents the longitude - default "lng"
					lngField: 'lng',
					// which field name in your data represents the data value - default "value"
					valueField: 'count',
					backgroundColor: 'rgba(0,0,255, .1)',
					gradient: {
						0.25: 'rgb(0,0,255)',
						0.55: 'rgb(0,255,0)',
						0.85: 'rgb(255, 255, 0)',
						0.94: 'rgb(234,69,101)',
						0.95: 'rgb(234,50,86)',
						0.96: 'rgb(217,33,69)',
						0.97: 'rgb(216,28,65)',
						0.98: 'rgb(227,20,61)',
						0.99: 'rgb(246,7,7)',
						1.0: 'rgb(255,0,0)'
					}
				}).addTo(this.map);

				this.heatmap.setData({
					min: getMin(this.localData.data.map(d => d[0])),
					max: getMax(this.localData.data.map(d => d[0])),
					data: this.localData.data.map(d => {
						let b = rotateLeafletGeometry(
							this.rotationDegree,
							[
								[d[1], d[2]],
								[d[1], d[2]]
							]
						);
						return {lat: b[0][0], lng: b[0][1], count: d[0]};
					})
				});
			}
		});
	}

	private getDotIcon() {
		let size = this.map.getZoom() * 2;
		return L.divIcon({
			iconSize: [size, size],
			className: 'red-dot'
		});
	}

	private async updateGeoFences() {
		this.editMode = false;
		this.isLoading = false;
		await Promise.resolve();
		this.ngZone.runOutsideAngular(() => {
			this.geoFenceLayersGroup.clearLayers();
			this.tooltipsLayersGroup.clearLayers();
			this.geoFences.forEach(l => {
				let geoFence = makeDeepCopy(l),
					bounds = rotateLeafletGeometry(
						this.rotationDegree,
						geoFence.geometry
					),
					leafletGeoFence = L.polygon(bounds);

				if (!this.isGeoFenceEditable) {
					let tooltip = new L.Tooltip({
						permanent: true,
						direction: 'center',
						className: 'geo-fence-name'
					})
						.setContent(l.location_name)
						.setLatLng(leafletGeoFence.getBounds().getNorthEast()) // topRight
						.addTo(this.tooltipsLayersGroup);
					if (this.conversionLabelMap[l.id as number]) {
						this.tooltipsLayersGroup.removeLayer(this.conversionLabelMap[l.id as number]);
					}
					this.conversionLabelMap[l.id as number] = tooltip;
				}
				this.afterGeoFenceCreated(leafletGeoFence, geoFence.location_name);
				this.geoFenceLayerMap.set(leafletGeoFence, geoFence);
				this.layerGeoFenceMap[geoFence.id as number] = leafletGeoFence;
			});
			if (this.showGeoFenceConversion || this.showHeatMap) {
				this.updateGeoFencesConversion();
			}
		});
	}

	private showEditGeoFenceToolbar(geoFence: L.Polygon) {
		let topRightCorner = this.getTopRightPoint(geoFence),
			{x, y} = this.map.latLngToContainerPoint(topRightCorner);

		this.renderer.setStyle(this.editToolbar.nativeElement, 'top', `${y - 23}px`);
		this.renderer.setStyle(this.editToolbar.nativeElement, 'left', `${x - 67}px`);
		this.renderer.setStyle(this.editToolbar.nativeElement, 'display', `block`);
		this.isEditToolbarDisplayed = true;
	}

	private showResizeGeoFenceToolbar() {
		let topRightCorner = this.getTopRightPoint(this.activeGeoFence),
			{x, y} = this.map.latLngToContainerPoint(topRightCorner);

		this.renderer.setStyle(this.resizeToolbar.nativeElement, 'top', `${y - 23}px`);
		this.renderer.setStyle(this.resizeToolbar.nativeElement, 'left', `${x - 67}px`);
		this.renderer.setStyle(this.resizeToolbar.nativeElement, 'display', `block`);
	}

	private hideResizeGeoFenceToolbar() {
		this.renderer.setStyle(this.resizeToolbar.nativeElement, 'display', `none`);
	}

	/**
	 * Get layer's intersection with existed layers.
	 * @param layer
	 * @private
	 */
	private getIntersection(layer: L.Polygon) {
		let z1 = (layer.getLatLngs()[0] as LatLng[]).map(l => [l.lng, l.lat]);
		let p1 = Turf.polygon([z1.concat([z1[0]])]),
			intersection = this.geoFenceLayersGroup.getLayers().filter(l => l !== layer).find(l => {
				if (l instanceof L.FeatureGroup) {
					return l.getLayers().filter(j => j !== layer).find((v: L.Rectangle) => {
						let t1 = (v.getLatLngs()[0] as LatLng[]).map(k => [k.lng, k.lat]);
						let p2 = Turf.polygon([t1.concat([t1[0]])]);
						return Turf.intersect(p1, p2);
					});
				} else {
					let t2 = ((l as L.Rectangle).getLatLngs()[0] as LatLng[]).map(k => [k.lng, k.lat]);
					let p2 = Turf.polygon([t2.concat([t2[0]])]);
					return Turf.intersect(p1, p2);
				}
			});

		return intersection;
	}

	private exitNewGeofenceMode() {
		if (this.currentNewFeoFenceControl) {
			this.currentNewFeoFenceControl.disable();
		}
	}

	private exitFloorDimensionMode() {
		if (this.currentRulerControl) {
			this.currentRulerControl._escape({keyCode: 27});
			this.currentRulerControl._escape({keyCode: 27});
			this.currentRulerControl.onRemove();
		}
	}

	/**
	 * Rescale existed geo fences after user defined new floor dimensions
	 * @private
	 */
	private updateGeoFencesSizes(coefficient: number) {
		let cb = layer => {
			let latLngs = layer.getLatLngs();
			latLngs[0].forEach(latLng => {
				latLng.lat *= coefficient;
				latLng.lng *= coefficient;
			});
			layer.setLatLngs(latLngs);
			if ((this.geoFenceLayerMap.get(layer) as RetailGeoFence)) {
				(this.geoFenceLayerMap.get(layer) as RetailGeoFence).geometry.forEach(v => {
					v[0] *= coefficient;
					v[1] *= coefficient;
				});
			}
		};
		this.geoFenceLayersGroup.getLayers().forEach(layer => {
			if (layer instanceof L.FeatureGroup) {
				layer.getLayers().forEach(l => {
					cb(l);
				});
			} else {
				cb(layer);
			}
		});
	}

	private registerMapGeoFenceEvents() {
		this.map.on('leaflet-ruler-draw-click', (e: any) => {
			if (e.points.length === 2) {
				this.hideEditGeoFenceToolbar();
				this.exitFloorDimensionMode();
				let message = 'SPECIFY_DRAWN_DISTANCE_IN_METERS_PROMPT',
					title = 'DEFINE_FLOOR_DIMENSIONS';

				this.modalService.prompt(message, {}, title).afterClosed().toPromise().then(res => {
					if (res) {
						let [, , width, height] = this.imageOverlay.getBounds().toBBoxString().split(',').map(v => +v),
							coefficient = res / e.result.Distance;

						this.busEventService.newFloorDimensions.next({
							max_x: width * coefficient,
							max_y: height * coefficient
						});

						this.updateGeoFencesSizes(coefficient);
					}
				});
			}
		});
		this.map.on(L.Draw.Event.EDITRESIZE, () => {
			this.isClickEventPrevented = true;
			this.showResizeGeoFenceToolbar();
		});
		this.map.on(L.Draw.Event.EDITMOVE, () => {
			this.isClickEventPrevented = true;
			this.showResizeGeoFenceToolbar();
		});
		this.map.on(L.Draw.Event.EDITSTART, () => {
			this.showResizeGeoFenceToolbar();
		});
		this.map.on(L.Draw.Event.EDITSTOP, () => {
			this.editMode = false;
			this.hideResizeGeoFenceToolbar();
		});
		this.map.on(L.Draw.Event.DELETESTART, () => {
			this.editMode = true;
		});
		this.map.on(L.Draw.Event.DELETESTOP, () => {
			this.editMode = false;
		});
		this.map.on('zoomstart', () => {
			this.hideEditGeoFenceToolbar(!this.isEditToolbarDisplayed);
		});
		this.map.on('zoomend', () => {
			if (this.isEditToolbarDisplayed) {
				this.showEditGeoFenceToolbar(this.activeGeoFence);
			}
		});
		this.map.on(L.Draw.Event.EDITED, (e: L.DrawEvents.Edited) => {
			if (e.layers.getLayers().length) {
				if (this.mode === 'edit') {
					this.isLoading = true;
					let requests = e.layers.getLayers().map((layer: L.Polygon) => {
						const geometry = this.prepareGeoFenceToSave(layer);
						return this.restService.updateGeoFence(Object.assign({}, this.geoFenceLayerMap.get(layer), {
							geometry
						})).then(() => {
							const geoFence = this.geoFences.find(x => x.id === this.geoFenceLayerMap.get(this.activeGeoFence)?.id) as RetailGeoFence;
							geoFence.geometry = geometry;
						});
					});
					Promise.all(requests).finally(() => {
						this.isLoading = false;
						this.changeDetectorRef.detectChanges();
						this.updateGeoFences();
					});
				} else {
					e.layers.getLayers().forEach((layer: L.Polygon) => {
						const geometry = this.prepareGeoFenceToSave(layer);
						this.geoFenceLayerMap.get(layer)!.geometry = geometry as RetailGeoFenceGeometry;
					});
				}
			}
		});

		this.restService.getGeoFencesTypes().then(types => {
			this.retailGeoFenceTypes = types;
			this.map.on(L.Draw.Event.CREATED, (e: L.DrawEvents.Created) => {
				this.isClickEventPrevented = true;
				let intersection = this.getIntersection(e.layer as L.Polygon);
				if (intersection) {
					this.hideEditGeoFenceToolbar();
					return this.modalService.showError('GEO_FENCE_INTERSECTION_ERROR');
				}
				this.geoFenceLayersGroup.addLayer(e.layer);
				let promise;
				if (this.mode === 'edit') {
					promise = this.restService.getGeoFences(this.toolbarService.floorPlanFiltersForm.getRawValue().floor_id);
				} else {
					promise = Promise.resolve([]);
				}
				promise.then(geoFences => {
					this.modalService.floorPlanEditGeoFence('Add a geo-fence', {
						mode: 'add',
						location_type_ids: types,
						geoFences
					}).afterClosed().toPromise().then(data => {
						if (data) {
							e.layer.bindTooltip(data.location_name, {direction: 'center'});
							const geometry = this.prepareGeoFenceToSave(e.layer as L.Polygon);
							let geoFence = {
								location_name: data.location_name,
								geometry: geometry as RetailGeoFenceGeometry,
								parent_floor: this.toolbarService.floorPlanFiltersForm.getRawValue().floor_id,
								location_type: data.location_type
							};
							if (this.mode === 'edit') {
								this.isLoading = true;
								this.restService.addGeoFence(geoFence).finally(() => {
									this.busEventService.reloadGeoFences.emit();
								});
							} else {
								this.geoFencesToAdd.push(geoFence);
								this.geoFenceLayerMap.set((e.layer as L.Polygon), geoFence);
								this.afterGeoFenceCreated(e.layer, data.location_name);
							}
						} else {
							this.geoFenceLayersGroup.removeLayer(e.layer);
						}
					});
				});
			});
		});
	}

	private prepareGeoFenceToSave(layer: L.Polygon): RetailGeoFenceGeometry {
		const rotatedGeometry = rotateLeafletGeometry((360 - this.rotationDegree) % 360,
			// @ts-ignore
			layer.getLatLngs()[0].map(v => [v.lat, v.lng])
		);
		rotatedGeometry.push(rotatedGeometry[0]);
		return rotatedGeometry as RetailGeoFenceGeometry;
	}

	private subscribeToGlobalEvents() {

	}

	/**
	 * Upload created geo fences after new floor was successfully added.
	 * @param floorId
	 * @private
	 */
	private uploadNewGeoFences(floorId) {
		if (this.geoFencesToAdd.length) {
			let promises = Array.from(this.geoFenceLayerMap.values()).map(geoFence => {
				return this.restService.addGeoFence(Object.assign(geoFence, {
					parent_floor: floorId
				}));
			});

			Promise.all(promises).finally(() => {
				this.restService.clearCache(['geoFences']);
				this.busEventService.reloadGeoFences.emit();
				this.geoFencesToAdd = [];
			});

			this.busEventService.showSubToolbar.next({open: false, mode: this.busEventService.showSubToolbar.value.mode});
		} else {
			let promises: any = [],
				cb = layer => {
					let updatedGeoFence = (this.geoFenceLayerMap.get(layer) as RetailGeoFence),
						geoFence = this.geoFences.find(l => l.id === updatedGeoFence.id) as RetailGeoFence,
						geometryChanged = JSON.stringify(updatedGeoFence.geometry) !== JSON.stringify(geoFence.geometry);

					if (geometryChanged) {
						(this.geoFences.find(l => l.id === updatedGeoFence.id) as RetailGeoFence).geometry = updatedGeoFence.geometry;
						promises.push(this.restService.updateGeoFence(updatedGeoFence));
					}
				};
			this.geoFenceLayersGroup.getLayers().forEach(layer => {
				if (layer instanceof L.FeatureGroup) {
					layer.getLayers().forEach(l => {
						cb(l);
					});
				} else {
					cb(layer);
				}
			});

			if (promises.length) {
				Promise.all(promises).finally(() => {
					this.restService.clearCache(['geoFences']);
					this.busEventService.reloadGeoFences.emit();
				}).finally(() => {
					this.busEventService.showSubToolbar.next({open: false, mode: this.busEventService.showSubToolbar.value.mode});
				});
			} else {
				this.busEventService.showSubToolbar.next({open: false, mode: this.busEventService.showSubToolbar.value.mode});
			}
		}
	}


	private afterGeoFenceCreated(leafletGeoFence, geoFenceName) {
		let group = new L.FeatureGroup();
		group.addLayer(leafletGeoFence);
		this.geoFenceLayersGroup.addLayer(group);
		this.geoFenceLayers.set(leafletGeoFence, group);
		if (this.isGeoFenceEditable) {
			let tooltip = new L.Tooltip({direction: 'center'}).setContent(geoFenceName);
			leafletGeoFence.bindTooltip(tooltip);
			leafletGeoFence.on('tooltipopen', () => {
				this.resizeGeoFenceTooltip(tooltip, leafletGeoFence);
			});
			this.map?.on('zoomend', () => {
				if (tooltip.getElement()) {
					this.resizeGeoFenceTooltip(tooltip, leafletGeoFence);
				}
			});
			leafletGeoFence.on('mousemove', () => {
				if (!this.editMode) {
					this.activeGeoFence = leafletGeoFence;
					this.showEditGeoFenceToolbar(leafletGeoFence);
				}
			});
			leafletGeoFence.on('mouseout', (e: LeafletMouseEvent) => {
				let parentNode = (e.originalEvent.relatedTarget as Element)?.parentNode;
				if (!leafletGeoFence.getBounds().contains(e.latlng) && parentNode !== this.editToolbar.nativeElement) {
					this.hideEditGeoFenceToolbar();
				}
			});
		}
	}

	private updateGeoFencesConversion() {
		if (this.map) {
			this.ngZone.runOutsideAngular(() => {
				setTimeout(() => {
					let {fontSize: baseFontSize, padding: basePadding} = this.getLabelFontSizeAndPadding();

					Object.values(this.layerGeoFenceMap).forEach(layer => {
						let startPoint = this.map.latLngToContainerPoint(this.getNearPoint(layer, LatLngType.NorthWest)),
							endPoint = this.map.latLngToContainerPoint(this.getNearPoint(layer, LatLngType.SouthEast)),
							center;

						let fontSize = baseFontSize,
							padding = basePadding,
							geoFenceId = this.geoFenceLayerMap.get(layer)!.id as number;

						layer.unbindTooltip();
						if (this.localData.data && !this.showHeatMap) {
							try {
								center = this.map.latLngToContainerPoint(layer.getCenter());
							} catch (e) {

							}
							if (!center) {
								return;
							}
							let v = this.localData.data.find(d => d.geo_fence_id === geoFenceId),
								tooltip = new L.Tooltip({
									offset: [startPoint.x - center.x, startPoint.y - center.y],
									permanent: true,
									direction: 'right',
									className: 'conversion-tooltip'
								});
							if (v) {
								layer.bindTooltip(tooltip).openTooltip();
								let tooltipElement = tooltip.getElement() as HTMLElement;
								tooltip.setContent(`${v.value}%<div class="divider" style="margin-left: ${padding}px; padding-left: ${padding}px;">${v.num_of_people} <span class="material-icons people-icon">people</span></div>`);
								if (tooltipElement) {
									tooltipElement.style.fontSize = fontSize + 'px';
									tooltipElement.style.padding = padding + 'px';
									if (tooltipElement.clientWidth > (endPoint.x - startPoint.x) / 2) {
										fontSize = fontSize * Math.abs(((endPoint.x - startPoint.x) / 2) / tooltipElement.clientWidth);
										tooltipElement.style.fontSize = fontSize + 'px';
										padding = padding * Math.abs(((endPoint.x - startPoint.x) / 2) / tooltipElement.clientWidth);
										tooltipElement.style.padding = padding + 'px';
									}
									(tooltipElement.querySelector('.divider') as HTMLElement).style.marginLeft = padding + 'px';
									(tooltipElement.querySelector('.divider') as HTMLElement).style.paddingLeft = padding + 'px';
								}
							}
						}
						let labelTooltip = this.conversionLabelMap[geoFenceId],
							labelTooltipElement = labelTooltip.getElement() as HTMLElement;

						if (labelTooltip && labelTooltipElement) {
							labelTooltipElement.style.fontSize = fontSize / 1.5 + 'px';
							labelTooltipElement.style.padding = padding / 2 + 'px';

							if (labelTooltipElement.clientWidth > (endPoint.x - startPoint.x) / 2) {
								fontSize = fontSize * Math.abs(((endPoint.x - startPoint.x) / 2) / labelTooltipElement.clientWidth) / 1.5;
								labelTooltipElement.style.fontSize = fontSize + 'px';
								padding = padding * Math.abs(((endPoint.x - startPoint.x) / 2) / labelTooltipElement.clientWidth) / 2;
								labelTooltipElement.style.padding = padding + 'px';
							}

							let topRight: LatLng = this.getTopRightPoint(layer);

							labelTooltip.setLatLng([topRight.lat, topRight.lng]);
							labelTooltipElement.style.marginLeft = labelTooltipElement.clientWidth / -2 + 'px';
							labelTooltipElement.style.marginTop = labelTooltipElement.clientHeight / -2 + 'px';
						}
					});
				});
			});
		}
	}

	private getLabelFontSizeAndPadding() {
		let {globalRoomWidth} = this.parameters['socketInfo'];
		let zoomLevel = this.map.getZoom(),
			fontSizeByZoom = {
				20: 180,
				19: 170,
				18: 150,
				17: 120,
				16: 100,
				15: 90,
				14: 80,
				13: 70,
				12: 60,
				11: 50,
				10: 40,
				9: 30,
				8: 15,
				7: 10,
				6: 8,
				5: 6,
				4: 4,
				3: 2,
				2: 1,
				1: 1,
				0: 1,
			},
			fact = 3.6,
			fontSize = fontSizeByZoom[zoomLevel] * fact * globalRoomWidth / 10,
			padding = fontSize / 2;

		return {fontSize, padding};
	}

	private resizeGeoFenceTooltip(tooltip: L.Tooltip, layer: L.Polygon) {
		let tooltipElement = tooltip.getElement(),
			{fontSize, padding} = this.getLabelFontSizeAndPadding();

		if (tooltipElement) {
			tooltipElement.style.fontSize = fontSize + 'px';
			tooltipElement.style.padding = padding + 'px';

			let startPoint = this.map.latLngToContainerPoint(this.getNearPoint(layer, LatLngType.NorthWest)),
				endPoint = this.map.latLngToContainerPoint(this.getNearPoint(layer, LatLngType.SouthEast));

			if (tooltipElement.clientWidth > (endPoint.x - startPoint.x)) {
				fontSize = fontSize * Math.abs(((endPoint.x - startPoint.x - padding * 2)) / tooltipElement.clientWidth);
				tooltipElement.style.fontSize = fontSize + 'px';
				padding = padding * Math.abs(((endPoint.x - startPoint.x - padding * 2)) / tooltipElement.clientWidth);
				tooltipElement.style.padding = padding + 'px';

				let leftBottom = this.getNearPoint(layer, LatLngType.NorthWest),
					topRight = this.getNearPoint(layer, LatLngType.SouthEast),
					center: LatLngTuple = [leftBottom.lat + (topRight.lat - leftBottom.lat) / 2, leftBottom.lng + (topRight.lng - leftBottom.lng) / 2];

				tooltip.setLatLng(center);
			}
		}
	}

	private getTopRightPoint(layer: L.Polygon) {
		return this.getNearPoint(layer, LatLngType.NorthEast);
	}

	private getNearPoint(layer: L.Polygon,  latLngType: LatLngType) {
		let method;
		switch (latLngType) {
			case LatLngType.NorthEast:
				method = 'getNorthEast';
				break;
			case LatLngType.SouthEast:
				method = 'getSouthEast';
				break;
			case LatLngType.SouthWest:
				method = 'getSouthWest';
				break;
			case LatLngType.NorthWest:
				method = 'getNorthWest';
				break;
		}
		let points = ([] as any[]).concat(layer.getLatLngs()[0]).sort((a, b) => a.distanceTo(layer.getBounds()[method]()) - b.distanceTo(layer.getBounds()[method]()));

		return points[0];
	}
}
