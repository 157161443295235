import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as L from 'leaflet';
import {BusEventService} from '../../../../../services/bus-event.service';
import {SettingsService} from '../../../../../services/settings.service';
import originIcon from '!raw-loader!../../../../../../assets/images/svg/system/add.svg';
import {FormControl, FormGroup} from '@angular/forms';
import {ModalService} from '../../../../../services/modal.service';
import {LatLngBounds} from 'leaflet';
import {SensorSocket} from '../../../../../services/system/sensor-socket';
import {MultiSensorsSocket} from '../../../../../services/system/multi-sensors-socket';
import {IConfigurableTabControl} from '../../../../../services/configuration.service';

require('../../../../../../assets/js/leaflet-ruler');
require('../../../../../../assets/js/leaflet.latlng-graticule');

@Component({
	selector: 'app-background-image-control',
	templateUrl: './background-image-control.component.html',
	styleUrls: ['./background-image-control.component.scss']
})
export class BackgroundImageControlComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() settingForm: FormGroup;
	@Input() sensorSocket: SensorSocket | MultiSensorsSocket;
	@Input() control: IConfigurableTabControl;

	@ViewChild('map') mapElement: ElementRef;

	roomScaled = false;
	scale;
	distance;

	showModalDistanceDialog = false;
	showErrorDialog = false;
	errorMessage = '';

	private map: L.Map;
	private imageOverlay: L.ImageOverlay;
	private origin: L.Marker;
	private currentRulerControl;

	constructor(private ngZone: NgZone,
				private busEventService: BusEventService,
				private settingsService: SettingsService,
				private modalService: ModalService,
				private changeDetectorRef: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.settingForm.addControl('width', new FormControl());
		this.settingForm.addControl('height', new FormControl());
		this.settingForm.addControl('backgroundImage', new FormControl());
		this.settingForm.addControl('origin', new FormControl());
	}

	async ngAfterViewInit() {
		await Promise.resolve();
		this.ngZone.runOutsideAngular(() => {
			this.map = L.map(this.mapElement.nativeElement, {
				attributionControl: false,
				zoom: 1,
				center: [0, 0],
				minZoom: -1,
				crs: L.CRS.Simple,
				zoomControl: false
			} as any);
			L.latlngGraticule({
				showLabel: true,
				dashArray: [2, 2],
				sides: ['', '-', '', '-']
			}).addTo(this.map);
		});
		this.map.on('leaflet-ruler-draw-click', (e: any) => {
			if (e.points.length === 2) {
				if (this.imageOverlay.getBounds().contains(e.points)) {
					this.distance = e.result.Distance;
					this.showModalDistanceDialog = true;
					this.exitFloorDimensionMode();
				} else {
					this.showError('FLOOR_DIMENSIONS_OUTSIDE_ERROR');
					this.startRoomDimension();
				}
				this.changeDetectorRef.detectChanges();
			}
		});
		this.settingsService.getSocketInfo(this.sensorSocket.url).then(info => {
			if (info['backgroundImage']) {
				this.settingForm.controls.width.setValue(info['width']);
				this.settingForm.controls.height.setValue(info['height']);
				this.settingForm.controls.backgroundImage.setValue(info['backgroundImage']);
				this.settingForm.controls.origin.setValue(info['origin']);
				this.updateImageOverlay();
			}
		});
	}

	ngOnDestroy() {
		this.exitFloorDimensionMode();
	}

	onSelectFile(event: Event) {
		if ((<HTMLInputElement>event.target).files?.length) {
			this.processUploadedFile((<HTMLInputElement>event.target).files![0]).then(() => {
				(<HTMLInputElement>event.target).value = '';
			});
		}
	}

	startRoomDimension() {
		this.exitFloorDimensionMode();
		this.currentRulerControl = L.control['ruler']({
			position: 'topleft',
			lengthUnit: {
				factor: 1,
				display: 'meters',
				decimal: 1,
				label: 'Distance:'
			}
		});
		this.currentRulerControl.onAdd(this.map);
		this.currentRulerControl._toggleMeasure();
	}

	scaleRoom() {
		let [x1, y1, x2, y2] = this.imageOverlay.getBounds().toBBoxString().split(',').map(v => +v),
			width = x2 - x1,
			height = y2 - y1;
		let coefficient = this.scale / this.distance;
		this.settingForm.controls.width.setValue(width * coefficient);
		this.settingForm.controls.height.setValue(height * coefficient);
		this.settingForm.controls.origin.setValue(this.settingForm.controls.origin.value.map(v => v * coefficient));
		this.showModalDistanceDialog = false;
		this.changeDetectorRef.detectChanges();
		this.updateImageOverlay();
	}

	onKeyDown(e) {
		e.stopPropagation();
		e.preventDefault();
		e.stopImmediatePropagation();
		if (this.scale) {
			this.scaleRoom();
		}
		return false;
	}

	closeScale() {
		this.showModalDistanceDialog = false;
		this.changeDetectorRef.detectChanges();
	}

	closeError() {
		this.showErrorDialog = false;
		this.changeDetectorRef.detectChanges();
	}

	showError(errorMessage: string) {
		this.errorMessage = errorMessage;
		this.showErrorDialog = true;
		this.changeDetectorRef.detectChanges();
	}

	deleteFloorPlan() {
		if (this.settingForm.controls.backgroundImage.value) {
			this.settingForm.controls.backgroundImage.setValue(null);
			this.updateImageOverlay();
		}
	}

	private exitFloorDimensionMode() {
		if (this.currentRulerControl) {
			this.currentRulerControl._escape({keyCode: 27});
			this.currentRulerControl._escape({keyCode: 27});
			this.currentRulerControl.onRemove();
		}
	}

	private processUploadedFile(file: File) {
		return new Promise(resolve => {
			let fileReader = new FileReader();

			fileReader.onload = async (e: Event) => {
				this.roomScaled = false;
				this.scale = undefined;
				let img = new Image();
				img.onload = () => {
					let height = 1,
						width = img.naturalWidth / img.naturalHeight;
					this.settingForm.controls.backgroundImage.setValue(e.target!['result']);
					this.settingForm.controls.width.setValue(width);
					this.settingForm.controls.height.setValue(height);
					this.settingForm.controls.origin.setValue([0, 0]);
					this.updateImageOverlay();
					resolve();
				};
				img.src = e.target!['result'];
			};

			fileReader.readAsDataURL(file);
		});
	}

	private updateImageOverlay() {
		if (this.imageOverlay) {
			this.map.removeLayer(this.imageOverlay);
		}
		let origin = this.settingForm.controls.origin.value;
		let bounds: L.LatLngBoundsLiteral = [[0, 0], [this.settingForm.controls.height.value, this.settingForm.controls.width.value]].map(t => {
			return [t[0] - origin[0], t[1] - origin[1]];
		});
		this.map.fitBounds(bounds);
		if (this.settingForm.controls.backgroundImage.value) {
			this.imageOverlay = L.imageOverlay(this.settingForm.controls.backgroundImage.value, bounds).addTo(this.map);
		}
		this.updateOrigin();
	}

	private updateOrigin() {
		if (this.origin) {
			this.map.removeLayer(this.origin);
		}
		let iconSVG = originIcon.replace(new RegExp('#e9effb', 'gmi'), 'red');
		let icon = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(iconSVG)))}`;
		this.origin = L.marker([0, 0], {
			icon: L.icon({
				iconUrl: icon,
				iconSize: [20, 20]
			}),
			draggable: true
		}).addTo(this.map);
		this.origin.on('dragend',  () => {
			let currentOrigin = this.settingForm.controls.origin.value,
				origin = [this.origin.getLatLng().lat + currentOrigin[0], this.origin.getLatLng().lng + currentOrigin[1]];
			this.settingForm.controls.origin.setValue(origin);
			this.origin.setLatLng([0, 0]);

			let bounds: L.LatLngBoundsLiteral = [[0, 0], [this.settingForm.controls.height.value, this.settingForm.controls.width.value]].map(t => {
				return [t[0] - origin[0], t[1] - origin[1]];
			});
			this.imageOverlay.setBounds(new L.LatLngBounds(bounds));
			this.map.fitBounds(bounds);
		});
		let lastValidPosition;
		this.origin.on('drag', () => {
			var latLng = this.origin.getLatLng();
			if (this.imageOverlay.getBounds().contains(latLng)) {
				lastValidPosition = latLng;
			} else {
				if (lastValidPosition) {
					this.origin.setLatLng(lastValidPosition);
				}
			}
		}, this.origin);
	}
}
