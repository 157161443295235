import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ModalMessageComponent} from './modal-message.component';

/**
 * Component represents as an error modal window
 */
@Component({
	selector: 'app-error-message',
	templateUrl: './modal-message.component.html',
	styleUrls: ['./modal-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalErrorComponent extends ModalMessageComponent implements OnInit {

}
