import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe converts '\n' to '<br>'
 */
@Pipe({
  name: 'htmlNewLine'
})
export class HtmlNewLinePipe implements PipeTransform {

  transform(newLine: any, args?: any): any {
    return newLine.replace(new RegExp('\n', 'g'), '<br>').replace(new RegExp('\\n', 'g'), '<br>');
  }

}
