<div class="sidebar d-flex flex-column">
	<div class="logo full-logo">
		<img src="assets/images/VayyarNewLogo-01.png" alt="">
	</div>
	<nav>
		<div>
			<ng-container *ngFor="let page of configurationService.pages">
				<ng-container *ngTemplateOutlet="buttonList;context:{page: page}" ></ng-container>
			</ng-container>
		</div>
	</nav>
	<div class="d-flex notifications-container">
		<ng-container *ngFor="let control of configurationService.pageMap[this.toolbarService.getRoute().data.pageName]?.controls">
			<!-- TODO refator rightSideControls -->
			<ng-container *ngIf="control.enable">
				<ng-container [ngSwitch]="control.type">
					<ng-container *ngSwitchCase="ConfigurablePageControlType.PAGE_FRAME_NUMBER_DISPLAY">
						<fieldset *ngIf="[runMode?.REPROCESS_IQ_DATA, runMode?.REPROCESS_AND_RECORD].includes(sensorSocket?.recordingInfo.mode)" class="show-frame-container" [attr.data-selector]="control.type + '-button'" [matTooltip]="control.tooltip">
							<legend>Current frame</legend>
							{{parameters[control.param] || ''}}
						</fieldset>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
		<div class="status-block status-block__reprocessing w-100 align-self-end" *ngIf="sensorSocket?.recordingInfo.mode === runMode?.REPROCESS_IQ_DATA">
			<span class="status-block__heading fw-bold">{{ 'REPROCESSING' | translate }}</span>
		</div>
		<div class="status-block status-block__recording w-100 align-self-end" *ngIf="sensorSocket?.recordingInfo.isRecording()">
				<div class="d-flex justify-content-center align-items-center">
						<span class="status-block__heading status-block__heading-rec fw-bold mr-2">{{ 'RECORDING' | translate }}</span>
						<span class="status-block__iconRec fw-bold fs-10 d-flex align-items-center" [ngClass]="{'red': sensorSocket?.recordingInfo.timerIsStarted && isRecording, 'no-icon': !isRecording && isRecording !== null, 'accent': isRecording === null}">REC</span>
				</div>
				<p class="recording-time fs-21">{{ sensorSocket?.recordingInfo.durationInMs | duration:sensorSocket?.recordingInfo.durationInMs > 3599999 }}</p>
		</div>
		<div class="status-block status-block__playback w-100 align-self-end" *ngIf="sensorSocket?.recordingInfo.mode === runMode?.PLAYBACK">
				<span class="status-block__heading fw-bold">{{ 'PLAYBACK' | translate }}</span>
		</div>
	</div>
	<span class="app-version">v{{appVersion}}</span>
</div>

<ng-template #buttonList let-page="page">
	<button matRipple [matRippleColor]="rippleColor" *ngIf="page.icon.startsWith('svg_'); else materialButton" [attr.data-selector]="page.name" routerLinkActive="active" [routerLink]="page.url"
			class="icon menu-button">
		<img [src]="'assets/images/svg/' + page.icon.replace('svg_', '') + (router.isActive(page.url, false) ? '-selected' : '') + '.svg'">
		{{page.displayName | translate}}
	</button>
	<ng-template #materialButton>
		<button routerLinkActive="active" [routerLink]="page.url">
			<span class="material-icons-outlined md-24" [innerHTML]="page.icon || defaultPageIcon | materialIcon"></span>
			{{page.displayName | translate}}
		</button>
	</ng-template>
</ng-template>
