import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {SettingsService} from '../../../services/settings.service';
import {StorageService} from '../../../services/storage.service';
import {SensorsService} from '../../../services/system/sensors.service';
import {Router} from '@angular/router';
import {SensorSocket} from '../../../services/system/sensor-socket';
import {environment} from '../../../../environments/environment';
import {ConnectionData} from '../../../models/models';

/**
 * Component allows to connect to any sensor, add and delete sensor in small popup.
 * Contains list of recently connected sensors (except analytics) and field for new ip connection.
 * It is called by sensor connector in the right top.
 */
@Component({
	selector: 'app-connection-list',
	templateUrl: './connection-list.component.html',
	styleUrls: ['./connection-list.component.scss']
})
export class ConnectionListComponent implements OnInit {

	@Input() sensorSocket: SensorSocket;
	@Input() showSocketList = false;
	@Output() close = new EventEmitter();
	@Output() update = new EventEmitter();
	@Output() connect = new EventEmitter<ConnectionData>();

	newIp = '';
	inModal = false;
	ipList: Array<any> = [];
	existingRoomsNames: Array<string> = [];

	constructor(private sensorsService: SensorsService,
				private router: Router,
				private storageService: StorageService,
				private settingsService: SettingsService,
				private modalService: ModalService) {

	}

	ngOnInit() {
		this.open();
	}


	getActionText(ip) {
		var text = 'Connect';

		if (ip.connected) {
			text = 'Disconnect';
		}

		return text;
	}

	connectToNewIp() {
		if (this.newIp) {
			this.connect.emit({
				url: this.newIp.trim(),
				autoConnect: true
			});
			this.onClose();
		}
	}


	open() {
		if (this.showSocketList) {
			this.loadSocketPool();
		}
	}


	onClose() {
		this.newIp = '';
		this.close.emit();
	}


	enterQuickEditIp(ip, input) {
		ip.visibleField = ip.name;
		ip.isQuickEditIp = true;
		setTimeout(() => {
			input.focus();
		});
	}


	exitQuickEditIp(ip, input, e) {
		if (this.existingRoomsNames.includes(input.getInputValue()) && input.getInputValue() !== ip.name) {
			this.modalService.showError('NAME_ALREADY_IN_USE_MESSAGE').afterClosed().toPromise().then(() => {
				input.focus();
			});
			e.isPrevented = true;
			return false;
		} else {
			this.saveIp(ip, input.getInputValue());
		}
	}


	saveIp(ip, name) {
		if (this.existingRoomsNames.includes(name) && name !== ip.name) {
			this.modalService.showError('NAME_ALREADY_IN_USE_MESSAGE');
			return false;
		}
		ip.visibleField = name.length ? name : this.prepareUrl(ip.url);
		this.settingsService.getSocketInfo(ip.url).then(info => {
			if (name.length) {
				info['name'] = name;
				ip.name = name;
			} else {
				delete info['name'];
				delete ip.name;
			}
			this.settingsService.saveSocketInfo(ip.url, info).then(() => {
				ip.initVisibleField = ip.visibleField;
				ip.isQuickEditIp = false;
				this.update.emit();
			});
		});
	}


	onNewIpKeyUp(e: KeyboardEvent) {
		switch (e.key) {
			case 'Enter':
				this.connectToNewIp();
				break;
		}
	}


	onEditIpKeyUp(e: KeyboardEvent, ip, value) {
		switch (e.key) {
			case 'Enter':
				this.saveIp(ip, value);
				break;
		}
	}

	goTo(ip) {
		this.connect.emit({
			url: ip.url,
			autoConnect: true
		});
		if (!this.inModal) {
			this.onClose();
		}
	}

	onClickActionBtn(ip) {
		if(!ip.isQuickEditIp) {
			let sensorSocket = this.sensorsService.getSensorSocket(ip.url);
			if(sensorSocket && sensorSocket.canDisconnect()) {
				this.sensorsService.disconnectSocket(sensorSocket);
				this.loadSocketPool();
			} else {
				this.connect.emit({
					url: ip.url,
					autoConnect: true
				});
				if (!this.inModal) {
					this.onClose();
				}
			}
		}
	}


	removeIp(ip) {
		var sensorSocket = this.sensorsService.getSensorSocket(ip.url),
			canDiconnect = sensorSocket && sensorSocket.canDisconnect(),
			message = 'Are you sure you want to remove this sensor from the list?';

		if (canDiconnect) {
			message = 'Are you sure you want to disconnect and remove this sensor from the list?';
		}
		this.modalService.confirm(message).afterClosed().toPromise().then(res => {
			if (res) {
				this.storageService.getItem('socketPool').then(socketPool => {
					if (socketPool) {
						socketPool.splice(socketPool.indexOf(ip.url), 1);
						this.storageService.setItem('socketPool', socketPool).then(() => {
							this.settingsService.clearAllSetting(ip.url);
							if (canDiconnect) {
								this.sensorsService.disconnectSocket(sensorSocket);
							}
							this.loadSocketPool();
						});
					}
				});
			}
		});
	}

	private loadSocketPool(callback?) {
		this.storageService.getItem('socketPool').then(socketPool => {
			var pool = (socketPool || []).map(url => {
				return this.settingsService.getSocketInfo(url);
			});
			Promise.all(pool).then((socketInfo: any[]) => {
				this.existingRoomsNames = socketInfo.map(info => info.name);
				this.ipList = socketInfo
					.map((info: any, i) => {
						let sensorSocket = this.sensorsService.getSensorSocket(socketPool[i]);
						return {
							visibleField: info['name'] || this.prepareUrl(socketPool[i]),
							initVisibleField: info['name'] || this.prepareUrl(socketPool[i]),
							url: socketPool[i],
							name: info['name'],
							isQuickEditIp: false,
							lastUsed: info['lastUsed'],
							connected: sensorSocket && sensorSocket.isConnected()
						};
					})
					.sort((a, b) => {
						if (a.lastUsed < b.lastUsed) {
							return 1;
						}
						if (a.lastUsed > b.lastUsed) {
							return -1;
						}
						return 0;
					})
					.sort((a, b) => {
						return a.url === (this.sensorSocket && this.sensorSocket.url) ? -1 : 0;
					});
				if (callback) {
					callback();
				}
			});
		});
	}

	private prepareUrl(url = '') {
		return url.replace(`:${environment.defaultSensorPort}`, '');
	}

}
