<button class="new-rule" mat-raised-button (click)="addNewSpace()">{{'NEW_SPACE' | translate}} <img
	src="assets/images/svg/noun-1533438.svg"></button>
<div class="table-container" [perfectScrollbar]="{suppressScrollX: false, minScrollbarLength: 15}">
	<table>
		<thead>
			<tr>
				<th class="active">Active</th>
				<th class="name">Name</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let space of spaces">
				<td class="active">
					<div>
						<label>
							<input type="radio" [checked]="space.current" (change)="changeCurrentSpace(space)">
						</label>
					</div>
				</td>
				<td class="name">
					<input type="text" [(ngModel)]="space.name">
					<span *ngIf="space.error" class="error">{{space.error | translate}}</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>
