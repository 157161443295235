import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {BaseLineGraphComponent} from '../../../../../../components/layers/base-classes/base-line-graph.component';

/**
 * Component renders graph.
 */
@Component({
	selector: 'app-breathing',
	templateUrl: './breathing.component.html',
	styleUrls: ['./breathing.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreathingComponent extends BaseLineGraphComponent {
	constructor(public injector: Injector) {
		super(injector);
	}
}
