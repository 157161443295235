import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {RunMode, SensorSocket} from '../../services/system/sensor-socket';
import {ConfigurablePageControlType, ConfigurationService} from 'src/app/services/configuration.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {ToolbarService} from '../../services/toolbar.service';
import {Subscription} from 'rxjs';
import {SensorsService} from '../../services/system/sensors.service';

declare var require: any;
const {version: appVersion} = require('../../../../package.json');

/**
 * Component renders logo and menu on the left side.
 * Sidebar is used in main container and showed on each page for authorized user.
 */
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges, OnDestroy {

	@Input() sensorSocket: SensorSocket;

	appVersion = appVersion;
	isRecording: boolean | null = null;
	runMode = RunMode;

	environment = environment;
	ConfigurablePageControlType = ConfigurablePageControlType;

	defaultPageIcon = 'material_web';
	rippleColor = getComputedStyle(document.documentElement).getPropertyValue('--button-ripple-color');
	parameters: any = {};

	private timerRef;
	private parametersChangeSubscription: Subscription;
	protected subscriptions: Array<Subscription> = [];

	constructor(private sensorsService: SensorsService,
				public configurationService: ConfigurationService,
				public router: Router,
				public toolbarService: ToolbarService) {
	}

	ngOnInit(): void {
		this.subscriptions.push(this.toolbarService.connectionSubject.subscribe(connection => {
			if (this.parametersChangeSubscription) {
				this.parametersChangeSubscription.unsubscribe();
			}
			this.parametersChangeSubscription = this.sensorsService.eventListeners.parametersChange[connection.url].subscribe(parameters => {
				this.parameters = parameters || {};
			});
		}));
	}

	ngOnChanges(c: SimpleChanges) {
		if ('sensorSocket' in c && this.sensorSocket && this.sensorSocket instanceof SensorSocket) {
			this.sensorSocket.recordingInfoUpdated.subscribe(code => {
				switch (code) {
					case 'RECORDING_STARTED':
						// Blinking red dot
						this.timerRef = setInterval(() => this.isRecording = !this.isRecording, 1000);
						break;
					case 'RECORDING_FINISHED':
						clearInterval(this.timerRef);
						this.isRecording = null;
						break;

				}
				this.isRecording = !this.isRecording;
			});
		}
	}

	ngOnDestroy() {
		if (this.parametersChangeSubscription) {
			this.parametersChangeSubscription.unsubscribe();
		}
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
