import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

declare const require: any;

/**
 * Helper functions for loading resources in Autodesk FBX or glb format.
 * Loads models (.fbx, .glb) using AJAX request.
 */

var FBXLoader = require('three-fbxloader-offical');

const modelsResources = {
	'Standing': 'Standing',
	'Sitting': 'Sitting',
	'Lying': 'LayingDown',
	'Walking': 'Walking',
	'Bottle': 'Bottle',
	'Fridge': 'Fridge',
	'Car': 'Car',
	'Tailor': 'Tailor'
};

let fbxSources = ['Tailor'];

let resourcesCache = {};

resources = {};

let loadResource = (res) => {
	let loader,
		ext = '';

	if (fbxSources.includes(res)) {
		loader = new FBXLoader();
		ext = 'fbx'; // Autodesk FBX format of 3d object
	} else {
		loader = new GLTFLoader();
		ext = 'glb'; // binary (json-based) format of 3d object
	}

	return new Promise((resolve, reject) => {
		loader.load(`assets/3dobjects/${modelsResources[res]}.${ext}`, model => {
			resourcesCache[res] = model;
			resolve(model);
		}, null, reject);
	});
};

Object.keys(modelsResources).forEach(res => {
	resources[res] = {
		get() {
			return resourcesCache[res] ? Promise.resolve(resourcesCache[res]) : loadResource(res);
		}
	};
	resourcesCache[res] = null;
});

export var resources;
