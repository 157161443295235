import {Component, Injector, OnInit} from '@angular/core';
import {AnalyticsGraphBaseComponent} from '../analyticsGraph.base.component';

@Component({
	selector: 'app-smart-retail',
	templateUrl: '../analyticsGraph.base.component.html',
	styleUrls: ['../analyticsGraph.base.component.scss']
})
export class SmartRetailComponent extends AnalyticsGraphBaseComponent implements OnInit {

	constructor(protected injector: Injector) {
		super(injector);
	}

	async ngOnInit(): Promise<void> {
		super.ngOnInit();
	}
}
