import * as THREE from 'three';
import {scalePoints} from '../../../../../utils/IsoUtils';
import {PointCloudStage_InCarBase} from '../../../../../base-classes/PointCloudStage_InCarBase';
import {BASE_COLORS} from '../../../../../utils/ImageUtils';
import { degToRad, getThreeJsScale } from '../../../../../utils/utils';
import {DEFAULT_POINT_SIZE} from '../../../../../base-classes/PointCloudStageBase';

/**
 * Used as scene renderer in BoxesCloudPoint layer component.
 */
export class PointCloudStage_InCar_Boxes extends PointCloudStage_InCarBase {

	private seatsMeshCache: Array<THREE.Group> = [];
	private defaultPlanesRemoved = false;
	private seatIndications;
	private currentSeatIndications;

	constructor(div, arena, status, parameters, isRaw, protected pointSize = DEFAULT_POINT_SIZE) {
		super(div, arena, status, parameters, false, isRaw, 0xadd8e6, pointSize);
		this.initCamera();
		this.addCabinPlanes();
		// TODO: Document
		this.updateSceneOnConfiguring();
	}

	addCabinPlanes() {
		this.mainGroup.add(...Object.values(this.inCarCabinPlanes));
	}

	updateSceneOnConfiguring() {
		if (this.status !== 'IMAGING') {
			this.updateDefaultCabinAccordingToArena(this.getLiftAboveGround(this.arena));
		}
	}

	updateCabinOnImaging(arena, cabinArena) {
		let isMultipleSensors = arena[0] instanceof Array;
		this.updateScene(arena, isMultipleSensors ? this.connectedSensorsParameters : this.parameters);
		this.updateInCarCabin(cabinArena);
		this.updateSensor(isMultipleSensors ? this.connectedSensorsParameters : this.parameters);
		this.updateArenaPlanesAccordingToPOV(arena);
	}

	updateScene(arena, parameters, cabinArena?) {
		let mainArena = arena,
			inCarMultiSensor = arena[0] instanceof Array;

		if (inCarMultiSensor) {
			mainArena = arena[0];
		}
		super.updateScene(mainArena, parameters);
		if (cabinArena) {
			let cb = (a, i) => {

				let localArenaScale = getThreeJsScale(a, this.SCALING_FACTOR),
					liftAboveGround = this.getLiftAboveGround(a),
					{x, z} = this.getPositionMargin(a),
					{x: cabinX, z: cabinZ} = this.getPositionMargin(cabinArena),
					aboveGround = liftAboveGround + localArenaScale[1] / 2 - 0.5;

				// Room floor
				if (this.arenas[i][0]) {
					this.arenas[i][0].position.set(cabinX, this.FLOOR_Y_POSITION, cabinZ);
				}
				// Front plane
				this.arenas[i][1].position.set(x, aboveGround, Math.max(a[5], a[4]) * this.SCALING_FACTOR);
				// Sensor plane
				this.arenas[i][2].position.set(x, aboveGround, Math.min(a[5], a[4]) * this.SCALING_FACTOR);
				// Right plane
				this.arenas[i][3].position.set(Math.max(a[1], a[0]) * this.SCALING_FACTOR + i, aboveGround, z);
				// Left plane
				this.arenas[i][4].position.set(Math.min(a[1], a[0]) * this.SCALING_FACTOR - i, aboveGround, z);
				// Bottom plane
				this.arenas[i][5].position.set(x, liftAboveGround + i, z);
				// Top
				this.arenas[i][6].position.set(x, liftAboveGround + localArenaScale[1] + 0.5, z);
				// Room floor
				if (this.arenas[i][0]) {
					this.arenas[i][0].scale.set((cabinArena[1] - cabinArena[0]) * this.SCALING_FACTOR, 1, (cabinArena[5] - cabinArena[4]) * this.SCALING_FACTOR);
				}
				this.arenas[i][1].scale.set(localArenaScale[0], localArenaScale[1], 1);
				this.arenas[i][2].scale.set(localArenaScale[0], localArenaScale[1], 1);
				this.arenas[i][3].scale.set(1, localArenaScale[1], localArenaScale[2]);
				this.arenas[i][4].scale.set(1, localArenaScale[1], localArenaScale[2]);
				// Bottom plane
				this.arenas[i][5].scale.set(localArenaScale[0], 1, localArenaScale[2]);
				// Top
				this.arenas[i][6].scale.set(localArenaScale[0], 1, localArenaScale[2]);
			};

			let cameraCenterX,
				cameraCenterZ;

			if (inCarMultiSensor) {
				arena.forEach((a, i) => {
					let arenaPlanes;
					if (this.arenas[i]) {
						arenaPlanes = this.arenas[i];
					} else {
						arenaPlanes = [null];
						for (let j = 1; j < 7; j++) {
							arenaPlanes[j] = this.arenaPlanes[j].clone();
							arenaPlanes[j].material = this.arenaPlanes[j].material.clone();
							arenaPlanes[j].material.color = new THREE.Color(BASE_COLORS[(i) % BASE_COLORS.length]);
							arenaPlanes[j].material.needsUpdate = true;
						}
						this.arenas.push(arenaPlanes);
						arenaPlanes.forEach(p => p && this.mainGroup.add(p));
					}
					cb(a, i);
				});
				cameraCenterX = (Math.max(...arena.map(a => a[1]), cabinArena[1]) + Math.min(...arena.map(a => a[0]), cabinArena[0])) / 2 * this.SCALING_FACTOR;
				cameraCenterZ = (Math.max(...arena.map(a => a[3]), cabinArena[3]) + Math.min(...arena.map(a => a[2]), cabinArena[2])) / 2 * this.SCALING_FACTOR;
			} else {
				cb(arena, 0);
				cameraCenterX = (Math.max(arena[1], cabinArena[1]) + Math.min(arena[0], cabinArena[0])) / 2 * this.SCALING_FACTOR;
				cameraCenterZ = (Math.max(arena[3], cabinArena[3]) + Math.min(arena[2], cabinArena[2])) / 2 * this.SCALING_FACTOR;
			}

			this.controls.target = new THREE.Vector3(cameraCenterX, this.scale[1] / 2 + this.getLiftAboveGround(arena), cameraCenterZ);
			this.controls.update();
		}
	}

	updateOnInit(data, arena, cabinArena, isRaw, isSensorVisible, seatIndications) {
		this.isSensorVisible = isSensorVisible;
		this.status = 'IMAGING';
		this.arena = arena;
		this.seatIndications = seatIndications;
		this.updateData(data, isRaw);
		this.updateCabinOnImaging(arena, cabinArena);

		this.updateSensorVisible(isSensorVisible);
	}

	/**
	 * This function is the main function of this class.
	 * It updates the point cloud in the arena according to data given from Matlab.
	 * We're giving the user the control over showing shadows and targets` centers
	 */
	update(data, arena, status, cabinArena, isRaw, isSensorVisible, seatIndications?) {
		let mainArena = arena;
		if (arena[0] instanceof Array) {
			mainArena = arena[0];
		}
		this.isSensorVisible = isSensorVisible;
		this.oldStatus = this.status;
		this.status = status;
		this.oldArena = this.arena;
		this.arena = mainArena;
		this.inCarCabinVertexs = cabinArena;
		this.seatIndications = seatIndications;
		if (status === 'IMAGING' && data) {
			this.updateData(data, isRaw);
			this.updateCabinOnImaging(arena, cabinArena);
		}
		// Update on changing status - For instance from "CONFIGURING" to "IMAGING"
		if (this.oldStatus !== this.status) {
			this.updateStatus(this.status);
			if (this.status === 'CONFIGURING') {
				this.isCarAndCabinUpdated = false;
				this.isCabinUpdated = false;
			}
		}
		this.updateSensorVisible(isSensorVisible);
	}

	updateData(data, isRaw) {
		// In case we got one point which is stripped from the wrapper array
		this.data = data && data.length && !data[0].length ? [data] : data;
		this.isRaw = isRaw;
		if (this.isRaw) {
			scalePoints(this.data || []);
		}
	}

	initCamera() {
		this.camera.position.set(0, this.scale[1] * 4, 100); // With the planes view, look from above
		this.camera.lookAt(0, 0, 0);
	}

	updateInCarCabin(cabinArena) {
		this.updateCabinInnerPlanes(cabinArena);
	}

	updateCabinInnerPlanes(cabinArena) {
		this.mainGroup.remove(this.tO);
		this.mainGroup.remove(this.tI);
		if (!this.defaultPlanesRemoved) {
			Object.values(this.inCarCabinPlanes).forEach(plane => {
				this.mainGroup.remove(plane);
			});
			this.defaultPlanesRemoved = true;
		}

		if (this.seatIndications) {
			if (JSON.stringify(this.seatIndications) !== this.currentSeatIndications) {
				this.updateCarSeatsIndexes(cabinArena);
				this.seatsMeshCache.forEach(mesh => {
					this.mainGroup.remove(mesh);
				});
				const {aboveGround, thickness, inCarPlaneHeight} = this.getCabinDefaultDims(
					this.getLiftAboveGround(this.arena)
				);
				this.seatIndications.forEach(seat => {
					let seatObject = new THREE.Group();
					this.seatsMeshCache.push(seatObject);
					this.mainGroup.add(seatObject);
					let carPlaneMaterial = new THREE.MeshBasicMaterial({
						color: seat[7] ? 0xadd8e6 : 0x8a8a8a,
						transparent: true,
						opacity: 0.5,
					});
					let inCarCabinSeatFrontPlane = new THREE.Mesh(this.PLANE_GEOMETRY, carPlaneMaterial);
					let inCarCabinSeatBackPlane = new THREE.Mesh(this.PLANE_GEOMETRY, carPlaneMaterial);
					let inCarCabinSeatLeftPlane = new THREE.Mesh(this.PLANE_GEOMETRY, carPlaneMaterial);
					let inCarCabinSeatRightPlane = new THREE.Mesh(this.PLANE_GEOMETRY, carPlaneMaterial);
					seatObject.add(inCarCabinSeatFrontPlane);
					seatObject.add(inCarCabinSeatBackPlane);
					seatObject.add(inCarCabinSeatLeftPlane);
					seatObject.add(inCarCabinSeatRightPlane);

					inCarCabinSeatFrontPlane.position.set(this.SCALING_FACTOR * seat[1], aboveGround, this.SCALING_FACTOR * seat[2] - this.SCALING_FACTOR * seat[5] / 2);
					inCarCabinSeatBackPlane.position.set(this.SCALING_FACTOR * seat[1], aboveGround, this.SCALING_FACTOR * seat[2] + this.SCALING_FACTOR * seat[5] / 2);
					inCarCabinSeatLeftPlane.position.set(this.SCALING_FACTOR * seat[1] - this.SCALING_FACTOR * seat[4] / 2, aboveGround, this.SCALING_FACTOR * seat[2]);
					inCarCabinSeatRightPlane.position.set(this.SCALING_FACTOR * seat[1] + this.SCALING_FACTOR * seat[4] / 2, aboveGround, this.SCALING_FACTOR * seat[2]);

					inCarCabinSeatFrontPlane.scale.set(this.SCALING_FACTOR * seat[4], inCarPlaneHeight, thickness);
					inCarCabinSeatBackPlane.scale.set(this.SCALING_FACTOR * seat[4], inCarPlaneHeight, thickness);
					inCarCabinSeatLeftPlane.scale.set(thickness, inCarPlaneHeight, this.SCALING_FACTOR * seat[5]);
					inCarCabinSeatRightPlane.scale.set(thickness, inCarPlaneHeight, this.SCALING_FACTOR * seat[5]);
				});
				this.currentSeatIndications = JSON.stringify(this.seatIndications);
			}
		}
	}

	updateDefaultCabinInner(inCarCabinFrontAndBackPlaneWidth,
							inCarCabinRightAndLeftPlaneWidth,
							aboveGround,
							inCarPlaneHeight,
							thickness) {
		super.updateDefaultCabinInner(inCarCabinFrontAndBackPlaneWidth,
			inCarCabinRightAndLeftPlaneWidth,
			aboveGround,
			inCarPlaneHeight,
			thickness);

		let {
			x_midFrontPlane,
			x_midBackPlane,
			z_frontSeatMidPlane,
			z_backSeatMidLeftPlane,
			z_backSeatMidRightPlane,
		} = this.inCarCabinPlanes;

		let t = new THREE.Group();

		t.add(x_midFrontPlane,
			x_midBackPlane,
			z_frontSeatMidPlane,
			z_backSeatMidLeftPlane,
			z_backSeatMidRightPlane);

		let {x, z} = this.getPositionMargin(this.arena);
		t.position.set(x, 0, z);
		this.tI = t;
		this.mainGroup.add(t);
	}

	addCarSeatsIndexes(inCarCabinFrontAndBackPlaneWidth, inCarCabinRightAndLeftPlaneWidth, defaultMargin) {
		super.addCarSeatsIndexes(inCarCabinFrontAndBackPlaneWidth, inCarCabinRightAndLeftPlaneWidth, defaultMargin);
		let {x, z} = this.getPositionMargin(this.arena);
		this.carSeatsIndexes.position.set(x, this.FLOOR_Y_POSITION + this.FLOOR_HEIGHT / 2 + this.PLANE_MARGIN, z);
	}

	/**
	 * Update car seats indexes according to dimensions coming from Matlab while IMAGING
	 *  */

	updateCarSeatsIndexes(cabinArena) {
		let canvas = document.createElement('canvas'),
			width = (cabinArena[1] - cabinArena[0]) * this.SCALING_FACTOR,
			height = (cabinArena[3] - cabinArena[2]) * this.SCALING_FACTOR;
		canvas.width = width;
		canvas.height = height;
		let ctx = canvas.getContext('2d', {alpha: true})!;
		ctx.fillStyle = this.getFloorColor(true) as string;
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.font = '35pt Arial';
		ctx.fillStyle = 'red';
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.font = '20pt Arial';

		const x_distFromArenaLeft = Math.abs(cabinArena[0]) * this.SCALING_FACTOR;
		const y_distFromArenaTop = Math.abs(cabinArena[2]) * this.SCALING_FACTOR;

		this.seatIndications.forEach(seat => {
			ctx.fillText(seat[0], x_distFromArenaLeft + this.SCALING_FACTOR * seat[1], y_distFromArenaTop + this.SCALING_FACTOR * seat[2] + 5);
		});

		let texture = new THREE.Texture(canvas);
		var textPlaneMaterial = new THREE.MeshBasicMaterial({map: texture});
		let textPlaneGeometry = new THREE.BoxGeometry(width, 1, height);
		let carSeatsIndexes = new THREE.Mesh(textPlaneGeometry, textPlaneMaterial);
		carSeatsIndexes.position.set((cabinArena[1] - (cabinArena[1] - cabinArena[0]) / 2) * this.SCALING_FACTOR, this.FLOOR_Y_POSITION, (cabinArena[3] - (cabinArena[3] - cabinArena[2]) / 2) * this.SCALING_FACTOR);
		texture.needsUpdate = true;
		// remove old indexes
		this.mainGroup.remove(this.carSeatsIndexes);
		this.mainGroup.add(carSeatsIndexes);
		this.carSeatsIndexes = carSeatsIndexes;
	}

	getCabinDefaultDims(liftAboveGround) {
		const defaultMargin = 15; // This should come from Matlab...
		const inCarCabinFrontAndBackPlaneWidth = this.scale[0] - defaultMargin * 2;
		const inCarCabinRightAndLeftPlaneWidth = this.scale[2] - defaultMargin * 2;
		const aboveGround = liftAboveGround + this.scale[1] / 2 - 0.5;
		const thickness = 3;
		const inCarPlaneHeight = this.scale[1] + 2;
		return {
			defaultMargin,
			inCarCabinFrontAndBackPlaneWidth,
			inCarCabinRightAndLeftPlaneWidth,
			aboveGround,
			thickness,
			inCarPlaneHeight,
		};
	}

	updateDefaultCabinAccordingToArena(liftAboveGround) {
		const {
			defaultMargin,
			inCarCabinFrontAndBackPlaneWidth,
			inCarCabinRightAndLeftPlaneWidth,
			aboveGround,
			thickness,
			inCarPlaneHeight,
		} = this.getCabinDefaultDims(liftAboveGround);
		// Outer planes
		this.updateDefaultCabinOuter(
			inCarCabinFrontAndBackPlaneWidth,
			inCarCabinRightAndLeftPlaneWidth,
			aboveGround,
			inCarPlaneHeight,
			thickness,
			defaultMargin
		);
		// Inner planes
		this.updateDefaultCabinInner(
			inCarCabinFrontAndBackPlaneWidth,
			inCarCabinRightAndLeftPlaneWidth,
			aboveGround,
			inCarPlaneHeight,
			thickness
		);
		this.addCarSeatsIndexes(inCarCabinFrontAndBackPlaneWidth, inCarCabinRightAndLeftPlaneWidth, defaultMargin);
	}

	updateDefaultCabinOuter(inCarCabinFrontAndBackPlaneWidth,
							inCarCabinRightAndLeftPlaneWidth,
							aboveGround,
							inCarPlaneHeight,
							thickness,
							defaultMargin) {
		const {
			inCarCabinFrontPlane,
			inCarCabinBackPlane,
			inCarCabinLeftPlane,
			inCarCabinRightPlane,
		} = this.inCarCabinPlanes;
		const z_inCarCabinFrontPlane = -this.scale[2] / 2 + defaultMargin;
		const z_inCarCabinBackPlane = this.scale[2] / 2 - defaultMargin;
		const x_inCarCabinRightPlane = this.scale[0] / 2 - defaultMargin;
		const x_inCarCabinLeftPlane = -this.scale[0] / 2 + defaultMargin;

		inCarCabinFrontPlane.position.set(0, aboveGround, z_inCarCabinFrontPlane);
		inCarCabinFrontPlane.scale.set(inCarCabinFrontAndBackPlaneWidth, inCarPlaneHeight, thickness);

		inCarCabinBackPlane.position.set(0, aboveGround, z_inCarCabinBackPlane);
		inCarCabinBackPlane.scale.set(inCarCabinFrontAndBackPlaneWidth, inCarPlaneHeight, thickness);

		inCarCabinRightPlane.position.set(x_inCarCabinRightPlane, aboveGround, 0);
		inCarCabinRightPlane.scale.set(thickness, inCarPlaneHeight, inCarCabinRightAndLeftPlaneWidth);

		inCarCabinLeftPlane.position.set(x_inCarCabinLeftPlane, aboveGround, 0);
		inCarCabinLeftPlane.scale.set(thickness, inCarPlaneHeight, inCarCabinRightAndLeftPlaneWidth);

		let t = new THREE.Group();

		t.add(inCarCabinFrontPlane,
			inCarCabinBackPlane,
			inCarCabinLeftPlane,
			inCarCabinRightPlane);

		let {x, z} = this.getPositionMargin(this.arena);
		t.position.set(x, 0, z);

		this.tO = t;
		this.mainGroup.add(t);
	}

	getFloorColor(isTextLayerColor = false) {
		let color: any = null;
		if (isTextLayerColor) {
			color = this.FLOOR_COLOR;
			color = color.toString();
			color = color.substring(2, color.length);
			return '#' + color;
		}
		return parseInt(this.FLOOR_COLOR, 16);
	}
}
