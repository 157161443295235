import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../../../../services/modal.service';
import {SensorsService} from '../../../../services/system/sensors.service';
import {SettingsService} from '../../../../services/settings.service';
import {TabbedFormControlComponent} from '../tabbed-form-control/tabbed-form-control.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {RunMode, SensorSocket} from '../../../../services/system/sensor-socket';
import {StorageService} from '../../../../services/storage.service';
import {getNonCacheableSensorParameters, tableFix} from '../../../../utils/utils';
import {TranslateService} from '@ngx-translate/core';
import {BusEventService} from '../../../../services/bus-event.service';
import {Subscription} from 'rxjs';
import {ToolbarService} from '../../../../services/toolbar.service';
import {ConnectionStatus} from '../../../../services/system/connection';
import {MODULE_PATH} from 'src/app/app.module';
import {ConfigurablePageControlType, ConfigurationService} from '../../../../services/configuration.service';

interface ISensorsItem {
	error: string;
	sensorSocket: SensorSocket;
	checked: boolean;
	isConnected: boolean;
	isConnecting: boolean;
	isParametersRetrieved: boolean;
	isEngine: boolean;
	isRunned: boolean;
	numberOfConnectedClients: number;
	isQuickEditSocketIp: boolean;
	isQuickEditSocketPort: boolean;
	isQuickEditName: boolean;
	name: string;
	ip: string;
	port: string;
	status: string;
	info: any;
}

enum Sort {
	None,
	Asc,
	Desc = -1
}

declare var require: any;
var FileSaver = require('file-saver');

/**
 * Component renders filters, add button, actions and table of sensors.
 * Each row contains sensor state, ip, port and actions.
 */
@Component({
	selector: 'app-connections',
	templateUrl: './connections.component.html',
	styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit, OnDestroy {
	filterString = '';
	isAllHostsChecked: boolean;
	isCheckedRows = false;
	connectionMenuVisible = false;

	currentSortingColumn: string | null = null;
	currentSortingDirection: Sort = Sort.Asc;
	windowWidth = document.documentElement.scrollWidth;
	monitoringItems: Array<any> = [];
	loading = false;
	existingRoomsNames: Array<string> = [];

	environment = environment;
	Sort = Sort;

	get connectedSensors() {
		return this.socketPool.filter(item => item.isConnected).length;
	}

	private socketPool: Array<any> = [];

	private lastEditNameItem;
	private lastEditNameInput;

	private lastEditSocketItem;
	private lastEditSocketInput;

	private subscriptions: Array<Subscription> = [];
	private isConnectingToMultipleSensors = false;

	private sortData = (a, b) => {
		if (this.currentSortingDirection !== Sort.None && this.currentSortingColumn) {
			if (a[this.currentSortingColumn] < b[this.currentSortingColumn]) {
				return this.currentSortingDirection * -1;
			}
			if (a[this.currentSortingColumn] > b[this.currentSortingColumn]) {
				return this.currentSortingDirection;
			}
		}
		return 0;
	};

	private tableFix = () => {
		this.windowWidth = document.documentElement.scrollWidth;
		tableFix();
	};

	private headerScroll = () => {
		(document.querySelector('.fixed-header') as any).style.left = 195 - window.pageXOffset + 'px';
	};

	private closeConnectionMenu = () => {
		this.connectionMenuVisible = false;
	};

	constructor(private modalService: ModalService,
				private sensorsService: SensorsService,
				private settingsService: SettingsService,
				private dialog: MatDialog,
				private router: Router,
				private storageService: StorageService,
				private translateService: TranslateService,
				private busEventService: BusEventService,
				private toolbarService: ToolbarService,
				private configurationService: ConfigurationService,
				@Inject(MODULE_PATH) private MODULE_PATH) {
	}

	ngOnInit() {
		this.subscriptions.push(this.sensorsService.anotherClientSensorLocationDataChanged.subscribe(() => {
			this.loadSocketPool();
		}));
		this.loadSocketPool();
		document.addEventListener('click', this.closeConnectionMenu);
		window.addEventListener('resize', this.tableFix);
		window.addEventListener('scroll', this.headerScroll);
		this.storageService.getItem('sensor-list-table-currentSortingColumn').then(currentSortingColumn => {
			if (currentSortingColumn) {
				this.currentSortingColumn = currentSortingColumn;
			}
		});
		this.storageService.getItem('sensor-list-table-currentSortingDirection').then(currentSortingDirection => {
			if (currentSortingDirection) {
				this.currentSortingDirection = currentSortingDirection as Sort;
			}
		});
		this.subscriptions.push(this.busEventService.reloadPool.subscribe(() => {
			this.loadSocketPool();
		}));
	}

	ngOnDestroy() {
		document.removeEventListener('click', this.closeConnectionMenu);
		window.removeEventListener('resize', this.tableFix);
		window.removeEventListener('scroll', this.headerScroll);
		this.socketPool.forEach(item => item.__statusSub.unsubscribe());
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	onSort(column) {
		if (this.currentSortingColumn === column) {
			switch (this.currentSortingDirection) {
				case Sort.None:
					this.currentSortingDirection = Sort.Asc;
					break;
				case Sort.Asc:
					this.currentSortingDirection = Sort.Desc;
					break;
				case Sort.Desc:
					this.currentSortingDirection = Sort.None;
					break;
			}
		} else {
			this.currentSortingColumn = column;
			this.currentSortingDirection = Sort.Asc;
		}
		this.updateList();
		this.storageService.setItem('sensor-list-table-currentSortingColumn', this.currentSortingColumn);
		this.storageService.setItem('sensor-list-table-currentSortingDirection', this.currentSortingDirection);
	}

	onFilter() {
		this.updateList();
	}

	selectAllHosts() {
		if (this.monitoringItems.length) {
			this.isAllHostsChecked = !this.isAllHostsChecked;
			this.socketPool.map(item => item.checked = this.isAllHostsChecked ? true : false);
			this.isCheckedRows = this.isAllHostsChecked;
		}
	}

	updateCheckedState() {
		this.isCheckedRows = this.socketPool.some(item => item.checked);
		this.isAllHostsChecked = this.socketPool.every(item => item.checked);
	}

	addConnection() {
		this.modalService.showAddConnections().afterClosed().toPromise().then(async (res) => {
			if (res) {
				this.loading = true;
				var infoGets: Array<any> = [],
					infoUpdates: Array<any> = [];
				for (let item of res) {
					if (item.ip) {
						var [ip, port] = item.ip.split(':'),
							sensorSocket;

						if (!port) {
							port = environment.defaultSensorPort;
						}
						if (!this.socketPool.find(s => s.ip === ip && s.port === port)) {
							sensorSocket = await this.sensorsService.createSensorSocket(ip, port);
							if (item.name) {
								let name = item.name;

								await infoGets.push(this.settingsService.getSocketInfo(sensorSocket.url).then(info => {
									if (name) {
										info['name'] = name;
									} else {
										delete info['name'];
									}
									infoUpdates.push(this.settingsService.saveSocketInfo(sensorSocket.url, info));
								}));
							}
						}
					}
				}
				Promise.all(infoGets).then(() => {
					Promise.all(infoUpdates).then(() => {
						this.loadSocketPool();
					});
				});
			}
		});
	}

	toggleConnect(item) {
		item.error = '';
		if (item.sensorSocket.canDisconnect()) {
			this.sensorsService.disconnectSocket(item.sensorSocket);
		} else {
			this.sensorsService.connectSocket(item.sensorSocket);
		}
	}

	onShowSettings(item) {
		let configuration = this.configurationService.configurationByUrl[item.sensorSocket.url],
			sensorPage = configuration.pages.find(page => page.name.toLowerCase() === 'sensor'),
			tabbedFormControl = sensorPage?.controls!.find(c => {
				return c.type === ConfigurablePageControlType.PAGE_TABBED_FORM_CONTROL;
			});
		this.dialog.open(TabbedFormControlComponent, {
			autoFocus: false,
			width: '1104px',
			disableClose: true,
			panelClass: 'modal-popup',
			minHeight: 632,
			data: {
				sensorSocket: item.sensorSocket,
				page: sensorPage,
				tabs: tabbedFormControl?.tabs || []
			}
		}).afterClosed().toPromise().then(saved => {
			if (saved) {
				setTimeout(() => {
					item.error = '';
				});
				this.loadSocketPool();
			}
		});
	}

	refresh() {
		this.socketPool.filter(item => item.isConnected).forEach(item => {
			item.sensorSocket.retrieveStatus();
		});
	}

	canChangeSensorState(run?) {
		return this.monitoringItems.some(item => {
			return item.checked && item.sensorSocket.isConnected() && item.sensorSocket.isParametersRetrieved() && (run ? !item.sensorSocket.isRunned() : item.sensorSocket.isRunned());
		});
	}

	runSensors() {
		this.monitoringItems.filter(item => {
			return item.checked && item.sensorSocket.isConnected() && !item.sensorSocket.isRunned();
		}).forEach(item => {
			this.sensorsService.runSensor(item.sensorSocket, RunMode.LIVE);
		});
	}

	stopSensors() {
		this.monitoringItems.filter(item => {
			return item.checked && item.sensorSocket.isConnected() && item.sensorSocket.isRunned();
		}).forEach(item => {
			this.sensorsService.stopSensor(item.sensorSocket);
		});
	}

	connectSensors() {
		this.isConnectingToMultipleSensors = true;
		this.connectionMenuVisible = false;
		let pool: Array<Promise<void>> = [];
		this.monitoringItems.filter(item => {
			return item.checked && !item.sensorSocket.canDisconnect();
		}).forEach(item => {
			pool.push(new Promise((resolve, reject) => {
				this.sensorsService.connectSocket(item.sensorSocket, resolve);
			}));
		});
		Promise.all(pool).then(() => {
			this.isConnectingToMultipleSensors = false;
		});
	}

	disConnectSensors() {
		this.connectionMenuVisible = false;
		this.monitoringItems.filter(item => {
			return item.checked && item.sensorSocket.canDisconnect();
		}).forEach(item => {
			item.error = '';
			this.sensorsService.disconnectSocket(item.sensorSocket);
		});
	}

	onClickSaveBtn(sensorSocket) {
		this.settingsService.getAllSettings(sensorSocket.url).then(parameters => {
			if (!parameters) {
				parameters = {};
			}
			FileSaver.saveAs(new Blob([JSON.stringify(parameters, null, '\t')], {type: 'application/json;charset=utf-8'}), `Sensor_${sensorSocket.url}_settings.json`);
			this.settingsService.saveSocketInfo(sensorSocket.url, {
				settingsSaved: true
			});
		});
	}

	setSensorState(item) {
		if (item.sensorSocket.isRunned()) {
			this.sensorsService.stopSensor(item.sensorSocket);
		} else {
			item.error = '';
			this.sensorsService.runSensor(item.sensorSocket, RunMode.LIVE);
		}
	}

	removeConnection(item) {
		let message = 'Are you sure you want to remove this connection from the list?';

		if (item.sensorSocket.canDisconnect()) {
			message = 'Are you sure you want to disconnect and remove this connection from the list?';
		}
		this.modalService.confirm(message).afterClosed().toPromise().then(res => {
			if (res) {
				item.__statusSub.unsubscribe();
				this.loading = true;
				this.sensorsService.removeSensorSocket(item.sensorSocket.url).then(() => {
					this.loadSocketPool();
				});
			}
		});
	}

	removeSelectedConnections() {
		let message = 'Are you sure you want to disconnect and remove these connections from the list?';

		this.modalService.confirm(message).afterClosed().toPromise().then(res => {
			if (res) {
				let pool: Array<Promise<any>> = [];
				this.monitoringItems.filter(item => item.checked).forEach(item => {
					item.__statusSub.unsubscribe();
					pool.push(this.sensorsService.removeSensorSocket(item.sensorSocket.url));
				});
				Promise.all(pool).then(() => {
					this.loadSocketPool();
				});
			}
		});
	}

	goTo(sensorSocket) {
		this.router.navigate([this.prepareURL(`/sensor`), sensorSocket.url]);
	}

	editName(item, input) {
		if (this.lastEditSocketItem) {
			return;
		}
		if (this.lastEditNameItem && this.lastEditNameItem === item) {
			return;
		}
		if (this.lastEditNameItem) {
			if (!this.saveEditName(this.lastEditNameItem, this.lastEditNameInput.value)) {
				return;
			}
		}
		this.lastEditNameItem = item;
		this.lastEditNameInput = input;
		item.isQuickEditName = true;
		setTimeout(() => {
			input.focus();
		});
	}

	exitEditName(item, input, e?) {
		if (this.existingRoomsNames.includes(input.getInputValue()) && input.getInputValue() !== item.name) {
			this.modalService.showError('NAME_ALREADY_IN_USE_MESSAGE').afterClosed().toPromise().then(() => {
				input.focus();
			});
			e.isPrevented = true;
			return false;
		} else {
			this.saveEditName(item, input.getInputValue(), e);
		}
	}

	saveEditName(item: ISensorsItem, name: string, e?) {
		if (e) {
			e.stopPropagation();
		}
		if (this.existingRoomsNames.includes(name) && name !== item.name) {
			this.modalService.showError('NAME_ALREADY_IN_USE_MESSAGE');
			return false;
		} else {
			let oldName = item.name;
			this.settingsService.getAllSettings(item.sensorSocket.url).then(settings => {
				settings['socketInfo']['name'] = name;
				item.name = name;
				this.settingsService.saveSocketInfo(item.sensorSocket.url, settings['socketInfo']).then(() => {
					if (item.isConnected && item.sensorSocket.isSensorsNetworkEnabled) {
						item.sensorSocket.setNetworkSensorLocationData({
							'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.position': settings['sensorNetworkParameters']['globalSensorLocation'],
							'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.orientation': settings['sensorNetworkParameters']['globalSensorOrientation']
						}).catch(console.error.bind(console));
					}
					this.sensorsService.validateAndUpdateRules();
					this.configurationService.updateConfiguration();
					this.existingRoomsNames.splice(this.existingRoomsNames.indexOf(oldName), 1);
					this.existingRoomsNames.push(name);
				});
			});
			this.exitQuickEditMode(item);
			return true;
		}
	}

	editSocket(item, input, key) {
		if (this.lastEditSocketItem) {
			return;
		} else {
			item[key] = true;
		}
		if (this.lastEditSocketItem && this.lastEditSocketItem === item) {
			return;
		}
		if (this.lastEditNameItem) {
			this.saveEditName(this.lastEditNameItem, this.lastEditNameInput.value);
		}
		this.lastEditSocketItem = item;
		this.lastEditSocketInput = input;
		setTimeout(() => {
			input.focus();
		});
	}

	exitEditSocket(item, input, value, e?) {
		if (e) {
			e.stopPropagation();
		}
		item.isQuickEditSocketIp = false;
		item.isQuickEditSocketPort = false;
		input.writeValue(value);
		this.lastEditSocketItem = null;
		this.lastEditSocketInput = null;
	}

	saveEditSocket(item, ip, port, e?) {
		let newUrl = `${ip}:${port}`;
		if (e) {
			e.stopPropagation();
		}
		if (item.sensorSocket.url === newUrl) {
			item.isQuickEditSocketIp = false;
			item.isQuickEditSocketPort = false;
			this.lastEditSocketItem = null;
			this.lastEditSocketInput = null;
			return;
		}
		this.settingsService.getAllSettings(item.sensorSocket.url).then(settings => {
			if (settings['sensorParameters']) {
				getNonCacheableSensorParameters(item.sensorSocket).forEach(parameter => {
					delete settings['sensorParameters'][parameter];
				});
			}
			this.settingsService.saveAllSettings(newUrl, settings, true).then(() => {
				item.isQuickEditSocketIp = false;
				item.isQuickEditSocketPort = false;
				this.sensorsService.removeSensorSocket(item.sensorSocket.url).then(() => {
					this.sensorsService.createSensorSocket(ip, port).then(() => {
						this.loadSocketPool();
					});
				});
			});
		});
		this.lastEditSocketItem = null;
		this.lastEditSocketInput = null;
	}

	blurName(item, value, e) {
		if (e.key === 'Enter') {
			this.saveEditName(item, value, e);
		}
	}

	blurSocket(item, ip, port, e) {
		if (e.key === 'Enter') {
			this.saveEditSocket(item, ip, port, e);
		}
	}

	private loadSocketPool() {
		this.loading = true;
		this.storageService.getItem('socketPool').then((socketPool) => {
			this.loading = false;
			this.prepareSocketPool(socketPool).then(pool => {
				this.socketPool = pool;
				this.toolbarService.setSocketPool(this.socketPool);
				this.updateList();
				this.updateCheckedState();
				var cb = () => {
					this.tableFix();
				};
				cb();
				setTimeout(() => {
					cb();
					setTimeout(cb, 50);
				}, 50);
			});
		}).catch(() => {
			this.loading = false;
		});
	}

	private prepareSocketPool(socketPool) {
		var component = this,
			socketsPool = (socketPool || []).map(async (url) => {
				return this.sensorsService.getSensorSocket(url) || await this.sensorsService.createSensorSocket(url.split(':')[0], url.split(':')[1]);
			});

		return Promise.all(socketsPool).then((sockets: any[]) => {
			let pool = sockets.map(socket => {
				return this.settingsService.getSocketInfo(socket.url);
			});

			return Promise.all(pool).then(socketInfo => {
				this.existingRoomsNames = socketInfo.map(info => info.name);
				return socketInfo.map((info, i) => {
					var item: ISensorsItem = {
						error: this.prepareError(sockets[i]['connectionStatus'], sockets[i]),
						sensorSocket: sockets[i],
						checked: 'checked' in sockets[i] ? sockets[i]['checked'] : false,
						get isConnected() {
							return sockets[i].isConnected();
						},
						get isConnecting() {
							return sockets[i].isConnecting();
						},
						get isParametersRetrieved() {
							return sockets[i].isParametersRetrieved();
						},
						isEngine: true,
						get isRunned() {
							return sockets[i].isRunned();
						},
						get numberOfConnectedClients() {
							return sockets[i].numberOfConnectedClients;
						},
						isQuickEditSocketIp: false,
						isQuickEditSocketPort: false,
						isQuickEditName: false,
						name: info['name'] || '',
						ip: sockets[i]['ip'],
						port: sockets[i]['port'],
						status: component.prepareStatus(sockets[i]['connectionStatus']),
						info: info || {}
					};

					item['__statusSub'] = sockets[i].status.subscribe(function (status: ConnectionStatus) {
						this.status = component.prepareStatus(status);
						switch (status) {
							case ConnectionStatus.ERROR:
								this.error = component.prepareError(status, this.sensorSocket);
								break;
							case ConnectionStatus.DISCONNECTED:
								this.error = component.prepareError(status, this.sensorSocket);
								break;
						}
					}.bind(item));

					return item;
				});
			});
		});
	}

	private prepareError(status, sensorSocket) {
		var error = '';

		switch (status) {
			case ConnectionStatus.ERROR:
				error = sensorSocket.lastErrorMessage;
				break;
			case ConnectionStatus.DISCONNECTED:
				if (['timeout', 'socketerror'].includes(sensorSocket.disconnectReason)) {
					error = 'NETWORK_ENGINE_DISCONNECTED';
				} else if ('multipleConnection' === sensorSocket.disconnectReason) {
					error = sensorSocket.lastErrorMessage;
				}
				break;
		}

		return error;
	}

	private prepareStatus(status) {
		switch (status) {
			case ConnectionStatus.CONNECTING:
				return 'STATUS_OF_SENSOR_CONNECTING_NS';
			case ConnectionStatus.CONNECTED:
			case ConnectionStatus.STOPPED:
			case ConnectionStatus.ANOTHER_CLIENT_CONNECTED:
			case ConnectionStatus.IMAGING:
			case ConnectionStatus.INITIALIZING:
			case ConnectionStatus.CALIBRATING:
			case ConnectionStatus.SAVING_DATA:
				return 'STATUS_OF_SENSOR_CONNECTED_NS';
			case ConnectionStatus.CONFIGURING:
				return 'STATUS_OF_SENSOR_CONFIGURING_NS';
			case ConnectionStatus.ERROR:
				return 'STATUS_OF_SENSOR_ERROR_NS';
			case ConnectionStatus.DISCONNECTED:
				return 'STATUS_OF_SENSOR_DISCONNECTED_NS';
			case ConnectionStatus.NOT_CONNECTED:
				return 'STATUS_OF_SENSOR_NO_SENSOR_NS';
			case ConnectionStatus.RESETTING:
				return 'STATUS_OF_SENSOR_RESETTING_NS';
			default:
				return status;
		}
	}

	private updateList() {
		if (this.filterString.length) {
			this.monitoringItems = [].concat(...this.socketPool.filter(item => {
				return ~item.sensorSocket.url.indexOf(this.filterString) || ~item.name.toLowerCase().indexOf(this.filterString.toLowerCase());
			})).sort(this.sortData);
		} else {
			this.monitoringItems = [].concat(...this.socketPool).sort(this.sortData);
		}
	}

	private prepareURL(url) {
		return `/${this.MODULE_PATH}${url}`;
	}

	private exitQuickEditMode(item) {
		item.isQuickEditName = false;
		this.lastEditNameItem = null;
		this.lastEditNameInput = null;
	}
}


