<div class="toolbar">
	<ng-container *ngIf="useCustomToolbarTemplate">
		<template #toolbarContainer></template>
	</ng-container>

	<div *ngIf="!useCustomToolbarTemplate" class="toolbar-block">
		<template #toolbarContainer></template>
	</div>
	<div class="connector-template" [hidden]="!sensorConnectorComponent || !showBackendConnector">
		<template #connectorContainer></template>
	</div>
	<app-user *ngIf="!environment.offline"></app-user>
</div>
