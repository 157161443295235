<div #map></div>
<div class="buttons">
	<ng-container>
		<button *ngIf="staticDataDict?.displayPcdSwitch"
				[matTooltip]="'Point Cloud ' + (isDisplayCenterTarget ? 'on' : 'off')"
				[class.active]="!isDisplayCenterTarget"
				mat-raised-button class="sensor" (click)="toggleTargetCenter()"></button>
		<button *ngIf="staticDataDict?.displayAxesSwitch"
				[matTooltip]="'Change Axes'" mat-raised-button class="axes" (click)="toggleAxes()"
				[attr.axes]="getAxesAttr()"></button>
		<button *ngIf="staticDataDict?.displayTargetsInfoSwitch"
				[matTooltip]="'Targets Info ' + (isDisplayTargetsInfo ? 'off' : 'on')"
				[class.active]="isDisplayTargetsInfo"
				mat-raised-button class="targets-info" (click)="toggleTargetInfo()"></button>
	</ng-container>
	<ng-content></ng-content>
</div>
