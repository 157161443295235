import * as d3 from 'd3';

/**
 * Utils for d3 graphs.
 */

export const AXIS_TYPE = {
	X: 'x',
	Y: 'y',
};

export function createXScaleFunc(graphWidth, min, max) {
	return d3
	.scaleLinear()
	.range([0, graphWidth])
	.domain([min, max]);
}

export function createYScaleFunc(graphHeight, min, max) {
	return d3
	.scaleLinear()
	.range([graphHeight, 0])
	.domain([min, max]);
}

export function createLineFunc(x, y) {
	return d3
	.line()
	.x((d: any) => x(d.time))
	.y((d: any) => y(d.value))
	.defined((r: any) => r.value && r.time)
	.curve(d3.curveCatmullRom);
}

export function positionXAxis(xAxisGroup, graphHeight) {
	return xAxisGroup.attr('transform', `translate(0, ${graphHeight})`);
}

export function getYAxis(y, height) {
	const smallNumOfTicks = 2;
	const defaultNumOfTicks = 5;
	const graphHeightThreshold = 100;

	return d3.axisLeft(y).ticks(height < graphHeightThreshold ? smallNumOfTicks : defaultNumOfTicks);
}

export function getXAxis(x, formatFunc?) {
	let xAxis = d3.axisBottom(x);
	if (formatFunc) {
		xAxis = xAxis.tickFormat(formatFunc);
	}
	return xAxis;
}

export function drawGrid(axisFunc, graphDims, gridLinesGroup, axisType) {
	let grildLines;
	let length;
	switch (axisType) {
		case AXIS_TYPE.Y:
			grildLines = getYAxis(axisFunc, graphDims.height);
			length = graphDims.width;
			break;
		case AXIS_TYPE.X:
			grildLines = getXAxis(axisFunc);
			length = graphDims.height;
			break;
		default:
			break;
	}
	grildLines = grildLines.tickSize(-length, 0, 0).tickFormat('');
	gridLinesGroup
	.call(grildLines)
	.selectAll('line, path')
	.style('fill', 'none')
	.style('stroke', '#d3d3d3')
	.style('shape-rendering', 'crispEdges');

	return {grildLines, gridLinesGroup};
}

export function createGrid(graphD3, xt = graphD3.x, yt = graphD3.y) {
	// Create y grid lines group
	const yGridLinesGroup = graphD3.grid.y.gridLinesGroup ? graphD3.grid.y.gridLinesGroup : graphD3.graph.append('g');
	// Draw y grid lines
	graphD3.grid.y = drawGrid(yt, graphD3.graphDims, yGridLinesGroup, AXIS_TYPE.Y);

	// Create x grid lines group
	let xGridLinesGroup = graphD3.grid.x.gridLinesGroup ? graphD3.grid.x.gridLinesGroup : graphD3.graph.append('g');
	xGridLinesGroup = positionXAxis(xGridLinesGroup, graphD3.graphDims.height);
	// Draw x grid lines
	graphD3.grid.x = drawGrid(xt, graphD3.graphDims, xGridLinesGroup, AXIS_TYPE.X);
}

export function rescaleYInCaseMaxOrMinHaveChanged(graphD3, minYValue, maxYValue) {
	// Create y scale function
	graphD3.y = createYScaleFunc(graphD3.graphDims.height, minYValue, maxYValue);
	// Update and draw axes
	const yAxis = getYAxis(graphD3.y, graphD3.graphDims.height);
	graphD3.yAxisGroup.call(yAxis);
	graphD3.line = createLineFunc(graphD3.x, graphD3.y);
	// Recreate grid
	createGrid(graphD3);
}

export function createNewGraphD3Obj() {
	return {
		svg: null,
		graphDims: null,
		graphMargins: null,
		graph: null,
		x: null,
		y: null,
		xAxisGroup: null,
		yAxisGroup: null,
		xAxis: null,
		yAxis: null,
		path: null,
		measurement: null,
		grid: {
			x: {},
			y: {},
		},
		resetZoomGroup: null,
	};
}

export function createAxes(graphD3, minX, maxX, minYValue, maxYValue) {
	// Create the graph d3 selection
	const svg = graphD3.svg;
	const graphMargins = graphD3.graphMargins;
	const graphDims = graphD3.graphDims;
	const graph = svg
	.append('g')
	.attr('transform', `translate(${graphMargins.left}, ${graphMargins.top})`)
	.attr('class', 'graph');
	graphD3.graph = graph;

	// Create x scale (time scale)
	const x = createXScaleFunc(graphDims.width, minX, maxX);
	// Create y scale (breathing scale)
	const y = createYScaleFunc(graphDims.height, minYValue, maxYValue);

	graphD3.minYValue = minYValue;
	graphD3.maxYValue = maxYValue;

	graphD3.x = x;
	graphD3.y = y;

	// Create axes groups
	let xAxisGroup = graph.append('g');
	xAxisGroup = positionXAxis(xAxisGroup, graphDims.height);
	const yAxisGroup = graph.append('g');

	graphD3.xAxisGroup = xAxisGroup;
	graphD3.yAxisGroup = yAxisGroup;

	// Create axes
	graphD3.xAxis = getXAxis(x);
	graphD3.yAxis = getYAxis(y, graphD3.graphDims.height);

	// Draw axes
	xAxisGroup.call(graphD3.xAxis);
	yAxisGroup.call(graphD3.yAxis);

	// Create grid
	createGrid(graphD3);

	return graphD3;
}
