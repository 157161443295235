<div *ngIf="toolbarService.getConnection()?.isConnected()" class="container">
	<div class="col-left">
		<div class="button-container">
			<ng-container *ngFor="let control of configurationService.pageMap[this.toolbarService.getRoute().data.pageName]?.controls">
				<!-- TODO refator rightSideControls -->
				<ng-container *ngIf="control.enable">
					<ng-container [ngSwitch]="control.type">
						<ng-container *ngSwitchCase="ConfigurablePageControlType.PAGE_SHOW_VALUE_CONTROL">
							<div class="show-value-container" [attr.data-selector]="control.type + '-button'" [matTooltip]="control.tooltip">
								{{parameters[control.param] || ''}}
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="ConfigurablePageControlType.PAGE_FPS">
							<div class="fps-container" [attr.data-selector]="control.type + '-button'" [matTooltip]="control.tooltip">
								{{control.label | translate}}: {{$any(toolbarService.getConnection()).fps | number:'1.0-1'}}
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="ConfigurablePageControlType.PAGE_SWITCH_CONTROL">
							<div class="additional-button" [attr.data-selector]="control.type + '-button'"
								 [matTooltip]="parameters[control.param] ? control.off_tooltip : control.on_tooltip">
								<button (click)="onAction(control)" [disabled]="control.disabled"
								[class.button-active]="control.changeColorUponClick && parameters[control.param]">
									<span class="material-icons md-22" [innerHTML]="control.icon || 'info' | materialIcon"></span>
									<div [innerHTML]="control.label | translate | htmlNewLine"></div>
								</button>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="ConfigurablePageControlType.PAGE_SWITCH_MULTIPLE_CONTROL">
							<div class="additional-button" [attr.data-selector]="control.type + '-button'"
								 [matTooltip]="control.active ? control.off_tooltip : control.on_tooltip">
								<button (click)="onAction(control)" [disabled]="control.disabled"
								[class.button-active]="control.active">
									<span class="material-icons md-22" [innerHTML]="control.icon || 'info' | materialIcon"></span>
									<div [innerHTML]="control.label | translate | htmlNewLine"></div>
								</button>
							</div>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<div *ngIf="!rightSideControls.includes(control.type)" class="additional-button"
								 [attr.data-selector]="control.type + '-button'" [matTooltip]="control.tooltip">
								<button (click)="onAction(control)" [disabled]="control.disabled"
									[class.button-active]="control.active">
									<span class="material-icons md-22" [innerHTML]="control.icon || 'info' | materialIcon"></span>
									<div [innerHTML]="control.label | translate | htmlNewLine"></div>
								</button>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<ng-container
		*ngIf="!toolbarService.getIsFloorPlan() && !toolbarService.getIsMultipleSensors() && !toolbarService.getIsSmartBuildings() && !toolbarService.getIsSmartCooler() && !toolbarService.getIsRoomOccupancy()">
		<div class="d-flex button-container button-container-right">
			<ng-container *ngFor="let control of configurationService.pageMap[this.toolbarService.getRoute().data.pageName]?.controls">
				<!-- TODO refator rightSideControls -->
				<ng-container *ngIf="control.enable && rightSideControls.includes(control.type)">
					<button *ngVar="isControlDisabled(control) as disabled" class="button-active border-accent"
							 [attr.data-selector]="control.type + '-button'" matRipple
							 (click)="onAction(control)"
							 [disabled]="disabled"
							 [matTooltip]="control.tooltip">
						<span class="material-icons md-22" [class.play-and-record]="control.type === 'page_record' && !toolbarService.getConnection()?.isRunned()"
							  [innerHTML]="(toolbarService.getConnection()?.isRunned() && !disabled ? 'material_stop' : control.icon) || 'info' | materialIcon"></span>
						<div [innerHTML]="(toolbarService.getConnection()?.isRunned() && !disabled ? 'STOP' : control.label) | translate | htmlNewLine"></div>
					</button>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>
</div>
