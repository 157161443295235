import {Component, ElementRef, Input, OnInit} from '@angular/core';

/**
 * Component allows to configure spaces in online mode.
 * Component renders add button, table (columns: active: checkbox, name: text) for spaces.
 */
@Component({
	selector: 'app-spaces',
	templateUrl: './spaces.component.html',
	styleUrls: ['./spaces.component.scss']
})
export class SpacesComponent implements OnInit {

	@Input() spaces;

	constructor(private hostElement: ElementRef) {
	}

	ngOnInit() {
	}

	addNewSpace() {
		this.spaces.push({
			name: ''
		});
		setTimeout(() => {
			let inputs = this.hostElement.nativeElement.querySelectorAll('table tbody input[type="text"]');
			inputs[inputs.length - 1].focus();
		});
	}

	changeCurrentSpace(space) {
		this.spaces.forEach(s => s.current = false);
		space.current = true;
	}

	checkSpaces() {
		this.spaces.forEach(space => {
			delete space.error;
		});
		let spacesWithSameName = this.spaces.filter(space => this.spaces.filter(s => s.name === space.name).length > 1);
		let spacesWithEmptyName = this.spaces.filter(space => !space.name);
		spacesWithSameName.forEach(space => {
			space.error = 'DUPLICATE_SPACE_NAME';
		});
		spacesWithEmptyName.forEach(space => {
			space.error = 'EMPTY_SPACE_NAME';
		});
		return spacesWithSameName.length === 0 && spacesWithEmptyName.length === 0;
	}
}
