import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BusEventService} from '../../../../../services/bus-event.service';
import {axesSystem2D, originPosition} from '../../../../../utils/utils';

/**
 * Base class for rendering heatmap or/and positions of people.
 * Used for InCar, Tracker app.
 */
@Directive()
export class HeatmapTargetTrackerBase implements OnInit, AfterViewInit {

	@Input() data: {
		data?: Array<Array<number>>;
		locationData?: Array<Array<number>>;
		targetsIdData?: Array<number>;
		postureData?: Array<string>;
		RetailAreaNames?: Array<string>;
		RetailAreas?: Array<Array<number | 'NaN'>>;
		InCarCabinVertexs?: Array<number>;
	} = {};
	@Input() parameters: any = {};
	@Input() rotate = 0;
	@Input() reflect = false;
	@Input() isFloorPlan = false;
	@Input() originPosition: originPosition = 'leftTop';

	VIEWBOX_W = 100;
	VIEWBOX_H = 100;
	ICON_H = 8;
	ICON_W = 8;

	xScaleCoefficient = 1;
	yScaleCoefficient = 1;
	clientHeight;
	clientWidth;

	displayAxes = 0;
	arena: any = {};

	protected originalOriginPosition: originPosition = this.originPosition;

	constructor(protected hostElement: ElementRef,
				protected busEventService: BusEventService) {
	}

	ngOnInit() {
		if (this.isFloorPlan) {
			this.ICON_H = 4;
			this.ICON_W = 4;
			this.updateGlobalArena();
		}
		setTimeout(() => {
			this.resizeViewBox();
		});
	}

	ngAfterViewInit() {
		// Update sensor size
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		});
	}

	drawRawData() {}

	drawAxes() {}

	getPosition(x0, y0, z0, axes, iconDim?) {
		let [x, y] = [[x0, y0, z0][axes[0]], [x0, y0, z0][axes[1]]];
		return this.getSvgPosition(x, y, axes, iconDim);
	}

	getSvgPosition(x, y, axes, iconDim?) {
		let _x = (x - this.arena[axes[0] * 2]) / (this.arena[axes[0] * 2 + 1] - this.arena[axes[0] * 2]);
		let _y = (y - this.arena[axes[1] * 2]) / (this.arena[axes[1] * 2 + 1] - this.arena[axes[1] * 2]);
		if (this.originPosition === 'leftBottom') {
			_y = 1 - _y;
		}
		if ([axesSystem2D.xy, axesSystem2D.xz].includes(this.displayAxes)) {
			_x = 1 - _x;
		}
		let tmp = _x;
		switch (this.rotate) {
			case 3:
				_x = 1 - _y;
				_y = tmp;
				break;
			case 2:
				_x = 1 - _x;
				_y = 1 - _y;
				break;
			case 1:
				_x = _y;
				_y = 1 - tmp;
				break;
			default:
				break;
		}
		if (this.reflect) {
			_x = 1 - _x;
		}
		_x = _x * this.VIEWBOX_W;
		_y = _y * this.VIEWBOX_H;
		if (iconDim) {
			_x -= iconDim[0] / 2;
			_y -= iconDim[1] / 2;
		}
		return [_x, _y];
	}

	public getAxes(): any {
		switch (this.displayAxes) {
			case axesSystem2D.xy:
				return [0, 1];
			case axesSystem2D.xz:
				return [0, 2];
			case axesSystem2D.yz:
				return [1, 2];
		}
	}

	public getAxesAttr(): string {
		let axes = this.getAxes();
		return axes[0] === 1 ? 'yz' : axes[1] === 1 ? 'xy' : 'xz';
	}

	protected resizeViewBox() {
		this.clientHeight = this.hostElement.nativeElement.clientHeight;
		this.clientWidth = this.hostElement.nativeElement.clientWidth;
		const aspect = this.clientWidth / this.clientHeight;

		this.VIEWBOX_H = +(this.VIEWBOX_W / aspect).toFixed();
		this.drawAxes();
		this.drawRawData();
	}

	protected updateGlobalArena() {
		if (this.parameters['socketInfo']['backgroundImage']) {
			this.arena = [0, +this.parameters['socketInfo']['globalRoomWidth'] || 0, 0, +this.parameters['socketInfo']['globalRoomHeight'] || 0, 0, 0];
		}
	}
}
