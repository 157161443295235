import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsService, VALUE_TO_DELETE} from '../../services/settings.service';
import {SensorSocket} from '../../services/system/sensor-socket';
import {ModalService} from '../../services/modal.service';
import {getChangedParameters, isSettingInReadOnly, makeDeepCopy} from '../../utils/utils';
import {SensorsService} from '../../services/system/sensors.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {StorageService} from '../../services/storage.service';
import {BusEventService} from 'src/app/services/bus-event.service';
import {HomeAutomationServerSocket} from '../../services/system/ham-socket';
import {Subscription} from 'rxjs';
import {SpacesComponent} from './spaces/spaces.component';
import {RestService} from '../../services/system/rest.service';
import {ConnectionStatus} from '../../services/system/connection';
import {SensorMountPlane} from '../../consts';
import {ToolbarService} from '../../services/toolbar.service';
import {RetailFloor, RetailGeoFenceType} from '../../models/models';

enum SETTINS_TABS {
	SENSOR,
	TARGETS,
	ADVANCED,
	RULES,
	LOCATIONS,
	ACTIVE_VIEWER,
	FALL_DETECTION,
	FLOOR_PLAN,
	WORK_MODE,
	SPACES,
	DEVICES,
	RECORDING,
	DEVELOPER
}

/**
 * Component represents modal window with settings. Window is separated on 3 blocks:
 * left - menu, right top - content, right bottom - buttons.
 */
@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

	@Input() isnNextBtnVisibility = true;

	@ViewChild('fileuploadSettings') fileuploadSettingsInput: ElementRef;
	@ViewChild(SpacesComponent) spacesComponent: SpacesComponent;

	sensorSocket: SensorSocket;
	SETTINS_TABS = SETTINS_TABS;

	maximumNumberOfTargets = 40;
	minimumNumberOfTargets = 1;

	activeMenuItem: SETTINS_TABS = SETTINS_TABS.SENSOR;
	settingForm: FormGroup;
	readonly = false;
	isSmartHome = false;

	environment = environment;

	initialSettings;
	initialLocations;
	isUserSettings = false;
	isPatientForm = false;
	initialFloorData: RetailFloor = {
		floor_name: '',
		floor_number: 0,
		pixel_to_m_scale_factor: 0,
		base64_image: '',
		image: '',
		max_x: 0,
		max_y: 0,
		max_z: 0,
		parent_building: null,
		min_x: 0,
		min_y: 0,
		min_z: 0
	};

	devices: Array<any> = [];
	socketPool: Array<string> = [];
	spaces: Array<any> = [];
	currentSpace;
	loading = false;

	// Smart Home
	rooms: Array<any> = [];
	zones = [];
	activeRoom;
	activeTab = 0;

	existingRoomsNames: Array<string> = [];

	measurementsForm: FormGroup;
	patientId = '';

	locationsTypes: Array<RetailGeoFenceType>;

	private settingSaved = false;
	private subscriptions: Array<Subscription> = [];
	private spaceInfo;
	private isDestroyed = false;

	private onKeyDown = (event: KeyboardEvent) => {
		if (!this.modalService.openedDialogs.length) {
			switch (event.key) {
				case 'Enter':
					if (document.activeElement) {
						document.activeElement['blur']();
					}
					setTimeout(() => {
						if (this.isSmartHome) {
							this.onSaveSmartHome();
						} else {
							this.onSave();
						}
					});
					break;
				case 'Escape':
					this.onCancel();
					break;
			}
		}
	};

	constructor(private fb: FormBuilder,
				private translate: TranslateService,
				private settingsService: SettingsService,
				private sensorsService: SensorsService,
				private dialogRef: MatDialogRef<SettingsComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private modalService: ModalService,
				private storageService: StorageService,
				private busEventService: BusEventService,
				private restService: RestService,
				private toolbarService: ToolbarService) {

		this.sensorSocket = data.sensorSocket;
		this.isUserSettings = data.isUserSettings;
		this.isPatientForm = data.isPatientForm;
	}

	async ngOnInit() {
		document.addEventListener('keydown', this.onKeyDown);
		if (this.isPatientForm) {
			this.measurementsForm = new FormGroup({
				'gender': new FormControl(null, [
					Validators.required
				]),
				'heartRate_min': new FormControl(null, [
					Validators.required,
					Validators.min(40),
					Validators.max(180)
				]),
				'heartRate_max': new FormControl(null, [
					Validators.required,
					Validators.min(40),
					Validators.max(180)
				]),
				'heartRate_value': new FormControl(null, [
					Validators.required,
					Validators.min(40),
					Validators.max(180)
				]),
				'temperature': new FormControl(null, [
					Validators.required,
					Validators.min(35),
					Validators.max(42)
				]),
				'bloodPressure': new FormGroup({
					'diastolic': new FormControl(null, [
						Validators.required
					]),
					'systolic': new FormControl(null, [
						Validators.required
					])
				}),
				'height': new FormControl(null, [
					Validators.required,
					Validators.min(1.4),
					Validators.max(2.2)
				]),
				'weight': new FormControl(null, [
					Validators.required,
					Validators.min(30),
					Validators.max(200)
				]),
				'age': new FormControl(null, [
					Validators.required,
					Validators.min(5),
					Validators.max(100)
				]),
				'isCoronaPositive': new FormControl(false, [
					Validators.required
				]),
				'isPersonCoughing': new FormControl(false, [
					Validators.required
				]),
				'mainHealthCondition': new FormControl(null),
				'isSensorInPosition': new FormControl(false, [
					Validators.required
				]),
				'isPersonInPosition': new FormControl(false, [
					Validators.required
				]),
				'hasHeartPacer': new FormControl(false, [
					Validators.required
				]),
				'hasAsthma': new FormControl(false, [
					Validators.required
				]),
				'hasCOPD': new FormControl(false, [
					Validators.required
				]),
				'reasonForArrival': new FormControl(null),
				'saturation': new FormControl(null, [
					Validators.required
				]),
				'bodyTemperatureMethod': new FormControl(null, [
					Validators.required
				]),
			});

			let settings = await this.settingsService.getAllSettings(this.sensorSocket.url);
			if (settings['guiParameters']['getDefaultPatientForm']) {
				let value = {};
				Object.keys(this.measurementsForm.controls).forEach(key => {
					if (['isCoronaPositive', 'isPersonCoughing', 'hasHeartPacer', 'hasAsthma', 'hasCOPD', 'isSensorInPosition', 'isPersonInPosition'].includes(key)) {
						value[key] = `ProcessorCfg.HeartBeat.PatientData.${key}` in settings['sensorParameters'] ? !!settings['sensorParameters'][`ProcessorCfg.HeartBeat.PatientData.${key}`] : false;
					} else if (key === 'bloodPressure') {
						value['bloodPressure'] = {
							'diastolic': 'ProcessorCfg.HeartBeat.PatientData.bloodPressure.diastolic' in settings['sensorParameters'] ? settings['sensorParameters'][`ProcessorCfg.HeartBeat.PatientData.bloodPressure.diastolic`] : null,
							'systolic': 'ProcessorCfg.HeartBeat.PatientData.bloodPressure.systolic' in settings['sensorParameters'] ? settings['sensorParameters'][`ProcessorCfg.HeartBeat.PatientData.bloodPressure.systolic`] : null
						};
					} else {
						value[key] = `ProcessorCfg.HeartBeat.PatientData.${key}` in settings['sensorParameters'] ? settings['sensorParameters'][`ProcessorCfg.HeartBeat.PatientData.${key}`] : null;
					}
				});
				this.measurementsForm.setValue(value);
			}
			this.sensorSocket.getSensorParameters({'ProcessorCfg.HeartBeat.PatientData.patientId': null}).then(sensorParameters => {
				this.patientId = sensorParameters['ProcessorCfg.HeartBeat.PatientData.patientId'];
			});
		} else if (this.isUserSettings) {
			this.activeMenuItem = SETTINS_TABS.SPACES;
			this.storageService.getItem('spaces').then(spaces => {
				this.spaces = spaces;
				this.currentSpace = spaces.find(s => s.current);
			});
		} else if (this.sensorSocket instanceof HomeAutomationServerSocket) {
			this.loading = true;
			this.isSmartHome = true;
			this.activeMenuItem = SETTINS_TABS.DEVICES;
			this.settingForm = this.fb.group({
				imageSrc: new FormControl(),
				globalRoomWidth: new FormControl(),
				globalRoomHeight: new FormControl(),
				roomName: new FormControl(),
				isCoverageFeet: new FormControl(),
				mountPlane: new FormControl(),
				RoomDimsX: new FormControl(),
				RoomDimsY: new FormControl(),
				RoomDimsZ: new FormControl(),
				zones: new FormArray([]),
				rules: new FormArray([])
			});
			this.storageService.getItem('socketPool').then(socketPool => {
				this.socketPool = socketPool;
				this.storageService.getItem('spaceInfo').then(spaceInfo => {
					this.spaceInfo = spaceInfo;
					var pool = (this.socketPool || []).map(url => {
						return this.settingsService.getAllSettings(url);
					});
					Promise.all(pool).then(settings => {
						let socketsNames = settings.map((parameters: any, i) => parameters['socketInfo'] && parameters['socketInfo'].name || this.socketPool[i]),
							zonesNamesByRooms = {};

						settings.forEach((parameters, i) => {
							let roomName = parameters['socketInfo'] && parameters['socketInfo'].name || this.socketPool[i];

							this.rooms.push({
								roomName,
								url: this.socketPool[i],
								ip: this.socketPool[i].split(':')[0],
								parameters,
								devices: []
							});
							if (parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names']) {
								zonesNamesByRooms[roomName] = parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'];
							}
						});

						if (this.rooms.length) {
							this.selectRoom(this.rooms[0]);
						}

						this.subscriptions.push(this.sensorsService.hamDevices.subscribe(deviceList => {
							this.devices = deviceList.devices.map((device: any) => {
								return {
									name: device.name,
									selected: false,
									disabled: false
								};
							});
							let deviceMap = spaceInfo.devices || {};
							this.rooms.forEach(r => r.devices = []);
							this.devices.forEach(device => {
								if (deviceMap[device.name]) {
									let room = this.rooms.find(r => r.roomName === deviceMap[device.name].name);
									if (room) {
										room.devices.push(device.name);
										device['roomName'] = room.roomName;
									}
								}
							});
							this.loading = false;
							if (this.activeRoom) {
								this.selectRoom(this.activeRoom);
							}
						}));

						let rules = (spaceInfo.rules || []).filter(rule => {
							let triggersIsValid = rule.rule.triggers.every(t => socketsNames.includes(t.roomName) && zonesNamesByRooms[t.roomName] && zonesNamesByRooms[t.roomName].includes(t.zoneName)),
								actionsIsValid = rule.rule.actions.every(a => socketsNames.includes(a.roomName));

							return triggersIsValid && actionsIsValid;
						});

						this.applyRules(rules);
					});
				});
			});
		} else {
			let settings;
			if (this.sensorSocket) {
				settings = await this.settingsService.getAllSettings(this.sensorSocket.url);
				this.initialSettings = settings;
			}
			this.subscriptions.push(this.sensorsService.parametersChange.subscribe(parameters => {
				if (parameters.url === this.sensorSocket.url && 'ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat' in parameters.parameters) {
					this.initialSettings['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'] = parameters.parameters['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'];
					this.initialSettings = makeDeepCopy(this.initialSettings);
				}
			}));
			this.subscriptions.push(this.sensorsService.anotherClientParametersChanged.subscribe(parameters => {
				this.settingsService.setSettingsForm({
					sensorParameters: parameters
				}, this.settingForm);
			}));
			if (this.sensorSocket) {
				this.subscriptions.push(this.sensorSocket.status.subscribe(status => {
					if (this.sensorSocket.isAnotherClientConnected && status === ConnectionStatus.INITIALIZING) {
						this.modalService.showMessage('ANOTHER_CLIENT_STARTED_SENSOR').afterClosed().toPromise().then(() => {
							this.dialogRef.close(false);
						});
					}
				}));
			}
			this.readonly = this.sensorSocket && isSettingInReadOnly(this.sensorSocket);
			this.settingForm = await this.settingsService.getSettingsForm(this.sensorSocket, settings);
			if (this.sensorSocket && this.sensorSocket.isSensorsNetworkEnabled) {
				this.storageService.getItem('socketPool').then(socketPool => {
					if (!this.isDestroyed) {
						if (socketPool.length) {
							var pool = (socketPool || []).map(url => {
								return this.settingsService.getSocketInfo(url);
							});
							Promise.all(pool).then((socketInfo: any) => {
								this.existingRoomsNames = socketInfo.map(info => info.name);
							});
						}
					}
				});
				this.subscriptions.push(this.sensorsService.anotherClientSensorLocationDataChanged.subscribe(data => {
					this.settingsService.setSettingsForm({
						sensorNetworkParameters: {
							globalSensorLocation: data['ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.position'],
							globalSensorOrientation: data['ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.orientation']
						}
					}, this.settingForm);
				}));
			}
		}
	}

	ngOnDestroy() {
		document.removeEventListener('keydown', this.onKeyDown);
		this.isDestroyed = true;
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	async onSave() {
		if (this.isPatientForm) {
			if (!this.measurementsForm.invalid) {
				this.modalService.confirmPatientForm('CONFIRM_PATIENT_FORM_SUBMIT', {}, undefined, 'START', undefined, ['patient-confirm', 'modal-default']).afterClosed().toPromise().then(res => {
					if (res) {
						this.dialogRef.close(this.measurementsForm.getRawValue());
					}
				});
			} else {
				this.measurementsForm.markAllAsTouched();
				this.measurementsForm.markAsDirty();
				this.measurementsForm.updateValueAndValidity();
			}
		} else if (this.isUserSettings) {
			if (this.spacesComponent.checkSpaces()) {
				this.storageService.setItem('spaces', this.spaces).then(() => {
					this.dialogRef.close(this.currentSpace !== this.spaces.find(s => s.current));
				});
			}
		} else if (this.sensorSocket && this.sensorSocket.isMultipleSensors) {
			if (this.settingForm.getRawValue()['imageSrc']) {
				this.settingsService.saveSocketInfo(this.sensorSocket.url, {
					globalRoomWidth: +this.settingForm.getRawValue()['globalRoomWidth'],
					globalRoomHeight: +this.settingForm.getRawValue()['globalRoomHeight'],
					backgroundImage: this.settingForm.getRawValue()['imageSrc']
				}).then(info => {
					this.busEventService.multipleRoomsParamsChanged.emit(info);
				});
			}
			this.dialogRef.close(true);
		} else if (this.checkBeforeSave()) {
			let cb = async () => {
				this.settingForm.controls.isChanged.setValue(false);
				this.settingSaved = true;
				this.onHideSettings(true, await this.saveSettings());
			};

			if (this.sensorSocket && this.sensorSocket.isAnotherClientConnected) {
				this.translate.get('WARNING_POPUP_ANOTHER_CLIENT_IS_CONNECTED_TO_THIS_HOST').subscribe((messageText: string) => {
					this.modalService.confirm(`${messageText}`).afterClosed().toPromise().then(res => {
						if (res) {
							cb();
						}
					});
				});
			} else {
				cb();
			}
		}
	}

	async onHideSettings(saved = true, diff?) {
		this.dialogRef.close(saved);
		if (this.settingSaved && this.sensorSocket) {
			if (Object.keys(diff).length) {
				Object.keys(diff).forEach(key => {
					if (!this.sensorSocket.isEditableParameter(key)) {
						delete diff[key];
					}
				});
				this.sensorsService.sendSettings(this.sensorSocket, true, diff).then(() => {
					if ('ProcessorCfg.Common.sensorOrientation.mountPlane' in diff || 'ProcessorCfg.Common.sensorOrientation.transVec' in diff) {
						this.updateBoardToWebGUITransMat();
					}
				});

				if ('ProcessorCfg.ExternalGUI.Zones.names' in diff || 'ProcessorCfg.ExternalGUI.Zones.interestArea' in diff) {
					let has = this.sensorsService.getHomeAutomationServerSocket();
					if (has && has.isConnected()) {
						let roomName = this.settingForm.getRawValue()['roomName'] || this.sensorSocket.url,
							zones = this.settingsService.prepareLocalZonesToSocket(this.settingForm.getRawValue()['zones'], roomName);

						// SET_ZONES
						has.setZones(zones);
					}
					this.sensorsService.validateAndUpdateRules();
				}
			} else {
				this.sensorsService.triggerParametersChange(this.sensorSocket.url, this.initialSettings['sensorParameters']);
			}
			if (this.sensorSocket.isSensorsNetworkEnabled) {
				let data = this.settingForm.getRawValue(),
					sensorNetworkParameters = Object.assign({}, this.initialSettings['sensorNetworkParameters'], {
						globalSensorLocation: [+data['globalSensorX'], +data['globalSensorY']],
						globalSensorOrientation: +data['globalSensorOrientation']
					}),
					nameIsChanged = data['roomName'] !== this.initialSettings['socketInfo']['name'],
					locationDataDiff = getChangedParameters(sensorNetworkParameters, this.initialSettings['sensorNetworkParameters']);
				if (nameIsChanged || Object.keys(locationDataDiff).length) {
					if (nameIsChanged) {
						this.sensorsService.validateAndUpdateRules();
					}
					this.sensorSocket.setNetworkSensorLocationData({
						'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.position': sensorNetworkParameters['globalSensorLocation'],
						'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.orientation': sensorNetworkParameters['globalSensorOrientation']
					}).catch(console.error.bind(console));
				}
			}
		} else if (this.sensorSocket && this.settingForm.controls.isChanged.value) {
			/**
			 * Restore initial settings
			 */
			this.sensorsService.sendSettings(this.sensorSocket, true, {
				'ProcessorCfg.Common.sensorOrientation.mountPlane': this.initialSettings['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'],
				'ProcessorCfg.Common.sensorOrientation.transVec': this.initialSettings['sensorParameters']['ProcessorCfg.Common.sensorOrientation.transVec'],
				'ProcessorCfg.MonitoredRoomDims': this.initialSettings['sensorParameters']['ProcessorCfg.MonitoredRoomDims']
			}).then(() => {
				this.updateBoardToWebGUITransMat();
			});
		}
	}

	onSaveSmartHome() {
		this.settingForm.updateValueAndValidity();
		if (this.settingForm.valid) {
			this.rooms.forEach(room => {
				this.settingsService.saveSensorParameters(room.url, room.parameters['sensorParameters']).then(() => {
					// Send updated zones
					let socket = this.sensorsService.getSensorSocket(room.url);
					if (socket && socket.isConnected()) {
						this.sensorsService.sendSettings(socket, true);
					}
				});
			});
			let devices = {};
			let rules = this.settingForm.getRawValue()['rules'];
			rules.forEach(rule => {
				delete rule['isEdit'];
			});
			this.devices.forEach(device => {
				let room = this.rooms.find(r => r.devices.includes(device.name));
				devices[device.name] = {
					name: room && room.roomName || '',
					ip: room && room.ip || ''
				};
			});

			let newInfo = Object.assign({}, this.spaceInfo, {
				devices,
				rules
			});
			this.storageService.setItem('spaceInfo', {
				devices,
				rules
			}).then(() => {
				this.busEventService.smartHomeParamsChanged.emit(newInfo);
			});

			(this.sensorSocket as any).setRules({
				rules
			});
			(this.sensorSocket as any).setDevidesLocation(Object.keys(devices).map(device => {
				return {
					name: device,
					room_name: devices[device].name,
					room_ip: devices[device].ip
				};
			}));
			this.dialogRef.close(true);
		} else {
			(<FormArray>this.settingForm.get('rules')).controls.forEach(r => {
				(<FormArray>r.get('rule')!.get('triggers')).controls.forEach((t: FormGroup) => {
					Object.values(t.controls).forEach(c => {
						c.markAsTouched();
					});
				});
				(<FormArray>r.get('rule')!.get('actions')).controls.forEach((a: FormGroup) => {
					Object.values(a.controls).forEach(c => {
						c.markAsTouched();
					});
				});
			});
		}
	}

	onCancel() {
		if (this.isPatientForm || this.isUserSettings || this.isSmartHome || (this.sensorSocket && this.sensorSocket.isMultipleSensors)) {
			this.dialogRef.close(false);
		} else {
			if (!this.settingForm.controls.isChanged.value) {
				this.onHideSettings(false);
			} else {
				this.modalService.confirm(`${['WARNING_POPUP_MADE_SOME_CHANGES']}`, {}, 'Message', 'Save')
					.afterClosed().toPromise().then(res => {
					if (!res) {
						this.onHideSettings(false);
					} else {
						this.onSave();
					}
				});
			}
		}
	}

	// Active menu item
	setActiveMenuItem(id: SETTINS_TABS): void {
		this.activeMenuItem = id;
	}

	onClickOpenBtn() {
		this.fileuploadSettingsInput.nativeElement.click();
	}

	getDeviceImage(device) {
		let src = '',
			disabled = '';

		if (device.disabled || !device.selected) {
			disabled = '-disabled';
		}

		switch (true) {
			case device.name.toLowerCase().startsWith('light'):
				src = `assets/images/smart-home/light${disabled}.svg`;
				break;
			case device.name.toLowerCase().startsWith('alarm'):
				src = `assets/images/smart-home/alarm${disabled}.svg`;
				break;
			case device.name.toLowerCase().startsWith('ac'):
				src = `assets/images/smart-home/ac${disabled}.svg`;
				break;
		}
		return src;
	}

	applyDeviceToRoom(device) {
		if (device.selected) {
			this.activeRoom.devices.push(device.name);
			device.roomName = this.activeRoom.roomName;
		} else {
			delete device.roomName;
			this.activeRoom.devices.splice(this.activeRoom.devices.indexOf(device.name), 1);
		}
		this.updateRules();
	}

	clickOnDevice(device) {
		if (!device.disabled) {
			device.selected = !device.selected;
			this.applyDeviceToRoom(device);
		}
	}

	selectRoom(room) {
		this.saveZones();
		this.activeRoom = room;
		this.devices.forEach(device => {
			device.disabled = this.rooms.filter(r => r !== this.activeRoom).some(r => r.devices.includes(device.name));
			device.selected = room.devices.includes(device.name);
		});

		let isFeet = false,
			newValue: any = {
				'roomName': room.roomName,
				'isCoverageFeet': isFeet,
				'mountPlane': '',
				'zones': []
			};

		while ((this.settingForm.controls.zones as FormArray).length !== 0) {
			(this.settingForm.controls.zones as FormArray).removeAt(0);
		}
		if (room.parameters['guiParameters']) {
			if ('isCoverageFeet' in room.parameters['guiParameters']) {
				isFeet = room.parameters['guiParameters']['isCoverageFeet'];
				newValue['isCoverageFeet'] = isFeet;
			}
		}
		if ('ProcessorCfg.Common.sensorOrientation.mountPlane' in room.parameters['sensorParameters']) {
			newValue['mountPlane'] = room.parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'] || '';
		}
		if ('ProcessorCfg.ExternalGUI.Zones.interestArea' in room.parameters['sensorParameters']) {
			(room.parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'] || []).forEach(() => {
				(this.settingForm.controls.zones as FormArray).push(new FormGroup({
					id: new FormControl(),
					name: new FormControl(),
					type: new FormControl(),
					svg: new FormControl(),
					x: new FormControl(),
					y: new FormControl(),
					width: new FormControl(),
					height: new FormControl(),
					coordinates: new FormControl()
				}));
			});
			newValue['zones'] = this.settingsService.generateLocalZonesFromStored(room.parameters['sensorParameters']);
		}
		this.settingForm.patchValue(newValue);
	}

	saveZones() {
		if (this.activeRoom) {
			let settings = this.settingForm.getRawValue();
			this.activeRoom.parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'] = settings['zones'].filter(zone => zone.id != null).map(zone => {
				return [
					zone.coordinates[0][0],
					zone.coordinates[1][0],
					zone.coordinates[0][1],
					zone.coordinates[2][1],
					'-inf',
					'inf'
				];
			});
			this.activeRoom.parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'] = settings['zones'].map(zone => zone.name);
			this.updateZones();
		}
	}

	updateZones() {
		this.zones = [].concat(...this.rooms.map(room => {
			let {roomName} = room;
			return (room.parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'] || []).map(zoneName => {
				return {
					zoneName,
					roomName
				};
			});
		}));
		this.updateRules();
	}

	updateRules() {

		let settings = this.rooms.map(room => room.parameters),
			zonesNamesByRooms = {};

		settings.forEach((parameters, i) => {
			let roomName = parameters['socketInfo'] && parameters['socketInfo'].name || this.socketPool[i];

			if (parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names']) {
				zonesNamesByRooms[roomName] = parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'];
			}
		});
		let rules = this.settingForm.getRawValue()['rules'].filter(rule => {
			let triggersIsValid = rule.rule.triggers.every(t => zonesNamesByRooms[t.roomName] && zonesNamesByRooms[t.roomName].includes(t.zoneName)),
				actionsIsValid = rule.rule.actions.every(a => this.devices.find(d => d.name === a.type && d.roomName === a.roomName));

			return triggersIsValid && actionsIsValid;
		});

		this.applyRules(rules);
	}

	applyRules(rules) {
		while ((this.settingForm.controls.rules as FormArray).length !== 0) {
			(this.settingForm.controls.rules as FormArray).removeAt(0);
		}

		rules.forEach(rule => {
			let triggers = new FormArray([]),
				actions = new FormArray([]);

			rule.rule.triggers.forEach(() => {
				triggers.push(new FormGroup({
					zoneName: new FormControl(null, Validators.required),
					roomName: new FormControl(null, Validators.required),
					shouldBeOccupied: new FormControl(null, Validators.required)
				}));
			});
			rule.rule.actions.forEach(() => {
				actions.push(new FormGroup({
					type: new FormControl(null, Validators.required),
					roomName: new FormControl(null, Validators.required),
					value: new FormControl(null, Validators.required)
				}));
			});
			(<FormArray>this.settingForm.get('rules')).push(new FormGroup({
				name: new FormControl(''),
				isActive: new FormControl(true),
				isEdit: new FormControl(false),
				rule: new FormGroup({
					triggers: triggers,
					actions: actions
				})
			}));
		});

		this.settingForm.patchValue({rules});
	}

	setActiveTab(tabIndex, withCheck = false) {
		if (withCheck && this.activeTab > tabIndex || !withCheck) {
			this.activeTab = tabIndex;
		}
	}

	onSensorPositionChanged(e) {
		let settings = this.settingsService.prepareSettingsToSave(this.settingForm.getRawValue(), makeDeepCopy(this.initialSettings));
		if (this.checkSensorMonitoredArena()) {
			this.sensorsService.sendSettings(this.sensorSocket, true, {
				'ProcessorCfg.Common.sensorOrientation.mountPlane': settings['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'],
				'ProcessorCfg.Common.sensorOrientation.transVec': settings['sensorParameters']['ProcessorCfg.Common.sensorOrientation.transVec'],
				'ProcessorCfg.MonitoredRoomDims': settings['sensorParameters']['ProcessorCfg.MonitoredRoomDims']
			}).then(() => {
				this.updateBoardToWebGUITransMat();
			});
		} else {
			e.isPrevented = true;
		}
	}

	async updateBoardToWebGUITransMat() {
		await this.settingsService.saveSensorParameters(this.sensorSocket.url, {
			'ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat': VALUE_TO_DELETE
		});
		this.sensorSocket.getSensorParameters({
			'ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat': null
		});
	}

	checkBeforeSave() {
		let result = true;
		if (this.settingForm.controls.maxNumberOfTargets.value < this.minimumNumberOfTargets) {
			this.modalService.showError(`Maximum number of Targets must be greater than ${this.minimumNumberOfTargets}`);
			result = false;
		} else if (!this.settingForm.controls.mountPlane.value.length) {
			this.modalService.showError('Pick a back wall or ceiling, please.');
			result = false;
		} else if (this.sensorSocket && this.sensorSocket.isSensorsNetworkEnabled && this.existingRoomsNames.includes(this.settingForm.controls.roomName.value) && this.initialSettings.socketInfo.name !== this.settingForm.controls.roomName.value) {
			this.modalService.showError('NAME_ALREADY_IN_USE_MESSAGE');
			result = false;
		} else if (!this.checkSensorMonitoredArena()) {
			result = false;
		}
		return result;
	}

	private saveSettings() {
		this.data.settingForm = this.settingForm;
		if (this.sensorSocket) {
			return this.settingsService.saveSettingsForm(this.sensorSocket.url, this.settingForm);
		} else {
			return Promise.resolve({});
		}
	}

	private checkSensorMonitoredArena() {
		let isInvalid = false;

		switch (this.settingForm.controls.mountPlane.value) {
			case SensorMountPlane.CEILING:
				isInvalid = +this.settingForm.controls.MonitoredRoomDimsZMax.value > +this.settingForm.controls.sensorHeight.value;
				if (isInvalid) {
					this.modalService.showError('SENSOR_HEIGHT_CAN_NOT_BE_LOWER_THAN_Z_MAX');
				}
				break;
			case SensorMountPlane.BACKWALL:
				isInvalid = +this.settingForm.controls.MonitoredRoomDimsYMin.value < 0;
				if (isInvalid) {
					this.modalService.showError('SENSOR_Y_COULD_NOT_BE_LESS_THAN_ZERO');
				}
				break;
		}

		return !isInvalid;
	}
}
