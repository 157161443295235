import {interpolateRgbBasis, rgb, scaleSequential, transpose} from 'd3';
import * as THREE from 'three';

/**
 * Helper class for scene rendering.
 */

/**
 * These colors should match the tracker target images` colors found in Targets.js layer
 *
 */

// The floor in point cloud 2D and 3D is lighter on 25%
export const POINT_CLOUD_LAYER_FLOOR_COLOR = {r: 0, g: 0, b: 0};

export const BASE_COLORS_AS_OBJ = [
	{r: 0, g: 190, b: 212}, // Light blue - 1
	{r: 140, g: 194, b: 74}, // Green - 2
	{r: 255, g: 193, b: 8}, // Yellow - 3
	{r: 128, g: 0, b: 128}, // Purple - 4
	{r: 255, g: 127, b: 39}, // Orange - 5
	{r: 255, g: 174, b: 200}, // Pink - 6
	{r: 63, g: 72, b: 204}, // Blue - 7
	{r: 120, g: 84, b: 72}, // Light Brown - 8
	{r: 0, g: 214, b: 20}, // Light Green - 9
	{r: 71, g: 135, b: 232}, // Blue
	{r: 215, g: 88, b: 0}, // Dark Orange - 11
	{r: 103, g: 103, b: 0}, // Dark green - 12
	{r: 164, g: 133, b: 47}, // Dark Yellow - 13
	{r: 255, g: 226, b: 120}, // Light Yellow - 14
	{r: 251, g: 96, b: 42}, // Brown - 15
];

export const BASE_COLORS = BASE_COLORS_AS_OBJ.map(rgbObj => 'rgb(' + rgbObj.r + ', ' + rgbObj.g + ', ' + rgbObj.b + ')');

export const TARGET_CENTER_COLOR = 'rgb(255, 0, 0)';

const d3 = {scaleSequential, interpolateRgbBasis, rgb, transpose};

const rotate90DegRight = arr => {
	const result = new Array(arr[0].length);
	for (let i = 0; i < arr[0].length; i++) {
		result[i] = new Array(arr.length);
		for (let j = 0; j < arr.length; j++) {
			result[i][j] = arr[arr.length - j - 1][i];
		}
	}
	return result;
};

export const rotateAndReflect = (arr, reflect, rotate) => {
	for (let i = 0; i < rotate; ++i) {
		arr = rotate90DegRight(arr);
	}
	if (reflect) {
		return arr.slice().reverse();
	}
	return arr;
};

export const colormaps = {
	Jet: [
		d3.rgb(0, 0, 131),
		d3.rgb(0, 60, 170),
		d3.rgb(5, 255, 255),
		d3.rgb(255, 255, 0),
		d3.rgb(250, 0, 0),
		d3.rgb(128, 0, 0),
	],
	EmbeddedJetHot: [
		d3.rgb(0, 143, 51),
		d3.rgb(32, 166, 58),
		d3.rgb(143, 210, 80),
		d3.rgb(199, 233, 91),
		d3.rgb(255, 255, 102),
		d3.rgb(255, 225, 92),
		d3.rgb(255, 194, 82),
		d3.rgb(255, 163, 71),
	],
	EmbeddedJetCold: [
		d3.rgb(105, 105, 250),
		d3.rgb(124, 92, 219),
		d3.rgb(133, 86, 204),
		d3.rgb(148, 76, 179),
		d3.rgb(167, 43, 148),
		d3.rgb(188, 48, 112),
		d3.rgb(216, 28, 65),
		d3.rgb(255, 0, 0),
	],
	Hot: [d3.rgb(255, 255, 255), d3.rgb(255, 210, 0), d3.rgb(230, 0, 0), d3.rgb(0, 0, 0)],
};

export const colorscale = d3.scaleSequential(d3.interpolateRgbBasis(colormaps['Jet']));

/**
 * TODO: Document!
 * @param {*} targetCenterColor
 * @param {*} colorScaleFunc
 * @param {*} ic - a color per target ID
 */
export const makeGetColor = (targetCenterColor, colorScaleFunc, ic) => (
	isRaw,
	targetID,
	isTargetCenterWithPCL = false
) => {
	if (isTargetCenterWithPCL) {
		return targetCenterColor;
	} else if (isRaw) {
		return colorScaleFunc(targetID);
	} else {
		return ic[targetID]['color'];
	}
};

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
export const hslToRgb = (h, s, l) => {
	var r, g, b;

	if (s === 0) {
		r = g = b = l; // achromatic
	} else {
		var hue2rgb = function hue2rgb(p, q, t) {
			if (t < 0) {
				t += 1;
			}
			if (t > 1) {
				t -= 1;
			}
			if (t < 1 / 6) {
				return p + (q - p) * 6 * t;
			}
			if (t < 1 / 2) {
				return q;
			}
			if (t < 2 / 3) {
				return p + (q - p) * (2 / 3 - t) * 6;
			}
			return p;
		};

		var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		var p = 2 * l - q;
		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);
	}

	return {r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255)};
};

export const HEATMAP_RESOLUTION = 250;
export const HEATMAP_COLORS = Array(HEATMAP_RESOLUTION)
	.fill(undefined)
	.map((_, value) => {
		let hslColor: any = new THREE.Color(
			'hsl(' +
			(1 - value / (HEATMAP_RESOLUTION - 1)) * 250 +
			', 100%, ' +
			Math.round((value / (HEATMAP_RESOLUTION - 1)) * 10 + 45) +
			'%)'
		);
		let hsl = {h: 0, s: 0, l: 0};
		hslColor.getHSL(hsl);
		let rgbColor = hslToRgb(hsl.h, hsl.s, hsl.l);
		return new THREE.Color(rgbColor.r, rgbColor.g, rgbColor.b);
	});

export function rgbToThreeHexColor(r, g, b) {
	function toHex(c) {
		var hex = c.toString(16);
		return hex.length === 1 ? '0' + hex : hex;
	}

	return '0x' + toHex(r) + toHex(g) + toHex(b);
}
