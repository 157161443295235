import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {
	transform<T>(items: Array<T>, key: string, value: any, equal = true): Array<T> {
		if (!items || !key || !value) {
			return items;
		}
		if (value instanceof Array) {
			return items.filter(item => value.includes(item[key]));
		} else {
			if (equal) {
				return items.filter(item => item[key] === value);
			} else {
				return items.filter(item => item[key].toLowerCase().includes(value.toLowerCase()));
			}
		}
	}
}
