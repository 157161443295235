import {EventEmitter} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {RunMode} from './sensor-socket';

export enum ConnectionStatus {
	ANOTHER_CLIENT_CONNECTED = 'ANOTHER_CLIENT_CONNECTED',
	INITIALIZING = 'INITIALIZING',
	CONFIGURING = 'CONFIGURING',
	CALIBRATING = 'CALIBRATING',
	ERROR = 'ERROR',
	IMAGING = 'IMAGING',
	CONNECTING = 'CONNECTING',
	CONNECTED = 'CONNECTED',
	DISCONNECTED = 'DISCONNECTED',
	NOT_CONNECTED = 'NOT_CONNECTED',
	STOPPED = 'STOPPED',
	SAVING_DATA = 'SAVING_DATA',
	RESETTING = 'RESETTING'
}

/**
 * Base class for backend connection.
 */
export abstract class Connection {
	readonly clientId;

	open = new EventEmitter;
	data = new EventEmitter;
	message = new EventEmitter;
	close: EventEmitter<boolean> = new EventEmitter;
	status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.NOT_CONNECTED);

	recordingInfo = {
		mode: RunMode.LIVE,
		isLive: function () {
			return true;
		},
		isRecording: function () {
			return false;
		},
		isPlayback: function () {
			return false;
		},
		isReprocessIqData: function () {
			return false;
		}
	};

	protected subscriptions: Array<Subscription> = [];
	protected _connectionStatus = ConnectionStatus.NOT_CONNECTED;
	protected _lastErrorMessage: string | null = null;
	protected disconnectReason;
	protected promiseCallbacks: {
		[command: string]: Array<{
			resolve: Function,
			reject: Function,
			payload?: any
		}>
	} = {};
	protected connectResolve;
	protected connectReject;

	get url() {
		return [this.ip, this.port].filter(p => p).join(':');
	}

	get connectionStatus() {
		return this._connectionStatus;
	}

	set connectionStatus(status) {
		this._connectionStatus = status;
		this.status.next(status);
	}

	get lastErrorMessage() {
		return this._lastErrorMessage;
	}

	constructor(public readonly ip?, public readonly port?) {
		this.clientId = this.genClientId();
	}

	abstract isConnected(): boolean;

	abstract isConnecting(): boolean;

	abstract isRunned(): boolean;

	canDisconnect(): boolean {
		return this.isConnected() || this.isConnecting();
	}

	abstract connect();

	abstract disconnect();

	private genClientId() {
		return 'client_' + Math.random().toString(36).substr(2, 9);
	}
}
