import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	NgZone,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import * as c3 from 'c3';
import * as d3 from 'd3';
import {ChartConfiguration, ChartType} from 'c3';

/**
 * Component represents a shell for c3 graph.
 * It takes data and configs and does work with c3 simpler.
 */
@Component({
	selector: 'app-c3-graph',
	templateUrl: './c3-graph.component.html',
	styleUrls: ['./c3-graph.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class C3GraphComponent implements OnInit, OnChanges, AfterViewInit {

	@ViewChild('chart') chart: ElementRef<HTMLDivElement>;
	@Input() data;
	@Input() type: ChartType = 'line';
	@Input() xValues = [];
	@Input() yValues = [];
	@Input() yFormat;
	@Input() showLegend = true;
	@Input() colors;
	@Input() axisLabels;
	@Output() rendered = new EventEmitter();

	private c3API;
	private isResized = false;
	private resizeTimeoutId;

	constructor(private zone: NgZone,
				private ref: ElementRef) {
	}

	ngOnInit(): void {

	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('type' in changes) {
			this.ref.nativeElement.dataset['selector'] = 'c3-graph_chart_' + this.type;
		}
		if ('data' in changes && !changes.data.isFirstChange()) {
			this.loadData();
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.data) {
				this.loadData();
			}
			this.ref.nativeElement.dataset['selector'] = 'c3-graph_chart_' + this.type;
		});
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event: Event) {
		clearTimeout(this.resizeTimeoutId);
		this.resizeTimeoutId = setTimeout(() => {
			if (this.c3API) {
				this.zone.runOutsideAngular(() => {
					this.c3API.resize({height: this.ref.nativeElement.clientHeight, width: this.ref.nativeElement.clientWidth});
				});
			}
		});
	}

	private loadData() {
		if (this.data instanceof Array) {
			this.zone.runOutsideAngular(() => {
				this.c3API = c3.generate(this.getConfig());
			});
		}
	}

	private getConfig(): ChartConfiguration {
		let data: any = [].concat(this.data),
			config = {
				bindto: this.chart.nativeElement,
				transition: {
					duration: 0
				},
				legend: {
					show: this.showLegend
				},
				point: {
					r: 5
				},
				size: {
					width: this.ref.nativeElement.clientWidth,
					height: this.ref.nativeElement.clientHeight
				},
				data: {
					x: 'x',
					type: this.type,
					colors: this.colors,
					columns: data
				},
				axis: {
					x: {
						type: 'category',
						tick: {
							centered: true
						},
						categories: this.xValues,
						label: {
							text: this.axisLabels.x,
							position: 'outter-center'
						}
					},
					y: {
						min: 0,
						padding: {
							bottom: 0
						},
						label: {
							text: this.axisLabels.y,
							position: 'outter-middle'
						}
					}
				},
				onrendered: function () {
					if (!this.isResized) {
						if (this.type === 'bar') {
							let thisChart = d3.select(config.bindto),
								first = (thisChart.select('.c3-axis-x .tick line').node() as Element).getBoundingClientRect(),
								second = (thisChart.select('.c3-axis-x .tick + .tick line').node() as Element).getBoundingClientRect(),
								distance = (second.x - first.x) / 2;

							thisChart.selectAll('.c3-axis-x .tick line,.c3-axis-x .tick text').style('transform', 'translate(-' + distance + 'px,0)');
						}
						setTimeout(() => {
							this.rendered.emit();
						});
					}
					this.isResized = false;
				}.bind(this),
				onresized: function () {
					this.isResized = true;
				}.bind(this)
			};

		if (this.yValues && this.yValues.length) {
			config['axis']['y']['max'] = this.yValues[this.yValues.length - 1];
			config['axis']['y']['tick'] = {
				count: this.yValues.length,
				values: this.yValues
			};
		}

		if (this.yFormat) {
			config['axis']['y']['tick']['format'] = d => d ? d + this.yFormat : d;
		}

		return config as any;
	}
}
