<div class="modal-default">
	<h3 [innerHTML]="data.title | translate:data.translateData | htmlNewLine"></h3>
	<div class="message" perfectScrollbar>
        <p>
            Is the patient:<br>
            <mat-checkbox [(ngModel)]="q1">
                Seating at ease?
            </mat-checkbox><br>
            <mat-checkbox [(ngModel)]="q2">
                Breathing normally?
            </mat-checkbox><br>
            <mat-checkbox [(ngModel)]="q3">
                Not moving?
            </mat-checkbox>
        </p>
	</div>
	<div class="default-panel">
		<button mat-button (click)="reject()" class="g-btn reject float-left"
				*ngIf="data.showCancel">{{(data.cancelText || 'Cancel') | translate}}
		</button>
        <button mat-button (click)="resolve()" class="g-btn reject button-right" *ngIf="data.showOk"
        [disabled]="!q1 || !q2 || !q3">
			{{(data.okText || 'OK') | translate}}
		</button>
		<ng-container *ngIf="data.additionalButtons">
			<button *ngFor="let button of data.additionalButtons" mat-button (click)="resolve(button.key)"
					class="g-btn reject button-right">{{button.text | translate}}
			</button>
		</ng-container>
	</div>
</div>



