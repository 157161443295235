import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

/**
 * Component represents as a confirm modal window with title, message, buttons as parameters.
 */
@Component({
	selector: 'app-modal-message',
	templateUrl: './modal-message.component.html',
	styleUrls: ['./modal-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalMessageComponent implements OnInit, OnDestroy {

	@Output() saveAndClose = new EventEmitter;
	cancelButtonDisabled = false;
	disabledScroll = true;

	private subscriptions: Array<Subscription> = [];

	constructor(private dialogRef: MatDialogRef<ModalMessageComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private translate: TranslateService,
				private ref: ChangeDetectorRef) {

	}

	ngOnInit() {
		this.subscriptions.push(this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
			switch (e.key) {
				case 'Enter':
					if (this.data.showOk) {
						if (document.activeElement) {
							document.activeElement['blur']();
						}
						setTimeout(() => {
							this.resolve();
						});
					}
					break;
				case 'Escape':
					this.reject();
					break;
			}
		}));

		this.subscriptions.push(this.dialogRef.afterOpened().subscribe(() => this.disabledScroll = false));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	resolve(res = true) {
		this.dialogRef.close(res);
	}

	reject() {
		if (this.data.showLoading && this.data.config.cancelCallback) {
			this.data.title = 'CANCELING';
			this.cancelButtonDisabled = true;
			this.data.config.cancelCallback();
		} else {
			this.dialogRef.close(false);
		}
	}

	refresh() {
		this.ref.detectChanges();
	}
}
