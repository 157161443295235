import {Component, Injector, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {SensorSocket} from '../../../../services/system/sensor-socket';
import {SingleSensorComponent} from '../../../flow-processor/components/base-view/single-sensor/single-sensor.component';
import {resources} from '../../../../utils/ResourcesLoader';

const coolerInventoryResources = {
	'Bottle': 'ortprdnmxcxfr',
	'Fridge': 'Fridge'
};

/**
 * Component is designed for showing scene with fridge and bottles.
 * Used for Smart Cooler app.
 */
@Component({
	selector: 'app-smart-cooler',
	templateUrl: '../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../components/base-view/base-view.component.scss']
})
export class SmartCoolerComponent extends SingleSensorComponent implements OnInit {

	connection: SensorSocket;

	protected shouldSaveUIParametersToCache = false;

	constructor(protected injector: Injector) {
		super(injector);
	}


	ngOnInit(): void {
		this.subscribeToToolbarControls();
		this.toolbarService.setIsSmartCooler(true);
		let sensorSocket = this.sensorsService.sensorSockets.value.find(s => s.isConnected());
		if (sensorSocket) {
			this.connection = sensorSocket;
			this.toolbarService.setConnection(this.connection);
			this.settingsService.getAllSettings(this.connection.url).then(settings => {
				this.connectionSettings = settings;
				let parameters = {
					'sensorParameters': {
						'ProcessorCfg.ExternalGUI.Layers': environment.smartCoolerLayers
					},
					'guiParameters': {
						selectedLayers: [[{
							'name': 'CoolerInventory',
							'componentName': 'CoolerInventoryComponent',
							'selected': true,
							'dataDict': {
								'bottlesOccupancy': 'bottlesOccupancy'
							},
							'staticDataDict': {
								'bottlesRowsColumnsLevel': [3, 3],
								'shelfIndex': 0
							}
						}], []]
					}
				};
				this.generateLayers(parameters);
			});
			this.preloadModelsResources();
			this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
				this.combinedData = data;
			}));
		}
	}


	protected sendOutputsToSocket(outputs) {
		this.sensorsService.sensorSockets.value.filter(socket => socket.isConnected()).forEach(socket => {
			socket.setOutputs('json_outputs', outputs);
		});
	}


	protected preloadModelsResources() {
		Object.keys(coolerInventoryResources).forEach(res => {
			if (resources[res] && ((environment.production && (res in (<any>window).modelsResources)) || !environment.production)) {
				resources[res].get().then(gltf => {
					this[`${res}3DModel`] = gltf;
				});
			}
		});
	}
}
