<div class="loading" [class.with-delay]="withDelay" [ngStyle]="{'width.px': size, 'height.px': size}" [attr.data-selector]="'loading-popup'">
	<ng-container *ngIf="type === 'circle'">
		<img src="assets/images/svg/loading/Preloader_Large.svg" alt="Loading">
		<img src="assets/images/svg/loading/Preloader_Middle.svg" alt="Loading"
			 [ngStyle]="{'width.px': size - 10, 'height.px': size - 10, 'top': 'calc(50% - ' + (size - 10) / 2 + 'px)', 'left': 'calc(50% - ' + (size - 10) / 2 + 'px)'}">
		<img src="assets/images/svg/loading/Preloader_Small.svg" alt="Loading"
			 [ngStyle]="{'width.px': size - 20, 'height.px': size - 20, 'top': 'calc(50% - ' + (size - 20) / 2 + 'px)', 'left': 'calc(50% - ' + (size - 20) / 2 + 'px)'}">
	</ng-container>
	<ng-container *ngIf="type === 'indeterminate'">
		<div class="progress-bar">
			<h3>{{title | translate}}</h3>
			<div>
				<mat-progress-bar mode="indeterminate" [class._mat-animation-noopable]="false"></mat-progress-bar>
			</div>
			<ng-container *ngIf="showStatus">
				<ng-container *ngTemplateOutlet="status"></ng-container>
			</ng-container>
		</div>
	</ng-container>
	<ng-container *ngIf="type === 'determinate'">
		<div class="progress-bar">
			<h3>{{title | translate}}</h3>
			<div class="loading-wrapper">
				<div class="track">
					<div class="value" [ngStyle]="{'width.%': busEventService.restLoadingPercentage | async}"></div>
				</div>
				<span class="percent">{{busEventService.restLoadingPercentage | async | number: '1.0-0'}}%</span>
			</div>
			<ng-container *ngIf="showStatus">
				<ng-container *ngTemplateOutlet="status"></ng-container>
			</ng-container>
		</div>
	</ng-container>
</div>

<ng-template #status>
	<div class="status" [innerHTML]="busEventService.restLoadingStatus | async"></div>
</ng-template>
