<div class="buttons">
	<ng-content></ng-content>
</div>
<div #container class="canvas"></div>
<div class="titles">
	<ng-container
		*ngIf="data?.state !== state.STATE_DONE">
		<span>{{data?.stateStr}}</span>
	</ng-container>
	<table *ngIf="data?.state === state.STATE_DONE">
		<tr *ngFor="let row of measurements">
			<ng-container *ngFor="let cell of row">
				<td>
					{{cell.label}}:
				</td>
				<td class="value">
					{{cmToInches(cell.value) | number:'1.0-2'}} {{units}}
				</td>
			</ng-container>
		</tr>
	</table>
</div>
