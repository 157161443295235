import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HomeAutomationServerSocket} from '../../../../../services/system/ham-socket';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {SensorsService} from '../../../../../services/system/sensors.service';

/**
 * Component renders temperature from smart home sensor-list.
 * Page contains toggle for AC.
 * Used for Smart Home app.
 */
@Component({
	selector: 'app-smart-home-ac',
	templateUrl: './smart-home-ac.component.html',
	styleUrls: ['./smart-home-ac.component.scss']
})
export class SmartHomeAcComponent implements OnInit, OnDestroy {

	@Input() socket: HomeAutomationServerSocket;
	@Input() parameters: any = {};

	form: FormGroup;

	private subscriptions: Array<Subscription> = [];

	constructor(private fb: FormBuilder,
				private sensorsService: SensorsService) {
	}

	ngOnInit() {
		this.form = this.fb.group({
			devices: new FormArray([])
		});
		this.subscriptions.push(this.sensorsService.hamDevices.subscribe(deviceList => {
			while ((this.form.controls.devices as FormArray).length !== 0) {
				(this.form.controls.devices as FormArray).removeAt(0);
			}
			deviceList.devices.forEach((device: any) => {
				let group = new FormGroup({});
				Object.keys(device).forEach(key => {
					let value = device[key];
					group.addControl(key, new FormControl(value));
				});
				(<FormArray>this.form.get('devices')).push(group);
			});
		}));
	}

	updateDevice(device, data) {
		let room = (this.parameters.socketInfo.devices || {})[device.get('name').value];
		this.socket.controlDevice({
			name: device.get('name').value,
			roomIp: room ? room.ip : null,
			roomName: room ? room.name : null,
			value: data.checked
		});
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}
}
