import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';

/**
 * Component renders slider line with draggable triangle.
 * Used for ArenaComponent (Setting - Arena).
 */
@Component({
	selector: 'app-sensor-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss']
})
export class SensorSliderComponent implements OnInit, OnChanges {

	@Input() controlMin;
	@Input() controlMax;
	@Input() min;
	@Input() max;
	@Input() positionValue;
	@Input() rangeMin;
	@Input() rangeMax;
	@Input() step = 0.1;
	@Input() readonly;
	@Input() isChanged = false;
	@Input() zeroPointPosition = 'relative';
	@Input() label = 'M';
	@ViewChild('wrapper') wrapper: ElementRef;
	@Output() startDrag = new EventEmitter();
	@Output() stopDrag = new EventEmitter();
	@Output() update = new EventEmitter();
	@Output() valueChange = new EventEmitter();

	sensorPosition;
	arenaSliderWidth;
	arenaSliderPosition;

	private activeSensorSliderMin;
	private activeSensorSliderMax;

	private updateValuesBySlider = (e: MouseEvent) => {
		let fullLength = this.activeSensorSliderMax - this.activeSensorSliderMin,
			percent = Math.round((e.clientX - this.activeSensorSliderMin) * 100 / fullLength);

		if (percent < 0) {
			percent = 0;
		}
		if (percent > 100) {
			percent = 100;
		}
		this.update.emit(percent);
	};

	constructor() {
	}

	ngOnInit(): void {
		this.updateSensorIconPositions();
		this.updateArenaSliderPositions();
	}

	ngOnChanges() {
		this.updateSensorIconPositions();
		this.updateArenaSliderPositions();
	}

	onStartDrag() {
		if (this.readonly) {
			return;
		}
		let boundingClientRect = this.wrapper.nativeElement.getBoundingClientRect();
		this.wrapper.nativeElement.classList.add('active');
		this.activeSensorSliderMin = boundingClientRect.x;
		this.activeSensorSliderMax = boundingClientRect.x + boundingClientRect.width;
		document.addEventListener('mousemove', this.updateValuesBySlider);
		document.addEventListener('mouseup', () => {
			document.removeEventListener('mousemove', this.updateValuesBySlider);
			this.wrapper.nativeElement.classList.remove('active');
			this.stopDrag.emit();
		}, {once: true});
		this.startDrag.emit({
			defaultValues: [this.controlMin.value, this.controlMax.value],
			defaultPercent: this.sensorPosition
		});
	}

	onValueChange(value, control) {
		if (!(value instanceof Event)) {
			this.valueChange.emit({
				value,
				control
			});
		}
	}

	updateSensorIconPositions() {
		let position,
			min = Math.min(0, this.min),
			max = Math.max(0, this.max),
			length = Math.abs(min) + Math.abs(max);

		if (this.zeroPointPosition === 'absolute') {
			position = (Math.abs(min) + this.positionValue) * 100 / length;
		} else {
			position = this.positionValue * 100 / length;
		}
		if (position < 0) {
			position = 0;
		}
		if (position > 100) {
			position = 100;
		}
		this.sensorPosition = position;
	}

	updateArenaSliderPositions() {
		let min = Math.min(0, this.min),
			max = Math.max(0, this.max),
			length = Math.abs(min) + Math.abs(max),
			position,
			width = (this.controlMax.value - this.controlMin.value) * 100 / length;

		if (this.zeroPointPosition === 'absolute') {
			position = Math.abs(+this.controlMin.value) * 100 / length;
		} else {
			if (this.max < 0) {
				position = 100 - Math.abs(this.min) * 100 / length;
			} else if (this.min < 0) {
				position = 0;
			} else {
				position = Math.abs(this.min) * 100 / length;
			}
		}
		this.arenaSliderPosition = position;
		this.arenaSliderWidth = width;
	}
}
