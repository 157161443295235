import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MAT_SELECT_CONFIG, MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_FORMATS, MatNativeDateModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

export const MY_FORMATS = {
	display: {
		dateInput: 'dd.MM.yyyy'
	},
};

@NgModule({
	imports: [MatSliderModule, MatSlideToggleModule, MatButtonModule, MatDialogModule, MatMenuModule, MatIconModule, MatRadioModule,
		MatSelectModule, MatCheckboxModule, MatInputModule, MatTooltipModule, MatButtonToggleModule, MatDatepickerModule, MatNativeDateModule, MatProgressBarModule,
		MatAutocompleteModule],
	exports: [MatSliderModule, MatSlideToggleModule, MatButtonModule, MatDialogModule, MatMenuModule, MatIconModule, MatRadioModule,
		MatSelectModule, MatCheckboxModule, MatInputModule, MatTooltipModule, MatButtonToggleModule, MatDatepickerModule, MatProgressBarModule,
		MatAutocompleteModule],
	providers: [
		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useValue: {position: 'above'}
		}, {
			provide: MAT_DATE_FORMATS,
			useValue: MY_FORMATS
		}, {
			provide: MAT_SELECT_CONFIG,
			useValue: {
				disableOptionCentering: true
			}
		}
	]
})
export class MaterialModule {
}
