<div class="modal-default">
	<h3>{{'LOGIN' | translate}}</h3>
	<div class="message">
		<div class="messages">
			<span class="error" *ngIf="errorMessage">{{errorMessage | translate}}</span>
			<span class="success" *ngIf="successMessage">{{successMessage | translate}}</span>
		</div>
		<div class="input" [ngClass]="{'disabled': loading}">
			<input #userName
					type="text"
				   name="pathName"
				   [disabled]="loading"
				   [(ngModel)]="name" autocomplete="off" autofocus (ngModelChange)="nameChanged()" (keyup.enter)="signIn()">
			<hr>
		</div>
		<app-loading-small *ngIf="loading"></app-loading-small>
	</div>
	<div class="default-panel">
		<button [disabled]="signUpDisabled || loading" mat-button (click)="signUp()"
				class="g-btn reject button-left">
			{{'SIGN_UP' | translate}}
		</button>
		<button [disabled]="loading" mat-button (click)="signIn()"
				class="g-btn reject button-right">
			{{'SIGN_IN' | translate}}
		</button>
	</div>
</div>
