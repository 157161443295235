import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MODAL_USER_INPUT_TYPE} from '../../../services/modal.service';
import {ConfigurableTabControlType, IConfigurablePageControl} from '../../../services/configuration.service';
import {TranslateService} from '@ngx-translate/core';
import {RunMode} from '../../../services/system/sensor-socket';
import {Subscription} from 'rxjs';

/**
 * Component renders a modal window with input.
 * Title, buttons as parameters.
 */
@Component({
	selector: 'app-user-input',
	templateUrl: './user-input.component.html',
	styleUrls: ['./user-input.component.scss']
})
export class UserInputComponent implements OnInit, AfterViewInit, OnDestroy {

	pathName = '';
	save_dir_base: any;
	control: FormControl;
	MODAL_USER_INPUT_TYPE = MODAL_USER_INPUT_TYPE;
	ConfigurableTabControlType = ConfigurableTabControlType;
	form: FormGroup;
	defaultNumberInputStep = 0.1;
	message = '';
	disabledScroll = true;

	private subscriptions: Array<Subscription> = [];

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case 'Enter':
				if (this.pathName.length || !this.control.errors && !this.save_dir_base) {
					if(document.activeElement) {
						document.activeElement['blur']();
					}
					setTimeout(() => {
						this.resolve();
					});
				}
				break;
			case 'Escape':
				this.reject();
				break;
		}
		event.stopPropagation();
		event.stopImmediatePropagation();
	}

	constructor(private dialogRef: MatDialogRef<UserInputComponent>,
				@Inject(MAT_DIALOG_DATA) public data: {[key: string]: any} & {control: IConfigurablePageControl},
				private ref: ChangeDetectorRef,
				private fb: FormBuilder,
				private translate: TranslateService) {
	}

	ngOnInit() {
		if (this.data.type === MODAL_USER_INPUT_TYPE.PATH) {
			document.addEventListener('keydown', this.onKeyDown, { capture: true, passive: false });
			if (this.data.value) {
				this.pathName = this.data.value;
			}
			if (this.data.sensorParams) {
				this.save_dir_base = this.data.sensorParams['sensorParameters']['FlowCfg.save_dir_base'];
			}
			this.control = new FormControl(this.pathName, [(c: FormControl) => {
				let NIC_REGEX_OLD = /^[a-z]:\\[^:*?>|]*|[A-Z]:\\[^:*?>|]*/; // Regular Expression 1
				let NIC_REGEX_NEW = /^[a-z]:\/[^:*?>|]*|[A-Z]:\/[^:*?>|]*/; // Regular Expression 2

				return (NIC_REGEX_OLD.test(c.value) || NIC_REGEX_NEW.test(c.value)) ? null : {
					pattern: {
						valid: false
					}
				};
			}]);
		} else {
			this.control = new FormControl();
		}
		this.form = this.fb.group({});
		if (this.data.control?.controls) {
			this.data.control?.controls?.forEach(control => {
				let value = this.data.sensorParams['sensorParameters'][control.param];
				if (control.multiple && !(value instanceof Array)) {
					value = [value];
				}
				this.form.addControl(control.param, new FormControl(value));
			});
		} else if (this.data.control?.save_data_params) {
			this.data.control?.save_data_params.forEach(param => {
				this.form.addControl(param, new FormControl(this.data.sensorParams['sensorParameters'][param]));
			});
		}
		this.updateMessage();
		this.subscriptions.push(this.form.valueChanges.subscribe(() => {
			this.updateMessage();
		}));
		this.subscriptions.push(this.dialogRef.afterOpened().subscribe(() => this.disabledScroll = false));
	}

	ngOnDestroy() {
		document.removeEventListener('keydown', this.onKeyDown, { capture: true });
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	ngAfterViewInit() {
		this.ref.detectChanges();
	}

	reject() {
		this.dialogRef.close(false);
	}

	resolve() {
		let data;
		if (this.data.type === MODAL_USER_INPUT_TYPE.PATH) {
			data = {
				value: this.pathName,
				params: this.form.getRawValue(),
				save_dir_base: this.save_dir_base
			};
		} else {
			data = {
				value: this.control.value,
				params: this.form.getRawValue()
			};
		}
		this.dialogRef.close(data);
	}

	updatePathName() {
		this.pathName = this.control.value;
	}

	// TODO temp
	someControlShouldBeSwitchedOn() {
		return this.data.control.controls && !(this.data.control.controls || []).filter(c => c.param !== 'FlowCfg.allow_savedData_override').some(c => this.form.controls[c.param].value);
	}

	private updateMessage() {
		let recordingTypes = this.getSelectedRecordingsTypesString();
		this.message = recordingTypes.length ? `<div class="recorded-data-title"><span>* By clicking continue you will save ${recordingTypes}.</span></div>` : '';
	}

	private getSelectedRecordingsTypesString() {
		let texts: Array<string> = [],
			sensorParameters = this.form.getRawValue(),
			parameters: any = [
				{
					name: 'FlowCfg.save_to_file',
					text: 'RAW_DATA'
				},
				{
					name: 'FlowCfg.save_image_to_file',
					text: 'IMAGE_DATA'
				},
				{
					name: 'FlowCfg.save_pointCloud_to_file',
					text: 'POINT_CLOUD'
				},
				{
					name: 'ProcessorCfg.OutputData.save_to_file',
					text: 'OUTPUT_LOGS'
				},
				{
					name: 'FlowCfg.save_outputs_to_file',
					text: 'OUTPUTS'
				}
			].filter(parameter => this.data.connection.isSupportedParameter(parameter.name) && sensorParameters[parameter.name]);

		parameters.forEach((parameter, i, arr) => {
			let isFirst = i === 0,
				isLast = i === arr.length - 1;

			if (!isFirst) {
				if (isLast) {
					texts.push('and');
				} else {
					texts.push(',');
				}
			}
			texts.push(this.translate.instant(parameter.text).toLowerCase());
		});

		return texts.join(' ').replace(' ,', ',');
	}
}
