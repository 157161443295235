<div class="progress-bar"><div class="progress" [class.animated]="inProgress" [style.width.%]="progress"></div></div>

<div class="add-sensor__modal">
	<div class="add-sensor__header">
		<div class="add-sensor__logo">
			<img src="assets/images/VayyarNewLogo-01.png" alt="logo">
		</div>
		<h1 class="add-sensor__heading">{{ 'CONNECT_TO_REMOTE_HOSTS' | translate}}</h1>
		<button class="add-sensor__add-sensor-btn" (click)="addSensor()" [disabled]="inProgress">{{'ADD_SENSOR' | translate}}</button>
	</div>

	<div class="add-sensor__body" [perfectScrollbar]="{suppressScrollX: false, minScrollbarLength: 15}">
		<table class="sensors-table">
			<thead>
				<tr class="sensors-table__fixed-header">
					<th width="60">
						<div class="flex-vh-center">
							<label for="selectAll" class="add-sensor__checkbox-label" (click)="selectAllHosts()"
								   [ngClass]="{'add-sensor__checkbox-checked': isAllHostsChecked, 'add-sensor__checkbox-unchecked': !isAllHostsChecked}">
							</label>
							<input type="checkbox" class="add-sensor__checkbox" id="selectAll" [checked]="isAllHostsChecked">
						</div>
					</th>
					<th width="141">
						<div class="flex-v-center">
							<span>{{'IP' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('visibleField')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'visibleField' && currentSortingDirection === 1"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'visibleField' && currentSortingDirection === -1"></span>
							</div>
						</div>
					</th>
					<th width="207">
						<div class="flex-v-center">
							<span>{{'NAME' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('name')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'name' && currentSortingDirection === 1"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'name' && currentSortingDirection === -1"></span>
							</div>
						</div>
					</th>
					<th width="101">
						<div class="flex-v-center">
							<span>{{'LAST_USED' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('lastUsed')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'lastUsed' && currentSortingDirection === 1"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'lastUsed' && currentSortingDirection === -1"></span>
							</div>
						</div>
					</th>
					<th width="81">
						<div class="flex-v-center">
							<span>{{'ACTIONS' | translate}}</span>
						</div>
					</th>
				</tr>
				<tr class="invisible">
					<th width="60">
						<div class="flex-vh-center">
							<label for="selectAll"
								   class="add-sensor__checkbox-label"
								   [ngClass]="{'add-sensor__checkbox-checked': isAllHostsChecked, 'add-sensor__checkbox-unchecked': !isAllHostsChecked}"
								   (click)="selectAllHosts()">
							</label>
							<input  type="checkbox"
									class="add-sensor__checkbox"
									id="selectAll"
									[checked]="isAllHostsChecked"
									[disabled]="inProgress">
						</div>
					</th>
					<th width="141">
						<div class="flex-v-center">
							<span>{{'IP' | translate}}</span>
							<div class="sort-icon__wrap pointer">
								<span class="sort-icon-top"></span>
								<span class="sort-icon-bot"></span>
							</div>
						</div>
					</th>
					<th width="207">
						<div class="flex-v-center">
							<span>{{'NAME' | translate}}</span>
							<div class="sort-icon__wrap pointer">
								<span class="sort-icon-top"></span>
								<span class="sort-icon-bot"></span>
							</div>
						</div>
					</th>
					<th width="101">
						<div class="flex-v-center">
							<span>{{'LAST_USED' | translate}}</span>
							<div class="sort-icon__wrap pointer">
								<span class="sort-icon-top"></span>
								<span class="sort-icon-bot"></span>
							</div>
						</div>
					</th>
					<th width="81">
						<div class="flex-v-center">
							<span>{{'ACTIONS' | translate}}</span>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
			<tr *ngFor="let item of monitoringItems; let i = index; let last = last;">
				<td class="flex-vh-center">
					<label for="itemCheckbox{{i}}"
						   class="add-sensor__checkbox-label "
						   [ngClass]="{'add-sensor__checkbox-checked': item.checked, 'add-sensor__checkbox-unchecked': !item.checked}"
						   (click)="onCheckboxChecked(item)">
					</label>
					<input type="checkbox"
						   class="add-sensor__checkbox"
						   [checked]="item.checked"
						   id="itemCheckbox{{i}}">
				</td>
				<td>
					<input  #url
							type="text"
							class="bg-transparent border-0 color-white name"
							[(ngModel)]="item.visibleField"
							[ngClass]="{'input-editable': item.isEdit}"
							placeholder="{{ 'ENTER_IP_ADDRESS' | translate }}"
							[disabled]="!item.isEdit">
				</td>
				<td>
					<div (click)="editName(item, inputName)" class="name-input-container">
						<app-inline-edit-input	#inputName
												  (edit)="editName(item, inputName)"
												  [ngModel]="item.name" class="name"
												  [ngClass]="{'selected': item.isQuickEditName}" type="text"
												  [matTooltip]="item.name"
												  [placeholder]="'ENTER_NAME' | translate"
												  [tooltip]="'EDIT_CONNECTION_NAME' | translate"
												  [disabled]="!item.isQuickEditName"
												  (valueChange)="saveEditName(item, $event)">
						</app-inline-edit-input>
					</div>
				</td>
				<td>
					<span>{{formatLastUsed(item.lastUsed)}}</span>
				</td>
				<td>
					<div class="d-flex">
						<button *ngIf="!item.connected" class="add-sensor__actions-icon connect"  mat-raised-button [disabled]="inProgress"
								(click)="goToSensor(item)" [matTooltip]="'CONNECT' | translate"></button>
						<button *ngIf="item.connected" class="add-sensor__actions-icon disconnect" [disabled]="inProgress"
								mat-raised-button (click)="disconnectSensorSocket(item)" [matTooltip]="'DISCONNECT' | translate"></button>
						<button class="add-sensor__actions-icon remove"  mat-raised-button [disabled]="inProgress"
								(click)="removeSensor(item, i)" [matTooltip]="'REMOVE_CONNECTION' | translate" matTooltipClass="red-tooltip"></button></div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
	<div class="add-sensor__footer">
		<button class="add-sensor__footer-btn" mat-raised-button (click)="onClose()">{{'CLOSE' | translate}}</button>
		<button class="add-sensor__footer-btn add-sensor__footer-btn-connect" mat-raised-button (click)="connectToSelectedSensors()" [disabled]="isConnectDisabled()">{{'CONNECT_SELECTED' | translate}}</button>
	</div>
</div>
