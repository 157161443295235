import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../modules/material/material.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SharedModule} from '../../modules/shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import {MODULE_PATH} from '../../app.module';
import {BackendConnectorEvkComponent} from '../../components/shared/backend-connector/backend-connector-evk/backend-connector-evk.component';
import {environment} from '../../../environments/environment';
import {ToolbarEvkComponent} from '../../components/shared/toolbars/toolbar-evk/toolbar-evk.component';
import {PageGuard} from '../../guards/page.guard';
import {LayersContainerComponent} from './components/layers/layers-container.component';
import {CovidDetectionComponent} from './components/base-view/covid-detection/covid-detection.component';
import {PatientDataGraphComponent} from './components/layers/complex-graph/patient-data-graph.component';
import {ConfirmPatientComponent} from '../../components/shared/confirm-patient/confirm-patient.component';
import {VitalsComponent} from './components/layers/graphs/vitals/vitals.component';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../../../assets/i18n/${lang}.json`));
	}
}

export let InjectorInstance: Injector;

const routes: Routes = [
	{
		path: '',
		component: CovidDetectionComponent,
		canActivate: [PageGuard],
		data: {
			isCovidDetection: true,
			toolbar: ToolbarEvkComponent,
			sensorConnector: BackendConnectorEvkComponent
		}
	}, {
		path: '**',
		pathMatch: 'full',
		redirectTo: ''
	}
];

@NgModule({
	declarations: [
		CovidDetectionComponent,
		LayersContainerComponent,
		PatientDataGraphComponent,
		ConfirmPatientComponent,
		VitalsComponent,
	],
	entryComponents: [
		ConfirmPatientComponent
	],
	providers: [
		{
			provide: MODULE_PATH,
			useValue: environment.VITALS_MODULE_PATH
		},
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		MaterialModule,
		PerfectScrollbarModule,
		SharedModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	]
})
export class VitalsModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}
