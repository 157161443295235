<button class="play" *ngIf="sensorSocket.recordingInfo.state != PlaybackState.PLAYING"
		(click)="changeState(PlaybackState.PLAYING)"
		[disabled]="!sensorSocket.isConnected()"></button>
<button class="pause" *ngIf="sensorSocket.recordingInfo.state == PlaybackState.PLAYING"
		(click)="changeState(PlaybackState.PAUSED)"
		[disabled]="!sensorSocket.isConnected()"></button>
<button class="stop" (click)="changeState(PlaybackState.STOPPED)"
		[disabled]="!sensorSocket.isConnected()"></button>
<div class="slider-wrap">
	<!--<div class="alert"><div><div></div></div></div>-->
	<nouislider
		[connect]="[true, false]"
		(start)="start($event)"
		(end)="end($event)"
		(slide)="onSliderChanged($event)"
		[ngModel]="getCurrentFrame(sensorSocket.recordingInfo.currentFrame)"
		[min]="0"
		[max]="(sensorSocket.recordingInfo.numberOfFrames || 2) - 1"
		[step]="1"
		[disabled]="!sensorSocket.isConnected()"
		[ngClass]="{'some-range-disabled': !sensorSocket.isConnected(), 'no-animate': noAnimate}"
		class="slider"></nouislider>
</div>
<span class="duration">{{((sensorSocket.recordingInfo.currentTime - sensorSocket.recordingInfo.startTime) < 0 ? 0 : sensorSocket.recordingInfo.currentTime - sensorSocket.recordingInfo.startTime) | duration:sensorSocket.recordingInfo.durationInMs > 3599999 }} / {{sensorSocket.recordingInfo.durationInMs | duration:sensorSocket.recordingInfo.durationInMs > 3599999 }}</span>
