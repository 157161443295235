<div class="settings" data-selector="settings-container">
	<div class="settings-sidebar">
		<nav>
			<ng-container *ngFor="let tab of tabs">
				<div>
					<button *ngIf="tab.icon.startsWith('svg_'); else materialButton"
							(click)="setActiveMenuItem($any(tab.label))"
							[class.active]="tab.label == activeMenuItem"
							class="svg-button"
							data-selector="settings-tab-button"
							[style]="'--bg-image: url(assets/images/svg/' + tab.icon.replace('svg_', '') + (tab.label == activeMenuItem ? '-selected' : '') + '.svg)'">
						{{tab.label}}
					</button>
					<ng-template #materialButton>
						<button (click)="setActiveMenuItem($any(tab.label))"
								[class.active]="tab.label == activeMenuItem"
								class="material-button"
								data-selector="settings-tab-button">
							<span class="material-icons md-18"
								  [innerHTML]="tab.icon || defaultTabIcon | materialIcon"></span>
							<span>{{tab.label}}</span>
						</button>
					</ng-template>
				</div>
			</ng-container>
		</nav>
	</div>
	<div class="settings-container">
		<div #container="ngxPerfectScrollbar" class="container" perfectScrollbar [disabled]="disabledScroll">
			<ng-container>
				<ng-container *ngFor="let tab of tabs">
					<ng-container *ngIf="activeMenuItem === tab.label">
						<table class="additional">
							<ng-container [ngSwitch]="tab.template?.type">
								<ng-container *ngSwitchCase="ConfigurationAdditionalTabTemplate.ARENA_TAB">
									<ng-container
										*ngTemplateOutlet="arenaTab;context: {tab: tab, form: settingForm.controls.sensorParameters}"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="ConfigurationAdditionalTabTemplate.TARGETS_TAB">
									<ng-container
										*ngTemplateOutlet="targetsTab;context: {tab: tab, form: settingForm.controls.sensorParameters}"></ng-container>
								</ng-container>
								<ng-container *ngSwitchDefault>
									<ng-container
										*ngTemplateOutlet="defaultTab;context: {tab: tab, form: settingForm.controls.sensorParameters}"></ng-container>
								</ng-container>
							</ng-container>
						</table>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
		<div class="default-panel decide-panel">
			<div class="decide-panel-buttons">
				<button tabindex="-1"
						*ngIf="!sensorSocket?.isMultipleSensors &&!data?.isFloorPlan && !isUserSettings"
						(click)="onClickOpenBtn()" class="g-btn load" mat-raised-button>
					<div class="icon default-yang-icon" [innerHTML]="'LOAD_SETTINGS' | translate"></div>
					<input #fileuploadSettings data-selector="upload-settings-input" type="file" (change)="onSelectFile($event)"
						   (click)="$event.stopPropagation()" accept="application/json">
				</button>
				<button tabindex="-1"
						*ngIf="!sensorSocket?.isMultipleSensors &&!data?.isFloorPlan && !isUserSettings"
						class="g-btn save-settings" mat-raised-button (click)="onClickSaveBtn()"
						data-selector="download-settings-button"
						[disabled]="!sensorSocket">{{'SAVE_SETTINGS' | translate}}
				</button>

				<button tabindex="-1" class="g-btn" mat-raised-button (click)="onCancel()"
						data-selector="cancel-settings-button">{{'CANCEL' | translate}}</button>

				<button
					*ngIf="!sensorSocket?.isMultipleSensors && !data?.isFloorPlan && !isUserSettings"
					class="g-btn"
					data-selector="reset-settings-button"
					mat-raised-button (click)="onReset()" [disabled]="!sensorSocket">{{'RESET' | translate}}
				</button>

				<button class="g-btn slc-btn" mat-raised-button (click)="onSave()"
						data-selector="save-settings-button">{{'OK' | translate}}
				</button>
			</div>
		</div>
	</div>
	<app-loading *ngIf="loading"></app-loading>
</div>


<ng-template #arenaTab let-tab="tab" let-form="form">
	<tr>
		<td>
			<div class="arena-tab-container">
				<app-targets-3d *ngIf="tab.template?.enablePreview && settingsFor3D"
								[sensorSocket]="sensorSocket"
								[parameters]="settingsFor3D"
								[settingsMode]="true">
				</app-targets-3d>
				<div *ngIf="tab.controls?.length" class="arena-tab-controls-table-wrapper">
					<table [class.with-border]="tab.template?.enablePreview">
						<col width="100">
						<col>
						<ng-container *ngFor="let control of tab.controls">
							<ng-container *ngIf="control.enable">
								<tr class="arena-row-delimiter"></tr>
								<ng-container [ngSwitch]="control.type">
									<ng-container
										*ngSwitchCase="ConfigurationAdditionalTabControlType.TAB_MONITORED_ROOM_DIMS">
										<tr>
											<td colspan="2">
												<h5 class="folder-text folder-text-left d-flex w-100 fw-bold fs-13">{{control.label}}</h5>
											</td>
										</tr>
										<tr>
											<td colspan="2">
												<app-configurable-control [control]="control" [form]="form"
																		  [settingForm]="settingForm"
												(sensorHeightChanged)="onSensorHeightChanged($event)"
												(sensorPositionChanged)="onSensorPositionChanged($event)"
												(monitoredAreaChanged)="onMonitoredAreaChanged($event)">
												</app-configurable-control>
											</td>
										</tr>
										<ng-container
											*ngTemplateOutlet="descriptionRow;context: {control: control}"></ng-container>
									</ng-container>
									<ng-container
										*ngSwitchCase="ConfigurationAdditionalTabControlType.TAB_SENSOR_HEIGHT_INPUT">
										<tr>
											<td>
												<h5 class="folder-text folder-text-left d-flex w-100 fw-bold fs-13">{{control.sensorHeightNumberInput.label}}</h5>
											</td>
											<td>
												<app-configurable-control [control]="Object.assign({}, control.sensorHeightNumberInput, {type: ConfigurationAdditionalTabControlType.TAB_SENSOR_HEIGHT_INPUT})"
																		  [form]="form" [settingForm]="settingForm"
																		  (sensorHeightChanged)="onSensorHeightChanged($event)"
																		  (sensorPositionChanged)="onSensorPositionChanged($event)"
																		  (monitoredAreaChanged)="onMonitoredAreaChanged($event)">
												</app-configurable-control>
											</td>
										</tr>
										<ng-container
											*ngTemplateOutlet="descriptionRow;context: {control: control.sensorHeightNumberInput}"></ng-container>
									</ng-container>
									<ng-container *ngSwitchDefault>
										<ng-container
											*ngTemplateOutlet="defaultTabRow;context: {control: control, form: form, defaultNumberInputStep: defaultNumberInputStep}"></ng-container>
									</ng-container>
								</ng-container>
							</ng-container>
						</ng-container>
					</table>
				</div>
			</div>
		</td>
	</tr>
</ng-template>

<ng-template #targetsTab let-tab="tab" let-form="form">
	<col width="140">
	<col>
	<ng-container *ngFor="let control of tab.controls">
		<ng-container *ngIf="control.enable">
			<tr class="row-delimiter"></tr>
			<ng-container
				*ngTemplateOutlet="targetsTabRow;context: {control: control, form: form, numberInputLabel: '', defaultNumberInputStep: 1}"></ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #defaultTab let-tab="tab" let-form="form">
	<col width="140">
	<col>
	<ng-container *ngFor="let control of tab.controls">
		<ng-container *ngIf="control.enable">
			<ng-container [ngSwitch]="control.type">
				<tr class="row-delimiter"></tr>
				<ng-container *ngSwitchCase="ConfigurationAdditionalTabControlType.TAB_BACKGROUND_IMAGE">
					<ng-container
						*ngTemplateOutlet="targetsTabRow;context: {control: control, form: form}"></ng-container>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<ng-container
						*ngTemplateOutlet="defaultTabRow;context: {control: control, form: form}"></ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #defaultTabRow let-control="control" let-form="form" let-defaultNumberInputStep="defaultNumberInputStep">
	<ng-container [ngSwitch]="control.type">
		<ng-container *ngSwitchCase="'tab_text_input'">
			<tr>
				<td colspan="2">
					<h5 class="folder-text folder-text-left d-flex w-100 fw-bold fs-13">{{control.label}}</h5>
				</td>
			</tr>
			<ng-container *ngTemplateOutlet="descriptionRow;context: {control: control}"></ng-container>
			<tr>
				<td colspan="2">
					<app-configurable-control [control]="control" [form]="form" [settingForm]="settingForm"
											  (sensorHeightChanged)="onSensorHeightChanged($event)"
											  (sensorPositionChanged)="onSensorPositionChanged($event)"
											  (monitoredAreaChanged)="onMonitoredAreaChanged($event)">
					</app-configurable-control>
				</td>
			</tr>
		</ng-container>
		<ng-container *ngSwitchDefault>
			<tr>
				<td>
					<h5 class="folder-text folder-text-left d-flex w-100 fw-bold fs-13">{{control.label}}</h5>
				</td>
				<td>
					<app-configurable-control [control]="control" [form]="form"
											  [defaultNumberInputStep]="defaultNumberInputStep"
											  [settingForm]="settingForm"
											  (sensorHeightChanged)="onSensorHeightChanged($event)"
											  (sensorPositionChanged)="onSensorPositionChanged($event)"
											  (monitoredAreaChanged)="onMonitoredAreaChanged($event)">
					</app-configurable-control>
				</td>
			</tr>
			<ng-container *ngTemplateOutlet="descriptionRow;context: {control: control}"></ng-container>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #targetsTabRow let-control="control" let-form="form"
			 let-numberInputLabel="numberInputLabel" let-defaultNumberInputStep="defaultNumberInputStep">
	<tr>
		<td colspan="2">
			<h5 class="folder-text folder-text-left d-flex w-100 fw-bold fs-13 targets-control-title">{{control.label}}</h5>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<app-configurable-control [control]="control" [form]="form" [defaultNumberInputStep]="defaultNumberInputStep"
									  [numberInputLabel]="numberInputLabel" [settingForm]="settingForm"
									  (sensorHeightChanged)="onSensorHeightChanged($event)"
									  (sensorPositionChanged)="onSensorPositionChanged($event)"
									  (monitoredAreaChanged)="onMonitoredAreaChanged($event)">
			</app-configurable-control>
		</td>
	</tr>
	<ng-container *ngTemplateOutlet="descriptionRow;context: {control: control}"></ng-container>
</ng-template>

<ng-template #descriptionRow let-control="control">
	<tr>
		<td colspan="2">
			<span
				class="folder-text folder-text-left d-flex w-100 description">
				{{control.description}}
			</span>
		</td>
	</tr>
</ng-template>
