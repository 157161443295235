import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {SVGLayerComponent} from '../../../../../components/layers/SVGLayer';

/**
 * Component renders analytics (sales funnel) of shop.
 * Used for Smart Retail app.
 */
@Component({
	selector: 'app-shopper-insights',
	templateUrl: './shopper-insights.component.html',
	styleUrls: ['./shopper-insights.component.scss']
})
export class ShopperInsightsComponent extends SVGLayerComponent implements OnInit {

	@Input() data: any = {};
	path = '';

	Math = Math;

	constructor(protected hostElement: ElementRef) {
		super();
	}

	ngOnInit() {
	}

	setConversionRate(percent, r) {
		var circumference = r * 2 * Math.PI;

		return circumference - percent / 100 * circumference;
	}

	protected resizeViewBox() {
		super.resizeViewBox();

		this.drawTrapezoid();
	}

	private drawTrapezoid() {
		this.path = `M0,0 L${this.VIEWBOX_W},0 L${this.VIEWBOX_W * 95 / 100},${this.VIEWBOX_H} L${this.VIEWBOX_W * 5 / 100},${this.VIEWBOX_H}z`;
	}
}
