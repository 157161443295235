import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RetailFloor, RetailLocation} from '../../../../../models/models';
import {RestService} from '../../../../../services/system/rest.service';
import {BusEventService} from '../../../../../services/bus-event.service';
import {Subscription} from 'rxjs';
import {ToolbarService} from '../../../../../services/toolbar.service';
import {ModalService} from '../../../../../services/modal.service';

/**
 * Component displays as a sub-toolbar below the main toolbar.
 * It contains controls for adding / editing locations and floors.
 */
@Component({
	selector: 'app-sub-toolbar-floor-plan',
	templateUrl: './sub-toolbar-floor-plan.component.html',
	styleUrls: ['./sub-toolbar-floor-plan.component.scss']
})
export class SubToolbarFloorPlanComponent implements OnInit, OnDestroy {

	floorForm: FormGroup;
	locationForm: FormGroup;
	locations: Array<RetailLocation> = [];
	rippleColor = getComputedStyle(document.documentElement).getPropertyValue('--button-ripple-color');

	@HostBinding('class.edit-mode') get editClass() {
		return this.mode === 'edit';
	}

	@HostBinding('class.add-mode') get addClass() {
		return this.mode === 'add';
	}

	@ViewChild('fileuploadSettings', {static: true}) fileuploadSettingsInput: ElementRef;

	mode;
	type;

	private subscriptions: Array<Subscription> = [];
	private initialFloorData: RetailFloor = {
		floor_name: '',
		floor_number: 0,
		pixel_to_m_scale_factor: 0,
		base64_image: '',
		image: '',
		max_x: 0,
		max_y: 0,
		max_z: 0,
		parent_building: null,
		min_x: 0,
		min_y: 0,
		min_z: 0
	};
	private initialLocationData: RetailLocation = {
		building_name: '',
		address: '',
		number_of_floors: 1
	};

	constructor(private fb: FormBuilder,
				private restService: RestService,
				private busEventService: BusEventService,
				private toolbarService: ToolbarService,
				private modalService: ModalService) {
	}

	ngOnInit(): void {
		this.initFloorForm();
		this.locationForm = this.fb.group({
			id: new FormControl(),
			building_name: new FormControl('', [Validators.required]),
			address: new FormControl('', [Validators.required]),
			number_of_floors: new FormControl(1, [Validators.required]),
		});
		this.reloadLocations();
		this.subscriptions.push(this.busEventService.showSubToolbar.subscribe(next => {
			this.onSubToolbarVisibilityChanged(next);
		}));
		this.subscriptions.push(this.toolbarService.floorPlanFiltersForm.valueChanges.subscribe(() => {
			if (typeof this.toolbarService.floorPlanFiltersForm.getRawValue().building_id === 'number') {
				this.reloadLocationForm();
			}
			if (typeof this.toolbarService.floorPlanFiltersForm.getRawValue().floor_id === 'number') {
				this.reloadFloorForm();
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	cancel() {
		this.close(true);
	}

	async save() {
		if (this.type === 'floor') {
			this.floorForm.markAllAsTouched();
			if (this.floorForm.valid) {
				const data: RetailFloor = Object.assign({}, this.initialFloorData, this.floorForm.getRawValue());
				delete data.base64_image;
				this.restService.getLocations().then(async (locations) => {
					let location = locations.find(b => b.id === this.floorForm.getRawValue()['parent_building']) as RetailLocation;
					this.busEventService.floorUpdating.next(true);
					try {
						if (this.mode === 'add') {
							await this.restService.addFloorPlan(data);
							this.modalService.showMessage('NEW_FLOOR_SUCCESSFULLY_ADDED', undefined, undefined, undefined, {
								floorName: data.floor_name,
								locationName: location.building_name
							});
						} else {
							await this.restService.updateFloorPlan(data);
							if (this.floorWasChanged()) {
								this.restService.clearAnalyticsCache();
							}
						}

						this.busEventService.reloadFloors.emit(data);
					} catch (e) {
					}
					this.busEventService.floorUpdating.next(false);
				});
			} else {
				let value = this.floorForm.getRawValue(),
					message = '',
					showMessage = false;

				if (!value.image) {
					showMessage = true;
					message = 'Please upload a floor plan.';
				} else if (!value.max_x) {
					showMessage = true;
					message = 'Please define the floor dimensions.';
				}
				if (showMessage) {
					this.modalService.showMessage(message);
				}
			}
		}
		if (this.type === 'location') {
			this.locationForm.markAllAsTouched();
			if (this.locationForm.valid) {
				this.busEventService.floorUpdating.next(true);
				await Promise.resolve();
				try {
					if (this.mode === 'add') {
						await this.restService.addLocation(this.locationForm.getRawValue());
						this.modalService.showMessage('NEW_LOCATION_SUCCESSFULLY_ADDED', undefined, undefined, undefined, {
							location_name: this.locationForm.getRawValue().building_name
						});
					} else {
						await this.restService.updateLocation(this.locationForm.getRawValue());
					}
					this.busEventService.reloadFiltersValues.emit();
					this.reloadLocations();
					this.close();
				} catch (e) {
				}
				this.busEventService.floorUpdating.next(false);
			}
		}
	}

	onSelectFile(event: Event) {
		if ((<HTMLInputElement>event.target).files?.length) {
			let reader = new FileReader();

			reader.onload = async (e: Event) => {
				(<HTMLInputElement>event.target).value = '';

				this.floorForm.controls.image.setValue(e.target!['result']);
			};
			// @ts-ignore
			reader.readAsDataURL((<HTMLInputElement>event.target).files[0]);
		}
	}

	newGeoFence() {
		this.busEventService.newGeoFence.emit();
	}

	defineFloorDimensions() {
		this.busEventService.defineFloorDimensions.emit();
	}

	async deleteFloorPlan() {
		if ((await this.restService.getGeoFences(this.initialFloorData.id)).length) {
			return this.modalService.showError('DELETE_FLOOR_ERROR_HAS_GEO_FENCES');
		}
		this.modalService.confirm('DELETE_FLOOR_PLAN_CONFIRM', {floor_name: this.initialFloorData.floor_name})
			.afterClosed().toPromise().then(res => {
			if (res) {
				this.busEventService.floorUpdating.next(true);
				this.restService.deleteFloorPlans([this.initialFloorData.id as number]).then(() => {
					this.busEventService.reloadFloors.emit();
					this.close();
					this.emitMultipleRoomsParamsChanged({
						floor_name: '',
						min_x: 0,
						max_x: 0,
						min_y: 0,
						max_y: 0,
						base64_image: null
					});
				}).finally(() => {
					this.busEventService.floorUpdating.next(false);
				});
			}
		});
	}

	async deleteLocation() {
		if ((await this.restService.getFloors()).find(f => f.parent_building === this.initialLocationData.id)) {
			return this.modalService.showError('DELETE_LOCATION_ERROR_HAS_FLOORS');
		}
		this.modalService.confirm('DELETE_LOCATION_CONFIRM', {location_name: this.initialLocationData.building_name})
			.afterClosed().toPromise().then(res => {
			if (res) {
				this.busEventService.floorUpdating.next(true);
				this.restService.deleteLocations([this.initialLocationData.id as number]).then(() => {
					this.busEventService.reloadFloors.emit();
					this.reloadLocations();
					this.close();
				}).finally(() => {
					this.busEventService.floorUpdating.next(false);
				});
			}
		});
	}

	private close(cancel = false) {
		this.busEventService.showSubToolbar.next({
			open: false,
			mode: this.busEventService.showSubToolbar.value.mode,
			type: this.type,
			cancel
		});
	}

	private initFloorForm() {
		this.floorForm = this.fb.group({
			id: new FormControl(),
			image: new FormControl('', [Validators.required]),
			floor_name: new FormControl('', {
				validators: [Validators.required],
				updateOn: 'blur'
			}),
			parent_building: new FormControl(null, [Validators.required]),
			max_x: new FormControl(null, [Validators.required]),
			max_y: new FormControl(null, [Validators.required]),
		});
		this.subscriptions.push(this.floorForm.valueChanges.subscribe(next => {
			let data = this.floorForm.getRawValue();
			this.busEventService.multipleRoomsParamsChanged.emit({
				name: data['floor_name'],
				globalRoomWidth: data['max_x'],
				globalRoomHeight: data['max_y'],
				backgroundImage: data['image']
			});
		}));
		this.subscriptions.push(this.busEventService.newFloorDimensions.subscribe((next: any) => {
			this.floorForm.patchValue(next);
		}));
	}

	private onSubToolbarVisibilityChanged(next) {
		this.mode = next.mode;
		this.type = next.type;
		if (next.open) {
			if (next.type === 'floor') {
				if (next.mode === 'edit') {
					this.reloadFloorForm();
				} else {
					this.floorForm.reset();
					this.floorForm.patchValue({parent_building: this.toolbarService.floorPlanFiltersForm.getRawValue()['building_id']});
				}
			}
			if (next.type === 'location') {
				if (next.mode === 'edit') {
					this.reloadLocationForm();
				} else {
					this.locationForm.reset();
				}
			}
		}
	}

	private reloadFloorForm() {
		this.restService.getFloorPlan(this.toolbarService.floorPlanFiltersForm.getRawValue().floor_id).then(floor => {
			this.initialFloorData = floor;
			let {id, base64_image, floor_name, parent_building, max_x, max_y} = floor;
			this.floorForm.setValue({
				id, image: base64_image, floor_name, parent_building, max_x, max_y
			}, {emitEvent: false});
		});
	}

	private reloadLocationForm() {
		this.restService.getLocations().then(locations => {
			let location = locations.find(l => l.id === this.toolbarService.floorPlanFiltersForm.getRawValue().building_id) as RetailLocation;
			this.initialLocationData = {...location, number_of_floors: location.number_of_floors || 1};
			this.locationForm.patchValue(this.initialLocationData, {emitEvent: false});
		});
	}

	private reloadLocations() {
		this.restService.getLocations().then(locations => {
			this.locations = locations;
		});
	}

	private floorWasChanged() {
		let newFloorData = this.floorForm.getRawValue();
		return ['max_x', 'max_y', 'max_z', 'min_x', 'min_y', 'min_z'].some(v => {
			return newFloorData[v] != this.initialFloorData[v];
		});
	}

	private emitMultipleRoomsParamsChanged(data) {
		this.busEventService.multipleRoomsParamsChanged.emit({
			name: data['floor_name'],
			globalRoomWidth: +data['max_x'] - +data['min_x'],
			globalRoomHeight: +data['max_y'] - +data['min_y'],
			backgroundImage: data['base64_image']
		});
	}
}
