import {Component, Input, OnInit} from '@angular/core';

/**
 * Component renders analytics (number of people, total number of people, spent time etc) of shop.
 * Used for Smart Retail app.
 */
@Component({
	selector: 'app-retail-stats',
	templateUrl: './retail-stats.component.html',
	styleUrls: ['./retail-stats.component.scss']
})
export class RetailStatsComponent implements OnInit {

	@Input() data: any = {};

	sort = {
		'numOfPeopleLive': {
			currentSortingColumn: null,
			currentSortingDirection: null,
			sortingCounter: 0
		},
		'numOfPeopleTotal': {
			currentSortingColumn: null,
			currentSortingDirection: null,
			sortingCounter: 0
		},
		'timeSpentInShop': {
			currentSortingColumn: null,
			currentSortingDirection: null,
			sortingCounter: 0
		}
	};

	hiddenColumns = {
		'numOfPeopleLive': [false,false,false],
		'numOfPeopleTotal': [false,false,false],
		'timeSpentInShop': [false,false,false]
	}

	hideColumnTitle = 'Hide column';
	showColumnTitle = 'Show column';

	constructor() {
	}

	ngOnInit() {
	}

	onSort(table, column) {
		if (this.sort[table].currentSortingColumn === column) {
			if (this.sort[table].sortingCounter === 2) {
				this.sort[table].currentSortingColumn = null;
				this.sort[table].currentSortingDirection = null;
				this.sort[table].sortingCounter = 0;
			} else {
				this.sort[table].currentSortingDirection = this.sort[table].currentSortingDirection * -1;
				this.sort[table].sortingCounter++;
			}
		} else {
			this.sort[table].currentSortingColumn = column;
			this.sort[table].currentSortingDirection = -1;
			this.sort[table].sortingCounter = 1;
		}
	}

	onToggleColumn(table, column) {
		this.hiddenColumns[table][column] = !this.hiddenColumns[table][column];
	}

	getMonitoringItems(table) {
		return [].concat((this.data && this.data['Registers'] && this.data['Registers'][table]) || []).sort((a, b) => {
			if (a[this.sort[table].currentSortingColumn] < b[this.sort[table].currentSortingColumn]) {
				return this.sort[table].currentSortingDirection * -1;
			}
			if (a[this.sort[table].currentSortingColumn] > b[this.sort[table].currentSortingColumn]) {
				return this.sort[table].currentSortingDirection;
			}
			return 0;
		});
	}

	numberOfColumns(table) {
		return this.hiddenColumns[table].filter(c => !c).length;
	}
}
