import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {transformArena} from '../../utils/utils';
import {Subscription} from 'rxjs';

/**
 * Base class for 3D layers.
 */
@Component({
	template: ''
})
export class ThreeJSLayerComponent implements OnDestroy {

	isMultipleSensors;
	pixDensity;
	connectedSensorsSettings = [];
	parameters = {};

	protected sensorStatusSubscription;
	protected subscriptions: Array<Subscription> = [];

	ngOnDestroy() {
		if (this.pixDensity) {
			this.pixDensity.cleanup();
		}
		if (this.sensorStatusSubscription) {
			this.sensorStatusSubscription.unsubscribe();
		}
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	protected getArena() {
		let cb = (parameters) => {
			let arena = transformArena(
				parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'],
				parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.userToWebGUITransMat']
			);
			arena.sensorPlane = parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'];

			return arena;
		};

		if (this.isMultipleSensors) {
			return this.connectedSensorsSettings.filter(parameters => {
				return parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'] &&
					parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.userToWebGUITransMat'] &&
					parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'];
			}).map(parameters => cb(parameters));
		} else {
			return cb(this.parameters);
		}
	}
}
