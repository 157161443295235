import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

/**
 * Component represents editable text.
 * If text is clicked input will be shown for editing.
 * If focus is lost then value will be saved and text input will be transformed to text.
 */
@Component({
	selector: 'app-inline-edit-input',
	templateUrl: './inline-edit-input.component.html',
	styleUrls: ['./inline-edit-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InlineEditInputComponent),
			multi: true,
		}
	]
})
export class InlineEditInputComponent implements OnInit, ControlValueAccessor {

	@Input() type = 'text';
	@Input() title;
	@Input() placeholder;
	@Input() tooltip;
	@Output() valueChange = new EventEmitter();
	@Output() blur = new EventEmitter();
	@Output() edit = new EventEmitter();
	@ViewChild('input') input: ElementRef;

	value: any;
	lastEnterKey = +new Date;
	showEditButton = true;

	private propagateChange = (_: any) => {
	};

	constructor(private renderer: Renderer2,
				private ref: ChangeDetectorRef) {
	}

	ngOnInit(): void {
	}

	focus() {
		this.input.nativeElement.focus();
	}

	onFocus() {
		this.showEditButton = false;
	}

	onBlur(e) {
		if (+new Date - this.lastEnterKey > 1000) {
			this.blur.emit(e);
			if (!e.isPrevented) {
				this.showEditButton = true;
			}
		} else {
			this.showEditButton = true;
		}
	}

	onKeyUp(e) {
		if (e.key === 'Enter') {
			this.lastEnterKey = +new Date;
			this.valueChange.emit(this.getInputValue());
		}
	}

	onChange() {
		this.propagateChange(this.getInputValue());
	}

	onEdit(e) {
		e.stopPropagation();
		this.edit.emit();
	}

	writeValue(value: any): void {
		this.value = value;
		this.ref.detectChanges();
	}

	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any): void {
	}

	setDisabledState(isDisabled: boolean): void {
		this.renderer.setProperty(this.input.nativeElement, 'disabled', isDisabled);
	}

	getInputValue() {
		return this.input.nativeElement.value.trim();
	}
}
