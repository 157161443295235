import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalService} from '../../../../services/modal.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-add-geo-fence',
	templateUrl: './add-geo-fence.component.html',
	styleUrls: ['./add-geo-fence.component.scss']
})
export class AddGeoFenceComponent implements OnInit, OnDestroy {

	form: FormGroup;

	private subscriptions: Array<Subscription> = [];

	constructor(private dialogRef: MatDialogRef<AddGeoFenceComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private fb: FormBuilder,
				private modalService: ModalService) {
	}

	ngOnInit(): void {
		this.subscriptions.push(this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
			switch (e.key) {
				case 'Enter':
					if (document.activeElement) {
						document.activeElement['blur']();
					}
					setTimeout(() => {
						this.resolve();
					});
					break;
				case 'Escape':
					this.reject();
					break;
			}
		}));
		this.form = this.fb.group({
			location_name: ['', [Validators.required]],
			location_type: ['', [Validators.required]],
		});
		if (this.data.mode === 'edit') {
			this.form.patchValue({
				location_name: this.data.geoFence.location_name,
				location_type: this.data.geoFence.location_type
			});
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	reject() {
		this.dialogRef.close(false);
	}

	resolve() {
		let nameAlreadyInUse = false;
		if (this.data.mode === 'add') {
			nameAlreadyInUse = this.data.geoFences.find(l => l.location_name === this.form.getRawValue().location_name);
		} else {
			nameAlreadyInUse = this.data.geoFences.filter(l => l.id !== this.data.geoFence.id).find(l => l.location_name === this.form.getRawValue().location_name);
		}
		if (nameAlreadyInUse) {
			this.modalService.showError('NAME_ALREADY_IN_USE_MESSAGE');
		} else {
			this.dialogRef.close(this.form.getRawValue());
		}
	}
}
