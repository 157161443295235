<ng-container [ngSwitch]="control.type">
	<ng-container *ngSwitchCase="ConfigurableTabControlType.TAB_SWITCH">
		<ng-container
			*ngTemplateOutlet="tabSwitch"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="ConfigurableTabControlType.TAB_BUTTON_SWITCH">
		<ng-container
			*ngTemplateOutlet="tabButtonsSwitch"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="ConfigurableTabControlType.TAB_NUMBER_INPUT">
		<ng-container
			*ngTemplateOutlet="tabNumberInput"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="ConfigurableTabControlType.TAB_TEXT_INPUT">
		<ng-container
			*ngTemplateOutlet="tabTextInput"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="ConfigurableTabControlType.TAB_SENSOR_HEIGHT_INPUT">
		<ng-container *ngTemplateOutlet="tabSensorHeightInput"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="ConfigurableTabControlType.TAB_DROPDOWN">
		<ng-container
			*ngTemplateOutlet="tabDropdown"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="ConfigurableTabControlType.TAB_BACKGROUND_IMAGE">
		<ng-container *ngTemplateOutlet="tabBackgroundImage"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="ConfigurableTabControlType.TAB_MONITORED_ROOM_DIMS">
		<ng-container *ngTemplateOutlet="tabMonitoredRoomDims"></ng-container>
	</ng-container>
</ng-container>

<ng-template #tabSwitch>
	<div class="d-flex toggle" [formGroup]="form" >
		<span class="folder-text off">{{ 'OFF' | translate }}</span>
		<app-slide-toggle class="app-slide-toggle" [formControlName]="control.param" [attr.data-selector]="control.param"
						  [matTooltip]="control.tooltip"
						  [readonly]="form.controls[control.param].disabled"></app-slide-toggle>
		<span class="folder-text on">{{ 'ON' | translate }}</span>
	</div>
</ng-template>

<ng-template #tabButtonsSwitch>
	<ng-container [formGroup]="form">
		<mat-button-toggle-group #group [formControlName]="control.param" [attr.data-selector]="control.param" [matTooltip]="control.tooltip">
			<mat-button-toggle *ngFor="let option of control.options" tabindex="-1" [value]="option.value" [attr.value]="option.value">
				{{ option.label | translate | uppercase }}
			</mat-button-toggle>
		</mat-button-toggle-group>
	</ng-container>
</ng-template>

<ng-template #tabNumberInput>
	<div class="d-flex" [formGroup]="form">
		<app-number-input [label]="numberInputLabel" buttonsPosition="right" [control]="form.controls[control.param]"
						  [matTooltip]="control.tooltip"
						  [readonly]="form.controls[control.param].disabled"
						  [max]="control.range | numberInputConfig: 1" [min]="control.range | numberInputConfig:0"
						  [step]="control.step || defaultNumberInputStep" [dataSelector]="control.param"></app-number-input>
	</div>
</ng-template>

<ng-template #tabSensorHeightInput>
	<div class="d-flex" [formGroup]="form">
		<app-number-input [label]="numberInputLabel" buttonsPosition="right" [control]="form.controls[control.param]"
						  [matTooltip]="control.tooltip"
						  [readonly]="form.controls[control.param].disabled"
						  [max]="control.range | numberInputConfig: 1" [min]="control.range | numberInputConfig:0"
						  [step]="control.step || defaultNumberInputStep" [dataSelector]="control.param"
						  (change)="onSensorHeightChanged($event)"></app-number-input>
	</div>
</ng-template>

<ng-template #tabTextInput>
	<div class="d-flex" [formGroup]="form">
		<div class="text-input" [class.disabled]="form.controls[control.param].disabled" [matTooltip]="control.tooltip">
			<input type="text" [formControlName]="control.param" [attr.data-selector]="control.param">
		</div>
	</div>
</ng-template>

<ng-template #tabDropdown>
	<div class="d-flex toggle" [formGroup]="form">
		<mat-form-field floatLabel="never" [class.mat-form-field-disabled]="form.controls[control.param].disabled" [matTooltip]="control.tooltip">
			<mat-select [formControlName]="control.param"
						[multiple]="control.multiple"
						[attr.data-selector]="control.param"
						[attr.value]="form.controls[control.param].value">
				<mat-option *ngFor="let option of control.options" [value]="option.value" [attr.value]="option.value">
					{{option.label}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</ng-template>

<ng-template #tabMonitoredRoomDims>
	<ng-container [formGroup]="form">
		<!-- TODO refactor sensorHeightParameter -->
		<app-arena [sensorSocket]="sensorSocket" [settingForm]="settingForm"
				   [monitoredRoomDimsParameter]="control.param"
				   [sensorHeightParameter]="settingsService.sensorHeightParameter"
				   [rangeX]="control.rangeX"
				   [rangeY]="control.rangeY"
				   [rangeZ]="control.rangeZ"
				   [step]="control.step"
				   [readonly]="form.controls[control.param].disabled"
				   [initialSettings]="initialSettings"
				   (sensorPosition)="onSensorPositionChanged($event)"
				   (monitoredArea)="onMonitoredAreaChanged($event)"
				   [attr.data-selector]="control.param"></app-arena>
	</ng-container>
</ng-template>

<ng-template #tabBackgroundImage>
	<app-background-image-control [control]="control" [matTooltip]="control.tooltip" [settingForm]="settingForm" [sensorSocket]="sensorSocket"></app-background-image-control>
</ng-template>
