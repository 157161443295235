import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	HostBinding,
	AfterViewInit,
	ViewChildren, QueryList
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {feetToMeter, meterToFeet} from '../../../../../utils/utils';
import {SettingsService} from '../../../../../services/settings.service';
import {SensorSliderComponent} from './slider/slider.component';
import {Subscription} from 'rxjs';
import {SensorMountPlane} from '../../../../../consts';
import {ConfigurableControlNumberInputConfig} from '../../../../../services/configuration.service';

export enum Tabs {
	ARENA,
	ROOM_INFO
}

class SliderData {
	public needsUpdate = false;
	public defaultValues: Array<any> = [];
}

/**
 * Component represents arena configs.
 * 3D scene, sensor position, sensor height, monitoring area (input and sliders) are showed.
 */
@Component({
	selector: 'app-arena',
	templateUrl: './arena.component.html',
	styleUrls: ['./arena.component.scss']
})
export class ArenaComponent implements OnInit, OnChanges, OnDestroy {
	@Input() sensorSocket;
	@Input() monitoredRoomDimsParameter;
	@Input() sensorHeightParameter;
	@Input() settingForm: FormGroup;
	@Input() set readonly(readonly) {
		this._readonly = readonly;
		this.disabledAttr = this._readonly ? '' : null;
	};
	get readonly() {
		return this._readonly;
	}
	@Input() initialSettings;
	@Input() rangeX: ConfigurableControlNumberInputConfig;
	@Input() rangeY: ConfigurableControlNumberInputConfig;
	@Input() rangeZ: ConfigurableControlNumberInputConfig;
	@Input() step = 0.1;
	@Output() sensorPosition = new EventEmitter;
	@Output() monitoredArea = new EventEmitter;

	/**
	 * Dynamic. Don't remove
	 */
	@ViewChildren('axisSlider') axesSliders: QueryList<SensorSliderComponent>;
	@HostBinding('attr.value') value;

	@HostBinding('attr.disabled')
	disabledAttr: any = null;

	Tabs = Tabs;

	settingsFor3D;
	activeSensorSliderDefaultValues;
	activeSensorSliderDefaultPercent;
	defaultSensorHeight;

	public sliders = {
		X: new SliderData(),
		Y: new SliderData(),
		Z: new SliderData()
	};

	private subscriptions: Array<Subscription> = [];
	private _readonly;

	constructor(private settingsService: SettingsService) {
	}

	ngOnInit() {
		let MonitoredRoomDimsControls = [
			'MonitoredRoomDimsXMin',
			'MonitoredRoomDimsXMax',
			'MonitoredRoomDimsYMin',
			'MonitoredRoomDimsYMax',
			'MonitoredRoomDimsZMin',
			'MonitoredRoomDimsZMax'
		];

		MonitoredRoomDimsControls.forEach((parameter, index) => {
			let isFeet = this.settingForm.controls.isCoverageFeet.value,
				value = (this.settingForm.controls['sensorParameters'] as FormGroup).controls[this.monitoredRoomDimsParameter].value[index];
			this.settingForm.controls[parameter].setValue(isFeet ? meterToFeet(value) : value);

			this.subscriptions.push(this.settingForm.controls[parameter].valueChanges.subscribe(e => {
				let newValue = MonitoredRoomDimsControls.map(controlName => {
					let newValue = +this.settingForm.controls[controlName].value;

					return isFeet ? feetToMeter(newValue) : newValue;
				});
				(this.settingForm.controls['sensorParameters'] as FormGroup).controls[this.monitoredRoomDimsParameter].setValue(newValue, {emitEvent: false});
				this.updateValueAttr();
			}));
		});
		this.updateValueAttr();
		this.subscriptions.push(this.settingForm.controls.__lastField4Subscribe.valueChanges.subscribe(() => {
			this.updateDefaultValues();
		}));
		this.subscriptions.push((this.settingForm.controls['sensorParameters'] as FormGroup).controls[this.monitoredRoomDimsParameter].valueChanges.subscribe(v => {
			let isFeet = this.settingForm.controls.isCoverageFeet.value;

			MonitoredRoomDimsControls.forEach((parameter, index) => {
				this.settingForm.controls[parameter].setValue(isFeet ? meterToFeet(v[index]) : v[index], {emitEvent: false});
			});
			this.updateValueAttr();
			this.updateDefaultValues();
		}));
		this.subscriptions.push((this.settingForm.controls[`sensorParameters`] as FormGroup).controls[this.sensorHeightParameter].valueChanges.subscribe(() => {
			this.updateDefaultValues();
		}));
		// TODO - it's a patch. We should receive a flag in control, that says that we should request new boardToWebGUITransMat
		this.subscriptions.push((this.settingForm.controls['sensorParameters'] as FormGroup).controls['ProcessorCfg.Common.sensorOrientation.mountPlane'].valueChanges.subscribe(e => {
			/**
			 * If this change is a part of changing several controls, wait for all controls receive new values
			 */
			setTimeout(() => {
				this.onSensorPositionChanged(e);
			});
		}));
		this.updateDefaultValues();
	}

	ngOnChanges(c) {
		if ('initialSettings' in c) {
			if (this.initialSettings) {
				this.settingsFor3D = this.initialSettings;
			} else {
				this.settingsFor3D = this.settingsService.getDefaultSettings();
			}
			this.updateDefaultValues();
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	isSliderReadonly(axis: string): boolean {
		switch (axis) {
			case 'X':
				return this.readonly || this.sliders.Y.needsUpdate || this.sliders.Z.needsUpdate;
			case 'Y':
				return this.readonly || this.sliders.X.needsUpdate || this.sliders.Z.needsUpdate;
			case 'Z':
				return this.readonly || this.sliders.X.needsUpdate || this.sliders.Y.needsUpdate;
		}
		return false;
	}

	public getSliderInputRange(axis: string): any {
		switch (axis) {
			case 'X': return this.rangeX;
			case 'Y': return this.rangeY;
			case 'Z': return this.rangeZ;
		}
	}

	updateDefaultValues() {
		this.sliders.X.defaultValues = [this.settingForm.controls[`MonitoredRoomDimsXMin`].value, this.settingForm.controls[`MonitoredRoomDimsXMax`].value];
		this.sliders.Y.defaultValues = [this.settingForm.controls[`MonitoredRoomDimsYMin`].value, this.settingForm.controls[`MonitoredRoomDimsYMax`].value];
		this.sliders.Z.defaultValues = [
			Math.min(this.settingForm.controls[`MonitoredRoomDimsZMin`].value, this.getSensorHeight()),
			Math.max(this.settingForm.controls[`MonitoredRoomDimsZMax`].value, this.getSensorHeight())
		];
	}

	onValueChange(e, index) {
		const emitResult = this.emitMonitoredAreaChanged();
		if (emitResult.isPrevented) {
			setTimeout(() => {
				e.control.setValue(e.value.previousValue);
			});
		} else {
			this.updateBoundaries();
			this.axesSliders.toArray()[index].updateArenaSliderPositions();
		}
	}

	setActiveTab(id: number): void {
		this.settingForm.controls.activeTab.setValue(id);
	}


	private round(n, d = 1) {
		let result = Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
		return (isNaN(result) ? 0 : result);
	}

	// TODO refactor this hardcoded mountPlaneControl ref
	onSensorPositionChanged(e) {
		let sensorPositionEvent = {
				isPrevented: false
			},
			mountPlaneControl = (this.settingForm.controls['sensorParameters'] as FormGroup).controls['ProcessorCfg.Common.sensorOrientation.mountPlane'],
			newValue = e;

		this.sensorPosition.emit(sensorPositionEvent);
		if (sensorPositionEvent.isPrevented) {
			newValue = mountPlaneControl.value === SensorMountPlane.CEILING ? SensorMountPlane.BACKWALL : SensorMountPlane.CEILING;
		}
		mountPlaneControl.setValue(newValue, {emitEvent: false});
	}

	startDrag(e) {
		this.activeSensorSliderDefaultValues = [].concat(e.defaultValues);
		this.activeSensorSliderDefaultPercent = e.defaultPercent;
		this.defaultSensorHeight = this.getSensorHeight();
	}

	stopDrag(axis) {
		const emitResult = this.emitMonitoredAreaChanged();
		if (emitResult.isPrevented) {
			this.updateValuesBySlider(this.activeSensorSliderDefaultPercent, axis.key);
		}
	}

	emitMonitoredAreaChanged(): {isPrevented: boolean} {
		let event = {
			isPrevented: false
		};
		this.monitoredArea.emit(event);
		return event;
	}

	updateValuesBySlider(percent, axis) {
		if (axis === 'Z') {
			let min = this.sliders.Z.defaultValues[0] > 0 ? 0 : this.sliders.Z.defaultValues[0],
				fullValue = this.round(Math.abs(min) + this.sliders.Z.defaultValues[1]),
				delta = this.round(percent * fullValue / 100),
				control = (this.settingForm.controls[`sensorParameters`] as FormGroup).controls[this.sensorHeightParameter],
				upperBoundaryWasChanged = this.sliders.Z.defaultValues[1] > +this.settingForm.controls[`MonitoredRoomDimsZMax`].value,
				lowerBoundaryWasChanged = this.sliders.Z.defaultValues[0] < +this.settingForm.controls[`MonitoredRoomDimsZMin`].value;

			control.setValue(this.round(min + delta), {emitEvent: false});
			this.sliders.Z.needsUpdate = upperBoundaryWasChanged || lowerBoundaryWasChanged;
		} else {
			let
				min = Math.min(0, this.getSliderMin(axis)),
				max = Math.max(0, this.getSliderMax(axis)),
				minValue = this.activeSensorSliderDefaultValues[0],
				maxValue = this.activeSensorSliderDefaultValues[1],
				length = Math.abs(min) + Math.abs(max),
				boundaryWasChanged = (this.sliders[axis].defaultValues[0] > 0 && this.sliders[axis].defaultValues[1] > 0) ||
					(this.sliders[axis].defaultValues[0] < 0 && this.sliders[axis].defaultValues[1] < 0),
				delta = this.round(length * (this.activeSensorSliderDefaultPercent - percent) / 100);

			this.settingForm.controls[`MonitoredRoomDims${axis}Min`].setValue(this.round(minValue + delta));
			this.settingForm.controls[`MonitoredRoomDims${axis}Max`].setValue(this.round(maxValue + delta));
			this.sliders[axis].needsUpdate = boundaryWasChanged;
		}
	}

	getSliderMin(axis) {
		return this.sliders[axis].defaultValues[0];
	}

	getSliderMax(axis) {
		return this.sliders[axis].defaultValues[1];
	}

	getSliderPositionValue(axis) {
		let sliderPositionValue;
		if (axis === 'Z') {
			sliderPositionValue = this.getSensorHeight();
		} else {
			if (this.sliders[axis].defaultValues[0] > 0) {
				sliderPositionValue = this.sliders[axis].defaultValues[0] - +this.settingForm.controls[`MonitoredRoomDims${axis}Min`].value;
			} else {
				sliderPositionValue = +this.settingForm.controls[`MonitoredRoomDims${axis}Min`].value;
			}
			sliderPositionValue = Math.abs(sliderPositionValue);
		}
		return sliderPositionValue;
	}

	updateBoundaries() {
		this.updateDefaultValues();
		Object.keys(this.sliders).forEach(key => {
			this.sliders[key].needsUpdate = false;
		});
	}

	onMeterFeetChange() {
		let sensorHeightParameterControl = (this.settingForm.controls[`sensorParameters`] as FormGroup).controls[this.sensorHeightParameter];
		if (this.settingForm.controls.isCoverageFeet.value) { // meter -> feet
			['MonitoredRoomDimsXMin', 'MonitoredRoomDimsXMax', 'MonitoredRoomDimsYMin', 'MonitoredRoomDimsYMax', 'MonitoredRoomDimsZMin', 'MonitoredRoomDimsZMax',
				/*'sensorHeight', 'minThreshold', 'maxThreshold'*/].forEach(control => {
				this.settingForm.controls[control].setValue(meterToFeet(this.settingForm.controls[control].value), {emitEvent: false});
			});
			// TODO refactor it
			sensorHeightParameterControl.setValue(meterToFeet(sensorHeightParameterControl.value), {emitEvent: false});
			// this.settingForm.controls.PostureThreshold.setValue(this.settingForm.controls.PostureThreshold.value.map(item => meterToFeet(item)));
		} else { // feet -> meter
			['MonitoredRoomDimsXMin', 'MonitoredRoomDimsXMax', 'MonitoredRoomDimsYMin', 'MonitoredRoomDimsYMax', 'MonitoredRoomDimsZMin', 'MonitoredRoomDimsZMax',
				/*'sensorHeight', 'minThreshold', 'maxThreshold'*/].forEach(control => {
				this.settingForm.controls[control].setValue(feetToMeter(this.settingForm.controls[control].value), {emitEvent: false});
			});
			// TODO refactor it
			sensorHeightParameterControl.setValue(feetToMeter(sensorHeightParameterControl.value), {emitEvent: false});
			// this.settingForm.controls.PostureThreshold.setValue(this.settingForm.controls.PostureThreshold.value.map(item => feetToMeter(item)));
		}
		this.updateBoundaries();
	}

	private getSensorHeight() {
		return +(this.settingForm.controls[`sensorParameters`] as FormGroup).controls[this.sensorHeightParameter].value;
	}

	private updateValueAttr() {
		this.value = JSON.stringify((this.settingForm.controls['sensorParameters'] as FormGroup).controls[this.monitoredRoomDimsParameter].value);
	}
}
