import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SensorsService} from '../services/system/sensors.service';
import {environment} from '../../environments/environment';

/**
 * Guard checks if environment allows to enter to page.
 */
@Injectable({
	providedIn: 'root'
})
export class PageGuard implements CanActivate {

	constructor(private sensorsService: SensorsService,
				private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot,
				state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		// https://github.com/microsoft/TypeScript/issues/2775
		// Issue marked as closed, but seems still reproduced
		let ret: any | Observable<boolean> | Promise<boolean> | boolean = true,
			url = next.pathFromRoot.filter(o => o.url.length).map(o => o.url[0]).join('/'),
			modules = [
				{
					moduleEnabled: environment.isFlowProcessorModuleEnabled,
					modulePath: environment.FLOW_PROCESSOR_MODULE_PATH
				}, {
					moduleEnabled: environment.isSmartBuildingsModuleEnabled,
					modulePath: environment.SMART_BUILDINGS_MODULE_PATH
				}, {
					moduleEnabled: environment.isSmartHomeModuleEnabled,
					modulePath: environment.SMART_HOME_MODULE_PATH
				}, {
					moduleEnabled: environment.isVitalsModuleEnabled,
					modulePath: environment.VITALS_MODULE_PATH
				}, {
					moduleEnabled: environment.isSmartCoolerModuleEnabled,
					modulePath: environment.SMART_COOLER_MODULE_PATH
				}, {
					moduleEnabled: environment.isSmartTailorModuleEnabled,
					modulePath: environment.SMART_TAILOR_MODULE_PATH
				}
			];

		for (let module of modules) {
			if (url.startsWith(module.modulePath)) {
				ret = module.moduleEnabled;
				break;
			}
		}

		if (ret instanceof Promise) {
			return ret.then(response => {
				if (response) {
					return true;
				} else {
					this.router.navigate(['/']);
					return false;
				}
				return response;
			});
		} else if (ret) {
			return true;
		} else {
			this.router.navigate(['/']);
			return false;
		}
	}
}
