import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BackendConnectorBaseComponent} from '../backend-connector.base.component';
import {ConnectionType} from '../../../../consts';
import {ActivatedRoute, Router} from '@angular/router';
import {SensorsService} from '../../../../services/system/sensors.service';
import {StorageService} from '../../../../services/storage.service';
import {SensorSocket} from '../../../../services/system/sensor-socket';
import {BusEventService} from '../../../../services/bus-event.service';
import {SettingsService} from '../../../../services/settings.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ConnectionStatusComponent} from '../../connection-status/connection-status.component';
import {environment} from '../../../../../environments/environment';
import {MODAL_TYPE, ModalService} from '../../../../services/modal.service';
import {ToolbarService} from '../../../../services/toolbar.service';
import {ConnectionData} from '../../../../models/models';
import {ConnectionStatus} from '../../../../services/system/connection';
import {MODULE_PATH} from 'src/app/app.module';
import {ConfigurablePageControlType, ConfigurationService} from '../../../../services/configuration.service';

/**
 * Backend connector to connect to EVK.
 */
@Component({
	selector: 'app-backend-connector-ws',
	templateUrl: '../backend-connector.base.component.html',
	styleUrls: ['../backend-connector.base.component.scss']
})
export class BackendConnectorEvkComponent extends BackendConnectorBaseComponent implements OnInit, OnDestroy {

	type = ConnectionType.WS;
	connection: SensorSocket;

	private connectionEstablishedPopup: MatDialogRef<ConnectionStatusComponent> | null;
	private connectionAttemptsCountMax = environment.maxConnectionAttempts;
	private connectionAttemptsCount = 0;
	private remoteConnectionAttemptsCountMax = 2;
	private lastErrorWindow;
	private statusPopup;
	private remoteHostListPopup;
	private statusPopupsDelay = 500;
	private statusPopupsTimeout;
	public showSocketList = true;

	constructor(public busEventService: BusEventService,
				private router: Router,
				private settingsService: SettingsService,
				private sensorsService: SensorsService,
				private storageService: StorageService,
				private route: ActivatedRoute,
				private modalService: ModalService,
				private toolbarService: ToolbarService,
				private configurationService: ConfigurationService,
				@Inject(MODULE_PATH) private MODULE_PATH) {
		super(busEventService);
	}

	ngOnInit(): void {
		this.route.url.subscribe(async () => {
			setTimeout(() => {
				let url = this.toolbarService.getRoute().paramMap.get('url');
				this.connectToURL(url);
			});
		});
		this.busEventService.connect.subscribe((data: ConnectionData) => {
			if (!data.fromConnector && data.type === this.type) {
				this.onConnectInConnectionList(data);
			}
		});
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	onConnectInConnectionList(data: ConnectionData) {
		let [ip, port] = data.url.split(':'),
			url = `${ip}:${port || environment.defaultSensorPort}`;

		this.router.navigate([this.prepareURL(`${this.toolbarService.getRoute().data.slug}/${url}`)]);
		this.connectToURL(url);
	}

	public connect() {
		let cb = () => {
			super.connect();
		};
		this.isNetworkDisconnectedPopupShowed = false;
		if (!this.connection.canDisconnect()) {
			this.sensorsService.connectSocket(this.connection, async (success) => {
				if (this.isDestroyed) {
					return;
				}

				if (!success && !this.connection.isConnectedToLocalhost() && this.connectionAttemptsCount === this.remoteConnectionAttemptsCountMax) {
					this.exitWelcomeFlow();
					if (!this.lastErrorWindow) {
						setTimeout(() => {
							if (!this.isDestroyed) {
								this.modalService.showMessage('CONNECT_TO_A_DIFFERENT_HOST_OR_CONTACT_SUPPORT',
									'CONNECTION_TO_A_REMOTE_HOST_FAILED');
							}
						});
					}
				} else if (!success && this.connection.isConnectedToLocalhost() && (this.connectionAttemptsCount === 2 || this.connectionAttemptsCount === 3)) {
					if (!this.lastErrorWindow) {
						this.modalService.confirm('PLEASE_VERIFY_THAT_THE_EVK_ENGINE_APPLICATION_IS_RUNNING', {}, 'UNABLE_TO_CONNECT_LOCALLY').afterClosed().toPromise().then(res => {
							if (!res) {
								this.exitWelcomeFlow();
							}
						});
					}
				} else if (!success && this.connection.isConnectedToLocalhost() && this.connectionAttemptsCount === 4) {
					this.exitWelcomeFlow();
					if (!this.lastErrorWindow) {
						this.modalService.showMessage('CONNECT_TO_A_DIFFERENT_HOST_OR_CONTACT_SUPPORT', 'CONNECTION_TO_A_REMOTE_HOST_FAILED');
					}
				} else {
					await this.settingsService.getSocketInfo(this.connection.url).then(socketInfo => {
						if ((environment.showWelcomeFlow || this.connectionEstablishedPopup) && socketInfo.showWelcomeFlow !== false) {
							this.openConnectionEstablishedPopup(success);
						} else if (this.connectionEstablishedPopup) {
							this.connectionEstablishedPopup.close();
							this.connectionEstablishedPopup = null;
						}
					});
				}

				if (this.connectionAttemptsCount === this.connectionAttemptsCountMax) {
					this.connectionAttemptsCount = 0;
				}
				this.connectEvent = {
					url: this.connection.url,
					type: this.type
				};
				cb();
			});
		} else {
			this.connectEvent = {
				url: this.connection.url,
				type: this.type
			};
			cb();
		}
	}

	public disconnect() {
		this.disconnectEvent = {
			url: this.connection.url,
			type: this.type
		};
		this.sensorsService.disconnectSocket(this.connection);
		super.disconnect();
	}

	public updateIP() {
		if (this.connection) {
			this.updateConnectionName();
			this.ip = this.connection.ip;
		}
	}

	public getConnectionStatus(): ConnectionStatus {
		if (this.connection) {
			if (this.connection.isConnecting()) {
				return ConnectionStatus.CONNECTING;
			} else {
				return this.connection.isConnected() ? ConnectionStatus.CONNECTED : ConnectionStatus.DISCONNECTED;
			}
		} else {
			return ConnectionStatus.NOT_CONNECTED;
		}
	}

	private subscribeToSocketEvents() {
		this.subscriptions.push(this.connection.status.subscribe((status: ConnectionStatus) => {
			this.updateStatus();
		}));
		this.subscriptions.push(this.connection.close.subscribe(disconnectReason => {
			this.disconnectReason = disconnectReason;
		}));
	}

	private async updateConnectionName(): Promise<void> {
		const socketInfo = await this.settingsService.getSocketInfo(this.connection.url);
		this.connectedSensorName = socketInfo.name;
	}

	private async getLastUsedSocket(): Promise<SensorSocket | undefined> {
		const socketPool = await this.storageService.getItem('socketPool');
		if (!this.isDestroyed && socketPool.length) {
			// Connect last used sensor
			const pool = (socketPool || []).map(url => {
				return this.settingsService.getSocketInfo(url);
			});
			const socketInfos = await Promise.all(pool);
			if (!this.isDestroyed) {
				// Sort by last used time
				const sortedPool = socketInfos
				.map((info: any, i) => ({
					lastUsed: info['lastUsed'],
					url: socketPool[i]
				}))
				.sort((a, b) => {
					if (a.lastUsed < b.lastUsed) {
						return 1;
					}
					if (a.lastUsed > b.lastUsed) {
						return -1;
					}
					return 0;
				});

				let [ip, port] = sortedPool[0].url.split(':');
				return this.sensorsService.getSensorSocket(sortedPool[0].url) || await this.sensorsService.createSensorSocket(ip, port);
			}
		}
	}

	private updateStatus() {
		if (this.connection) {
			let status;
			clearTimeout(this.statusPopupsTimeout);
			if (this.statusPopup) {
				this.statusPopup.close();
			}
			switch (this.connection.connectionStatus) {
				case ConnectionStatus.CONNECTING:
					status = `STATUS_OF_SENSOR_CONNECTING`;
					let host = 'local host',
						isLocalHost = this.connection.ip === environment.localHostSensorIp,
						additionalButtons: any[] = [];

					if (!isLocalHost) {
						host = `remote host \n${this.connection.ip}`;
						additionalButtons.push({text: 'Switch remote host', key: 'remote'});
						additionalButtons.push({text: 'Connect to local host', key: 'local'});
					} else {
						additionalButtons.push({text: 'Connect to remote host', key: 'remote'});
					}
					this.statusPopup = this.modalService.showLoadingPopup(`Connecting to ${host}`, additionalButtons);
					this.statusPopup.afterClosed().toPromise().then(resAction => {
						if (typeof resAction === 'boolean') {
							this.sensorsService.disconnectSocket(this.connection);
						} else if (typeof resAction === 'string') {
							this.sensorsService.disconnectSocket(this.connection);
							switch (resAction) {
								case 'remote':
									this.connectionResultCallback('switch-host', true);
									break;
								case 'local':
									if (this.connectionEstablishedPopup || this.remoteHostListPopup) {
										this.connectionResultCallback('switch-host');
									} else {
										this.connectToLocalHost();
									}
									break;
							}
						}
					});
					break;
				case ConnectionStatus.CONNECTED:
				case ConnectionStatus.STOPPED:
					this.isNetworkDisconnectedPopupShowed = false;
					status = `STATUS_OF_SENSOR_CONNECTED`;
					if (this.remoteHostListPopup) {
						this.remoteHostListPopup.close();
					}
					break;
				case ConnectionStatus.INITIALIZING:
				case ConnectionStatus.CALIBRATING:
				case ConnectionStatus.RESETTING:
					this.statusPopupsTimeout = setTimeout(() => {
						if (!this.isDestroyed) {
							let component = this,
								configObject = {
									cancelCallback() {
										/**
										 * If user click "Cancel" in loading popup,
										 * close this popup after CONFIGURING status received
										 */
										let statusPopup = component.statusPopup;
										component.statusPopup = null;
										component.connection.stop();

										let statusSubscription = component.connection.status.subscribe(status => {
											if (status === ConnectionStatus.CONFIGURING) {
												statusPopup.close();
												statusSubscription.unsubscribe();
											}
										});
									}
								};
							if (this.connection.connectionStatus === ConnectionStatus.RESETTING) {
								configObject['message'] = 'An error has occurred, please wait while we reset the system';
							}
							this.statusPopup = this.modalService.showLoadingPopup(ConnectionStatus[this.connection.connectionStatus], configObject, this.connection.connectionStatus !== ConnectionStatus.RESETTING);
						}
					}, this.statusPopupsDelay);
					status = 'STATUS_OF_SENSOR_CONNECTED';
					break;
				case ConnectionStatus.IMAGING:
					status = 'STATUS_OF_SENSOR_CONNECTED';
					break;
				case ConnectionStatus.SAVING_DATA:
					this.statusPopup = this.modalService.showLoadingPopup('Saving Data');
					/**
					 * The popup with recording directory will appears twice
					 * Since stopSensor will open this popup also
					 */
					this.statusPopup.afterClosed().toPromise().then(res => {
						if (typeof res === 'boolean') {
							this.sensorsService.disconnectSocket(this.connection);
						} else {
							this.busEventService.sensorRecordingSaved.emit();
						}
					});
					status = 'STATUS_OF_SENSOR_CONNECTED';
					break;
				case ConnectionStatus.CONFIGURING:
					status = 'STATUS_OF_SENSOR_CONFIGURING';
					break;
				case ConnectionStatus.ERROR:
					status = `STATUS_OF_SENSOR_ERROR`;
					break;
				case ConnectionStatus.DISCONNECTED:
					status = `STATUS_OF_SENSOR_DISCONNECTED`;
					break;
				case ConnectionStatus.NOT_CONNECTED:
					status = `STATUS_OF_SENSOR_NO_SENSOR`;
					break;
			}
			this.setConnectionStatus(status);
		}
	}

	private exitWelcomeFlow() {
		if (this.connectionEstablishedPopup) {
			this.connectionEstablishedPopup.close();
			this.connectionEstablishedPopup = null;
		}
		this.connectionAttemptsCount = 0;
	}

	private openConnectionEstablishedPopup(success) {
		var sub: any = {};

		if (this.connectionEstablishedPopup) {
			this.connectionEstablishedPopup.close();
			this.connectionEstablishedPopup = null;
		}
		this.settingsService.getSensorParameters(this.connection.url).then(sensorSocketSettings => {
			console.log('Connection result:', success ? 'Established' : 'Failed');
			console.log('Socket: connected ', this.connection.isConnected(), ', status ', this.connection.connectionStatus);
			setTimeout(async () => {
				// Should be opened after opening the menu in sensor-tracker-view component
				if (this.connection.isConnected()) {
					await this.busEventService.menuOpened;
				}
				if (!this.connection.isAnotherClientConnected && !this.isDestroyed) {
					let tabbedFormControl = (this.configurationService.pageMap[this.toolbarService.getRoute().data.pageName]?.controls || []).find(c => {
							return c.type === ConfigurablePageControlType.PAGE_TABBED_FORM_CONTROL;
						}),
						config = {
						allowDefineNewArena: sensorSocketSettings ? this.connection.isEditableParameter(sensorSocketSettings['ProcessorCfg.MonitoredRoomDims']) : false,
						success,
						showOpenSettings: tabbedFormControl
					};
					this.connectionEstablishedPopup = this.modalService.showConnectionResult(this.connection, config, (res, data) => {
							if (res === 'load-settings') {
								data = sub;
							}
							this.connectionResultCallback(res, data);
						});

					// Save current popup to variable to be sure to null right popup after popup is closed, because sometime we have several active popups
					let connectionEstablishedPopup = this.connectionEstablishedPopup;
					connectionEstablishedPopup.afterClosed().toPromise().then(() => {
						if (sub.sub) {
							sub.sub.unsubscribe();
						}
						if (connectionEstablishedPopup === this.connectionEstablishedPopup) {
							this.connectionEstablishedPopup = null;
						}
					});
				}
			});
		});
	}

	private connectionResultCallback(res, data?) {
		let tabbedFormControl = (this.configurationService.pageMap[this.toolbarService.getRoute().data.pageName].controls || []).find(c => {
			return c.type === ConfigurablePageControlType.PAGE_TABBED_FORM_CONTROL;
		});
		switch (res) {
			case 'new-settings':
				this.toolbarService.getToolbarComponent().onShowSettings(tabbedFormControl).afterClosed().toPromise().then(saved => {
					if (saved && this.connectionEstablishedPopup) {
						this.connectionEstablishedPopup.close();
					}
				});
				break;
			case 'load-settings':
				data.sub = this.busEventService.onSettingsFromFileLoaded.subscribe(() => {
					if (this.connectionEstablishedPopup) {
						this.connectionEstablishedPopup.close();
					}
				});
				this.busEventService.onClickLoadSettings.emit();
				break;
			case 'load-recording':
				this.toolbarService.recordingPlayingToggle.emit({control: tabbedFormControl});
				if (this.connectionEstablishedPopup) {
					this.connectionEstablishedPopup.close();
				}
				break;
			case 'switch-host':
				if (this.connectionEstablishedPopup) {
					this.connectionEstablishedPopup.close();
				}
				if (this.connection.isConnectedToLocalhost() || data) {
					if (!this.remoteHostListPopup) {
						this.remoteHostListPopup = this.modalService.showRemoteHostList();
						this.remoteHostListPopup.componentInstance.inModal = true;
						this.remoteHostListPopup.afterClosed().toPromise().then(newSocket => {
							this.remoteHostListPopup = null;
							if (newSocket) {
								this.router.navigate([this.prepareURL(`${this.toolbarService.getRoute().data.slug}/${newSocket.url}`)]);
							}
						});
						this.remoteHostListPopup.componentInstance.sensorSocket = this.connection;
						let rSub = this.remoteHostListPopup.componentInstance.close.subscribe(() => {
							this.remoteHostListPopup.close();
							rSub.unsubscribe();
						});
					}
				} else {
					this.connectToLocalHost();
				}
				break;
			case 'try-again':
				this.connectionAttemptsCount++;
				this.connectToURL(this.connection.url);
				break;
			case 'cancel':
				this.modalService.close(MODAL_TYPE.ALL);
				this.connectionEstablishedPopup = null;
				break;
			case 'doNotShowWelcomeFlowChange':
				this.settingsService.saveSocketInfo(this.connection.url, {
					showWelcomeFlow: data
				});
				break;
		}
	}

	private connectToLocalHost() {
		let url = `${environment.localHostSensorIp}:${environment.defaultSensorPort}`;
		this.router.navigate([this.prepareURL(`${this.toolbarService.getRoute().data.slug}/${url}`)]);
		this.connectToURL(url);
	}

	async connectToURL(url) {
		if (url) {
			let [ip, port] = url.split(':');
			this.connection = this.sensorsService.getSensorSocket(url) || await this.sensorsService.createSensorSocket(ip, port);
		} else {
			let lastUsedSocket = <SensorSocket>(await this.getLastUsedSocket());
			if (lastUsedSocket) {
				this.connection = lastUsedSocket;
				await this.router.navigate([this.prepareURL(`${this.toolbarService.getRoute().data.slug}/${lastUsedSocket.ip}:${lastUsedSocket.port}`)], {replaceUrl: true});
			} else {
				this.connectToLocalHost();
			}
		}
		if (this.connection) {
			this.subscribeToSocketEvents();
			this.connect();
			this.updateIP();
		}
	}

	protected prepareURL(url) {
		return `/${this.MODULE_PATH}/${url}`;
	}
}
