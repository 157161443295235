import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseViewComponent} from '../../../../../components/base-view/base-view.component';
import {RunMode, SensorSocket} from '../../../../../services/system/sensor-socket';
import {makeDeepCopy, prepareRecordingPath} from '../../../../../utils/utils';
import {ConnectionStatus} from '../../../../../services/system/connection';
import {MODAL_TYPE} from '../../../../../services/modal.service';
import {environment} from '../../../../../../environments/environment';
import {ConnectionType, SelectingTargetDataGraphBehavior} from '../../../../../consts';
import {resources} from '../../../../../utils/ResourcesLoader';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {IConfigurablePageControl, Layer} from '../../../../../services/configuration.service';
import {cleanColorTable, resetColorTables} from '../../../../../utils/ColorTablesUtils';
import {OutputType} from '../../../../../models/models';

const humanModelsResources = {
	'Standing': 'Standing',
	'Sitting': 'Sitting',
	'Lying': 'LayingDown',
	'Walking': 'Walking'
};

@Component({
	selector: 'app-single-sensor',
	templateUrl: '../../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../../components/base-view/base-view.component.scss']
})
export class SingleSensorComponent extends BaseViewComponent implements OnInit, OnDestroy {

	connection: SensorSocket;

	protected isNeedToPlayBeepSound = true;
	protected busEventServiceConnectSub: Subscription;

	protected isStatusAfterErrorAsked = false;
	protected lastErrorWindow;
	protected recordingFlowLoadSettingsSubscribe;
	protected busEventServiceClearTargetSelectSub: Subscription;
	protected busEventServiceTargetSelectedSub: Subscription;
	protected routeChangeSub: Subscription;
	protected translate: TranslateService;

	protected router: Router;

	constructor(protected injector: Injector) {
		super(injector);
		this.router = injector.get(Router);
		this.translate = injector.get(TranslateService);
	}


	ngOnInit() {
		this.toolbarService.resetArenaModes();
		this.subscribeToConnectionUpdates();
		this.subscribeToTargetSelectionUpdates();
		this.subscribeToToolbarControls();
		this.initColorTables();
		this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
			this.combinedData = data;
		}));
		this.subscriptions.push(this.busEventService.sensorRecordingSaved.subscribe(() => {
			this.onSensorRecordingSave();
		}));
		this.subscriptions.push(this.busEventService.onClickLoadSettings.subscribe(() => {
			this.fileuploadSettingsInput.nativeElement.click();
		}));
	}


	ngOnDestroy() {
		if (this.busEventServiceClearTargetSelectSub) {
			this.busEventServiceClearTargetSelectSub.unsubscribe();
		}
		if (this.busEventServiceTargetSelectedSub) {
			this.busEventServiceTargetSelectedSub.unsubscribe();
		}
		if (this.busEventServiceConnectSub) {
			this.busEventServiceConnectSub.unsubscribe();
		}
		if (this.routeChangeSub) {
			this.routeChangeSub.unsubscribe();
		}
		super.ngOnDestroy();
	}


	/**
	 * Recording has been saved
	 */
	onSensorRecordingSave() {
		let recordingDir = this.connectionSettings['sensorParameters']['FlowCfg.save_dir'],
			recordingTypes = this.getSelectedRecordingsTypesString(),
			message = `Saved ${recordingTypes} successfully at<br>${recordingDir}`;
		if(recordingTypes){
			this.modalService.showMessage(message, 'RECORDING_SAVED', 'OK', false);
		}
	}

	/**
	 * On sensitivity changed in base-view menu.
	 */
	onSensitivityValueChange(sensitivityValue) {
		this.sensitivityValue = sensitivityValue;
		this.updateSensorSettings({
			'ProcessorCfg.ExternalGUI.FilterImage.numOfSd': sensitivityValue
		}, true);
	}


	/**
	 * On load recording button click in toolbar.
	 * Opens input for records folder.
	 */
	recordingPlayingToggle(control: IConfigurablePageControl) {
		if (this.recordingFlowLoadSettingsSubscribe) {
			this.recordingFlowLoadSettingsSubscribe.unsubscribe();
		}
		this.settingsService.getSocketInfo(this.connection.url).then(socketInfo => {
			let realSettings = this.sensorsService.getRealSettings(this.connection.url),
				settings = realSettings ? {sensorParameters: realSettings} : this.connectionSettings;

			return this.modalService.userInput('INSERT_RECORDED_DIRECTORY', {
				value: this.connectionSettings['sensorParameters']['FlowCfg.save_dir'],
				sensorParams: settings,
				control,
				connection: this.connection,
				runMode: RunMode.REPROCESS_IQ_DATA
			}).afterClosed().toPromise().then( async (res) => {
				if (typeof res === 'object') {
					await this.settingsService.saveSensorParameters(this.connection.url, res.params);
					this.inputRecordedDirectoryPathResultCallback(res.value, socketInfo);
				}
			});
		});
	}


	onFileSelect(event: Event) {
		if ((<HTMLInputElement>event.target).files?.length) {
			let reader = new FileReader();

			reader.onload = async (e: ProgressEvent) => {
				(<HTMLInputElement>event.target).value = '';
				var parameters;

				try {
					parameters = JSON.parse((<EventTarget>e.target)['result']);
				} catch (e) {
					console.error(e);
					this.modalService
					.showError(`Settings could not be loaded\n(${e.message})`, true, {}, 'Settings Loading Failed', 'Continue');
				}
				if (parameters && this.checkUploadedParameters(parameters)) {
					this.settingsService.saveAllSettings(this.connection.url, parameters).then(() => {
						this.sensorsService.triggerParametersChange(this.connection.url, parameters['sensorParameters']);
						this.busEventService.onSettingsFromFileLoaded.emit();
					});
				}
			};
			reader.readAsText((<FileList>(<HTMLInputElement>event.target).files)[0]);
		}
	}


	/**
	 * Socket in recording
	 */
	runSensorRecording(runMode: RunMode, control: IConfigurablePageControl) {
		let title = 'INSERT_RECORDING__NAME',
			value = '';
		if (runMode === RunMode.REPROCESS_AND_RECORD) {
			title = 'INSERT_RECORDED_DIRECTORY';
			value = this.connectionSettings['sensorParameters']['FlowCfg.save_dir'];
		}

		this.modalService.userInput(title, {
			value,
			sensorParams: this.connectionSettings,
			control,
			connection: this.connection,
			runMode,
			showSaveDirBaseIfNeeded: runMode === RunMode.RECORD,
		}).afterClosed().toPromise().then(async (res) => {
			if (typeof res === 'object') {
				let path = res.value,
					save_dir_base = this.connectionSettings['sensorParameters']['FlowCfg.save_dir_base'],
					settings = Object.assign({}, res.params);

				if (!save_dir_base && res.save_dir_base) {
					settings['FlowCfg.save_dir_base'] = res.save_dir_base;
					save_dir_base = res.save_dir_base;
				}
				await this.settingsService.saveSensorParameters(this.connection.url, settings);
				this.sensorsService.sendSettings(this.connection, true, settings);
				this.sensorsService.runSensor(this.connection, runMode, {
					path: prepareRecordingPath(path, save_dir_base)
				});
			}
		});
	}

	async updateWithSettings(initialParameters?) {
		const parameters = await this.settingsService.getAllSettings(this.connection.url);
		this.isDuplicateView = parameters.guiParameters.isDuplicateView;
		if (parameters.guiParameters['selectedCameras']) {
			this.selectedCameras = parameters.guiParameters['selectedCameras'];
		}
		this.toolbarService.setIsDuplicateViewFlag(this.isDuplicateView);
		this.sensitivityValue = parameters.sensorParameters['ProcessorCfg.ExternalGUI.FilterImage.numOfSd'];
		if (initialParameters) {
			this.generateLayers({
				sensorParameters: initialParameters,
				guiParameters: parameters.guiParameters
			});
			this.setOutputs(false);
		}
	}


	onLayerToggle(viewIndex: number, layer: Layer) {
		super.onLayerToggle(viewIndex, layer);
		this.setOutputs();
	}


	onDuplicateViewToggle() {
		super.onDuplicateViewToggle();
		this.setOutputs();
	}


	onPosture(viewIndex) {
		var posture = this.getPostureForSensorTrackersView(viewIndex);

		this.selectedPostures[viewIndex] = !posture;
		this.setOutputs();
	}


	protected checkUploadedParameters(parameters) {
		return this.settingsService.checkUploadedParametersAreValid(parameters, this.connection);
	}


	protected subscribeToConnectionUpdates(): void {
		if (this.busEventServiceConnectSub) {
			this.busEventServiceConnectSub.unsubscribe();
		}
		// On connect from connections list (small popup in right top corner)
		this.busEventServiceConnectSub = this.busEventService.connect.subscribe(e => {
			if (e.type === ConnectionType.WS) {
				this.updateSensorSocket(e.url);
			}
		});
		setTimeout(() => {
			/**
			 * Due to https://github.com/angular/angular/issues/16710
			 * we should check, that component is still active before continue
			 * to prevent subscription inside chain
			 * '/sensor' -> '/sensor/127.0.0.1:1234' -> '/sensor' -> '/sensor/127.0.0.1:1234'
			 * These transitions appears because:
			 * navigationTrigger: 'popstate' -> app redirect to last used sensor or localhost ->
			 * navigationTrigger: 'hashchange' (this is Angular bug and we shouldn't receive it) -> app redirect to last used sensor or localhost again
			 * In regular usage it doesn't happen, because we use Angular router to transition between app pages
			 * But by using Protractor it happens, when we load already active url.
			 * location.assign('http://localhost:4500/#/fp/sensor/') emulate this behaviour
			 */
			if (this.isDestroyed) {
				return;
			}
			if (this.routeChangeSub) {
				this.routeChangeSub.unsubscribe();
			}
			this.routeChangeSub = this.route.params.subscribe(async (params) => {
				if (params['url']) {
					this.updateSensorSocket(params['url']);
				}
			});
		});
	}

	protected runSensor() {
		this.sensorsService.runSensor(this.connection, RunMode.LIVE);
	}


	protected stopSensor() {
		switch (this.connection.recordingInfo.mode) {
			case RunMode.LIVE:
			case RunMode.REPROCESS_IQ_DATA:
			case RunMode.REPROCESS_AND_RECORD:
			case RunMode.RECORD:
				this.connection.stop();
				break;
			case RunMode.PLAYBACK:
				this.sensorsService.stopSensor(this.connection);
				break;
		}
	}


	protected updateSensorSettings(settings, notify) {
		return this.settingsService.saveSensorParameters(this.connection.url, settings).then(() => {
			return this.sensorsService.sendSettings(this.connection, notify, settings);
		});
	}


	protected async updateSensorSocket(url) {
		let [ip, port] = url.split(':');
		if (!port) {
			port = environment.defaultSensorPort;
		}
		this.connection = this.sensorsService.getSensorSocket(url) || await this.sensorsService.createSensorSocket(ip, port);
		this.toolbarService.setConnection(this.connection);
		this.settingsService.saveSocketInfo(this.connection.url, {
			lastUsed: +(new Date)
		});
		this.registerSocketEvents();
	}


	protected setOutputs(allowEmpty = true) {
		let outputs: Array<string> = [];
		let BreathingGraphs = this.layers[0].find(l => l.name === 'BreathingGraphs');
		let ActivityGraphs = this.layers[0].find(l => l.name === 'ActivityGraphs');
		outputs = outputs.concat(...this.extractOutputs(0));
		if (this.isDuplicateView) {
			outputs = outputs.concat(...this.extractOutputs(1));
		}
		outputs = Array.from(new Set(outputs));
		if (outputs.length || allowEmpty) {
			let lastUsedOutputs: Array<string> = makeDeepCopy(this.connection.lastUsedOutputs[environment.outputsType as OutputType] || []),
				outputsChangedEventData = {
					url: this.connection.url,
					isBreathingActive: false,
					isActivityActive: false,
					outputs,
					needBreathingGraphGap: false,
					needActivityGraphGap: false
				},
				outputsGraphsConfigs = [{
					layer: BreathingGraphs,
					needGraphGapKeyName: 'needBreathingGraphGap',
					isActiveKeyName: 'isBreathingActive',
					outputKeyName: 'breathingOutput'
				}, {
					layer: ActivityGraphs,
					needGraphGapKeyName: 'needActivityGraphGap',
					isActiveKeyName: 'isActivityActive',
					outputKeyName: 'activityOutput'
				}];

			this.sendOutputsToSocket(outputs);

			outputsGraphsConfigs.forEach(config => {
				if (config.layer) {
					let allKeysPresentInPreviousOutputs = Object.values(config.layer.dataDict).every(o => lastUsedOutputs.includes(o)),
						someKeysNotPresentInNewOutputs = Object.values(config.layer.dataDict).some(o => !outputs.includes(o)),
						someOutputsRemoved = allKeysPresentInPreviousOutputs && someKeysNotPresentInNewOutputs,
						someKeysNotPresentInPreviousOutputs = Object.values(config.layer.dataDict).some(o => !lastUsedOutputs.includes(o)),
						allKeysPresentInNewOutputs = Object.values(config.layer.dataDict).every(o => outputs.includes(o)),
						someOutputsAdded = someKeysNotPresentInPreviousOutputs && allKeysPresentInNewOutputs;

					outputsChangedEventData[config.needGraphGapKeyName] = someOutputsRemoved || someOutputsAdded;
					outputsChangedEventData[config.isActiveKeyName] = Object.values(config.layer.dataDict).every(o => outputs.includes(o));
					outputsChangedEventData[config.outputKeyName] = config.layer.dataDict.data;
				}
			});
			this.busEventService.outputsChanged.emit(outputsChangedEventData);
		}
	}


	protected async onParametersChange(initialParameters?) {
		// Get settings from connection or localStorage
		const parameters = await this.settingsService.getAllSettings(this.connection.url, false);

		// In case we retrieve sensorParameters from another client
		if (!('guiParameters' in parameters)) {
			parameters['guiParameters'] = this.settingsService.getDefaultSettings()['guiParameters'];
		}
		this.connectionSettings = parameters;
		this.toolbarService.setSensorSocketSettings(this.connectionSettings);
		if (this.connectionSettings['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.Automatically) {
			this.selectedModelIds = [];
		}
		if (!this.Standing3DModel) {
			setTimeout(() => {
				if (!this.isDestroyed) {
					this.preloadModelsResources();
				}
			});
		}

		this.updateWithSettings(initialParameters);
	}

	/**
	 * Subscribe on status changes, close, parametersChange, dataRetrieve, metaDataRetrieve, combined data in connection.
	 */
	protected registerSocketEvents() {
		var previousStatus;
		if (this.statusSub) {
			this.statusSub.unsubscribe();
		}
		this.statusSub = this.connection.status.subscribe((status: ConnectionStatus) => {
			if (this.connection) {
				switch (status) {
					case ConnectionStatus.SAVING_DATA:
						if (this.isNeedToPlayBeepSound) {
							this.playBeepSound();
						}
						this.isNeedToPlayBeepSound = true;
						break;
					case ConnectionStatus.ERROR:
						let errorMessage = this.connection.lastErrorMessage,
							showOk = true;
						/**
						 * We want to close all opened modal windows except errors
						 */
						this.modalService.close(MODAL_TYPE.CONFIRM, MODAL_TYPE.INPUT, MODAL_TYPE.MESSAGE);
						if (!errorMessage) {
							/**
							 * If this is unhandled exception:
							 * 1. Show "Unhandled exception!" message
							 * 2. Ask for the sensor status 1 time:
							 * 	> if status is still ERROR - ask user to restart EVK and reconnect (popup will be closed after disconnect)
							 */
							if (!this.isStatusAfterErrorAsked) {
								errorMessage = 'UNHANDLED_EXCEPTION_ERROR';
								this.connection.retrieveStatus();
								this.isStatusAfterErrorAsked = true;
							} else {
								/**
								 * At this stage we have another "Unhandled exception" without an error message
								 */
								if (this.lastErrorWindow) {
									/**
									 * Since we got another "Unhandled exception" we can close the previous one
									 */
									this.lastErrorWindow.close();
								}
								errorMessage = 'UNHANDLED_EXCEPTION_ERROR_WITH_RECONNECT';
								this.isStatusAfterErrorAsked = false;
								showOk = false;
							}
						}
						this.lastErrorWindow = this.modalService.showError(errorMessage, showOk);
						this.lastErrorWindow.afterClosed().toPromise().then(() => {
							this.lastErrorWindow = null;
						});
						break;
					case ConnectionStatus.DISCONNECTED:
						this.onDisconnect();
						break;
				}
				previousStatus = status;
			}
		});
		if (this.closeSub) {
			this.closeSub.unsubscribe();
		}
		this.closeSub = this.connection.close.subscribe(disconnectReason => {
			this.toolbarService.setDisconnectReason(disconnectReason);
		});
		if (this.parametersChangeSub) {
			this.parametersChangeSub.unsubscribe();
		}
		if (this.sensorsService.eventListeners.parametersChange[this.connection.url]) {
			this.parametersChangeSub = this.sensorsService.eventListeners.parametersChange[this.connection.url].subscribe(parameters => {
				/**
				 * Subscription is BehaviorSubject and after re-connect to socket we could call onParametersChange until socket is not
				 * connected. So we should check, that socket is connected.
				 */
				if (parameters && this.connection.isConnected()) {
					this.onParametersChange(parameters);
				}
			});
		}
		if (this.dataRetrieveSub) {
			this.dataRetrieveSub.unsubscribe();
		}
		if (this.sensorsService.eventListeners.dataRetrieve[this.connection.url]) {
			this.dataRetrieveSub = this.sensorsService.eventListeners.dataRetrieve[this.connection.url].subscribe(data => {
				this.data = data;
			});
		}
		this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
			this.combinedData = data;
		}));
	}


	protected subscribeToToolbarControls(): void {
		// Controls of toolbar
		this.subscriptions.push(this.toolbarService.sensorRun.subscribe(() => {
			this.runSensor();
		}));
		this.subscriptions.push(this.toolbarService.sensorStop.subscribe(() => {
			this.stopSensor();
		}));
		this.subscriptions.push(this.toolbarService.duplicateView.subscribe(() => {
			this.onDuplicateViewToggle();
		}));
		this.subscriptions.push(this.toolbarService.recordingPlayingToggle.subscribe(data => {
			this.recordingPlayingToggle(data.control);
		}));
		this.subscriptions.push(this.toolbarService.runRecord.subscribe(data => {
			this.runSensorRecording(RunMode.RECORD, data.control);
		}));
		this.subscriptions.push(this.toolbarService.loadAndSave.subscribe(data => {
			this.runSensorRecording(RunMode.REPROCESS_AND_RECORD, data.control);
		}));
	}


	protected sendOutputsToSocket(outputs) {
		this.connection.setOutputs(environment.outputsType as OutputType, outputs);
	}


	protected preloadModelsResources() {
		Object.keys(humanModelsResources).forEach(posture => {
			if (resources[posture] && ((environment.production && (posture in (<any>window).modelsResources)) || !environment.production)) {
				resources[posture].get().then(gltf => {
					this[`${posture}3DModel`] = gltf.scene.getObjectByName(humanModelsResources[posture]);
				}).catch(e => {
					console.log(e, posture);
				});
			}
			var img = new Image();
			img.src = `assets/images/svg/3d-scene/Icon_${posture}.svg`;
		});
	}


	protected playBeepSound() {
		var audio = new Audio('assets/sounds/bleep.wav');
		audio.play();
	}


	protected extractOutputs(index) {
		return this.layers[index].filter(l => l.selected).map(l => Object.keys(l.dataDict || {}).filter(dict => {
			if (dict === 'postureData') {
				return this.getPostureForSensorTrackersView(index);
			} else {
				return true;
			}
		}).map(dict => l.dataDict[dict]));
	}

	protected initColorTables() {
		resetColorTables();
		this.subscriptions.push(this.busEventService.cleanColorTable.subscribe(timeout => {
			/**
			 * In Multi sensor page use the maximum MaximumGraveDuration value from all connected sensors.
			 * To cover all cases where we need to do Garbage Collection.
			 */
			let maxCleanColorTableTimeout = Math.max(...this.connectedSensorsSettings.map(settings => settings['sensorParameters']['Cfg.Tracker.PointTracker.TargetsGraves.MaximumGraveDuration']));

			if (!this.isMultipleSensors || timeout === maxCleanColorTableTimeout) {
				cleanColorTable();
			}
		}));
	}

	private inputRecordedDirectoryPathResultCallback(recordedDirPath, socketInfo) {
		let recordingPath = prepareRecordingPath(recordedDirPath, this.connectionSettings['sensorParameters']['FlowCfg.save_dir_base']),
			flowCallback = key => {
				if (flowDialog) {
					flowDialog.close();
				}
				switch (key) {
					case 'RUN_PLAYBACK':
						this.playRecording(RunMode.PLAYBACK, recordingPath);
						break;
					case 'REPROCESS_IQ_DATA':
						this.playRecordingInReprocessIqDataMode(recordingPath, socketInfo);
						break;
				}
			},
			flowDialog,
			showFlowDialog = this.connectionSettings['sensorParameters']['ProcessorCfg.ExternalGUI.enableVideoPlayback'];

		if (showFlowDialog) {
			flowDialog = this.modalService.showFlow({
				title: 'RECORDING_SELECTED',
				subTitle: 'WHAT_DO_YOU_WANT_TO_DO_NEXT',
				buttons: [{
					key: 'RUN_PLAYBACK'
				}, {
					key: 'REPROCESS_IQ_DATA'
				}],
				callback: key => {
					flowCallback(key);
				}
			});
		} else {
			flowCallback('REPROCESS_IQ_DATA');
		}
	}


	private playRecordingInReprocessIqDataMode(recordingPath, info) {
		let	showFlowDialog = info['showReprocessSettingsWindow'] !== false,
			savePathAndRunSensor = () => {
				this.playRecording(RunMode.REPROCESS_IQ_DATA, recordingPath);
			};

		if (showFlowDialog) {
			savePathAndRunSensor();
		} else {
			const selectSettingsForPlayRecordingDialog = this.modalService.showFlow({
				title: 'SELECT_SETTINGS_FOR_POST_PROCESSING',
				buttons: [
					{key: 'APPLY_ORIGINAL_SETTINGS'},
					{key: 'LOAD_SAVED_SETTINGS'},
					{key: 'APPLY_CURRENT_SETTINGS', disabled: !this.connectionSettings}
				],
				showDoNotShowButton: true,
				callback: (key2, data) => {
					let confirmSettingsOverload = () => {
						let message = 'NEW_SETTINGS_WILL_OVERLOAD_CURRENT_CONFIRM';
						return this.modalService.confirm(message, {}, 'Confirm', 'Continue').afterClosed().toPromise();
					};
					switch (key2) {
						case 'APPLY_ORIGINAL_SETTINGS':
							this.sensorsService.updateSensorRunMode(this.connection, RunMode.REPROCESS_IQ_DATA, {
								path: recordingPath
							});
							this.selectedModelIds = [];
							this.updateSensorSettings({
								'FlowCfg.save_dir': recordingPath,
								'FlowCfg.read_from_file': 1.0
							}, false).then(() => {
								this.connection.getRecMetadata().catch(console.error.bind(console));
								selectSettingsForPlayRecordingDialog.close();
							});
							break;
						case 'LOAD_SAVED_SETTINGS':
							confirmSettingsOverload().then(res => {
								if (res) {
									selectSettingsForPlayRecordingDialog.close();
									this.recordingFlowLoadSettingsSubscribe = this.busEventService.onSettingsFromFileLoaded.subscribe(() => {
										this.recordingFlowLoadSettingsSubscribe.unsubscribe();
										savePathAndRunSensor();
									});
									this.fileuploadSettingsInput.nativeElement.click();
								}
							});
							break;
						case 'APPLY_CURRENT_SETTINGS':
							selectSettingsForPlayRecordingDialog.close();
							savePathAndRunSensor();
							break;
						case 'doNotShowChange':
							this.settingsService.saveSocketInfo(this.connection.url, {
								showReprocessSettingsWindow: data
							});
							break;
					}
				}
			});
		}
	}

	private playRecording(runMode: RunMode, recordingPath) {
		this.selectedModelIds = [];
		this.sensorsService.runSensor(this.connection, runMode, {
			path: recordingPath
		});
	}

	private subscribeToTargetSelectionUpdates(): void {
		if (this.busEventServiceTargetSelectedSub) {
			this.busEventServiceTargetSelectedSub.unsubscribe();
		}
		if (this.busEventServiceClearTargetSelectSub) {
			this.busEventServiceClearTargetSelectSub.unsubscribe();
		}
		this.busEventServiceClearTargetSelectSub = this.busEventService.targetClearSelect.subscribe(() => {
			this.selectedModelIds = [];
		});
		this.busEventServiceTargetSelectedSub = this.busEventService.targetSelected.subscribe((id: string) => {
			if (this.selectedModelIds.includes(id)) {
				this.selectedModelIds.splice(this.selectedModelIds.indexOf(id), 1);
			} else if (this.selectedModelIds.length < 6) {
				this.selectedModelIds.push(id);
			}
			this.selectedModelIds = this.selectedModelIds.slice();
		});
	}

	private getSelectedRecordingsTypesString() {
		let texts: Array<string> = [],
			parameters: any = [
				{
					name: 'FlowCfg.save_to_file',
					text: 'RAW_DATA'
				},
				{
					name: 'FlowCfg.save_image_to_file',
					text: 'IMAGE_DATA'
				},
				{
					name: 'FlowCfg.save_pointCloud_to_file',
					text: 'POINT_CLOUD'
				},
				{
					name: 'ProcessorCfg.OutputData.save_to_file',
					text: 'OUTPUT_LOGS'
				},
				{
					name: 'FlowCfg.save_outputs_to_file',
					text: 'OUTPUTS'
				}
			].filter(parameter => this.connection.isSupportedParameter(parameter.name) && this.connection.lastParameters[parameter.name]);

		parameters.forEach((parameter, i, arr) => {
			let isFirst = i === 0,
				isLast = i === arr.length - 1;

			if (!isFirst) {
				if (isLast) {
					texts.push('and');
				} else {
					texts.push(',');
				}
			}
			texts.push(this.translate.instant(parameter.text).toLowerCase());
		});

		return texts.join(' ').replace(' ,', ',');
	}

	private onDisconnect() {
		this.modalService.close(MODAL_TYPE.ALL);
		delete this.connectionSettings;
		this.cleanOldData();
	}
}
