import {PointCloudStageBase} from '../../../../../base-classes/PointCloudStageBase';

/**
 * Class renders bottles inside cooler in 3D scene.
 */
export class CoolerInventoryStage extends PointCloudStageBase {

	private shelvesCount = 3;
	private bottlesObjects: Array<any> = [];
	private firstShelfZ = 159;
	private shelfStep = 41;

	constructor(div, arena, status, bottlesRowsColumnsLevel, bottleObject, public isTopView) {
		super(div, arena, status, false);
		let bottle = bottleObject.clone();
		bottle.position.set(0, 0, 0);
		bottle.receiveShadow = true;
		for (let z = 0; z < this.shelvesCount; z++) {
			for (let i = 0; i < bottlesRowsColumnsLevel[1]; i++) {
				for (let j = 0; j < bottlesRowsColumnsLevel[0]; j++) {
					let b = bottle.clone(),
						yPos = this.isTopView ? this.firstShelfZ + 43 : this.firstShelfZ - this.shelfStep * z,
						zDelta = this.isTopView ? [-90, 0, 85][z] : 0;
					b.position.set(20 * i - 27, yPos, 20 * j - (j ? 27 : 25) + zDelta);
					this.bottlesObjects.push(b);
				}
			}
		}
		this.arenaPlanes.forEach(p => this.mainGroup.remove(p));
		this.mainGroup.remove(this.sensor);
		if (this.isTopView) {
			this.controls.enabled = false;
		}
	}

	animation() {
		const now = Date.now();
		const elapsed = now - this.then;

		if (elapsed > this.fpsInterval) {
			switch (this.status) {
				case 'CONFIGURING':
					if (JSON.stringify(this.arena) !== JSON.stringify(this.oldArena)) {
						this.updateScene(this.arena, this.parameters);
						if (this.updateSceneOnConfiguring) {
							this.updateSceneOnConfiguring();
						}
					}
					break;
				case 'IMAGING':

					break;
				default:
					break;
			}
			this.renderer.render(this.scene, this.camera);
			this.then = now - (elapsed % this.fpsInterval);
		}
		this.animFrameReqId = requestAnimationFrame(this.animation.bind(this));
	}

	initCamera() {
		if (this.isTopView) {
			this.camera.position.set(6, 400, 0);
		} else {
			this.camera.position.set(-90, 215, 200);
		}
		this.camera.lookAt(0, 0, 0);
		if (this.isTopView) {
			this.camera.rotation.z = 0;
		}
	}

	update(data, arena, status) {
		this.oldStatus = this.status;
		this.status = status;
		this.oldArena = this.arena;
		this.arena = arena;
		if (status === 'IMAGING' && data) {
			this.updateData(data);
		}
		// Update on changing status - For instance from "CONFIGURING" to "IMAGING"
		if (this.oldStatus !== this.status) {
			this.updateStatus(this.status);
		}
	}

	updateData(data) {
		try {
			for (let z = 0; z < data.shelfIndex.length; z++) {
				for (let i = 0; i < data.bottlesOccupancy[z][0].length; i++) {
					for (let j = 0; j < data.bottlesOccupancy[z].length; j++) {
						let shelfIndex = data.shelfIndex[z],
							bottlesPerShelf = (data.bottlesOccupancy[z][0].length * data.bottlesOccupancy[z].length),
							index = shelfIndex * bottlesPerShelf + (i * data.bottlesOccupancy[z].length + j);

						if (data.bottlesOccupancy[z][j][i]) {
							this.mainGroup.add(this.bottlesObjects[index]);
						} else {
							this.mainGroup.remove(this.bottlesObjects[index]);
						}
					}
				}
			}
		} catch (e) {
			console.error('The data probably does not match the "bottlesRowsColumnsLevel" settings.');
			console.error(e);
		}
	}

}
