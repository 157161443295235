import {Component, Injector, OnInit} from '@angular/core';
import {BaseViewComponent} from '../../../../components/base-view/base-view.component';
import {environment} from '../../../../../environments/environment';
import {HomeAutomationServerSocket} from '../../../../services/system/ham-socket';

/**
 * Component is designed for showing categories with list of devices.
 * Used for Smart Home app.
 */
@Component({
	selector: 'app-smart-home',
	templateUrl: '../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../components/base-view/base-view.component.scss']
})
export class SmartHomeComponent extends BaseViewComponent implements OnInit {

	connection: HomeAutomationServerSocket;

	private smartHomeLayersGenerated = false;

	constructor(protected injector: Injector) {
		super(injector);
	}


	ngOnInit(): void {
		this.toolbarService.setIsSmartHome(true);
		this.updateSmartHomeSettings();
		this.subscriptions.push(this.busEventService.smartHomeParamsChanged.subscribe(info => {
			this.connectionSettings = Object.assign({}, this.connectionSettings, {socketInfo: info});
		}));
	}


	createConnectionToHomeAutomationServerSocket(url, connect = false) {
		let [ip, port] = url.split(':');
		if (!port) {
			port = environment.defaultSensorPort;
		}
		if (this.sensorsService.getHomeAutomationServerSocket()) {
			if (this.connection && this.connection.url !== url) {
				this.connection.disconnect();
				this.connection = this.sensorsService.createHomeAutomationServerSocket(ip, port);
				this.toolbarService.setConnection(this.connection);
			} else {
				this.connection = this.sensorsService.getHomeAutomationServerSocket();
				this.toolbarService.setConnection(this.connection);
			}
			if (connect) {
				this.connectToSmartHome();
			}
		} else {
			this.connection = this.sensorsService.createHomeAutomationServerSocket(ip, port);
			this.toolbarService.setConnection(this.connection);
			if (connect) {
				this.connectToSmartHome();
			}
		}
		if (!this.smartHomeLayersGenerated) {
			this.updateSmartHomeSettings();
		}
	}


	connectToSmartHome() {
		if (this.connection) {
			this.connection.disconnect();
		}
		this.sensorsService.connectHomeAutomationServerSocket(this.connection);
	}


	protected updateSmartHomeSettings() {
		this.storageService.getItem('spaceInfo').then(spaceInfo => {
			if (spaceInfo.homeAutomationServerSocketURL) {
				this.connectionSettings = Object.assign({}, this.connectionSettings, {socketInfo: spaceInfo});
				this.createConnectionToHomeAutomationServerSocket(spaceInfo.homeAutomationServerSocketURL, false);
				let parameters = {
					'sensorParameters': {
						'ProcessorCfg.ExternalGUI.Layers': environment.smartHomeLayers
					},
					'guiParameters': {
						selectedLayers: [[{
							'name': 'SmartHomeDashboard',
							'componentName': 'SmartHomeDashboardComponent',
							'selected': true,
							'dataDict': {},
							'staticDataDict': {}
						}], []]
					}
				};
				this.generateLayers(parameters);
				this.smartHomeLayersGenerated = true;
			}
		});
	}
}
