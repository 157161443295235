import {Component, OnInit} from '@angular/core';
import {BaseViewComponent} from '../base-view/base-view.component';
import {environment} from '../../../environments/environment';
import {BusEventService} from '../../services/bus-event.service';

/**
 * Component is used as main container for all pages for authorized user.
 * It is divided in two parts: left - represented as sidebar, right - for content.
 * Content are loaded depends on url in router-outlet.
 * Routes are written down in routing.module.
 */
@Component({
	selector: 'app-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

	arenaComponent: BaseViewComponent;
	isLoading = false;

	constructor(private busEventService: BusEventService) {
	}

	ngOnInit() {
		if (environment.isSmartBuildingsModuleEnabled) {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
				this.busEventService.pageIsLoaded.next(true);
			}, 3000);
		} else {
			this.busEventService.pageIsLoaded.next(true);
		}
	}

	onRouterOutletActivate(e) {
		if (e instanceof BaseViewComponent) {
			// fix ExpressionChangedAfterItHasBeenCheckedError with debugger
			setTimeout(() => {
				this.arenaComponent = e;
			});
		}
	}
}
