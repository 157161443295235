import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

/**
 * Component designed to confirm patient readiness for scanning.
 * Used for COVID app.
 */
@Component({
	selector: 'app-confirm-patient',
	templateUrl: './confirm-patient.component.html',
	styleUrls: ['./confirm-patient.component.scss']
})
export class ConfirmPatientComponent implements OnInit {

	q1 = false;
	q2 = false;
	q3 = false;

	constructor(private dialogRef: MatDialogRef<ConfirmPatientComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private translate: TranslateService) {
	}

	ngOnInit() {
		this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
			switch (e.key) {
				case 'Enter':
					if (this.data.showOk) {
						if (document.activeElement) {
							document.activeElement['blur']();
						}
						setTimeout(() => {
							this.resolve();
						});
					}
					break;
				case 'Escape':
					this.reject();
					break;
			}
		});
	}

	resolve(res = true) {
		this.dialogRef.close(res);
	}

	reject() {
		this.dialogRef.close(false);
	}

}
