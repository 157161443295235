import {Pipe, PipeTransform} from '@angular/core';
import {ConfigurableControlNumberInputConfig} from '../services/configuration.service';

@Pipe({
	name: 'numberInputConfig'
})
export class NumberInputConfigPipe implements PipeTransform {

	transform(value: Array<ConfigurableControlNumberInputConfig>, index: number): number | void {
		let ret;

		if (value instanceof Array && typeof value[index] === 'number') {
			ret = value[index];
		}
		return ret;
	}

}
