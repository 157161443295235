<div class="container" data-selector="flow-modal">
	<div class="logo">
		<img src="assets/images/VayyarNewLogo-01.png" alt="logo">
	</div>

	<h2 class="title">{{data.title | translate}}</h2>
	<h3 *ngIf="data.subTitle">{{data.subTitle | translate}}</h3>

	<div class="buttons">
		<button *ngFor="let button of data.buttons" [disabled]="button.disabled" (click)="buttonClick(button.key)">
			{{button.key | translate}}
		</button>
	</div>

	<div *ngIf="data.showDoNotShowButton" class="absolute-input">
		<div>
			<input type="checkbox" id="dontShowAgain" [(ngModel)]="doNotShow" (ngModelChange)="doNotShowChange()">
		</div>
		<div>
			<label for="dontShowAgain">{{'DONT_SHOW_ME_THIS_WINDOW_AGAIN' | translate}}</label>
		</div>
	</div>

	<div [ngClass]="{'absolute-button-mod': !data.success}" class="absolute-button">
		<button class="g-btn welocme-cancel" mat-raised-button (click)="cancel()">{{'CANCEL' | translate}}</button>
	</div>
</div>
