import {shuffleArray} from './IsoUtils';
import {BASE_COLORS_AS_OBJ} from './ImageUtils';

class NumberOfColorInstances<T> {
	private colors: Array<T> = [];
	private numberOfInstances: Array<number> = [];

	get(color: T) {
		let index = this.colors.indexOf(color);

		return this.numberOfInstances[index];
	}

	set(color: T, numberOfInstances: number) {
		let index = this.colors.indexOf(color);

		if (index === -1) {
			this.colors.push(color);
			this.numberOfInstances.push(numberOfInstances);
		} else {
			this.numberOfInstances[index] = numberOfInstances;
		}
	}

	getMinimumColor(): T {
		let index = this.numberOfInstances.indexOf(Math.min(...this.numberOfInstances));

		return this.colors[index];
	}
}

export function resetColorTables() {
	cn = new NumberOfColorInstances();
	ccl = shuffleArray(BASE_COLORS_AS_OBJ);
	Object.keys(ic).forEach(targetID => {
		delete ic[targetID];
	});
	removedTargetsIDs = [];
}

export function cleanColorTable() {
	/**
	 * Delete all rows that has 0 in their “Alive” column – It is guaranteed that they didn’t come back.
	 * Otherwise, they would have 1 in their “Alive” field.
	 * Update all the rows in the “Alive” field to 0. We assume everyone are dead until they come back.
	 */
	Object.keys(ic).forEach(targetID => {
		if (ic[targetID]['alive'] === 0) {
			delete ic[targetID];
		} else {
			ic[targetID]['alive'] = 0;
		}
	});
}

/**
 * On each frame, update the ic table with “Alive” state only if it’s 1.
 * That means you will make sure that if some target comes back, it will not be deleted in the next cycle of garbage collection.
 */
export function updateTargetColorAllocationDataStructures(targetCenterData: any[] = [], getTargetID) {
	let newTargetsIDs = [...new Set(targetCenterData.filter(target => {
			return !ic[getTargetID(target)];
		}).map(target => getTargetID(target)))],
		disappearedTargetsIDs = [...new Set(Object.keys(ic).filter(targetIDKey => {
			let targetID = +targetIDKey;
			return !targetCenterData.find(target => {
				return getTargetID(target) === targetID;
			}) && !removedTargetsIDs.includes(targetID);
		}).map(targetIDKey => +targetIDKey))],
		returnedTargetsIDs = [...new Set(targetCenterData.filter(target => {
			let targetID = getTargetID(target);
			return ic[targetID] && removedTargetsIDs.includes(targetID);
		}).map(target => getTargetID(target)))];

	removedTargetsIDs.push(...disappearedTargetsIDs);
	returnedTargetsIDs.forEach(targetID => {
		removedTargetsIDs.splice(removedTargetsIDs.indexOf(targetID), 1);
	});

	/**
	 * A new ID enters
	 *
	 * Check if the connected colors list is empty
	 * 	Yes
	 * 		Choose the minimum from the number of instances from cn (if there’re a few minimums choose one of them)
	 *		Increase the counter of the chosen color on the cn table
	 * No
	 * 		Choose (and remove) from the head of ccl list.
	 * 		Add a new row to the cn table
	 */
	newTargetsIDs.forEach(targetID => {
		let color;
		if (!ccl.length) {
			color = cn.getMinimumColor();
			cn.set(color, cn.get(color) + 1);
		} else {
			color = ccl.shift();
			cn.set(color, 1);
		}
		ic[targetID] = {
			color,
			alive: 1
		};
	});

	/**
	 * An ID disappears
	 *
	 * Decrease it’s color counter from the cn table by 1.
	 * If we reach 0, add it to the tail of the ccl list.
	 */
	disappearedTargetsIDs.forEach(targetID => {
		let color = ic[targetID]['color'];

		cn.set(color, cn.get(color) - 1);
		if (cn.get(color) === 0) {
			ccl.push(color);
		}
	});

	/**
	 * An ID comes back
	 *
	 * Increase it’s color counter in the cn table by 1.
	 * If before it I was in 0, remove it from the ccl list.
	 */
	returnedTargetsIDs.forEach(targetID => {
		let color = ic[targetID]['color'],
			currentNumberOfInstances = cn.get(color);

		cn.set(color, cn.get(color) + 1);
		if (currentNumberOfInstances === 0) {
			ccl.splice(ccl.indexOf(color), 1);
		}
		// Part of garbage collection process
		ic[targetID]['alive'] = 1;
	});
}

	// number of color instances in the arena
let cn: NumberOfColorInstances<string> = new NumberOfColorInstances(),
	// A connected list of colors keeping the order of their disappearance.
	// The color in the tail of the list disappeared the latest.
	ccl: Array<string> = [],
	// A color per ID. Alive states if that ID is in the arena or not.
	ic: {
		[targetID: number]: {
			color: string,
			alive: number
		}
	} = {},
	removedTargetsIDs: Array<number> = [];

export { ic };
