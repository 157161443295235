<div class="connection-satus-container" data-selector="connection-status-popup" *ngIf="data">
	<div class="connection-status-logo">
		<img src="assets/images/VayyarNewLogo-01.png" alt="logo">
	</div>

	<!-- Headings  -->
	<!-- <h2 class="connection-status-title">Welcome</h2> -->
	<h2 *ngIf="data.success" class="connection-status-title connection-status-title-done">{{'CONNECTION_ESTABLISHED' | translate}}<br> <span>to
		{{data.sensorSocket.ip}}</span></h2>
	<h2 *ngIf="!data.success" class="connection-status-title connection-status-title">{{'CONNECTION_TO' | translate}}
		{{data.sensorSocket.isConnectedToLocalhost() ? 'Local host' : data.sensorSocket.ip}} {{'FAILED' | translate}}</h2>

	<!-- Buttons -->
	<div class="connection-status-buttons">
		<!-- <div>
			<button class="connection-status-btn connection-status-btn-big">Connect automatically to Local Host</button>
			<button class="connection-status-btn connection-status-btn-big">Connect to <span>Remote Host</span></button>
		</div> -->

		<div *ngIf="data.success">
			<button *ngIf="data.showOpenSettings" data-selector="connection-status-popup_define-arena-settings" class="connection-status-btn connection-status-btn-small" (click)="openSettings()" [disabled]="!data.allowDefineNewArena">
				{{defineArenaSettingsText | translate}}
			</button>
			<button data-selector="connection-status-popup_load-saved-settings" class="connection-status-btn connection-status-btn-small" (click)="loadSettings()" [disabled]="!data.allowDefineNewArena">{{'LOAD_SAVED_ARENA_SETTINGS' | translate}}</button>
			<button data-selector="connection-status-popup_load-recording" class="connection-status-btn connection-status-btn-small" (click)="loadRecording()">{{'LOAD_RECORDING' | translate}}</button>
			<button data-selector="connection-status-popup_switch-host" class="connection-status-btn connection-status-btn-small IP-Computer-Name-Localhost" (click)="switchHost()">
				{{'CONNECT_TO' | translate}} {{data.sensorSocket.isConnectedToLocalhost() ? (remoteHost | translate) : (localHost | translate)}}
			</button>
		</div>
		<div *ngIf="!data.success">
            <button data-selector="connection-status-popup_try-again-to-connect_button" class="connection-status-btn connection-status-btn-small" (click)="tryAgain()">
				{{'TRY_AGAIN_TO_CONNECT' | translate}} {{data.sensorSocket.isConnectedToLocalhost() ? (locally | translate) : (remotely | translate)}}
			</button>
            <button data-selector="connection-status-popup_connect-to_button" class="connection-status-btn connection-status-btn-small" (click)="switchHost()">
				{{'CONNECT_TO' | translate}} {{data.sensorSocket.isConnectedToLocalhost() ? (remoteHostToLowerCase | translate) : localHostToLowerCase | translate}}
			</button>
        </div>

		<!-- <div *ngIf="data.success || !data.success" [ngClass]="{'absolute-button-mod': !data.success}" class="absolute-button">
			<button class="g-btn welocme-cancel" mat-raised-button (click)="cancel()">Close</button>
		</div> -->

		<div class="absolute-input">
			<div>
				<input data-selector="connection-status-popup_dont-show-again" type="checkbox" id="dontShowAgain" [(ngModel)]="doNotShowWelcomeFlow" (ngModelChange)="doNotShowWelcomeFlowChange()">
			</div>
			<div>
				<label for="dontShowAgain">{{'DONT_SHOW_ME_THIS_WINDOW_AGAIN' | translate}}</label>
			</div>
		</div>

	</div>
	<!-- Welcome background -->
	<div class="welcome-background">
		<img src="assets/images/svg/Welcome/welcome-background-1.png" class="first-img" alt="">
		<img src="assets/images/svg/Welcome/welcome-background-2.png"  class="second-img" alt="">
	</div>

	<div *ngIf="data.success || !data.success" [ngClass]="{'absolute-button-mod': !data.success}" class="absolute-button">
		<button class="g-btn welocme-cancel" mat-raised-button (click)="cancel()" data-selector="connection-status-popup_close-button">{{'CLOSE' | translate}}</button>
	</div>
</div>
