import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ConnectionsComponent} from './components/sensor-list/connections.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../modules/material/material.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SharedModule} from '../../modules/shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import {MODULE_PATH} from '../../app.module';
import {BackendConnectorEvkComponent} from '../../components/shared/backend-connector/backend-connector-evk/backend-connector-evk.component';
import {environment} from '../../../environments/environment';
import {SingleSensorComponent} from './components/base-view/single-sensor/single-sensor.component';
import {ToolbarEvkComponent} from '../../components/shared/toolbars/toolbar-evk/toolbar-evk.component';
import {PageGuard} from '../../guards/page.guard';
import {PointCloud2dComponent} from './components/layers/point-cloud-2d/point-cloud-2d.component';
import {PointCloud3dComponent} from './components/layers/point-cloud-3d/point-cloud-3d.component';
import {SensorTracker2DRawViewComponent} from './components/layers/sensor-tracker-2D-raw-view/sensor-tracker-2D-raw-view.component';
import {HeatmapComponent} from './components/layers/sensor-tracker-2D-raw-view/heatmap/heatmap.component';
import {TargetTrackerComponent} from './components/layers/sensor-tracker-2D-raw-view/target-tracker/target-tracker.component';
import {BreathingComponent} from './components/layers/graphs/breathing/breathing.component';
import {ActivityComponent} from './components/layers/graphs/activity/activity.component';
import {NumberOfPeopleComponent} from './components/layers/number-of-people/number-of-people.component';
import {AppInfoComponent} from './components/layers/app-info/app-info.component';
import {BoxesPointCloudComponent} from './components/layers/boxes-point-cloud/boxes-point-cloud.component';
import {CarPointCloudComponent} from './components/layers/car-point-cloud/car-point-cloud.component';
import {InCarOccupancyComponent} from './components/layers/in-car-occupancy/in-car-occupancy.component';
import {Targets3DComponent} from './components/layers/targets-3d/targets-3d.component';
import {InCarOccupancyBaseComponent} from './components/layers/base-classes/in-car-occupancy-base/in-car-occupancy-base.component';
import {TabbedFormControlComponent} from './components/tabbed-form-control/tabbed-form-control.component';
import {MultiSensorsComponent} from './components/base-view/multi-sensors/multi-sensors.component';
import {IncarMultiSensorsComponent} from './components/base-view/incar-multi-sensors/incar-multi-sensors.component';
import {LayersContainerComponent} from './components/layers/layers-container.component';
import {RecordingPlayerComponent} from '../../components/recording-player/recording-player.component';
import {NouisliderModule} from 'ng2-nouislider';
import { SrrCloudPointComponent } from './components/layers/srr-cloud-point/srr-cloud-point.component';
import {FlowProcessorPageGuard} from './guards/flow-processor-page.guard';
import { PointCloudRaw2dComponentWand } from 'src/app/applications/flow-processor/components/layers/point-cloud-raw2d-wand/point-cloud-raw2d-component-wand.component';
import { WebCameraVideoComponent } from './components/layers/web-camera-video/web-camera-video.component';
import {
	PointCloudRaw2dComponent
} from 'src/app/applications/flow-processor/components/layers/point-cloud-raw2d_old/point-cloud-raw2d.component';
import { PoseLandmark2dComponent } from './components/layers/pose-landmark2d/pose-landmark2d.component';
import { TargetsInfoComponent } from './components/layers/targets-info/targets-info.component';
import {MatTableModule} from '@angular/material/table';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../../../assets/i18n/${lang}.json`));
	}
}

export let InjectorInstance: Injector;

const routes: Routes = [
	{
		path: 'sensor',
		component: SingleSensorComponent,
		canActivate: [PageGuard],
		data: {
			pageName: 'Sensor',
			slug: 'sensor',
			toolbar: ToolbarEvkComponent,
			sensorConnector: BackendConnectorEvkComponent
		}
	}, {
		path: `sensor/:url`,
		component: SingleSensorComponent,
		canActivate: [PageGuard],
		data: {
			pageName: 'Sensor',
			slug: 'sensor',
			toolbar: ToolbarEvkComponent,
			sensorConnector: BackendConnectorEvkComponent
		}
	}, {
		path: 'connections',
		canActivate: [PageGuard],
		component: ConnectionsComponent
	}, {
		path: '**',
		pathMatch: 'full',
		redirectTo: 'sensor'
	}
];

@NgModule({
	declarations: [
		MultiSensorsComponent,
		IncarMultiSensorsComponent,
		ConnectionsComponent,
		BackendConnectorEvkComponent,
		LayersContainerComponent,
		SingleSensorComponent,
		PointCloud2dComponent,
		PointCloud3dComponent,
		SensorTracker2DRawViewComponent,
		HeatmapComponent,
		TargetTrackerComponent,
		BreathingComponent,
		ActivityComponent,
		NumberOfPeopleComponent,
		AppInfoComponent,
		BoxesPointCloudComponent,
		CarPointCloudComponent,
		InCarOccupancyComponent,
		Targets3DComponent,
		InCarOccupancyBaseComponent,
		TabbedFormControlComponent,
		RecordingPlayerComponent,
		SrrCloudPointComponent,
		PointCloudRaw2dComponentWand,
		PointCloudRaw2dComponent,
		WebCameraVideoComponent,
		PoseLandmark2dComponent,
		TargetsInfoComponent,
	],
	entryComponents: [
		BackendConnectorEvkComponent,
		TabbedFormControlComponent
	],
	providers: [
		{
			provide: MODULE_PATH,
			useValue: environment.FLOW_PROCESSOR_MODULE_PATH
		},
		FlowProcessorPageGuard
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		MaterialModule,
		PerfectScrollbarModule,
		SharedModule,
		NouisliderModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		MatTableModule
	]
})
export class FlowProcessorModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}
