import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {colorscale, makeGetColor, TARGET_CENTER_COLOR} from '../../../../../utils/ImageUtils';
import {ic, updateTargetColorAllocationDataStructures} from '../../../../../utils/ColorTablesUtils';

@Component({
	selector: 'app-targets-info',
	templateUrl: './targets-info.component.html',
	styleUrls: ['./targets-info.component.scss']
})
export class TargetsInfoComponent implements OnInit, OnChanges {

	@Input() data: any = [];
	dataSource: any[] = [];
	displayedColumns: string[] = ['position', 'range', 'speed', 'angle', 'ageClassification'];

	private getColor = (function (colorGetter) {
		return (...a) => {
			// @ts-ignore
			let color = colorGetter(...a);
			return typeof color === 'string' ? color : `rgb(${color.r}, ${color.g}, ${color.b})`;
		};
	}(makeGetColor(TARGET_CENTER_COLOR, colorscale, ic)));

	constructor() {
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('data' in changes) {
			this.updateTargetColorAllocationDataStructures(this.data.targetsIdData);
			let dataSource: any[] = [];
			(this.data.range || []).forEach((range, i) => {
				dataSource.push({
					position: i + 1,
					range: (+range / 100).toFixed(2),
					speed: this.data.speed[i],
					angle: this.data.angle[i],
					ageClassification: this.data.ageClassification[i],
					color: this.getColor(false, this.data.targetsIdData[i])
				});
			});
			this.dataSource = dataSource;
		}
	}

	private updateTargetColorAllocationDataStructures(data: any[] = []) {
		updateTargetColorAllocationDataStructures(data, target => target);
	}
}
