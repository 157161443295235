import {Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

/**
 * Component renders slide toggle.
 */
@Component({
	selector: 'app-slide-toggle',
	templateUrl: './slide-toggle.component.html',
	styleUrls: ['./slide-toggle.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SlideToggleComponent),
			multi: true,
		}
	]
})
export class SlideToggleComponent implements OnInit, ControlValueAccessor {

	@Input() checked = false;
	// TODO implement ControlValueAccessor interface
	@Input() set readonly(readonly) {
		this._readonly = readonly;
		this.disabledAttr = this._readonly ? '' : null;
	};
	get readonly() {
		return this._readonly;
	}
	@Input() unCheckedValue;
	@Input() checkedValue;
	@Output() change: EventEmitter<MatSlideToggleChange> = new EventEmitter();
	@Output() canChange: EventEmitter<MatSlideToggleChange> = new EventEmitter();
	@ViewChild('slide', { static: true }) slide: MatSlideToggle;

	@HostBinding('attr.value')
	value;

	@HostBinding('attr.disabled')
	disabledAttr: any = null;

	private lastEvent;
	private _readonly;

	private propagateChange = (_: any) => {
	};

	constructor() {
	}

	ngOnInit() {
		this.writeValue(this.checked);
	}

	onChange() {
		this.propagateChange(this.slide.checked);
		this.value = this.slide.checked;
		this.change.emit({
			checked: this.slide.checked,
			source: this.slide
		});
	}

	onClick(e: MouseEvent) {
		if(this.slide.disabled) {
			return;
		}
		e.preventDefault();

		this.lastEvent = {
			checked: !this.slide.checked,
			source: this.slide,
			prevented: false,
			preventDefault: function () {
				this.prevented = true;
			}
		};

		this.canChange.emit(this.lastEvent);

		if (!this.lastEvent.prevented) {
			this.slide.toggle();
			this.onChange();
		} else if (this.lastEvent.promise) {
			this.lastEvent.promise.then(res => {
				if (res) {
					this.slide.toggle();
					this.onChange();
				}
			});
		}
	}

	public registerOnTouched() {
	}

	public registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	public writeValue(checked: boolean) {
		this.value = Boolean(checked);
		this.slide.checked = this.value;
	}

	setDisabledState(isDisabled: boolean): void {
		this.slide.setDisabledState(isDisabled);
	}
}
