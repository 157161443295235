import {Injectable, Injector} from '@angular/core';
import {ModalMessageComponent} from '../components/shared/modal-message/modal-message.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConnectionStatusComponent} from '../components/shared/connection-status/connection-status.component';
import {SensorSocket} from './system/sensor-socket';
import {AddNewConnectionsComponent} from '../components/shared/add-new-sensor/add-new-connections.component';
import {ConnectToRemoteHostsComponent} from '../components/shared/connect-to-remote-hosts/connect-to-remote-hosts.component';
import {UserInputComponent} from '../components/shared/user-input/user-input.component';
import {FlowModalComponent} from '../components/shared/flow-modal/flow-modal.component';
import {LoginModalComponent} from '../components/login/login.component';
import {SensorsService} from './system/sensors.service';
import {StorageService} from './storage.service';
import {SettingsService} from './settings.service';
import {ConfirmPatientComponent} from '../components/shared/confirm-patient/confirm-patient.component';
import {ModalErrorComponent} from '../components/shared/modal-message/ModalErrorComponent';
import {SettingsComponent} from '../components/settings/settings.component';
import {AddGeoFenceComponent} from '../applications/smart-building/components/add-location/add-geo-fence.component';
import {ModalPromptComponent} from '../components/shared/modal-prompt/modal-prompt.component';

export enum MODAL_TYPE {
	CONFIRM,
	INPUT,
	MESSAGE,
	ERROR,
	ALL
}

export enum MODAL_USER_INPUT_TYPE {
	ANY,
	PATH
}

/**
 * Service for calling modal windows with predefined configs.
 */
@Injectable({
	providedIn: 'root'
})
export class ModalService {

	lastErrorDialog: MatDialogRef<ModalMessageComponent>;
	lastMessageDialog: MatDialogRef<ModalMessageComponent>;

	readonly openedDialogs: Array<{
		dialogRef: MatDialogRef<any>,
		modalType: MODAL_TYPE,
		[key: string]: any
	}> = [];

	constructor(private dialog: MatDialog,
				private storageService: StorageService,
				private inject: Injector) {

	}

	confirm(message, translateData = {}, title = 'Confirm', okText = 'OK', cancelText = 'Cancel', cssClass?) {
		let dialogRef = this.dialog.open(ModalMessageComponent, {
			autoFocus: false,
			width: '480px',
			disableClose: true,
			minHeight: 200,
			panelClass: cssClass || 'modal-default',
			data: {
				title: title,
				showCancel: true,
				showOk: true,
				okButtonEnabled: true,
				message: message,
				cancelText: cancelText,
				okText: okText,
				translateData: translateData
			}
		});

		this.registerDialog(dialogRef, MODAL_TYPE.CONFIRM);

		return dialogRef;
	}

	prompt(message, translateData = {}, title = 'Confirm', okText = 'OK', cancelText = 'Cancel', cssClass?) {
		let dialogRef = this.dialog.open(ModalPromptComponent, {
			autoFocus: false,
			width: '480px',
			disableClose: true,
			minHeight: 200,
			panelClass: cssClass || 'modal-default',
			data: {
				title: title,
				showCancel: true,
				showOk: true,
				okButtonEnabled: true,
				message: message,
				cancelText: cancelText,
				okText: okText,
				translateData: translateData
			}
		});

		this.registerDialog(dialogRef, MODAL_TYPE.CONFIRM);

		return dialogRef;
	}

	confirmPatientForm(message, translateData = {}, title = 'Confirm', okText = 'OK', cancelText = 'Cancel', cssClass?) {
		let dialogRef = this.dialog.open(ConfirmPatientComponent, {
			autoFocus: false,
			width: '480px',
			disableClose: true,
			minHeight: 200,
			panelClass: cssClass,
			data: {
				title: title,
				showCancel: true,
				showOk: true,
				okButtonEnabled: true,
				message,
				cancelText,
				okText,
				translateData
			}
		});

		this.registerDialog(dialogRef, MODAL_TYPE.CONFIRM);

		return dialogRef;
	}

	userInput(title, data = {}, okText = 'Continue', cancelText = 'Cancel') {
		let dialogRef = this.dialog.open(UserInputComponent, {
			autoFocus: true,
			width: '680px',
			disableClose: true,
			minHeight: 200,
			maxHeight: '90%',
			panelClass: ['modal-default', 'user-input'],
			data: Object.assign({}, {
				title: title,
				cancelText: cancelText,
				okText: okText,
				type: MODAL_USER_INPUT_TYPE.PATH
			}, data)
		});

		this.registerDialog(dialogRef, MODAL_TYPE.INPUT);

		return dialogRef;
	}

	floorPlanEditGeoFence(title, data = {}, okText = 'Continue', cancelText = 'Cancel') {
		let dialogRef = this.dialog.open(AddGeoFenceComponent, {
			autoFocus: true,
			width: '480px',
			disableClose: true,
			minHeight: 200,
			panelClass: 'modal-default',
			data: Object.assign({}, {
				title: title,
				cancelText: cancelText,
				okText: okText
			}, data)
		});

		this.registerDialog(dialogRef, MODAL_TYPE.INPUT);

		return dialogRef;
	}

	showMessage(message, title = 'Information', okText = 'OK', cancelText?, translateData = {}, additionalButtons = []) {
		if (this.lastMessageDialog) {
			this.lastMessageDialog.close();
		}
		this.lastMessageDialog = this.dialog.open(ModalMessageComponent, {
			autoFocus: false,
			width: '480px',
			disableClose: true,
			panelClass: 'modal-default',
			minHeight: 200,
			data: {
				title: title,
				showOk: true,
				okButtonEnabled: true,
				showCancel: cancelText,
				cancelText,
				okText,
				message,
				translateData,
				additionalButtons
			}
		});

		this.registerDialog(this.lastMessageDialog, MODAL_TYPE.MESSAGE);

		return this.lastMessageDialog;
	}

	onServerSideMessage(e) {
		let existedDialog = this.openedDialogs.find(d => d.id === e.id);
		if (e.active) {
			if (!existedDialog) {
				let btnShowOk = false;
				if (e.dismissible) {
					btnShowOk = true;
				}
				let dialogRef = this.dialog.open(ModalMessageComponent, {
					autoFocus: false,
					width: '480px',
					disableClose: true,
					panelClass: 'modal-default',
					minHeight: 169,
					data: {
						title: 'Information',
						showOk: btnShowOk,
						showCancel: false,
						message: e.text
					}
				});

				this.registerDialog(dialogRef, MODAL_TYPE.MESSAGE, {id: e.id});
			} else {
				Object.assign(existedDialog.dialogRef.componentInstance['data'], {
					message: e.text
				});
				existedDialog.dialogRef.componentInstance.refresh();
			}
		} else if (existedDialog) {
			existedDialog.dialogRef.close();
		}
	}

	showError(message, showOk = true, translateData = {}, title = 'Error', okText = 'OK') {
		this.lastErrorDialog = this.dialog.open(ModalErrorComponent, {
			autoFocus: false,
			width: '480px',
			disableClose: true,
			panelClass: 'modal-default',
			minHeight: 200,
			data: {
				title,
				showOk,
				message,
				okText,
				translateData
			}
		});

		this.registerDialog(this.lastErrorDialog, MODAL_TYPE.ERROR);

		return this.lastErrorDialog;
	}

	showLoadingPopup(title, config?: Object, showCancel?: boolean): MatDialogRef<ModalMessageComponent>;
	showLoadingPopup(title, additionalButtons?: Array<any>, showCancel?: boolean): MatDialogRef<ModalMessageComponent>;
	showLoadingPopup(title, configOrAdditionalButtons, showCancel = true) {
		let additionalButtons: Array<any> = [];
		if (configOrAdditionalButtons instanceof Array) {
			additionalButtons = configOrAdditionalButtons;
		}
		let dialogRef = this.dialog.open(ModalMessageComponent, {
			autoFocus: false,
			width: '480px',
			disableClose: true,
			panelClass: 'modal-default',
			minHeight: 200,
			data: {
				message: configOrAdditionalButtons.message,
				title: title,
				showCancel,
				showOk: false,
				showLoading: true,
				okButtonEnabled: true,
				additionalButtons,
				config: configOrAdditionalButtons
			}
		});

		this.registerDialog(dialogRef, MODAL_TYPE.MESSAGE);

		return dialogRef;
	}

	showConnectionResult(sensorSocket: SensorSocket, config, callback): MatDialogRef<ConnectionStatusComponent> {
		let dialogRef = this.dialog.open(ConnectionStatusComponent, {
			autoFocus: false,
			disableClose: true,
			minHeight: 200,
			data: {
				sensorSocket,
				callback,
				...config
			}
		});

		this.registerDialog(dialogRef, MODAL_TYPE.MESSAGE);

		return dialogRef;
	}

	showRemoteHostList() {
		let dialogRef = this.dialog.open(ConnectToRemoteHostsComponent, {
			autoFocus: false,
			disableClose: true,
			minHeight: 200,
			minWidth: 950,
			width: '950px',
			height: '630px',
			data: {}
		});

		this.registerDialog(dialogRef, MODAL_TYPE.INPUT);

		return dialogRef;
	}

	showAddConnections() {
		let dialogRef = this.dialog.open(AddNewConnectionsComponent, {
			autoFocus: false,
			disableClose: true,
			minHeight: 200,
			minWidth: 720,
			height: '630px',
			data: {}
		});

		this.registerDialog(dialogRef, MODAL_TYPE.INPUT);

		return dialogRef;
	}

	showFlow(data) {
		let dialogRef = this.dialog.open(FlowModalComponent, {
			autoFocus: false,
			disableClose: true,
			data: data
		});

		this.registerDialog(dialogRef, MODAL_TYPE.INPUT);

		return dialogRef;
	}

	showSettings(data) {
		let dialogRef = this.dialog.open(SettingsComponent, {
			autoFocus: false,
			width: '1152px',
			disableClose: true,
			panelClass: ['modal-popup', 'settings-popup'],
			minHeight: 200,
			data: data
		});
		return dialogRef;
	}

	close(...modalTypes: [...MODAL_TYPE[]]) {
		modalTypes.forEach(modalType => {
			switch (modalType) {
				case MODAL_TYPE.ALL:
					this.dialog.closeAll();
					break;
				case MODAL_TYPE.CONFIRM:
				case MODAL_TYPE.ERROR:
				case MODAL_TYPE.INPUT:
				case MODAL_TYPE.MESSAGE:
					this.openedDialogs.forEach(dialog => {
						if (dialog.modalType === modalType) {
							dialog.dialogRef.close();
						}
					});
					break;
			}
		});
	}

	showLogin() {
		let dialogRef = this.dialog.open(LoginModalComponent, {
			autoFocus: false,
			disableClose: true,
			width: '480px',
			panelClass: 'modal-default',
			data: {}
		});

		this.registerDialog(dialogRef, MODAL_TYPE.INPUT);

		return dialogRef;
	}

	async checkAllSensorAreConnected() {
		let settingsService = this.inject.get(SettingsService),
			sensorsService = this.inject.get(SensorsService),
			isReady = true,
			disconnectedSensors: Array<any> = [],
			socketPool = await this.storageService.getItem('socketPool');

		for (let i = 0; i < socketPool.length; i++) {
			let url = socketPool[i];
			let socket = sensorsService.getSensorSocket(url);
			if (!(socket && socket.isConnected())) {
				isReady = false;
				disconnectedSensors.push((await settingsService.getSocketInfo(url)).name || url);
			}
		}

		if (!isReady) {
			this.showError('NOT_READY_ERROR', true, {
				sensors: disconnectedSensors.join(', ')
			});
		}

		return isReady;
	}

	private registerDialog(dialogRef: MatDialogRef<any>, modalType: MODAL_TYPE, additionalData = {}) {
		this.openedDialogs.push(Object.assign({
			dialogRef,
			modalType
		}, additionalData));
		dialogRef.afterClosed().toPromise().then(() => {
			let dialog: any = this.openedDialogs.find(d => d.dialogRef === dialogRef);
			this.openedDialogs.splice(this.openedDialogs.indexOf(dialog), 1);
		});
	}
}
