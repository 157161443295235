import {Component, Input, OnInit} from '@angular/core';
import {getColor, getSensorRotation2D, getSensorTranslation2D} from '../../../utils/utils';
import {BusEventService} from '../../../services/bus-event.service';
import {SelectingTargetDataGraphBehavior} from '../../../consts';
import ATSrc from '!raw-loader!../../../../assets/images/car/AT.svg';
import IFSrc from '!raw-loader!../../../../assets/images/car/IF.svg';
import PTSrc from '!raw-loader!../../../../assets/images/car/PT.svg';
import CSrc from '!raw-loader!../../../../assets/images/car/C.svg';
import OOPSrc from '!raw-loader!../../../../assets/images/car/OOP.svg';
import SBRSrc from '!raw-loader!../../../../assets/images/car/seatbelt-reminder.svg';
import SBRRedSrc from '!raw-loader!../../../../assets/images/car/sbr_red.svg';
import SBRGreenSrc from '!raw-loader!../../../../assets/images/car/sbr_green.svg';
import adultBlankSrc from '!raw-loader!../../../../assets/images/car/adult-blank.svg';
import adultSeat1Src from '!raw-loader!../../../../assets/images/car/adult-seat-1.svg';
import adultSeat3Src from '!raw-loader!../../../../assets/images/car/adult-seat-3.svg';
import PersonSrc from '!raw-loader!../../../../assets/images/car/Person.svg';
import Occupied1Src from '!raw-loader!../../../../assets/images/car/occupied1.svg';
import Occupied2Src from '!raw-loader!../../../../assets/images/car/occupied2.svg';
import EmptyMonitored from '!raw-loader!../../../../assets/images/car/empty_monitored.svg';
import EmptyNonMonitored from '!raw-loader!../../../../assets/images/car/empty_nonmonitored.svg';
import EmptyDeploy from '!raw-loader!../../../../assets/images/car/empty_deploy.svg';
import EmptyLrd from '!raw-loader!../../../../assets/images/car/empty_lrd.svg';
import EmptyNo from '!raw-loader!../../../../assets/images/car/empty_no.svg';
import ManMonitored from '!raw-loader!../../../../assets/images/car/man_monitored.svg';
import ManDeploy from '!raw-loader!../../../../assets/images/car/man_deploy.svg';
import ManLrd from '!raw-loader!../../../../assets/images/car/man_lrd.svg';
import ManNo from '!raw-loader!../../../../assets/images/car/man_no.svg';
import BoyMonitored from '!raw-loader!../../../../assets/images/car/boy_monitored.svg';
import BoyDeploy from '!raw-loader!../../../../assets/images/car/boy_deploy.svg';
import BoyLrd from '!raw-loader!../../../../assets/images/car/boy_lrd.svg';
import BoyNo from '!raw-loader!../../../../assets/images/car/boy_no.svg';
import GirlMonitored from '!raw-loader!../../../../assets/images/car/girl_monitored.svg';
import GirlDeploy from '!raw-loader!../../../../assets/images/car/girl_deploy.svg';
import GirlLrd from '!raw-loader!../../../../assets/images/car/girl_lrd.svg';
import GirlNo from '!raw-loader!../../../../assets/images/car/girl_no.svg';
import BabyMonitored from '!raw-loader!../../../../assets/images/car/baby_monitored.svg';
import BabyDeploy from '!raw-loader!../../../../assets/images/car/baby_deploy.svg';
import BabyLrd from '!raw-loader!../../../../assets/images/car/baby_lrd.svg';
import BabyNo from '!raw-loader!../../../../assets/images/car/baby_no.svg';

@Component({
	selector: 'app-svg-car',
	templateUrl: './svg-car.component.html',
	styleUrls: ['./svg-car.component.scss']
})
export class SvgCarComponent implements OnInit {

	@Input() rotate = 0;
	@Input() isTargetsSelectable = false;
	@Input() isTargetsVisible = true;
	@Input() isTrackerEnabled = false;
	@Input() isSensorVisible = true;
	@Input() isMultipleSensors;
	@Input() sensorColor;
	@Input() parameters: any = {};
	@Input() selectedModelIds;
	@Input() connectedSensorsSettings = [];
	@Input() localData: any = {};

	personSrcType = [
		adultBlankSrc,		// 0
		ATSrc,				// 1
		IFSrc,				// 2
		PTSrc,				// 3
		CSrc,				// 4
		OOPSrc,				// 5
		SBRSrc,				// 6
		SBRRedSrc,			// 7
		SBRGreenSrc,		// 8
		Occupied1Src,		// 9
		Occupied2Src,		// 10
		PersonSrc,			// 11
		EmptyMonitored,		// 12
		EmptyNonMonitored,	// 13
		EmptyDeploy,		// 14
		EmptyLrd,			// 15
		EmptyNo,			// 16
		ManMonitored,		// 17
		ManDeploy,			// 18
		ManLrd,				// 19
		ManNo,				// 20
		BoyMonitored,		// 21
		BoyDeploy,			// 22
		BoyLrd,				// 23
		BoyNo,				// 24
		GirlMonitored,		// 25
		GirlDeploy,			// 26
		GirlLrd,			// 27
		GirlNo,				// 28
		BabyMonitored,		// 29
		BabyDeploy,			// 30
		BabyLrd,			// 31
		BabyNo,				// 32
	];

	selectingTargetDataGraphBehavior = SelectingTargetDataGraphBehavior;

	SENSOR_H = 10;
	SENSOR_W = 10;

	chairType = {1: adultSeat1Src, 3: adultSeat3Src};

	constructor(private busEventService: BusEventService) {
	}

	ngOnInit(): void {
	}

	trackByDataId(index, item) {
		return index;
	}

	getImageX(index) {
		let percent = ((this.localData.seatIndications[index][1] - this.localData.cabinArena[0]) / (this.localData.cabinArena[1] - this.localData.cabinArena[0])) * 100;
		return 110 * percent / 100;
	}

	getTargetPosition(index) {
		let percentX = 0,
			percentY = 0,
			arena = this.localData.cabinArena || this.parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'];

		if (arena) {
			let target = this.localData.data[index];
			percentX = ((target[0] - arena[0]) / (arena[1] - arena[0])) * 100;
			percentY = ((target[1] - arena[2]) / (arena[3] - arena[2])) * 100;
		}

		return [110 * percentX / 100, 190 * percentY / 100];
	}

	getImageY(index) {
		let percent = ((this.localData.seatIndications[index][2] - this.localData.cabinArena[2]) / (this.localData.cabinArena[3] - this.localData.cabinArena[2])) * 100;
		return 190 * percent / 100;
	}

	getImageWidth(index) {
		let percent = (this.localData.seatIndications[index][4] / (this.localData.cabinArena[1] - this.localData.cabinArena[0])) * 100;
		return 110 * percent / 100;
	}

	getImageHeight(index) {
		let percent = (this.localData.seatIndications[index][5] / (this.localData.cabinArena[3] - this.localData.cabinArena[2])) * 100;
		return 190 * percent / 135;
	}

	getSeatImageHref(svg) {
		return `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(svg)))}`;
	}

	getPersonImageHref(index) {
		let svg = this.getPersonType(index),
			def = ((this.parameters && this.parameters['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.Automatically) || this.selectedModelIds.includes(this.localData.targetsID ? this.localData.targetsID[index] : index + 1 + '')),
			href = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent((svg || adultBlankSrc).replace('fill:#00bed4;', def ? 'fill:#f4f4f4;' : `fill:${getColor(this.localData.targetsID ? this.localData.targetsID[index] : index + 1)};`))))}`;

		return href;
	}

	getTranslate(index) {
		let iconHeight = this.getImageHeight(index),
			iconWidth = this.getImageWidth(index);

		return 'translate(-' + iconWidth / 2 + ', -' + iconHeight / 2 + ')';
	}

	onMouseDown(i) {
		if (this.isTargetsSelectable) {
			this.busEventService.targetSelected.emit(this.localData.targetsID ? this.localData.targetsID[i] : i + 1 + '');
		}
	}

	getSensorRotation(i?) {
		let rotation,
			position = this.getSensorPosition(i);

		if (this.isMultipleSensors) {
			rotation = getSensorRotation2D(this.connectedSensorsSettings[i], position[0] + this.SENSOR_W / 2, position[1] + this.SENSOR_H / 2, 'xy');
		} else {
			rotation = getSensorRotation2D(this.parameters, position[0] + this.SENSOR_W / 2, position[1] + this.SENSOR_H / 2, 'xy');
		}

		return rotation;
	}

	getSensorPosition(i?) {
		let percentX = 50,
			percentY = 50,
			arena = this.localData.cabinArena || this.parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'];

		if (arena) {
			let sensorPosition;
			if (this.isMultipleSensors) {
				sensorPosition = getSensorTranslation2D(this.connectedSensorsSettings[i]);
			} else {
				sensorPosition = getSensorTranslation2D(this.parameters);
			}
			percentX = ((sensorPosition[0] - arena[0]) / (arena[1] - arena[0])) * 100;
			percentY = ((sensorPosition[1] - arena[2]) / (arena[3] - arena[2])) * 100;
		}

		return [110 * percentX / 100, 190 * percentY / 100];
	}

	private getPersonType(index) {
		return this.personSrcType[this.localData.seatIndications[index][6]];
	}
}
