import {Component, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SensorSocket} from '../../../../../services/system/sensor-socket';
import {VitalsComponent} from '../graphs/vitals/vitals.component';
import {DataService} from '../../../../../services/system/data.service';
import {Subscription} from 'rxjs';

/**
 * Component renders graphs with measurements of heart rate, heart rate variability and respiration rate.
 * Used for COVID app.
 */
@Component({
	selector: 'app-patient-data-graph',
	templateUrl: './patient-data-graph.component.html',
	styleUrls: ['./patient-data-graph.component.scss']
})
export class PatientDataGraphComponent implements OnInit, OnChanges, OnDestroy {

	@Input() data;
	@Input() measLabel;
	@Input() parameters;
	@Input() selectedModelIds;
	@Input() status;
	@Input() sensorSocket: SensorSocket;
	@ViewChildren(VitalsComponent) complexComponents: QueryList<VitalsComponent>;

	heartRateData: any = {
		data: []
	};
	respirationData: any = {
		data: []
	};
	bpm = 0;
	hrv = 0;
	rpm = 0;
	analysisClass = '';

	private subscriptions: Array<Subscription> = [];

	constructor(private dataService: DataService) {
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	async ngOnChanges(c) {
		if ('sensorSocket' in c) {
			this.resetGraphs();
			this.loadStoredData();
			this.bpm = 0;
			this.hrv = 0;
			this.rpm = 0;
		}
		if ('data' in c) {
			this.bpm = isNaN(+this.data.bpm) ? 0 : this.data.bpm;
			this.hrv = isNaN(+this.data.hrv) ? 0 : this.data.hrv;
			this.rpm = isNaN(+this.data.rpm) ? 0 : this.data.rpm;
			if (this.data.heartRateData && this.data.heartRateData.length) {
				this.heartRateData = {
					data: this.data.heartRateData.map(row => {
						return [1, row[1], 0, row[0]];
					})
				};
			}
			if (this.data.respirationData && this.data.respirationData.length) {
				this.respirationData = {
					data: this.data.respirationData.map(row => {
						return [1, row[1], 0, row[0]];
					})
				};
			}
			this.analysisClass = this.data.color;
		}
	}

	private resetGraphs() {
		if (this.complexComponents) {
			this.complexComponents.forEach(item => {
				item.resetGraphs();
			});
		}
	}

	private loadStoredData() {
		let heartRateData = this.dataService.savedOutputs[this.sensorSocket.url] && this.dataService.savedOutputs[this.sensorSocket.url]['heartRateData'];
		let respirationData = this.dataService.savedOutputs[this.sensorSocket.url] && this.dataService.savedOutputs[this.sensorSocket.url]['respirationData'];
		if (heartRateData && heartRateData.length) {
			this.heartRateData = {
				data: heartRateData.map(row => {
					return [1, row[1], 0, row[0]];
				})
			};
		}
		if (respirationData && respirationData.length) {
			this.respirationData = {
				data: respirationData.map(row => {
					return [1, row[1], 0, row[0]];
				})
			};
		}
	}
}
