import {Component, Injector, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ToolbarService} from '../../../../services/toolbar.service';
import {ToolbarBaseComponent} from '../toolbar.base.component';

/**
 * Component renders count of connected sensors on the top of page.
 */
@Component({
	selector: 'app-toolbar-sensors',
	templateUrl: './toolbar-sensors.component.html',
	styleUrls: ['./toolbar-sensors.component.scss']
})
export class ToolbarSensorsComponent extends ToolbarBaseComponent implements OnInit {

	environment = environment;

	constructor(public toolbarService: ToolbarService,
				protected injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
	}

}
