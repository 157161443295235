<div class="arena-settings">
	<div class="sensor-tabs">
		<div class="tabs" *ngIf="sensorSocket?.isSensorsNetworkEnabled">
			<button tabindex="-1" (click)="setActiveTab(Tabs.ARENA)" [class.active]="Tabs.ARENA == settingForm.controls.activeTab.value">{{'ARENA' | translate}}</button>
			<button tabindex="-1" (click)="setActiveTab(Tabs.ROOM_INFO)" [class.active]="Tabs.ROOM_INFO == settingForm.controls.activeTab.value">{{'ROOM_INFO' | translate}}</button>
		</div>

		<!-- Tabs content -->
		<div class="container" [class.withTabs]="sensorSocket?.isSensorsNetworkEnabled">
			<!-- Monitoring Tab -->
			<div class="monitoring" *ngIf="settingForm.controls.activeTab.value === Tabs.ARENA">
				<button style="float: right;" mat-raised-button (click)="updateBoundaries()" class="g-btn update-boundaries"
						[disabled]="!sliders.X.needsUpdate && !sliders.Y.needsUpdate && !sliders.Z.needsUpdate">Update</button>
				<br><br>
				<div class="sensor-monitoring-area"
					 data-selector="monitoredRoomDims">

					<app-sensor-slider
						*ngFor="let axis of sliders | keyvalue; let index=index"
						#axisSlider
						class="slider-{{axis.key}}"
						[class.changed]="sliders[axis.key].needsUpdate"
						zeroPointPosition="relative"
						[isChanged]="sliders[axis.key].needsUpdate"
						[controlMin]="settingForm.get('MonitoredRoomDims' + axis.key + 'Min')"
						[controlMax]="settingForm.get('MonitoredRoomDims' + axis.key + 'Max')"
						[positionValue]="getSliderPositionValue(axis.key)"
						[min]="sliders[axis.key].defaultValues[0]"
						[max]="sliders[axis.key].defaultValues[1]"
						[rangeMin]="getSliderInputRange(axis.key) | numberInputConfig: 0"
						[rangeMax]="getSliderInputRange(axis.key) | numberInputConfig: 1"
						[step]="step"
						[readonly]="isSliderReadonly(axis.key)"
						[label]="settingForm.controls.isCoverageFeet.value ? 'F' : 'M'"
						(startDrag)="startDrag($event)"
						(stopDrag)="stopDrag(axis)"
						(update)="updateValuesBySlider($event, axis.key)"
						(valueChange)="onValueChange($event, index)"
						data-selector="monitoredRoomDims-{{axis.key}}Min"></app-sensor-slider>

					<app-slide-toggle [formControl]="settingForm.controls.isCoverageFeet"
									  [readonly]="readonly || sliders.X.needsUpdate || sliders.Y.needsUpdate || sliders.Z.needsUpdate"
									  (change)="onMeterFeetChange()"
									  [checkedValue]="'FEET' | translate"
									  [unCheckedValue]="'METER' | translate"
									  class="app-slide-toggle"></app-slide-toggle>
				</div>
			</div>
		</div>
	</div>
</div>
