import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {FirebaseService} from './services/firebase.service';
import {environment} from '../environments/environment';

/**
 * App root component.
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	constructor(private translate: TranslateService,
				private router: Router,
				private firebaseService: FirebaseService) {

		let language, test = (navigator.language ? navigator.language.substr(0, 2).toLowerCase() :
			((navigator['userLanguage'] && navigator['userLanguage'].replace(/-[a-z]{2}$/, String.prototype.toUpperCase))) || 'en');
		switch (test) {
			case 'en':
			default:
				language = 'en';
				break;
		}

		this.translate.use(language);

		if (!environment.offline) {
			this.firebaseService.init();
		}
	}

	ngOnInit() {
	}
}
