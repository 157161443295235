import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseViewComponent} from '../../../../components/base-view/base-view.component';
import {ConnectionData, RetailGraph} from '../../../../models/models';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {mergeMap, skip, takeUntil} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ConnectionType} from '../../../../consts';
import {generateLoadingPopupStatusText, prepareHttpParamsForRetailGraph} from '../../../../utils/utils';
import {geoFencesGraphs, retailGraphs} from '../../../../services/system/rest.service';
import {FormGroup} from '@angular/forms';

@Component({
	selector: 'app-graphs',
	templateUrl: '../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../components/base-view/base-view.component.scss']
})
export class GraphsComponent extends BaseViewComponent implements OnInit, OnDestroy {

	isRetailAnalytics = false;
	isGeoFences = false;
	showLayerControlsMenu = false;
	viewContainerRequiredConnection = true;
	showLoadingWhileConnecting = true;
	graphs: Array<RetailGraph> = [];
	selectedGraph: RetailGraph;
	locationNames: any = {};
	data: any = {};
	xValues: any = [];
	connectionSettings: any = {
		sensorParameters: {
			'ProcessorCfg.MonitoredRoomDims': []
		},
		guiParameters: {
			isCoverageFeet: false
		},
		socketInfo: {
			name: '',
			backgroundImage: '',
			globalRoomWidth: 0,
			globalRoomHeight: 0
		}
	};

	environment = environment;

	isLive = false;

	private liveDataStartedAt;
	private liveDataInterval = 200;
	private liveDataError = new BehaviorSubject(false);
	private liveDataSubscription: Subscription;
	private numOfResults = 1;
	private lastRefreshDataPromise: Promise<any>;
	private lastUsedFilter: any;
	private dataIntervalId;

	constructor(protected injector: Injector) {
		super(injector);
	}

	async ngOnInit() {
		this.isRetailAnalytics = this.route.snapshot.data.isRetailAnalytics;
		this.isGeoFences = this.route.snapshot.data.isGeoFences;
		if (this.isRetailAnalytics) {
			this.graphs = retailGraphs;
		}
		if (this.isGeoFences) {
			this.graphs = geoFencesGraphs;
		}
		this.toolbarService.isAnalyticsGeoFencesFilterShown = this.isRetailAnalytics;
		this.toolbarService.isAnalyticsTotalTrafficShown = this.isGeoFences;
		this.toolbarService.isAnalyticsLive = false;
		this.toolbarService.analyticsSelectedGraph = null;
		this.updateMultipleRoomsParamsIfNeeded();
		this.toolbarService.setIsSmartBuildings(true);
		this.toolbarService.setConnection(null as any);
		// Reset toolbar form
		if (!this.toolbarService.analyticsForm) {
			this.toolbarService.initAnalyticsForm();
			(this.toolbarService.analyticsForm as FormGroup).setValue(this.rest.filter);
		}
		await this.initConnection();

		if (!this.toolbarService.floorPlanFiltersForm) {
			this.toolbarService.initFloorPlanFiltersForm();
		} else {
			this.reloadGeoFences();
		}

		let locations = await this.rest.getLocations();
		locations.forEach(location => {
			this.locationNames[location.id as number] = location.building_name;
		});
		if (locations.length) {
			let firstLocation = locations[0],
				floors = (await this.rest.getFloors()).filter(f => f.parent_building === firstLocation.id);

			if (!this.toolbarService.analyticsForm.controls.building_id.value) {
				this.toolbarService.analyticsForm.patchValue({building_id: firstLocation.id});
			}
			if (floors.length) {
				this.updateFloorId(floors[0].id as number);
			}
			this.reloadGeoFences().then(() => {
				this.refreshData();
			});
		}

		this.updateMultipleRoomsParamsIfNeeded();
		this.subscriptions.push(this.busEventService.connect.subscribe((data: ConnectionData) => {
			if (data.type === ConnectionType.REST) {
				this.toolbarService.analyticsForm.enable({emitEvent: false});
				this.retailServerAddressUpdated();
				this.updateConnection(data.url);
			}
		}));
		this.subscriptions.push(this.busEventService.disconnect.subscribe(data => {
			if (data.type === ConnectionType.REST) {
				this.toolbarService.analyticsForm.disable({emitEvent: false});
				this.stopLiveData();
			}
		}));
		this.subscriptions.push(this.busEventService.run.subscribe(e => {
			if (e === this.connection.url) {
				this.isLive = true;
				this.toggleLiveMode();
			}
		}));
		this.subscriptions.push(this.busEventService.stop.subscribe(e => {
			if (e === this.connection.url) {
				this.isLive = false;
				this.toggleLiveMode();
			}
		}));
		this.subscriptions.push(this.toolbarService.onRefreshClick.subscribe(e => {
			this.rest.clearCache([...this.graphs.map(graph => [`${graph.page}/graph`, `${graph.page}/kpi`]).flat(), 'heatmap', 'total-traffic-conversion',
				...this.geoFences.map(geoFence => `total-traffic-conversion_${geoFence.id}/kpi`)]);
			this.refreshData();
		}));
		this.subscriptions.push(this.busEventService.multipleRoomsParamsChanged.subscribe(info => {
			this.connectionSettings = Object.assign({}, this.connectionSettings, {socketInfo: info});
		}));
		this.subscriptions.push(this.toolbarService.analyticsForm.controls.floor_id.valueChanges.subscribe(() => {
			this.updateMultipleRoomsParamsIfNeeded();
			this.reloadGeoFences();
		}));
	}

	ngOnDestroy(): void {
		this.rest.preloadingIsCanceled = true;
		this.stopLiveData();
		this.busEventService.restIsLoading.next(false);
		clearInterval(this.dataIntervalId);
		if (this.lastUsedFilter) {
			if (typeof this.lastUsedFilter.floor_id !== 'number') {
				this.lastUsedFilter.floor_id = null;
			}
			this.toolbarService.analyticsForm.setValue(this.lastUsedFilter);
		}
		super.ngOnDestroy();
	}

	async updateWithSettings(initialParameters?) {
		const parameters = await this.settingsService.getAllSettings(this.connection.url);
		this.toolbarService.setIsDuplicateViewFlag(this.isDuplicateView);
		initialParameters = {};
		initialParameters['ProcessorCfg.ExternalGUI.Layers'] = [
			{
				'componentName': 'SmartRetail',
				'displayName': 'SmartRetail',
				'staticDataDict': {
					'graphs': this.graphs
				}
			}, {
				'componentName': 'GeoFences',
				'displayName': 'Geo Fences',
				'staticDataDict': {
					'graphs': this.graphs
				}
			}
		];
		this.generateLayers({
			sensorParameters: initialParameters,
			guiParameters: parameters.guiParameters
		});
	}

	protected generateLayers(parameters) {
		parameters.guiParameters['selectedLayers'] = [[
			{
				name: 'SmartRetail',
				componentName: 'SmartRetailComponent',
				'staticDataDict': {
					'graphs': this.graphs
				},
				selected: this.isRetailAnalytics
			}, {
				name: 'GeoFences',
				componentName: 'GeoFencesComponent',
				'staticDataDict': {
					'graphs': this.graphs
				},
				selected: this.isGeoFences
			}
		], []];
		super.generateLayers(parameters);
	}

	async toggleLiveMode() {
		this.liveDataInterval = environment.analyticsRESTserverLiveDataInterval;
		this.toolbarService.isAnalyticsLive = this.isLive;
		if (this.isLive) {
			this.liveDataStartedAt = +new Date;
			this.startLiveData();
		} else {
			this.stopLiveData();
			if (this.lastRefreshDataPromise) {
				await this.lastRefreshDataPromise;
			}
			this.refreshData();
		}
	}

	async retailServerAddressUpdated() {
		this.refreshData().then(() => {
			if (!this.isDestroyed) {
				this.busEventService.smartBuildingPageIsReady.emit();
			}
		});
	}

	onLayerToggle(viewIndex: number, graph) {
		this.selectedGraph = graph;
		this.toolbarService.analyticsSelectedGraph = this.selectedGraph;
	}

	async refreshData(inBackground = false) {
		if (typeof this.toolbarService.floorId !== 'number') {
			if (typeof this.toolbarService.analyticsForm.controls.building_id.value === 'number') {
				return this.modalService.showMessage('LOCATION_HAS_NO_FLOORS_WARNING', undefined, undefined, undefined, {
					location_name: this.locationNames[this.toolbarService.analyticsForm.controls.building_id.value]
				});
			}
			return Promise.resolve();
		}
		let returnPromise;
		if (!inBackground) {
			this.busEventService.restLoadingPercentage.next(0);
			this.busEventService.restIsLoading.next(true);
		}

		if (inBackground) {
			if (this.isLive && this.selectedGraph.liveModeSupport) {
				this.rest.clearCache(['heatmap']);
				returnPromise = this.refreshGraph(this.selectedGraph);
			} else {
				returnPromise = Promise.resolve();
			}
		} else {
			/**
			 * Every 1 second go up in 1 percent.
			 * Every request that is returned go up to the the limit of the part we are.
			 */
			returnPromise = new Promise(resolve => {
				let worker = i => {
					this.busEventService.restLoadingStatus.next(this.generateLoadingPopupStatusText(i));
					let newPercent = 100 / this.graphs.length * (i + 1);
					this.dataIntervalId = setInterval(() => {
						let nextPercent = this.busEventService.restLoadingPercentage.value + 1;
						this.busEventService.restLoadingPercentage.next(nextPercent);
						if (nextPercent + 1 > newPercent) {
							clearInterval(this.dataIntervalId);
						}
					}, 1000);
					this.refreshGraph(this.graphs[i]).then(() => {
						if (this.isDestroyed) {
							resolve();
						} else {
							clearInterval(this.dataIntervalId);
							this.busEventService.restLoadingPercentage.next(newPercent);
							if (this.graphs[i + 1]) {
								worker(i + 1);
							} else {
								this.busEventService.restIsLoading.next(false);
								resolve();
							}
						}
					}).catch(() => {
						this.busEventService.restIsLoading.next(false);
					});
				};

				worker(0);
			});
		}
		this.lastUsedFilter = this.toolbarService.analyticsForm.getRawValue();
		this.lastRefreshDataPromise = returnPromise;
		return returnPromise;
	}

	refreshGraph(graph: RetailGraph): Promise<any> {
		let filterValue = this.toolbarService.analyticsForm.getRawValue(),
			filter = Object.assign({}, filterValue, {
				floor_ids: []
			});

		if (typeof this.toolbarService.floorId === 'number') {
			filter['floor_ids'].push(this.toolbarService.floorId);
		}
		switch (graph.type) {
			case 'heatmap':
				if (typeof this.toolbarService.floorId === 'number') {
					let params = prepareHttpParamsForRetailGraph(graph, filter, this.isLive, this.numOfResults);

					return this.rest.getHeatmap(params).then((response: any) => {
						let data: any = {
							data: [response]
						};

						this.data[graph.page] = data;

						return response;
					}).catch(e => {
						this.catchRestError(e, graph);
					}).finally(() => {
						this.data[graph.page].isLoading = false;
					});
				} else {
					return Promise.resolve();
				}
				break;
			case 'conversion':
				if (this.geoFences?.length) {
					if (this.isLive) {
						this.numOfResults += 1;
					} else {
						if (!this.data[graph.page]) {
							this.data[graph.page] = {};
						}
						this.data[graph.page].isLoading = true;
						this.numOfResults = 1; // Reset number of results to 1
					}
					filter = Object.assign({}, filterValue, {
						floor_ids: [filterValue['floor_id']]
					});
					let totalTrafficConversionParams: any = prepareHttpParamsForRetailGraph(graph, filter, this.isLive, this.numOfResults);

					// Get total traffic for calculating the geo-fences percentage out of the floor plan's total traffic
					let floorPlanTotalTrafficFilter = Object.assign({}, filter);
					floorPlanTotalTrafficFilter.location_ids = [];
					let totalTrafficParams: any = prepareHttpParamsForRetailGraph(graph, floorPlanTotalTrafficFilter, this.isLive, this.numOfResults);

					return this.rest.getGraph(totalTrafficParams, graph.totalPage as string, true, 'total-traffic-conversion').then((response: any) => {
						let totalTraffic = response.kpi || 0;
						this.toolbarService.analyticsTotalTraffic = totalTraffic;
						return Promise.all(this.geoFences.map(geoFence => {
							let params = {
								dates: totalTrafficConversionParams.dates,
								times: totalTrafficConversionParams.times,
								location_ids: [geoFence.id],
								utc_timezone_offset: totalTrafficConversionParams.utc_timezone_offset
							};
							return this.rest.getGraph(params, graph.totalPage as string, true, `${graph.page}_${geoFence.id}/kpi`);
						})).then(data => {

							let ret = {
								data: [this.geoFences.map((g, i) => {
									let num_of_people = data[i].kpi || 0;

									return {
										geo_fence_id: g.id,
										value: Math.round(num_of_people * 100 / totalTraffic) || 0,
										num_of_people: num_of_people
									};
								})],
								kpi: 0
							};

							this.data[graph.page] = ret;
						});
					});
				} else {
					return Promise.resolve();
				}
				break;
			default:
				if (this.isLive) {
					this.numOfResults += 1;
				} else {
					if (!this.data[graph.page]) {
						this.data[graph.page] = {};
					}
					this.data[graph.page].isLoading = true;
					this.numOfResults = 1; // Reset number of results to 1
				}

				let params = prepareHttpParamsForRetailGraph(graph, filter, this.isLive, this.numOfResults);

				return this.rest.getGraph(params, graph.page).then((response: any) => {
					let data: any = [];
					if ('time' in response && response.time) {
						this.xValues = response.time;
						data.push(['x'].concat(response.time));
					} else {
						data.push(['x'].concat([]));
					}
					if (graph.isTime) {
						data.push(
							[graph.label].concat(response.buckets.map(v => v / 60))// sec -> min
						);
					} else {
						data.push(
							[graph.label].concat(response.buckets)
						);
					}
					if ('kpi' in response) {
						data.kpi = response.kpi;
					}
					this.data[graph.page] = data;

					return response;
				}).catch(e => {
					this.catchRestError(e, graph);
				}).finally(() => {
					this.data[graph.page].isLoading = false;
				});
				break;
		}
	}

	private startLiveData() {
		this.liveDataSubscription = interval(this.liveDataInterval)
			.pipe(mergeMap(() => this.refreshData(true)))
			.pipe(takeUntil(this.liveDataError.pipe(skip(1))))
			.subscribe();
	}

	private stopLiveData() {
		if (this.liveDataSubscription) {
			this.liveDataSubscription.unsubscribe();
		}
	}

	private catchRestError(e, graph) {
		this.data[graph.page] = [];
		this.toolbarService.setConnectionStatus('NOT_CONNECTED');
		this.liveDataError.next(true);
		this.isLive = false;
		this.toolbarService.isAnalyticsLive = this.isLive;
	}

	private applyDateFilter() {
		if (!(this.isLive && this.selectedGraph.liveModeSupport)) {
			let rawValue = this.toolbarService.analyticsForm.getRawValue();
			if (rawValue['end_time'] >= rawValue['start_time']) {
				this.refreshData();
				this.rest.filter = rawValue;
			} else {
				this.modalService.showError('END_TIME_IS_LESS_ERROR');
			}
		}
	}

	private updateConnection(url) {
		let [ip, port] = url.replace('http://', '').split(':');
		this.connection = this.rest.getConnection(url) || this.rest.createConnection(ip, port);
		this.toolbarService.setConnection(this.connection);
		this.updateWithSettings();
	}

	private async initConnection() {
		let analyticsServerAddress;
		if (environment.useAzure) {
			analyticsServerAddress = environment.azure.telemetryFunctionAppURL;
		} else {
			if (environment.disableRemoteConnToRESTServer) {
				analyticsServerAddress = environment.analyticsRESTserverAddress;
			} else {
				let tmp = await this.storageService.getItem('analyticsServerAddress');
				analyticsServerAddress = (tmp || {}).analyticsServerAddress;
			}
		}
		if (analyticsServerAddress) {
			let url = analyticsServerAddress + (environment.useAzure ? '' : `:${this.rest.retailAnalyticsAPIserverPort}`);
			if (!url.startsWith('http')) {
				url = 'http://' + url;
			}

			this.updateConnection(url);
		}
	}

	private generateLoadingPopupStatusText(i) {
		return generateLoadingPopupStatusText(this.graphs.map(g => g.label), i);
	}

	private emitMultipleRoomsParamsChanged(data) {
		this.busEventService.multipleRoomsParamsChanged.emit({
			name: data['floor_name'],
			globalRoomWidth: +data['max_x'] - +data['min_x'],
			globalRoomHeight: +data['max_y'] - +data['min_y'],
			backgroundImage: data['base64_image']
		});
	}

	private updateMultipleRoomsParamsIfNeeded() {
		let floorId = this.toolbarService.floorId;
		if (typeof floorId === 'number') {
			this.rest.getFloorPlan(floorId).then(response => {
				this.emitMultipleRoomsParamsChanged(response);
			});
		} else {
			this.emitMultipleRoomsParamsChanged({
				floor_name: '',
				min_x: 0,
				max_x: 0,
				min_y: 0,
				max_y: 0,
				base64_image: null
			});
		}
	}

	private reloadGeoFences() {
		let floorId = this.toolbarService.floorId;
		if (typeof floorId === 'number') {
			return this.rest.getGeoFences(floorId).then(geoFences => {
				this.geoFences = geoFences;
			});
		} else {
			this.geoFences = [];
			return Promise.resolve();
		}
	}

	private updateFloorId(floor_id: number) {
		this.toolbarService.floorId = floor_id;
		this.toolbarService.analyticsForm.patchValue({floor_id: floor_id});
	}
}
