import {Connection, ConnectionStatus} from './connection';
import {InjectorInstance} from '../../app.module';
import {HttpClient} from '@angular/common/http';

/**
 * Class for working with REST API using HTTP.
 */
export class RestConnection extends Connection {

	get url() {
		let url = [this.ip, this.port].filter(p => p).join(':');
		if (!url.startsWith('http')) {
			url = 'http://' + url;
		}
		return url;
	}

	private http = InjectorInstance.get<HttpClient>(HttpClient);

	connect() {
		this.disconnectReason = null;
		this._lastErrorMessage = null;
		this.connectionStatus = ConnectionStatus.CONNECTED;
		return Promise.resolve();
		if (this.canDisconnect()) {
			return Promise.resolve();
		}
		this.disconnectReason = null;
		this._lastErrorMessage = null;
		this.connectionStatus = ConnectionStatus.CONNECTING;
		return this.http.get(`${this.url}/connect`, {
			responseType: 'text'
		}).toPromise().then(() => {
			this.connectionStatus = ConnectionStatus.CONNECTED;
		});
	}

	disconnect() {
		this.connectionStatus = ConnectionStatus.DISCONNECTED;
	}

	isConnected(): boolean {
		return this.connectionStatus === ConnectionStatus.CONNECTED;
	}

	isConnecting(): boolean {
		return this.connectionStatus === ConnectionStatus.CONNECTING;
	}

	isRunned() {
		return this.isConnected();
	}
}
