import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {rotateAndReflect} from '../../../../../utils/ImageUtils';

@Component({
	selector: 'app-point-cloud-raw2d-wand',
	templateUrl: './point-cloud-raw2d-component-wand.component.html',
	styleUrls: ['./point-cloud-raw2d-component-wand.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointCloudRaw2dComponentWand implements OnChanges {

	@Input() data: any;
	@ViewChild('canvas', {static: true}) canvas: ElementRef;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
		changeDetectorRef.detach();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.data?.data?.[0]) {
			let data: Array<Array<Array<number>>> = rotateAndReflect(this.data.data, false, 1);

			let canvas = document.createElement('canvas');
			canvas.width = this.canvas.nativeElement.width = data[0].length;
			canvas.height = this.canvas.nativeElement.height = data.length;
			let ctx = canvas.getContext('2d', {alpha: true}) as CanvasRenderingContext2D,
				imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			for (let i = 0; i < data.length; i++) {
				for (let j = 0; j < data[0].length; j++) {
					let [r, g, b] = data[i][j], color = {r, g, b, a: 255},
						redIndex = ((i * (imageData.width * 4)) + (j * 4)),
						greenIndex = ((i * (imageData.width * 4)) + (j * 4)) + 1,
						blueIndex = ((i * (imageData.width * 4)) + (j * 4)) + 2,
						alfaIndex = blueIndex + 1;

					imageData.data[redIndex] = color.r;
					imageData.data[greenIndex] = color.g;
					imageData.data[blueIndex] = color.b;
					imageData.data[alfaIndex] = color.a;
				}
			}
			this.canvas.nativeElement.getContext('2d').putImageData(imageData, 0, 0);
		}
	}
}
