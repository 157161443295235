import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Injector,
	Input,
	NgZone,
	OnChanges,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import * as LineGraphUtils from '../../../utils/LineGraphUtils';
import * as d3 from 'd3';
import {DataService} from '../../../services/system/data.service';
import {SensorSocket} from '../../../services/system/sensor-socket';
import {getColor} from '../../../utils/utils';
import {SelectingTargetDataGraphBehavior} from '../../../consts';

const secondsFormat = d => `${d}s`;
const resetZoomTextWidth = 100;

/**
 * Component renders graph. Inherited in activity, breathing, vitals.
 */
@Component({
	selector: 'app-graph',
	template: ''
})
export class BaseLineGraphComponent implements AfterViewInit, OnChanges {

	@Input() data;
	@Input() dataKeyName;
	@Input() measLabel;
	@Input() parameters;
	@Input() selectedModelIds;
	@Input() status;
	@Input() sensorSocket: SensorSocket;

	@ViewChild('container', {static: true}) private container: ElementRef;

	isAutoMode = true;
	containerWidth;
	containerHeight;
	graphs: any = {};
	minYValue = 0;
	maxYValue = 0.3;
	lastRecordedTime = null;
	templateDataOverTime: Array<any> = [];
	minTimeDomainValue: number | null = null;
	maxTimeDomainValue: number | null = null;
	dataIntervalSec;

	isZoomYaxisEnabled = true;
	isMeasurementEnabled = true;

	private dataService: DataService;
	private zone: NgZone;

	constructor(public injector: Injector) {
		this.dataService = injector.get(DataService);
		this.zone = injector.get(NgZone);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.zone.runOutsideAngular(() => {
				this.setTimeDomain();
				this.containerWidth = this.container.nativeElement.offsetWidth;
				this.containerHeight = this.container.nativeElement.offsetHeight;
				let currentData = this.dataService.savedOutputs[this.sensorSocket.url] && this.dataService.savedOutputs[this.sensorSocket.url][this.dataKeyName];
				if (currentData) {
					this.resetGraphs();
					let targetIDs = Array.from(new Set([].concat(...currentData.map(a => a.map(b => b[0])))));
					targetIDs.forEach(targetID => {
						this.addGraph(targetID);
						let dataRow = currentData[currentData.length - 1].find(r => r[0] === targetID);

						const {targetGraph} = this.updateGraphData(dataRow);
						this.maxTimeDomainValue = this.dataIntervalSec;
						this.minTimeDomainValue = this.maxTimeDomainValue! - this.dataIntervalSec;

						let dataOverTime: Array<any> = [];
						currentData.forEach(row => {
							let targetData = row.find(r => r[0] === targetID);
							if (targetData) {
								this.prepareDataOverTime(targetData, dataOverTime, targetGraph);
							}
						});

						targetGraph.dataOverTime = dataOverTime;

						// Update measurement
						if (this.isMeasurementEnabled) {
							targetGraph.d3.measurement.text(this.getMeasText(targetGraph, dataRow));
						}

						LineGraphUtils.rescaleYInCaseMaxOrMinHaveChanged(targetGraph.d3, targetGraph.d3.minYValue, targetGraph.d3.maxYValue);

						// Update graph path
						this.updateGraphPath(targetGraph, targetID);
					});
					this.updateExistingGraphs();
				} else {
					if (this.data && this.data.data && this.data.data.length) {
						let data = !this.data.data[0].length ? [this.data.data] : this.data.data;
						this.updateGraphs(data);
					} else {
						this.drawInitialEmptyGraph();
					}
				}
			});
		});
	}

	ngOnChanges(c: SimpleChanges) {

		if ('parameters' in c) {
			let needUpdateCurrentGraphs = this.dataIntervalSec !== this.parameters['sensorParameters']['ProcessorCfg.ExternalGUI.breathing_interval_sec'];
			this.dataIntervalSec = this.parameters['sensorParameters']['ProcessorCfg.ExternalGUI.breathing_interval_sec'];
			this.isAutoMode = this.parameters['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.Automatically;
			if (needUpdateCurrentGraphs && !c.parameters.isFirstChange()) {
				this.setTimeDomain();
				this.resetAllGraphsZoom();
				this.updateExistingGraphs();
			}
		}

		if (this.containerWidth) {
			if ('status' in c && c.status.previousValue === 'CALIBRATING') {
				// Clean graphs right when we enter imaging mode
				this.resetGraphs();
				this.drawInitialEmptyGraph();
			}
			if (this.minTimeDomainValue === null) {
				// Set the time domain only once per 'IMAGING' session
				this.setTimeDomain();
			}

			if ('data' in c && this.data && this.data.data && this.data.data.length) {
				let data = !this.data.data[0].length ? [this.data.data] : this.data.data;
				this.updateGraphs(data);
			}
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.containerWidth = this.container.nativeElement.offsetWidth;
		this.containerHeight = this.container.nativeElement.offsetHeight;
		this.updateExistingGraphs();
	}

	updateTemplateDataOverTime(time) {
		this.templateDataOverTime.push({
			time: time,
			value: 0,
		});
		if (time > this.maxTimeDomainValue!) {
			this.templateDataOverTime.shift();
		}
	}

	setTimeDomain() {
		this.minTimeDomainValue = 0;
		const maxTimeDomainSec = this.minTimeDomainValue + this.dataIntervalSec;
		this.maxTimeDomainValue = maxTimeDomainSec;
	}

	resetGraphs() {
		d3.select(this.container.nativeElement).selectAll('svg').remove();
		this.graphs = {};
		this.minTimeDomainValue = null;
		this.maxTimeDomainValue = null;
		this.minYValue = 0;
		this.maxYValue = 1;
		this.lastRecordedTime = null;
		this.templateDataOverTime = [];
	}

	addNewGraphs(breathingData) {
		let targetIDs = Array.from(new Set(breathingData.map(a => a[0])));
		targetIDs.forEach((targetID: number) => {
			if (targetID) {
				let data = breathingData.filter(r => r[0] === targetID);
				// Update template breathingDataOverTime
				if (this.isAutoMode) {
					if (!this.graphs[targetID]) {
						this.addGraph(targetID);
					}
					this.updateMultipleData(targetID, data);
				} else {
					this.toggleGraphsOnClick(targetID);
					if (this.graphs[targetID]) {
						this.updateMultipleData(targetID, data);
					}
				}
			}
		});
	}

	toggleGraphsOnClick(targetID) {
		// Handle only valid targets (targetID is not 0, meaning it's valid)
		if (this.selectedModelIds.includes(targetID + '')) {
			// Check if it's a new target (meaning not existing in this.graphs)
			if (!this.graphs[targetID]) {
				this.addGraph(targetID);
			}
			if (d3.select(`#targetID_${targetID}`).attr('display') === 'none') {
				d3.select(`#targetID_${targetID}`).attr('display', 'null');
				this.updateExistingGraphs(); // Update the rest of the graph heights
			}
		} else {
			if (this.graphs[targetID]) {
				this.hideGraph(targetID);
				this.updateExistingGraphs(); // Update the rest of the graph heights
			}
		}
	}

	hideGraph(targetID) {
		if (d3.select(`#targetID_${targetID}`).attr('display') !== 'none') {
			d3.select(`#targetID_${targetID}`).attr('display', 'none');
			this.updateExistingGraphs(); // Update the rest of the graph heights
		}
	}

	/**
	 * Draw the graph according to:
	 *    1. Current Value
	 *    2. Elapsed Time
	 *
	 * The values accepts in `dataRow` are:
	 * `dataRow[0] = Target ID`
	 * `dataRow[1] = Value`
	 * `dataRow[2] = Measurement Value`
	 * `dataRow[3] = Elapsed Time`
	 *
	 * @param {*} dataRow
	 */
	drawGraph(dataRow) {
		const {targetGraph, targetID} = this.updateGraphData(dataRow);

		if (targetGraph.dataOverTime) {
			// Update measurement
			if (this.isMeasurementEnabled) {
				targetGraph.d3.measurement.text(this.getMeasText(targetGraph, dataRow));
			}
			// Update graph path
			this.updateGraphPath(targetGraph, targetID);
		}
	}

	updateGraphPath(targetGraph, targetID) {
		const color = getColor(targetID);
		targetGraph.d3.path
		.data([targetGraph.dataOverTime])
		.attr('fill', 'none')
		.attr('stroke', color)
		.attr('stroke-width', '3')
		.attr('d', targetGraph.d3.line)
		.style('vector-effect', 'non-scaling-stroke');
	}

	getMeasText(targetGraph, dataRow) {
		const measurement = parseFloat(dataRow[2]).toFixed(2);
		const time = dataRow[3];
		let measurementText;
		if (!parseFloat(measurement)) {
			if (!targetGraph.didntReceiveMeasTime) {
				targetGraph.didntReceiveMeasTime = time;
			}
			const timeElapsed = time - targetGraph.didntReceiveMeasTime;
			measurementText = `${this.measLabel}: NA`;
			if (timeElapsed > 1 && targetGraph.lastRecordedMeas) {
				measurementText += ` (last ${this.measLabel} of ${targetGraph.lastRecordedMeas} detected ${Number.parseInt(timeElapsed + '')}s ago)`;
			}
		} else {
			targetGraph.didntReceiveMeasTime = null;
			targetGraph.lastRecordedMeas = measurement;
			measurementText = `${this.measLabel}: ${measurement}`;
		}
		return measurementText;
	}

	initDataTillCurrTime(currGraphDataOverTime, currTime) {
		const len = this.templateDataOverTime.length;
		for (let i = 0; i < len && i < this.templateDataOverTime[i].time < currTime; i++) {
			currGraphDataOverTime.push({
				time: this.templateDataOverTime[i].time,
				value: null,
			});
		}
	}

	updateGraphData(dataRow) {
		const targetID = dataRow[0];
		const targetGraph = this.graphs[targetID];
		const value = dataRow[1];
		const dataRowTime = dataRow[3];
		// Add documentation of why we need this
		this.lastRecordedTime = dataRowTime;

		if (targetGraph.dataOverTime && !targetGraph.dataOverTime.length) {
			this.initDataTillCurrTime(targetGraph.dataOverTime, dataRowTime);
		}
		// Update graph data
		targetGraph.dataOverTime.push({
			time: dataRowTime,
			value: value === 'NaN' ? null : value,
		});

		if (dataRowTime >= this.maxTimeDomainValue!) {
			// Remove data from the beginning of targetGraph.dataOverTime (only if it has more than 1 row)
			if (targetGraph.dataOverTime.length > 1) {
				targetGraph.dataOverTime.shift();
			}

			// Update domain of the x axis
			if (targetGraph.dataOverTime.length === 1) {
				// This could happen when the layer is enabled after:
				// 1. IMAGING has started (when the layer is not enabled)
				// 2. dataRowTime > this.props.dataIntervalSec
				this.minTimeDomainValue = dataRowTime;
				this.maxTimeDomainValue = dataRowTime + this.dataIntervalSec;
			} else {
				this.minTimeDomainValue = this.getMinimumTime();
				this.maxTimeDomainValue = dataRowTime;
			}
			// Create x scale function
			targetGraph.d3.x = LineGraphUtils.createXScaleFunc(
				targetGraph.d3.graphDims.width,
				this.minTimeDomainValue,
				this.maxTimeDomainValue
			);
			targetGraph.d3.xAxis = LineGraphUtils.getXAxis(targetGraph.d3.x, secondsFormat);
			targetGraph.d3.xAxisGroup.call(targetGraph.d3.xAxis);
			targetGraph.d3.line = LineGraphUtils.createLineFunc(targetGraph.d3.x, targetGraph.d3.y);
		}

		// Rescale Y axis in case we have a new yMin or yMax
		if (value > targetGraph.d3.maxYValue) {
			targetGraph.d3.maxYValue = value;
			LineGraphUtils.rescaleYInCaseMaxOrMinHaveChanged(targetGraph.d3, targetGraph.d3.minYValue, targetGraph.d3.maxYValue);
		} else if (value < targetGraph.d3.minYValue) {
			targetGraph.d3.minYValue = value;
			LineGraphUtils.rescaleYInCaseMaxOrMinHaveChanged(targetGraph.d3, targetGraph.d3.minYValue, targetGraph.d3.maxYValue);
		}

		return {targetGraph, targetID};
	}

	getMinimumTime() {
		const allGraphs = Object.values(this.graphs);
		const min = Math.min(
			...allGraphs.map((graph: any) => {
				const graphFirstData = graph.dataOverTime[0];
				return graphFirstData.time;
			})
		);
		return min;
	}

	addGraph(targetID) {
		// Creating a new graph object
		let targetGraph = (this.graphs[targetID] = this.createNewGraphObj());
		// Set up the SVG (width, height, position) in which we're going to draw the graph
		targetGraph = this.setUpSVG(targetGraph, targetID);
		// Create axes (scaling, number of ticks, range, domain)
		targetGraph.d3 = LineGraphUtils.createAxes(
			targetGraph.d3,
			this.minTimeDomainValue,
			this.maxTimeDomainValue,
			this.minYValue,
			this.maxYValue
		);
		// Use the line function to match the (x, y) coordinates to our path
		// This will be used in the path
		targetGraph.d3.line = LineGraphUtils.createLineFunc(targetGraph.d3.x, targetGraph.d3.y);
		targetGraph.d3.pathG = targetGraph.d3.graph.append('g').attr('clip-path', `url(#clip_${targetID})`);
		targetGraph.d3.path = targetGraph.d3.pathG.append('path');
		this.updateExistingGraphs(); // Update the rest of the graph heights
	}

	getNumberOfTargets() {
		return this.isAutoMode
			? Object.values(this.graphs).length
			: this.selectedModelIds.filter(isTargetSelected => isTargetSelected).length;
	}

	getFixedSvgHeight(newNumOfTargetIDs) {
		let fixedSvgHeight: number | null = null;
		if (this.containerHeight < 600 && newNumOfTargetIDs > 2) {
			fixedSvgHeight = this.containerHeight / 2.1;
		} else if (this.containerHeight >= 600 && newNumOfTargetIDs > 5) {
			fixedSvgHeight = this.containerHeight / 4.1;
		}
		return fixedSvgHeight;
	}

	updateExistingGraphs() {
		// Handle responsiveness
		const newNumOfTargetIDs = this.getNumberOfTargets();
		let fixedSvgHeight = this.getFixedSvgHeight(newNumOfTargetIDs);
		this.containerHeight = this.container.nativeElement.offsetHeight;

		// Update all graphs
		Object.keys(this.graphs).forEach(
			function (targetID) {
				let targetGraphD3 = this.graphs[targetID].d3;
				const xAxisGroup = targetGraphD3.xAxisGroup;
				const yAxisGroup = targetGraphD3.yAxisGroup;
				// Rescale y scale - New height according to number of targets
				const svgNewHeight = fixedSvgHeight
					? fixedSvgHeight
					: newNumOfTargetIDs === 0
						? 0
						: this.containerHeight / newNumOfTargetIDs;
				const graphNewHeight = Math.max(
					0,
					svgNewHeight - targetGraphD3.graphMargins.top - targetGraphD3.graphMargins.bottom
				);
				targetGraphD3.svg.attr('height', svgNewHeight);
				targetGraphD3.graphDims.height = graphNewHeight;

				// Re-create y scale function
				targetGraphD3.y = LineGraphUtils.createYScaleFunc(graphNewHeight, targetGraphD3.minYValue, targetGraphD3.maxYValue);
				// Reposition x axis
				targetGraphD3.xAxisGroup = LineGraphUtils.positionXAxis(xAxisGroup, graphNewHeight);
				// Update and draw y axis
				targetGraphD3.yAxis = LineGraphUtils.getYAxis(targetGraphD3.y, targetGraphD3.graphDims.height);
				yAxisGroup.call(targetGraphD3.yAxis);

				// Rescale x scale (time scale) - New width according to container new width
				const graphNewWidth =
					this.containerWidth - targetGraphD3.graphMargins.left - targetGraphD3.graphMargins.right;
				targetGraphD3.svg.attr('width', this.containerWidth);
				targetGraphD3.graphDims.width = graphNewWidth;
				// Re-create x scale function
				targetGraphD3.x = LineGraphUtils.createXScaleFunc(
					graphNewWidth,
					this.minTimeDomainValue,
					this.maxTimeDomainValue
				);
				// Update and draw x axis
				targetGraphD3.xAxis = LineGraphUtils.getXAxis(targetGraphD3.x, secondsFormat);
				xAxisGroup.call(targetGraphD3.xAxis);

				// Re-create grid
				LineGraphUtils.createGrid(targetGraphD3);

				targetGraphD3.line = LineGraphUtils.createLineFunc(targetGraphD3.x, targetGraphD3.y);

				// Rescale clipPath
				targetGraphD3.svg
				.select('clipPath')
				.select('rect')
				.attr('width', graphNewWidth)
				.attr('height', graphNewHeight);

				// Update graph path
				this.updateGraphPath(this.graphs[targetID], targetID);

				// Update "Reset Zoom" button's position
				targetGraphD3.resetZoomGroup.attr(
					'transform',
					`translate(${this.containerWidth -
					targetGraphD3.graphMargins.right -
					resetZoomTextWidth}, ${targetGraphD3.graphMargins.top / 2})`
				);

				targetGraphD3.zoomBaseElem.attr('height', graphNewHeight).attr('width', graphNewWidth);
			}.bind(this)
		);
	}

	/**
	 * Remove graphs of non exisiting targets
	 * @param {*} data
	 */
	removeOldGraphs(data) {
		const dataTargets = data.map(dataRow => dataRow[0]);
		const targetIDsGraphsToRemove = Object.keys(this.graphs).filter(
			targetID => !dataTargets.includes(parseInt(targetID, 10))
		);
		if (targetIDsGraphsToRemove.length) {
			targetIDsGraphsToRemove.forEach(targetID => {
				d3.select(`#targetID_${targetID}`).remove();
				delete this.graphs[targetID];
			});
			this.updateExistingGraphs(); // Update the rest of the graph heights
		}
	}

	createNewGraphObj(): any {
		return {
			dataOverTime: [],
			lastRecordedMeas: null,
			d3: LineGraphUtils.createNewGraphD3Obj(),
		};
	}

	setUpSVG(targetGraph, targetID) {
		// Set up width and height of container
		const container = this.container.nativeElement;
		const containerHeight = (this.containerHeight = container.offsetHeight);
		const containerWidth = container.offsetWidth;

		// Graph dimensions
		const numOfTargets = this.getNumberOfTargets();
		const graphMargins = {top: 70, left: 50, right: 50, bottom: 25};
		const graphDims = {
			width: containerWidth - graphMargins.left - graphMargins.right,
			height: containerHeight / numOfTargets - graphMargins.top - graphMargins.bottom,
		};
		targetGraph.d3.graphDims = graphDims;
		targetGraph.d3.graphMargins = graphMargins;

		const zoom = d3
		.zoom()
		.scaleExtent([-1, 10])
		.on('zoom', () => {
			const t = d3.event.transform;
			const xt = t.rescaleX(targetGraph.d3.x);
			let yt;
			if (this.isZoomYaxisEnabled) {
				yt = t.rescaleY(targetGraph.d3.y);
			}
			targetGraph.d3.path.attr('transform', t);
			targetGraph.d3.xAxisGroup.call(targetGraph.d3.xAxis.scale(xt));
			if (this.isZoomYaxisEnabled) {
				targetGraph.d3.yAxisGroup.call(targetGraph.d3.yAxis.scale(yt));
			}
			LineGraphUtils.createGrid(targetGraph.d3, xt, yt);
		});

		// Create the SVG
		const svg = d3
		.select(this.container.nativeElement)
		.append('svg')
		.attr('width', containerWidth)
		.attr('height', containerHeight / numOfTargets)
		.attr('preserveAspectRatio', 'none')
		.attr('id', `targetID_${targetID}`)
		.style('pointer-events', 'none');

		targetGraph.d3.svg = svg;

		svg.append('clipPath')
		.attr('id', `clip_${targetID}`)
		.append('rect')
		.attr('width', graphDims.width)
		.attr('height', Math.max(graphDims.height, 0));

		if (this.isMeasurementEnabled) {
			const measurementGroup = svg.append('g').attr('transform', `translate(${graphMargins.left}, ${graphMargins.top / 2})`);

			measurementGroup
			.append('rect')
			.attr('height', '20px')
			.attr('width', '20px')
			.attr('y', '0')
			.attr('fill', getColor(targetID));

			const measurement = measurementGroup
			.append('text')
			.attr('x', '30px')
			.attr('y', '17px')
			.attr('fill', 'white')
			.style('font-size', '18px');
			targetGraph.d3.measurement = measurement;
		}

		const zoomBaseElem = svg
		.append('rect')
		.attr('x', graphMargins.left)
		.attr('y', graphMargins.top)
		.attr('height', Math.max(graphDims.height, 0))
		.attr('width', graphDims.width)
		.style('opacity', 0)
		.style('pointer-events', 'all')
		.style('z-index', 1000)
		.call(zoom);
		targetGraph.d3.zoomBaseElem = zoomBaseElem;
		targetGraph.d3.zoom = zoom;

		const resetZoomGroup = svg
		.append('g')
		.style('cursor', 'pointer')
		.attr(
			'transform',
			`translate(${containerWidth - graphMargins.right - resetZoomTextWidth}, ${graphMargins.top / 2})`
		)
		.style('pointer-events', 'all')
		.on('click', function (d, i) {
			this.resetZoom(zoomBaseElem, zoom);
		}.bind(this));

		let color = getColor(targetID);
		resetZoomGroup
		.append('rect')
		.attr('height', '30px')
		.attr('width', `${resetZoomTextWidth}px`)
		.attr('y', '0')
		.attr('fill', color);

		resetZoomGroup
		.append('text')
		.text('Reset Zoom')
		.attr('x', '9px')
		.attr('y', '19px')
		.attr('fill', 'white')
		.style('text-align', 'center');

		if (color === '#FFFFFF') {
			resetZoomGroup.select('text').style('mix-blend-mode', 'difference');
		}
		targetGraph.d3.resetZoomGroup = resetZoomGroup;

		return targetGraph;
	}

	protected prepareDataOverTime(targetData, dataOverTime, targetGraph) {
		let value = targetData[1] === 'NaN' ? null : targetData[1];
		let dataRowTime = targetData[3];
		dataOverTime.push({
			value: value,
			time: targetData[3]
		});

		// Rescale Y axis in case we have a new yMin or yMax
		if (value > targetGraph.d3.maxYValue) {
			targetGraph.d3.maxYValue = value;
		} else if (value < targetGraph.d3.minYValue) {
			targetGraph.d3.minYValue = value;
		}

		if (dataRowTime > this.maxTimeDomainValue!) {
			this.maxTimeDomainValue = dataRowTime;
			if (this.maxTimeDomainValue! - this.dataIntervalSec > 0) {
				this.minTimeDomainValue = this.maxTimeDomainValue! - this.dataIntervalSec;
			}
		}
	}

	private updateGraphs(data) {
		const time = data[0][3];
		if (this.lastRecordedTime === null || time > this.lastRecordedTime!) {
			this.updateTemplateDataOverTime(time);
			this.addNewGraphs(data);
			this.removeOldGraphs(data);
		}
	}

	private drawInitialEmptyGraph() {
		this.updateGraphs([[-1, null, null, null]]);
	}

	private resetZoom(zoomBaseElem, zoom) {
		zoomBaseElem
		.transition()
		.duration(750)
		.call(zoom.transform, d3.zoomIdentity);
	}

	private resetAllGraphsZoom() {
		Object.keys(this.graphs).forEach(
			(targetID) => {
				let targetGraphD3 = this.graphs[targetID].d3;
				this.resetZoom(targetGraphD3.zoomBaseElem, targetGraphD3.zoom);
			}
		);
	}

	private updateMultipleData(targetID, data) {
		if (data.length === 1) {
			// We get one frame per target ID on data request (regular mode)
			this.drawGraph(data[0]);
		} else {
			// We get multiple frames (history) sent per targetID on data request
			let breathingDataRow = data[data.length - 1];
			const {targetGraph} = this.updateGraphData(breathingDataRow);
			targetGraph.breathingDataOverTime.pop();

			data.forEach(targetData => {
				this.prepareDataOverTime(targetData, targetGraph.breathingDataOverTime, targetGraph);
			});

			if (breathingDataRow[3] >= this.maxTimeDomainValue!) {
				// Remove data from the beginning of targetGraph.breathingDataOverTime (only if it has more than 1 row)
				if (targetGraph.breathingDataOverTime.length > data.length) {
					for (let i = 1; i < data.length; i++) {
						targetGraph.breathingDataOverTime.shift();
					}
				}
			}

			// Update measurement
			if (this.isMeasurementEnabled) {
				targetGraph.d3.measurement.text(this.getMeasText(targetGraph, breathingDataRow));
			}

			LineGraphUtils.rescaleYInCaseMaxOrMinHaveChanged(targetGraph.d3, targetGraph.d3.minYValue, targetGraph.d3.maxYValue);

			// Update graph path
			this.updateGraphPath(targetGraph, targetID);
		}
	}
}
