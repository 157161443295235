import {Component, OnInit} from '@angular/core';

/**
 * Component represents a small block with loading animation (pulsing circles) inside.
 * Used in SensorConnectorComponent (BaseViewComponent right top).
 */
@Component({
  selector: 'app-loading-small',
  templateUrl: './loading-small.component.html',
  styleUrls: ['./loading-small.component.scss']
})
export class LoadingSmallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
