import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SensorsService} from '../../../../../services/system/sensors.service';
import {getLayerCfgName} from 'src/app/utils/utils';
import {ModalService} from 'src/app/services/modal.service';
import {InCarOccupancyBaseComponent} from '../base-classes/in-car-occupancy-base/in-car-occupancy-base.component';

/**
 * seatIndications data structure
 	[
		seat_i, = 0
		seat_x_location_i, = 1
		seat_y_location_i, = 2
		seat_z_location_i, = 3
		seat_x_length_i, = 4
		seat_y_length_i, = 5
		indication_type, = 6
		is_monitored = 7
	]
 */

/**
 * Component renders 2D image of car with passengers inside.
 * Used for InCar app.
 */
@Component({
	selector: 'app-in-car-occupancy',
	templateUrl: './in-car-occupancy.component.html',
	styleUrls: ['./in-car-occupancy.component.scss']
})
export class InCarOccupancyComponent extends InCarOccupancyBaseComponent implements OnInit, OnDestroy, OnChanges {

	Boolean = Boolean;

	sbrOrCpd = 'sbr';

	seatBeltsBuckledCount = 8;
	seatBeltBuckled1 = false;
	seatBeltBuckled2 = false;
	seatBeltBuckled3 = false;
	seatBeltBuckled4 = false;
	seatBeltBuckled5 = false;
	seatBeltBuckled6 = false;
	seatBeltBuckled7 = false;
	seatBeltBuckled8 = false;

	doorsCount = 4;
	doorOpen1 = false;
	doorOpen2 = false;
	doorOpen3 = false;
	doorOpen4 = false;

	ignitionSwitch = false;

	drivingSpeed = 0;

	showSbrOrCpd = false;
	showSeatBeltsBuckled = true;
	showDoorsOpen = true;
	showIgnitionSwitch = false;
	showDrivingSpeed = false;

	enableSbrOrCpd = true;
	enableSeatBeltsBuckled = true;
	enableDoorsOpen = true;
	enableIgnitionSwitch = true;
	enableDrivingSpeed = true;

	public seatBeltsBuckledGrid: number[][] = [];
	public doorsGrid: number[][] = [[1, 2], [3, 4]];

	private isSeatBeltsBuckledGridGenerated = false;

	private configurableParameters = [
		'sbrOrCpd',
		'seatBeltBuckled1', 'seatBeltBuckled2', 'seatBeltBuckled3', 'seatBeltBuckled4', 'seatBeltBuckled5', 'seatBeltBuckled6', 'seatBeltBuckled7', 'seatBeltBuckled8',
		'doorOpen1', 'doorOpen2', 'doorOpen3', 'doorOpen4',
		'drivingSpeed',
		'showSbrOrCpd',
		'showSeatBeltsBuckled',
		'showDoorsOpen',
		'ignitionSwitch',
		'showIgnitionSwitch',
		'showDrivingSpeed',
		'enableSbrOrCpd',
		'enableSeatBeltsBuckled',
		'enableDoorsOpen',
		'enableIgnitionSwitch',
		'enableDrivingSpeed',
	];

	constructor(protected sensorsService: SensorsService,
				protected modalService: ModalService) {
		super(sensorsService, modalService);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	ngOnChanges(c: SimpleChanges) {
		if (('parameters' in c || 'metadata' in c || 'cfgDict' in c) && this.parameters) {
			this.configurableParameters.forEach(parameter => {
				if (this.metadata.names[parameter]) {
					// Initialize this[parameter] with the default value
					this[parameter] = this.cfgDict[this.metadata.names[parameter]];
					if (getLayerCfgName(this.metadata.names[parameter]) in this.parameters['sensorParameters']) {
						let valueFromGetParamsReq = this.parameters['sensorParameters'][getLayerCfgName(this.metadata.names[parameter])];
						if (valueFromGetParamsReq !== null) { // Assign this[parameter] with the value received from the EVK
							this[parameter] = valueFromGetParamsReq;
						}
					}
				}
			});
		}

		super.ngOnChanges(c);

		if ('data' in c) {
			/**
			 * In CPD mode, layer shows disabled gray-out car without targets.
			 * To avoid displaying non-relevant view after switching to sbr mode
			 * until new data in this sbr mode come, we removing this data completely.
			 */
			if (this.inCPDMode()) {
				delete this.localData.seatIndications;
			}
			if (this.localData && this.localData.seatIndications) {
				this.updateBuckledSeatsGrids(this.localData.seatIndications);
			}
		}
	}

	updateBuckledSeatsGrids(seatIndications) {
		if (!this.isSeatBeltsBuckledGridGenerated) {
			let result = {};
			seatIndications.forEach((data) => {
				const positionY = data[2];
				if (!result[positionY]) {
					result[positionY] = [];
				}
				result[positionY].push(data[7] ? data[0] : null);
			});
			this.seatBeltsBuckledGrid = Object.values(result);
			this.isSeatBeltsBuckledGridGenerated = true;
		}
	}

	updateMode() {
		let newValue = this.inCPDMode() ? 'sbr' : 'cpd',
			parameters = {
				[getLayerCfgName(this.metadata.names.sbrOrCpd)]: newValue
			};
		this.sbrOrCpd = newValue;
		this.sensorsService.sendSettings(this.sensorSocket, true, parameters);
	}

	updateIgnition() {
		let parameters = {
			[getLayerCfgName(this.metadata.names.ignitionSwitch)]: this.ignitionSwitch
		};
		this.sensorsService.sendSettings(this.sensorSocket, true, parameters);
	}

	updateDrivingSpeed() {
		let parameters = {
			[getLayerCfgName(this.metadata.names.drivingSpeed)]: this.drivingSpeed
		};
		this.sensorsService.sendSettings(this.sensorSocket, true, parameters);
	}

	updateseatBeltsBuckled(i) {
		this[`seatBeltBuckled${i}`] = !this[`seatBeltBuckled${i}`];
		let parameters = {};
		for (let index = 0; index < this.seatBeltsBuckledCount; index++) {
			parameters[getLayerCfgName(this.metadata.names[`seatBeltBuckled${index + 1}`])] = this[`seatBeltBuckled${index + 1}`] || false;
		}
		this.sensorsService.sendSettings(this.sensorSocket, true, parameters);
	}

	updatedoorsOpen(i) {
		this[`doorOpen${i}`] = !this[`doorOpen${i}`];
		this.sendDoorsStateToSocket();
	}

	closeAllDoors() {
		for (let i = 0; i < this.doorsCount; i++) {
			this[`doorOpen${i + 1}`] = false;
		}
		this.sendDoorsStateToSocket();
	}

	inCPDMode() {
		return this.sbrOrCpd === 'cpd';
	}

	protected updateSensor(status) {
		if (status === 'CONFIGURING') {
			this.isSeatBeltsBuckledGridGenerated = false;
		}
		super.updateSensor(status);
	}

	private sendDoorsStateToSocket() {
		let parameters = {};

		for (let i = 0; i < this.doorsCount; i++) {
			parameters[getLayerCfgName(this.metadata.names[`doorOpen${i + 1}`])] = this[`doorOpen${i + 1}`] || false;
		}
		this.sensorsService.sendSettings(this.sensorSocket, true, parameters);
	}
}
