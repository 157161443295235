<div class="d-flex">
	<div class="w-100" [ngStyle]="{'margin-right.px': environment.offline ? 14 : 0 }">
		<div class="notifications-block d-flex justify-content-center">
				<span
					class="notifications-block__quantity fw-bold fs-42 d-flex align-items-center">{{toolbarService.getConnectedSensors()}}</span>
			<span class="notifications-block__text connected-sensors d-flex fs-16 align-items-center fw-bold"
				  [innerHTML]="'CONNECTED_SENSORS' | translate | htmlNewLine"></span>
		</div>

	</div>
</div>
