<app-loading-small *ngIf="getConnectionStatus() === ConnectionStatus.CONNECTING"></app-loading-small>

<!--<button *ngIf="isNetworkDisconnectedIconShown()"-->
<!--		class="showDisconnected-popup"-->
<!--		mat-raised-button-->
<!--		(click)="showNetworkDisconnectedPopup()"></button>-->

<div class="connect-group">
	<button class="ip-btn"
			[matTooltip]="connectedSensorName || ip"
			(click)="showConnectionPopup()"
			data-selector="sensor-box-name"
	><span>{{connectedSensorName || ip}}</span></button>
	<button class="connect-btn"
			data-selector="connect-button"
			(click)="onConnectClick()"
			[ngClass]="{
				'sensor-to-disconnect': canDisconnect(),
				'sensor-to-connect': canConnect()
			}"
	>{{canDisconnect() ? 'Disconnect' : 'Connect'}}</button>
</div>

<span class="connect-text"
	  [ngClass]="{
	  		'connect-text-disconnected': canConnect(),
	   		'connect-text-connected': canDisconnect(),
			'connect-text-error': canConnect()}"
	  data-selector="sensor-box-connect-status">
	{{connectionStatus | translate}}
</span>

<app-connection-list *ngIf="isConnectionPopupShowed"
					 class="hide-title-bg"
					 [sensorSocket]="connection"
					 [showSocketList]="showSocketList"
					 (close)="hideConnectionPopup()"
					 (connect)="onConnectInConnectionList($event)"
></app-connection-list>
<!--(update)="update()"-->

<div *ngIf="isNetworkDisconnectedPopupShowed"
	 class="disconnected-popup">
	<div class="disconnected-popup-content">
		<div class="disconnected-popup-description">
			<p>{{'NETWORK_ENGINE_DISCONNECTED' | translate}}</p>
			<ul>
				<li>{{'HELP_MESSAGE_FIRST' | translate}}</li>
				<li>{{'HELP_MESSAGE_SECOND' | translate}}</li>
			</ul>
		</div>
		<div>
			<img src="assets/images/svg/Sensor-connected/Network-disconnected-image.svg"
				 alt="">
		</div>
		<button class="disconnected-popup-button-close"
				(click)="hideNetworkDisconnectedPopup()"></button>
	</div>
</div>
