import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {BaseLineGraphComponent} from '../../../../../../components/layers/base-classes/base-line-graph.component';

/**
 * Component renders graph.
 */
@Component({
	selector: 'app-activity',
	templateUrl: './activity.component.html',
	styleUrls: ['./activity.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityComponent extends BaseLineGraphComponent {
	constructor(public injector: Injector) {
		super(injector);
	}
}
