<div class="buttons">
	<ng-content></ng-content>
</div>
<span class="title">{{'AC monitoring' | translate}}</span>
<form [formGroup]="form">
	<ng-container *ngFor="let device of form.get('devices')!['controls']">
		<ng-container *ngIf="device.get('temperature')">
			<span class="temperature">{{device.get('temperature').value}} °F</span>
			<app-slide-toggle [formControl]="device.get('status')" (change)="updateDevice(device, $event)"></app-slide-toggle>
		</ng-container>
	</ng-container>
</form>
