<div class="modal-default" data-selector="popup_message">
	<h3 [innerHTML]="data.title | translate:data.translateData | htmlNewLine"></h3>
	<div class="message" perfectScrollbar [disabled]="disabledScroll">
		<ng-container>
			<p [innerHTML]="data.message | translate:data.translateData | htmlNewLine | safe:'html'"></p>
			<div class="input">
			<input #input type="number"
				   autocomplete="off"
				   name="input"
			[(ngModel)]="value">
			</div>
		</ng-container>
	</div>
	<div class="default-panel">
		<button mat-button (click)="reject()" class="g-btn reject float-left">
			{{(data.cancelText || 'Cancel') | translate}}
		</button>
		<button mat-button (click)="resolve()" class="g-btn reject button-right">
			{{(data.okText || 'OK') | translate}}
		</button>
	</div>
</div>
