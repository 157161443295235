import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {originPosition} from '../../../../../utils/utils';
import {Layer} from 'src/app/services/configuration.service';

/**
 * Component can render heatmap or/and positions of people.
 * Used for InCar, Tracker app.
 */
@Component({
	selector: 'app-sensor-tracker-two-d-raw-view',
	templateUrl: './sensor-tracker-2D-raw-view.component.html',
	styleUrls: ['./sensor-tracker-2D-raw-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SensorTracker2DRawViewComponent {

	@Input() data: {
		data?: Array<Array<number>>;
		locationData?: Array<Array<number>>;
		targetsIdData?: Array<number>;
		postureData?: Array<string>;
		RetailAreaNames?: Array<string>;
		RetailAreas?: Array<Array<number | 'NaN'>>;
		InCarCabinVertexs?: Array<number>;
	} = {};
	@Input() isRaw = false;
	@Input() parameters: any = {};
	@Input() layer: Layer;
	@Input() rotate = 0;
	@Input() reflect = false;
	@Input() postureValue = false;
	@Input() ruler = false;
	@Input() isFloorPlan = false;
	@Input() isSensorVisible = true;
	@Input() axesEnabled = true;
	@Input() originPosition: originPosition = 'leftTop';
}
