import {PointCloudStage_InCarBase} from '../../../../../base-classes/PointCloudStage_InCarBase';
import {scalePoints} from '../../../../../utils/IsoUtils';
import {resources} from '../../../../../utils/ResourcesLoader';

declare const require: any;
const THREE = require('three');

const CAR_MATERIAL = new THREE.MeshLambertMaterial({
	color: 0xc0c0c0,
	transparent: true,
	opacity: 0.5,
});
const CAR_SCALE_FACTOR = 17;
const CAR_FLOOR_COLOR = '0xd3d3d3';

/**
 * Used as scene renderer in CarCloudPoint layer component.
 */
export class PointCloudStage_InCar_Car extends PointCloudStage_InCarBase {

	isLoading = true;

	constructor(div, arena, status, parameters, isRaw) {
		super(div, arena, status, parameters, false, isRaw, 'white');
		this.initCamera();
		this.addCar();
		this.addCabinPlanes();
		this.updateSceneOnConfiguring();
	}

	addCabinPlanes() {
		const {
			x_midFrontPlane,
			x_midBackPlane,
			z_frontSeatMidPlane,
			z_backSeatMidLeftPlane,
			z_backSeatMidRightPlane
		} = this.inCarCabinPlanes;
		this.mainGroup.add(
			x_midFrontPlane,
			x_midBackPlane,
			z_frontSeatMidPlane,
			z_backSeatMidLeftPlane,
			z_backSeatMidRightPlane);
	}

	updateSceneOnConfiguring() {
		if (this.status !== 'IMAGING') {
			this.updateCar();
			this.updateDefaultCabinAccordingToArena(this.getLiftAboveGround(this.arena));
		}
	}

	updateCar(arena = this.arena) {
		if (this.car && this.inCarCabinVertexs) {
			const leftBottom = this.inCarCabinVertexs[0];
			const rightBottom = this.inCarCabinVertexs[1];
			const rightTop = this.inCarCabinVertexs[2];

			const inCarCabinFrontAndBackPlaneWidth = (rightBottom[0] - leftBottom[0]);
			const inCarCabinRightAndLeftPlaneWidth = (rightTop[1] - rightBottom[1]);
			const scaleFactor = {
				x: inCarCabinFrontAndBackPlaneWidth,
				y: arena[5],
				z: inCarCabinRightAndLeftPlaneWidth
			};
			this.car.scale.set(
				CAR_SCALE_FACTOR * scaleFactor.x,
				CAR_SCALE_FACTOR * scaleFactor.y,
				CAR_SCALE_FACTOR * scaleFactor.z
			);
			const {aboveGround} = this.getCabinDefaultDims(this.getLiftAboveGround(this.arena));
			this.car.position.set(0, aboveGround, 0);
			this.isCarUpdatedOnImaging = this.status === 'IMAGING';
		}
	}

	addCar() {
		const scaleFactor = {
			x: this.scale[0] / 100,
			y: this.arena[5],
			z: this.scale[2] / 100
		};

		const pointCloudStage = this;

		resources['Car'].get().then(gltf => {
			// Sensor disconnected, for example
			if (!pointCloudStage.arena) {
				return;
			}
			const object = gltf.scene.getObjectByName('BodyKRZ1').clone();
			const {aboveGround} = this.getCabinDefaultDims(pointCloudStage.getLiftAboveGround(pointCloudStage.arena));
			object.position.set(0, aboveGround, 0);
			object.scale.set(
				CAR_SCALE_FACTOR * scaleFactor.x,
				CAR_SCALE_FACTOR * scaleFactor.y,
				CAR_SCALE_FACTOR * scaleFactor.z
			);
			pointCloudStage.car = object;
			pointCloudStage.mainGroup.add(object);
			object.traverse(function (child) {
				if (child.isMesh) {
					child.castShadow = child.receiveShadow = true;
					child.material = CAR_MATERIAL;
				}
			});
			this.isLoading = false;
			if (this.isInitializedWithRealArenaSettings) {
				this.updateCar();
			}
		});
	}

	updateCarAndCabinOnImaging(arena, frontMostOfRear_rearMostOfFront, inCarCabinVertexs) {
		let isMultipleSensors = arena[0] instanceof Array;
		this.updateScene(arena, isMultipleSensors ? this.connectedSensorsParameters : this.parameters);
		this.updateCar();
		this.updateSensor(isMultipleSensors ? this.connectedSensorsParameters : this.parameters);
		this.updateInCarCabin(frontMostOfRear_rearMostOfFront, inCarCabinVertexs);
	}

	updateOnInit(data, arena, inCarCabinVertexs, frontMostOfRear_rearMostOfFront, isRaw, isSensorVisible) {
		this.isSensorVisible = isSensorVisible;
		this.status = 'IMAGING';
		this.arena = arena;
		this.inCarCabinVertexs = inCarCabinVertexs;
		this.updateData(data, isRaw);
		if (frontMostOfRear_rearMostOfFront && inCarCabinVertexs) {
			this.updateCarAndCabinOnImaging(arena, frontMostOfRear_rearMostOfFront, inCarCabinVertexs);
		}

		if (isSensorVisible) {
			if (!this.mainGroup.children.includes(this.sensor)) {
				this.mainGroup.add(this.sensor);
			}
		} else {
			this.mainGroup.remove(this.sensor);
		}
	}

	/**
	 * This function is the main function of this class.
	 * It updates the point cloud in the arena according to data given from Matlab.
	 * We're giving the user the control over showing shadows and targets` centers
	 */
	update(data, arena, status, inCarCabinVertexs, frontMostOfRear_rearMostOfFront, isRaw, isSensorVisible) {
		let mainArena = arena;
		if (arena[0] instanceof Array) {
			mainArena = arena[0];
		}
		this.oldStatus = this.status;
		this.status = status;
		this.oldArena = this.arena;
		this.arena = mainArena;
		this.isSensorVisible = isSensorVisible;
		this.inCarCabinVertexs = inCarCabinVertexs;
		if (status === 'IMAGING' && data) {
			this.updateData(data, isRaw);

			if (frontMostOfRear_rearMostOfFront && inCarCabinVertexs) {
				if (!(this.isCarUpdatedOnImaging && this.isCabinUpdatedOnImaging)) {
					// Update car and cabin on imaging only once
					this.updateCarAndCabinOnImaging(arena, frontMostOfRear_rearMostOfFront, inCarCabinVertexs);
				}
			}
		}
		// Update on changing status - For instance from "CONFIGURING" to "IMAGING"
		if (this.oldStatus !== this.status) {
			this.updateStatus(this.status);
			if (this.status === 'CONFIGURING') {
				this.isCarUpdatedOnImaging = false;
				this.isCabinUpdatedOnImaging = false;
			}
		}
		this.updateSensorVisible(isSensorVisible);
	}

	updateData(data, isRaw) {
		// In case we got one point which is stripped from the wrapper array
		this.data = data && data.length && !data[0].length ? [data] : data;
		this.isRaw = isRaw;
		if (this.isRaw) {
			scalePoints(this.data || []);
		}
	}

	initCamera() {
		this.camera.position.set(-this.scale[0] * 1, this.scale[1] * 2, -this.scale[2] * 1);
		this.camera.lookAt(0, 0, 0);
	}

	updateInCarCabin(frontMostOfRear_rearMostOfFront, InCarCabinVertexs) {
		this.updateCabinInnerPlanes(frontMostOfRear_rearMostOfFront, InCarCabinVertexs);
		this.updateCarSeatsIndexes(InCarCabinVertexs);
		this.isCabinUpdatedOnImaging = this.status === 'IMAGING';
	}

	getCabinDefaultDims(liftAboveGround) {
		const defaultMargin = 15; // This should come from Matlab...
		const inCarCabinFrontAndBackPlaneWidth = this.scale[0] - defaultMargin * 2;
		const inCarCabinRightAndLeftPlaneWidth = this.scale[2] - defaultMargin * 2;
		const aboveGround = this.FLOOR_HEIGHT;
		const thickness = 3;
		const inCarPlaneHeight = 1;
		return {
			defaultMargin,
			inCarCabinFrontAndBackPlaneWidth,
			inCarCabinRightAndLeftPlaneWidth,
			aboveGround,
			thickness,
			inCarPlaneHeight,
		};
	}

	updateDefaultCabinAccordingToArena(liftAboveGround) {
		const {
			defaultMargin,
			inCarCabinFrontAndBackPlaneWidth,
			inCarCabinRightAndLeftPlaneWidth,
			aboveGround,
			thickness,
			inCarPlaneHeight,
		} = this.getCabinDefaultDims(liftAboveGround);
		// Inner planes
		this.updateDefaultCabinInner(
			inCarCabinFrontAndBackPlaneWidth,
			inCarCabinRightAndLeftPlaneWidth,
			aboveGround,
			inCarPlaneHeight,
			thickness
		);
		this.addCarSeatsIndexes(inCarCabinFrontAndBackPlaneWidth, inCarCabinRightAndLeftPlaneWidth, defaultMargin);
	}

	getFloorColor(isTextLayerColor = false) {
		let color: any = null;
		if (isTextLayerColor) {
			color = CAR_FLOOR_COLOR;
			color = color.toString();
			color = color.substring(2, color.length);
			return '#' + color;
		}
		return parseInt(CAR_FLOOR_COLOR, 16);
	}
}
