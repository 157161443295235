import {PointCloudStage_InCar_Car} from '../car-point-cloud/PointCloudStage_InCar_Car';

/**
 * Used as scene renderer in SsrCloudPoint layer component.
 */
export class PointCloudStage_SSR_Car extends PointCloudStage_InCar_Car {

	showFloor = false;
	isLoading = true;

	constructor(div, arena, status, parameters, isRaw) {
		super(div, arena, status, parameters, isRaw);
	}

	addCarSeatsIndexes(inCarCabinFrontAndBackPlaneWidth, inCarCabinRightAndLeftPlaneWidth, defaultMargin) {
	}

	updateCarSeatsIndexes(InCarCabinVertexs) {
	}

	addCabinPlanes() {
	}

	createCabinPlanes() {
	}

	updateDefaultCabinInner() {
	}

	updateCabinInnerPlanes(frontMostOfRear_rearMostOfFront, InCarCabinVertexs) {
	}

	updateInCarCabin(frontMostOfRear_rearMostOfFront, InCarCabinVertexs) {
	}

	updateDefaultCabinAccordingToArena(liftAboveGround) {
	}
}
