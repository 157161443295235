<div class="buttons">
	<ng-content></ng-content>
</div>
<app-layer-controls
	[opened]="true"
	[hideViewButton]="true"
	[hideFlipButton]="true"
	[hideResetViewButton]="true"
	[hideRulerButton]="true"
	[hideSensitivityButton]="true"
	[hideSensorButton]="true"
	[isViewDisabled]="true"
	[isRotateEnable]="true"
	[rotateValue]="conversionComponent?.rotationDegree === 90 ? 3 : conversionComponent?.rotationDegree === 180 ? 2 : conversionComponent?.rotationDegree === 270 ? 1 : 0"
	(rotate)="conversionComponent?.rotate(90)"
></app-layer-controls>
<div class="wrap">
	<app-floor-plan-layer #conversionComponent *ngIf="selectedGraph?.type === 'conversion'"
						  [data]="data[selectedGraph?.page]"
						  [isSensorVisible]="false"
						  [showTargets]="false"
						  [showHeatMap]="false"
						  [showMenu]="false"
						  [geoFences]="geoFences"
						  [isGeoFenceEditable]="false"
						  [showGeoFenceConversion]="true"
						  [heatmapColors]="'cold'"
						  [parameters]="sensorSocketSettings"
						  (rendered)="onRendered()">
	</app-floor-plan-layer>
	<app-loading *ngIf="busEventService.restIsLoading | async" type="indeterminate" size="800"></app-loading>
</div>
