import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

/**
 * Component represents a modal window with title, subtitle and set of buttons as parameters.
 * Buttons are showed in vertical line.
 */
@Component({
	selector: 'app-flow-modal',
	templateUrl: './flow-modal.component.html',
	styleUrls: ['./flow-modal.component.scss']
})
export class FlowModalComponent implements OnInit {

	doNotShow = false;

	constructor(private dialogRef: MatDialogRef<FlowModalComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit() {
	}

	cancel() {
		this.dialogRef.close();
	}

	buttonClick(key) {
		this.data.callback(key);
	}

	doNotShowChange() {
		this.data.callback('doNotShowChange', !this.doNotShow);
	}
}
