<div class="buttons">
	<ng-content></ng-content>
</div>
<div class="host__wrap">
	<ng-container *ngIf="!data?.Tables">
		<h3 class="empty-title">Please start imaging in order to see shopper insights</h3>
	</ng-container>
	<ng-container *ngIf="data?.Tables">
		<h3>{{data?.Tables?.numOfPeopleLive?.name}}</h3>
		<table class="host__table w-100" #t1>
			<thead>
			<tr>
				<th [ngStyle]="{'width.px': t1.clientWidth * (100 / numberOfColumns('numOfPeopleLive')) / 100 - (hiddenColumns.numOfPeopleLive.length - numberOfColumns('numOfPeopleLive')) * 50}">
					<div class="d-flex align-items-center">
						<span class="column-name">{{data?.Tables?.numOfPeopleLive?.columns[0]}}</span>
						<div class="sort-icon__wrap pointer" (click)="onSort('numOfPeopleLive', 'Name')">
								<span class="sort-icon-top"
									  [class.selected]="sort['numOfPeopleLive'].currentSortingColumn === 'Name' && sort['numOfPeopleLive'].currentSortingDirection === 1"></span>
							<span class="sort-icon-bot"
								  [class.selected]="sort['numOfPeopleLive'].currentSortingColumn === 'Name' && sort['numOfPeopleLive'].currentSortingDirection === -1"></span>
						</div>
					</div>
				</th>
				<th [ngStyle]="{'width.px': hiddenColumns.numOfPeopleLive[1] ? 50 : t1.clientWidth * (100 / numberOfColumns('numOfPeopleLive')) / 100 - (hiddenColumns.numOfPeopleLive.length - numberOfColumns('numOfPeopleLive')) * 50}">
					<div class="d-flex align-items-center">
						<ng-container *ngIf="!hiddenColumns.numOfPeopleLive[1]">
							<span class="column-name">{{data?.Tables?.numOfPeopleLive?.columns[1]}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('numOfPeopleLive', 'Standing')">
									<span class="sort-icon-top"
										[class.selected]="sort['numOfPeopleLive'].currentSortingColumn === 'Standing' && sort['numOfPeopleLive'].currentSortingDirection === 1"></span>
								<span class="sort-icon-bot"
									[class.selected]="sort['numOfPeopleLive'].currentSortingColumn === 'Standing' && sort['numOfPeopleLive'].currentSortingDirection === -1"></span>
							</div>
						</ng-container>
						<div class="hide-icon__wrap pointer" (click)="onToggleColumn('numOfPeopleLive', 1)">
							<span class="hide-icon-left" *ngIf="!hiddenColumns.numOfPeopleLive[1]" [matTooltip]="hideColumnTitle"></span>
							<span class="hide-icon-right selected" *ngIf="hiddenColumns.numOfPeopleLive[1]" [matTooltip]="showColumnTitle"></span>
						</div>
					</div>
				</th>
				<th [ngStyle]="{'width.px': hiddenColumns.numOfPeopleLive[2] ? 50 : t1.clientWidth * (100 / numberOfColumns('numOfPeopleLive')) / 100 - (hiddenColumns.numOfPeopleLive.length - numberOfColumns('numOfPeopleLive')) * 50}">
					<div class="d-flex align-items-center">
						<ng-container *ngIf="!hiddenColumns.numOfPeopleLive[2]">
							<span class="column-name">{{data?.Tables?.numOfPeopleLive?.columns[2]}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('numOfPeopleLive', 'PassingThrough')">
									<span class="sort-icon-top"
										[class.selected]="sort['numOfPeopleLive'].currentSortingColumn === 'PassingThrough' && sort['numOfPeopleLive'].currentSortingDirection === 1"></span>
								<span class="sort-icon-bot"
									[class.selected]="sort['numOfPeopleLive'].currentSortingColumn === 'PassingThrough' && sort['numOfPeopleLive'].currentSortingDirection === -1"></span>
							</div>
						</ng-container>
						<div class="hide-icon__wrap pointer" (click)="onToggleColumn('numOfPeopleLive', 2)">
							<span class="hide-icon-left" *ngIf="!hiddenColumns.numOfPeopleLive[2]" [matTooltip]="hideColumnTitle"></span>
							<span class="hide-icon-right selected" *ngIf="hiddenColumns.numOfPeopleLive[2]" [matTooltip]="showColumnTitle"></span>
						</div>
					</div>
				</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngIf="data?.Arena">
				<td>{{data?.Tables?.numOfPeopleLive?.rows[0]}}</td>
				<td>
					<ng-container *ngIf="!hiddenColumns.numOfPeopleLive[1]">
						<div class="empty-cell"></div>
					</ng-container>
				</td>
				<td><ng-container *ngIf="!hiddenColumns.numOfPeopleLive[2]">{{data.Arena.numOfPeopleLive | number:'1.0-0'}}</ng-container></td>
			</tr>
			<tr *ngFor="let item of getMonitoringItems('numOfPeopleLive'); let i = index">
				<td>{{item['Name']}}</td>
				<td>
					<ng-container *ngIf="!hiddenColumns.numOfPeopleLive[1]">
						{{item['Standing'] | number:'1.0-0'}}
					</ng-container>
				</td>
				<td>
					<ng-container *ngIf="!hiddenColumns.numOfPeopleLive[2]">
						{{item['PassingThrough'] | number:'1.0-0'}}
					</ng-container>
				</td>
			</tr>
			<tr *ngIf="!data?.Registers?.numOfPeopleLive?.length">
				<td class="no-data" colspan="3">No data</td>
			</tr>
			</tbody>
		</table>

		<h3>{{data?.Tables?.numOfPeopleTotal?.name}}</h3>
		<table class="host__table w-100" #t2>
			<thead>
				<tr>
				<th [ngStyle]="{'width.px': t2.clientWidth * (100 / numberOfColumns('numOfPeopleTotal')) / 100 - (hiddenColumns.numOfPeopleTotal.length - numberOfColumns('numOfPeopleTotal')) * 50}">
					<div class="d-flex align-items-center">
						<span class="column-name">{{data?.Tables?.numOfPeopleTotal?.columns[0]}}</span>
						<div class="sort-icon__wrap pointer" (click)="onSort('numOfPeopleTotal', 'Name')">
							<span class="sort-icon-top"
								  [class.selected]="sort['numOfPeopleTotal'].currentSortingColumn === 'isConnected' && sort['numOfPeopleTotal'].currentSortingDirection === 1"></span>
							<span class="sort-icon-bot"
								  [class.selected]="sort['numOfPeopleTotal'].currentSortingColumn === 'isConnected' && sort['numOfPeopleTotal'].currentSortingDirection === -1"></span>
						</div>
					</div>
				</th>
				<th [ngStyle]="{'width.px': hiddenColumns.numOfPeopleTotal[1] ? 50 : t2.clientWidth * (100 / numberOfColumns('numOfPeopleTotal')) / 100 - (hiddenColumns.numOfPeopleTotal.length - numberOfColumns('numOfPeopleTotal')) * 50}">
					<div class="d-flex align-items-center">
						<ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[1]">
							<span class="column-name">{{data?.Tables?.numOfPeopleTotal?.columns[1]}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('numOfPeopleTotal', 'Standing')">
								<span class="sort-icon-top"
									[class.selected]="sort['numOfPeopleTotal'].currentSortingColumn === 'Standing' && sort['numOfPeopleTotal'].currentSortingDirection === 1"></span>
								<span class="sort-icon-bot"
									[class.selected]="sort['numOfPeopleTotal'].currentSortingColumn === 'Standing' && sort['numOfPeopleTotal'].currentSortingDirection === -1"></span>
							</div>
						</ng-container>
						<div class="hide-icon__wrap pointer" (click)="onToggleColumn('numOfPeopleTotal', 1)">
							<span class="hide-icon-left" *ngIf="!hiddenColumns.numOfPeopleTotal[1]" [matTooltip]="hideColumnTitle"></span>
							<span class="hide-icon-right selected" *ngIf="hiddenColumns.numOfPeopleTotal[1]" [matTooltip]="showColumnTitle"></span>
						</div>
					</div>
				</th>
				<th [ngStyle]="{'width.px': hiddenColumns.numOfPeopleTotal[2] ? 50 : t2.clientWidth * (100 / numberOfColumns('numOfPeopleTotal')) / 100 - (hiddenColumns.numOfPeopleTotal.length - numberOfColumns('numOfPeopleTotal')) * 50}">
					<div class="d-flex align-items-center">
						<ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[2]">
							<span class="column-name">{{data?.Tables?.numOfPeopleTotal?.columns[2]}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('numOfPeopleTotal', 'PassingThrough')">
								<span class="sort-icon-top"
									[class.selected]="sort['numOfPeopleTotal'].currentSortingColumn === 'PassingThrough' && sort['numOfPeopleTotal'].currentSortingDirection === 1"></span>
								<span class="sort-icon-bot"
									[class.selected]="sort['numOfPeopleTotal'].currentSortingColumn === 'PassingThrough' && sort['numOfPeopleTotal'].currentSortingDirection === -1"></span>
							</div>
						</ng-container>
						<div class="hide-icon__wrap pointer" (click)="onToggleColumn('numOfPeopleTotal', 2)">
							<span class="hide-icon-left" *ngIf="!hiddenColumns.numOfPeopleTotal[2]" [matTooltip]="hideColumnTitle"></span>
							<span class="hide-icon-right selected" *ngIf="hiddenColumns.numOfPeopleTotal[2]" [matTooltip]="showColumnTitle"></span>
						</div>
					</div>
				</th>
				<th [ngStyle]="{'width.px': hiddenColumns.numOfPeopleTotal[3] ? 50 : t2.clientWidth * (100 / numberOfColumns('numOfPeopleTotal')) / 100 - (hiddenColumns.numOfPeopleTotal.length - numberOfColumns('numOfPeopleTotal')) * 50}">
					<div class="d-flex align-items-center">
						<ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[3]">
							<span class="column-name">{{data?.Tables?.numOfPeopleTotal?.columns[3]}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('numOfPeopleTotal', 'Conversion')">
								<span class="sort-icon-top"
									[class.selected]="sort['numOfPeopleTotal'].currentSortingColumn === 'Conversion' && sort['numOfPeopleTotal'].currentSortingDirection === 1"></span>
								<span class="sort-icon-bot"
									[class.selected]="sort['numOfPeopleTotal'].currentSortingColumn === 'Conversion' && sort['numOfPeopleTotal'].currentSortingDirection === -1"></span>
							</div>
						</ng-container>
						<div class="hide-icon__wrap pointer" (click)="onToggleColumn('numOfPeopleTotal', 3)">
							<span class="hide-icon-left" *ngIf="!hiddenColumns.numOfPeopleTotal[3]" [matTooltip]="hideColumnTitle"></span>
							<span class="hide-icon-right selected" *ngIf="hiddenColumns.numOfPeopleTotal[3]" [matTooltip]="showColumnTitle"></span>
						</div>
					</div>
				</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngIf="data?.Arena">
				<td>{{data?.Tables?.numOfPeopleTotal?.rows[0]}}</td>
				<td>
					<ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[1]">
						<div class="empty-cell"></div>
					</ng-container>
				</td>
				<td>
					<ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[2]">
						{{data.Arena.numOfPeopleTotal | number:'1.0-0'}}
					</ng-container>
				</td>
				<td>
					<ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[3]">
						<div class="empty-cell"></div>
					</ng-container>
				</td>
			</tr>
			<tr *ngFor="let item of getMonitoringItems('numOfPeopleTotal'); let i = index">
				<td>{{item['Name']}}</td>
				<td><ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[1]">{{item['Standing'] | number:'1.0-0'}}</ng-container></td>
				<td><ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[2]">{{item['PassingThrough'] | number:'1.0-0'}}</ng-container></td>
				<td><ng-container *ngIf="!hiddenColumns.numOfPeopleTotal[3]">{{item['Conversion'] | number:'1.0-2'}}%</ng-container></td>
			</tr>
			<tr *ngIf="!data?.Registers?.numOfPeopleTotal?.length">
				<td class="no-data" colspan="4">No data</td>
			</tr>
			</tbody>
		</table>

		<h3>{{data?.Tables?.timeSpentInShop?.name}}</h3>
		<table class="host__table w-100" #t3>
			<thead>
			<tr>
					<th [ngStyle]="{'width.px': t3.clientWidth * (100 / numberOfColumns('timeSpentInShop')) / 100 - (hiddenColumns.timeSpentInShop.length - numberOfColumns('timeSpentInShop')) * 50}">
					<div class="d-flex align-items-center">
						<span class="column-name">{{data?.Tables?.timeSpentInShop?.columns[0]}}</span>
						<div class="sort-icon__wrap pointer" (click)="onSort('timeSpentInShop', 'Name')">
							<span class="sort-icon-top"
								  [class.selected]="sort['timeSpentInShop'].currentSortingColumn === 'Name' && sort['timeSpentInShop'].currentSortingDirection === 1"></span>
							<span class="sort-icon-bot"
								  [class.selected]="sort['timeSpentInShop'].currentSortingColumn === 'Name' && sort['timeSpentInShop'].currentSortingDirection === -1"></span>
						</div>
					</div>
				</th>
				<th [ngStyle]="{'width.px': hiddenColumns.timeSpentInShop[1] ? 50 : t3.clientWidth * (100 / numberOfColumns('timeSpentInShop')) / 100 - (hiddenColumns.timeSpentInShop.length - numberOfColumns('timeSpentInShop')) * 50}">
					<div class="d-flex align-items-center">
						<ng-container *ngIf="!hiddenColumns.timeSpentInShop[1]">
							<span class="column-name">{{data?.Tables?.timeSpentInShop?.columns[1]}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('timeSpentInShop', 'Standing')">
								<span class="sort-icon-top"
									[class.selected]="sort['timeSpentInShop'].currentSortingColumn === 'Standing' && sort['timeSpentInShop'].currentSortingDirection === 1"></span>
								<span class="sort-icon-bot"
									[class.selected]="sort['timeSpentInShop'].currentSortingColumn === 'Standing' && sort['timeSpentInShop'].currentSortingDirection === -1"></span>
							</div>
						</ng-container>
						<div class="hide-icon__wrap pointer" (click)="onToggleColumn('timeSpentInShop', 1)">
							<span class="hide-icon-left" *ngIf="!hiddenColumns.timeSpentInShop[1]" [matTooltip]="hideColumnTitle"></span>
							<span class="hide-icon-right selected" *ngIf="hiddenColumns.timeSpentInShop[1]" [matTooltip]="showColumnTitle"></span>
						</div>
					</div>
				</th>
				<th [ngStyle]="{'width.px': hiddenColumns.timeSpentInShop[2] ? 50 : t3.clientWidth * (100 / numberOfColumns('timeSpentInShop')) / 100 - (hiddenColumns.timeSpentInShop.length - numberOfColumns('timeSpentInShop')) * 50}">
					<div class="d-flex align-items-center">
						<ng-container *ngIf="!hiddenColumns.timeSpentInShop[2]">
							<span class="column-name">{{data?.Tables?.timeSpentInShop?.columns[2]}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('timeSpentInShop', 'PassingThrough')">
								<span class="sort-icon-top"
									[class.selected]="sort['timeSpentInShop'].currentSortingColumn === 'PassingThrough' && sort['timeSpentInShop'].currentSortingDirection === 1"></span>
								<span class="sort-icon-bot"
									[class.selected]="sort['timeSpentInShop'].currentSortingColumn === 'PassingThrough' && sort['timeSpentInShop'].currentSortingDirection === -1"></span>
							</div>
						</ng-container>
						<div class="hide-icon__wrap pointer" (click)="onToggleColumn('timeSpentInShop', 2)">
							<span class="hide-icon-left" *ngIf="!hiddenColumns.timeSpentInShop[2]" [matTooltip]="hideColumnTitle"></span>
							<span class="hide-icon-right selected" *ngIf="hiddenColumns.timeSpentInShop[2]" [matTooltip]="showColumnTitle"></span>
						</div>
					</div>
				</th>
			</tr>
			</thead>
			<tbody>
			<tr *ngIf="data?.Arena">
				<td>{{data?.Tables?.timeSpentInShop?.rows[0]}}</td>
				<td><ng-container *ngIf="!hiddenColumns.timeSpentInShop[1]"><div class="empty-cell"></div></ng-container></td>
				<td><ng-container *ngIf="!hiddenColumns.timeSpentInShop[2]">{{data.Arena.timeSpentInShop | number:'1.0-0'}}s</ng-container></td>
			</tr>
			<tr *ngFor="let item of getMonitoringItems('timeSpentInShop'); let i = index">
				<td>{{item['Name']}}</td>
				<td><ng-container *ngIf="!hiddenColumns.timeSpentInShop[1]">{{item['Standing'] | number:'1.0-0'}}s</ng-container></td>
				<td><ng-container *ngIf="!hiddenColumns.timeSpentInShop[2]">{{item['PassingThrough'] | number:'1.0-0'}}s</ng-container></td>
			</tr>
			<tr *ngIf="!data?.Registers?.timeSpentInShop?.length">
				<td class="no-data" colspan="3">No data</td>
			</tr>
			</tbody>
		</table>
	</ng-container>
</div>
