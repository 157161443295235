import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HomeAutomationServerSocket} from '../../../../../services/system/ham-socket';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {SensorsService} from '../../../../../services/system/sensors.service';
import {StorageService} from '../../../../../services/storage.service';
import {BusEventService} from '../../../../../services/bus-event.service';
import {SettingsService} from '../../../../../services/settings.service';
import {validateNumberInputKeyDown} from '../../../../../utils/utils';

/**
 * Component is designed for showing categories with list of devices.
 * Used for Smart Home app.
 */
@Component({
	selector: 'app-smart-home-dashboard',
	templateUrl: './smart-home-dashboard.component.html',
	styleUrls: ['./smart-home-dashboard.component.scss']
})
export class SmartHomeDashboardComponent implements OnInit, OnDestroy {

	@Input() socket: HomeAutomationServerSocket;
	@Input() parameters: any = {};

	form: FormGroup;
	devices = {};

	private subscriptions: Array<Subscription> = [];
	private socketsNames: Array<any> = [];

	constructor(private fb: FormBuilder,
				private sensorsService: SensorsService,
				private storageService: StorageService,
				private busEventService: BusEventService,
				private settingsService: SettingsService) {
	}

	ngOnInit() {
		this.form = this.fb.group({
			devices: new FormArray([])
		});
		this.subscriptions.push(this.busEventService.smartHomeParamsChanged.subscribe(spaceInfo => {
			this.devices = spaceInfo.devices;
		}));
		Promise.all([
			this.storageService.getItem('spaceInfo'),
			this.storageService.getItem('socketPool')
		]).then(res => {
			let [spaceInfo, socketPool] = res;
			var pool = (socketPool || []).map(url => {
				return this.settingsService.getSocketInfo(url);
			});
			Promise.all(pool).then(socketsInfo => {
				this.socketsNames = socketsInfo.map((info: any, i) => info.name || socketPool[i]);
			});
			this.devices = spaceInfo.devices;
			this.subscriptions.push(this.sensorsService.hamDevices.subscribe(deviceList => {
				while ((this.form.controls.devices as FormArray).length !== 0) {
					(this.form.controls.devices as FormArray).removeAt(0);
				}
				deviceList.devices.forEach(device => {
					let group = new FormGroup({});
					Object.keys(device).forEach(key => {
						let value = device[key];
						group.addControl(key, new FormControl(value));
					});
					(<FormArray>this.form.get('devices')).push(group);
				});
			}));
		});
	}

	updateDevice(device, updateStatus = false) {
		let room = (this.parameters.socketInfo.devices || {})[device.get('name').value],
			value = device.get('armed') ? device.get('armed').value : device.get('status').value,
			state = {
				name: device.get('name').value,
				roomIp: room ? room.ip : null,
				roomName: room ? room.name : null,
				[device.get('armed') ? 'armed' : 'value']: updateStatus ? !value : value
			};
		if (device.get('temperature')) {
			state['temperature'] = device.get('temperature').value;
		}
		device.get(device.get('armed') ? 'armed' : 'status').setValue(updateStatus ? !value : value);
		this.socket.controlDevice(state);
	}

	validateKeyDown(e) {
		return validateNumberInputKeyDown(e);
	}

	getRoomName(device) {
		return this.devices[device] && this.devices[device].name && this.socketsNames.includes(this.devices[device].name) ? this.devices[device].name : '';
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	getDeviceImage(device) {
		let src = '',
			type = '-active';

		if ((device.get('armed') && !device.get('armed').value) || (!device.get('armed') && device.get('status') && !device.get('status').value)) {
			type = '-disabled';
		}

		switch (true) {
			case device.get('name').value.toLowerCase().startsWith('light'):
				src = `assets/images/smart-home/light${type}.svg`;
				break;
			case device.get('name').value.toLowerCase().startsWith('alarm'):
				src = `assets/images/smart-home/alarm${type}.svg`;
				break;
			case device.get('name').value.toLowerCase().startsWith('ac'):
				src = `assets/images/smart-home/ac${type}.svg`;
				break;
		}
		return src;
	}
}
