import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {
	axesSystem2D,
	generateUIDFromCharCodes,
	getSensorRotation,
	getSensorTranslation2D,
	rotateLeafletGeometry
} from 'src/app/utils/utils';
import {
	colorscale,
	makeGetColor,
	TARGET_CENTER_COLOR,
} from '../../../../../utils/ImageUtils';
import {Subscription} from 'rxjs';
import {SensorsService} from '../../../../../services/system/sensors.service';
import {BusEventService} from '../../../../../services/bus-event.service';
import {SettingsService} from '../../../../../services/settings.service';
import {scalePoints} from '../../../../../utils/IsoUtils';
import * as L from 'leaflet';
import tambourine from '!raw-loader!../../../../../../assets/images/svg/card-shapes/tambourine.svg';
import chirva from '!raw-loader!../../../../../../assets/images/svg/card-shapes/chirva.svg';
import tref from '!raw-loader!../../../../../../assets/images/svg/card-shapes/tref.svg';
import peak from '!raw-loader!../../../../../../assets/images/svg/card-shapes/peak.svg';
import arrow_down_x from '!raw-loader!../../../../../../assets/images/svg/Axis_Assets/Axis_Assets/arrow_down-x.svg';
import arrow_down_y from '!raw-loader!../../../../../../assets/images/svg/Axis_Assets/Axis_Assets/arrow_down-y.svg';
import arrow_up_x from '!raw-loader!../../../../../../assets/images/svg/Axis_Assets/Axis_Assets/arrow_up-x.svg';
import arrow_up_y from '!raw-loader!../../../../../../assets/images/svg/Axis_Assets/Axis_Assets/arrow_up-y.svg';
import arrow_right_x from '!raw-loader!../../../../../../assets/images/svg/Axis_Assets/Axis_Assets/arrow_right-x.svg';
import arrow_right_y from '!raw-loader!../../../../../../assets/images/svg/Axis_Assets/Axis_Assets/arrow_right-y.svg';
import arrow_left_x from '!raw-loader!../../../../../../assets/images/svg/Axis_Assets/Axis_Assets/arrow_left-x.svg';
import arrow_left_y from '!raw-loader!../../../../../../assets/images/svg/Axis_Assets/Axis_Assets/arrow_left-y.svg';
import 'leaflet-rotatedmarker';
import {ic, updateTargetColorAllocationDataStructures } from 'src/app/utils/ColorTablesUtils';

require('../../../../../../assets/js/leaflet.latlng-graticule');

/**
 * Component renders 2D point cloud.
 */
@Component({
	selector: 'app-point-cloud-2d',
	templateUrl: './point-cloud-2d.component.html',
	styleUrls: ['./point-cloud-2d.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointCloud2dComponent implements OnChanges, AfterViewInit, OnDestroy {

	@Input() data: any = {};
	@Input() parameters: any = {};
	@Input() connectedSensorsSettings = [];
	@Input() staticDataDict: any = {};
	@Input() rotateValue = 0;
	@Input() reflect = false;
	@Input() metadata = true;
	@Input() postureValue = false;
	@Input() isSensorVisible = true;
	@Input() showGrid = true;
	@Input() hostElementMargin = 0;
	@Input() isMultipleSensors = false;

	@Output() posture = new EventEmitter;
	@Output() rendered = new EventEmitter();

	@ViewChild('map') mapElement: ElementRef;

	localData: any = {};
	SENSOR_H = 0.2; // in meters
	SENSOR_W = 0.2; // in meters

	isDisplayCenterTarget = false;
	isDisplayTargetsInfo = true;
	displayAxes = 0;

	targets: Array<any> = [];
	points: Array<any> = [];

	scale_const = 0.35;

	cardSuitsSymbols = [chirva, tambourine, tref, peak];

	private subscriptions: Array<Subscription> = [];
	private isDestroyed = false;
	private targetsInfoData = {};
	private UIDs: Array<string> = [];
	private sides = ['-', '', '-', ''];
	private map: L.Map;
	private imageOverlay: L.ImageOverlay;
	private currentArenas: Array<L.Rectangle> = [];
	private currentSensors: Array<L.Marker> = [];
	private targetsLayersGroup = new L.FeatureGroup();
	private targetsLayersByTargetID: {
		[targetID: number]: Array<L.Marker>
	} = {};
	private pointsLayersGroup = new L.FeatureGroup();
	private grid: L.LatLngGraticule;
	private rectangleVertices: L.Rectangle;
	private axesXhelper: L.Marker;
	private axesYhelper: L.Marker;
	private polygonsOverlay: Array<L.Polygon> = [];
	private polylineOverlay: Array<L.Polyline> = [];
	private alerts: Array<L.Popup> = [];

	getColor = (function (colorGetter) {
		return (...a) => {
			// @ts-ignore
			let color = colorGetter(...a);
			return typeof color === 'string' ? color : `rgb(${color.r}, ${color.g}, ${color.b})`;
		};
	}(makeGetColor(TARGET_CENTER_COLOR, colorscale, ic)));

	constructor(private hostElement: ElementRef,
				private ref: ChangeDetectorRef,
				private sensorsService: SensorsService,
				private busEventService: BusEventService,
				private settingsService: SettingsService,
				private ngZone: NgZone) {
	}

	async ngAfterViewInit() {
		await Promise.resolve();
		this.ngZone.runOutsideAngular(() => {
			this.map = L.map(this.mapElement.nativeElement, {
				attributionControl: false,
				zoom: 1,
				center: [0, 0],
				minZoom: -1,
				crs: L.CRS.Simple,
				zoomControl: false
			} as any);
			setTimeout(() => {
				this.grid = L.latlngGraticule({
					showLabel: true,
					dashArray: [2, 2],
					sides: this.sides
				});
				this.updateAxisHelper();
				this.updateGrid();
				this.updateRectangles();
				this.map.on('zoomend', () => {
					this.updateAxisHelper();
					this.updateSensors();
					this.updatePoints();
				});
				this.map.addLayer(this.targetsLayersGroup);
				this.map.addLayer(this.pointsLayersGroup);
			});
		});
		setTimeout(() => {
			this.rendered.emit();
		});
		if (this.isMultipleSensors) {
			this.settingsService.getSocketInfo('multi').then(info => {
				if (info['backgroundImage']) {
					this.updateBackgroundImage(info);
				}
			});
		}
	}

	ngOnChanges(c: SimpleChanges) {
		if ('parameters' in c || 'connectedSensorsSettings' in c || 'rotateValue' in c || 'reflect' in c) {
			setTimeout(() => {
				if (!this.isDestroyed) {
					if ('rotateValue' in c) {
						this.updateAxisHelper();
						this.updateGridSides();
					}
					this.updateArenas();
					this.updateSensors();
					this.updatePoints();
					this.updateRectangles();
					this.ref.detectChanges();
				}
			});
		}
		if ('isSensorVisible' in c) {
			this.updateSensors();
		}
		if ('data' in c) {
			this.updateData();
			this.updatePoints();
			this.updateRectangles();
			this.updateAlerts();
		}
		if ('showGrid' in c) {
			this.updateGrid();
		}
		if('parameters' in c && this.staticDataDict.backgroundImage){
			setTimeout(() => {
				if (!this.isDestroyed) {
					this.updateBackgroundImage(this.staticDataDict.backgroundImage, true);
				}
			});
		} else if ('parameters' in c) {
			if (this.imageOverlay) {
				this.map.removeLayer(this.imageOverlay);
			}
			if (this.parameters['socketInfo']['backgroundImage']) {
				setTimeout(() => {
					if (!this.isDestroyed) {
						if (this.isMultipleSensors) {
							this.settingsService.getSocketInfo('multi').then(info => {
								if (info['backgroundImage']) {
									this.updateBackgroundImage(info);
								}
							});
						} else {
							this.updateBackgroundImage(this.parameters['socketInfo']);
						}
					}
				});
			}
		}

		if('parameters' in c && this.staticDataDict.polygonsVertices){
			setTimeout(() => {
				if (!this.isDestroyed) {
					this.updatePolygons();
				}
			});
		}
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	toggleTargetCenter() {
		this.isDisplayCenterTarget = !this.isDisplayCenterTarget;
		this.updatePoints();
	}

	toggleTargetInfo() {
		this.isDisplayTargetsInfo = !this.isDisplayTargetsInfo;
		this.updatePoints();
	}

	toggleAxes() {
		this.displayAxes = (this.displayAxes + 1) % 3;
		this.updateArenas();
		this.updateSensors();
		this.updatePoints();
		this.updateRectangles();
	}

	getRectanglePoints() {
		return [
			[-this.staticDataDict.rectangleVertices[0], -this.staticDataDict.rectangleVertices[1]],
			[-this.staticDataDict.rectangleVertices[2], -this.staticDataDict.rectangleVertices[3]]
		];
	}

	getSensorRotation(parameters) {
		let rotation = getSensorRotation(parameters, true),
			index;

		switch (this.getAxes()) {
			case 'xy':
			default:
				index = 1;
				break;
			case 'xz':
				index = 2;
				break;
			case 'yz':
				index = 0;
				break;
		}

		// In 2D we rotate sensor only around "height" axis
		// Currently disabled. To restore change return value to return rotation[index]
		return 0;
	}

	getPosition(x0, y0, z0, axes, fromEVK = true) {
		if (x0 === 'NaN') {
			return [0, 0];
		}
		let x, y;
		if (fromEVK) {
			[x, y] = [[x0, y0, z0][axes[0]], [x0, y0, z0][axes[1]]];
		} else {
			[x, y] = [x0, y0];
		}

		let _x = x;
		let _y = -y;

		if ([axesSystem2D.xy, axesSystem2D.xz].includes(this.displayAxes)) {
			_x = -_x;
		}

		if (this.shouldInvertXAxis()) {
			_x = 1 - _x;
		}

		[_x, _y] = [_x, _y].map(v => isFinite(v) ? v : 0);

		return [_x, _y];
	}

	parseRawPoints(raw_points, axes) {
		/*
			0: (x, y)
			1: id
			2: radius
		*/
		if (!raw_points || !(raw_points instanceof Array) || this.isDisplayCenterTarget) {
			return [];
		}
		if (raw_points.length && !raw_points[0].length) {
			raw_points = [raw_points];
		}
		if (!this.metadata) {
			scalePoints(raw_points);
		}
		return raw_points.map(
			x =>
				x[0] === 'NaN'
					? null
					: [this.getPosition(x[0], x[1], x[2], axes), x[3], this.scale_const / 5]
		);
	}

	parseTargetCenterData(targetCenterData, axes) {
		/*
			0: (x, y)
			1: UID or targetID
			2: radius
			3: targetID
		*/
		if (!targetCenterData || !(targetCenterData instanceof Array)) {
			return [];
		}
		if (targetCenterData.length && !targetCenterData[0].length) {
			targetCenterData = [targetCenterData];
		}
		let window_size = 0;
		if (!targetCenterData[0]) {
			return [];
		}
		let first_id = targetCenterData[0][3];
		for (const item of targetCenterData) {
			if (item[3] !== first_id) {
				break;
			}
			++window_size;
		}
		let parsed_points: Array<any> = [];

		parsed_points = targetCenterData.map(
			(x, i) => {
				let targetID = this.isMultipleSensors && typeof x[4] === 'number' ? x[4] : x[3];
				return x[0] === 'NaN'
					? null
					: [
						this.getPosition(x[0], x[1], x[2], axes),
						targetID,
						(this.isDisplayCenterTarget ? 1 : 0 * 0.75 + 1) *
						this.scale_const *
						(0.2 + 0.8 * Math.pow((i % window_size) / window_size, 2)),
						x[3]
					];
			}
		);

		return parsed_points;
	}

	getAxes() {
		let axes;

		switch (this.displayAxes) {
			case axesSystem2D.xy:
				axes = [0, 1];
				break;
			case axesSystem2D.xz:
				axes = [0, 2];
				break;
			case axesSystem2D.yz:
				axes = [1, 2];
				break;
		}

		return axes;
	}

	getAxesAttr() {
		let axes = this.getAxes();
		if (axes[1] === 1) {
			return axes[0] === 1 ? 'yz' : 'xy';
		} else {
			return axes[0] === 1 ? 'yz' : 'xz';
		}
	}

	/**
	 * @deprecated
	 */
	togglePosture() {
		this.posture.emit();
	}

	updateData() {
		if (this.isMultipleSensors) {
			this.localData = this.concatCombinedData(this.data);
		} else {
			this.localData = this.data;
			if (this.localData.targetsInfoData) {
				if (!Array.isArray(this.localData.targetsInfoData[0])) {
					this.localData.targetsInfoData = [this.localData.targetsInfoData];
				}
			}
		}
		if (this.localData.targetsInfoData) {
			this.localData.targetsInfoData.forEach(info => {
				this.targetsInfoData[Number(info[0])] = info[1];
			});
		}
	}

	private concatCombinedData(aggregatedData = {}) {
		let ret = {};
		Object.keys(aggregatedData).forEach(outputName => {
			if (outputName === 'targetsInfoData' && aggregatedData[outputName][0] && !Array.isArray(aggregatedData[outputName][0][0])) {
				aggregatedData[outputName] = [aggregatedData[outputName]];
			}
			aggregatedData[outputName].forEach(outputData => {
				outputData.forEach(point => {
					if (typeof point === 'object') {
						// In case targetsInfoData is not 2 dim array we don't have the outputData.index
						point.index = (typeof outputData.index === 'number' ? outputData.index : point.index) + 1;
						if (outputName === 'targetsInfoData') {
							point[0] = point.index * 1000 + Number(point[0]);
						} else {
							/**
							 * Original point structure is:
							 *	0, 1, 2 - x, y, z coordinates
							 *	3 - target ID
							 *  4 till the end - char codes that make up the string UID
							 *  ---
							 * Generate UID from char codes
							 * Add numeric unique target ID
							 * So point values is going to be:
							 * 0, 1, 2 - x, y, z coordinates
							 * 3 - connection index + target ID
							 * 4 - numeric UID
							 */
							point[3] = point.index * 1000 + point[3];
							let UID = generateUIDFromCharCodes(point.slice(4));
							this.updateUIDs(UID);
							point[4] = this.UIDs.indexOf(UID) + 1;
						}
					}
				});
			});
			ret[outputName] = [].concat(...aggregatedData[outputName]);
		});

		return ret;
	}

	private updateUIDs(UID) {
		if (!this.UIDs.includes(UID)) {
			this.UIDs.push(UID);
		}
	}

	/**
	 * By default, x axis goes from right to left (from the user point of view)
	 * "Invert" means to change x axis to start from left to right
	 */
	private shouldInvertXAxis() {
		return this.reflect;
	}

	private getArena() {
		let arena = this.parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'];
		if (this.isMultipleSensors) {
			arena = [0, 1, 2, 3, 4, 5].map(index => {
				return Math[index % 2 ? 'max' : 'min'](...this.connectedSensorsSettings.map(parameters => parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'][index]));
			});
		}

		return arena;
	}

	private getArenaGeometry() {
		let arena = this.getArena();

		return [
			[-arena[1], -arena[3]],
			[-arena[0], -arena[2]]
		];
	}

	private updateArenas() {
		this.ngZone.runOutsideAngular(() => {
			this.removeOldArenas();
			this.addNewArenas();
		});
	}

	private removeOldArenas() {
		this.currentArenas.forEach(arena => {
			this.map.removeLayer(arena);
		});
	}

	private addNewArenas() {
		if (this.map) {
			let rectangleOptions = {
					color: 'rgb(59, 177, 205)',
					opacity: .2,
					stroke: false
				},
				bounds;
			if (this.isMultipleSensors) {
				this.connectedSensorsSettings.forEach(parameters => {
					let arena = parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'];
					bounds = rotateLeafletGeometry(
						this.getRotationDegree(),
						[
							[-arena[1], -arena[3]],
							[-arena[0], -arena[2]]
						]
					);
					let leafletArena = L.rectangle(bounds, rectangleOptions);
					this.currentArenas.push(leafletArena);
					this.map.addLayer(leafletArena);
				});
			} else {
				bounds = rotateLeafletGeometry(
					this.getRotationDegree(),
					this.getArenaGeometry()
				);
				let leafletArena = L.rectangle(bounds, rectangleOptions);
				this.currentArenas.push(leafletArena);
				this.map.addLayer(leafletArena);
			}

			this.fitBounds();
		}
	}

	private updateSensors() {
		this.ngZone.runOutsideAngular(() => {
			this.removeOldSensors();
			this.addNewSensors();
		});
	}

	private removeOldSensors() {
		this.currentSensors.forEach(sensor => {
			this.map.removeLayer(sensor);
		});
	}

	private addNewSensors() {
		if (this.map && this.isSensorVisible) {
			let callback = (sensorPosition, parameters) => {
				let bounds = rotateLeafletGeometry(
						this.getRotationDegree(),
						[[-sensorPosition[0], sensorPosition[1]]]
					),
					size = this.map.project([this.SENSOR_W, this.SENSOR_H], this.map.getZoom()),
					leafletSensor = L.marker(bounds[0], {
						icon: L.divIcon({
							iconSize: [Math.abs(size.x), Math.abs(size.y)],
							className: 'sensor-icon'
						}),
						// @ts-ignore
						rotationAngle: this.getSensorRotation(parameters),
						rotationOrigin: 'center center',
					});
				this.currentSensors.push(leafletSensor);
				this.map.addLayer(leafletSensor);
			};

			if (this.isMultipleSensors) {
				this.connectedSensorsSettings.forEach(parameters => {
					// @ts-ignore
					callback(this.getPosition(...getSensorTranslation2D(parameters), this.getAxes(), false), parameters);
				});
			} else {
				// @ts-ignore
				callback(this.getPosition(...getSensorTranslation2D(this.parameters), this.getAxes(), false), this.parameters);
			}
		}
	}

	private updatePoints() {
		if (this.map) {
			this.ngZone.runOutsideAngular(() => {
				let axes = this.getAxes(),
				targets = this.parseTargetCenterData(this.localData.targetCenterData, axes),
				points = this.parseRawPoints(this.localData.data, axes);

				this.removeOldPoint(targets.map(t => t[1]));
				this.targets = targets;
				this.points = points;
				this.updateTargetColorAllocationDataStructures(this.targets);
				this.updateTargets();
				this.updateRawPoints();
			});
		}
	}

	private removeOldPoint(targetsIDs) {
		this.targets.forEach(target => {
			let targetID = target[1];
			// Remove targets with ID's that doesn't exist in new data
			if (!targetsIDs.includes(targetID) && this.targetsLayersByTargetID[targetID]) {
				this.targetsLayersByTargetID[targetID].forEach(layer => {
					this.targetsLayersGroup.removeLayer(layer);
				});
				delete this.targetsLayersByTargetID[targetID];
			} else if (this.targetsLayersByTargetID[targetID]) {
				// Remove targets with ID's that exist in new data, but their count more than in new data (remove older)
				let deleteCount = this.targetsLayersByTargetID[targetID].length - targetsIDs.filter(id => id === targetID).length,
					oldTargets = this.targetsLayersByTargetID[targetID].splice(0, deleteCount);

				oldTargets.forEach(layer => {
					this.targetsLayersGroup.removeLayer(layer);
				});
			}
		});
		this.pointsLayersGroup.clearLayers();
	}

	private updateAlerts(){
		if(this.localData.alerts && this.localData.alerts.length){
			this.ngZone.runOutsideAngular(() => {
				this.removeOldAlerts();
				this.localData.alerts.forEach( el =>{
					let alert = this.alerts.find( item =>{
						let latlng = item.getLatLng();
						return latlng && latlng.lat == el.coordinates[0] && latlng.lng == el.coordinates[1];
					});

					if(alert){
						alert.setContent('<div class="alert" style="font-size:' + el.fontSize + 'px; color: ' + el.color + '; background-color: ' + el.backgroundColor + ';">' + el.text + '</div>');
					}else{
						let popup = L.popup({
							closeButton: false,
							autoClose: false,
							className: 'custom-popup'
						})
						.setLatLng(el.coordinates)
						.setContent('<div class="alert" style="font-size:' + el.fontSize + 'px; color: ' + el.color + '; background-color: ' + el.backgroundColor + ';">' + el.text + '</div>')
						.openOn(this.map);
						this.alerts.push(popup);
					}
				});
			});
		}
	}

	private removeOldAlerts(){
		this.alerts.forEach( item =>{
			let latlng = item.getLatLng();
			let	popupCoordinates = [latlng?.lat, latlng?.lng];
			let alert = this.localData.alerts.find(el=> JSON.stringify(el.coordinates) === JSON.stringify(popupCoordinates))
			if(!alert){
				this.map.removeLayer(item);
			}
		});
	}

	private updateTargets() {
		this.targets.forEach((c, i) => {
			if (c && c[1] && c[2]) {
				let index = this.targets.filter(t => t[1] === c[1]).indexOf(c);
				if (this.targetsLayersByTargetID[c[1]] && this.targetsLayersByTargetID[c[1]][index]) {
					let target = this.targetsLayersByTargetID[c[1]][index],
						bounds = rotateLeafletGeometry(
						this.getRotationDegree(),
						[c[0]]
					);
					// Move target
					target.setLatLng(bounds[0]);
					// Update icon size
					let icon = target.options.icon as L.Icon,
						size = this.map.project([c[2] * 2, c[2] * 2], this.map.getZoom());
					icon.options.iconSize = [Math.abs(size.x), Math.abs(size.y)];
					target.setIcon(icon);
					if (this.isDisplayTargetsInfo && this.targetsInfoData[c[3]] && (!this.targets[i + 1] || (this.targets[i + 1][3] !== c[3]))) {
						target.setTooltipContent(this.targetsInfoData[c[3]]);
					}
				} else {
					let iconShape = this.cardSuitsSymbols[(c[1] - 1) % this.cardSuitsSymbols.length],
						icon,
						color;
					if (this.isMultipleSensors) {
						color = this.getColor(c[1] < 1, c[1], false);
					} else {
						color = this.getColor(c[1] < 1, c[1], this.staticDataDict?.displayPcdSwitch ? !this.isDisplayCenterTarget : false);
					}
					let iconSVG = iconShape.replace(new RegExp('currentColor', 'gmi'), color);
					icon = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(iconSVG)))}`;
					let bounds = rotateLeafletGeometry(
							this.getRotationDegree(),
							[c[0]]
						),
						size = this.map.project([c[2] * 2, c[2] * 2], this.map.getZoom()),
						target = L.marker(bounds[0], {
							icon: L.icon({
								iconUrl: icon,
								iconSize: [Math.abs(size.x), Math.abs(size.y)]
							})
						});
					this.targetsLayersGroup.addLayer(target);
					if (!this.targetsLayersByTargetID[c[1]]) {
						this.targetsLayersByTargetID[c[1]] = [];
					}
					this.targetsLayersByTargetID[c[1]].push(target);
					if (this.isDisplayTargetsInfo && this.targetsInfoData[c[3]] && (!this.targets[i + 1] || (this.targets[i + 1][3] !== c[3]))) {
						target.bindTooltip(this.targetsInfoData[c[3]], {
							direction: 'bottom',
							permanent: true,
							className: 'point-cloud-2d-target-info-data',
							opacity: 1,
							offset: [0, 15]
						}).openTooltip();
					}
				}
			}
		});
	}

	private updateRawPoints() {
		this.points.forEach((c, i) => {
			if (c && c[1] && c[2]) {
				let bounds = rotateLeafletGeometry(
						this.getRotationDegree(),
						[c[0]]
					),
					size = this.map.project([c[2] * 2, c[2] * 2], this.map.getZoom()),
					color = this.getColor(c[1] < 1, c[1]),
					point = L.marker(bounds[0], {
						icon: L.divIcon({
							html: `<div style="background: ${color}"></div>`,
							iconSize: [Math.abs(size.x), Math.abs(size.y)],
							className: 'raw-point'
						})
					});
				this.pointsLayersGroup.addLayer(point);
			}
		});
	}

	private getRotationDegree() {
		let rotationDegree = 0;

		switch (this.rotateValue) {
			case 3:
				rotationDegree = 90;
				break;
			case 2:
				rotationDegree = 180;
				break;
			case 1:
				rotationDegree = 270;
				break;
		}

		return rotationDegree;
	}

	private updateGrid() {
		if (this.map) {
			if (this.showGrid && !this.map.hasLayer(this.grid)) {
				this.map.addLayer(this.grid);
			} else if (!this.showGrid) {
				this.map.removeLayer(this.grid);
			}
		}
	}

	private updateRectangles() {
		if (this.rectangleVertices) {
			this.map.removeLayer(this.rectangleVertices);
		}
		if (this.map && this.staticDataDict.rectangleVertices) {
			let color = this.targets.some((c, i) => {
					return this.staticDataDict.rectangleVertices[0] > this.localData.targetCenterData[i][0] && this.localData.targetCenterData[i][0] > this.staticDataDict.rectangleVertices[2] &&
						this.staticDataDict.rectangleVertices[3] < this.localData.targetCenterData[i][1] && this.localData.targetCenterData[i][1] < this.staticDataDict.rectangleVertices[1];
				}) ? 'rgba(255, 0, 0, .2)' : 'rgba(59, 177, 205, .2)',
				rectangleOptions = {
					color: color,
					opacity: .2,
					stroke: false
				},
				bounds = rotateLeafletGeometry(
					this.getRotationDegree(),
					this.getRectanglePoints()
				),
				rectangle = L.rectangle(bounds, rectangleOptions);
			this.rectangleVertices = rectangle;
			this.map.addLayer(rectangle);
		}
	}

	private updateTargetColorAllocationDataStructures(targetCenterData: any[] = []) {
		updateTargetColorAllocationDataStructures(targetCenterData, target => target[1]);
	}

	private updateBackgroundImage(info, isBase64 = false) {
		let bounds:L.LatLngBoundsLiteral;
		let backgroundImage;
		if (this.imageOverlay) {
			this.map.removeLayer(this.imageOverlay);
		}

		if(isBase64){
			backgroundImage = info.base64Image;
			bounds = [[info.minX, info.minY],[info.maxX, info.maxY]];
		}else{
			backgroundImage = info.backgroundImage;
			let {height, width, origin} = info;
			bounds = [[0, 0], [height, width]].map(t => {
					return [t[0] - origin[0], t[1] - origin[1]];
			});
		}

		this.imageOverlay = L.imageOverlay(backgroundImage, bounds).addTo(this.map);
		this.fitBounds();
	}

	private updatePolygons(){
		if(this.polygonsOverlay.length){
			this.removeOverlays(this.polygonsOverlay);
		}
		if(this.polylineOverlay.length){
			this.removeOverlays(this.polylineOverlay);
		}
		this.staticDataDict.polygonsVertices.forEach(el=>{
			if(el.vertices.length === 2){
				let polyline = L.polyline(el.vertices, {
					color: el.boundingColor,
					opacity: el.opacity,
					}).addTo(this.map);
				this.polylineOverlay.push(polyline);
			}else{
				let polygon = L.polygon(el.vertices, {
					color: el.boundingColor,
					fillColor: el.backgroundColor,
					opacity: el.opacity,
					fillOpacity: el.opacity}).addTo(this.map);
				this.polygonsOverlay.push(polygon);
			}
		});

		this.fitBounds();
	}

	private fitBounds(){

        let group = L.featureGroup(this.currentArenas);

        if (this.rectangleVertices) {
            group.addLayer(this.rectangleVertices);
        }

		if(this.imageOverlay){
			group.addLayer(this.imageOverlay);
		}

		if(this.polygonsOverlay.length){
			group.addLayer(L.featureGroup(this.polygonsOverlay));
		}

		if(this.polylineOverlay.length){
			group.addLayer(L.featureGroup(this.polylineOverlay));
		}

        this.map.fitBounds(group.getBounds());
	}

	private removeOverlays(data){
		data.forEach(el=>{
			this.map.removeLayer(el);
		});
	}

	private updateAxisHelper() {
		if (this.map) {
			if (this.axesXhelper) {
				this.map.removeLayer(this.axesXhelper);
			}
			if (this.axesYhelper) {
				this.map.removeLayer(this.axesYhelper);
			}
			let size = this.map.project([5, 5], this.map.getZoom()),
				arrowsXShape = [arrow_down_y, arrow_right_y, arrow_up_y, arrow_left_y],
				arrowsYShape = [arrow_left_x, arrow_down_x, arrow_right_x, arrow_up_x],
				arrowsXMarginTop = [0, , -size.x + 'px', size.y / 2 + 'px'],
				arrowsXMarginLeft = [-Math.abs(size.x) / 2 + 'px', 0, , -size.x + 'px'],
				arrowsYMarginTop = [size.y / 2 + 'px', 0, , size.y + 'px'],
				arrowsYMarginLeft = [-size.x + 'px', , 0],
				icon_down = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(arrowsXShape[this.rotateValue])))}`,
				icon_left = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(arrowsYShape[this.rotateValue])))}`;

			this.axesXhelper = L.marker([0, 0], {
				icon: L.icon({
					iconUrl: icon_down,
					iconSize: [Math.abs(size.x), Math.abs(size.y)]
				})
			}).addTo(this.map);
			this.axesYhelper = L.marker([0, 0], {
				icon: L.icon({
					iconUrl: icon_left,
					iconSize: [Math.abs(size.x), Math.abs(size.y)]
				})
			}).addTo(this.map);
			if (typeof arrowsXMarginTop[this.rotateValue] !== null) {
				this.axesXhelper['_icon'].style.marginTop = arrowsXMarginTop[this.rotateValue];
			}
			if (typeof arrowsXMarginLeft[this.rotateValue] !== null) {
				this.axesXhelper['_icon'].style.marginLeft = arrowsXMarginLeft[this.rotateValue];
			}
			if (typeof arrowsYMarginTop[this.rotateValue] !== null) {
				this.axesYhelper['_icon'].style.marginTop = arrowsYMarginTop[this.rotateValue];
			}
			if (typeof arrowsYMarginLeft[this.rotateValue] !== null) {
				this.axesYhelper['_icon'].style.marginLeft = arrowsYMarginLeft[this.rotateValue];
			}
		}
	}

	private updateGridSides() {
		if (this.grid) {
			let sides;
			switch (this.rotateValue) {
				case 0:
					sides = ['-', '', '-', ''];
					break;
				case 1:
					sides = ['-', '', '', '-'];
					break;
				case 2:
					sides = ['', '-', '', '-'];
					break;
				case 3:
					sides = ['', '-', '-', ''];
					break;
			}
			// @ts-ignore
			this.grid.options.sides = sides;
		}
	}
}
