<div class="wrap">
  <div class="host__wrap">
      <div class="sensors-header">
        <input data-selector="connections_filter_input" type="text" placeholder="Filter" class="host__input-filter" [(ngModel)]="filterString" (ngModelChange)="onFilter()">
        <div class="d-flex position-relative">
            <button data-selector="connections_add-connection_button" class="host__add-sensor-btn pointer" (click)="addConnection()">{{'ADD_CONNECTIONS' | translate}}</button>
            <div class="host__actions-wrap d-flex">
              <button class="host__actions-icon refresh pointer mr-2" [disabled]="!monitoringItems.length" [matTooltip]="'REFRESH_CONNECTIONS_STATUSES' | translate"
			  (click)="refresh()"></button>
              <button class="host__actions-icon run pointer mr-2 ml-2" [disabled]="!isCheckedRows || !canChangeSensorState(true)"
			  (click)="runSensors()" [matTooltip]="'RUN_SELECTED_CONNECTIONS' | translate"></button>
              <button class="host__actions-icon stop pointer mr-2 ml-2" [disabled]="!isCheckedRows || !canChangeSensorState()"
			  (click)="stopSensors()" [matTooltip]="'STOP_SELECTED_CONNECTIONS' | translate"></button>
              <!--<button class="host__actions-icon event pointer mr-2 ml-2" disabled></button>-->
              <button class="host__actions-icon connect-disconnect pointer mr-2 ml-2" [class.active]="connectionMenuVisible" [matTooltip]="'CONNECT_OR_DISCONNECT_SELECTED_CONNECTIONS' | translate"
					  [disabled]="!isCheckedRows" (click)="connectionMenuVisible = !connectionMenuVisible; $event.stopPropagation();"></button>
				<div class="connection-menu" *ngIf="connectionMenuVisible">
					<div (click)="connectSensors()">{{'CONNECT' | translate}}</div>
					<div (click)="disConnectSensors()">{{'DISCONNECT' | translate}}</div>
				</div>
              <button class="host__actions-icon remove pointer ml-2" [disabled]="!isCheckedRows" [matTooltip]="'REMOVE_SELECTED_CONNECTIONS' | translate"
			  (click)="removeSelectedConnections()"></button>
            </div>
        </div>
      </div>
	  <div class="sensors-container" [perfectScrollbar]="{suppressScrollX: false, minScrollbarLength: 15}">
	  	<div class="sensors-body">
		  <table class="host__table w-100">
			  <thead>
				<tr class="fixed-header" [ngStyle]="{'min-width': windowWidth - 224 + 'px'}">
				  <th>
					  <div class="d-flex justify-content-center align-items-center">
						  <label for="selectAll"
								 class="host__checkbox-label pointer"
								 [ngClass]="{'checked': isAllHostsChecked, 'unchecked': !isAllHostsChecked}"
								 (click)="selectAllHosts()">
						  </label>
						  <input  type="checkbox"
								  class="host__checkbox"
								  [checked]="isAllHostsChecked">
					  </div>
				  </th>
				  <th class="fs-11 uppercase">
					  <div class="d-flex align-items-center">
						  <span>{{'CONNECTIVITY' | translate}}</span>
						  <div class="sort-icon__wrap pointer" (click)="onSort('isConnected')">
							  <span class="sort-icon-top" [class.selected]="currentSortingColumn === 'isConnected' && currentSortingDirection === Sort.Asc"></span>
							  <span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'isConnected' && currentSortingDirection === Sort.Desc"></span>
						  </div>
					  </div>
				  </th>
				  <th class="fs-11 uppercase">
					  <div class="d-flex align-items-center">
						  <span>{{'STATUS' | translate}}</span>
						  <div class="sort-icon__wrap pointer" (click)="onSort('status')">
							  <span class="sort-icon-top" [class.selected]="currentSortingColumn === 'status' && currentSortingDirection === Sort.Asc"></span>
							  <span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'status' && currentSortingDirection === Sort.Desc"></span>
						  </div>
					  </div>
				  </th>
				  <th class="fs-11 uppercase">
					  <div class="d-flex align-items-center">
						  <span>{{'IP' | translate}}</span>
						  <div class="sort-icon__wrap pointer" (click)="onSort('ip')">
							  <span class="sort-icon-top" [class.selected]="currentSortingColumn === 'ip' && currentSortingDirection === Sort.Asc"></span>
							  <span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'ip' && currentSortingDirection === Sort.Desc"></span>
						  </div>
					  </div>
				  </th>
					<th class="fs-11 uppercase">
						<div class="d-flex align-items-center">
							<span>{{'PORT' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('port')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'port' && currentSortingDirection === Sort.Asc"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'port' && currentSortingDirection === Sort.Desc"></span>
							</div>
						</div>
					</th>
					<th class="fs-11 uppercase">
						<div class="d-flex align-items-center" (click)="onSort('numberOfConnectedClients')">
							<span [innerHTML]="'NUMBER_OF_CONNECTED_CLIENTS' | translate | htmlNewLine"></span>
							<div class="sort-icon__wrap pointer">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'numberOfConnectedClients' && currentSortingDirection === Sort.Asc"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'numberOfConnectedClients' && currentSortingDirection === Sort.Desc"></span>
							</div>
						</div>
					</th>
				  <th class="fs-11 uppercase">
					  <div class="d-flex align-items-center">
						  <span>{{'NAME' | translate}}</span>
						  <div class="sort-icon__wrap pointer" (click)="onSort('name')">
							  <span class="sort-icon-top" [class.selected]="currentSortingColumn === 'name' && currentSortingDirection === Sort.Asc"></span>
							  <span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'name' && currentSortingDirection === Sort.Desc"></span>
						  </div>
					  </div>
				  </th>
				  <th class="fs-11 uppercase">{{'ACTIONS' | translate}}</th>
			  </tr>
				<tr class="invisible">
					<th class="col-select-all">
						<div class="d-flex justify-content-center align-items-center">
							<label for="selectAll"
								   class="host__checkbox-label pointer"
								   [ngClass]="{'checked': isAllHostsChecked, 'unchecked': !isAllHostsChecked}"
								   (click)="selectAllHosts()">
							</label>
							<input  type="checkbox"
									class="host__checkbox"
									id="selectAll"
									[checked]="isAllHostsChecked">
						</div>
					</th>
					<th class="fs-11 uppercase col-connectivity">
						<div class="d-flex align-items-center">
							<span>{{'CONNECTIVITY' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('isConnected')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'isConnected' && currentSortingDirection === Sort.Asc"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'isConnected' && currentSortingDirection === Sort.Desc"></span>
							</div>
						</div>
					</th>
					<th class="fs-11 uppercase col-status">
						<div class="d-flex align-items-center">
							<span>{{'STATUS' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('isRunned')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'isRunned' && currentSortingDirection === Sort.Asc"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'isRunned' && currentSortingDirection === Sort.Desc"></span>
							</div>
						</div>
					</th>
					<th class="fs-11 uppercase col-ip">
						<div class="d-flex align-items-center">
							<span>{{'IP' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('ip')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'ip' && currentSortingDirection === Sort.Asc"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'ip' && currentSortingDirection === Sort.Desc"></span>
							</div>
						</div>
					</th>
					<th class="fs-11 uppercase col-port">
						<div class="d-flex align-items-center">
							<span>{{'PORT' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('port')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'port' && currentSortingDirection === Sort.Asc"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'port' && currentSortingDirection === Sort.Desc"></span>
							</div>
						</div>
					</th>
					<th class="fs-11 uppercase col-number-of-connected">
						<div class="d-flex align-items-center" (click)="onSort('numberOfConnectedClients')">
							<span>{{'NUMBER_OF_CONNECTED_CLIENTS' | translate}}</span>
							<div class="sort-icon__wrap pointer">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'numberOfConnectedClients' && currentSortingDirection === Sort.Asc"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'numberOfConnectedClients' && currentSortingDirection === Sort.Desc"></span>
							</div>
						</div>
					</th>
					<th class="fs-11 uppercase">
						<div class="d-flex align-items-center">
							<span>{{'NAME' | translate}}</span>
							<div class="sort-icon__wrap pointer" (click)="onSort('name')">
								<span class="sort-icon-top" [class.selected]="currentSortingColumn === 'name' && currentSortingDirection === Sort.Asc"></span>
								<span class="sort-icon-bot" [class.selected]="currentSortingColumn === 'name' && currentSortingDirection === Sort.Desc"></span>
							</div>
						</div>
					</th>
					<th class="fs-11 uppercase col-actions">{{'ACTIONS' | translate}}</th>
				</tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let item of monitoringItems; let i = index">
				  <td class="d-flex justify-content-center align-items-center">
					  <label for="itemCheckbox{{i}}"
							 class="host__checkbox-label pointer "
							 [ngClass]="{'checked': item.checked, 'unchecked': !item.checked}"
							 (click)="item.checked = !item.checked; updateCheckedState()">
					  </label>
					  <input type="checkbox"
							 class="host__checkbox"
							 [checked]="item.checked"
							 id="itemCheckbox{{i}}">
				  </td>
				  <td data-selector="is-connected">
					  <span *ngIf="item.isConnected">
						{{'CONNECTED' | translate}}
					  </span>
					  <span *ngIf="item.isConnecting">
						{{'CONNECTING' | translate}}
					  </span>
					  <span *ngIf="!item.isConnected && !item.isConnecting">
						{{'DISCONNECTED' | translate}}
					  </span>
				  </td>
				  <td>
					  <span class="color-accent sensor-status">{{(item.isRunned ? 'RUNNING' : 'NOT_RUNNING') | translate}}</span>
					  <img *ngIf="item.error && item.error.length" [matTooltip]="item.error | translate"
						   class="sensor-error" src="assets/images/svg/system/System-Monitoring_Error.svg">
				  </td>
				  <td>
					  <div (click)="editSocket(item, inputIp, 'isQuickEditSocketIp')">
						<app-inline-edit-input	#inputIp ngDefaultControl
												[ngModel]="item.ip" class="name"
												[ngClass]="{'selected': item.isQuickEditSocketisQuickEditSocketIport}" type="text"
												[matTooltip]="item.port"
												[placeholder]="'SENSOR_IP' | translate"
												[tooltip]="'EDIT_CONNECTION_IP' | translate"
												[disabled]="!item.isQuickEditSocketIp"
												(valueChange)="saveEditSocket(item, $event, item.port)"
												(blur)="exitEditSocket(item, inputIp, item.ip)"
												data-selector="connection_sensor-list_ip_inline-edit">
							</app-inline-edit-input>
					  </div>
				  </td>
				  <td>
					  <div (click)="editSocket(item, inputPort, 'isQuickEditSocketPort')">
						  <app-inline-edit-input	#inputPort ngDefaultControl
						  							[ngModel]="item.port" class="name"
													  [ngClass]="{'selected': item.isQuickEditSocketPort}" type="text"
													  [matTooltip]="item.port"
													  [placeholder]="'SENSOR_PORT' | translate"
													  [tooltip]="'EDIT_CONNECTION_PORT' | translate"
													  [disabled]="!item.isQuickEditSocketPort"
													  (valueChange)="saveEditSocket(item, item.ip, $event)"
													  (blur)="exitEditSocket(item, inputPort, item.port)"
													  data-selector="port">
							</app-inline-edit-input>
					  </div>
				  </td>
				  <td class="connected-clients">
					  <span>{{item.numberOfConnectedClients}}</span>
				  </td>
				  <td>
					  <div (click)="editName(item, inputName)">
						<app-inline-edit-input	#inputName ngDefaultControl
												[ngModel]="item.name" class="name"
												[ngClass]="{'selected': item.isQuickEditName}" type="text"
												[matTooltip]="item.name"
												[placeholder]="'CONNECTION_NAME' | translate"
												[tooltip]="'EDIT_CONNECTION_NAME' | translate"
												[disabled]="!item.isQuickEditName"
												(valueChange)="saveEditName(item, $event)"
												(blur)="exitEditName(item, inputName, $event)"
												  data-selector="connection_sensor-list_name_inline-edit">
						</app-inline-edit-input>
					  </div>
				  </td>
				  <td>
					  <div class="host__actions-wrap d-flex">
						  <button class="host__actions-icon enter pointer mr-2" [matTooltip]="'GO_TO_CONNECTION' | translate"
								  (click)="goTo(item.sensorSocket)"
								  data-selector="connection_sensor-list_go-to-sensor_button"></button>
						  <button class="host__actions-icon settings pointer mr-2 ml-2" [matTooltip]="'SETTINGS' | translate"
								  (click)="onShowSettings(item)" [disabled]="!item.isConnected || item.isRunned || !item.isParametersRetrieved"></button>
						  <button class="host__actions-icon pointer mr-2 ml-2" [matTooltip]="(item.isRunned ? 'STOP_SENSOR' : 'RUN_SENSOR') | translate"
								  [ngClass]="{'stop': item.isRunned, 'run': !item.isRunned}"
								  (click)="setSensorState(item)" [disabled]="!item.isConnected || !item.isParametersRetrieved"></button>
						  <button class="host__actions-icon pointer mr-2 ml-2" [matTooltip]="(item.isConnected ? 'DISCONNECT' : 'CONNECT') | translate"
								  [ngClass]="{'disconnect-default': item.isConnected || item.isConnecting, 'connect-default': !item.isConnected && !item.isConnecting}"
								  (click)="toggleConnect(item)"
								  data-selector="connection_sensor-list_connect_button"></button>
						  <button class="host__actions-icon remove pointer ml-2" [matTooltip]="'REMOVE_CONNECTION' | translate"
								  (click)="removeConnection(item)"
								  data-selector="connection_sensor-list_delete_button"></button>
					  </div>
				  </td>
			  </tr>
			  </tbody>
		  </table>
			<div class="loading-wrapper" *ngIf="loading">
				<app-loading></app-loading>
			</div>
		</div>
	  </div>
  </div>
</div>
