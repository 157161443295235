import {Component, Injector, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {prepareRecordingPath} from '../../../../../utils/utils';
import {RunMode} from '../../../../../services/system/sensor-socket';
import {SingleSensorComponent} from '../../../../flow-processor/components/base-view/single-sensor/single-sensor.component';
import {MatDialog} from '@angular/material/dialog';

/**
 * Component is designed for showing scheme of building.
 * Used for Smart Buildings app.
 */
@Component({
	selector: 'app-covid-detection',
	templateUrl: '../../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../../components/base-view/base-view.component.scss']
})
export class CovidDetectionComponent extends SingleSensorComponent implements OnInit {

	viewContainerRequiredConnection = false;

	private datePipe: DatePipe;
	private dialog: MatDialog;

	constructor(protected injector: Injector) {
		super(injector);
		this.datePipe = injector.get(DatePipe);
		this.dialog = injector.get(MatDialog);
	}

	ngOnInit() {
		this.toolbarService.setIsCovidDetection(true);
		this.subscribeToToolbarControls();
		super.ngOnInit();
	}


	protected async onSensorToggle(state) {
		let t = await this.storageService.getItem('alert_on_disconnections'),
			alert_on_disconnections = t ? t.alert_on_disconnections : true;

		if (state && alert_on_disconnections && !(await this.modalService.checkAllSensorAreConnected())) {
			return false;
		} else {
			// super.onSensorToggle(state);
		}
	}


	runSensorRecording() {
		this.modalService.showSettings({
			sensorSocket: this.connection,
			isUserSettings: false,
			isPatientForm: true
		}).afterClosed().toPromise().then(patientData => {
			if (patientData) {
				let timestamp = this.datePipe.transform(new Date(), 'yyyy_MM_dd_HH_mm_ss');
				this.sensorsService.sensorSockets.value
				.filter(sensorSocket => {
					return sensorSocket.isConnected() && sensorSocket.isParametersRetrieved();
				})
				.forEach(sensorSocket => {
					this.sensorsService.updateSensorRunMode(sensorSocket, RunMode.RECORD, {
						path: prepareRecordingPath(timestamp, this.connectionSettings['sensorParameters']['ProcessorCfg.ExternalGUI.save_dir_base'])
					});
					let patientParameters = {};
					Object.keys(patientData).forEach(key => {
						patientParameters[`ProcessorCfg.HeartBeat.PatientData.${key}`] = patientData[key];
					});
					patientParameters[`ProcessorCfg.HeartBeat.PatientData.bloodPressure.diastolic`] = patientData['bloodPressure']['diastolic'];
					patientParameters[`ProcessorCfg.HeartBeat.PatientData.bloodPressure.systolic`] = patientData['bloodPressure']['systolic'];
					delete patientParameters[`ProcessorCfg.HeartBeat.PatientData.bloodPressure`];
					this.settingsService.saveSensorParameters(sensorSocket.url, patientParameters).then(() => {
						this.sensorsService.runSensor(sensorSocket);
					});
				});
			}
		});
	}


	protected subscribeToConnectionUpdates(): void {
		if (this.busEventServiceConnectSub) {
			this.busEventServiceConnectSub.unsubscribe();
		}
		// On connect from connections list (small popup in right top corner)
		this.busEventServiceConnectSub = this.busEventService.connect.subscribe(e => {
			this.updateSensorSocket(e.url);
		});
	}


	protected sendOutputsToSocket(outputs) {
		this.sensorsService.sensorSockets.value
		.filter(sensorSocket => {
			return sensorSocket.isConnected() && sensorSocket.isParametersRetrieved();
		})
		.forEach(sensorSocket => {
			sensorSocket.setOutputs('json_outputs', outputs);
		});
	}


	protected generateLayers(parameters) {
		parameters.guiParameters['selectedLayers'] = [[
			{
				name: 'CovidGraphs',
				componentName: 'HeartRateGraphComponent',
				selected: true
			}
		], [
			{
				name: 'CovidGraphs',
				componentName: 'HeartRateGraphComponent',
				selected: true
			}
		]];
		super.generateLayers(parameters);
	}


	protected stopSensor() {
		this.isNeedToPlayBeepSound = false;
		this.sensorsService.sensorSockets.value
		.filter(sensorSocket => {
			return sensorSocket.isConnected() && sensorSocket.isParametersRetrieved();
		})
		.forEach(sensorSocket => {
			sensorSocket.stop();
		});
	}
}
