import {AfterViewInit, Directive, OnDestroy} from '@angular/core';
import {MatFormField} from '@angular/material/form-field';
import {MatSelect} from '@angular/material/select';
import {Subscription} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatInput} from '@angular/material/input';

const SELECT_OFFSET = 24; // Offset for arrow in dropdown. 18 + 6 for padding
const INPUT_OFFSET = 6; // 6 for padding

@Directive({
	selector: '[appMatSelectAutoWidth]'
})
export class MatSelectAutoWidthDirective implements OnDestroy {

	private _valueChangeSubscription: Subscription|undefined;
	private _textElement;

	constructor(private host: MatFormField) {
		setTimeout(() => {
			if (this.host._control instanceof MatSelect) {
				this._updateSelectWidth();
				this._valueChangeSubscription = (this.host._control as MatSelect).valueChange.subscribe(() => {
					setTimeout(() => {
						this._updateSelectWidth();
					});
				});
			}
			if (this.host._control instanceof MatInput) {
				const input = this.host._elementRef.nativeElement.querySelector('input');
				this._textElement = document.createElement('span');
				this._textElement.style.fontSize = input.style.textSize;
				this._textElement.style.fontWeight = input.style.fontWeight;
				this._textElement.style.position = 'absolute';
				this._textElement.style.visibility = 'hidden';
				this.host._elementRef.nativeElement.appendChild(this._textElement);

				this._updateInputWidth(input.value);
				this._valueChangeSubscription = (this.host._control as MatInput).ngControl.valueChanges?.subscribe((value) => {
					setTimeout(() => {
						this._updateInputWidth(value);
					});
				});
			}
		});
	}

	private _updateSelectWidth(): void {
		const placeholderLabel = this.host._elementRef.nativeElement.querySelector('mat-label');
		const valueLabel = this.host._elementRef.nativeElement.querySelector('.mat-select-value-text span');
		const valueWidth = (valueLabel ? valueLabel : placeholderLabel).getBoundingClientRect().width;
		this.host._elementRef.nativeElement.style.width = (Math.ceil(valueWidth) + SELECT_OFFSET) + 'px';
	}

	private _updateInputWidth(value): void {
		this._textElement.textContent = value;
		const placeholderLabel = this.host._elementRef.nativeElement.querySelector('mat-label');
		const valueWidth = (value ? this._textElement : placeholderLabel).getBoundingClientRect().width;
		this.host._elementRef.nativeElement.style.width = (Math.ceil(valueWidth) + INPUT_OFFSET) + 'px';
	}

	ngOnDestroy() {
		if (this._valueChangeSubscription) {
			this._valueChangeSubscription.unsubscribe();
		}
	}
}
