<span class="monitoring-counter position-counter-left" [ngClass]="{'opacity-disabled': readonly}">
    <div *ngIf="buttonsPosition === 'left'" class="buttons">
        <button [disabled]="readonly" tabindex="-1" class="inc" mat-raised-button (mousedown)="changeFieldFast(step)"
				(mouseup)="changeFieldFastStop()" (mouseout)="changeFieldFastStop()"
				(click)="changeField(step)"></button>
        <button [disabled]="readonly" tabindex="-1" class="dec" mat-raised-button
				(mousedown)="changeFieldFast(step * -1)" (mouseup)="changeFieldFastStop()"
				(mouseout)="changeFieldFastStop()" (click)="changeField(step * -1)"></button>
    </div>
    <input #input
		   [disabled]="readonly"
		   appQuickNumber
		   [min]="min"
		   [size]="precision"
		   [ngModel]="control.value"
		   (keydown)="validateKeyDown($event)"
		   (blur)="updateControlValue()"
		   (keyup.enter)="updateControlValue()"
		   [attr.data-selector]="dataSelector">
    <span>{{label | translate}}</span>
    <div *ngIf="buttonsPosition === 'right'" class="buttons right">
        <button [disabled]="readonly" tabindex="-1" class="inc" mat-raised-button (mousedown)="changeFieldFast(step)"
				(mouseup)="changeFieldFastStop()" (mouseout)="changeFieldFastStop()"
				(click)="changeField(step)"></button>
        <button [disabled]="readonly" tabindex="-1" class="dec" mat-raised-button
				(mousedown)="changeFieldFast(step * -1)" (mouseup)="changeFieldFastStop()"
				(mouseout)="changeFieldFastStop()" (click)="changeField(step * -1)"></button>
    </div>
</span>
