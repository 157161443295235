<div #subToolbar class="sub-toolbar" data-selector="sub-toolbar" [class.show-sub-toolbar]="useSubToolbar && showSubToolbar"
	 (transitionstart)="subToolbarTransitionStart($event)" (transitionend)="subToolbarTransitionEnd($event)">
	<div [hidden]="!(useSubToolbar && showSubToolbarContent)">
		<ng-template #subToolbarContainer></ng-template>
	</div>
</div>
<div class="d-flex base-view-container" [ngClass]="{'h-100': !(useSubToolbar && showSubToolbar), 'show-sub-toolbar': (useSubToolbar && showSubToolbar)}">
	<div class="base-view-body"
		 [class.flex]="isDuplicateView">
		<div class="three-d"
			 [ngClass]="{
			 	 'three-d-duplicate-view-active': isDuplicateView,
				 'float': !isDuplicateView,
				 'three-d-full-width': isDuplicateView,
				 'three-d-active-recording-panel': connection?.recordingInfo.isPlayback(),
				 'three-d-right-fall-detection': !isDuplicateView && fallDetection
			 }"
			 [ngStyle]="{
			 	'background-color': isLayersContainerVisible ? 'transparent' : '#262d47'
			}">

			<ng-container *ngIf="isLayersContainerVisible; else elseBlock">
				<ng-container *ngTemplateOutlet="layersContainer;context:{
					index: 0,
					showCloseBtn: false
				}"></ng-container>
				<ng-container *ngIf="isDuplicateView">
					<ng-container *ngTemplateOutlet="layersContainer;context:{
					index: 1,
					showCloseBtn: true,
					class: 'layer-container-right'
				}"></ng-container>
				</ng-container>
			</ng-container>
			<ng-template #elseBlock>
				<ng-container *ngIf="showNoLayersContainerLoading; else elseBlock">
					<app-loading *ngIf="busEventService.restIsLoading | async; else elseBlock"
								 [title]="connection?.isConnected() ? 'LOADING' : 'CONNECTING'"
								 [attr.data-selector]="'loading-overlay'"
								 type="indeterminate" size="800" [showStatus]="connection?.isConnected()"></app-loading>
				</ng-container>
				<ng-template #elseBlock>
					<h2 class="noLayersContainerText">{{noLayersContainerText | translate}}</h2>
				</ng-template>
			</ng-template>

			<div *ngIf="showLoadingWhileConnecting && this.connection?.isConnecting()" class="loading-wrapper">
				<app-loading type="indeterminate" size="800" [title]="'CONNECTING'"></app-loading>
			</div>
		</div>

		<div class="fall-detection float" *ngIf="fallDetection">
			<span class="fall-detection-title">{{'FALL_DETECTION' | translate}}</span>
			<button class="close" (click)="closeFallDetection()" mat-raised-button></button>
			<div class="fall-detection-wrap">
				<div class="presence active">
					<div class="icon"></div>
					<span>{{'PRESENCE' | translate}}</span>
				</div>
				<div class="fall-suspected active">
					<div class="icon"></div>
					<span>{{'FALL_SUSPECTED' | translate}}</span>
				</div>
				<div class="verifying active">
					<div class="icon">
						<app-loading [size]="41"></app-loading>
					</div>
					<span>{{'VERIFYING' | translate}}</span>
				</div>
				<div class="fall-detected active">
					<div class="icon"></div>
					<span>{{'FALL_DETECTED' | translate}}</span>
				</div>
			</div>
		</div>

		<div *ngIf="connection?.recordingInfo.isPlayback()"
			 class="recording-player">
			<app-recording-player [sensorSocket]="connection"></app-recording-player>
		</div>

	</div>
</div>
<input #fileuploadSettings data-selector="scene_upload-settings-input" type="file" (change)="onFileSelect($event)" accept="application/json">
<ng-template #layersContainer let-index="index" let-class="class" let-showCloseBtn="showCloseBtn">
	<app-layers-container
		class="{{class}}"
		[class.mr-canvas]="connection && connection.isConnected() && isDuplicateView"
		[isMultipleSensors]="isMultipleSensors"
		[layers]="layers[index]"
		[data]="data"
        [geoFences]="geoFences"
		[combinedData]="combinedData"
		[rotateValue]="getRotateForSensorTrackersView(index)"
		[reflect]="getFlipForSensorTrackersView(index)"
		[postureValue]="getPostureForSensorTrackersView(index)"
		[rulerValue]="getRulerForSensorTrackersView(index)"
		[sensorValue]="getSensorForSensorTrackersView(index)"
		[cameraValue]="getCameraForSensorTrackersView(index)"
		[sensorSocketSettings]="connectionSettings"
		[connectedSensorsSettings]="connectedSensorsSettings"
		[style.width.%]="(!connection || !connection.isConnected() || !isDuplicateView) ? 100 : 50"
		[duplicateView]="isDuplicateView"
		[StandingHuman3DModel]="Standing3DModel"
		[SittingHuman3DModel]="Sitting3DModel"
		[LyingHuman3DModel]="Lying3DModel"
		[WalkingHuman3DModel]="Walking3DModel"
		[Bottle3DModel]="Bottle3DModel"
		[Fridge3DModel]="Fridge3DModel"
		[connection]="connection"
		[sensitivityValueSelect]="sensitivityValue"
		[selectedModelIds]="selectedModelIds"
		[viewIndex]="index"
		[showMenu]="showLayerControlsMenu"
		[showCloseBtn]="showCloseBtn"
		[subToolbarIsAnimated]="subToolbarIsAnimated"

		(toggleLayer)="onLayerToggle(index, $event)"
		(toggleCamera)="onCameraToggle(index, $event)"
		(sensitivityValue)="onSensitivityValueChange($event)"
		(toggleDuplicate)="onDuplicateViewToggle()"
		(ruler)="onRuler(index)"
		(rotate)="onRotate(index)"
		(flip)="onFlip(index)"
		(posture)="onPosture(index)"
		(sensor)="onViewSensorToggle(index)">
	</app-layers-container>
</ng-template>
