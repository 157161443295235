import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Input,
	NgZone,
	OnChanges, OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import * as THREE from 'three';
import {resources} from '../../../../../utils/ResourcesLoader';
import {cmToInches} from '../../../../../utils/utils';
import {AnimationClip} from 'three';
import FloorTexture from '!raw-loader!../../../../../../assets/3dobjects/Floor/WoodFloor09_col.jpg.base64';

declare const require: any;
const OrbitControls = require('three-orbit-controls')(THREE);

export enum state {
	STATE_NO_PERSON = 0,
	STATE_MOVE_TO_CENTER = 1,
	STATE_MEASURE_A = 2,
	STATE_MEASURE_B = 3,
	STATE_MEASURE_C = 4,
	STATE_DONE = 5
}

export const stateToAnimation = {
	[state.STATE_NO_PERSON]: ['Greeting'],
	[state.STATE_MOVE_TO_CENTER]: ['Pointing'],
	[state.STATE_MEASURE_B]: ['Directing'],
	[state.STATE_MEASURE_C]: ['Thinking'],
	[state.STATE_DONE]: ['Success', 'Waiting']
};

/**
 * Component displays measured data of person.
 * Used for Smart Tailor app.
 */
@Component({
	selector: 'app-smart-tailor-measurements',
	templateUrl: './smart-tailor-measurements.component.html',
	styleUrls: ['./smart-tailor-measurements.component.scss']
})
export class SmartTailorMeasurementsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

	@Input() data: any = {};
	@ViewChild('container', {static: true}) container: ElementRef;

	cmToInches: (value: number) => number;
	units = 'in';

	measurements: Array<any> = [];

	state = state;

	private clock = new THREE.Clock();
	private renderer;
	private camera;
	private scene;
	private controls;
	private actions = {};
	private currentAction;
	private mixer;
	private currentState = state.STATE_NO_PERSON;
	private isResourcesLoaded = false;
	private animationsQueue: Array<number> = [];
	private frameId: number | null = null;

	constructor(private ngZone: NgZone) {
		this.cmToInches = cmToInches;
	}

	ngOnInit() {
		this.renderer = new THREE.WebGLRenderer({antialias: true});
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(this.container.nativeElement.clientWidth, this.container.nativeElement.clientHeight);
		this.container.nativeElement.appendChild(this.renderer.domElement);

		this.camera = new THREE.PerspectiveCamera(45, this.container.nativeElement.clientWidth / this.container.nativeElement.clientHeight, 1, 2000);

		this.scene = new THREE.Scene();
		this.scene.background = new THREE.Color(0xe1dbd6);

		let AmbientLight = new THREE.AmbientLight(0xffffff, 1.5);
		this.scene.add(AmbientLight);

		let light = new THREE.DirectionalLight(0xffffff, 0.15);
		light.castShadow = true;
		light.position.set(-100, 150, 300);
		light.lookAt(0, 100, 0);
		this.scene.add(light);

		var mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(2000, 2000), new THREE.MeshPhongMaterial({
			color: 0x999999,
			depthWrite: false
		}));
		mesh.rotation.x = -Math.PI / 2;
		mesh.receiveShadow = true;
		this.scene.add(mesh);

		var grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
		(<THREE.Material>grid.material).opacity = 0.2;
		(<THREE.Material>grid.material).transparent = true;
		this.scene.add(grid);

		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.target.set(0, 100, 0);
		this.controls.maxPolarAngle = Math.PI / 2.25;

		this.initCamera();
		let mapTexture = (new THREE.TextureLoader()).load(`data:image/jpg;base64,${FloorTexture}`);

		let floorMaterial = new THREE.MeshBasicMaterial({
			map: mapTexture
		});
		let floorGeometry = new THREE.BoxGeometry(2000, 1, 2000);
		let floor = new THREE.Mesh(floorGeometry, floorMaterial);
		floor.castShadow = true;
		floor.receiveShadow = true;
		this.scene.add(floor);

		resources['Tailor'].get().then(object => {
			object.traverse(function (child) {
				if (child.isMesh) {
					child.castShadow = true;
					child.receiveShadow = true;
				}
			});

			this.mixer = new THREE.AnimationMixer(object);

			object.animations.forEach((animation: AnimationClip) => {
				let action = this.mixer.clipAction(animation),
					nameParts = animation.name.split('|'),
					name = nameParts[nameParts.length - 1];

				this.setWeight(action, 0);
				action.play();
				this.actions[name] = action;
			});
			this.scene.add(object);
			this.playAction('Waiting');
			this.isResourcesLoaded = true;
		});

		this.animate();
	}

	initCamera() {
		this.camera.position.set(100, 150, 300);
		this.controls.update();
	}

	ngOnChanges(c: SimpleChanges) {
		if (this.data && this.isResourcesLoaded) {
			if (this.data && this.data.measurements) {
				this.measurements = [];
				let i = 0,
					keys = Object.keys(this.data.measurements),
					n = keys.length;

				while (i < n) {
					this.measurements.push(keys.slice(i, i += 2).map(key => this.data.measurements[key]));
				}
			}
			if ('state' in this.data && this.currentState !== this.data.state) {
				while (this.animationsQueue.length) {
					clearTimeout(this.animationsQueue.pop());
				}
				stateToAnimation[this.data.state].forEach((animation, i, a) => {
					if (!i) {
						this.playAction(animation);
					} else {
						this.animationsQueue.push(
							window.setTimeout(() => {
								this.playAction(animation);
							}, this.actions[a[i - 1]].getClip().duration * 1000)
						);
					}
				});
				this.currentState = this.data.state;
			}
		}
	}

	public ngOnDestroy() {
		if (this.frameId != null) {
			cancelAnimationFrame(this.frameId);
		}
	}

	animate() {
		this.ngZone.runOutsideAngular(() => {
			this.frameId = requestAnimationFrame(this.animate.bind(this));
			var delta = this.clock.getDelta();
			if (this.mixer) this.mixer.update(delta);
			this.renderer.render(this.scene, this.camera);
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.onResize();
		});
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event?: Event) {
		if (this.container) {
			this.camera.aspect = this.container.nativeElement.clientWidth / this.container.nativeElement.clientHeight;
			this.camera.updateProjectionMatrix();
			this.renderer.setSize(this.container.nativeElement.clientWidth, this.container.nativeElement.clientHeight);
		}
	}

	private playAction(actionName) {
		let action = this.actions[actionName];

		this.setWeight(action, 1);

		if (this.currentAction) {
			this.currentAction.crossFadeTo(action, 2, false);
		}
		this.currentAction = action;
	}

	private setWeight(action, weight) {
		action.enabled = true;
		action.setEffectiveTimeScale(1);
		action.setEffectiveWeight(weight);
	}

}
