import {Component, Input, ViewChild} from '@angular/core';
import {PlaybackState, SensorSocket} from '../../services/system/sensor-socket';
import {NouisliderComponent} from 'ng2-nouislider';
import {BusEventService} from '../../services/bus-event.service';

/**
 * Panel for controlling the playback of a previously recorded data block from the sensor.
 * Placed at bottom of page below of BaseViewComponent.
 */
@Component({
	selector: 'app-recording-player',
	templateUrl: './recording-player.component.html',
	styleUrls: ['./recording-player.component.scss']
})
export class RecordingPlayerComponent {

	@Input() sensorSocket: SensorSocket;
	@ViewChild(NouisliderComponent, {static: true}) slider: NouisliderComponent;

	PlaybackState = PlaybackState;

	noAnimate = false;
	private lastFrame;
	private isStopping = false;
	private isDragging = false;

	constructor(private busEventService: BusEventService) {
	}

	// Start dragging
	start(e) {
		this.isDragging = true;
		this.noAnimate = true;
		this.sensorSocket.recordingInfo.onSliderMouseDown();
	}

	// Stop dragging
	end(e) {
		this.isDragging = false;
		this.noAnimate = false;
		this.sensorSocket.recordingInfo.onSliderMouseUp();
	}

	// Play / Pause / Stop
	changeState(newState) {
		if (newState === PlaybackState.PLAYING && this.sensorSocket.recordingInfo.state !== PlaybackState.PAUSED) {
			this.busEventService.targetClearSelect.emit();
		}
		this.noAnimate = true;
		this.isStopping = true;
		this.sensorSocket.changeRecordingState(newState);
		setTimeout(() => {
			this.noAnimate = false;
			this.isStopping = false;
		});
	}

	onSliderChanged(frame_number) {
		if (typeof frame_number === 'number') {
			if (!this.isDragging) {
				this.noAnimate = true;
			}
			this.lastFrame = frame_number;
			this.sensorSocket.recordingInfo.onSliderChange(frame_number);
			if (!this.isDragging) {
				setTimeout(() => {
					this.noAnimate = false;
				}, 250);
			}
		}
	}

	getCurrentFrame(frame_number) {
		if (this.noAnimate && !this.isStopping) {
			return this.lastFrame;
		}
		this.lastFrame = frame_number;
		return frame_number;
	}
}
