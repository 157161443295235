import {ConnectionType} from '../../../consts';
import {BusEventService} from '../../../services/bus-event.service';
import {Subscription} from 'rxjs';
import {ConnectionData} from '../../../models/models';
import {Connection, ConnectionStatus} from '../../../services/system/connection';
import {Directive} from '@angular/core';

/**
 * Base abstract connector
 */
@Directive()
export abstract class BackendConnectorBaseComponent {

	connection: Connection;
	ConnectionStatus = ConnectionStatus;
	isConnectionPopupShowed: boolean;
	isNetworkDisconnectedPopupShowed: boolean;
	ip: string;
	connectionStatus = 'NOT_CONNECTED';
	showSocketList = false;
	connectedSensorName: string;

	protected abstract type: ConnectionType;
	protected connectEvent: ConnectionData;
	protected disconnectEvent;
	protected isDestroyed = false;
	protected subscriptions: Array<Subscription> = [];
	protected disconnectReason: string;

	constructor(protected busEventService: BusEventService) {
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	onConnectClick() {
		if (this.canConnect()) {
			this.connect();
		} else if (this.canDisconnect()) {
			this.disconnect();
		}
		this.hideConnectionPopup();
	}

	public showNetworkDisconnectedPopup(): void {
		this.isNetworkDisconnectedPopupShowed = true;
	}

	public hideNetworkDisconnectedPopup(): void {
		this.isNetworkDisconnectedPopupShowed = false;
	}

	public showConnectionPopup(): void {
		this.isConnectionPopupShowed = true;
		this.hideNetworkDisconnectedPopup();
	}

	public hideConnectionPopup(): void {
		this.isConnectionPopupShowed = false;
	}

	public isNetworkDisconnectedIconShown(): boolean {
		return this.canConnect() && ['timeout', 'socketerror'].includes(this.disconnectReason);
	}

	connect() {
		this.busEventService.connect.emit(
			Object.assign({}, this.connectEvent, {
				fromConnector: true
			})
		);
		this.updateIP();
	}

	disconnect() {
		this.busEventService.disconnect.emit(this.disconnectEvent);
	}

	onConnectInConnectionList(data: ConnectionData) {
		this.ip = data.url;
		if (this.canDisconnect()) {
			this.disconnect();
		}
		this.connect();
		this.hideConnectionPopup();
	}

	public abstract updateIP();

	public abstract getConnectionStatus(): ConnectionStatus;

	public connectToURL(url: string) {

	}

	public canDisconnect(): boolean {
		return this.getConnectionStatus() === ConnectionStatus.CONNECTED;
	}

	public canConnect(): boolean {
		return this.getConnectionStatus() === ConnectionStatus.DISCONNECTED;
	}

	protected setConnectionStatus(status: string) {
		this.connectionStatus = status;
	}
}
