<div #wrap class="add-sensor__wrap">
    <h1 class="color-accent fs-24 add-sensor__heading">{{ 'ADD_CONNECTIONS' | translate}}</h1>
    <div class="add-sensor__table-container" [perfectScrollbar]="{suppressScrollX: false, minScrollbarLength: 15}" [disabled]="disabledScroll">
        <table class="add-sensor__table w-100">
        <thead>
            <tr class="fixed-header">
				<th class="fs-11 uppercase" width="180">
					<div class="d-flex align-items-center">
						<span>{{'IP' | translate}}</span>
					</div>
				</th>
				<th class="fs-11 uppercase" width="161">
					<div class="d-flex align-items-center">
						<span>{{'NAME' | translate}}</span>
					</div>
				</th>
				<th class="fs-11 uppercase" width="70">
					<div class="d-flex align-items-center">
					</div>
				</th>
			</tr>
            <tr class="invisible">
                <th class="fs-11 uppercase" width="180">
                    <div class="d-flex align-items-center">
                        <span>{{'IP' | translate}}</span>
                    </div>
                </th>
                <th class="fs-11 uppercase" width="161">
                    <div class="d-flex align-items-center">
                        <span>{{'NAME' | translate}}</span>
                    </div>
                </th>
                <th class="fs-11 uppercase" width="70">
                    <div class="d-flex align-items-center">
                    </div>
                </th>
            </tr>
        </thead>

          <tbody>
          <tr *ngFor="let item of sensorsList; let i = index; let last = last;">
              <td>
                  <input  type="text"
                          class="bg-transparent border-0 color-white ip-input"
						  data-selector="add-new-connection_ip_input"
                          [(ngModel)]="item.ip"
                          [ngClass]="{'input-editable': item.isEdit}"
                          placeholder="{{ 'ENTER_IP_ADDRESS' | translate }}"
						  (keyup.enter)="connectToNewSensor()"
                          (keyup)="blurInput($event, item, 'ip')">
				  <span *ngIf="item.errors.ip" data-selector="add-new-connection_ip-error_text" class="error">{{item.errors.ip | translate}}</span>
              </td>
              <td>
                  <input type="text"
                         class="bg-transparent border-0 color-white"
						 data-selector="add-new-connection_name_input"
                         [(ngModel)]="item.name"
                         [ngClass]="{'input-editable': item.isEdit}"
                         placeholder="{{ 'ENTER_NAME' | translate }}"
						 (keyup.enter)="connectToNewSensor()"
                         (keyup)="blurInput($event, item, 'name')">
				  <span *ngIf="item.errors.name" data-selector="add-new-connection_name-error_text" class="error">{{item.errors.name | translate}}</span>
              </td>
              <td>
                  <div class="add-sensor__actions-wrap d-flex">
                      <button data-selector="add-new-connection_delete-sensor_button" class="add-sensor__actions-icon remove pointer"  mat-raised-button (click)="removeSensor(i)"></button>
                      <button data-selector="add-new-connection_add-sensor_button" *ngIf="last && i < 9"
                              class="add-sensor__actions-icon add pointer"
                              mat-raised-button
                              (click)="addSensor(sensor)"></button>
                  </div>
              </td>
          </tr>
          </tbody>
      </table>
    </div>
      <div class="buttons">
          <button data-selector="add-new-connection_cancel_button" class="add-sensor-btn" mat-raised-button (click)="onClose()">{{'CANCEL' | translate}}</button>
          <button data-selector="add-new-connection_add_button" class="add-sensor-btn color-white" mat-raised-button (click)="connectToNewSensor()" [disabled]="!connectEnabled()">{{'ADD' | translate}}</button>
      </div>
</div>
