import {Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {HeatmapTargetTrackerBase} from '../heatmap-target-tracker.base';
import {BusEventService} from '../../../../../../services/bus-event.service';
import {colorscale, rotateAndReflect} from '../../../../../../utils/ImageUtils';
import * as d3 from 'd3';
import {getSensorPosition2D, getSensorRotation2D, originPosition} from '../../../../../../utils/utils';

/**
 * Component can render positions of people.
 * Used for InCar, Tracker app.
 */
@Component({
	selector: 'app-heatmap',
	templateUrl: './heatmap.component.html',
	styleUrls: ['./heatmap.component.scss']
})
export class HeatmapComponent extends HeatmapTargetTrackerBase implements OnChanges {

	@Input() isRaw = false;

	@ViewChild('canvas', {static: true}) canvas: ElementRef;
	@ViewChild('rawCanvas', {static: true}) rawCanvas: ElementRef;

	private paths;
	public xScalePadding = 0;
	public yScalePadding = 0;

	@HostListener('window:resize', ['$event'])
	public onResize(event: Event) {
		setTimeout(() => {
			this.resizeViewBox();
		});
	}

	constructor(protected hostElement: ElementRef,
				protected busEventService: BusEventService) {
		super(hostElement, busEventService);
	}

	ngOnChanges(c: SimpleChanges) {
		if ('data' in c || 'rotate' in c || 'reflect' in c) {
			this.drawRawData();
		}

		if (('parameters' in c) && this.parameters) {
			if (!this.isFloorPlan) {
				this.arena = this.parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'];
			} else if (this.isFloorPlan) {
				this.updateGlobalArena();
			}

			this.drawCanvas();
		}
		if ('isRaw' in c && this.isRaw) {
			setTimeout(() => {
				this.resizeViewBox();
			});
		}
	}

	drawRawData() {
		if (this.data && this.data.data && this.data.data instanceof Array && this.data.data.length &&
			this.data.data[0] && this.data.data[0].length) {
			/**
			 * Heatmap should be fliped on x axis.
			 * Might be changed.
			 */
			const data = rotateAndReflect(this.data.data, !this.reflect, this.rotate);
			let data1D = new Array(data.length * data[0].length);
			for (let j = 0; j < data[0].length; j++) {
				for (let i = 0; i < data.length; i++) {
					data1D[j * data.length + i] = data[i][j];
				}
			}

			let colors = colorscale.domain([d3.min(data1D), d3.max(data1D) || 1]),
				path = d3.geoPath().projection(this.scale(data.length, data[0].length));

			this.paths = d3
			.contours()
			.size([data.length, data[0].length])
			.thresholds(100)(data1D)
			.map((contour, i) => {
				return {
					key: i,
					d: path(contour),
					fill: colors(contour.value)
				};
			});
		} else {
			this.paths = [];
		}

		this.drawCanvas();
	}

	getPolygonPoints(coordinates) {
		return [coordinates[0], coordinates[1], coordinates[3], coordinates[2]].map(pair => {
			let c = this.getPosition(pair[0], pair[1], 0, this.getAxes());
			return c.join(' ');
		}).join(',');
	}

	protected drawCanvas() {
		var img = new Image();
		var bg = 'rgb(0, 0, 131)';
		var src = 'data:image/svg+xml;utf8,' + `
			<svg xmlns="http://www.w3.org/2000/svg"
				 viewBox="0 0 ${this.VIEWBOX_W} ${this.VIEWBOX_H}"
				 preserveAspectRatio="none"
				 style="background: ${bg};"
				 height="${100 / this.yScaleCoefficient}%"
				 width="${100 / this.xScaleCoefficient}%">`;
		if (this.isRaw) {
			let data = [[0]];
			/**
			 * Heatmap should be fliped on x axis.
			 * Might be changed.
			 */
			if (this.data && this.data.data && this.data.data instanceof Array && this.data.data.length && this.data.data[0] && this.data.data[0].length) {
				data = rotateAndReflect(this.data.data, !this.reflect, this.rotate);
			}
			const data1D = new Array(data.length * data[0].length);
			for (let j = 0; j < data[0].length; j++) {
				for (let i = 0; i < data.length; i++) {
					data1D[j * data.length + i] = data[i][j];
				}
			}

			let color = colorscale.domain([d3.min(data1D), d3.max(data1D) || 1]);
			const n = data.length;
			const m = data[0].length;
			this.rawCanvas.nativeElement.width = n;
			this.rawCanvas.nativeElement.height = m;
			const context = this.rawCanvas.nativeElement.getContext('2d');
			const image = context.createImageData(n, m);
			for (let j = 0; j < m; j++) {
				for (let i = 0; i < n; i++) {
					const k = j * n + i;
					// @ts-ignore
					const c = d3.rgb(color(data1D[k]));
					image.data[k * 4 + 0] = c.r;
					image.data[k * 4 + 1] = c.g;
					image.data[k * 4 + 2] = c.b;
					image.data[k * 4 + 3] = 255;
				}
			}
			context.putImageData(image, 0, 0);
		} else if (this.paths) {
			src += this.paths.map(o => `<path key="${o.key}" d="${o.d}" fill="${o.fill}"></path>`).join('\n');
		}
		if (this.data.InCarCabinVertexs) {
			const points = this.getPolygonPoints([
				this.data.InCarCabinVertexs[1], this.data.InCarCabinVertexs[2],
				this.data.InCarCabinVertexs[0], this.data.InCarCabinVertexs[3]
			]);
			src += `<polygon points="${points}" fill="rgba(59, 177, 205, .2)"></polygon>`;
		}

		if (this.getAxesAttr() === 'xy') {
			let zones: Array<any> = [];
			if ((this.data.RetailAreas && this.data.RetailAreaNames) || zones && zones.length) {
				let helperSVG: SVGElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
					helperSVGText: SVGTextElement = document.createElementNS('http://www.w3.org/2000/svg', 'text'),
					fontSize = this.clientHeight / this.clientWidth * 2;

				document.body.appendChild(helperSVG);
				helperSVG.setAttributeNS(null, 'viewBox', `0 0 ${this.VIEWBOX_W}  ${this.VIEWBOX_H}`);
				helperSVG.setAttributeNS(null, 'width', `${100 / this.yScaleCoefficient}%`);
				helperSVG.setAttributeNS(null, 'height', `${100 / this.xScaleCoefficient}%`);
				helperSVG.setAttributeNS(null, 'style', `overflow: hidden;top: -100%;left: -100%;position: absolute;opacity: 0;`);

				helperSVGText.setAttributeNS(null, 'font-size', `${fontSize}px`);
				helperSVGText.setAttributeNS(null, 'font-family', 'Roboto Black,sans-serif');
				helperSVGText.setAttributeNS(null, 'fill', 'rgba(59, 177, 205, .7)');

				helperSVG.appendChild(helperSVGText);

				if (this.data.RetailAreas && this.data.RetailAreaNames) {
					let arenas: Array<any> = [],
						i = 0;
					this.data.RetailAreas.forEach(a => {
						if (!arenas[i]) {
							arenas[i] = [];
						}
						if (a[0] !== 'NaN') {
							arenas[i].push(a);
						} else {
							i++;
						}
					});
					arenas.forEach((arena, i) => {
						let zone = [arena[1], arena[2], arena[0], arena[3]];
						let textCoordinates = this.getPosition(arena[1][0], arena[1][1], 0, this.getAxes());
						let name = encodeURIComponent(this.data.RetailAreaNames![i + 1]);
						helperSVGText.innerHTML = this.data.RetailAreaNames![i + 1];
						let arenaWidth = this.getPosition(zone[0][0], zone[0][1], 0, this.getAxes())[0] - this.getPosition(zone[1][0], zone[1][1], 0, this.getAxes())[0];
						let textWidth = helperSVGText.getBBox().width;
						src += `<text x="${textCoordinates[0] + ((arenaWidth - textWidth) / 2)}" y="${textCoordinates[1] - 0.5}" font-size="${fontSize}px" font-family="Roboto Black,sans-serif" fill="rgba(59, 177, 205, .7)">${name}</text><polygon points="${this.getPolygonPoints(zone)}" fill="rgba(59, 177, 205, .2)"></polygon>`;
					});
				} else if (zones && zones.length) {
					zones.forEach((zone, i) => {
						let textCoordinates = this.getPosition(zone.coordinates[1][0], zone.coordinates[1][1], 0, this.getAxes());
						let name = encodeURIComponent(zone.name);
						helperSVGText.innerHTML = zone.name;
						let arenaWidth = this.getPosition(zone.coordinates[0][0], zone.coordinates[0][1], 0, this.getAxes())[0] - this.getPosition(zone.coordinates[1][0], zone.coordinates[1][1], 0, this.getAxes())[0];
						let textWidth = helperSVGText.getBBox().width;
						src += `<text x="${textCoordinates[0] + ((arenaWidth - textWidth) / 2)}" y="${textCoordinates[1] - 0.5}" font-size="${fontSize}px" font-family="Roboto Black,sans-serif" fill="rgba(59, 177, 205, .7)">${name}</text><polygon points="${this.getPolygonPoints(zone.coordinates)}" fill="rgba(59, 177, 205, .2)"></polygon>`;
					});
				}
				document.body.removeChild(helperSVG);
			}
		}

		src += '</svg>';
		img.src = src;
		img.addEventListener('load', () => {
			this.canvas.nativeElement.getContext('2d').clearRect(
				0, 0, this.clientWidth * this.xScaleCoefficient, this.clientHeight * this.yScaleCoefficient);
			this.canvas.nativeElement.getContext('2d').drawImage(img, 0, 0);
		});
	}

	protected scale(xSize, ySize) {
		var VIEWBOX_W = this.VIEWBOX_W,
			VIEWBOX_H = this.VIEWBOX_H;

		return d3.geoTransform({
			point: function (x, y) {
				this.stream.point(x * VIEWBOX_W / xSize, y * VIEWBOX_H / ySize);
			}
		});
	}
}
