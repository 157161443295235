import {Tracker3DStageBase} from '../../../../../base-classes/Tracker3DStageBase';

declare const require: any;
const THREE = require('three');

/**
 * Renders 3D scenes with monitored area in settings window.
 */
export class Settings3DStage extends Tracker3DStageBase {

    axesHelper;
    axesHelper2;

    constructor(div, arena, status, parameters) {
        super(div, arena, status, parameters, false, 'Orthographic');
		let b = require(`src/assets/fonts/Roboto-Bold/Roboto_Bold.json`);
		this.fontBold = (new THREE.FontLoader()).parse(b);

		this.mainGroup.remove(this.gridHelper);
		this.mainGroup.remove(this.arenaPlanes[0]);// Floor
		this.arenaPlanes[1].material.color.setHex(0x3bb1cd);
		this.arenaPlanes[1].material.needsUpdate = true;
		this.addAxes();
		this.isCameraTargetNeedUpdate = false;
		this.controls.maxPolarAngle = Math.PI / 2;
		this.controls.addEventListener('change', (e) => {
			['X', 'Y', 'Z'].forEach(axis => {
				this[`label${axis}`].lookAt(this.camera.position);
			});
		});
		this.arenaPlanesStatus = [true, true, true, true, true, true, true];
		this.mainGroup.add(this.arenaPlanes[1]);
		this.mainGroup.add(this.arenaPlanes[2]);
		this.mainGroup.add(this.arenaPlanes[3]);
		this.mainGroup.add(this.arenaPlanes[4]);
		this.mainGroup.add(this.arenaPlanes[5]);
		this.mainGroup.add(this.arenaPlanes[6]);
    }

	private fontBold;
	labelX;
	labelY;
	labelZ;

    addAxes() {
		['X', 'Y', 'Z'].forEach(axis => {
			let mesh = new THREE.Mesh(new THREE.BufferGeometry(), new THREE.MeshBasicMaterial({color: 0x3bb1cd, side: THREE.DoubleSide}));
			let b = this.fontBold.generateShapes(axis, 10);
			let shapeGeometry = new THREE.ShapeGeometry(b);
			shapeGeometry.computeBoundingBox();
			// TODO rename xMid
			let xMid = 0.1 * (shapeGeometry.boundingBox.max.x - shapeGeometry.boundingBox.min.x);
			mesh.geometry.fromGeometry(shapeGeometry);
			mesh.position.set(...this.mapEVKaxesToThreeJS([axis === 'X' ? xMid : 0, axis === 'Y' ? xMid : 0, axis === 'Z' ? xMid : 0], false));
			this.mainGroup.add(mesh);
			this[`label${axis}`] = mesh;
			this[`label${axis}`].lookAt(this.camera.position);
		});
		this.axesHelper = new THREE.AxesHelper(500);
		this.axesHelper2 = new THREE.AxesHelper(500);
		this.axesHelper2.scale.set(-1, -1, -1);
		this.mainGroup.add( this.axesHelper );
		this.mainGroup.add( this.axesHelper2 );
		this.addArrows();
	}

	addArrows() {
		const origin = new THREE.Vector3(0, 0, 0);
		const length = 0.25 * this.SCALING_FACTOR;
		const color = 0x3bb1cd;

		// EVK axes representation: x, y, z
		const EVKAxes = [
			[1, 0, 0],
			[0, 1, 0],
			[0, 0, 1]
		];

		EVKAxes.forEach((vector: [number, number, number]) => {
			const dir = new THREE.Vector3(...this.mapEVKaxesToThreeJS(vector, false)).normalize();
			this.mainGroup.add(new THREE.ArrowHelper(dir, origin, length, color, 0.1 * this.SCALING_FACTOR, 0.05 * this.SCALING_FACTOR));
		});
	}


	update(data, arena, status, isDisplaySensor, reflect, posture) {
		super.update(data, arena, status, isDisplaySensor, reflect, posture);
	}
}
