import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule} from '@angular/platform-browser';
import {InjectionToken, Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ContainerComponent} from './components/container/container.component';
import {LoginComponent, LoginModalComponent} from './components/login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {SettingsComponent} from './components/settings/settings.component';
import {MaterialModule} from './modules/material/material.module';
import {DataService} from './services/system/data.service';
import {RoutingModule} from './modules/routing/routing.module';
import {BaseViewComponent} from './components/base-view/base-view.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NouisliderModule} from 'ng2-nouislider';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SettingsService} from './services/settings.service';
import {ModalService} from './services/modal.service';
import {ModalMessageComponent} from './components/shared/modal-message/modal-message.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {LoadingComponent} from './components/shared/loading/loading.component';
import {ConnectionStatusComponent} from './components/shared/connection-status/connection-status.component';
import {BusEventService} from './services/bus-event.service';
import {UserInputComponent} from './components/shared/user-input/user-input.component';
import {FlowModalComponent} from './components/shared/flow-modal/flow-modal.component';
import {AddNewConnectionsComponent} from './components/shared/add-new-sensor/add-new-connections.component';
import {ConnectToRemoteHostsComponent} from './components/shared/connect-to-remote-hosts/connect-to-remote-hosts.component';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {PageGuard} from './guards/page.guard';
import {LoadingSmallComponent} from './components/shared/loading-small/loading-small.component';
import {UserComponent} from './components/user/user.component';
import {SVGLayerComponent} from './components/layers/SVGLayer';
import {QuickNumberDirective} from './directives/quick-number.directive';
import {RestService} from './services/system/rest.service';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './modules/shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import {ConfigurationService} from './services/configuration.service';
import {DynamicToolbarComponent} from './components/shared/toolbars/dynamic-toolbar/dynamic-toolbar.component';
import {ToolbarEvkComponent} from './components/shared/toolbars/toolbar-evk/toolbar-evk.component';
import {ToolbarSensorsComponent} from './components/shared/toolbars/toolbar-sensors/toolbar-sensors.component';
import {BackendConnectorRestComponent} from './components/shared/backend-connector/backend-connector-rest/backend-connector-rest.component';
import {BackendConnectorMqttComponent} from './components/shared/backend-connector/backend-connector-mqtt/backend-connector-mqtt.component';
import {ModalErrorComponent} from './components/shared/modal-message/ModalErrorComponent';
import {SensorsService} from './services/system/sensors.service';
import {SpacesComponent} from './components/settings/spaces/spaces.component';
import {CookieService} from 'ngx-cookie-service';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule} from '@angular-material-components/datetime-picker';
import {SubToolbarFloorPlanComponent} from './applications/smart-building/components/toolbars/sub-toolbar-floor-plan/sub-toolbar-floor-plan.component';
import {MatRippleModule} from '@angular/material/core';
import { ModalPromptComponent } from './components/shared/modal-prompt/modal-prompt.component';
import {ThreeJSLayerComponent} from './components/layers/ThreeJSLayer';

export const MODULE_PATH = new InjectionToken('');

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../assets/i18n/${lang}.json`));
	}
}

export let InjectorInstance: Injector;

@NgModule({
	declarations: [
		AppComponent,
		ContainerComponent,
		LoginModalComponent,
		SidebarComponent,
		SettingsComponent,
		SpacesComponent,
		BaseViewComponent,
		ModalMessageComponent,
		ModalErrorComponent,
		ConnectionStatusComponent,
		UserInputComponent,
		FlowModalComponent,
		AddNewConnectionsComponent,
		ConnectToRemoteHostsComponent,
		LoginComponent,
		LoadingSmallComponent,
		UserComponent,
		SVGLayerComponent,
		QuickNumberDirective,
		DynamicToolbarComponent,
		ToolbarEvkComponent,
		ToolbarSensorsComponent,
		BackendConnectorRestComponent,
		BackendConnectorMqttComponent,
		SubToolbarFloorPlanComponent,
		ModalPromptComponent,
		ThreeJSLayerComponent,
	],
	entryComponents: [
		SettingsComponent,
		ModalMessageComponent,
		LoadingComponent,
		ConnectionStatusComponent,
		AddNewConnectionsComponent,
		ConnectToRemoteHostsComponent,
		UserInputComponent,
		FlowModalComponent,
		LoginModalComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		PerfectScrollbarModule,
		NgxChartsModule,
		MaterialModule,
		NouisliderModule,
		FormsModule,
		ReactiveFormsModule,
		RoutingModule,
		VirtualScrollerModule,
		HammerModule,
		HttpClientModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		HammerModule,
		HttpClientModule,
		NgxMatDatetimePickerModule,
		NgxMatNativeDateModule,
		MatRippleModule
	],
	providers: [
		AuthGuard,
		PageGuard,
		SensorsService,
		DataService,
		SettingsService,
		ModalService,
		BusEventService,
		RestService,
		ConfigurationService,
		CookieService,
		{
			provide: MODULE_PATH,
			useValue: ''
		},
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: {suppressScrollX: false}
		}
	],
	exports: [
		LoadingComponent,
		SubToolbarFloorPlanComponent,
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}
