import {Component, Injector, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {ToolbarService} from '../../../../../services/toolbar.service';
import {RestService} from '../../../../../services/system/rest.service';
import {ToolbarBaseComponent} from '../../../../../components/shared/toolbars/toolbar.base.component';
import {BusEventService} from '../../../../../services/bus-event.service';
import {ConnectionData, RetailLocation, RetailFloor, RetailGeoFence, RetailGeoFenceType} from '../../../../../models/models';
import {ConnectionType, LS_SmartRetailTimezone, MAT_DATE_FORMATS} from '../../../../../consts';
import {NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatMenuTrigger} from '@angular/material/menu';
import * as ct from 'countries-and-timezones';
import {StorageService} from '../../../../../services/storage.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

/**
 * Component with analytics page filters and connection input in the top of page.
 */
@Component({
	selector: 'app-toolbar-analytics',
	templateUrl: './toolbar-analytics.component.html',
	styleUrls: ['./toolbar-analytics.component.scss'],
	providers: [
		{ provide: NGX_MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS }
	],
	animations: [
		trigger('fade', [
			transition('void => *', [
				style({opacity: 0, width: 0, minWidth: 0, margin: 0}),
				animate(250, style({width: '*', minWidth: '*', margin: '*'})),
				animate(250, style({opacity: 1}))
			]),
			transition('* => void', [
				animate(250, style({opacity: 0})),
				animate(250, style({width: 0, minWidth: 0, margin: 0}))
			])
		])
	]
})
export class ToolbarAnalyticsComponent extends ToolbarBaseComponent implements OnInit {
	environment = environment;
	geoFences: Array<RetailGeoFence> = [];
	floors: Array<RetailFloor> = [];
	selectedFloorsByLocation: Array<number> = [];
	locations: Array<RetailLocation> = [];
	locationNames: any = {};
	geoFencesNames: any = '';
	endDateMax = new Date((new Date).setHours(23, 59));
	rippleColor = getComputedStyle(document.documentElement).getPropertyValue('--button-ripple-color');
	timePopover;
	timezones;
	savedTimezoneValue;

	constructor(public toolbarService: ToolbarService,
				public rest: RestService,
				public busEventService: BusEventService,
				protected injector: Injector,
				protected storageService: StorageService) {
		super(injector);
		this.timePopover = {
			start_time: this.toolbarService.analyticsForm.get('start_time')?.value,
			end_time: this.toolbarService.analyticsForm.get('end_time')?.value
		};
	}

	ngOnInit(): void {
		this.timezones = Object.values(ct.getAllTimezones())
			.sort((a: any, b: any) => ('' + a.country).localeCompare(b.country))
			.map((timezone: any) => ({
				id: timezone.name,
				name: `${timezone.country || ''} | ${timezone.name} (UTC${timezone.dstOffsetStr})`
			}));
		if (this.rest.isConnected) {
			this.loadFiltersValues();
		}
		this.subscriptions.push(this.busEventService.connect
			.subscribe((data: ConnectionData) => {
				if (data.type === ConnectionType.REST) {
					this.loadFiltersValues();
				}
			}));
		this.subscriptions.push(this.toolbarService.analyticsForm.controls.building_id.valueChanges.subscribe(v => {
			let selectedFloorsIds = this.floors.filter(f => f.parent_building === v).map(f => f.id as number);
			this.selectedFloorsByLocation = selectedFloorsIds;
			this.toolbarService.analyticsForm.patchValue({
				floor_ids: selectedFloorsIds,
				location_ids: []
			});
			this.updateFloorId(selectedFloorsIds[0]);
		}));
		this.subscriptions.push(this.toolbarService.analyticsForm.controls.location_ids.valueChanges.subscribe(() => {
			this.geoFencesNames = this.toolbarService.analyticsForm.controls.location_ids.value.map(id => this.geoFences.find(g => g.id === id)?.location_name).join(', ');
		}));
	}

	onRefreshClick(): void {
		if (this.toolbarService.analyticsForm.valid) {
			this.toolbarService.onRefreshClick.emit();
		}
	}

	public onTimezoneChanged(ev: MatAutocompleteSelectedEvent) {
		this.storageService.setItem(LS_SmartRetailTimezone, ev.option.value);
	}

	public onTimezoneInputFocus(input): void {
		this.savedTimezoneValue = input.value;
		input.value = '';
	}

	public onTimezoneInputBlur(event, input): void {
		input.value = this.savedTimezoneValue;
		input.blur();
	}

	public onTimezoneInputKeydown(input): void {
		setTimeout(() => {
			this.savedTimezoneValue = input.value;
		});
	}

	private loadFiltersValues() {
		this.rest.getGeoFences().then(response => {
			this.geoFences = response;
		});
		this.rest.getFloors().then(response => {
			this.floors = response;
		});
		this.rest.getLocations().then(response => {
			this.locations = response;
			this.locationNames = {};
			this.locations.forEach(location => {
				this.locationNames[location.id as number] = location.building_name;
			});
		});
	}

	public onResetFilters(): void {
		this.toolbarService.resetAnalyticsForm();
		this.clearDateValidators();
	}

	public onDateChanged() {
		this.clearDateValidators();
	}

	private clearDateValidators() {
		// Fix bug validation: [min] [max] are not updated after changes or resetting the filters and it makes it invalid.
		// Limitation in calendar UI works great so we don't need these validators.
		this.toolbarService.analyticsForm.controls.end_date.clearValidators();
		this.toolbarService.analyticsForm.controls.start_date.clearValidators();
	}

	public saveTime(menu: MatMenuTrigger) {
		this.toolbarService.analyticsForm.get('start_time')?.setValue(new Date(this.timePopover.start_time));
		this.toolbarService.analyticsForm.get('end_time')?.setValue(new Date(this.timePopover.end_time));
		menu.closeMenu();
	}

	private updateFloorId(floor_id: number) {
		this.toolbarService.floorId = floor_id;
		this.toolbarService.analyticsForm.patchValue({floor_id: floor_id});
	}
}
