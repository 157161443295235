import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {HeatmapTargetTrackerBase} from '../heatmap-target-tracker.base';
import {BusEventService} from '../../../../../../services/bus-event.service';
import {axesSystem2D, getColor, getSensorPosition2D, getSensorRotation2D, meterToFeet, originPosition} from '../../../../../../utils/utils';
import hubCircleSVG from '!raw-loader!../../../../../../../assets/images/svg/3d-scene/hub-circle.svg';
import * as d3 from 'd3';
import {SelectingTargetDataGraphBehavior} from '../../../../../../consts';
import {Layer} from '../../../../../../services/configuration.service';

/**
 * Component can render positions of people.
 * Used for InCar, Tracker app.
 */
@Component({
	selector: 'app-target-tracker',
	templateUrl: './target-tracker.component.html',
	styleUrls: ['./target-tracker.component.scss']
})
export class TargetTrackerComponent extends HeatmapTargetTrackerBase implements OnChanges {

	@Input() axesEnabled = true;
	@Input() ruler = false;
	@Input() isSensorVisible = true;
	@Input() postureValue = false;
	@Input() layer: Layer;

	@ViewChild('rulerEl') rulerEl: ElementRef;

	isTargetsSelectable = false;
	// TODO - it's a quick fix, need to refactor to auto scale
	MAX_AXES_LINES = 15;
	SENSOR_H = 3;
	SENSOR_W = 3;

	rulersLabels = [
		['X', 'Y'],
		['X', 'Z'],
		['Y', 'Z']
	];

	protected isCoverageFeet = false;

	private hubCircleSVG = hubCircleSVG;
	private baseSVGColorToChange = '#4CDA64';

	constructor(protected hostElement: ElementRef,
				protected busEventService: BusEventService) {
		super(hostElement, busEventService);
	}

	ngOnChanges(c: SimpleChanges) {
		if (('parameters' in c) && this.parameters) {
			if (!this.isFloorPlan) {
				this.arena = this.parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'];
			} else if (this.isFloorPlan) {
				this.updateGlobalArena();
			}
			this.isTargetsSelectable = this.parameters['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.UponTargetSelection;
			this.isCoverageFeet = this.parameters['guiParameters']['isCoverageFeet'];

			this.drawAxes();
		}
		if ('originPosition' in c) {
			this.originalOriginPosition = this.originPosition;
		}
		if ('ruler' in c || 'postureValue' in c || 'rotate' in c || 'reflect' in c) {
			this.drawAxes();
		}
	}

	getImageSrc(index) {
		if (this.isFloorPlan) {
			return `assets/images/svg/3d-scene/Icon_Red_Dot.svg`;
		} else {
			return `assets/images/svg/3d-scene/Icon_Standing.svg`;
		}
	}

	getHubCircleImageSrc(index) {
		const postureSVG = this.hubCircleSVG.replace(new RegExp(this.baseSVGColorToChange, 'gmi'), getColor(this.data.targetsIdData![index]));
		return `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(postureSVG)))}`;
	}

	trackByRPMdataId(index, item) {
		return item.id;
	}

	onMouseDown(id) {
		if (this.isTargetsSelectable) {
			this.busEventService.targetSelected.emit(id);
		}
	}

	drawAxes() {
		setTimeout(() => {
			if (this.ruler && this.rulerEl) {
				let axes = this.getAxes(),
					domain = [[this.isCoverageFeet ? meterToFeet(this.arena[axes[0] * 2]) : this.arena[axes[0] * 2], this.isCoverageFeet ? meterToFeet(this.arena[axes[0] * 2 + 1]) : this.arena[axes[0] * 2 + 1]],
						[this.isCoverageFeet ? meterToFeet(this.arena[axes[1] * 2]) : this.arena[axes[1] * 2], this.isCoverageFeet ? meterToFeet(this.arena[axes[1] * 2 + 1]) : this.arena[axes[1] * 2 + 1]]];

				domain[0] = (this.reflect) ? [domain[0][1], domain[0][0]] : domain[0];
				Array(this.rotate)
				.fill(undefined)
				.map(_ => (domain = [domain[1], [domain[0][1], domain[0][0]]]));
				const svg = d3.select(this.rulerEl.nativeElement);
				svg.selectAll('*').remove();
				const rulerBounding = this.rulerEl.nativeElement.getBoundingClientRect();
				const yRange = this.originPosition === 'leftTop' ? [0, rulerBounding.height] : [rulerBounding.height, 0];

				let xTicks = this.getTicks(axes[0]);
				let yTicks = this.getTicks(axes[1]);

				xTicks = xTicks > this.MAX_AXES_LINES ? this.MAX_AXES_LINES : xTicks;
				yTicks = yTicks > this.MAX_AXES_LINES ? this.MAX_AXES_LINES : yTicks;

				const x = d3.scaleLinear().domain(domain[0]).range([0, rulerBounding.width]);
				const y = d3.scaleLinear().domain(domain[1]).range(yRange);

				svg.append('g')
				.attr('class', this.originPosition)
				.call(d3.axisTop(x).ticks(xTicks).tickFormat(v => ''))
				.selectAll('g')
				.append('svg:foreignObject')
				.append('xhtml:div')
				.attr('class', 'axis-label x-axis-label')
				.html((v: number) => `${v.toFixed(1)} <span>${this.isCoverageFeet ? 'F' : 'M'}</span>`);

				svg.append('g')
				.call(d3.axisLeft(y).ticks(yTicks).tickFormat(v => ''))
				.selectAll('g')
				.append('svg:foreignObject')
				.append('xhtml:div')
				.attr('class', 'axis-label y-axis-label')
				.html((v: number) => `${v.toFixed(1)} <span>${this.isCoverageFeet ? 'F' : 'M'}</span>`);
			}
		});
	}

	toggleAxes() {
		this.displayAxes = (this.displayAxes + 1) % 3;
		if ([axesSystem2D.xz, axesSystem2D.yz].includes(this.displayAxes)) {
			this.originPosition = this.originalOriginPosition === 'leftTop' ? 'leftBottom' : 'leftTop';
		} else {
			this.originPosition = this.originalOriginPosition === 'leftTop' ? 'leftTop' : 'leftBottom';
		}
		this.drawAxes();
	}

	getSensorPosition() {
		let [x, y] = getSensorPosition2D(this.arena, this.getAxes(), this.getAxesAttr(), this.parameters);

		return this.getSvgPosition(x, y, this.getAxes(), [this.SENSOR_W, this.SENSOR_H]);
	}

	getSensorRotation() {
		return getSensorRotation2D(this.parameters, this.SENSOR_W / 2, this.SENSOR_H / 2, this.getAxesAttr());
	}

	private getTicks(axis) {
		return (Math.abs(this.isCoverageFeet ? meterToFeet(this.arena[axis * 2]) : this.arena[axis * 2]) +
			Math.abs(this.isCoverageFeet ? meterToFeet(this.arena[axis * 2 + 1]) :
				this.arena[axis * 2 + 1])) / (this.isCoverageFeet ? meterToFeet(0.5) : 0.5);
	}
}
