<app-sidebar [sensorSocket]="arenaComponent?.connection"></app-sidebar>

<main class="main">
	<div class="main-container">
		<div class="toolbar">
			<app-dynamic-toolbar></app-dynamic-toolbar>
		</div>
		<div class="content">
			<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
		</div>
	</div>
</main>

<app-loading *ngIf="isLoading" type="circle" data-selector="global-loading-popup"></app-loading>
