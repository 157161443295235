import {PointCloudStageBase} from './PointCloudStageBase';
import {Object3D} from 'three';
import {environment} from '../../environments/environment';
import hubCircleSVG from '!raw-loader!../../assets/images/svg/3d-scene/hub-circle.svg';
import {getColor} from '../utils/utils';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

/**
 * Renders scene with targets 3D models.
 */
export class Tracker3DStageBase extends PointCloudStageBase {

	data: {
		locationData?: Array<Array<number | 'NaN'>>;
		targetsIdData?: Array<number>;
		postureData?: Array<string>;
	};

	private targetsObjects: {
		[key: string]: Object3D
	} = {};

	private scaleForStanding = 0.12 * this.SCALING_FACTOR;

	// Dynamic, don't remove
	private hudCircle;
	private StandingHuman3DModel;
	private SittingHuman3DModel;
	private LyingHuman3DModel;
	private WalkingHuman3DModel;

	private hubCircleSVG = hubCircleSVG;
	private baseSVGColorToChange = '#4CDA64';
	private hudCircleCache = {};

	private spotLightFront;
	private spotLightBack;
	private spotLightLeft;
	private spotLightRight;
	private pointLightFront;
	private pointLightBack;
	private pointLightLeft;
	private pointLightRight;
	private pointLightRightWall;
	private pointLightLeftWall;
	private pointLightFrontWall;
	private pointLightBackWall;

	isDirectionEnabled = false;

	reflect = false;

	constructor(div, arena, status, parameters, shouldUpdateArenaPlanesAccordingToPOV = true, cameraType = 'Perspective') {
		super(div, arena, status, parameters, shouldUpdateArenaPlanesAccordingToPOV, cameraType);
	}

	init(arena, status) {
		super.init(arena, status);
		this.createLigth();
	}

	animation() {
		const now = Date.now();
		const elapsed = now - this.then;

		if (elapsed > this.fpsInterval) {
			switch (this.status) {
				case 'CONFIGURING':
					if (JSON.stringify(this.arena) !== JSON.stringify(this.oldArena)) {
						this.updateScene(this.arena, this.parameters);
						if (this.updateSceneOnConfiguring) {
							this.updateSceneOnConfiguring();
						}
					}
					break;
				case 'IMAGING':

					break;
				default:
					break;
			}
			if (this.scene && this.renderer) {
				this.renderer.render(this.scene, this.camera);
			}
			this.then = now - (elapsed % this.fpsInterval);
		}
		this.animFrameReqId = requestAnimationFrame(this.animation.bind(this));
	}

	updateOnInit(data, arena, isDisplaySensor, reflect, posture) {
		let mainArena = arena,
			isMultipleSensors = arena[0] instanceof Array;

		if (isMultipleSensors) {
			mainArena = arena[0];
		}
		this.status = 'IMAGING';
		this.arena = mainArena;
		this.updateData(data, reflect, posture);

		if (isDisplaySensor) {
			if (!this.mainGroup.children.includes(this.sensor)) {
				this.mainGroup.add(this.sensor);
			}
		} else {
			this.mainGroup.remove(this.sensor);
		}
	}

	update(data, arena, status, isDisplaySensor, reflect, posture) {
		let mainArena = arena,
			isMultipleSensors = arena[0] instanceof Array;

		if (isMultipleSensors) {
			mainArena = arena[0];
		}
		this.oldStatus = this.status;
		this.status = status;
		this.oldArena = this.arena;
		this.arena = mainArena;
		if (status === 'IMAGING' && data) {
			this.updateData(data, reflect, posture);
		}
		// Update on changing status - For instance from "CONFIGURING" to "IMAGING"
		if (this.oldStatus !== this.status) {
			this.updateStatus(this.status);
		}
		if (isDisplaySensor) {
			if (!this.mainGroup.children.includes(this.sensor)) {
				this.mainGroup.add(this.sensor);
			}
		} else {
			this.mainGroup.remove(this.sensor);
		}
	}

	updateData(data, reflect, posture) {
		this.data = data;
		if (this.data) {
			Object.keys(this.targetsObjects).forEach(targetDataId => {
				if (!this.data.targetsIdData!.includes(+targetDataId)) {
					this.mainGroup.remove(this.targetsObjects[targetDataId]);
					delete this.targetsObjects[targetDataId];
				}
			});
			if (this.data.locationData) {
				this.data.locationData.forEach((locationData, i) => {
					if (locationData.some(l => l === 'NaN')) {
						return;
					}
					let targetId = this.data.targetsIdData![i] + '';

					if (this.targetsObjects[targetId]) {
						let target = this.targetsObjects[targetId];
						if (locationData.length) {
							let PostureVector = 'Default';
							if(this.data.postureData) {
								PostureVector = this.data.postureData![i];
							}
							if (PostureVector === 'Default' || !posture) {
								PostureVector = 'Standing';
							}
							if (target.userData.posture === PostureVector) {
								let position = this.getPointPosition(locationData);
								let yArenaGap = this.arena[3] / 2;

								let v = new THREE.Vector3(position.x,
									-yArenaGap + 1,
									position.z);

								if (this.isDirectionEnabled) {
									let isTargetChangeDirection = Math.abs(target.userData.previousLookAtLocation[0] - (locationData[0] as number)) > environment.targetDirectionCalculationThreshold ||
										Math.abs(target.userData.previousLookAtLocation[1] - (locationData[1] as number)) > environment.targetDirectionCalculationThreshold;
									if (isTargetChangeDirection) {
										target.lookAt(v);
										target.userData.previousLookAtLocation = locationData;
									}
								} else {
									target.rotation.y = reflect ? Math.PI : 0;
								}

								target.position.set(v.x, v.y, v.z);
							} else {
								this.mainGroup.remove(target);
								delete this.targetsObjects[targetId];
								this.addNewHumanModel(i, reflect, posture);
							}
						} else {
							this.mainGroup.remove(target);
							delete this.targetsObjects[targetId];
						}
					} else {
						this.addNewHumanModel(i, reflect, posture);
					}
				});
			}
		}
	}

	cleanupHelper() {
		Object.keys(this.targetsObjects).forEach(targetDataId => {
			this.mainGroup.remove(this.targetsObjects[targetDataId]);
			delete this.targetsObjects[targetDataId];
		});
		this.mainGroup.remove(this.spotLightFront);
		this.mainGroup.remove(this.spotLightBack);
		this.mainGroup.remove(this.spotLightLeft);
		this.mainGroup.remove(this.spotLightRight);
		this.mainGroup.remove(this.pointLightFront);
		this.mainGroup.remove(this.pointLightBack);
		this.mainGroup.remove(this.pointLightLeft);
		this.mainGroup.remove(this.pointLightRight);
		this.mainGroup.remove(this.pointLightRightWall);
		this.mainGroup.remove(this.pointLightLeftWall);
		this.mainGroup.remove(this.pointLightFrontWall);
		this.mainGroup.remove(this.pointLightBackWall);
		this.StandingHuman3DModel = undefined;
		this.SittingHuman3DModel = undefined;
		this.LyingHuman3DModel = undefined;
		this.WalkingHuman3DModel = undefined;
		this.hudCircle = undefined;
		this.spotLightFront = undefined;
		this.spotLightBack = undefined;
		this.spotLightLeft = undefined;
		this.spotLightRight = undefined;
		this.pointLightFront = undefined;
		this.pointLightBack = undefined;
		this.pointLightLeft = undefined;
		this.pointLightRight = undefined;
		this.pointLightRightWall = undefined;
		this.pointLightLeftWall = undefined;
		this.pointLightFrontWall = undefined;
		this.pointLightBackWall = undefined;
		super.cleanupHelper();
	}

	updateStatus(status) {
		let color;
		if (status === 'CONFIGURING') {
			color = this.sensorColorOnConfig;
		} else if (status === 'IMAGING') {
			if (this.oldStatus === 'CALIBRATING') {
				this.data = {};
			}
			color = this.sensorColorOnImaging;
		}
		this.updateSensorColor(color);
	}

	onViewSelectionChange() {
		Object.keys(this.targetsObjects).forEach(targetDataId => {
			this.mainGroup.remove(this.targetsObjects[targetDataId]);
			delete this.targetsObjects[targetDataId];
		});
	}

	saveHumanModel3D(name, humanModel3D) {
		humanModel3D.scale.set(this.scaleForStanding, this.scaleForStanding, this.scaleForStanding);
		humanModel3D.geometry.center();
		this[name] = humanModel3D;
	}

	private addNewHumanModel(index, reflect, isPostureEnabled) {
		let PostureVector = 'Default';
		if(this.data.postureData) {
			PostureVector = this.data.postureData![index];
			if (PostureVector === 'Default' || !isPostureEnabled) {
				PostureVector = 'Standing';
			}
		}
		if (this[`${PostureVector}Human3DModel`]) {
			let posture = PostureVector,
				source = this[`${posture}Human3DModel`],
				person = source.clone(),
				targetId = this.data.targetsIdData![index] + '';

			person.material = source.material.clone();
			person.scale.set(this.scaleForStanding, this.scaleForStanding, this.scaleForStanding);
			let circle = this.getHudCircle(getColor(targetId));
			let group = new THREE.Group();

			group.add(person, circle);

			switch (posture) {
				case 'Standing':
					person.position.set(0, 7.4 * this.scaleForStanding, 0);
					break;
				case 'Sitting':
					person.position.set(0, 6 * this.scaleForStanding, 0);
					break;
				case 'Lying':
					person.position.set(0, 0.97 * this.scaleForStanding, 0);
					break;
				case 'Walking':
					person.position.set(0, 7.4 * this.scaleForStanding, 0);
					break;
			}

			circle.position.set(-0.4 * this.SCALING_FACTOR, 0, 0.4 * this.SCALING_FACTOR);

			let position = this.getPointPosition(this.data.locationData![index]);
			let yArenaGap = this.arena[3] / 2;
			let v = new THREE.Vector3(position.x,
				-yArenaGap + 1,
				position.z);

			if (this.isDirectionEnabled) {
				group.lookAt(v);
			} else {
				group.rotation.y = reflect ? Math.PI : 0;
			}

			group.position.set(v.x, v.y, v.z);
			group.userData = {
				id: targetId,
				posture: posture,
				previousLookAtLocation: this.data.locationData![index]
			};
			this.targetsObjects[targetId] = group;
			this.mainGroup.add(group);
		}
	}

	private getHudCircle(color) {

		if (this.hudCircleCache[color]) {
			return this.hudCircleCache[color];
		} else {

			var paths = (new SVGLoader()).parse(this.hubCircleSVG.replace(new RegExp(this.baseSVGColorToChange, 'gmi'), color)).paths,
				group = new THREE.Group(),
				scale = 0.002 * this.SCALING_FACTOR;

			for (let i = 0; i < paths.length; i++) {
				let path: any = paths[i];
				let material5 = new THREE.MeshBasicMaterial({
					color: path.color,
					side: THREE.DoubleSide,
					depthWrite: false,
					transparent: true,
					opacity: typeof path.opacity === 'undefined' ? 1 : path.opacity
				});
				let shapes = path.toShapes(true);
				for (let j = 0; j < shapes.length; j++) {
					let shape = shapes[j];
					let geometry = new THREE.ShapeBufferGeometry(shape);
					let mesh2 = new THREE.Mesh(geometry, material5);
					group.add(mesh2);
				}
			}
			group.scale.set(scale, scale, scale);
			group.rotation.x = Math.PI / -2;
			this.hudCircleCache[color] = group;

			return group;
		}
	}

	// create and add sources of light (4 spot light, 8 point light)
	protected createLigth() {
		let intencivitySpotLigth = 1.0,
			color = new THREE.Color(0.72, 0.74, 0.73), // bbbcb7
			intencivityPointLigth = 0.7,
			sphereSize = 0.5,
			distance = 0,
			decay = 0;

		let minDistanceX = this.arena[0] * this.SCALING_FACTOR,
			maxDistanceX = this.arena[1] * this.SCALING_FACTOR,
			minDistanceY = this.arena[2] * this.SCALING_FACTOR,
			maxDistanceY = this.arena[3] * this.SCALING_FACTOR,
			minDistanceZ = this.arena[4] * this.SCALING_FACTOR,
			maxDistanceZ = this.arena[5] * this.SCALING_FACTOR,
			x = maxDistanceX - minDistanceX,
			z = maxDistanceZ - minDistanceZ,
			y = maxDistanceY - minDistanceY,
			offsetObjectsX = (maxDistanceX - minDistanceX) / 2 + minDistanceX,
			offsetObjectsZ = (maxDistanceZ - minDistanceZ) / 2 + minDistanceZ,
			offsetObjectsY = y / 2;

		// top front
		this.spotLightFront = new THREE.SpotLight(color, intencivitySpotLigth);
		this.spotLightFront.position.set(offsetObjectsX, offsetObjectsY + y, offsetObjectsZ + z * 3);
		this.mainGroup.add(this.spotLightFront);

		let spotLightHelperFront = new THREE.SpotLightHelper(this.spotLightFront);
		// this.scene.add( spotLightHelperFront );

		// top back
		this.spotLightBack = new THREE.SpotLight(color, intencivitySpotLigth);
		this.spotLightBack.position.set(offsetObjectsX, offsetObjectsY + y, offsetObjectsZ - z * 3);
		this.mainGroup.add(this.spotLightBack);

		let spotLightHelperBack = new THREE.SpotLightHelper(this.spotLightBack);
		// this.scene.add( spotLightHelperBack );

		// left
		this.spotLightLeft = new THREE.SpotLight(color, intencivitySpotLigth);
		this.spotLightLeft.position.set(offsetObjectsX - x * 3, offsetObjectsY + y, offsetObjectsZ);
		this.mainGroup.add(this.spotLightLeft);

		let spotLightHelperLeft = new THREE.SpotLightHelper(this.spotLightLeft);
		// this.scene.add( spotLightHelperLeft );

		// right
		this.spotLightRight = new THREE.SpotLight(color, intencivitySpotLigth);
		this.spotLightRight.position.set(offsetObjectsX + x * 3, offsetObjectsY + y, offsetObjectsZ);
		this.mainGroup.add(this.spotLightRight);

		let spotLightHelperRight = new THREE.SpotLightHelper(this.spotLightRight);
		// this.scene.add( spotLightHelperRight );

		// front right
		this.pointLightFront = new THREE.PointLight(color, intencivityPointLigth, distance, decay);
		this.pointLightFront.position.set(offsetObjectsX + x, offsetObjectsY, offsetObjectsZ + z);
		this.mainGroup.add(this.pointLightFront);

		// var pointLightHelper = new THREE.PointLightHelper( pointLightFront, sphereSize );
		// this.scene.add( pointLightHelper );

		// back left
		this.pointLightBack = new THREE.PointLight(color, intencivityPointLigth, distance, decay);
		this.pointLightBack.position.set(offsetObjectsX - x, offsetObjectsY, offsetObjectsZ - z);
		this.mainGroup.add(this.pointLightBack);

		// var pointLightHelper2 = new THREE.PointLightHelper( pointLightBack, sphereSize );
		// this.scene.add( pointLightHelper2 );

		// front left
		this.pointLightLeft = new THREE.PointLight(color, intencivityPointLigth, distance, decay);
		this.pointLightLeft.position.set(offsetObjectsX - x, offsetObjectsY, offsetObjectsZ + z);
		this.mainGroup.add(this.pointLightLeft);

		// var pointLightHelper3 = new THREE.PointLightHelper( pointLightLeft, sphereSize );
		// this.scene.add( pointLightHelper3 );

		// back rigth
		this.pointLightRight = new THREE.PointLight(color, intencivityPointLigth, distance, decay);
		this.pointLightRight.position.set(offsetObjectsX + x, offsetObjectsY, offsetObjectsZ - z);
		this.mainGroup.add(this.pointLightRight);

		// var pointLightHelper4 = new THREE.PointLightHelper( pointLightRight, sphereSize );
		// this.scene.add( pointLightHelper4 );
		//
		// rigth wall
		this.pointLightRightWall = new THREE.PointLight(color, intencivityPointLigth, distance, decay);
		this.pointLightRightWall.position.set(offsetObjectsX + x, offsetObjectsY, offsetObjectsZ);
		this.mainGroup.add(this.pointLightRightWall);

		// var pointLightHelper5 = new THREE.PointLightHelper( pointLightRightWall, sphereSize );
		// this.scene.add( pointLightHelper5 );

		// left wall
		this.pointLightLeftWall = new THREE.PointLight(color, intencivityPointLigth, distance, decay);
		this.pointLightLeftWall.position.set(offsetObjectsX - x, offsetObjectsY, offsetObjectsZ);
		this.mainGroup.add(this.pointLightLeftWall);

		// var pointLightHelper6 = new THREE.PointLightHelper( pointLightLeftWall, sphereSize );
		// this.scene.add( pointLightHelper6 );

		// front wall
		this.pointLightFrontWall = new THREE.PointLight(color, intencivityPointLigth, distance, decay);
		this.pointLightFrontWall.position.set(offsetObjectsX, offsetObjectsY, offsetObjectsZ + z);
		this.mainGroup.add(this.pointLightFrontWall);

		// var pointLightHelper7 = new THREE.PointLightHelper( pointLightFrontWall, sphereSize );
		// this.scene.add( pointLightHelper7 );

		// back wall
		this.pointLightBackWall = new THREE.PointLight(color, intencivityPointLigth, distance, decay);
		this.pointLightBackWall.position.set(offsetObjectsX, offsetObjectsY, offsetObjectsZ - z);
		this.mainGroup.add(this.pointLightBackWall);

		// var pointLightHelper8 = new THREE.PointLightHelper( pointLightBackWall, sphereSize );
		// this.scene.add( pointLightHelper8 );
	}
}
