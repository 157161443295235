import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {StorageService} from '../../services/storage.service';
import {FirebaseService} from '../../services/firebase.service';
import {AuthGuard} from '../../guards/auth.guard';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {ModalService} from '../../services/modal.service';

/**
 * Container for showing login component.
 * Used in /login route.
 */
@Component({ template: `` })
export class LoginComponent implements OnInit {

	constructor(private modalService: ModalService) {}

	ngOnInit() {
		setTimeout(() => {
			this.modalService.showLogin();
		});
	}
}

/**
 * Component represents login window.
 * It allows to register and to authorize user.
 * It contains one field - user name.
 * It was created for CES 2020.
 */
@Component({
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginModalComponent implements OnInit, AfterViewInit {

	@ViewChild('userName', { static: true }) userName: ElementRef;

	name = '';
	errorMessage: string | null = '';
	successMessage: string | null = '';
	signUpDisabled = false;
	loading = false;

	private loadingTimeout;

	constructor(private dialogRef: MatDialogRef<LoginModalComponent>,
				private storageService: StorageService,
				private firebaseService: FirebaseService,
				private authGuard: AuthGuard,
				private router: Router) {
	}

	ngOnInit() {
		if (environment.offline) {
			this.dialogRef.close();
			this.router.navigate(['/connections']);
		} else {
			this.storageService.getItem('lastLoggedInUser').then(lastLoggedInUser => {
				if (lastLoggedInUser) {
					this.name = lastLoggedInUser;
					this.signIn();
				}
			});
		}
	}

	ngAfterViewInit() {
		if (this.userName.nativeElement) {
			this.userName.nativeElement.focus();
		}
	}

	signUp() {
		if (this.name.length) {
			this.setLoadingState(true);
			this.firebaseService.signUp(this.name).then(success => {
				if (success) {
					this.errorMessage = null;
					this.successMessage = 'USER_SIGN_UP_SUCCESS';
					this.signUpDisabled = true;
				} else {
					this.errorMessage = 'USER_ALREADY_EXIST';
				}
				this.setLoadingState(false);
			}).catch(() => {
				this.setLoadingState(false);
			});
		} else {
			this.errorMessage = 'EMPTY_USER_NAME';
		}
	}

	signIn() {
		if (this.name.length) {
			this.setLoadingState(true);
			this.firebaseService.signIn(this.name).then(success => {
				if (success) {
					this.dialogRef.close(this.name);
					this.storageService.setItem('lastLoggedInUser', this.name);
					this.router.navigate([this.authGuard.redirectURL ? this.authGuard.redirectURL : '/']);
				} else {
					this.errorMessage = 'USER_NOT_FOUND';
				}
				this.setLoadingState(false);
			}).catch(() => {
				this.setLoadingState(false);
			});
		} else {
			this.errorMessage = 'EMPTY_USER_NAME';
		}
	}

	nameChanged() {
		this.errorMessage = null;
		this.successMessage = null;
		this.signUpDisabled = false;
	}

	private setLoadingState(state) {
		clearTimeout(this.loadingTimeout);
		if (state) {
			this.loadingTimeout = setTimeout(() => {
				this.loading = true;
			}, 500);
		} else {
			this.loading = false;
		}
	}
}
