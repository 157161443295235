import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'materialIcon'
})
export class MaterialIconPipe implements PipeTransform {

	transform(value: string | Array<string>): string {
		if (value instanceof Array) {
			return value.map(v => v.replace('material_', '')).join('&nbsp;');
		} else {
			return value.replace('material_', '');
		}
	}

}
