import {
	AfterViewInit,
	Component,
	ComponentFactoryResolver,
	ElementRef,
	Injector,
	OnDestroy,
	QueryList,
	ViewChild,
	ViewChildren,
	ViewContainerRef
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SensorsService} from '../../services/system/sensors.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {StorageService} from '../../services/storage.service';
import {SettingsService} from '../../services/settings.service';
import {ModalService} from '../../services/modal.service';
import {BusEventService} from '../../services/bus-event.service';
import {isContainedIn} from '../../utils/utils';
import {DataService} from '../../services/system/data.service';
import {LayersContainerComponentBase} from '../layers/layers-container-component-base.component';
import {RestService} from '../../services/system/rest.service';
import {Components} from './base-view.layers';
import {ToolbarService} from '../../services/toolbar.service';
import {Connection} from '../../services/system/connection';
import {MODULE_PATH} from 'src/app/app.module';
import {ConfigurationService, Layer} from '../../services/configuration.service';
import {RetailGeoFence} from '../../models/models';

/**
 * Component contains toolbar, sensor connection panel, scene for rendering (look at /layers folder).
 * It is used (as a base component) in different pages with the following flags:
 * * Floor plan 			-										(Smart Buildings app)
 * * Smart Cooler 			-										(Smart Cooler app)
 * * Smart Tailor 			-										(Smart Tailor app)
 * * Smart Home 			- 										(Smart Home app)
 * * Sensor, Multi Sensors	isMultipleSensors						(InCar app)
 * * Covid					-										(Covid app)
 * * Sensor, Multi Sensors,	isMultipleSensors						(Tracker app)
 * 	 Room occupancy
 */
@Component({
	selector: 'app-base-view',
	templateUrl: './base-view.component.html',
	styleUrls: ['./base-view.component.scss']
})
export class BaseViewComponent implements OnDestroy, AfterViewInit {
	@ViewChild('fileuploadSettings') fileuploadSettingsInput: ElementRef;
	@ViewChildren(LayersContainerComponentBase) trackers: QueryList<LayersContainerComponentBase>;
	@ViewChild('subToolbarContainer', {read: ViewContainerRef}) subToolbarContainer: ViewContainerRef;
	@ViewChild('subToolbar') subToolbar: ElementRef;

	environment = environment;

	isMultipleSensors = false;

	isDuplicateView = false;
	fallDetection = false;

	sensitivityValue;
	data: any = {};
	combinedData: any = {};
	connection: Connection;

	connectionSettings;
	connectedSensorsSettings;
	selectedModelIds: Array<string> = [];

	// Dynamic, don't remove
	Standing3DModel;
	Sitting3DModel;
	Lying3DModel;
	Walking3DModel;
	Bottle3DModel;
	Fridge3DModel;

	showLayerControlsMenu = true;
	viewContainerRequiredConnection = true;
	showLoadingWhileConnecting = false;

	showNoLayersContainerLoading = false;
	noLayersContainerText = '';
	showSubToolbar = false;
	showSubToolbarContent = false;
	useSubToolbar = false;

	layers: Array<Array<Layer>> = [[], []];

	geoFences: Array<RetailGeoFence> = [];

	busEventService: BusEventService;
	toolbarService: ToolbarService;

	subToolbarIsAnimated = false;

	get isLayersContainerVisible() {
		return (this.connection?.isConnected() && this.connectionSettings?.sensorParameters) || !this.viewContainerRequiredConnection;
	}

	protected selectedRotates = {};
	protected selectedFlips = {};
	protected selectedPostures = {};
	protected selectedRulers = {};
	protected selectedSensors = {};
	protected selectedCameras = {};

	protected statusSub: Subscription;
	protected closeSub: Subscription;
	protected parametersChangeSub: Subscription;
	protected dataRetrieveSub: Subscription;
	protected shouldSaveUIParametersToCache = true;
	protected isDestroyed = false;
	protected subscriptions: Array<Subscription> = [];

	protected rest: RestService;
	protected dataService: DataService;
	protected route: ActivatedRoute;
	protected sensorsService: SensorsService;
	protected storageService: StorageService;
	protected settingsService: SettingsService;
	protected modalService: ModalService;
	protected configurationService: ConfigurationService;
	protected MODULE_PATH;
	protected subToolbarComponent;
	protected resolver: ComponentFactoryResolver;

	constructor(protected injector: Injector) {
		this.toolbarService = injector.get(ToolbarService);
		this.rest = injector.get(RestService);
		this.dataService = injector.get(DataService);
		this.route = injector.get(ActivatedRoute);
		this.sensorsService = injector.get(SensorsService);
		this.storageService = injector.get(StorageService);
		this.settingsService = injector.get(SettingsService);
		this.modalService = injector.get(ModalService);
		this.busEventService = injector.get(BusEventService);
		this.configurationService = injector.get(ConfigurationService);
		this.resolver = injector.get(ComponentFactoryResolver);
		this.MODULE_PATH = injector.get(MODULE_PATH);
	}


	/**
	 * @abstract
	 */
	onPosture(viewIndex) {
	}

	/**
	 * @abstract
	 */
	onSensitivityValueChange(sensitivityValue) {
	}


	/**
	 * @abstract
	 */
	onFileSelect(event: Event) {
	}

	/**
	 * @abstract
	 */
	protected preloadModelsResources() {
	}

	/**
	 * @abstract
	 */
	protected sendOutputsToSocket(outputs) {
	}

	/**
	 * @abstract
	 */
	protected updateSensorSettings(s, notify) {
	}

	/**
	 * @abstract
	 */
	protected registerSocketEvents() {
	}

	async ngAfterViewInit() {
		if (this.useSubToolbar && this.subToolbarComponent) {
			await Promise.resolve();
			let factory = this.resolver.resolveComponentFactory(this.subToolbarComponent),
				componentRef = this.subToolbarContainer.createComponent(factory);
		}
		this.subscriptions.push(this.trackers.changes.subscribe(() => {
			Promise.all(this.trackers.map(tracker => tracker.menuOpened)).then(() => {
				/**
				 * Wait for menu is opened
				 */
				setTimeout(() => {
					this.busEventService.menuOpenedResolver();
				});
			});
		}));
		this.subscriptions.push(this.busEventService.showSubToolbar.subscribe(state => {
			if (state.open) {
				this.showSubToolbarContent = true;
			} else {
				setTimeout(() => {
					this.showSubToolbarContent = false;
				}, 500);
			}
			this.showSubToolbar = state.open;
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 500);
		}));
	}


	ngOnDestroy() {
		this.isDestroyed = true;
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
		if (this.statusSub) {
			this.statusSub.unsubscribe();
		}
		if (this.closeSub) {
			this.closeSub.unsubscribe();
		}
		if (this.parametersChangeSub) {
			this.parametersChangeSub.unsubscribe();
		}
		if (this.dataRetrieveSub) {
			this.dataRetrieveSub.unsubscribe();
		}
		this.busEventService.updateMenuOpenedPromise();
	}


	/**
	 * Duplicate button is pressed in toolbar
	 */
	onDuplicateViewToggle() {
		this.isDuplicateView = !this.isDuplicateView;
		this.toolbarService.setIsDuplicateViewFlag(this.isDuplicateView);
		if (!this.isDuplicateView) {
			delete this.selectedRotates[1];
			delete this.selectedFlips[1];
			delete this.selectedPostures[1];
			delete this.selectedRulers[1];
			delete this.selectedSensors[1];
		}
		if (this.shouldSaveUIParametersToCache) {
			this.settingsService.saveUIParameters(this.connection.url, {
				isDuplicateView: this.isDuplicateView
			});
		}
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
			// TODO check this
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			});
		});
	}


	onRotate(viewIndex) {
		var rotate = this.getRotateForSensorTrackersView(viewIndex);

		this.selectedRotates[viewIndex] = ((rotate - 1) % 4 + 4) % 4;
	}


	onFlip(viewIndex) {
		var flip = this.getFlipForSensorTrackersView(viewIndex);

		this.selectedFlips[viewIndex] = !flip;
	}


	onRuler(viewIndex) {
		var ruler = this.getRulerForSensorTrackersView(viewIndex);

		this.selectedRulers[viewIndex] = !ruler;
	}


	onViewSensorToggle(viewIndex) {
		var sensor = this.getSensorForSensorTrackersView(viewIndex);

		this.selectedSensors[viewIndex] = !sensor;
	}


	// Get rotate (or init) for tracker view (scene)
	getRotateForSensorTrackersView(i) {
		if (!(i in this.selectedRotates)) {
			this.selectedRotates[i] = 0;
		}

		return this.selectedRotates[i];
	}

	getCameraForSensorTrackersView(i) {
		return this.selectedCameras[i];
	}


	getFlipForSensorTrackersView(i) {
		if (!(i in this.selectedFlips)) {
			this.selectedFlips[i] = false;
		}

		return this.selectedFlips[i];
	}


	getPostureForSensorTrackersView(i) {
		if (!(i in this.selectedPostures)) {
			this.selectedPostures[i] = true;
		}

		return this.selectedPostures[i];
	}


	getRulerForSensorTrackersView(i) {
		if (!(i in this.selectedRulers)) {
			this.selectedRulers[i] = true;
		}

		return this.selectedRulers[i];
	}


	getSensorForSensorTrackersView(i) {
		if (!(i in this.selectedSensors)) {
			this.selectedSensors[i] = true;
		}

		return this.selectedSensors[i];
	}


	closeFallDetection() {
		this.fallDetection = false;
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		});
	}


	onLayerToggle(viewIndex: number, layer: Layer) {
		if (layer.selected) {
			this.layers[viewIndex].filter(l => l !== layer).forEach(l => {
				let hasDifferentComponent = l.componentName !== layer.componentName,
					hasTheSameName = l.name === layer.name,
					itIsAlternativeLayer = (layer.alternative && layer.alternative.includes(l.name));

				if (hasDifferentComponent || hasTheSameName || itIsAlternativeLayer) {
					l.selected = false;
				}
			});
		}
		this.layers[0] = ([] as Array<Layer>).concat(this.layers[0]);
		this.layers[1] = ([] as Array<Layer>).concat(this.layers[1]);
		if (this.shouldSaveUIParametersToCache) {
			this.settingsService.saveUIParameters(this.connection.url, {
				'selectedLayers': this.layers
			});
		}
		delete this.selectedPostures[viewIndex];
	}

	onCameraToggle(viewIndex: number, deviceId: string) {
		this.selectedCameras[viewIndex] = deviceId;
		if (this.shouldSaveUIParametersToCache) {
			this.settingsService.saveUIParameters(this.connection.url, {
				'selectedCameras': this.selectedCameras
			});
		}
	}

	closeSubToolbar() {
		this.busEventService.showSubToolbar.next({open: false});
	}

	subToolbarTransitionStart(e: TransitionEvent) {
		if (e.target === this.subToolbar.nativeElement) {
			this.subToolbarIsAnimated = true;
		}
	}

	subToolbarTransitionEnd(e: TransitionEvent) {
		if (e.target === this.subToolbar.nativeElement) {
			this.subToolbarIsAnimated = false;
		}
	}

	protected generateLayers(parameters) {
		let layers: Array<Layer> = [],
			selectedLayers = parameters.guiParameters['selectedLayers'] || [[], []],
			componentsNames = Components.map(c => c.layerName);

		// For new pages, that working with CoCo
		if (this.configurationService.pageMap[this.toolbarService.getRoute().data.pageName]) {
			layers = (this.configurationService.pageMap[this.toolbarService.getRoute().data.pageName].layers as Array<Layer>) || [];
		} else {
			layers = parameters.sensorParameters['ProcessorCfg.ExternalGUI.Layers'];
		}

		let newLayers: Array<Array<Layer>> = [[], []];
		layers.forEach(layer => {
			// Twice for each view
			[0, 1].forEach(i => {
				// Check we know how to display this layer
				if (componentsNames.includes(layer['componentName'])) {
					let isDataDictStrictCheck = this.showLayerControlsMenu;
					let savedLayer = selectedLayers[i].find(l => {
						return l.name === layer['componentName'] &&
							(
								(
									isDataDictStrictCheck &&
									Object.keys(l.dataDict || {}).length === Object.keys(layer.dataDict || {}).length &&
									Object.keys(l.dataDict || {}).every(k => Object.keys(layer.dataDict || {}).includes(k)) &&
									Object.values(l.dataDict || {}).every((v: string) => Object.values(layer.dataDict || {}).includes(v))
								) || !isDataDictStrictCheck
							) &&
							Object.keys(l.staticDataDict || {}).length === Object.keys(layer.staticDataDict || {}).length &&
							Object.keys(l.cfgDict || {}).length === Object.keys(layer.cfgDict || {}).length &&
							Object.keys(l.metadata || {}).length === Object.keys(layer.metadata || {}).length;
					});
					let component: any = {};
					Components.forEach(c => {
						let match = c.layerName === layer['componentName'];
						if (match && c.shouldMatch) {
							match = isContainedIn(c.shouldMatch, layer);
						}
						if (match) {
							component = c;
						}
					});
					newLayers[i].push(this.generateLayer(layer, savedLayer, component));
				}
			});
		});
		if (JSON.stringify(this.layers) !== JSON.stringify(newLayers)) {
			this.layers = newLayers;
		}
	}


	private generateLayer(layer, savedLayer, component): Layer {
		let isXAxisInverted = false;
		if (layer.hasOwnProperty('isXAxisInverted')) {
			isXAxisInverted = layer.isXAxisInverted;
		} else if (component.hasOwnProperty('isXAxisInverted')) {
			isXAxisInverted = component.isXAxisInverted;
		}

		return {
			name: layer['componentName'],
			componentName: component.componentName,
			selected: savedLayer ? savedLayer.selected : false,
			displayName: layer['displayName'],
			alternative: component.alternative,
			dataDict: layer['dataDict'],
			staticDataDict: layer['staticDataDict'] || {},
			cfgDict: layer['cfgDict'] || {},
			metadata: layer['metadata'] || {},
			controls: layer['controls'] || [],
			isXAxisInverted,
			axes: layer['axes'],
			axesEnabled: component.axesEnabled,
			sensor: component.sensor,
			flip: component.flip,
			rotate: component.rotate,
			reset3d: component.reset3d,
			sensitivity: component.sensitivity,
			ruler: component.ruler,
			isRaw: component.isRaw,
			tracker: component.tracker,
			isSmartTailor: component.isSmartTailor,
			camera: component.camera,
		};
	}

	protected prepareURL(url) {
		return `/${this.MODULE_PATH}/${url}`;
	}

	protected cleanOldData() {
		this.data = {};
		this.combinedData = {};
	}
}
