import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SensorsService} from '../../../services/system/sensors.service';
import {environment} from '../../../../environments/environment';

/**
 * Guard checks if environment allows to enter to page.
 */
@Injectable({
	providedIn: 'root'
})
export class FlowProcessorPageGuard implements CanActivate {
	constructor(private sensorsService: SensorsService,
				private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot,
				state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		let ret = true;
		if (ret) {
			return true;
		} else {
			this.router.navigate([ environment.FLOW_PROCESSOR_MODULE_PATH + '/connections']);
			return false;
		}
	}
}
