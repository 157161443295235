<div class="connecting-popup"><!-- div connecting popup - open -->
	<!-- Hidden -->
	<div class="connecting-popup__logo">
		<img src="assets/images/VayyarNewLogo-01.png" alt="logo">
	</div>

	<div class="connecting-popup__content"><!-- div container - open -->
		<div *ngIf="showSocketList">
			<ng-container>
				<div class="connecting-popup__title">Recently Used Hosts</div>

				<div class="sensors">
					<div class="sensors__header clear">
						<span>Edit</span>
						<span>IP/Name</span>
						<span>Action</span>
					</div>

					<ul class="sensors__list" perfectScrollbar>
						<li *ngFor="let ip of ipList">
							<!-- Buttons edit -->
							<div class="sensors__list-buttons">
								<button (click)="removeIp(ip)" data-selector="recently-connection-list_connection-item-ip-delete_button" mat-raised-button><img
									src="assets/images/svg/Sensor-connected/icon-small-close.svg" alt=""></button>
							</div>

							<!-- Input for IP -->
							<div class="sensors__list-ip-input" (click)="goTo(ip)" [class.clickable]="!ip.isQuickEditIp">
								<app-inline-edit-input #computerName ngDefaultControl
													   data-selector="recently-connection-list_connection-item-ip_inline-editor"
													   [ngModel]="ip.visibleField" class="def-input"
													   [ngClass]="{'input-selected': ip.isQuickEditIp}" type="text"
													   [matTooltip]="ip.name"
													   [placeholder]="'Insert Computer name' | translate"
													   [tooltip]="'EDIT_CONNECTION_NAME' | translate"
													   [disabled]="!ip.isQuickEditIp"
													   (valueChange)="saveIp(ip, $event)"
													   (edit)="enterQuickEditIp(ip, computerName)"
														(blur)="exitQuickEditIp(ip, computerName, $event)">
								</app-inline-edit-input>
							</div>

							<!-- Buttons status... For example "Connect" or "Disconnect" -->
							<div class="sensors__list-btn-status">
								<button (click)="onClickActionBtn(ip)" mat-raised-button
										[innerHTML]="getActionText(ip)"
										data-selector="recently-connection-list_connection-item-connect_button"
								></button>
							</div>
						</li>
					</ul>
				</div>
			</ng-container>
		</div>

		<!-- Welcome popup butons -->
		<div class="connecting-popup__footer">
			<div class="enter-ip-container">
				<input type="text" data-selector="recently-connection-list_new-connection-ip_input" placeholder="Enter IP/Computer name" [(ngModel)]="newIp"
					   (keyup)="onNewIpKeyUp($event)">
			</div>
			<div class="connecting-popup__footer-buttons">
				<button mat-raised-button data-selector="recently-connection-list_cancel_button" class="btn-cancel" (click)="onClose()"><span>Cancel</span></button>
				<button mat-raised-button data-selector="recently-connection-list_connect_button" class="btn-connect" (click)="connectToNewIp()" [disabled]="!newIp.length">
					<span>Connect</span></button>
			</div>
		</div>

		<!-- Welcome popup background -->
		<div class="welcome-background">
			<img src="assets/images/svg/Welcome/welcome-background-1.png" class="first-img" alt="">
			<img src="assets/images/svg/Welcome/welcome-background-2.png" class="second-img" alt="">
		</div>

	</div><!-- div container - close -->
</div><!-- div connecting popup - close -->
