import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HtmlNewLinePipe} from 'src/app/pipes/html-new-line/html-new-line.pipe';
import {DurationPipe} from 'src/app/pipes/duration.pipe';
import {InlineEditInputComponent} from '../../components/shared/inline-edit-input/inline-edit-input.component';
import {MaterialModule} from '../material/material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SafePipe} from '../../pipes/safe.pipe';
import {MaterialIconPipe} from '../../pipes/material-icon.pipe';
import {NumberInputConfigPipe} from '../../pipes/number-input-config.pipe';
import {ConnectionListComponent} from '../../components/shared/connection-list/connection-list.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import {SvgCarComponent} from '../../components/shared/svg-car/svg-car.component';
import {NumberInputComponent} from '../../components/shared/number-input/number-input.component';
import {SensorSliderComponent} from '../../applications/flow-processor/components/tabbed-form-control/arena/slider/slider.component';
import {SlideToggleComponent} from '../../components/shared/slide-toggle/slide-toggle.component';
import {LoadingComponent} from '../../components/shared/loading/loading.component';
import {VarDirective} from '../../directives/ng-var.directive';
import {FilterPipe} from '../../pipes/filter.pipe';
import {MatSelectAutoWidthDirective} from '../../directives/mat-select-auto-width.directive';
import {DisableControlDirective} from '../../directives/disable-control.directive';
import {LayerControlsComponent} from '../../components/layer-controls/layer-controls.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {
	ConfigurableControlComponent
} from '../../applications/flow-processor/components/configurable-control/configurable-control.component';
import {
	BackgroundImageControlComponent
} from '../../applications/flow-processor/components/tabbed-form-control/floor-plan/background-image-control.component';
import {ArenaComponent} from '../../applications/flow-processor/components/tabbed-form-control/arena/arena.component';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../../../assets/i18n/${lang}.json`));
	}
}

@NgModule({
	declarations: [
		DurationPipe,
		HtmlNewLinePipe,
		SafePipe,
		MaterialIconPipe,
		NumberInputConfigPipe,
		InlineEditInputComponent,
		ConnectionListComponent,
		SvgCarComponent,
		NumberInputComponent,
		SensorSliderComponent,
		SlideToggleComponent,
		LoadingComponent,
		VarDirective,
		FilterPipe,
		MatSelectAutoWidthDirective,
		DisableControlDirective,
		LayerControlsComponent,
		ConfigurableControlComponent,
		BackgroundImageControlComponent,
		ArenaComponent,
	],
	imports: [
		CommonModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		PerfectScrollbarModule
	],
	exports: [
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		DurationPipe,
		HtmlNewLinePipe,
		SafePipe,
		MaterialIconPipe,
		NumberInputConfigPipe,
		InlineEditInputComponent,
		ConnectionListComponent,
		SvgCarComponent,
		NumberInputComponent,
		SensorSliderComponent,
		SlideToggleComponent,
		LoadingComponent,
		VarDirective,
		FilterPipe,
		MatSelectAutoWidthDirective,
		DisableControlDirective,
		LayerControlsComponent,
		ConfigurableControlComponent,
		BackgroundImageControlComponent,
		ArenaComponent,
	],
	entryComponents: [
		ConnectionListComponent
	]
})
export class SharedModule {
}
