<app-point-cloud-raw2d *ngIf="layer.name === 'HeatmapContour'" [data]="data"
></app-point-cloud-raw2d>
<app-target-tracker
	[layer]="layer"
	[data]="data"
	[ruler]="ruler"
	[rotate]="rotate"
	[reflect]="reflect"
	[parameters]="parameters"
	[originPosition]="originPosition"
	[isFloorPlan]="isFloorPlan"
	[isSensorVisible]="isSensorVisible"
	[postureValue]="postureValue"
	[axesEnabled]="axesEnabled">
	<ng-content></ng-content>
</app-target-tracker>
