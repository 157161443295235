import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {StorageService} from '../../../services/storage.service';
import {SettingsService} from '../../../services/settings.service';
import {environment} from '../../../../environments/environment';
import {DatePipe} from '@angular/common';
import {MatDialogRef} from '@angular/material/dialog';
import {SensorsService} from '../../../services/system/sensors.service';
import {ModalService} from '../../../services/modal.service';
import {Router} from '@angular/router';
import {tableFix} from '../../../utils/utils';
import {BusEventService} from '../../../services/bus-event.service';
import {ConnectionType} from '../../../consts';

/**
 * Component allows to connect to remote sensors in modal window.
 * Can be called in "Connection to local host failed" modal window.
 * It renders add button and table with sensors.
 */
@Component({
	selector: 'app-connect-to-remote-hosts',
	templateUrl: './connect-to-remote-hosts.component.html',
	styleUrls: ['./connect-to-remote-hosts.component.scss']
})
export class ConnectToRemoteHostsComponent implements OnInit, OnDestroy {

	isAllHostsChecked: boolean;
	isCheckedRows = false;

	sensorsList: Array<any> = [];

	@Output() close = new EventEmitter();

	currentSortingColumn: string | null = null;
	currentSortingDirection = 1;

	progress = 0;
	inProgress = false;

	get monitoringItems() {
		if (!this.sensorsList.length) {
			setTimeout(() => {
				this.isAllHostsChecked = false;
			});
		}
		return [].concat(...this.sensorsList).sort(this.sortData);
	}

	private lastConnectedSensorSocket;
	private lastEditNameItem;
	private lastEditNameInput;

	private sortData = (a, b) => {
		if(this.currentSortingColumn) {
			if (a[this.currentSortingColumn] < b[this.currentSortingColumn]) {
				return this.currentSortingDirection * -1;
			}
			if (a[this.currentSortingColumn] > b[this.currentSortingColumn]) {
				return this.currentSortingDirection;
			}
		}
		return 0;
	};

	constructor(private dialogRef: MatDialogRef<ConnectToRemoteHostsComponent>,
				private storageService: StorageService,
				private settingsService: SettingsService,
				private sensorsService: SensorsService,
				private modalService: ModalService,
				private router: Router,
				private busEventService: BusEventService) {
	}

	ngOnInit() {
		this.loadSocketPool();
		window.addEventListener('resize', tableFix);
	}

	ngOnDestroy() {
		window.removeEventListener('resize', tableFix);
	}

	addSensor() {
		this.currentSortingColumn = null;
		this.currentSortingDirection = 1;
		this.sensorsList.unshift({
			visibleField: '',
			ip: '',
			name: '',
			connected: false,
			connecting: false,
			isEdit: true
		});
		setTimeout(() => {
			(document.querySelector('.sensors-table tbody input.name') as any).focus();
		});
	}

	selectAllHosts() {
		if (!this.inProgress && this.monitoringItems.length) {
			this.isAllHostsChecked = !this.isAllHostsChecked;
			this.sensorsList.map(item => item.checked = this.isAllHostsChecked ? true : false);
			this.isCheckedRows = this.isAllHostsChecked;
		}
	}

	onCheckboxChecked(i) {
		if (!this.inProgress) {
			i.checked = !i.checked;
			this.isCheckedRows = this.sensorsList.some(item => item.checked);
			this.isAllHostsChecked = this.sensorsList.every(item => item.checked);
		}
	}

	onSort(column) {
		if (this.currentSortingColumn === column) {
			this.currentSortingDirection = this.currentSortingDirection * -1;
		} else {
			this.currentSortingColumn = column;
			this.currentSortingDirection = 1;
		}
	}

	saveSensor(item) {
		if (item.visibleField && item.visibleField.length) {
			var info = {};

			if (item.name) {
				info['name'] = item.name;
			} else {
				delete info['name'];
			}
			this.settingsService.saveSocketInfo(item.visibleField, info).then(() => {
				item.isEdit = false;
			});
		}
	}

	removeSensor(item, i) {
		var message = 'Are you sure you want to remove this sensor from the list?';

		if (item.connected) {
			message = 'Are you sure you want to disconnect and remove this sensor from the list?';
		}
		this.modalService.confirm(message).afterClosed().toPromise().then(res => {
			if (res) {
				this.storageService.getItem('socketPool').then(socketPool => {
					if (socketPool && ~socketPool.indexOf(item.visibleField)) {
						socketPool.splice(socketPool.indexOf(item.visibleField), 1);
					} else if (socketPool && ~socketPool.indexOf(item.ip)) {
						socketPool.splice(socketPool.indexOf(item.ip), 1);
					} else {
						this.sensorsList.splice(i, 1);
					}
					this.storageService.setItem('socketPool', socketPool).then(() => {
						this.settingsService.clearAllSetting(item.visibleField);
						this.loadSocketPool();
						if (item.connected) {
							this.sensorsService.disconnectSocket(this.sensorsService.getSensorSocket(item.ip));
						}
					});
				});
			}
		});
	}

	onClose() {
		this.dialogRef.close(this.lastConnectedSensorSocket);
	}

	connectToSelectedSensors() {
		this.progress = 0;
		this.inProgress = true;
		var filteredList = this.sensorsList.filter(item => {
				return item.checked && !item.connected && !item.connecting;
			}),
			total = filteredList.length, i = 0;

		filteredList.forEach(item => {
			this.connectToSensor(item).then(() => {
				this.progress = ++i * 100 / total;
				if (i === total) {
					this.inProgress = false;
					this.onClose();
				}
			});
		});
	}

	async connectToSensor(item, disabled = false): Promise<any> {
		if (!item['connected'] && item.visibleField && item.visibleField.length && !disabled) {
			var [ip, port] = item.visibleField.split(':'),
				sensorSocket;
			item.connecting = true;
			if (!port) {
				port = environment.defaultSensorPort;
			}
			sensorSocket = await this.sensorsService.createSensorSocket(ip, port);
			this.lastConnectedSensorSocket = sensorSocket;

			return new Promise((resolve) => {
				this.sensorsService.connectSocket(sensorSocket, res => {
					item['connected'] = res;
					item.connecting = false;
					resolve();
				});
			});
		} else {
			return Promise.resolve();
		}
	}

	disconnectSensorSocket(item) {
		this.sensorsService.disconnectSocket(this.sensorsService.getSensorSocket(item.ip));
		item.connected = false;
	}

	goToSensor(item) {
		this.dialogRef.close();
		this.router.navigate(['/sensor', item.visibleField], { skipLocationChange: true });
		this.busEventService.connect.emit({
			url: item.visibleField,
			type: ConnectionType.WS
		});
	}

	formatLastUsed(timestamp) {
		var today = new Date(),
			date = new Date(timestamp);

		if (date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
			return new DatePipe('en').transform(timestamp, 'HH:mm');
		} else {
			return new DatePipe('en').transform(timestamp, 'dd MMM');
		}
	}

	focus(input) {
		setTimeout(() => {
			input.focus();
		});
	}

	isConnectDisabled() {
		return !this.sensorsList.some(item => item.checked) || this.inProgress;
	}

	editName(item, input) {
		if (!this.inProgress) {
			if (this.lastEditNameItem && this.lastEditNameItem === item) {
				return;
			}
			if (this.lastEditNameItem) {
				this.saveEditName(this.lastEditNameItem, this.lastEditNameInput.value);
			}
			this.lastEditNameItem = item;
			this.lastEditNameInput = input;
			item.isQuickEditName = true;
			setTimeout(() => {
				input.focus();
			});
		}
	}

	exitEditName(item, input, e?) {
		if (e) {
			e.stopPropagation();
		}
		item.isQuickEditName = false;
		input.value = item.name;
		this.lastEditNameItem = null;
		this.lastEditNameInput = null;
	}

	saveEditName(item, name, e?) {
		if (e) {
			e.stopPropagation();
		}
		this.settingsService.getSocketInfo(item.ip).then(info => {
			if (name.length) {
				info['name'] = name;
			} else {
				delete info['name'];
				if (item.sensorSocket) {
					item.sensorSocket.name = '';
				}
			}
			this.settingsService.saveSocketInfo(item.ip, info).then(() => {
				item.isQuickEditName = false;
			});
		});
		this.lastEditNameItem = null;
		this.lastEditNameInput = null;
	}

	blurName(item, value, e) {
		if (e.key === 'Enter') {
			this.saveEditName(item, value, e);
		}
	}

	private loadSocketPool(callback?) {
		this.storageService.getItem('socketPool').then(socketPool => {
			var pool = (socketPool || []).map(url => {
				return this.settingsService.getSocketInfo(url);
			});
			Promise.all(pool).then(socketInfo => {
				this.sensorsList = socketInfo
				.map((info: any, i) => {
					var socket = this.sensorsService.getSensorSocket(socketPool[i]);
					return {
						visibleField: this.prepareUrl(socketPool[i]),
						ip: socketPool[i],
						name: info['name'] || '',
						isQuickEditName: false,
						lastUsed: info['lastUsed'],
						connected: socket && socket.isConnected(),
						connecting: socket && socket.isConnecting(),
						isEdit: false
					};
				})
				.sort((a, b) => {
					if (a.lastUsed < b.lastUsed) {
						return 1;
					}
					if (a.lastUsed > b.lastUsed) {
						return -1;
					}
					return 0;
				});
				if (callback) {
					callback();
				}
				var cb = () => {
					tableFix();
				};
				setTimeout(() => {
					cb();
					setTimeout(cb, 50);
				}, 50);
			});
		});
	}

	private prepareUrl(url = '') {
		return url.replace(`:${environment.defaultSensorPort}`, '');
	}
}
