import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigurableTabControlType, IConfigurableTabControl} from '../../../../services/configuration.service';
import {FormGroup} from '@angular/forms';
import {SensorSocket} from '../../../../services/system/sensor-socket';
import {MultiSensorsSocket} from '../../../../services/system/multi-sensors-socket';
import {SettingsService} from '../../../../services/settings.service';

@Component({
	selector: 'app-configurable-control',
	templateUrl: './configurable-control.component.html',
	styleUrls: ['./configurable-control.component.scss']
})
export class ConfigurableControlComponent implements OnInit {

	@Input() control: IConfigurableTabControl;
	@Input() form: FormGroup;
	@Input() settingForm: FormGroup;
	@Input() sensorSocket: SensorSocket | MultiSensorsSocket;
	@Input() initialSettings;
	@Input() defaultNumberInputStep: number;
	@Input() numberInputLabel;
	@Output() sensorPositionChanged = new EventEmitter;
	@Output() monitoredAreaChanged = new EventEmitter;
	@Output() sensorHeightChanged = new EventEmitter;
	ConfigurableTabControlType = ConfigurableTabControlType;

	constructor(public settingsService: SettingsService) {
	}

	ngOnInit(): void {
	}

	onSensorPositionChanged(e) {
		this.sensorPositionChanged.emit(e);
	}

	onMonitoredAreaChanged(e) {
		this.monitoredAreaChanged.emit(e);
	}

	onSensorHeightChanged(e) {
		this.sensorHeightChanged.emit(e);
	}
}
