<div class="buttons">
	<ng-content></ng-content>
</div>
<app-layer-controls
	*ngIf="!!activeFloorPlan"
	[opened]="true"
	[hideViewButton]="true"
	[hideFlipButton]="true"
	[hideResetViewButton]="true"
	[hideRulerButton]="true"
	[hideSensitivityButton]="true"
	[hideSensorButton]="true"
	[isViewDisabled]="true"
	[isRotateEnable]="true"
	[rotateValue]="activeFloorPlan?.rotationDegree === 90 ? 3 : activeFloorPlan?.rotationDegree === 180 ? 2 : activeFloorPlan?.rotationDegree === 270 ? 1 : 0"
	(rotate)="activeFloorPlan?.rotate(90)"
></app-layer-controls>
<div class="wrap">
	<div class="host__wrap">
		<ng-container>
			<div class="type">
				<div *ngFor="let graph of graphs; trackBy: trackByPage" class="widget" [class.selected]="selectedGraph === graph" [attr.data-selector]="graph.page + '_widget'"
					 [class.loading]="busEventService.restIsLoading | async"
					 (click)="selectWidget(graph)" [attr.disabled]="(graph.type === 'heatmap' || graph.type === 'conversion') && typeOf(toolbarService.floorId) !== 'number'">
					<div matRipple [matRippleColor]="rippleColor">
						<ng-container *ngIf="['bar', 'line'].includes(graph.type); else elseBlock">
							<ng-container *ngIf="data[graph?.page]?.kpi || data[graph?.page]?.kpi === 0; else dash">
								<ng-container *ngIf="graph.isTime; else number">
									<span class="value">{{data[graph?.page]?.kpi * 1000 | duration: true}}</span>
								</ng-container>
								<ng-template #number>
									<span class="value">{{data[graph?.page]?.kpi | number : '1.0-1'}}{{graph.units}}</span>
								</ng-template>
							</ng-container>
							<ng-template #dash>
								<span class="value">-</span>
							</ng-template>
							<span class="description">{{graph.label}}</span>
						</ng-container>
						<ng-template #elseBlock>
							<span class="value">{{graph.label}}</span>
						</ng-template>
						<!-- <ng-container *ngIf="graph.liveModeSupport">
							<button [disabled]="!selectedGraph?.liveModeSupport"
									[ngStyle]="{'background': isLive ? 'red' : 'unset'}" class="g-btn live-mode-button"
									[ngClass]="{'a-btn': isLive}" (click)="toggleLiveMode()">
								{{'Last targets' | translate | uppercase}}
							</button>
						</ng-container> -->
					</div>
				</div>
			</div>
			<div class="content">
				<div class="container" [class.loading]="busEventService.restIsLoading | async">
					<app-c3-graph *ngIf="['bar', 'line'].includes(selectedGraph?.type)"
								  [type]="selectedGraph?.type"
								  [data]="data[selectedGraph?.page]"
								  [colors]="colors"
								  [yFormat]="selectedGraph?.isPercent ? '%' : null"
								  [xValues]="xValues"
								  [yValues]="selectedGraph?.isPercent ? yValues : null"
								  [axisLabels]="selectedGraph?.axisLabels"
								  (rendered)="onRendered()"></app-c3-graph>
					<app-floor-plan-layer #heatmapComponent *ngIf="selectedGraph?.type === 'heatmap'"
										  [data]="data[selectedGraph?.page]"
										  [isSensorVisible]="false"
										  [showTargets]="false"
										  [showGeoFences]="true"
										  [showMenu]="false"
										  [geoFences]="geoFences"
										  [isGeoFenceEditable]="false"
										  [heatmapColors]="'cold'"
										  [parameters]="sensorSocketSettings"
										  (rendered)="onRendered()">
					</app-floor-plan-layer>
					<app-floor-plan-layer #conversionComponent *ngIf="selectedGraph?.type === 'conversion'"
										  [data]="data[selectedGraph?.page]"
										  [isSensorVisible]="false"
										  [showTargets]="false"
										  [showHeatMap]="false"
										  [showMenu]="false"
										  [geoFences]="geoFences"
										  [isGeoFenceEditable]="false"
										  [showGeoFenceConversion]="true"
										  [heatmapColors]="'cold'"
										  [parameters]="sensorSocketSettings"
										  (rendered)="onRendered()">
					</app-floor-plan-layer>
				</div>
			</div>
		</ng-container>
	</div>
	<app-loading *ngIf="busEventService.restIsLoading | async" type="indeterminate" size="800" [showStatus]="true"></app-loading>
</div>
