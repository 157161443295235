<ng-container *ngIf="connection && ![ConnectionStatus.DISCONNECTED, ConnectionStatus.NOT_CONNECTED].includes(connection.connectionStatus)">
		<app-layer-controls
			*ngIf="showMenu"
			[isDisabled]="isDisabled()"
			[reflect]="reflect"
			[rotateValue]="rotateValue"
			[rulerValue]="rulerValue"
			[sensitivityValueSelect]="sensitivityValueSelect"
			[sensorValue]="sensorValue"
			[layersByComponent]="layersByComponent"
			[layersByCategory]="layersByCategory"
			[isFlipEnable]="isFlipEnable"
			[isReset3dEnable]="isReset3dEnable"
			[isRotateEnable]="isRotateEnable"
			[isRulerEnable]="isRulerEnable"
			[isSensitivityEnable]="isSensitivityEnable"
			[isCameraListEnable]="isCameraListEnable"
			[isSensorEnable]="isSensorEnable"
			[isViewShow]="isViewShow"
			[isSettingInReadOnly]="isSettingInReadOnly()"
			[cameraList]="cameraList"
			[cameraValue]="cameraValue"

			(onViewShow)="isViewShow = $event"
			(flip)="flip.emit()"
			(reset3d)="reset3D($event)"
			(sensor)="sensor.emit()"
			(toggleLayer)="onToggleLayer($event)"
			(toggleCamera)="onToggleCamera($event)"
			(sensitivityValue)="sensitivityValue.emit($event)"
			(ruler)="ruler.emit()"
			(rotate)="rotate.emit()"
		></app-layer-controls>

	<div class="h-100 w-100" (mousedown)="closeViewPanel()" (click)="closeViewPanel()">
		<app-targets-3d *ngIf="isComponentActive('Targets3DComponent')"
						[data]="isMultipleSensors ? localCombinedData['Targets3DComponent'] : localData['Targets3DComponent']"
						[postureValue]="postureValue"
						[reflect]="isFlipEnable && layersByComponent['Targets3DComponent'].isXAxisInverted ? !reflect : reflect"
						[sensorSocket]="connection"
						[parameters]="sensorSocketSettings"
						[isMultipleSensors]="isMultipleSensors"
						[connectedSensorsSettings]="connectedSensorsSettings"
						[isSensorVisible]="isSensorEnable && sensorValue"
						[StandingHuman3DModelOriginal]="StandingHuman3DModel"
						[SittingHuman3DModelOriginal]="SittingHuman3DModel"
						[LyingHuman3DModelOriginal]="LyingHuman3DModel"
						[WalkingHuman3DModelOriginal]="WalkingHuman3DModel"
						(flip)="toggleFlip($event)">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['Targets3DComponent'].controls}">
			</ng-container>
		</app-targets-3d>

		<app-point-cloud-3d
			*ngIf="isComponentActive('PointCloud3dComponent') && layersByComponent['PointCloud3dComponent'].isRaw && !layersByComponent['PointCloud3dComponent'].isSmartTailor"
			[isRaw]="true"
			[data]="isMultipleSensors ? localCombinedData['PointCloud3dComponent'] : localData['PointCloud3dComponent']"
			[sensorSocket]="connection"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['PointCloud3dComponent'].controls}">
			</ng-container>
		</app-point-cloud-3d>

		<app-point-cloud-3d
			*ngIf="isComponentActive('PointCloud3dComponent') && layersByComponent['PointCloud3dComponent'].isSmartTailor"
			[isRaw]="true"
			[isSmartTailor]="true"
			[data]="isMultipleSensors ? localCombinedData['PointCloud3dComponent'] : localData['PointCloud3dComponent']"
			[sensorSocket]="connection"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['PointCloud3dComponent'].controls}">
			</ng-container>
		</app-point-cloud-3d>

		<app-point-cloud-3d
			*ngIf="isComponentActive('PointCloud3dComponent') && !layersByComponent['PointCloud3dComponent'].isRaw && !layersByComponent['PointCloud3dComponent'].isSmartTailor"
			[isRaw]="false"
			[data]="isMultipleSensors ? localCombinedData['PointCloud3dComponent'] : localData['PointCloud3dComponent']"
			[sensorSocket]="connection"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['PointCloud3dComponent'].controls}">
			</ng-container>
		</app-point-cloud-3d>

		<app-sensor-tracker-two-d-raw-view *ngIf="isComponentActive('SensorTracker2DRawViewComponent')"
										   [isRaw]="layersByComponent['SensorTracker2DRawViewComponent'].isRaw"
										   [data]="localData['SensorTracker2DRawViewComponent']"
										   [rotate]="isRotateEnable && rotateValue"
										   [postureValue]="postureValue"
										   [reflect]="isFlipEnable && layersByComponent['SensorTracker2DRawViewComponent'].isXAxisInverted ? !reflect : reflect"
										   [ruler]="rulerValue"
										   [isSensorVisible]="isSensorEnable && sensorValue"
										   [parameters]="sensorSocketSettings"
										   [layer]="layersByComponent['SensorTracker2DRawViewComponent']"
										   [axesEnabled]="layersByComponent['SensorTracker2DRawViewComponent'].axesEnabled">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SensorTracker2DRawViewComponent'].controls}">
			</ng-container>
		</app-sensor-tracker-two-d-raw-view>

		<app-point-cloud-raw2d-wand *ngIf="isComponentActive('PointCloudRaw2dComponentWand')"
									[data]="localData['PointCloudRaw2dComponentWand']">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['PointCloudRaw2dComponentWand'].controls}">
			</ng-container>
		</app-point-cloud-raw2d-wand>

		<app-pose-landmark2d *ngIf="isComponentActive('PoseLandmark2dComponent')"
							 [parameters]="sensorSocketSettings"
							 [staticDataDict]="layersByComponent['PoseLandmark2dComponent'].staticDataDict"
							 [data]="localData['PoseLandmark2dComponent']">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['PoseLandmark2dComponent'].controls}">
			</ng-container>
		</app-pose-landmark2d>

		<app-targets-info *ngIf="isComponentActive('TargetsInfoComponent')"
						  [data]="localData['TargetsInfoComponent']">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['TargetsInfoComponent'].controls}">
			</ng-container>
		</app-targets-info>

		<app-number-of-people *ngIf="isComponentActive('NumberOfPeopleComponent')"
							  [data]="localData['NumberOfPeopleComponent']">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['NumberOfPeopleComponent'].controls}">
			</ng-container>
		</app-number-of-people>

		<app-room-occupancy-layer *ngIf="isComponentActive('RoomOccupancyComponent')"
							[data]="localData['RoomOccupancyComponent']"
							perfectScrollbar>
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['RoomOccupancyComponent'].controls}">
			</ng-container>
		</app-room-occupancy-layer>

		<app-in-car-occupancy *ngIf="isComponentActive('InCarOccupancyComponent')"
								  [rotate]="isRotateEnable && rotateValue"
								  [data]="isMultipleSensors ? localCombinedData['InCarOccupancyComponent'] : localData['InCarOccupancyComponent']"
							      [isMultipleSensors]="isMultipleSensors"
								  [connectedSensorsSettings]="connectedSensorsSettings"
								  [parameters]="sensorSocketSettings"
								  [sensorSocket]="connection"
								  [selectedModelIds]="selectedModelIds"
								  [metadata]="layersByComponent['InCarOccupancyComponent'].metadata"
								  [cfgDict]="layersByComponent['InCarOccupancyComponent'].cfgDict"
							  	  [isTrackerEnabled]="layersByComponent['InCarOccupancyComponent'].tracker"
								  [isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['InCarOccupancyComponent'].controls}">
			</ng-container>
		</app-in-car-occupancy>

		<app-in-car-occupancy-base *ngIf="isComponentActive('InCarOccupancyBaseComponent')"
							  [rotate]="isRotateEnable && rotateValue"
							  [data]="isMultipleSensors ? localCombinedData['InCarOccupancyBaseComponent'] : localData['InCarOccupancyBaseComponent']"
							  [isMultipleSensors]="isMultipleSensors"
							  [connectedSensorsSettings]="connectedSensorsSettings"
							  [parameters]="sensorSocketSettings"
							  [sensorSocket]="connection"
							  [selectedModelIds]="selectedModelIds"
							  [metadata]="layersByComponent['InCarOccupancyBaseComponent'].metadata"
							  [cfgDict]="layersByComponent['InCarOccupancyBaseComponent'].cfgDict"
							  [isSensorVisible]="isSensorEnable && sensorValue"
		></app-in-car-occupancy-base>

		<app-in-car-only-occupancy *ngIf="isComponentActive('InCarOnlyOccupancyComponent')"
							  [rotate]="isRotateEnable && rotateValue"
							  [data]="isMultipleSensors ? localCombinedData['InCarOnlyOccupancyComponent'] : localData['InCarOnlyOccupancyComponent']"
							  [isMultipleSensors]="isMultipleSensors"
							  [connectedSensorsSettings]="connectedSensorsSettings"
							  [parameters]="sensorSocketSettings"
							  [sensorSocket]="connection"
							  [selectedModelIds]="selectedModelIds"
							  [metadata]="layersByComponent['InCarOnlyOccupancyComponent'].metadata"
							  [cfgDict]="layersByComponent['InCarOnlyOccupancyComponent'].cfgDict"
							  [isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['InCarOnlyOccupancyComponent'].controls}">
			</ng-container>
		</app-in-car-only-occupancy>

		<app-car-point-cloud
			*ngIf="isComponentActive('CarPointCloudComponent') && layersByComponent['CarPointCloudComponent'].isRaw"
			[isRaw]="true"
			[data]="isMultipleSensors ? localCombinedData['CarPointCloudComponent'] : localData['CarPointCloudComponent']"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[sensorSocket]="connection"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['CarPointCloudComponent'].controls}">
			</ng-container>
		</app-car-point-cloud>

		<app-car-point-cloud
			*ngIf="isComponentActive('CarPointCloudComponent') && !layersByComponent['CarPointCloudComponent'].isRaw"
			[isRaw]="false"
			[data]="isMultipleSensors ? localCombinedData['CarPointCloudComponent'] : localData['CarPointCloudComponent']"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[sensorSocket]="connection"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['CarPointCloudComponent'].controls}">
			</ng-container>
		</app-car-point-cloud>

		<app-srr-cloud-point
			*ngIf="isComponentActive('SsrCloudPointComponent') && layersByComponent['SsrCloudPointComponent'].isRaw"
			[isRaw]="true"
			[data]="isMultipleSensors ? localCombinedData['SsrCloudPointComponent'] : localData['SsrCloudPointComponent']"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[sensorSocket]="connection"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SsrCloudPointComponent'].controls}">
			</ng-container>
		</app-srr-cloud-point>

		<app-srr-cloud-point
			*ngIf="isComponentActive('SsrCloudPointComponent') && !layersByComponent['SsrCloudPointComponent'].isRaw"
			[isRaw]="false"
			[data]="isMultipleSensors ? localCombinedData['SsrCloudPointComponent'] : localData['SsrCloudPointComponent']"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[sensorSocket]="connection"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SsrCloudPointComponent'].controls}">
			</ng-container>
		</app-srr-cloud-point>

		<app-boxes-point-cloud
			*ngIf="isComponentActive('BoxesPointCloudComponent') && layersByComponent['BoxesPointCloudComponent'].isRaw"
			[isRaw]="true"
			[data]="isMultipleSensors ? localCombinedData['BoxesPointCloudComponent'] : localData['BoxesPointCloudComponent']"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[metadata]="layersByComponent['BoxesPointCloudComponent'].metadata"
			[cfgDict]="layersByComponent['BoxesPointCloudComponent'].cfgDict"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[sensorSocket]="connection"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['BoxesPointCloudComponent'].controls}">
			</ng-container>
		</app-boxes-point-cloud>

		<app-boxes-point-cloud
			*ngIf="isComponentActive('BoxesPointCloudComponent') && !layersByComponent['BoxesPointCloudComponent'].isRaw"
			[isRaw]="false"
			[data]="isMultipleSensors ? localCombinedData['BoxesPointCloudComponent'] : localData['BoxesPointCloudComponent']"
			[isMultipleSensors]="isMultipleSensors"
			[parameters]="sensorSocketSettings"
			[metadata]="layersByComponent['BoxesPointCloudComponent'].metadata"
			[cfgDict]="layersByComponent['BoxesPointCloudComponent'].cfgDict"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[sensorSocket]="connection"
			[isSensorVisible]="isSensorEnable && sensorValue">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['BoxesPointCloudComponent'].controls}">
			</ng-container>
		</app-boxes-point-cloud>

		<app-breathing *ngIf="isComponentActive('BreathingComponent')"
                       [data]="localData['BreathingComponent']"
                       [dataKeyName]="layersByComponent['BreathingComponent'].dataDict['data']"
                       [measLabel]="layersByComponent['BreathingComponent'].staticDataDict?.measLabel"
                       [parameters]="sensorSocketSettings"
                       [selectedModelIds]="selectedModelIds"
                       [sensorSocket]="connection"
                       [status]="connection?.connectionStatus" perfectScrollbar>
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['BreathingComponent'].controls}">
			</ng-container>
		</app-breathing>

		<app-patient-data-graph *ngIf="isComponentActive('HeartRateGraphComponent')"
								[data]="localData['HeartRateGraphComponent']"
								[measLabel]="layersByComponent['HeartRateGraphComponent'].staticDataDict?.measLabel || 'RPM'"
								[parameters]="sensorSocketSettings"
								[selectedModelIds]="selectedModelIds"
								[sensorSocket]="connection"
								[status]="connection?.connectionStatus" perfectScrollbar>
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['HeartRateGraphComponent'].controls}">
			</ng-container>
		</app-patient-data-graph>

		<app-activity *ngIf="isComponentActive('ActivityComponent')"
                      [data]="localData['ActivityComponent']"
                      [dataKeyName]="layersByComponent['ActivityComponent'].dataDict['data']"
                      [measLabel]="layersByComponent['ActivityComponent'].staticDataDict?.measLabel"
                      [parameters]="sensorSocketSettings"
                      [selectedModelIds]="selectedModelIds"
                      [sensorSocket]="connection"
                      [status]="connection?.connectionStatus" perfectScrollbar>
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['ActivityComponent'].controls}">
			</ng-container>
		</app-activity>

		<app-retail-stats *ngIf="isComponentActive('RetailStatsComponent')"
						  [data]="localData['RetailStatsComponent']"
						  perfectScrollbar>
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['RetailStatsComponent'].controls}">
			</ng-container>
		</app-retail-stats>

		<app-app-info *ngIf="isComponentActive('AppInfoComponent')"
					  [sensorSocket]="connection"
					  [layerName]="layersByComponent['AppInfoComponent'].displayName"
					  [data]="localData['AppInfoComponent']">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['AppInfoComponent'].controls}">
			</ng-container>
		</app-app-info>

		<app-point-cloud-2d
			*ngIf="isComponentActive('PointCloud2dComponent')"
			[data]="isMultipleSensors ? localCombinedData['PointCloud2dComponent'] : localData['PointCloud2dComponent']"
			[staticDataDict]="layersByComponent['PointCloud2dComponent'].staticDataDict"
			[postureValue]="postureValue"
			[parameters]="sensorSocketSettings"
			[connectedSensorsSettings]="connectedSensorsSettings"
			[rotateValue]="isRotateEnable && rotateValue"
			[isSensorVisible]="isSensorEnable && sensorValue"
			[isMultipleSensors]="isMultipleSensors"
			[showGrid]="rulerValue"
			[reflect]="isFlipEnable && layersByComponent['PointCloud2dComponent'].isXAxisInverted ? !reflect : reflect">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['PointCloud2dComponent'].controls}">
			</ng-container>
		</app-point-cloud-2d>

		<app-floor-plan-layer *ngIf="isComponentActive('FloorPlanLayerComponent')"
							  data-selector="floor-plan_layer"
			[data]="localCombinedData['FloorPlanLayerComponent']"
			[showHeatMap]="false"
			[parameters]="sensorSocketSettings"
            [geoFences]="geoFences"
			[subToolbarIsAnimated]="subToolbarIsAnimated">
		</app-floor-plan-layer>

		<app-smart-home-dashboard *ngIf="isComponentActive('SmartHomeDashboardComponent')" [socket]="connection"
								  [parameters]="sensorSocketSettings">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SmartHomeDashboardComponent'].controls}">
			</ng-container>
		</app-smart-home-dashboard>

		<app-smart-home-ac *ngIf="isComponentActive('SmartHomeAcComponent')" [socket]="connection"
						   [parameters]="sensorSocketSettings">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SmartHomeAcComponent'].controls}">
			</ng-container>
		</app-smart-home-ac>

		<app-cooler-inventory
			*ngIf="isComponentActive('CoolerInventoryComponent') && layersByComponent['CoolerInventoryComponent'].name === 'CoolerInventory'"
			[sensorSocket]="connection"
			[parameters]="sensorSocketSettings"
			[Bottle3DModelOriginal]="Bottle3DModel"
			[Fridge3DModelOriginal]="Fridge3DModel"
			[data]="localData['CoolerInventoryComponent']"
			[bottlesRowsColumnsLevel]="bottlesRowsColumnsLevel">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['CoolerInventoryComponent'].controls}">
			</ng-container>
		</app-cooler-inventory>

		<app-cooler-inventory
			*ngIf="isComponentActive('CoolerInventoryComponent') && layersByComponent['CoolerInventoryComponent'].name === 'CoolerTopView'"
			[isTopView]="true"
			[sensorSocket]="connection"
			[parameters]="sensorSocketSettings"
			[Bottle3DModelOriginal]="Bottle3DModel"
			[Fridge3DModelOriginal]="Fridge3DModel"
			[data]="localData['CoolerInventoryComponent']"
			[bottlesRowsColumnsLevel]="bottlesRowsColumnsLevel">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['CoolerInventoryComponent'].controls}">
			</ng-container>
		</app-cooler-inventory>

		<app-smart-tailor-measurements *ngIf="isComponentActive('SmartTailorMeasurementsComponent')"
									   [data]="localData['SmartTailorMeasurementsComponent']">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SmartTailorMeasurementsComponent'].controls}">
			</ng-container>
		</app-smart-tailor-measurements>

		<app-smart-tailor-silhouette *ngIf="isComponentActive('SmartTailorSilhouetteComponent')"
									 [data]="localData['SmartTailorSilhouetteComponent']">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SmartTailorSilhouetteComponent'].controls}">
			</ng-container>
		</app-smart-tailor-silhouette>

		<app-shopper-insights *ngIf="isComponentActive('ShopperInsightsComponent')"
							  [data]="localData['ShopperInsightsComponent']">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['ShopperInsightsComponent'].controls}">
			</ng-container>
		</app-shopper-insights>

		<app-smart-tailor-launcher *ngIf="isComponentActive('SmartTailorLauncherComponent')"
								   [data]="localData['SmartTailorLauncherComponent']"
								   [sensorSocket]="connection">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SmartTailorLauncherComponent'].controls}">
			</ng-container>
		</app-smart-tailor-launcher>

		<app-smart-retail *ngIf="isComponentActive('SmartRetailComponent')"
						  [graphs]="layersByComponent['SmartRetailComponent'].staticDataDict?.graphs"
						  [sensorSocketSettings]="sensorSocketSettings"
						  [data]="data"
						  [connection]="connection"
						  [geoFences]="geoFences"
						  (toggleWidget)="toggleLayer.emit($event)">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['SmartRetailComponent'].controls}">
			</ng-container>
		</app-smart-retail>

		<app-geo-fences *ngIf="isComponentActive('GeoFencesComponent')"
						  [graphs]="layersByComponent['GeoFencesComponent'].staticDataDict?.graphs"
						  [sensorSocketSettings]="sensorSocketSettings"
						  [data]="data"
						  [connection]="connection"
						  [geoFences]="geoFences"
						  (toggleWidget)="toggleLayer.emit($event)">
			<ng-container *ngTemplateOutlet="controlTemplate;context:{controls: layersByComponent['GeoFencesComponent'].controls}">
			</ng-container>
		</app-geo-fences>
		<app-web-camera-video *ngIf="isComponentActive('WebCameraVideoComponent')"
								[isCameraListEnable]="isCameraListEnable"
							  	[deviceId]="cameraValue"
								(deviceList)="onUpdateDeviceList($event)"></app-web-camera-video>
	</div>
</ng-container>

<button class="close" *ngIf="showCloseBtn" (click)="onToggleDuplicate()" mat-raised-button></button>

<ng-template #controlTemplate let-controls="controls">
	<ng-container *ngFor="let control of controls">
		<ng-container *ngIf="control.enable">
			<ng-container [ngSwitch]="control.type">
				<button *ngSwitchCase="ConfigurationLayerControlType.LAYER_SWITCH" (click)="onControlCheckboxClick(control)"
						mat-raised-button class="dynamic-control"
						[attr.data-selector]="control.param + '-button'"
						[matTooltip]="sensorSocketSettings['sensorParameters'][control.param] ? control.off_tooltip : control.on_tooltip">
					<span class="material-icons md-18" [class.active]="sensorSocketSettings['sensorParameters'][control.param]">
						{{control.icon || defaultLayerControlIcon | materialIcon}}
					</span>
				</button>
				<button *ngSwitchCase="ConfigurationLayerControlType.LAYER_BTN_CFG_BKG_IMG" (click)="onControlBkgImgClick()"
						[matTooltip]="control.tooltip"
						mat-raised-button class="dynamic-control">
					<span class="material-icons md-18">
						{{control.icon || defaultLayerControlIcon | materialIcon}}
					</span>
				</button>
				<ng-container *ngSwitchCase="ConfigurationLayerControlType.LAYER_INFO">
					<div *ngIf="sensorSocketSettings['sensorParameters'][control.param]?.length" class="layer-info" [matTooltip]="control.tooltip">
						<h3>{{control.label}}</h3>
						{{sensorSocketSettings['sensorParameters'][control.param]}}
					</div>
				</ng-container>
				<mat-form-field *ngSwitchCase="ConfigurationLayerControlType.LAYER_DROPDOWN" style="margin: 0 5px;"
								floatLabel="always" [ngStyle]="{'paddingTop.px': control.title ? 5 : 0 }">
					<mat-label *ngIf="control.title" style="margin-top: 5px;">
						{{control.title}}
					</mat-label>
					<mat-select #select
								[multiple]="control.multiple"
								[attr.data-selector]="control.param"
								[matTooltip]="control.tooltip"
								[placeholder]="control.label"
								[value]="getDropdownControlValue(control, select)"
								(selectionChange)="selectLayerDropDown($event.value, control)">
						<ng-container *ngFor="let option of control.options">
							<mat-option *ngIf="filterSelectedControllerIds(option, control)"
								[value]="option"
								[attr.value]="option.value">
								{{option.label}}
							</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</ng-container>
			<div class="separator"></div>
		</ng-container>
	</ng-container>
</ng-template>
