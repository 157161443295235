import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {SensorSocket} from '../../../../../services/system/sensor-socket';
import {state} from '../smart-tailor-measurements/smart-tailor-measurements.component';

/**
 * Component for configuring sensor before smart tailor scanning.
 * Used for Smart Tailor app.
 */
@Component({
	selector: 'app-smart-tailor-launcher',
	templateUrl: './smart-tailor-launcher.component.html',
	styleUrls: ['./smart-tailor-launcher.component.scss']
})
export class SmartTailorLauncherComponent implements OnInit, OnChanges {

	@Input() sensorSocket: SensorSocket;
	@Input() data: any = {};

	gender = '';
	isDisableState = true;

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		this.isDisableState = this.data && this.data.state && ![state.STATE_DONE, state.STATE_NO_PERSON].includes(this.data.state);
	}

	selectGender(gender) {
		this.gender = gender;
		this.sensorSocket.setGender({gender}).catch(console.error.bind(console));
	}

	setReadyForScanning(s = true) {
		this.sensorSocket.setReadyForScanning({
			readyForScanning: s
		}).catch(console.error.bind(console));
	}
}
