import {EventEmitter} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Socket} from './socket';
import {ConnectionStatus} from './connection';
import {ISupportedParameter, ISupportedParameterProperties, OutputType} from '../../models/models';

export enum PlaybackState {
	STOPPED,
	PAUSED,
	PLAYING
}

export enum RunMode {
	LIVE,
	RECORD,
	PLAYBACK,
	REPROCESS_IQ_DATA,
	REPROCESS_AND_RECORD
}

/**
 * Class for working with websockets using specified for sensor commands.
 */
export class SensorSocket extends Socket {

	readonly isMultipleSensors: boolean = false;
	supportedParameters: {
		[parameterName: string]: ISupportedParameter
	} = {};

	metadata = new EventEmitter;
	recordingInfoUpdated = new EventEmitter;
	parameters = new EventEmitter;
	configuration = new EventEmitter;
	anotherClientParametersChanged = new EventEmitter;
	sensorLocationData = new EventEmitter;
	anotherClientSensorLocationDataChanged = new EventEmitter;

	isSensorsNetworkEnabled = false;
	isCovidDetection = false;
	isInventoryDemo = false;
	isRoomOccupancy = false;
	isSmartHome = false;
	isAnotherClientConnected = false;

	recordingInfo: any;

	fps = 0;
	lastParameters = {};

	get numberOfConnectedClients() {
		return [ConnectionStatus.NOT_CONNECTED, ConnectionStatus.DISCONNECTED].includes(this.connectionStatus) ? 0 : this._numberOfConnectedClients;
	}

	// Used while status is IMAGING
	private dataTimeout = environment.sensorImagingDataTimeout;
	private dataTimer;
	private isQueryJsonData = false;
	private isQueryBinaryData = false;
	private fpsTimes: Array<number> = [];
	private lastDataTime;
	private _numberOfConnectedClients = 0;
	private _isParametersRetrieved = false;

	constructor(public readonly ip?, public readonly port?) {
		super(ip, port);
		this.createRecordingInfo(RunMode.LIVE);
	}

	isRunned() {
		return this.isConnected() && ([ConnectionStatus.IMAGING].includes(this.connectionStatus) || this.recordingInfo.mode === RunMode.PLAYBACK);
	}

	isParametersRetrieved(): boolean {
		return this._isParametersRetrieved;
	}

	connect() {
		return super.connect().then(e => {
			clearTimeout(this.dataTimer);
			this.subscriptions.push(this.close.subscribe(() => {
				this.onDisconnect();
			}));
			return e;
		});
	}

	disconnect() {
		super.disconnect();
		this.onDisconnect();
	}

	start() {
		this.lastDataTime = null;
		this.fpsTimes = [];
		this.fps = 0;
		return this.sendRawData({
			'Type': 'COMMAND',
			'ID': 'START',
			'Payload': Object.assign(
				{
					clientId: this.clientId
				},
				this.lastUsedOutputs
			)
		});
	}

	stop() {
		this.connectionStatus = ConnectionStatus.STOPPED;
		return this.sendRawData({
			'Type': 'COMMAND',
			'ID': 'STOP',
			'Payload': {}
		});
	}

	queryJsonData() {
		this.isQueryJsonData = true;
		return this.sendRawData({
			Type: 'QUERY', ID: 'JSON_DATA'
		});
	}

	stopQueryJsonData() {
		this.isQueryJsonData = false;
	}

	stopQueryBinaryData() {
		this.isQueryBinaryData = false;
	}

	stopQueryData() {
		this.stopQueryJsonData();
		this.stopQueryBinaryData();
	}

	getRecFrame(frame_number) {
		return this.sendRawData({
			Type: 'COMMAND',
			ID: 'GET_REC_FRAME',
			Payload: {
				frame_number: frame_number
			}
		});
	}

	isQueringJsonData() {
		return this.isQueryJsonData;
	}

	isQueringBinaryData() {
		return this.isQueryBinaryData;
	}

	queryBinaryData(): boolean {
		this.isQueryBinaryData = true;
		return this.sendRawData({
			Type: 'QUERY', ID: 'BINARY_DATA'
		});
	}

	getRecMetadata(): Promise<object> {
		this.sendCommand('GET_REC_METADATA');
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['GET_REC_METADATA']) {
				this.promiseCallbacks['GET_REC_METADATA'] = [];
			}
			this.promiseCallbacks['GET_REC_METADATA'].push({resolve: resolve, reject: reject});
		});
	}

	resetSensorParameters(): Promise<object> {
		this.sendCommand('RESET_PARAMS', this.supportedParameters);
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['RESET_PARAMS']) {
				this.promiseCallbacks['RESET_PARAMS'] = [];
			}
			this.promiseCallbacks['RESET_PARAMS'].push({resolve: resolve, reject: reject});
		});
	}

	getSensorParameters(supportedParameters): Promise<object> {
		this.sendCommand('GET_PARAMS', supportedParameters);
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['GET_PARAMS']) {
				this.promiseCallbacks['GET_PARAMS'] = [];
			}
			this.promiseCallbacks['GET_PARAMS'].push({
				resolve: resolve,
				reject: reject,
				payload: supportedParameters
			});
		});
	}

	setGender(supportedParameters): Promise<object> {
		this.sendCommand('SET_GENDER', supportedParameters);
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['SET_GENDER']) {
				this.promiseCallbacks['SET_GENDER'] = [];
			}
			this.promiseCallbacks['SET_GENDER'].push({resolve: resolve, reject: reject});
		});
	}

	setReadyForScanning(state) {
		this.sendCommand('READY_FOR_SCANNING', state);
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['READY_FOR_SCANNING']) {
				this.promiseCallbacks['READY_FOR_SCANNING'] = [];
			}
			this.promiseCallbacks['READY_FOR_SCANNING'].push({resolve: resolve, reject: reject});
		});
	}

	/**
	 * Set new parameters to sensor
	 * @param parameters
	 * @param {boolean} notify - notify another connected clients about parameters changes
	 * @returns {Promise<object>}
	 */
	setSensorParameters(parameters, notify): Promise<object> {
		/**
		 * @see onMessage
		 * In case we did not initialize this change:
		 * on SET_PARAMS - provide popup "For your information, another connected user had just changed some settings"
		 * on SET_PARAMS_BEFORE_IMAGING - don't provide this popup
		 */
		let command = notify ? 'SET_PARAMS' : 'SET_PARAMS_BEFORE_IMAGING';
		// TODO refactor it!
		if ('ProcessorCfg.ExternalGUI.Zones.interestArea' in parameters && parameters['ProcessorCfg.ExternalGUI.Zones.interestArea'] && parameters['ProcessorCfg.ExternalGUI.Zones.interestArea'].length === 1) {
			parameters['ProcessorCfg.ExternalGUI.Zones.interestArea'] = parameters['ProcessorCfg.ExternalGUI.Zones.interestArea'][0];
		}
		Object.keys(parameters).forEach(parameter => {
			if (!this.isEditableParameter(parameter)) {
				delete parameters[parameter];
			}
		});
		Object.keys(parameters).forEach(parameter => {
			if (!this.isSupportedParameter(parameter)) {
				delete parameters[parameter];
			}
		});
		if (Object.keys(parameters).length) {
			this.sendCommand(command, parameters);
			Object.assign(this.lastParameters, parameters);
			return new Promise((resolve, reject) => {
				if (!this.promiseCallbacks[command]) {
					this.promiseCallbacks[command] = [];
				}
				this.promiseCallbacks[command].push({resolve: resolve, reject: reject});
			});
		} else {
			return Promise.resolve({});
		}
	}

	addSupportedParameter(parameter: ISupportedParameter): void {
		this.supportedParameters[parameter.name] = parameter;
	}

	isSupportedParameter(parameterName: string): boolean {
		return parameterName in this.supportedParameters;
	}

	isEditableParameter(parameterName: string): boolean {
		return <boolean>this.getSupportedParameterProperty(parameterName, 'isEditable');
	}

	isSavableParameter(parameterName: string): boolean {
		return <boolean>this.getSupportedParameterProperty(parameterName, 'isSavable');
	}

	isCacheableParameter(parameterName: string): boolean {
		return <boolean>this.getSupportedParameterProperty(parameterName, 'isCacheable');
	}

	setOutputs(type?: OutputType, outputs?: Array<string>) {
		if (type && outputs) {
			if (this.lastUsedOutputs[type] && this.lastUsedOutputs[type].length === outputs.length && outputs.every(o => this.lastUsedOutputs[type].includes(o))) {
				return;
			}
			this.lastUsedOutputs[type] = outputs;
		}
		this.sendCommand('SET_OUTPUTS', Object.assign({
			clientId: this.clientId
		}, this.lastUsedOutputs));
	}

	retrieveStatus() {
		return this.sendCommand('GET_STATUS');
	}

	isConnectedToLocalhost() {
		return this.ip === environment.localHostSensorIp;
	}

	setNetworkSensorLocationData(data) {
		this.sendCommand('SET_NETWORK_SENSOR_LOCATION_DATA', data);
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['SET_NETWORK_SENSOR_LOCATION_DATA']) {
				this.promiseCallbacks['SET_NETWORK_SENSOR_LOCATION_DATA'] = [];
			}
			this.promiseCallbacks['SET_NETWORK_SENSOR_LOCATION_DATA'].push({resolve: resolve, reject: reject});
		});
	}

	getNetworkSensorLocationData() {
		this.sendCommand('GET_NETWORK_SENSOR_LOCATION_DATA', {
			'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.orientation': null,
			'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.position': null
		});
	}

	changeRecordingState(newState: PlaybackState) {
		switch (newState) {
			case PlaybackState.PLAYING:
				this.recordingInfo.play();
				break;
			case PlaybackState.PAUSED:
				this.recordingInfo.pause();
				break;
			case PlaybackState.STOPPED:
				this.recordingInfo.stop(true);
				break;
		}
	}

	createRecordingInfo(mode: RunMode, parameters = {path: ''}) {
		var sensorSocket = this;

		this.recordingInfo = {
			path: parameters.path,
			mode: mode,
			state: PlaybackState.STOPPED,
			startTime: 0,
			currentTime: 0,
			endTime: 0,
			metadataLoaded: false,
			// fps: 0,
			get fps() {
				return this.numberOfFrames / (this.durationInMs / 1000);
			},
			numberOfFrames: 0,
			currentFrame: 0,
			lastRequestedFrame: 0,
			timerIsStarted: false,
			get durationInMs() {
				return this.endTime - this.startTime;
			},
			get intervalTickDelay() {
				return 1 / this.fps * 1000;
			},
			_internalTimerRef: null,
			tick: function () {
				if (this.isRecording()) {
					this.endTime = +new Date();
				} else if (this.mode === RunMode.PLAYBACK) {
					if (this.state === PlaybackState.PLAYING && this.metadataLoaded) {
						if (this.currentTime === 0) {
							this.getRecFrame(this.currentFrame);
						} else {
							if (this.currentFrame + 1 < this.numberOfFrames) {
								// We shouldn't request a new frame with the same number,
								// because it can make a queue of a few hundred of request and responses.
								// And sensor can stuck
								if (this.currentFrame + 1 !== this.lastRequestedFrame) {
									this.getRecFrame(this.currentFrame + 1);
								}
							} else {
								this.stop();
							}
						}
					}
				} else if (this.mode === RunMode.REPROCESS_IQ_DATA) {
					// No need to do anything
				}
			},
			startTimer: function () {
				if (!this.timerIsStarted) {
					this.timerIsStarted = true;
					if (this.isRecording()) {
						this.startTime = +new Date();
						sensorSocket.recordingInfoUpdated.emit('RECORDING_STARTED');
					}
					this._internalTimerRef = setInterval(() => {
						this.tick();
					}, this.intervalTickDelay);
				} else {
					console.warn('Recording info timer already started!');
				}
			},
			stopTimer: function () {
				this.timerIsStarted = false;
				if (this.isRecording()) {
					sensorSocket.recordingInfoUpdated.emit('RECORDING_FINISHED');
				}
				clearInterval(this._internalTimerRef);
			},
			getRecFrame: function (frame_number) {
				this.lastRequestedFrame = frame_number;
				sensorSocket.getRecFrame(frame_number);
			},
			onSliderMouseDown: function () {
				this.stopTimer();
			},
			onSliderChange: function (frame_number) {
				this.currentFrame = frame_number;
				this.getRecFrame(this.currentFrame);
			},
			onSliderMouseUp: function () {
				if (this.state === PlaybackState.PLAYING) {
					this.startTimer();
				}
			},
			// Only valid in playback mode
			play: function () {
				this.lastDataTime = null;
				this.fpsTimes = [];
				if (this.mode !== RunMode.PLAYBACK) {
					return;
				}
				this.state = PlaybackState.PLAYING;
				if (this.currentTime === this.endTime) {
					this.reset();
				}
				this.startTimer();
				this.tick();
				sensorSocket.recordingInfoUpdated.emit('PLAYBACK_STATE_PLAYING');
			},
			// Only valid in playback mode
			pause: function () {
				if (this.mode !== RunMode.PLAYBACK) {
					return;
				}
				this.state = PlaybackState.PAUSED;
				this.stopTimer();
				sensorSocket.recordingInfoUpdated.emit('PLAYBACK_STATE_PAUSED');
			},
			// Only valid in playback mode
			stop: function (reset = false) {
				this.stopTimer();
				if (this.mode !== RunMode.PLAYBACK) {
					return;
				}
				this.state = PlaybackState.STOPPED;
				if (reset) {
					this.reset();
				}
				sensorSocket.recordingInfoUpdated.emit(reset ? 'PLAYBACK_STATE_STOPPED' : 'PLAYBACK_FINISHED');
			},
			reset: function () {
				this.currentTime = 0;
				this.currentFrame = 0;
				this.lastRequestedFrame = 0;
				if (this.isRecording()) {
					this.endTime = 0;
					this.startTime = 0;
				}
			},
			isRecording: function () {
				return [RunMode.REPROCESS_AND_RECORD, RunMode.RECORD].includes(this.mode);
			},
			isLive: function () {
				return this.mode === RunMode.LIVE;
			},
			isPlayback: function () {
				return this.mode === RunMode.PLAYBACK;
			},
			isReprocessIqData: function () {
				return this.mode === RunMode.REPROCESS_IQ_DATA;
			}
		};
	}

	private updateRecordingInfo(metadata) {
		this.recordingInfo.startTime = metadata.start_time;
		this.recordingInfo.endTime = metadata.end_time;
		this.recordingInfo.numberOfFrames = metadata.num_frames;
		this.recordingInfo.metadataLoaded = true;
		// this.recordingInfo.fps = metadata.fps;
	}

	protected registerSocketEvent() {
		if (this.socket) {
			this.socket.onmessage = (e: MessageEvent) => {
				clearTimeout(this.dataTimer);
				if (e.data instanceof ArrayBuffer) {
					this.onMessage(this.buffer(e.data));
				} else {
					this.onMessage(JSON.parse(e.data));
				}
				if (this.dataTimeout > 0) {
					this.dataTimer = setTimeout(() => {
						if (this.connectionStatus === ConnectionStatus.IMAGING) {
							this.disconnectReason = 'timeout';
							this.disconnect();
						}
					}, this.dataTimeout);
				}
			};
		} else {
			console.warn('WebSocket object is absent');
		}
	}

	protected onMessage(json) {
		switch (json.ID) {
			case 'JSON_DATA':
			case 'BINARY_DATA':
				if ((this.isQueryJsonData || this.isQueryBinaryData) && this.connectionStatus === ConnectionStatus.IMAGING) {
					// We won't retrieve data after sensor is stopped
					this.data.emit({
						data: json.Payload
					});
					this.updateFPS();
					if (json.ID === 'JSON_DATA' && this.isQueryJsonData) {
						this.queryJsonData();
					} else if (json.ID === 'BINARY_DATA' && this.isQueryBinaryData) {
						this.queryBinaryData();
					}
				} else {
					console.log(`Data skipped: isQueryJsonData - ${this.isQueryJsonData}, isQueryBinaryData - ${this.isQueryBinaryData}, ConnectionStatus - ${this.connectionStatus}`);
				}
				break;
			case 'GET_REC_FRAME':
				this.recordingInfo.currentTime = json.Payload.timestamp;
				this.recordingInfo.currentFrame = json.Payload.frame_number;
				this.data.emit({
					type: 'json',
					data: json.Payload
				});
				this.updateFPS();
				break;
			case 'GET_PARAMS':
				if (environment.configurablePagesParameterName in json.Payload) {
					this.configuration.emit(json.Payload[environment.configurablePagesParameterName]);
				} else if (environment.supportedSensorParametersListParameterName in json.Payload) {

				} else {
					// Do it only once
					let unknownParametersCount = 0;
					if (!this._isParametersRetrieved) {
						Object.keys(json.Payload).forEach(parameter => {
							let isUnknownParam = this.isUnknownParam(json.Payload[parameter]);
							if (isUnknownParam) {
								unknownParametersCount++;
								return delete json.Payload[parameter];
							}
						});
					} else {
						Object.keys(json.Payload).forEach(parameter => {
							let isUnknownParam = this.isUnknownParam(json.Payload[parameter]);
							if (isUnknownParam) {
								unknownParametersCount++;
								delete json.Payload[parameter];
							}
						});
					}
					json[this.unknownParametersCountSymbol] = unknownParametersCount;
					if (!this._isParametersRetrieved) {
						this._isParametersRetrieved = Object.keys(json.Payload).length + unknownParametersCount === Object.keys(this.supportedParameters).length;
					}
					this.parameters.emit({
						data: json.Payload,
						command: json.ID
					});

					Object.assign(this.lastParameters, json.Payload);
				}
				break;
			case 'SET_PARAMS':
				if (this.isAnotherClientConnected && !(this.promiseCallbacks[json.ID] && this.promiseCallbacks[json.ID].length)) {
					// Probably it's a change from another client
					this.anotherClientParametersChanged.emit(json.Payload);
				} else {
					this.parameters.emit({
						data: json.Payload,
						command: json.ID
					});
				}
				break;
			case 'SET_PARAMS_BEFORE_IMAGING':
				this.parameters.emit({
					data: json.Payload,
					command: json.ID
				});
				break;
			case 'RESET_PARAMS':
				this.filterUnknownParameters(json.Payload);
				break;
			case 'GET_STATUS':
				this._numberOfConnectedClients = json.Payload.numConnectedClients;
				this.isAnotherClientConnected = json.Payload.numConnectedClients > 1;
				if (json.Payload.status === ConnectionStatus.ERROR) {
					this._lastErrorMessage = json.Payload.errorMessage;
					this.connectionStatus = json.Payload.status;
					this.isQueryJsonData = false;
					// this.isQueryBinaryData = false;
					if (this.recordingInfo.mode === RunMode.PLAYBACK) {
						this.recordingInfo.pause();
					}
					setTimeout(() => {
						Object.keys(this.promiseCallbacks).forEach(command => {
							while (this.promiseCallbacks[command].length) {
								let {reject} = this.promiseCallbacks[command].shift()!;
								reject(json.Payload.errorMessage);
							}
						});
					});
					return;
				}
				if (this.connectionStatus === ConnectionStatus.CONNECTED) {
					if (this.isAnotherClientConnected) {
						this.connectionStatus = ConnectionStatus.ANOTHER_CLIENT_CONNECTED;
					}
				}

				this.connectionStatus = json.Payload.status;
				break;
			case 'GET_REC_METADATA':
				this.metadata.emit(JSON.parse(json.Payload.metadata));
				this.updateRecordingInfo(JSON.parse(json.Payload.metadata));
				break;
			case 'SET_NETWORK_SENSOR_LOCATION_DATA':
				// Probably it's a change from another client
				if (this.isAnotherClientConnected && !(this.promiseCallbacks[json.ID] && this.promiseCallbacks[json.ID].length)) {
					this.anotherClientSensorLocationDataChanged.emit(json.Payload);
				} else {
					this.sensorLocationData.emit(json.Payload);
				}
				break;
			case 'GET_NETWORK_SENSOR_LOCATION_DATA':
				this.sensorLocationData.emit(json.Payload);
				break;
			case 'MESSAGE':
				this.message.emit(json.Payload);
				break;
		}
		super.onMessage(json);
	}

	disposeRecordingInfo() {
		this.recordingInfo.stopTimer();
		this.recordingInfo.endTime = 0;
		this.recordingInfo.startTime = 0;
	}

	markThisAsSensorsNetworkEnabled() {
		this.isSensorsNetworkEnabled = true;
	}

	markThisAsCovidDetection() {
		this.isCovidDetection = true;
	}

	markThisAsInventoryDemo() {
		this.isInventoryDemo = true;
	}

	markThisAsRoomOccupancy() {
		this.isRoomOccupancy = true;
	}

	markThisAsSmartHome() {
		this.isSmartHome = true;
	}

	private updateFPS() {
		let now = performance.now();
		if (!this.lastDataTime) {
			this.lastDataTime = now;
		}
		let msPassed = now - this.lastDataTime;
		if (msPassed) {
			this.fpsTimes.push(1 / (msPassed / 1000));
			let fpsTimeAverage = this.fpsTimes.reduce((p, c) => p + c, 0) / this.fpsTimes.length;
			this.fpsTimes = this.fpsTimes.filter(time => time * 100 / fpsTimeAverage <= 200).slice(this.fpsTimes.length - 10);
			this.fps = this.fpsTimes.reduce((p, c) => p + c, 0) / this.fpsTimes.length;
		}
		this.lastDataTime = now;
	}

	private onDisconnect() {
		clearTimeout(this.dataTimer);
		this.stopQueryData();
		this.supportedParameters = {};
		this._isParametersRetrieved = false;
		this.isSensorsNetworkEnabled = false;
		this.isInventoryDemo = false;
		this.isRoomOccupancy = false;
		this.isSmartHome = false;
		this.isAnotherClientConnected = false;
		this.recordingInfo.mode = RunMode.LIVE;
		// stopQueryBinaryData
	}

	private filterUnknownParameters(payload) {
		Object.keys(payload).forEach(parameter => {
			let isUnknownParam = typeof payload[parameter] === 'string' && payload[parameter].toLowerCase() === 'unknown param';
			if (isUnknownParam) {
				delete payload[parameter];
			}
		});
	}

	private getSupportedParameterProperty(parameterName: string, propertyName: ISupportedParameterProperties) {
		if (this.isSupportedParameter(parameterName)) {
			return this.supportedParameters[parameterName][propertyName];
		} else {
			console.warn('Unknown parameter: ', parameterName);
			return environment.defaultParameterConfiguration[propertyName];
		}
	}
}
