<div class="modal-default">
	<h3 [innerHTML]="data.title | translate:data.translateData | htmlNewLine"></h3>
	<div class="message">
		<ng-container *ngIf="data.type === MODAL_USER_INPUT_TYPE.PATH">
			<ng-container *ngIf="!data.sensorParams['sensorParameters']['FlowCfg.save_dir_base'] && data.showSaveDirBaseIfNeeded">
				<h5 class="input-title">Saved files directory</h5>
				<!--<span>This directory will hold all of the files saved by recording data</span>-->
				<div class="input">
					<input type="text"
						   autocomplete="off"
						   name="pathName"
						   [(ngModel)]="save_dir_base"
						   data-selector="load-recording-dir">
					<hr>
				</div>
				<h5 class="input-title">Recording name</h5>
			</ng-container>
			<div class="input">
				<input type="text"
					   autocomplete="off"
					   name="pathName"
					   (ngModelChange)="updatePathName()"
					   [formControl]="control">
				<hr>
			</div>
		</ng-container>
		<ng-container *ngIf="data.type !== MODAL_USER_INPUT_TYPE.PATH">
			<div class="input">
				<input type="text"
					   autocomplete="off"
					   name="input"
					   [formControl]="control">
				<hr>
			</div>
		</ng-container>
		<div *ngIf="data.control?.controls?.length" style="position: relative; margin-top: 20px; min-height: 200px;" perfectScrollbar [disabled]="disabledScroll">
			<div class="additional">
				<ng-container *ngFor="let control of data.control.controls">
					<ng-container [ngSwitch]="control.type">
						<ng-container *ngSwitchCase="'tab_text_input'">
							<div class="cell" colspan="2">
								<h5 class="folder-text folder-text-left d-flex w-100 fw-bold fs-13">{{control.label}}</h5>
								<ng-container *ngTemplateOutlet="descriptionRow;context: {control: control}"></ng-container>
								<app-configurable-control [control]="control" [form]="form"></app-configurable-control>
							</div>
						</ng-container>
						<ng-container *ngSwitchCase="'tab_dropdown'">
							<div class="cell" style="align-items: center; justify-content: space-between; display: flex; flex-wrap: wrap;">
								<h5 class="folder-text folder-text-left d-flex w-100 fw-bold fs-13" style="min-width: fit-content;">
									{{control.label}}
								</h5>
								<app-configurable-control [control]="control" [form]="form" [defaultNumberInputStep]="defaultNumberInputStep"></app-configurable-control>
								<ng-container *ngTemplateOutlet="descriptionRow;context: {control: control}"></ng-container>
							</div>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<div class="cell">
								<h5 class="folder-text folder-text-left d-flex w-100 fw-bold fs-13">{{control.label}}</h5>
								<app-configurable-control [control]="control" [form]="form" [defaultNumberInputStep]="defaultNumberInputStep"></app-configurable-control>
								<ng-container *ngTemplateOutlet="descriptionRow;context: {control: control}"></ng-container>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div *ngIf="message.length" [innerHTML]="message | safe: 'html'"></div>
	</div>
	<div class="default-panel">
		<button mat-button (click)="reject()" class="g-btn reject float-left">
			{{(data.cancelText || 'Cancel') | translate}}
		</button>
		<button mat-button (click)="resolve()"
				class="g-btn reject button-right"
				[disabled]="data.type === MODAL_USER_INPUT_TYPE.PATH ? ((data.sensorParams && !save_dir_base && !!control?.errors?.pattern ) || pathName.length === 0 || someControlShouldBeSwitchedOn()) : !control.value"
				data-selector="load-recording-continue-button">
			{{ (data.okText || 'OK') | translate}}
		</button>
		<ng-container *ngIf="data.additionalButtons">
			<button *ngFor="let button of data.additionalButtons" mat-button
					(click)="resolve(button.key)"
					class="g-btn reject button-right">{{button.text}}
			</button>
		</ng-container>
	</div>
</div>

<ng-template #defaultTabRow let-control="control" let-template="template" let-form="form"
			 let-defaultNumberInputStep="defaultNumberInputStep">

</ng-template>

<ng-template #descriptionRow let-control="control">
	<span class="folder-text folder-text-left d-flex w-100 description">
		{{control.description}}
	</span>
</ng-template>

<ng-template #tabSwitch let-control="control" let-form="form">
	<div class="d-flex toggle" [formGroup]="form">
		<span class="folder-text off">{{ 'OFF' | translate }}</span>
		<app-slide-toggle class="app-slide-toggle" [formControlName]="control.param" [attr.data-selector]="control.param"
						  [readonly]="form.controls[control.param].disabled"></app-slide-toggle>
		<span class="folder-text on">{{ 'ON' | translate }}</span>
	</div>
</ng-template>

<ng-template #tabButtonsSwitch let-control="control" let-form="form">
	<ng-container [formGroup]="form">
		<mat-button-toggle-group #group [formControlName]="control.param" [attr.data-selector]="control.param">
			<mat-button-toggle *ngFor="let option of control.options" tabindex="-1" [value]="option.value" [attr.value]="option.value">
				{{ option.label | translate | uppercase }}
			</mat-button-toggle>
		</mat-button-toggle-group>
	</ng-container>
</ng-template>

<ng-template #tabNumberInput let-control="control" let-form="form" let-label="numberInputLabel"
			 let-defaultNumberInputStep="defaultNumberInputStep">
	<div class="d-flex" [formGroup]="form">
		<app-number-input [label]="label" buttonsPosition="right" [control]="form.controls[control.param]"
						  [readonly]="form.controls[control.param].disabled"
						  [max]="control.range | numberInputConfig: 1" [min]="control.range | numberInputConfig:0"
						  [step]="control.step || defaultNumberInputStep" [dataSelector]="control.param"></app-number-input>
	</div>
</ng-template>

<ng-template #tabTextInput let-control="control" let-form="form">
	<div class="d-flex" [formGroup]="form">
		<div class="text-input" [class.disabled]="form.controls[control.param].disabled">
			<input type="text" [formControlName]="control.param" [attr.data-selector]="control.param">
		</div>
	</div>
</ng-template>

<ng-template #tabDropdown let-control="control" let-form="form">
	<div class="d-flex toggle" [formGroup]="form">
		<mat-form-field floatLabel="never" [class.mat-form-field-disabled]="form.controls[control.param].disabled">
			<mat-select [formControlName]="control.param"
						[multiple]="control.multiple"
						[attr.data-selector]="control.param"
						[attr.value]="form.controls[control.param].value">
				<mat-option *ngFor="let option of control.options" [value]="option.value" [attr.value]="option.value">
					{{option.label}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</ng-template>
