import {Component, Input, OnInit} from '@angular/core';

/**
 * Component renders an icon with monitored number of people.
 */
@Component({
	selector: 'app-number-of-people',
	templateUrl: './number-of-people.component.html',
	styleUrls: ['./number-of-people.component.scss']
})
export class NumberOfPeopleComponent implements OnInit {

	@Input() data: any = {};

	constructor() {
	}

	ngOnInit() {
	}

}
