import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {environment} from '../../../environments/environment';
import {MatMenuTrigger} from '@angular/material/menu';
import {Layer} from '../../services/configuration.service';
import {Subscription} from 'rxjs';

/**
 * Component represents a panel with buttons (view, sensor, flip...) for different views (layers).
 */
@Component({
	selector: 'app-layer-controls',
	templateUrl: './layer-controls.component.html',
	styleUrls: ['./layer-controls.component.scss']
})
export class LayerControlsComponent implements OnChanges, AfterViewInit, OnDestroy {

	@ViewChild(MatMenuTrigger) public menu: MatMenuTrigger;
	@ViewChild('buttonWrap') buttonWrap: ElementRef;
	@ViewChild('cameraPanel') cameraPanel: ElementRef;

	@Input() layersByComponent;
	@Input() layersByCategory;
	@Input() rulerValue;
	@Input() reflect;
	@Input() sensorValue;
	@Input() rotateValue;
	@Input() cameraValue;
	@Input() sensitivityValueSelect;
	@Input() isDisabled: boolean;
	@Input() isSettingInReadOnly;
	@Input() isViewDisabled = false;
	@Input() isViewShow = false;

	@Input() isSensorEnable;
	@Input() isFlipEnable;
	@Input() isRotateEnable;
	@Input() isReset3dEnable;
	@Input() isRulerEnable;
	@Input() isSensitivityEnable;
	@Input() isCameraListEnable;

	@Input() hideViewButton;
	@Input() hideSensorButton;
	@Input() hideFlipButton;
	@Input() hideRotateButton;
	@Input() hideResetViewButton;
	@Input() hideRulerButton;
	@Input() hideSensitivityButton;
	@Input() opened = false;
	@Input() cameraList: Array<MediaDeviceInfo> = [];

	@Output() toggleLayer = new EventEmitter;
	@Output() toggleCamera = new EventEmitter;
	@Output() sensor = new EventEmitter;
	@Output() flip = new EventEmitter;
	@Output() rotate = new EventEmitter;
	@Output() reset3d = new EventEmitter();
	@Output() ruler = new EventEmitter;
	@Output() sensitivityValue = new EventEmitter;
	@Output() onViewShow = new EventEmitter;

	cameraPanelTop: number;
	public connect = [true, false];
	public sensitivityLabelPosition = 0;
	public isSensitivityPanelShow = false;
	public isCameraListShow = false;
	public sensitivityMin = environment.sensitivityMin;
	public sensitivityMax = environment.sensitivityMax;
	private menuOpenedOnInit = false;
	private isDisplayAreaShow = false;
	private menuOpenedSubscription: Subscription;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.opened) {
			setTimeout(() => {
				if (this.opened) {
					this.menu.openMenu();
				} else {
					this.menu.closeMenu();
				}
			});
		}
	}

	public ngAfterViewInit() {
		this.menuOpenedSubscription = this.menu.menuOpened.subscribe(() => {
			(this.menu as any)._overlayRef.hostElement.classList.add('z-index-990');
		});
	}

	ngOnDestroy() {
		this.menuOpenedSubscription.unsubscribe();
	}

	// Show panel View
	public toggleLayersPanel(e): void {
		e.stopPropagation();
		this.onViewShow.emit(!this.isViewShow);
		this.hideAllPanels(2);
	}

	public hideAllPanels(panelType: number): void {
		if (panelType !== 2 && this.isViewShow) {
			this.onViewShow.emit(false);
		}
		if (panelType !== 5 && this.isSensitivityPanelShow) {
			this.isSensitivityPanelShow = false;
		}
		if (panelType !== 6 && this.isDisplayAreaShow) {
			this.isDisplayAreaShow = false;
		}
		if (panelType !== 7 && this.isCameraListShow) {
			this.isCameraListShow = false;
		}
	}

	public onMenuClosed(): void {
		this.hideAllPanels(-1);
	}

	public onMenuOpened(): void {
		if (this.menuOpenedOnInit) {
			this.onViewShow.emit(true);
		}
		this.menuOpenedOnInit = true;
	}

	public onToggleLayer(layer: Layer): void {
		this.toggleLayer.emit(layer);
	}

	public onClickSensorBtn(e): void {
		e.stopPropagation();
		this.hideAllPanels(-1);
		this.sensor.emit();
	}

	public onClickFlipBtn(e): void {
		e.stopPropagation();
		this.hideAllPanels(-1);
		this.flip.emit();
	}

	public onClickRotateBtn(e): void {
		e.stopPropagation();
		this.hideAllPanels(-1);
		this.rotate.emit();
	}

	public onClickSensitivity(e): void {
		e.stopPropagation();
		this.hideAllPanels(5);
		this.isSensitivityPanelShow = !this.isSensitivityPanelShow;
	}

	public onClickCamera(e) {
		e.stopPropagation();
		this.hideAllPanels(5);
		this.isCameraListShow = !this.isCameraListShow;
		if (this.isCameraListShow) {
			setTimeout(() => {
				let bbox = this.buttonWrap.nativeElement.getBoundingClientRect();
				this.cameraPanelTop = bbox.bottom - bbox.top - this.cameraPanel.nativeElement.clientHeight;
			});
		}
	}

	public onClickRulerBtn(e): void {
		e.stopPropagation();
		this.hideAllPanels(-1);
		this.ruler.emit();
	}

	public sensitivityUpdateVal(e): void {
		this.sensitivityLabelPosition = (this.sensitivityValueSelect - this.sensitivityMin) * 100 / (this.sensitivityMax - this.sensitivityMin);
	}

	public sensitivityChangeVal(sensitivityValue): void {
		this.sensitivityValueSelect = sensitivityValue;
		this.sensitivityValue.emit(sensitivityValue);
	}

	public onReset3dViewClick(e): void {
		this.reset3d.emit(e);
	}

	public onToggleCamera(camera: MediaDeviceInfo): void {
		if (this.cameraValue === camera.deviceId) {
			return;
		}
		this.toggleCamera.emit(camera.deviceId);
	}
}
