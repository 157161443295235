import {ChangeDetectorRef, Component, Injector, Input, OnInit, EventEmitter, Output, ViewChild} from '@angular/core';
import {ToolbarService} from '../../../../../services/toolbar.service';
import {BusEventService} from '../../../../../services/bus-event.service';
import {Connection} from '../../../../../services/system/connection';
import {RetailGeoFence, RetailGraph} from '../../../../../models/models';
import {FloorPlanLayerComponent} from '../../layers/floor-plan/floor-plan.component';

/**
 * Component represents Smart Retail base component in Smart Buildings application.
 */
@Component({
	selector: 'app-analytics-graph-base',
	templateUrl: './analyticsGraph.base.component.html',
	styleUrls: ['./analyticsGraph.base.component.scss']
})
export class AnalyticsGraphBaseComponent implements OnInit {

	@Input() graphs: Array<RetailGraph> = [];
	@Input() data: any = {};
	@Input() sensorSocketSettings;
	@Input() connection: Connection;
	@Input() geoFences: Array<RetailGeoFence> = [];
	@Output() toggleWidget = new EventEmitter();
	@ViewChild('conversionComponent', {static: false}) conversionComponent: FloorPlanLayerComponent;
	@ViewChild('heatmapComponent', {static: false}) heatmapComponent: FloorPlanLayerComponent;
	activeFloorPlan: FloorPlanLayerComponent | null;
	isLive = false;
	toolbarService: ToolbarService;
	selectedGraph: RetailGraph;
	xValues: any = [];
	yValues = [0, 20, 40, 60, 80, 100];
	colors = {
		'Geo-fenced': '#e19128',
		'Missed Opportunities': '#ffc233',
		'Conversion': '#126fad',
		'Average Time': '#126fad',
		'Total': '#fab753'
	};
	rippleColor = getComputedStyle(document.documentElement).getPropertyValue('--button-ripple-color');

	busEventService: BusEventService;

	protected ref: ChangeDetectorRef;

	constructor(protected injector: Injector) {
		this.toolbarService = this.injector.get(ToolbarService);
		this.ref = this.injector.get(ChangeDetectorRef);
		this.busEventService = this.injector.get(BusEventService);
	}

	async ngOnInit() {
		this.toolbarService.isAnalyticsFormShown = true;
		this.selectedGraph = this.graphs[0];
	}

	selectWidget(graph: RetailGraph) {
		this.selectedGraph = graph;
		this.toolbarService.analyticsSelectedGraph = this.selectedGraph;
		this.toggleWidget.emit(graph);
		setTimeout(() => {
			if (graph.type === 'conversion') {
				this.activeFloorPlan = this.conversionComponent;
			} else if (graph.type === 'heatmap') {
				this.activeFloorPlan = this.heatmapComponent;
			} else {
				this.activeFloorPlan = null;
			}
		});
	}

	toggleLiveMode() {
		this.isLive = !this.isLive;
		this.isLive ? this.busEventService.run.emit(this.connection.url) : this.busEventService.stop.emit(this.connection.url);
	}

	onRendered() {
		setTimeout(() => {
			this.ref.detectChanges();
		});
	}

	trackByPage(index, graph: RetailGraph) {
		return graph.page;
	}

	typeOf(value) {
		return typeof value;
	}
}
