import {PointCloudStageBase} from '../../../../../base-classes/PointCloudStageBase';
import * as THREE from 'three';

/**
 * Renders smart tailor model.
 */
export class SmartTailorStage extends PointCloudStageBase {
	private pointScale = 4.5;
	private bottomPadding = 0.05;

	constructor(div, arena, status) {
		super(div, arena, status, false);
		this.arenaPlanes.forEach(p => this.mainGroup.remove(p));
		this.mainGroup.remove(this.sensor);
		this.pointCloudMat!.size = 4;
	}

	/**
	 * Updates Point Cloud points and their shadows
	 */
	updatePoints(data) {
		let positions: Array<any> = [];
		let colors: Array<any> = [];
		let numOfVerticesToDisplay = data.length;
		let min = Math.min(...data.map(p => p[1]));
		for (let i = 0; i < data.length; ++i) {
			let point = data[i];
			let color = this.getColor(this.isRaw, point[3]);
			let pointPosition = this.getPointPosition([-point[0] * this.pointScale, point[2] * this.pointScale, (point[1] - min + this.bottomPadding) * this.pointScale]);
			positions.push(pointPosition.x, pointPosition.y, pointPosition.z);
			colors.push(color.r, color.g, color.b);
		}

		(<THREE.BufferAttribute>this.pointCloudGeo!.attributes.position).set(positions);
		(<THREE.BufferAttribute>this.pointCloudGeo!.attributes.position).needsUpdate = true;
		(<THREE.BufferAttribute>this.pointCloudGeo!.attributes.color).set(colors);
		(<THREE.BufferAttribute>this.pointCloudGeo!.attributes.color).needsUpdate = true;
		this.pointCloudGeo!.setDrawRange(0, numOfVerticesToDisplay);
	}

	initCamera() {
		this.camera.position.set(0, 350, 550);
		this.camera.lookAt(0, 0, 0);
	}

	setControlsTarget() {
		this.controls.target = new THREE.Vector3(0, 250, 0);
	}
}
