import {Socket} from './socket';
import {EventEmitter} from '@angular/core';

/**
 * Class for working with websocket using specified commands for home automation.
 */
export class HomeAutomationServerSocket extends Socket {

	devicesLocations = new EventEmitter;
	rules = new EventEmitter;
	devicesStatus = new EventEmitter;

	getDevices() {
		this.sendCommand('GET_DEVICES');
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['GET_DEVICES']) {
				this.promiseCallbacks['GET_DEVICES'] = [];
			}
			this.promiseCallbacks['GET_DEVICES'].push({resolve: resolve, reject: reject});
		});
	}

	setZones(zones) {
		this.sendCommand('SET_ZONES', zones);
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['SET_ZONES']) {
				this.promiseCallbacks['SET_ZONES'] = [];
			}
			this.promiseCallbacks['SET_ZONES'].push({resolve: resolve, reject: reject});
		});
	}

	setDevidesLocation(data) {
		this.sendCommand('SET_DEVICES_LOCATION', data);
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['SET_DEVICES_LOCATION']) {
				this.promiseCallbacks['SET_DEVICES_LOCATION'] = [];
			}
			this.promiseCallbacks['SET_DEVICES_LOCATION'].push({resolve: resolve, reject: reject});
		});
	}

	controlDevice(data) {
		this.sendCommand('CONTROL_DEVICE', data);
	}

	setRules(rules) {
		this.sendCommand('SET_RULES', rules);
		return new Promise((resolve, reject) => {
			if (!this.promiseCallbacks['SET_RULES']) {
				this.promiseCallbacks['SET_RULES'] = [];
			}
			this.promiseCallbacks['SET_RULES'].push({resolve: resolve, reject: reject});
		});
	}

	setRoomsState(state) {
		return this.sendCommand('SET_ROOMS_STATE', state);
	}

	isRunned() {
		return this.isConnected();
	}

	protected onMessage(json) {
		switch (json.ID) {
			case 'SET_DEVICES_LOCATION':
				if (!(this.promiseCallbacks[json.ID] && this.promiseCallbacks[json.ID].length)) {
					this.devicesLocations.emit(json.Payload);
				}
				break;
			case 'SET_RULES':
				if (!(this.promiseCallbacks[json.ID] && this.promiseCallbacks[json.ID].length)) {
					this.rules.emit(json.Payload);
				}
				break;
			case 'GET_DEVICES':
				if (!(this.promiseCallbacks[json.ID] && this.promiseCallbacks[json.ID].length)) {
					this.devicesStatus.emit(json.Payload);
				}
				break;
		}
		super.onMessage(json);
	}
}
