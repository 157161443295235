import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

/**
 * Directive allows to increase or decrease value using keyboard arrow down or arrow up.
 */
@Directive({
  selector: '[appQuickNumber]'
})
export class QuickNumberDirective {

  @Input() formControl: FormControl;
  @Input() min: number;
  @Input() max: number;
  @Input() step = 0.1;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeydown(e) {
    let shouldBeProcessed = false,
        step = 0,
        newValue;
    switch(e.key) {
      case 'ArrowDown':
        step = this.step * -1;
        newValue = Math.round((+this.el.nativeElement.value + step) * 100) / 100;
        shouldBeProcessed = typeof this.min === 'number' ? newValue >= this.min : true;
        break;
      case 'ArrowUp':
        step = this.step;
        newValue = Math.round((+this.el.nativeElement.value + step) * 100) / 100;
        shouldBeProcessed = typeof this.max === 'number' ? newValue <= this.max : true;
        break;
    }
    if(shouldBeProcessed) {
      let value = Math.round((+this.el.nativeElement.value + step) * 100) / 100;
      this.el.nativeElement.value = value;
      const event = new KeyboardEvent("keyup",{
          "key": "Enter"
      });
      this.el.nativeElement.dispatchEvent(event);
      if(this.formControl) {
        this.formControl.setValue(value);
      }
    }
  }

}
