<div>
	<div class="buttons">
		<div *ngIf="showSbrOrCpd">
			<span [matTooltip]="'SEAT_BELT_INDICATION' | translate">SBR</span>
			<app-slide-toggle [ngModel]="sbrOrCpd === 'cpd'" (change)="updateMode()" [disabled]="!enableSbrOrCpd"></app-slide-toggle>
			<span [matTooltip]="'CHILD_PROTECTION_DETECTION' | translate" class="slide-cpd">CPD</span>
		</div>
		<ng-content></ng-content>
	</div>
	<div class="selector-wrapper" perfectScrollbar>
		<div *ngIf="showIgnitionSwitch" class="ignition">
			<h3>{{'IGNITION' | translate}}</h3>
			<app-slide-toggle class="app-slide-toggle" [(ngModel)]="ignitionSwitch" (change)="updateIgnition()"
							  [disabled]="(drivingSpeed > 0) || !enableIgnitionSwitch">
			</app-slide-toggle>
		</div>
		<div *ngIf="showDrivingSpeed" class="driving-speed">
			<h3>{{'DRIVING_SPEED' | translate}}</h3>
			<mat-slider [min]="0" [max]="100" [step]="25" [(ngModel)]="drivingSpeed" (change)="updateDrivingSpeed()"
						[disabled]="!Boolean(ignitionSwitch) || !enableDrivingSpeed"></mat-slider>
			<div class="labels">
				<div>0</div><div>25</div><div>50</div><div>75</div><div>100</div><span class="label" style="right: -60px;">km/h</span>
			</div><br><br>
			<div class="labels">
				<div>0</div><div>15</div><div>31</div><div>46</div><div>62</div><span class="label" style="right: -57px;">mph</span>
			</div>
		</div>
		<div class="doors-opened" *ngIf="showDoorsOpen && doorsGrid.length">
			<h3>{{'DOORS_OPENED' | translate}}</h3>
			<div class="selector" [class.disabled]="!enableDoorsOpen">
				<div *ngFor="let row of doorsGrid">
					<div *ngFor="let cellIndex of row">
						<span class="number">{{cellIndex}}</span>
						<app-slide-toggle class="app-slide-toggle" [ngModel]="Boolean(this['doorOpen' + cellIndex])"
										  (change)="updatedoorsOpen(cellIndex)" [disabled]="!enableDoorsOpen">
						</app-slide-toggle>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<button class="g-btn close-all-doors" (click)="closeAllDoors()" [disabled]="!enableDoorsOpen">
					{{'CLOSE_ALL_DOORS' | translate}}
				</button>
			</div>
		</div>
		<div *ngIf="showSeatBeltsBuckled && seatBeltsBuckledGrid.length">
			<h3>{{'SEAT_BELTS_BUCKLED' | translate}}</h3>
			<div class="selector" [class.disabled]="!enableSeatBeltsBuckled">
				<div *ngFor="let row of seatBeltsBuckledGrid">
					<div *ngFor="let cellIndex of row">
						<ng-container *ngIf="cellIndex !== null">
							<span class="number">{{cellIndex}}</span>
							<app-slide-toggle class="app-slide-toggle" [ngModel]="Boolean(this['seatBeltBuckled' + cellIndex])"
											  (change)="updateseatBeltsBuckled(cellIndex)" [disabled]="!enableSeatBeltsBuckled">
							</app-slide-toggle>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="scene">
	<div class="alert" [ngStyle]="{visibility: localData[messageHeaderDataKeyName]?.length && localData[messageContentDataKeyName]?.length ? 'visible' : 'hidden'}"
		 [style.--message-bounding-color]="localData[messageBoundingColorKeyName]"
		 [style.--message-bounding-animation-frequency]="localData[messageBoundingFrequencyKeyName]">
		<h3 [ngStyle]="{
				color: localData[messageHeaderColorKeyName],
				backgroundColor: localData[messageHeaderBackgroundColorKeyName]
			}">{{localData[messageHeaderDataKeyName]}}</h3>
		<div>{{localData[messageContentDataKeyName]}}</div>
	</div>
	<div class="scene-body">
		<app-svg-car
			[rotate]="rotate"
			[isTargetsSelectable]="isTargetsSelectable"
			[localData]="localData"
			[isSensorVisible]="isSensorVisible && sbrOrCpd !== 'cpd'"
			[isMultipleSensors]="isMultipleSensors"
			[isTargetsVisible]="sbrOrCpd !== 'cpd'"
			[class.car-disabled]="sbrOrCpd == 'cpd'"
			[isTrackerEnabled]="isTrackerEnabled"
			[sensorColor]="sensorColor"
			[parameters]="parameters"
			[selectedModelIds]="selectedModelIds"
			[connectedSensorsSettings]="connectedSensorsSettings">
		</app-svg-car>
	</div>
</div>
