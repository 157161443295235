import {Component, Input, OnInit} from '@angular/core';
import {BusEventService} from '../../../services/bus-event.service';

export type ProgressBarType = 'circle' | 'indeterminate' | 'determinate';

/**
 * Component represents a block with loading animation (red, blue, gray semicircle) inside.
 */
@Component({
	selector: 'app-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

	@Input() withDelay = true;
	@Input() size = 68;
	@Input() type: ProgressBarType = 'circle';
	@Input() title = 'LOADING';
	@Input() showStatus = false;

	constructor(public busEventService: BusEventService) {
	}

	ngOnInit() {
	}

}
