export const Components: any[] = [
	{
		layerName: 'HeatmapContour',
		componentName: 'SensorTracker2DRawViewComponent',
		alternative: ['HeatmapCanvas'],
		sensor: true,
		flip: true,
		rotate: true,
		ruler: true,
		sensitivity: true,
		axesEnabled: false
	},
	{
		layerName: 'HeatmapContourWand',
		componentName: 'PointCloudRaw2dComponentWand',
		sensor: false,
		flip: false,
		rotate: false,
		ruler: false,
		sensitivity: false,
		axesEnabled: false
	}, {
		layerName: 'PoseLandmark2d',
		componentName: 'PoseLandmark2dComponent',
		sensor: false,
		flip: false,
		rotate: false,
		ruler: false,
		sensitivity: false,
		axesEnabled: false
	}, {
		layerName: 'TargetsInfo',
		componentName: 'TargetsInfoComponent',
		sensor: false,
		flip: false,
		rotate: false,
		ruler: false,
		sensitivity: false,
		axesEnabled: false
	},
	{
		layerName: 'HeatmapCanvas',
		componentName: 'SensorTracker2DRawViewComponent',
		alternative: ['HeatmapContour', 'HeatmapContourWand'],
		isRaw: true,
		sensor: true,
		flip: true,
		rotate: true,
		ruler: true,
		sensitivity: true,
		axesEnabled: false
	},
	{
		layerName: 'Targets',
		componentName: 'SensorTracker2DRawViewComponent',
		sensor: true,
		flip: true,
		rotate: true,
		ruler: true,
	},
	{
		layerName: 'MultipleRooms',
		componentName: 'FloorPlanLayerComponent',
		sensor: true,
		flip: true,
		rotate: true
	},
	{
		layerName: 'SmartHomeDashboard',
		componentName: 'SmartHomeDashboardComponent',
		'dataDict': {},
		'staticDataDict': {}
	},
	{
		layerName: 'SmartHomeAc',
		componentName: 'SmartHomeAcComponent',
		'dataDict': {},
		'staticDataDict': {}
	},
	{
		layerName: 'Number',
		componentName: 'NumberOfPeopleComponent'
	},
	{
		layerName: 'InCar',
		componentName: 'InCarOccupancyComponent',
		sensor: true,
		tracker: false,
		alternative: ['InCarTracker']
	},
	{
		layerName: 'InCarTracker',
		componentName: 'InCarOccupancyComponent',
		sensor: true,
		tracker: true,
		alternative: ['InCar']
	},
	{
		layerName: 'LegacyInCar',
		componentName: 'InCarOccupancyBaseComponent',
		sensor: true
	},
	{
		layerName: 'BreathingGraphs',
		componentName: 'BreathingComponent'
	},
	{
		layerName: 'ActivityGraphs',
		componentName: 'ActivityComponent'
	},
	{
		layerName: 'CovidGraphs',
		componentName: 'HeartRateGraphComponent'
	},
	{
		layerName: 'PointCloud3D',
		componentName: 'PointCloud3dComponent',
		sensor: true,
		reset3d: true,
		alternative: ['PointCloud3DRaw', 'SmartTailorPointCloud']
	},
	{
		layerName: 'PointCloud3DRaw',
		componentName: 'PointCloud3dComponent',
		sensor: true,
		reset3d: true,
		isRaw: true,
		alternative: ['PointCloud3D', 'SmartTailorPointCloud']
	},
	{
		layerName: 'PointCloud3D_InCar_Car',
		componentName: 'CarPointCloudComponent',
		sensor: true,
		reset3d: true,
		alternative: ['PointCloud3DRaw_InCar_Car']
	},
	{
		layerName: 'PointCloud3DRaw_InCar_Car',
		componentName: 'CarPointCloudComponent',
		sensor: true,
		reset3d: true,
		isRaw: true,
		alternative: ['PointCloud3D_InCar_Car']
	},
	{
		layerName: 'SrrPointCloud3D',
		componentName: 'SrrCloudPointComponent',
		sensor: true,
		reset3d: true,
		alternative: ['SrrPointCloud3DRaw']
	},
	{
		layerName: 'SrrPointCloud3DRaw',
		componentName: 'SrrCloudPointComponent',
		sensor: true,
		reset3d: true,
		isRaw: true,
		alternative: ['SrrPointCloud3D']
	},
	{
		layerName: 'PointCloud3D_InCar_Boxes',
		componentName: 'BoxesPointCloudComponent',
		sensor: true,
		reset3d: true,
		alternative: ['PointCloud3DRaw_InCar_Boxes']
	},
	{
		layerName: 'PointCloud3DRaw_InCar_Boxes',
		componentName: 'BoxesPointCloudComponent',
		sensor: true,
		reset3d: true,
		isRaw: true,
		alternative: ['PointCloud3D_InCar_Boxes']
	},
	{
		layerName: 'ShopperInsights',
		componentName: 'ShopperInsightsComponent'
	},
	{
		layerName: 'Drawing',
		componentName: 'SensorTracker2DRawViewComponent',
		flip: true,
		rotate: true,
		sensor: true,
		ruler: true
	},
	{
		layerName: 'Targets3D',
		componentName: 'Targets3DComponent',
		sensor: true,
		reset3d: true
	},
	{
		layerName: 'PointCloud2D',
		componentName: 'PointCloud2dComponent',
		flip: true,
		rotate: true,
		sensor: true,
		ruler: true
	},
	{
		layerName: 'CoolerInventory',
		componentName: 'CoolerInventoryComponent',
		alternative: ['CoolerTopView'],
		reset3d: true
	},
	{
		layerName: 'CoolerTopView',
		componentName: 'CoolerInventoryComponent',
		alternative: ['CoolerInventory']
	},
	{
		layerName: 'SmartTailorMeasurements',
		componentName: 'SmartTailorMeasurementsComponent',
		reset3d: true
	},
	{
		layerName: 'SmartTailorPointCloud',
		componentName: 'PointCloud3dComponent',
		alternative: ['PointCloud3DRaw', 'PointCloud3D'],
		isSmartTailor: true,
		reset3d: true
	},
	{
		layerName: 'SmartTailorSilhouette',
		componentName: 'SmartTailorSilhouetteComponent'
	},
	{
		layerName: 'ScanPerson',
		componentName: 'SmartTailorLauncherComponent'
	},
	{
		layerName: 'RoomOccupancy',
		componentName: 'RoomOccupancyComponent'
	},
	{
		layerName: 'AppInfo',
		componentName: 'AppInfoComponent'
	},
	{
		layerName: 'SmartRetail',
		componentName: 'SmartRetailComponent'
	},
	{
		layerName: 'GeoFences',
		componentName: 'GeoFencesComponent'
	},
	{
		layerName: 'RetailStats',
		componentName: 'RetailStatsComponent'
	},
	{
		layerName: 'WebCameraVideo',
		componentName: 'WebCameraVideoComponent',
		camera: true
	},
];
