import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SensorSocket } from 'src/app/services/system/sensor-socket';

/**
 * Component receives HTML from sensor and renders it.
 */
@Component({
	selector: 'app-app-info',
	templateUrl: './app-info.component.html',
	styleUrls: ['./app-info.component.scss']
})
export class AppInfoComponent implements OnInit {

	@Input() layerName: any = '';
	@Input() data: any = '';
	@Input() sensorSocket: SensorSocket;

	constructor(private _sanitizer: DomSanitizer) {
	}

	ngOnInit() {
	}

	prepareHTML(html) {
		return this._sanitizer.bypassSecurityTrustHtml(html);
	}
}
