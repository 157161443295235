<div class="scene">
	<div class="alert" [ngStyle]="{visibility: localData[messageHeaderDataKeyName]?.length && localData[messageContentDataKeyName]?.length ? 'visible' : 'hidden'}">
		<h3>{{localData[messageHeaderDataKeyName]}}</h3>
		<div>{{localData[messageContentDataKeyName]}}</div>
	</div>
	<div class="scene-body">
		<app-svg-car [rotate]="rotate" [isTargetsSelectable]="isTargetsSelectable" [localData]="localData"
		[isSensorVisible]="isSensorVisible" [isMultipleSensors]="isMultipleSensors"
		[sensorColor]="sensorColor" [parameters]="parameters" [selectedModelIds]="selectedModelIds"
		[connectedSensorsSettings]="connectedSensorsSettings"></app-svg-car>
	</div>
</div>
