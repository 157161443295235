/**
 * Helper class for scene rendering.
 */


/**
 * Point = (x, y, z, intensity)
 * Given the intensity from Matlab, set each point's intensity
 * relative to the min and max of the intensities
 *
 * This will be represented in color (from blue to red)
 *
 * */

export function scalePoints(data) {
	let max = Math.max(...data.map(point => point[3]));
	let min = Math.min(...data.map(point => point[3]));
	let range = max === min ? 1 : max - min;
	for (let i = 0; i < data.length; ++i) {
		let point = data[i];
		data[i][3] = (point[3] - min) / range;
	}
}

export function getMaxOccOfEl(array) {
	let numOfOccForEl = {};
	let maxCount = 1;

	for (let i = 0; i < array.length; i++) {
		let el = array[i];
		numOfOccForEl[el] = numOfOccForEl[el] == null ? 1 : numOfOccForEl[el] + 1;
		if (numOfOccForEl[el] > maxCount) {
			maxCount = numOfOccForEl[el];
		}
	}
	return maxCount;
}

export function shuffleArray(a) {
	let array = [].concat(...a);
	for (let i = array.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * (i + 1));
		let temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
}
