<div class="sidebar">
	<p>1. Upload {{control.label | lowercase}}.</p><br>
	<button mat-raised-button (click)="backgroundImageFileInput.click()"
			class="g-btn"
			[disabled]="false">
		{{ 'CHOOSE_FILE' | translate}}
	</button>
	<input #backgroundImageFileInput type="file" accept="image/*" (change)="onSelectFile($event)"><br><br>
	<p>2. Choose an area where you know the length of one of the sides. Then click each end of that side.</p><br>
	<button mat-raised-button (click)="startRoomDimension()"
			class="g-btn"
			[disabled]="false">
		{{ 'SCALE_ROOM' | translate}}
	</button><br><br>
	<p>3. Determine an origin by dragging the red icon to the desired position.</p>

	<button mat-raised-button class="g-btn" style="margin-top: auto;" (click)="deleteFloorPlan()"
	[disabled]="!this.settingForm.controls.backgroundImage.value">
		{{'DELETE_FLOOR_PLAN' | translate}}
	</button>
</div>
<div #map class=""></div>
<div *ngIf="showModalDistanceDialog" class="distance-block">
	<div class="modal-distance">
		<div style="position:relative;">
			<h3>Enter Side Length</h3>
			<span class="material-icons close" (click)="closeScale()">close</span>
			<div class="message ps">
				<div class="input ng-star-inserted">
					<input type="number" autocomplete="off" name="input" placeholder="in meters" [(ngModel)]="scale" (keydown.enter)="onKeyDown($event)">
				</div>
				<p>This data will enable calculation of the floor dimensions and all areas within it.</p>
			</div>
			<div class="default-panel">
				<button mat-raised-button class="g-btn" (click)="scaleRoom()" [disabled]="!scale">
					OK
				</button>
			</div>
		</div>
	</div>
</div>
<div *ngIf="showErrorDialog" class="distance-block">
	<div class="modal-distance">
		<div style="position:relative;">
			<h3>Error</h3>
			<div class="message ps">
				<p>{{errorMessage | translate}}</p>
			</div>
			<div class="default-panel">
				<button mat-raised-button class="g-btn" (click)="closeError()">
					OK
				</button>
			</div>
		</div>
	</div>
</div>
