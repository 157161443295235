import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {isSettingInReadOnly} from '../../utils/utils';
import {environment} from '../../../environments/environment';
import {Targets3DComponent} from '../../applications/flow-processor/components/layers/targets-3d/targets-3d.component';
import {MatMenuTrigger} from '@angular/material/menu';
import {PointCloud3dComponent} from '../../applications/flow-processor/components/layers/point-cloud-3d/point-cloud-3d.component';
import {CarPointCloudComponent} from '../../applications/flow-processor/components/layers/car-point-cloud/car-point-cloud.component';
import {CoolerInventoryComponent} from '../../applications/smart-cooler/components/layers/cooler-inventory/cooler-inventory.component';
import {SmartTailorMeasurementsComponent} from '../../applications/smart-tailor/components/layers/smart-tailor-measurements/smart-tailor-measurements.component';
import {BoxesPointCloudComponent} from '../../applications/flow-processor/components/layers/boxes-point-cloud/boxes-point-cloud.component';
import {LayerControlsComponent} from '../layer-controls/layer-controls.component';
import {Connection, ConnectionStatus} from '../../services/system/connection';
import {SensorsService} from '../../services/system/sensors.service';
import {
	ConfigurableLayerControlType,
	ConfigurableTabControlType,
	ConfigurationService, IConfigurableControlOption,
	IConfigurableLayerControl, IConfigurableTabControl,
	Layer
} from '../../services/configuration.service';
import {SensorSocket} from '../../services/system/sensor-socket';
import {RetailGeoFence} from '../../models/models';
import {TabbedFormControlComponent} from '../../applications/flow-processor/components/tabbed-form-control/tabbed-form-control.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';

/**
 * Container for layers. Placed in BaseViewComponent. BaseViewComponent can contain up to 2 LayersContainerComponent (left - right).
 */
@Component({
	selector: 'app-layers-container',
	templateUrl: './layers-container-component-base.component.html',
	styleUrls: ['./layers-container-component-base.component.scss']
})
export class LayersContainerComponentBase implements OnChanges, AfterViewInit {

	@Input() layers: Array<Layer>;
	@Input() viewIndex;
	@Input() sensorSocketSettings;
	@Input() connectedSensorsSettings;
	@Input() connection: Connection;
	@Input() data: any;
	@Input() geoFences: Array<RetailGeoFence>;
	@Input() combinedData: any;
	@Input() duplicateView = false;
	@Input() rotateValue;
	@Input() reflect;
	@Input() postureValue;
	@Input() rulerValue;
	@Input() sensorValue;
	@Input() cameraValue;
	@Input() showCloseBtn = false;
	@Input() isMultipleSensors;

	@Input() StandingHuman3DModel;
	@Input() SittingHuman3DModel;
	@Input() LyingHuman3DModel;
	@Input() WalkingHuman3DModel;

	@Input() Bottle3DModel;
	@Input() Fridge3DModel;

	@Input() targetCounter = 0;
	@Input() sensitivityValueSelect;
	@Input() selectedModelIds;
	@Input() showMenu = true;
	@Input() subToolbarIsAnimated = false;

	@Output() toggleLayer = new EventEmitter;
	@Output() toggleCamera = new EventEmitter;
	@Output() toggleDuplicate = new EventEmitter;
	@Output() ruler = new EventEmitter;
	@Output() sensor = new EventEmitter;
	@Output() rotate = new EventEmitter;
	@Output() flip = new EventEmitter;
	@Output() posture = new EventEmitter;
	@Output() sensitivityValue = new EventEmitter;

	@ViewChild(Targets3DComponent) tracker3D: Targets3DComponent;
	@ViewChild(CoolerInventoryComponent) cooler: CoolerInventoryComponent;
	@ViewChild(PointCloud3dComponent) pointCloud: PointCloud3dComponent;
	@ViewChild(CarPointCloudComponent) pointCloudCar: CarPointCloudComponent;
	@ViewChild(BoxesPointCloudComponent) PointCloudBoxes: BoxesPointCloudComponent;
	@ViewChild(SmartTailorMeasurementsComponent) smartTailor: SmartTailorMeasurementsComponent;
	@ViewChild(LayerControlsComponent) viewControlsComponent: LayerControlsComponent;

	layersByComponent: { [componentName: string]: any } = {};
	layersByCategory: Array<Array<Layer>> = [];

	environment = environment;
	ConnectionStatus = ConnectionStatus;
	ConfigurationLayerControlType = ConfigurableLayerControlType;

	isViewShow = false;
	isRulerEnable = false;
	isSensitivityEnable = false;
	isCameraListEnable = false;
	isSensorEnable = false;
	isFlipEnable = false;
	isRotateEnable = false;
	isReset3dEnable = false;
	connect = [true, false];

	bottlesRowsColumnsLevel = [0, 0];

	public menuOpened: Promise<any>;

	localData = {};
	localCombinedData = {};

	defaultLayerControlIcon = 'info';
	cameraList: Array<MediaDeviceInfo> = [];

	selectedControllerIds: Array<{control: IConfigurableLayerControl, id: string}> = [];

	private menu: MatMenuTrigger;
	private menuOpenedResolve;
	private allLayerDropdownsControls: Array<{control: IConfigurableLayerControl, select: MatSelect}> = [];

	constructor(protected sensorsService: SensorsService,
				protected dialog: MatDialog,
				protected configurationService: ConfigurationService) {
		this.menuOpened = new Promise((resolve, reject) => {
			this.menuOpenedResolve = resolve;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('sensorSocketSettings' in changes && this.sensorSocketSettings) {
			if (this.sensorSocketSettings['sensorParameters'] && 'ProcessorCfg.ExternalGUI.Layers' in this.sensorSocketSettings['sensorParameters'] && this.sensorSocketSettings['sensorParameters']['ProcessorCfg.ExternalGUI.InventoryDemo']) {
				let layers = this.sensorSocketSettings['sensorParameters']['ProcessorCfg.ExternalGUI.Layers'],
					CoolerInventory = layers.find(l => {
						return l.componentName === 'CoolerInventory';
					});

				if (CoolerInventory) {
					this.bottlesRowsColumnsLevel = CoolerInventory.staticDataDict.bottlesRowsColumnsLevel;
				}
			}
		}
		if ('layers' in changes) {
			this.layersByCategory = [];
			let categories = Array.from(new Set(this.layers.map(l => {
				return l.staticDataDict && l.staticDataDict.category || '';
			})));
			categories.forEach(c => {
				this.layersByCategory.push(this.layers.filter(l => {
					return (l.staticDataDict && l.staticDataDict.category === c) || (!c.length && !('category' in l.staticDataDict!));
				}));
			});
			this.layers.forEach(l => {
				let component = this.layers.find(l2 => l.componentName === l2.componentName && l2.selected);
				let withAxes = this.layers.find(l2 => l.componentName === l2.componentName && l2.selected && l2.axes);
				this.layersByComponent[l.componentName] = {
					name: component && component.name || '',
					displayName: component && component.displayName || '',
					isActive: this.layers.filter(l2 => l.componentName === l2.componentName).some(l2 => l2.selected),
					isRaw: this.layers.some(l2 => l.componentName === l2.componentName && l2.selected && l2.isRaw),
					tracker: this.layers.some(l2 => l.componentName === l2.componentName && l2.selected && l2.tracker),
					isSmartTailor: this.layers.some(l2 => l.componentName === l2.componentName && l2.selected && l2.isSmartTailor),
					isXAxisInverted: this.layers.some(l2 => l.componentName === l2.componentName && l2.selected && l2.isXAxisInverted),
					axes: withAxes && withAxes['axes'],
					axesEnabled: this.layers.filter(l2 => l.componentName === l2.componentName && l2.selected).every(l2 => typeof l2.axesEnabled === 'boolean' ? l2.axesEnabled : true),
					dataDict: {},
					controls: [],
					staticDataDict: {},
					metadata: {},
					cfgDict: {}
				};
				['staticDataDict', 'metadata', 'cfgDict', 'dataDict'].forEach(key => {
					this.layers.filter(l2 => l.componentName === l2.componentName && l2.selected).forEach(l2 => {
						if (l2[key]) {
							Object.keys(l2[key]).forEach(k => {
								let k2 = l2.name === 'Drawing' ? 'InCarCabinVertexs' : k;
								this.layersByComponent[l.componentName][key][k2] = l2[key][k];
							});
						}
					});
				});
				['controls'].forEach(key => {
					this.layers.filter(l2 => l.componentName === l2.componentName && l2.selected).forEach(l2 => {
						if (l2[key]) {
							this.layersByComponent[l.componentName][key] = l2[key];
						}
					});
				});
			});
			this.isSensorEnable = this.layers.some(l => l.sensor && l.selected);
			this.isFlipEnable = this.layers.some(l => l.flip && l.selected);
			this.isRotateEnable = this.layers.some(l => l.rotate && l.selected);
			this.isReset3dEnable = this.layers.some(l => l.reset3d && l.selected);
			this.isSensitivityEnable = this.layers.some(l => l.sensitivity && l.selected);
			this.isCameraListEnable = this.layers.some(l => l.camera && l.selected);
			this.isRulerEnable = this.layers.some(l => l.ruler && l.selected);
		}
		if ('data' in changes) {
			Object.keys(this.layersByComponent).forEach(componentName => {
				this.localData[componentName] = this.convertDataKeys(this.data, componentName);
			});
		}
		if ('combinedData' in changes) {
			Object.keys(this.layersByComponent).forEach(componentName => {
				this.localCombinedData[componentName] = this.convertDataKeys(this.combinedData, componentName);
			});
		}
	}

	ngAfterViewInit() {
		if (this.viewControlsComponent) {
			this.menu = this.viewControlsComponent.menu;
		}
		setTimeout(() => {
			if (this.menu && this.showMenu) {
				this.menu.openMenu();
				setTimeout(() => {
					this.menuOpenedResolve();
				});
			}
		});
	}

	onToggleDuplicate() {
		this.toggleDuplicate.emit();
	}

	isDisabled() {
		return !this.connection || (this.connection && [ConnectionStatus.INITIALIZING, ConnectionStatus.CALIBRATING, ConnectionStatus.SAVING_DATA, ConnectionStatus.RESETTING].includes(this.connection.connectionStatus));
	}

	isSettingInReadOnly() {
		return isSettingInReadOnly(this.connection);
	}

	reset3D(e) {
		e.stopPropagation();
		if (this.tracker3D) {
			this.tracker3D.pixDensity.initCamera(this.tracker3D.pixDensity.origArena);
			this.tracker3D.pixDensity.controls.update();
		} else if (this.pointCloud && this.pointCloud.pixDensity) {
			this.pointCloud.pixDensity.initCamera(this.pointCloud.pixDensity.origArena);
			this.pointCloud.pixDensity.controls.update();
		} else if (this.pointCloudCar && this.pointCloudCar.pixDensity) {
			this.pointCloudCar.pixDensity.initCamera();
			this.pointCloudCar.pixDensity.controls.update();
		} else if (this.PointCloudBoxes && this.PointCloudBoxes.pixDensity) {
			this.PointCloudBoxes.pixDensity.initCamera();
			this.PointCloudBoxes.pixDensity.controls.update();
		} else if (this.cooler && this.cooler.pixDensity) {
			this.cooler.pixDensity.initCamera();
			this.cooler.pixDensity.controls.update();
		} else if (this.smartTailor) {
			this.smartTailor.initCamera();
		}
	}

	toggleFlip(flip) {
		this.reflect = flip;
	}

	isComponentActive(componentName) {
		return this.layers.filter(l => l.componentName === componentName).some(l => l.selected);
	}

	onToggleLayer(layer: Layer) {
		this.toggleLayer.emit(layer);
	}

	onToggleCamera(deviceId: string) {
		this.toggleCamera.emit(deviceId);
	}

	closeViewPanel() {
		setTimeout(() => {
			this.isViewShow = false;
		});
	}

	onControlCheckboxClick(control: IConfigurableLayerControl) {
		if (this.connection instanceof SensorSocket) {
			this.sensorsService.sendSettings(this.connection, true, {
				[control.param]: !this.sensorSocketSettings['sensorParameters'][control.param]
			});
		}
	}

	onControlBkgImgClick() {
		this.dialog.open(TabbedFormControlComponent, {
			autoFocus: false,
			width: '1104px',
			disableClose: true,
			panelClass: 'modal-popup',
			minHeight: 632,
			data: {
				sensorSocket: this.connection,
				isFloorPlan: true,
				tabs: [{
					'enable': true,
					'label': 'Background image',
					'icon': 'material_developer_board',
					'template': {},
					'controls': [{
						'enable': true,
						'type': ConfigurableTabControlType.TAB_BACKGROUND_IMAGE,
						'label': 'Background image',
					}]
				}]
			}
		});
	}

	onUpdateDeviceList(event) {
		navigator.mediaDevices.enumerateDevices().then(devices => {
			let videoDevices = devices.filter(d => d.kind === 'videoinput');

			this.onToggleCamera(event);

			this.cameraList = videoDevices;
		});
	}

	selectLayerDropDown(option: IConfigurableControlOption | Array<IConfigurableControlOption>, control: IConfigurableLayerControl, sendToSocket = true) {
		if (option) {
			let newSelectedControllerIds = this.allLayerDropdownsControls.map(o => {
				return o.control.options?.filter(os => {
					return ('id' in os) && (o.select.value instanceof Array ? o.select.value.includes(os) : o.select.value === os) &&
						(!os.controllerId || this.allLayerDropdownsControls.find(ok => {
							return ok.control !== o.control && (ok.select.value instanceof Array ? ok.select.value.includes(os) : ok.select.value?.id === os.controllerId);
						}));
				}).map(os => ({control: o.control, id: os.id as string})) || [];
			}).flat();

			this.selectedControllerIds = newSelectedControllerIds;
			if (sendToSocket && this.connection instanceof SensorSocket) {
				this.sensorsService.sendSettings(this.connection, true, {
					[control.param]: option instanceof Array ? option.map(o => o.value) : option.value
				});
			}
			let updateDisableState = (o: IConfigurableControlOption) => {
				let worker = (id, disabled) => {
					let select = this.allLayerDropdownsControls.find(o => o.control.controllerId === id)?.select;
					if (select) {
						select.disabled = disabled;
					}
				};
				if (o.disabling) {
					o.disabling.forEach(id => {
						worker(id, true);
					});
				}
				if (o.enabling) {
					o.enabling.forEach(id => {
						worker(id, false);
					});
				}
			};
			setTimeout(() => {
				if (option instanceof Array ) {
					option.forEach(o => {
						updateDisableState(o);
					});
				} else {
					updateDisableState(option);
				}
			});
		}
	}

	filterSelectedControllerIds(option: IConfigurableControlOption, control: IConfigurableLayerControl) {
		return !option.controllerId || this.selectedControllerIds.filter(o => o.control !== control).map(o => o.id).includes(option.controllerId);
	}

	getDropdownControlValue(control: IConfigurableLayerControl, select) {
		let value = this.sensorSocketSettings['sensorParameters'][control.param];
		if (control.multiple && !(value instanceof Array)) {
			value = [value];
		}
		let option;
		if (value instanceof Array) {
			option = control.options?.filter(o => value.includes(o.value));
		} else {
			option = control.options?.find(o => o.value === value);
		}
		if (!this.allLayerDropdownsControls.find(o => o.control === control)) {
			select.value = option;
			this.allLayerDropdownsControls.push({control, select});
			this.selectLayerDropDown(option, control, false);
		}
		return option;
	}

	private convertDataKeys(data, componentName) {
		let newData = {},
			invertedDataDict = {};

		Object.keys(this.layersByComponent[componentName].dataDict).forEach(key => {
			invertedDataDict[this.layersByComponent[componentName].dataDict[key]] = key;
		});
		Object.keys(data).forEach(key => {
			if (invertedDataDict[key]) {
				newData[invertedDataDict[key]] = data[key];
			}
		});

		return newData;
	}
}
