import {Component, Injector} from '@angular/core';
import {BaseLineGraphComponent} from '../../../../../../components/layers/base-classes/base-line-graph.component';

/**
 * Component renders graph.
 */
@Component({
	selector: 'app-vitals',
	templateUrl: './vitals.component.html',
	styleUrls: ['./vitals.component.scss']
})
export class VitalsComponent extends BaseLineGraphComponent {
	isZoomYaxisEnabled = true;
	isMeasurementEnabled = false;
	dataKeyName = 'BreathingMatrix';

	constructor(public injector: Injector) {
		super(injector);
	}
}
