import {EventEmitter, Injectable, Injector} from '@angular/core';
import {StorageService} from './storage.service';
import {SensorsService} from './system/sensors.service';
import {ModalService} from './modal.service';
import {RunMode} from './system/sensor-socket';
import {BehaviorSubject, Subject} from 'rxjs';
import {resetColorTables} from '../utils/ColorTablesUtils';

/**
 * Service for notifying subscribers of updates with data using EventEmitter.
 */
@Injectable({
	providedIn: 'root'
})
export class BusEventService {

	// Sensor events
	connect = new EventEmitter();
	disconnect: EventEmitter<string> = new EventEmitter();
	run: EventEmitter<string> = new EventEmitter();
	stop: EventEmitter<string> = new EventEmitter();
	outputsChanged = new EventEmitter();
	sensorRecordingSaved = new EventEmitter();
	cleanColorTable: EventEmitter<number> = new EventEmitter(); // The number is timeout

	// Targets events
	targetSelected: EventEmitter<string> = new EventEmitter();
	targetClearSelect = new EventEmitter();
	multipleRoomsParamsChanged = new EventEmitter();
	smartHomeParamsChanged = new EventEmitter();
	reloadPool = new EventEmitter();
	defaultPage = new EventEmitter();
	analyticsServerAddress = new EventEmitter();

	onClickLoadSettings = new EventEmitter();
	onSettingsFromFileLoaded = new EventEmitter();

	menuOpened: Promise<any>;
	menuOpenedResolver;

	showSubToolbar = new BehaviorSubject<any>({open: false, mode: 'edit'});

	restIsLoading = new BehaviorSubject(false);
	restLoadingPercentage = new BehaviorSubject(0);
	pageIsLoaded = new BehaviorSubject(false);
	restLoadingStatus = new BehaviorSubject('');
	smartBuildingPageIsReady = new EventEmitter();

	reloadGeoFences = new EventEmitter();
	reloadFloors = new EventEmitter();
	reloadFiltersValues = new EventEmitter();

	newGeoFence = new EventEmitter();
	uploadGeoFencesForNewFloor = new EventEmitter();
	defineFloorDimensions = new EventEmitter();
	newFloorDimensions = new Subject();
	floorUpdating: Subject<boolean> = new Subject();

	floorPlanLocationDrawClick = new Subject();
	floorPlanLocationDrawEnd = new Subject();

	constructor(private inject: Injector) {
		this.disconnect.subscribe(async () => {
			let storageService = this.inject.get(StorageService),
				t = await storageService.getItem('alert_on_disconnections'),
				alert_on_disconnections = t ? t.alert_on_disconnections : true,
				sensorsService = this.inject.get(SensorsService),
				modalService = this.inject.get(ModalService),
				inRecording = sensorsService.sensorSockets.value.some(socket => {
					return socket && socket.isConnected() && socket.recordingInfo && socket.recordingInfo.mode === RunMode.RECORD;
				}),
				isCovidDetection = sensorsService.sensorSockets.value.some(sensorSocket => sensorSocket.isConnected() && sensorSocket.isCovidDetection);

			if (isCovidDetection && alert_on_disconnections && inRecording) {
				modalService.checkAllSensorAreConnected();
			}
		});
		this.run.subscribe(() => {
			resetColorTables();
		});
		this.updateMenuOpenedPromise();
	}

	updateMenuOpenedPromise() {
		this.menuOpened = new Promise((resolve, reject) => {
			this.menuOpenedResolver = resolve;
		});
	}
}
