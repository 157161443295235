import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../modules/material/material.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SharedModule} from '../../modules/shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import {MODULE_PATH} from '../../app.module';
import {environment} from '../../../environments/environment';
import {LayersContainerComponent} from './components/layers/layers-container.component';
import {SmartTailorMeasurementsComponent} from './components/layers/smart-tailor-measurements/smart-tailor-measurements.component';
import {SmartTailorSilhouetteComponent} from './components/layers/smart-tailor-silhouette/smart-tailor-silhouette.component';
import {SmartTailorLauncherComponent} from './components/layers/smart-tailor-launcher/smart-tailor-launcher.component';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../../../assets/i18n/${lang}.json`));
	}
}

export let InjectorInstance: Injector;

const routes: Routes = [];

@NgModule({
	declarations: [
		LayersContainerComponent,
		SmartTailorMeasurementsComponent,
		SmartTailorSilhouetteComponent,
		SmartTailorLauncherComponent,
	],
	providers: [
		{
			provide: MODULE_PATH,
			useValue: environment.SMART_TAILOR_MODULE_PATH
		},
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		MaterialModule,
		PerfectScrollbarModule,
		SharedModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	]
})
export class SmartTailorModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}
