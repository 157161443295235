import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SettingsService, VALUE_TO_DELETE} from '../../../../services/settings.service';
import {SensorSocket} from '../../../../services/system/sensor-socket';
import {ModalService} from '../../../../services/modal.service';
import {getChangedParameters, makeDeepCopy, meterToFeet} from '../../../../utils/utils';
import {SensorsService} from '../../../../services/system/sensors.service';
import {TranslateService} from '@ngx-translate/core';
import {ArenaComponent} from './arena/arena.component';
import {environment} from '../../../../../environments/environment';
import {StorageService} from '../../../../services/storage.service';
import {Subscription} from 'rxjs';
import {
	ConfigurableTabControlType,
	ConfigurableTabTemplate,
	IConfigurableTab,
	IConfigurableTabControl
} from '../../../../services/configuration.service';
import {ConnectionStatus} from '../../../../services/system/connection';
import {SensorMountPlane} from '../../../../consts';
import {PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {MultiSensorsSocket} from '../../../../services/system/multi-sensors-socket';
import {BusEventService} from '../../../../services/bus-event.service';

declare var require: any;
var FileSaver = require('file-saver');


/**
 * Component represents modal window with settings. Window is separated on 3 blocks:
 * left - menu, right top - content, right bottom - buttons.
 */
@Component({
	selector: 'app-settings',
	templateUrl: './tabbed-form-control.component.html',
	styleUrls: ['./tabbed-form-control.component.scss']
})
export class TabbedFormControlComponent implements OnInit, OnDestroy {

	@Input() isnNextBtnVisibility = true;

	@ViewChild('fileuploadSettings') fileuploadSettingsInput: ElementRef;
	@ViewChild(ArenaComponent) sensorComponent: ArenaComponent;
	@ViewChild('container') container: PerfectScrollbarDirective;

	ConfigurationAdditionalTabTemplate = ConfigurableTabTemplate;
	ConfigurationAdditionalTabControlType = ConfigurableTabControlType;
	sensorSocket: SensorSocket | MultiSensorsSocket;

	activeMenuItem;
	settingForm: FormGroup;

	environment = environment;

	initialSettings;
	settingsFor3D;
	isUserSettings = false;

	devices: Array<any> = [];
	socketPool: Array<string> = [];
	spaces: Array<any> = [];
	currentSpace;
	loading = false;

	tabs: Array<IConfigurableTab> = [];

	defaultTabIcon = 'material_settings';
	defaultNumberInputStep = 0.1;
	disabledScroll = true;
	Object = Object;

	private settingSaved = false;
	private subscriptions: Array<Subscription> = [];
	private isDestroyed = false;

	private onKeyDown = (event: KeyboardEvent) => {
		if (!this.modalService.openedDialogs.length) {
			switch (event.key) {
				case 'Enter':
					if (document.activeElement) {
						document.activeElement['blur']();
					}
					setTimeout(() => {
						this.onSave();
					});
					break;
				case 'Escape':
					this.onCancel();
					break;
			}
		}
	};

	/**
	 * @see restoreInitialBoardToWebGUITransMatRelatedParameters
	 */
	private restoreInitialBoardToWebGUITransMatBeforeUnload = async () => {
		await this.settingsService.saveSensorParameters(this.sensorSocket.url, {
			'ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat': VALUE_TO_DELETE
		});
		await this.restoreInitialBoardToWebGUITransMatRelatedParameters();
	};

	constructor(public settingsService: SettingsService,
				private fb: FormBuilder,
				private translate: TranslateService,
				private sensorsService: SensorsService,
				private dialogRef: MatDialogRef<TabbedFormControlComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any,
				private modalService: ModalService,
				private storageService: StorageService,
				private busEventService: BusEventService) {

		this.sensorSocket = data.sensorSocket;
		this.isUserSettings = data.isUserSettings;

		window.addEventListener('beforeunload', this.restoreInitialBoardToWebGUITransMatBeforeUnload);
	}

	async ngOnInit() {
		document.addEventListener('keydown', this.onKeyDown);
		this.tabs = this.data.tabs;
		let settings;
		if (this.sensorSocket) {
			settings = await this.settingsService.getAllSettings(this.sensorSocket.url);
			this.initialSettings = settings;
			this.settingsFor3D = this.initialSettings;
		}
		this.subscriptions.push(this.dialogRef.afterOpened().subscribe(() => this.disabledScroll = false));
		this.subscriptions.push(this.sensorsService.parametersChange.subscribe(parameters => {
			if (parameters.url === this.sensorSocket.url && 'ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat' in parameters.parameters) {
				this.initialSettings['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'] = parameters.parameters['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'];
				this.initialSettings = makeDeepCopy(this.initialSettings);
				this.updateSettingsFor3DPreview();
			}
		}));
		if (this.sensorSocket) {
			this.subscriptions.push(this.sensorSocket.status.subscribe(status => {
				if (this.sensorSocket.isAnotherClientConnected && status === ConnectionStatus.INITIALIZING) {
					this.modalService.showMessage('ANOTHER_CLIENT_STARTED_SENSOR').afterClosed().toPromise().then(() => {
						this.dialogRef.close(false);
					});
				}
			}));
		}
		this.settingForm = await this.settingsService.getSettingsForm(this.sensorSocket, settings);
		this.subscriptions.push(this.sensorsService.anotherClientParametersChanged.subscribe(parameters => {
			this.settingsService.setSettingsForm({
				sensorParameters: parameters
			}, this.settingForm);
		}));

		/**
		 * Generate new form controls from configuration
		 */
		this.tabs.filter(tab => tab.enable).forEach((tab: IConfigurableTab) => {
			tab.controls.filter(control => control.enable).forEach((control: IConfigurableTabControl) => {
				let controlParam = control.param,
					value = settings['sensorParameters'][controlParam];
				if (control.type === ConfigurableTabControlType.TAB_SWITCH) {
					value = Boolean(settings['sensorParameters'][control.param]);
				}
				// TODO refactor it
				if (control.type === ConfigurableTabControlType.TAB_SENSOR_HEIGHT_INPUT) {
					value = settings['sensorParameters'][control.boardToWebGUITransMat as string][1][3];
					controlParam = control.paramToSetHeightTo as string;
				}
				// TODO refactor it
				if (this.settingForm.controls.isCoverageFeet.value) {
					if (control.param === this.settingsService.sensorHeightParameter) {
						value = meterToFeet(value);
					}
				}
				if (control.multiple && !(value instanceof Array)) {
					value = [value];
				}
				let formControl = new FormControl({
					value,
					disabled: !this.sensorSocket.isEditableParameter(controlParam)
				});
				(formControl as any)._configurableControl = control;
				(this.settingForm.controls.sensorParameters as FormGroup).addControl(controlParam, formControl);
			});
		});
		this.subscriptions.push(this.settingForm.valueChanges.subscribe(e => {
			this.updateSettingsFor3DPreview();
		}));
		let firstActiveTab = this.tabs.filter(tab => tab.enable)[0];
		if (firstActiveTab) {
			this.activeMenuItem = firstActiveTab.label;
		}
	}

	ngOnDestroy() {
		window.removeEventListener('beforeunload', this.restoreInitialBoardToWebGUITransMatBeforeUnload);
		document.removeEventListener('keydown', this.onKeyDown);
		this.isDestroyed = true;
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	onSave() {
		if (this.checkBeforeSave()) {
			let cb = async () => {
				this.settingSaved = true;
				this.onHideSettings(true, await this.saveSettings());
			};

			if (this.sensorSocket && this.sensorSocket.isAnotherClientConnected) {
				this.translate.get('WARNING_POPUP_ANOTHER_CLIENT_IS_CONNECTED_TO_THIS_HOST').subscribe((messageText: string) => {
					this.modalService.confirm(`${messageText}`).afterClosed().toPromise().then(res => {
						if (res) {
							cb();
						}
					});
				});
			} else {
				cb();
			}
		}
	}

	async onHideSettings(saved = true, diff = {}) {
		this.loading = true;
		if (this.settingSaved) {
			if (Object.keys(diff).length) {
				this.sensorsService.sendSettings(this.sensorSocket, true, diff).finally(() => {
					this.dialogRef.close(saved);
				});
			} else {
				this.dialogRef.close(saved);
				if (!(this.sensorSocket instanceof MultiSensorsSocket)) {
					this.sensorsService.triggerParametersChange(this.sensorSocket.url, this.initialSettings['sensorParameters']);
				}
			}
			if (this.sensorSocket.isSensorsNetworkEnabled) {
				let data = this.settingForm.getRawValue(),
					sensorNetworkParameters = Object.assign({}, this.initialSettings['sensorNetworkParameters'], {
						globalSensorLocation: [+data['globalSensorX'], +data['globalSensorY']],
						globalSensorOrientation: +data['globalSensorOrientation']
					}),
					nameIsChanged = data['roomName'] !== this.initialSettings['socketInfo']['name'],
					locationDataDiff = getChangedParameters(sensorNetworkParameters, this.initialSettings['sensorNetworkParameters']);
				if (nameIsChanged || Object.keys(locationDataDiff).length) {
					if (nameIsChanged) {
						this.sensorsService.validateAndUpdateRules();
					}
					this.sensorSocket.setNetworkSensorLocationData({
						'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.position': sensorNetworkParameters['globalSensorLocation'],
						'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.orientation': sensorNetworkParameters['globalSensorOrientation']
					}).catch(console.error.bind(console));
				}
			}
		} else {
			/**
			 * Restore initial settings
			 */
			if (Object.keys(diff).length) {
				if ('ProcessorCfg.Common.sensorOrientation.mountPlane' in diff || this.settingsService.sensorHeightParameter in diff) {
					this.restoreInitialBoardToWebGUITransMatRelatedParameters().then(() => {
						return this.getNewBoardToWebGUITransMat();
					}).finally(() => {
						this.dialogRef.close(saved);
					});
				} else {
					this.dialogRef.close(saved);
				}
			} else {
				this.dialogRef.close(saved);
			}
		}
	}

	async onCancel() {
		if (this.isUserSettings || !!this.data.isFloorPlan || (this.sensorSocket && this.sensorSocket.isMultipleSensors)) {
			this.dialogRef.close(false);
		} else {
			let diff = await this.settingsService.getChangedParameters(this.sensorSocket.url, this.settingForm, this.initialSettings);

			if (Object.keys(diff).length) {
				this.modalService.confirm(`${['WARNING_POPUP_MADE_SOME_CHANGES']}`, {}, 'Message', 'Yes', 'No')
					.afterClosed().toPromise().then(res => {
					if (res) {
						this.onHideSettings(false, diff);
					}
				});
			} else {
				this.onHideSettings(false);
			}
		}
	}

	onReset(callback?) {
		this.loading = true;
		setTimeout(async () => {
			await this.settingsService.resetSettingsForm(this.settingForm, this.sensorSocket);
			this.loading = false;

			if (callback) {
				setTimeout(() => {
					callback();
				});
			}
		});
	}

	// Active menu item
	setActiveMenuItem(id): void {
		this.activeMenuItem = id;
		setTimeout(() => {
			this.container.scrollToTop();
		});
	}

	onClickOpenBtn() {
		this.fileuploadSettingsInput.nativeElement.click();
	}


	/**
	 * Upload settings
	 * @param event
	 */
	onSelectFile(event: Event) {
		if ((<HTMLInputElement>event.target).files?.length) {
			let fileReader = new FileReader();

			fileReader.onload = async (e: Event) => {
				(<HTMLInputElement>event.target).value = '';
				let parameters;

				try {
					parameters = JSON.parse(e.target!['result']);
				} catch (e) {
					let message = `Settings could not be loaded\n(${e.message})`,
						title = 'Settings Loading Failed';

					console.error(e);
					this.modalService.showError(message, true, {}, title, 'Continue');
				}
				if (parameters && this.checkUploadedParametersAreValid(parameters)) {
					let initialSensorParameters = (this.initialSettings ? this.initialSettings['sensorParameters'] : {}),
						settings = {
							sensorParameters: Object.assign({}, initialSensorParameters, parameters['sensorParameters']),
							guiParameters: parameters['guiParameters'],
							socketInfo: parameters['socketInfo']
						};
					this.settingsService.setSettingsForm(settings, this.settingForm);
				}
			};
			fileReader.readAsText((<HTMLInputElement>event.target).files![0]);
		}
	}

	onClickSaveBtn() {
		let settings = JSON.parse(JSON.stringify(this.settingsService.prepareSettingsToSave(this.settingForm.getRawValue(), this.initialSettings)));
		Object.keys(settings['sensorParameters']).forEach(parameter => {
			if (!this.sensorSocket.isSavableParameter(parameter)) {
				delete settings['sensorParameters'][parameter];
			}
		});
		// Currently Zones not used (only in SmartHome)
		delete settings['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'];
		delete settings['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'];
		settings['guiParameters'] = {
			isCoverageFeet: settings['guiParameters']['isCoverageFeet'],
			selectingTargetDataGraphBehavior: settings['guiParameters']['selectingTargetDataGraphBehavior']
		};
		settings['socketInfo'] = {
			name: settings['socketInfo']['name']
		};
		if (!this.sensorSocket.isSensorsNetworkEnabled) {
			delete settings['sensorNetworkParameters'];
		}
		if (!settings['sensorParameters']['ProcessorCfg.ExternalGUI.enable_click_on_target_for_breathing_option']) {
			delete settings['guiParameters']['selectingTargetDataGraphBehavior'];
		}
		FileSaver.saveAs(new Blob([JSON.stringify(settings, null, '\t')], {type: 'application/json;charset=utf-8'}), `Sensor_${this.sensorSocket.url}_settings.json`);
		this.settingsService.saveSocketInfo(this.sensorSocket.url, {
			settingsSaved: true
		});
	}

	onSensorPositionChanged(e) {
		let settings = this.settingsService.prepareSettingsToSave(this.settingForm.getRawValue(), makeDeepCopy(this.initialSettings));
		this.updateBoardToWebGUITransMat(settings);
	}

	onSensorHeightChanged(e) {
		let settings = this.settingsService.prepareSettingsToSave(this.settingForm.getRawValue(), makeDeepCopy(this.initialSettings));
		this.updateBoardToWebGUITransMat(settings);
	}

	onMonitoredAreaChanged(e) {

	}

	async getNewBoardToWebGUITransMat() {
		await this.settingsService.saveSensorParameters(this.sensorSocket.url, {
			'ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat': VALUE_TO_DELETE
		});
		this.sensorSocket.getSensorParameters({
			'ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat': null
		});
	}

	private saveSettings(): Promise<any> {
		this.data.settingForm = this.settingForm;
		if (this.sensorSocket && this.sensorSocket instanceof MultiSensorsSocket) {
			return this.saveBackgroundImage();
		} else if (this.sensorSocket) {
			return this.settingsService.saveSettingsForm(this.sensorSocket.url, this.settingForm).then(diff => {
				if (this.settingForm.controls.backgroundImage) {
					return this.saveBackgroundImage().then(() => {
						return diff;
					});
				} else {
					return diff;
				}
			});
		} else {
			return Promise.resolve({});
		}
	}

	private saveBackgroundImage() {
		let info = {
			backgroundImage: this.settingForm.controls.backgroundImage.value,
			width: this.settingForm.controls.width.value,
			height: this.settingForm.controls.height.value,
			origin: this.settingForm.controls.origin.value,
		};
		return this.settingsService.saveSocketInfo(this.sensorSocket.url, info).then(() => {
			this.busEventService.multipleRoomsParamsChanged.emit(info);
		});
	}

	// TODO should use validation rules from configuration
	checkBeforeSave() {
		let monitoredRoomDimsControlPresents = this.tabs.find(tab => tab.controls.some(control => {
				return control.type === ConfigurableTabControlType.TAB_MONITORED_ROOM_DIMS && control.enable;
			})),
			floorPlanControlPresents = this.tabs.find(tab => tab.controls.some(control => {
				return control.type === ConfigurableTabControlType.TAB_BACKGROUND_IMAGE && control.enable;
			})),
			result = monitoredRoomDimsControlPresents ? this.checkSensorMonitoredArena() : true;

		if (floorPlanControlPresents) {
			result = result && this.checkFloorPlan();
		}
		return result;
	}


	private checkUploadedParametersAreValid(parameters) {
		return this.settingsService.checkUploadedParametersAreValid(parameters, this.sensorSocket);
	}

	private checkFloorPlan() {
		return true;
	}

	// TODO should use validation rules from configuration
	private checkSensorMonitoredArena() {
		let isInvalid = false,
			mountPlane = (this.settingForm.controls['sensorParameters'] as FormGroup).controls['ProcessorCfg.Common.sensorOrientation.mountPlane'].value,
			sensorHeight = +(this.settingForm.controls[`sensorParameters`] as FormGroup).controls[this.settingsService.sensorHeightParameter].value;

		switch (mountPlane) {
			case SensorMountPlane.CEILING:
				isInvalid = +this.settingForm.controls.MonitoredRoomDimsZMax.value > sensorHeight;
				if (isInvalid) {
					this.modalService.showError('SENSOR_HEIGHT_CAN_NOT_BE_LOWER_THAN_Z_MAX');
				}
				break;
			case SensorMountPlane.BACKWALL:
				isInvalid = +this.settingForm.controls.MonitoredRoomDimsYMin.value < 0;
				if (isInvalid) {
					this.modalService.showError('SENSOR_Y_COULD_NOT_BE_LESS_THAN_ZERO');
				}
				break;
		}

		if (this.settingForm.controls.MonitoredRoomDimsXMin.value > this.settingForm.controls.MonitoredRoomDimsXMax.value ||
			this.settingForm.controls.MonitoredRoomDimsYMin.value > this.settingForm.controls.MonitoredRoomDimsYMax.value ||
			this.settingForm.controls.MonitoredRoomDimsZMin.value > this.settingForm.controls.MonitoredRoomDimsZMax.value) {
			isInvalid = true;
		}

		return !isInvalid;
	}

	private updateSettingsFor3DPreview() {
		let newSettingsFor3D = this.settingsService.prepareSettingsToSave(this.settingForm.getRawValue(), this.initialSettings || this.settingsService.getDefaultSettings()),
			sensorHeight = newSettingsFor3D['sensorParameters'][this.settingsService.sensorHeightParameter];
		if (newSettingsFor3D['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat']) {
			newSettingsFor3D['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'][1][3] = sensorHeight;
		}
		this.settingsFor3D = newSettingsFor3D;
	}

	/**
	 * After cancel restore initial parameters related to boardToWebGUITransMat
	 */
	private restoreInitialBoardToWebGUITransMatRelatedParameters() {
		return this.sendBoardToWebGUITransMatRelatedParameters(this.initialSettings);
	}

	/**
	 * After changing sensor position(mount plane or sensor height)
	 * send mount plane, sensor height and monitored area to FP
	 * and request new boardToWebGUITransMat
	 * @param settings
	 */
	private updateBoardToWebGUITransMat(settings) {
		// TODO refactor this hardcoded mountPlaneControl ref
		this.sendBoardToWebGUITransMatRelatedParameters(settings).then(() => {
			this.getNewBoardToWebGUITransMat();
		});
	}

	/**
	 * @see updateBoardToWebGUITransMat
	 * @param settings
	 */
	private sendBoardToWebGUITransMatRelatedParameters(settings) {
		return this.sensorsService.sendSettings(this.sensorSocket, true, {
			'ProcessorCfg.Common.sensorOrientation.mountPlane': settings['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'],
			[this.settingsService.sensorHeightParameter]: settings['sensorParameters'][this.settingsService.sensorHeightParameter],
			'ProcessorCfg.MonitoredRoomDims': settings['sensorParameters']['ProcessorCfg.MonitoredRoomDims']
		});
	}
}
