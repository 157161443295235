import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseViewComponent} from '../../../../../components/base-view/base-view.component';
import {environment} from '../../../../../../environments/environment';
import {ConnectionType, SelectingTargetDataGraphBehavior} from '../../../../../consts';
import {MqttSocket} from '../../../../../services/system/mqtt-socket';
import {ConnectionStatus} from '../../../../../services/system/connection';
import {MODAL_TYPE} from '../../../../../services/modal.service';
import {SignalrConnection} from '../../../../../services/system/signalr-connection';
import {interval, Subscription} from 'rxjs';
import {flatMap, startWith} from 'rxjs/operators';
import {SubToolbarFloorPlanComponent} from '../../toolbars/sub-toolbar-floor-plan/sub-toolbar-floor-plan.component';

/**
 * Component is designed for showing scheme of building.
 * Used for Smart Buildings app.
 */
@Component({
	selector: 'app-floor-plan',
	templateUrl: '../../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../../components/base-view/base-view.component.scss']
})
export class FloorPlanComponent extends BaseViewComponent implements OnInit, OnDestroy {

	useSubToolbar = true;
	showLayerControlsMenu = false;
	connection: MqttSocket | SignalrConnection;
	noLayersContainerText = 'PLEASE_CHOOSE_A_LOCATION_AND_A_FLOOR';
	showNoLayersContainerLoading = true;
	private ackSendingSubscription: Subscription;
	private ackTimeoutId;
	private noReactionOnMessageTimeoutId;
	private isDataFlowStarted = false;
	private mode;
	protected subToolbarComponent = SubToolbarFloorPlanComponent;

	constructor(protected injector: Injector) {
		super(injector);
		this.busEventService.showSubToolbar.next({open: false, mode: this.busEventService.showSubToolbar.value.mode});
	}

	ngOnInit() {
		this.toolbarService.setIsSmartBuildings(true);
		if (!this.toolbarService.floorPlanFiltersForm) {
			this.toolbarService.initFloorPlanFiltersForm();
		}

		// On connect from connections list (small popup in right top corner)
		this.subscriptions.push(this.busEventService.connect.subscribe(e => {
			this.createConnection(e.type, e.url);
		}));
		this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
			this.combinedData = data;
		}));
		this.subscriptions.push(this.busEventService.multipleRoomsParamsChanged.subscribe(info => {
			this.connectionSettings = Object.assign({}, this.connectionSettings, {socketInfo: info});
		}));
		this.subscriptions.push(this.busEventService.floorPlanLocationDrawEnd.subscribe((data: any) => {
			if (data.points.length > 2) {
				// Close the path adding the first element to the end
				this.saveLocation([...data.points, data.points[0]]);
			}
		}));

		if (environment.useAzure) {
			this.connectToSignalr();
			this.subscriptions.push(this.toolbarService.floorPlanFiltersForm.controls.floor_id.valueChanges.subscribe(() => {
				if (this.isFloorPlanFiltersFormValid()) {
					this.reloadFloorPlan();
					this.cleanOldData();
					this.dataService.clearMqttStorage(this.connection.url);
				}
			}));
			this.subscriptions.push(this.busEventService.showSubToolbar.subscribe(next => {
				this.mode = next.mode;
				if (next.open) {
					if (next.mode === 'add') {
						this.geoFences = [];
						this.emitMultipleRoomsParamsChanged({
							floor_name: '',
							min_x: 0,
							max_x: 0,
							min_y: 0,
							max_y: 0,
							base64_image: null
						});
					}
				} else if (next.cancel) {
					this.reloadFloorPlan();
				}
			}));
			this.subscriptions.push(this.busEventService.reloadGeoFences.subscribe(() => {
				this.reloadGeoFences();
			}));
		}
	}

	private saveLocation(points) {
		this.modalService.userInput('ENTER_LOCATION_NAME', {type: 'any'})
			.afterClosed()
			.subscribe(result => {
				if (result) {
					this.toolbarService.setFloorPlanLocation({
						...this.toolbarService.floorPlanFiltersForm.getRawValue(),
						name: result.value,
						points: points.map(p => [p.lng, p.lat])
					});
				}
			});
	}

	private async startLiveData(): Promise<any> {
		let data = await this.getDataForLiveDataCommands();

		return this.rest.startFloorsLiveData(data)
			.catch((error) => {
				this.modalService.showError('FAILED_TO_START_LIVE_DATA_TRANSMISSION_FROM_THE_SERVER');
				return Promise.reject(error);
			});
	}

	private async stopLiveData() {
		let data = await this.getDataForLiveDataCommands();

		this.rest.stopFloorsLiveData(data)
			.catch((error) => {
				this.modalService.showError('FAILED_TO_STOP_LIVE_DATA_TRANSMISSION_FROM_THE_SERVER');
				return Promise.reject(error);
			});
	}

	private async getDataForLiveDataCommands() {
		let {building_id, floor_id} = this.toolbarService.floorPlanFiltersForm.getRawValue(),
			data = {
				building_id,
				floor_id
			};

		return data;
	}

	private stopSignalrDataFlow(): void {
		this.stopLiveData();
		this.stopSendingAcknowledgePacket();
		this.connection.stop();
		this.isDataFlowStarted = false;
	}

	private async startSignalrDataFlow(): Promise<void> {
		if (!this.isDataFlowStarted) {
			const loaderModal = this.modalService.showLoadingPopup('STARTING_LIVE_MODE', []);
			let cancelled = false;
			loaderModal.afterClosed().toPromise().then(async (res) => {
				if (res === false) {
					cancelled = true;
				}
			});
			await this.startLiveData();
			this.connection.start();
			if (cancelled) {
				// Stop after /ack request
				setTimeout(() => {
					this.stopSignalrDataFlow();
				});
			}
			loaderModal.close();
		}
		this.startSendingAcknowledgePacket();
		this.isDataFlowStarted = true;
	}

	ngOnDestroy() {
		this.rest.preloadingIsCanceled = true;
		if (this.connection) {
			if (this.connection instanceof SignalrConnection && this.connection.isRunned()) {
				this.stopSignalrDataFlow();
			}
			this.sensorsService.stopSensor(this.connection);
		}
		this.stopAckTimeoutCount();
		super.ngOnDestroy();
	}

	connectToSignalr(): void {
		this.busEventService.restIsLoading.next(true);
		this.getOrCreateSignalrConnection(environment.azure.realTimeFunctionAppURL).then(connection => {
			this.subscriptions.push(this.toolbarService.startFloorPlanLiveData.subscribe(e => {
				this.dataService.clearMqttStorage(this.connection.url);
				this.toolbarService.floorPlanFiltersForm.markAllAsTouched();
				if (this.toolbarService.floorPlanFiltersForm.valid) {
					this.createConnection(e.type, e.url);
				}
			}));
			this.subscriptions.push(this.toolbarService.stopFloorPlanLiveData.subscribe(e => {
				if (e.type === ConnectionType.SIGNALR) {
					this.stopSignalrDataFlow();
				}
				this.stopAckTimeoutCount();
			}));
			this.connection = connection;
			this.toolbarService.setConnection(this.connection);
			this.connection
				.connect()
				.catch((err) => {
					this.modalService.showError(err.message);
				}).finally(() => {
				this.busEventService.restIsLoading.next(false);
				});
			this.onParametersChange();
		});
	}

	async updateWithSettings(initialParameters?) {
		const parameters = await this.settingsService.getAllSettings(this.connection.url);
		this.isDuplicateView = parameters.guiParameters.isDuplicateView;
		this.toolbarService.setIsDuplicateViewFlag(this.isDuplicateView);
		initialParameters = {};
		if (environment.isSmartBuildingsModuleEnabled) {
			initialParameters['ProcessorCfg.ExternalGUI.Layers'] = environment.smartBuildingsLayers;
		} else {
			initialParameters['ProcessorCfg.ExternalGUI.Layers'] = environment.floorPlanLayers;
		}
		this.generateLayers({
			sensorParameters: initialParameters,
			guiParameters: parameters.guiParameters
		});
	}


	protected generateLayers(parameters) {
		parameters.guiParameters['selectedLayers'] = [[
			{
				name: 'MultipleRooms',
				componentName: 'PointCloud2DComponent',
				selected: true
			}
		], []];
		super.generateLayers(parameters);
	}


	protected async onParametersChange(initialParameters?) {
		const parameters = await this.settingsService.getAllSettings(this.connection.url, true);

		if (this.connectionSettings?.socketInfo) {
			delete parameters.socketInfo;
		}
		// In case we retrieve sensorParameters from another client
		if (!('guiParameters' in parameters)) {
			parameters['guiParameters'] = this.settingsService.getDefaultSettings()['guiParameters'];
		}
		if (environment.isSmartBuildingsModuleEnabled) {
			parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Layers'] = environment.smartBuildingsLayers;
		} else {
			parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Layers'] = environment.floorPlanLayers;
		}
		this.connectionSettings = Object.assign({}, this.connectionSettings, parameters);
		this.toolbarService.setSensorSocketSettings(this.connectionSettings);
		if (this.connectionSettings['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.Automatically) {
			this.selectedModelIds = [];
		}

		this.updateWithSettings(initialParameters);
	}


	/**
	 * Subscribe on status changes, close, parametersChange, dataRetrieve, metaDataRetrieve, combined data in connection.
	 */
	protected registerSocketEvents() {
		var previousStatus;
		if (this.statusSub) {
			this.statusSub.unsubscribe();
		}
		this.statusSub = this.connection.status.subscribe((status: ConnectionStatus) => {
			if (this.connection) {
				if (status === ConnectionStatus.DISCONNECTED) {
					this.modalService.close(MODAL_TYPE.ALL);
					this.data = {};
					this.combinedData = {};
				}
				previousStatus = status;
			}
		});
		if (this.closeSub) {
			this.closeSub.unsubscribe();
		}
		this.closeSub = this.connection.close.subscribe(disconnectReason => {
			this.toolbarService.setDisconnectReason(disconnectReason);
		});
		if (this.sensorsService.eventListeners.dataRetrieve[this.connection.url]) {
			this.subscriptions.push(this.sensorsService.eventListeners.dataRetrieve[this.connection.url].subscribe(data => {
				this.data = data;
			}));
		}
		this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
			this.combinedData = data;
		}));
	}

	private async createConnection(connectionType: ConnectionType, url: string) {
		const serverAddress = await this.getServerAddress();
		if (serverAddress) {
			this.rest.setServerAddress(serverAddress);
		}
		if (connectionType === ConnectionType.MQTT) {
			this.connection = await this.getOrCreateMqttConnection(url);
		} else if (connectionType === ConnectionType.SIGNALR) {
			this.connection = await this.getOrCreateSignalrConnection(url);
			this.startSignalrDataFlow();
		}
		this.toolbarService.setConnection(this.connection);
		this.registerSocketEvents();
		this.onParametersChange();
		this.connection.retrieveStatus();
	}

	private async getOrCreateMqttConnection(url) {
		let connection = this.sensorsService.getMqttSocket(`${url}:${environment.mqttBrokerWebsocketPort}`);
		if (!connection) {
			return this.sensorsService.createMqttSocket(url, environment.mqttBrokerWebsocketPort);
		}
		return connection;
	}

	private async getOrCreateSignalrConnection(url) {
		let connection = this.sensorsService.getSignalrConnection(url);
		if (!connection) {
			return this.sensorsService.createSignalrSocket(url);
		}
		return connection;
	}

	private async startSendingAcknowledgePacket() {
		let data = await this.getDataForLiveDataCommands();
		this.startAckTimeoutCount();
		this.ackSendingSubscription = interval(environment.azure.signalrKeepAliveInterval)
			.pipe(startWith(0))
			.pipe(
				flatMap(() => this.rest.sendFloorsAck(data))
			)
			.subscribe({
				error: () => {
					this.modalService.showError('KEEP_ALIVE_MESSAGE_WAS_NOT_RECEIVED_BY_THE_SERVER');
					this.stopSignalrDataFlow();
				}
			});
	}

	private stopSendingAcknowledgePacket(): void {
		if (this.ackSendingSubscription) {
			this.ackSendingSubscription.unsubscribe();
		}
	}

	private async getServerAddress(): Promise<string> {
		if (environment.useAzure) {
			return environment.azure.telemetryFunctionAppURL;
		} else {
			if (environment.disableRemoteConnToRESTServer) {
				return environment.analyticsRESTserverAddress;
			} else {
				let tmp = await this.storageService.getItem('analyticsServerAddress');
				return (tmp || {}).analyticsServerAddress;
			}
		}
	}

	private emitMultipleRoomsParamsChanged(data) {
		this.busEventService.multipleRoomsParamsChanged.emit({
			name: data['floor_name'],
			globalRoomWidth: +data['max_x'] - +data['min_x'],
			globalRoomHeight: +data['max_y'] - +data['min_y'],
			backgroundImage: data['base64_image']
		});
	}

	private startAckTimeoutCount() {
		this.ackTimeoutId = setTimeout(() => {
			this.stopSendingAcknowledgePacket();
			this.noReactionOnMessageTimeoutId = setTimeout(() => {
				this.stopSignalrDataFlow();
			}, environment.azure.noReactionOnInactivityMessageTimeout);
			this.modalService.confirm('Are you still watching?', {}, undefined, 'Yes', 'No').afterClosed().toPromise().then(res => {
				if (res) {
					this.startSignalrDataFlow();
				} else {
					if (this.isDataFlowStarted) {
						this.stopSignalrDataFlow();
					}
				}
				clearTimeout(this.noReactionOnMessageTimeoutId);
			});
		}, environment.azure.inactivityTimeout);
	}

	private stopAckTimeoutCount() {
		clearTimeout(this.ackTimeoutId);
	}

	private reloadGeoFences() {
		const filterFormValues = this.toolbarService.floorPlanFiltersForm.getRawValue();
		this.rest.getGeoFences(filterFormValues.floor_id).then(locations => {
			this.geoFences = locations;
		});
	}

	private reloadFloorPlan() {
		if (this.isFloorPlanFiltersFormValid()) {
			const filterFormValues = this.toolbarService.floorPlanFiltersForm.getRawValue();
			this.storageService.setItem('floorPlanFilter', filterFormValues);
			this.rest.getFloorPlan(filterFormValues.floor_id).then(response => {
				if (!this.isDestroyed) {
					this.busEventService.smartBuildingPageIsReady.emit();
				}
				this.onParametersChange().then(() => {
					this.emitMultipleRoomsParamsChanged(response);
				});
			});
			this.reloadGeoFences();
		}
	}

	private isFloorPlanFiltersFormValid() {
		let data = this.toolbarService.floorPlanFiltersForm.getRawValue();

		return typeof data.building_id === 'number' && typeof data.floor_id === 'number';
	}
}
