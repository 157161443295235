import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {timeout} from 'rxjs/operators';
import {RestConnection} from './rest-connection';
import {prepareHttpParamsForRetailGraph} from '../../utils/utils';
import {Router} from '@angular/router';
import {RetailFloor, RetailGeoFence, RetailGeoFenceType, RetailGraph, RetailLocation} from '../../models/models';
import {BusEventService} from '../bus-event.service';
import {StorageService} from '../storage.service';
import {ModalService} from '../modal.service';
import {MatDialogRef} from '@angular/material/dialog';
import {ModalMessageComponent} from '../../components/shared/modal-message/modal-message.component';
import {LS_SmartRetailTimezone} from '../../consts';

export var retailGraphs: Array<RetailGraph> = [
	{
		label: 'Traffic',
		type: 'bar',
		page: 'total-traffic',
		data: 'TotalTraffic',
		axisLabels: {
			x: 'Time',
			y: 'Number of People'
		}
	}, {
		label: 'Dwell time',
		type: 'line',
		page: 'dwell-time',
		data: 'Averagetime',
		isTime: true,
		axisLabels: {
			x: 'Time',
			y: 'Avg Time (min)'
		}
	}, {
		label: 'Heatmap',
		type: 'heatmap',
		page: 'Heatmap',
		data: 'Heatmap',
		liveModeSupport: true,
		axisLabels: {
			x: '',
			y: ''
		}
	}
];

export var geoFencesGraphs: Array<RetailGraph> = [
	{
		label: 'Geo-fences',
		type: 'conversion',
		page: 'total-traffic-conversion',
		totalPage: 'total-traffic',
		data: 'Conversion',
	}
];

interface CacheEntry {
	data: any;
	params: HttpParams;
	body?: object;
}

/**
 * Class for working with REST API using HTTP.
 */
@Injectable({
	providedIn: 'root'
})
export class RestService {

	filter;

	isConnected = true;
	isConnecting = false;
	retailAnalyticsAPIserverPort = '12345';
	/**
	 * Preloading could be canceled, when user left initial app page.
	 * In this case there is no need to preload, because all requests to the server will be executed in the standard order.
	 */
	preloadingIsCanceled = false;
	private retailAnalyticsAPIserverAddress;
	private connectionPool: {
		[url: string]: RestConnection
	} = {};

	private cache: {
		[cacheKey: string]: CacheEntry
	} = {};

	private floorImageCache = {};
	private errorModalPopupRef: MatDialogRef<ModalMessageComponent> | null;
	private doNotShowErrorMessageRequests = ['ack', 'start_live_mode', 'stop_live_mode'];

	private pagesInitializationRequests: any = {
		'/smart-buildings/floor-plan': [
			{
				method: this.getGeoFencesTypes,
				args: []
			}, {
				method: this.getLocations,
				args: []
			}, {
				method: this.getFloors,
				args: []
			}
		],
		'/smart-buildings/analytics': [
			{
				method: this.getGeoFences,
				args: []
			}, {
				method: this.getFloorPlan,
				args: []
			}
		],
		'/smart-buildings/geo-fences': [
			{
				method: this.getGeoFences,
				args: []
			}, {
				method: this.getFloorPlan,
				args: []
			}
		]
	};

	constructor(private http: HttpClient,
				private router: Router,
				private busEventService: BusEventService,
				private storageService: StorageService,
				private modalService: ModalService) {
		this.initFilters();
		this.storageService.getItem(LS_SmartRetailTimezone).then(timezone => {
			if (timezone) {
				this.filter.timezone = timezone;
			}
		});
		if (environment.isSmartBuildingsModuleEnabled) {
			/**
			 * Start preloading after current page is done.
			 */
			let smartBuildingPageIsReady = this.busEventService.smartBuildingPageIsReady.subscribe(() => {
				smartBuildingPageIsReady.unsubscribe();
				if (!this.preloadingIsCanceled) {
					this.preloadData();
				}
			});

			this.sendRetailAckRequests();
			setInterval(() => {
				this.sendRetailAckRequests();
			}, environment.azure.retailInactivityTimeout);
		}
	}

	initFilters(): void {
		this.filter = {
			start_date: new Date((new Date).setHours(0, 0)),
			end_date: new Date((new Date).setHours(23, 0)),
			start_time: new Date((new Date).setHours(0, 0)),
			end_time: new Date((new Date).setHours(23, 0)),
			location_ids: [],
			floor_ids: [],
			floor_id: null,
			building_id: null,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
		};
	}

	getConnection(url): RestConnection {
		return this.connectionPool[url];
	}

	createConnection(ip, port = this.retailAnalyticsAPIserverPort) {
		let connection = new RestConnection(ip, port);
		this.connectionPool[connection.url] = connection;
		return connection;
	}

	setServerAddress(retailAnalyticsAPIserverAddress) {
		if (this.retailAnalyticsAPIserverAddress !== retailAnalyticsAPIserverAddress) {
			this.isConnected = false;
			if (environment.useAzure) {
				this.retailAnalyticsAPIserverAddress = retailAnalyticsAPIserverAddress;
			} else {
				this.retailAnalyticsAPIserverAddress = `http://${retailAnalyticsAPIserverAddress}:${this.retailAnalyticsAPIserverPort}`;
			}
		}
	}

	getServerAddress() {
		return this.retailAnalyticsAPIserverAddress;
	}

	connect() {
		return Promise.resolve();
		if (this.isConnected) {
			return Promise.resolve();
		}
		this.isConnecting = true;
		return this.http.get(`${this.retailAnalyticsAPIserverAddress}/connect`, {
			responseType: 'text'
		}).toPromise()
			.then(() => {
				this.isConnected = true;
			})
			.finally(() => {
				this.isConnecting = false;
			});
	}

	getGeoFences(floor_id?): Promise<Array<RetailGeoFence>> {
		return this.makeGetRequest(environment.azure.metadataFunctionAppURL, 'locations', true, 'geoFences').then(data => {
			if (floor_id) {
				return data.filter(l => l.parent_floor === floor_id);
			} else {
				return data;
			}
		});
	}

	addGeoFence(data: RetailGeoFence) {
		this.clearCache(['geoFences']);
		return this.makePostRequest(environment.azure.metadataFunctionAppURL, 'locations', data);
	}

	updateGeoFence(data: RetailGeoFence) {
		this.clearCache(['geoFences']);
		return this.makePatchRequest(environment.azure.metadataFunctionAppURL, 'locations', data);
	}

	deleteGeoFences(data: Array<number>) {
		this.clearCache(['geoFences']);
		return this.http.request('DELETE', `${environment.azure.metadataFunctionAppURL}/locations`, {
			body: data
		}).toPromise().catch(this.catchError.bind(this));
	}

	getGeoFencesTypes(): Promise<Array<RetailGeoFenceType>> {
		return this.makeGetRequest(environment.azure.metadataFunctionAppURL, 'locations/types', true);
	}

	getFloorPlan(floor_id: number): Promise<RetailFloor> {
		return this.getFloors().then(floors => {
			return new Promise(resolve => {
				let floor = floors.find(f => f.id === floor_id);

				if (floor && floor['image'].length) {
					let cachedImage = this.floorImageCache[floor['image']],
						resolveData = (image = '') => {
							let data = Object.assign(floor, {
								'base64_image': image
							});
							resolve(data);
						};

					if (cachedImage) {
						if (cachedImage instanceof Promise) {
							cachedImage.then(base64String => {
								resolveData(base64String);
							});
						} else {
							resolveData(cachedImage);
						}
					} else {
						let requestPromise = this.http.get(floor['image'], {
							'responseType': 'text'
						}).toPromise().then(base64String => {
							if (this.floorImageCache[floor!['image']] === requestPromise) {
								this.floorImageCache[floor!['image']] = base64String;
							}
							resolveData(base64String);

							return base64String;
						}).catch(() => {
							resolveData();
						});

						this.floorImageCache[floor['image']] = requestPromise;
					}
				} else {
					resolve(floor);
				}
			});
		});
	}

	addFloorPlan(data: RetailFloor) {
		this.clearCache(['floors']);
		return this.makePostRequest(environment.azure.metadataFunctionAppURL, 'floors', data);
	}

	updateFloorPlan(data: RetailFloor) {
		this.clearCache(['floors']);
		return this.makePatchRequest(environment.azure.metadataFunctionAppURL, 'floors', data);
	}

	deleteFloorPlans(data: Array<number>) {
		this.clearCache(['floors']);
		return this.http.request('DELETE', `${environment.azure.metadataFunctionAppURL}/floors`, {
			body: data
		}).toPromise().catch(this.catchError.bind(this));
	}

	getGraph(params: object, path: string, onlyKPI = false, cacheKey?): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			let requests: Array<Promise<any>> = [];
			if (!onlyKPI) {
				requests.push(
					this.makePostRequest(environment.azure.telemetryFunctionAppURL, `${path}/graph`, params, true, cacheKey || `${path}/graph`)
				);
			}
			requests.push(
				this.makePostRequest(environment.azure.telemetryFunctionAppURL, `${path}/kpi`, params, true, cacheKey || `${path}/kpi`)
			);
			Promise.all(requests).then(response => {
				resolve({
					time: onlyKPI ? null : Object.keys(response[0]),
					buckets: onlyKPI ? null : Object.values(response[0]),
					kpi: response[onlyKPI ? 0 : 1]
				});
			}).catch(reject);
		});
	}

	getHeatmap(params: object) {
		return this.makePostRequest(environment.azure.commonAnalyticsFunctionAppURL, 'heatmap', params, true, 'heatmap');
	}

	getFloors(): Promise<Array<RetailFloor>> {
		return this.makeGetRequest(environment.azure.metadataFunctionAppURL, 'floors', true, 'floors');
	}

	getLocations(): Promise<Array<RetailLocation>> {
		return this.makeGetRequest(environment.azure.metadataFunctionAppURL, 'buildings', true, 'locations');
	}

	addLocation(data) {
		this.clearCache(['locations']);
		return this.makePostRequest(environment.azure.metadataFunctionAppURL, 'buildings', data);
	}

	updateLocation(data: RetailLocation) {
		this.clearCache(['locations']);
		return this.makePatchRequest(environment.azure.metadataFunctionAppURL, 'buildings', data);
	}

	deleteLocations(data: Array<number>) {
		this.clearCache(['locations']);
		return this.http.request('DELETE', `${environment.azure.metadataFunctionAppURL}/buildings`, {
			body: data
		}).toPromise().catch(this.catchError.bind(this));
	}

	sendFloorsAck(data) {
		return this.makePostRequest(environment.azure.realTimeFunctionAppURL, 'ack', data);
	}

	startFloorsLiveData(data) {
		return this.makePostRequest(environment.azure.realTimeFunctionAppURL, 'start_live_mode', data);
	}

	stopFloorsLiveData(data) {
		return this.makePostRequest(environment.azure.realTimeFunctionAppURL, 'stop_live_mode', data);
	}

	clearCache(cacheKeys: string[]) {
		cacheKeys?.forEach(cacheKey => {
			delete this.cache[cacheKey];
		});
	}

	async clearAnalyticsCache() {
		let keys = ['total-traffic-conversion', 'heatmap'].concat(...retailGraphs.map(g => [`${g.page}/graph`, `${g.page}/kpi`])).concat(...(await this.getGeoFences()).map(g => `total-traffic-conversion_${g.id}/kpi`));
		this.clearCache(keys);
	}

	private makeGetRequest(url, path, useCache = false, cacheKey?: string, params?, useTimeout = false) {
		return this.makeRequest(url, 'get', path, useCache, cacheKey || path, params, {}, useTimeout);
	}

	private makePostRequest(url, path, body, useCache = false, cacheKey?: string, params?, useTimeout = false) {
		return this.makeRequest(url, 'post', path, useCache, cacheKey || path, params, body, useTimeout);
	}

	private makePatchRequest(url, path, body, params?) {
		return this.makeRequest(url, 'patch', path, false, '', params, body);
	}

	/**
	 * Retrieve new data from server.
	 * 1. Check if there is a data in cache for this request with the same query parameters and return it in case it is.
	 * 2. If not - clear cache for this request (in case request failed - don't use previous cached data anymore).
	 * 3. Request new data from server.
	 */
	private makeRequest(url, method = 'get', path, useCache = false, cacheKey: string, params?, body = {}, useTimeout = false): Promise<any> {
		if (useCache && this.cache[cacheKey]) {
			let cacheAvailable = false;
			if (method === 'get') {
				cacheAvailable = !params || params.toString() === this.cache[cacheKey].params.toString();
			} else {
				cacheAvailable = JSON.stringify(body) === JSON.stringify(this.cache[cacheKey].body);
			}
			if (cacheAvailable) {
				let data = this.cache[cacheKey].data;

				/**
				 * Cache could contain actual data or active request to server.
				 */
				if (data instanceof Promise) {
					return data;
				} else {
					return Promise.resolve(data);
				}
			}
		}
		let request;
		switch (method) {
			case 'get':
				request = this.http.get(`${url}/${path}`, {
					params
				});
				break;
			case 'post':
				request = this.http.post(`${url}/${path}`, body, {
					params
				});
				break;
			case 'patch':
				request = this.http.patch(`${url}/${path}`, body, {
					params
				});
				break;
		}

		if (useTimeout) {
			request = request.pipe(timeout(environment.analyticsRESTserverTimeout));
		}

		let requestPromise = request.toPromise().catch(e => {
			return this.catchError(e, path);
		});

		if (useCache) {
			requestPromise = requestPromise.then(response => {
				/**
				 * Update cache with new data only if this request is latest.
				 */
				if (this.cache[cacheKey].data === requestPromise) {
					this.cache[cacheKey].data = response;
				}

				return response;
			});

			this.cache[cacheKey] = {
				params: params,
				body,
				data: requestPromise
			};
		}

		return requestPromise;
	}

	private toHttpParams(o = {}): HttpParams {
		let params = new HttpParams();
		Object.keys(o).forEach(key => {
			if (o[key] !== undefined) {
				params = params.append(key, o[key]);
			}
		});
		return params;
	}

	private catchError(e, request?) {
		console.error(e);
		this.isConnected = false;
		this.isConnecting = false;
		if (!this.doNotShowErrorMessageRequests.includes(request)) {
			if (!this.errorModalPopupRef) {
				this.errorModalPopupRef = this.modalService.showError('UNHANDLED_EXCEPTION_ERROR_TRY_AGAIN_LATER');
				this.errorModalPopupRef.afterClosed().toPromise().then(() => {
					this.errorModalPopupRef = null;
				});
			}
		}
		return Promise.reject(e);
	}

	/**
	 * Preload data for Smart building pages other that current.
	 * @private
	 */
	private async preloadData() {
		let floorPlanFilter = await this.storageService.getItem('floorPlanFilter');
		if (floorPlanFilter) {
			this.pagesInitializationRequests['/smart-buildings/floor-plan'].push({
				method: () => {
					return Promise.all([
						this.getLocations(),
						this.getFloors()
					]).then((data): any => {
						let {building_id, floor_id} = floorPlanFilter;
						if (data[0].find(b => b.id === building_id) && data[1].find(f => f.id === floor_id)) {
							return this.getFloorPlan(floorPlanFilter.floor_id);
						} else {
							return Promise.resolve();
						}
					});
				},
				args: []
			});
		}
		if (!this.filter.floor_ids.length) {
			let locations = await this.getLocations();
			if (locations.length) {
				let floors = await this.getFloors(),
					firstFloor = floors.find(f => f.parent_building === locations[0].id);

				if (firstFloor) {
					this.filter.floor_ids.push(firstFloor.id);
				}
			}
		}
		retailGraphs.forEach(graph => {
			let options,
				params = prepareHttpParamsForRetailGraph(graph, this.filter);

			if (graph.type === 'heatmap') {
				options = {
					method: this.getHeatmap,
					args: [params, graph.page]
				};
			} else {
				options = {
					method: this.getGraph,
					args: [params, graph.page]
				};
			}
			if (options) {
				this.pagesInitializationRequests['/smart-buildings/analytics'].push(options);
			}
		});

		let requests = Object.keys(this.pagesInitializationRequests)
			.filter(url => this.router.url !== url)
			.map(url => this.pagesInitializationRequests[url])
			.flat();

		let worker = i => {
			(requests[i].method as Function).apply(this, requests[i].args).finally(() => {
				if (requests[i + 1] && !this.preloadingIsCanceled) {
					worker(i + 1);
				}
			});
		};

		if (requests.length) {
			worker(0);
		}
	}

	private sendRetailAckRequests() {
		return this.makeGetRequest(environment.azure.telemetryFunctionAppURL, 'init-connection').then(() => {
			return this.makeGetRequest(environment.azure.metadataFunctionAppURL, 'ack');
		}).catch(e => {
		});
	}
}
