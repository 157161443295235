<div class="modal-default">
	<h3 [innerHTML]="data.title | translate:data.translateData | htmlNewLine"></h3>
	<div class="message">
		<form [formGroup]="form">
			<h5 class="input-title">{{'GEO_FENCE_NAME' | translate}}</h5>
			<div class="input">
				<input type="text" formControlName="location_name" data-selector="add-geofence_geofence-name_input">
				<hr>

			</div>
			<h5 class="input-title">{{'GEO_FENCE_TYPE' | translate}}</h5>
			<mat-form-field [attr.data-value]="form.controls['location_type'].value" data-selector="add-geofence_location-type_select" floatLabel="never" style="width: 100%">
				<mat-select formControlName="location_type">
					<mat-option *ngFor="let type of data.location_type_ids" [value]="type.id">
						{{type.type}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
	</div>
	<div class="default-panel">
		<button mat-button (click)="reject()" class="g-btn reject float-left" data-selector="popup_cancel_button">
			{{(data.cancelText || 'Cancel') | translate}}
		</button>
		<button mat-button (click)="resolve()"
				class="g-btn reject button-right"
				[disabled]="form.invalid"
				data-selector="add-geofence_ok_button">
			{{ 'OK' | translate}}
		</button>
	</div>
</div>




