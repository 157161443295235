import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';

@Component({
	selector: 'app-web-camera-video',
	templateUrl: './web-camera-video.component.html',
	styleUrls: ['./web-camera-video.component.scss']
})
export class WebCameraVideoComponent implements OnInit, OnDestroy, OnChanges {

	@Input() deviceId: string;
	@Output() deviceList = new EventEmitter();
	@ViewChild('video') video: ElementRef<HTMLVideoElement>;

	private deviceListUpdateEmitted = false;

	constructor(private el: ElementRef) {
	}

	ngOnInit(): void {
		this.initStream();
	}

	ngOnDestroy() {
		this.stopStreamedVideo();
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('deviceId' in changes) {
			this.stopStreamedVideo();
			this.initStream();
		}
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event?: Event) {
		this.updateStreamWithConstraints();
	}

	private initStream() {
		if (navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia({video: this.getVideoConstraints()})
				.then((stream) => {
					this.video.nativeElement.srcObject = stream;
					if (!this.deviceListUpdateEmitted) {
						this.deviceListUpdateEmitted = true;
						this.emitDeviceListUpdate(stream.getVideoTracks()[0].getSettings());
					}
				})
				.then(() => new Promise(resolve => this.video.nativeElement.onloadedmetadata = resolve))
				.then(() => {
					// console.log(`Video stream ready. Video height is ${this.video.nativeElement.videoHeight}px, width is ${this.video.nativeElement.videoWidth}px`);
				})
				.catch(function (e) {
					console.error(e);
				});
		}
	}

	private stopStreamedVideo() {
		if (this.video) {
			const stream = this.video.nativeElement.srcObject as MediaStream;
			if (stream) {
				stream.getTracks().forEach(track => track.stop());
			}
			this.video.nativeElement.srcObject = null;
		}
	}

	private getVideoConstraints(): MediaTrackConstraints {
		return {
			height: {max: this.el.nativeElement.clientHeight},
			width: {max: this.el.nativeElement.clientWidth},
			deviceId: this.deviceId,
			resizeMode: 'none'
		};
	}

	private updateStreamWithConstraints() {
		const stream = this.video.nativeElement.srcObject as MediaStream;
		if (stream) {
			stream.getTracks().forEach(track => track.applyConstraints(this.getVideoConstraints()));
		}
	}

	/**
	 * We can receive device names only after the user has granted access to the camera.
	 * @private
	 */
	private emitDeviceListUpdate(settings: MediaTrackSettings) {
		this.deviceList.emit(settings.deviceId);
	}

}
