import {StorageService} from '../../../services/storage.service';
import {SensorSocket} from '../../../services/system/sensor-socket';
import {environment} from '../../../../environments/environment';
import {ToolbarService} from '../../../services/toolbar.service';
import {MatDialog} from '@angular/material/dialog';
import {ChangeDetectorRef, Directive, Injector, OnDestroy} from '@angular/core';
import {SettingsService} from '../../../services/settings.service';
import {SensorsService} from '../../../services/system/sensors.service';
import {ConfigurationService, IConfigurablePageControl} from '../../../services/configuration.service';
import {ModalService} from '../../../services/modal.service';
import {Subscription} from 'rxjs';

/**
 * Base component for toolbar.
 */

// TODO: Add Angular decorator.
@Directive()
export class ToolbarBaseComponent implements OnDestroy {
	protected storageService: StorageService;
	toolbarService: ToolbarService;
	configurationService: ConfigurationService;
	protected dialog: MatDialog;
	protected ref: ChangeDetectorRef;
	protected settingsService: SettingsService;
	protected sensorsService: SensorsService;
	protected modalService: ModalService;

	protected subscriptions: Array<Subscription> = [];

	constructor(protected injector: Injector) {
		this.storageService = injector.get(StorageService);
		this.toolbarService = injector.get(ToolbarService);
		this.dialog = injector.get(MatDialog);
		this.ref = injector.get(ChangeDetectorRef);
		this.settingsService = injector.get(SettingsService);
		this.sensorsService = injector.get(SensorsService);
		this.configurationService = injector.get(ConfigurationService);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	clearAll() {
		this.storageService.clearStorage();
		document.location.reload(true);
	}

	onShowSettings(control?: IConfigurablePageControl) {
		let isFloorPlan = environment.isSmartBuildingsModuleEnabled && this.toolbarService.getIsSmartBuildings();

		let dialogRef = this.modalService.showSettings({
			sensorSocket: isFloorPlan ? undefined : this.toolbarService.getConnection(),
			isFloorPlan: isFloorPlan,
			isUserSettings: false,
			page: this.toolbarService.getRoute().data.pageName,
			tabs: control ? control.tabs : []
		});

		if (this.toolbarService.getIsCovidDetection()) {
			dialogRef.afterClosed().toPromise().then(async (saved) => {
				if (saved) {
					setTimeout(() => {
						this.ref.detectChanges();
					});
					this.settingsService.getSensorParameters((<SensorSocket>this.toolbarService.getConnection()).url).then(parameters => {
						if (parameters) {
							this.storageService.getItem('socketPool').then(socketPool => {
								socketPool.forEach(url => {
									this.settingsService.saveSensorParameters(url, {
										'ProcessorCfg.HeartBeat.recording_duration_timeout': parameters['ProcessorCfg.HeartBeat.recording_duration_timeout'],
										'ProcessorCfg.HeartBeat.pause_before_recording_duration': parameters['ProcessorCfg.HeartBeat.pause_before_recording_duration']
									});
								});
							});
						}
					});
				}
			});
		}

		return dialogRef;
	}

	onClickShowSettings(control?: IConfigurablePageControl) {
		this.onShowSettings(control);
	}

	isControlDisabled(...params: any[]);
	isControlDisabled(enabledWhenRunned = false) {
		let connection = this.toolbarService.getConnection() as SensorSocket,
			settings = this.toolbarService.getSensorSocketSettings(),
			isRunned = connection?.isRunned(),
			isConnected = connection?.isConnected();

		return !isConnected || !settings || (enabledWhenRunned ? !isRunned : isRunned);
	}
}
