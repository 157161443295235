<div class="edit-buttons">
	<ng-container *ngIf="type === 'floor'">
		<button matRipple data-selector="subtoolbar_upload-flor-plan_button" [matRippleColor]="rippleColor" (click)="fileuploadSettings.click()" class="load toolbar-button">
			<mat-icon>insert_photo</mat-icon>
			<div class="btn-text">{{'UPLOAD_FLOOR_PLAN' | translate}}</div>
			<input #fileuploadSettings type="file" (change)="onSelectFile($event)"
				   (click)="$event.stopPropagation()" accept="image/*">
		</button>
		<button matRipple [matRippleColor]="rippleColor" (click)="defineFloorDimensions()" class="toolbar-button"
				[disabled]="!floorForm.controls.image.value">
			<mat-icon>straighten</mat-icon>
			<div class="btn-text">{{'DEFINE_FLOOR_DIMENSIONS' | translate}}</div>
		</button>
		<button matRipple data-selector="subtoolbar_new-geofence_button" [matRippleColor]="rippleColor" (click)="newGeoFence()" class="toolbar-button"
				[disabled]="!floorForm.controls.image.value">
			<mat-icon>picture_in_picture</mat-icon>
			<div class="btn-text">{{'NEW_GEO_FENCE' | translate}}</div>
		</button>
		<button *ngIf="mode === 'edit'" data-selector="subtoolbar_delete-floor-plan_button" matRipple [matRippleColor]="rippleColor" (click)="deleteFloorPlan()" class="toolbar-button">
			<mat-icon>delete</mat-icon>
			<div class="btn-text">{{'DELETE_FLOOR_PLAN' | translate}}</div>
		</button>
	</ng-container>
	<ng-container *ngIf="type === 'location'">
		<button *ngIf="mode === 'edit'" data-selector="subtoolbar_delete-location_button" matRipple [matRippleColor]="rippleColor" (click)="deleteLocation()" class="toolbar-button">
			<mat-icon>delete</mat-icon>
			<div class="btn-text">{{'DELETE_LOCATION' | translate}}</div>
		</button>
	</ng-container>
</div>
<div class="right">
	<form *ngIf="type === 'floor'" [formGroup]="floorForm">
		<mat-form-field [attr.data-value]="floorForm.get('parent_building').value" matRipple data-selector="subtoolbar_location_select" matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor" class="mr-2">
			<mat-label>{{'LOCATIONS' | translate:{count: locations.length} }}</mat-label>
			<mat-select formControlName="parent_building" [attr.data-selector]="'floor-parent_building'">
				<mat-option *ngFor="let building of locations" [value]="building.id">
					{{building.building_name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field matRipple data-selector="subtoolbar_floor-name_input" matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor">
			<mat-label>Floor name</mat-label>
			<input type="text" matInput formControlName="floor_name" [attr.data-selector]="'floor-name_input'">
		</mat-form-field>
	</form>
	<form *ngIf="type === 'location'" [formGroup]="locationForm">
		<mat-form-field matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor" class="mr-2">
			<mat-label>Number of floors</mat-label>
			<input type="number" matInput formControlName="number_of_floors" [attr.data-selector]="'add-location_location-number-of-floors_input'">
		</mat-form-field>
		<mat-form-field data-selector="subtoolbar_location-name_input" matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor" class="mr-2">
			<mat-label>Location name</mat-label>
			<input type="text" matInput formControlName="building_name" [attr.data-selector]="'add-location_location-name_input'">
		</mat-form-field>
		<mat-form-field data-selector="subtoolbar_address_input" matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor">
			<mat-label>Location address</mat-label>
			<input type="text" matInput formControlName="address" [attr.data-selector]="'add-location_location-address_input'">
		</mat-form-field>
	</form>
	<button class="g-btn cancel" data-selector="subtoolbar_cancel_button" mat-raised-button (click)="cancel()" style="margin-right: 10px;">{{'CANCEL' | translate}}</button>
	<button class="g-btn" data-selector="subtoolbar_save_button" mat-raised-button type="submit" (click)="save()">{{'SAVE' | translate}}</button>
</div>
