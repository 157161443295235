<svg width="100%" height="100%" viewBox="0 0 345 345">
	<g [attr.transform]="'rotate(' + -rotate * 90 + ' ' + 345 / 2 + ' ' + 345 / 2 + ')'">
		<svg width="100%" height="100%" viewBox="0 0 150 345">
			<filter id="grayscale">
				<feColorMatrix type="matrix" values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0"/>
			</filter>
			<image width="150" height="187" href="assets/images/car/car-nose-see-through.svg"/>
			<image width="150" height="10" y="186" href="assets/images/car/car-center-see-through.svg"/>
			<image width="150" height="150" y="195" href="assets/images/car/car-rear-see-through.svg"/>
			<g width="100%" height="100%" transform="translate(20 110)">
				<ng-container *ngIf="isTargetsVisible">
					<ng-container *ngFor="let i = index; let loc of localData.seatIndications; trackBy: trackByDataId">
						<!--
							Indexes from 0 to 11 use 2 icons: 1 for seat and one for person.
							New icons (index 12 and above) use 1 icon, so no need for this image.
						 -->
						<image *ngIf="loc[6] < 12"
							[attr.key]="i"
							[attr.x]="getImageX(i)"
							[attr.y]="getImageY(i)"
							[attr.width]="getImageWidth(i)"
							[attr.height]="getImageHeight(i)"
							[attr.href]="getSeatImageHref(chairType[1])"
							[attr.transform]="getTranslate(i)"
							[attr.filter]="loc[7] ? '' : 'url(#grayscale)'"
						/>
						<image
							[attr.key]="i"
							[attr.x]="getImageX(i)"
							[attr.y]="getImageY(i)"
							[attr.width]="getImageWidth(i)"
							[attr.height]="getImageHeight(i)"
							[attr.href]="getPersonImageHref(i)"
							[attr.transform]="getTranslate(i)"
							[style.cursor]="isTargetsSelectable ? 'pointer' : 'default'"
							(click)="onMouseDown(i)"
							[attr.filter]="loc[7] ? '' : 'url(#grayscale)'"
						/>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="isTrackerEnabled">
					<ng-container *ngFor="let i = index; let loc of localData.data; trackBy: trackByDataId">
						<circle
							r="8"
							fill="red"
							[attr.cx]="getTargetPosition(i)[0]"
							[attr.cy]="getTargetPosition(i)[1]"
						>
						</circle>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="isSensorVisible">
					<ng-container *ngIf="isMultipleSensors; else elseBlock">
						<ng-container *ngFor="let s of localData.sensorPosition; let i = index;">
							<rect
								[attr.height]="SENSOR_H"
								[attr.width]="SENSOR_W"
								[attr.x]="getSensorPosition(i)[0]"
								[attr.y]="getSensorPosition(i)[1]"
								[attr.fill]="sensorColor"
								[attr.transform]="'translate(-'+ SENSOR_W / 2 +', '+ SENSOR_H / 4 +') ' + getSensorRotation(i)"></rect>
						</ng-container>
					</ng-container>
					<ng-template #elseBlock>
						<rect
							[attr.height]="SENSOR_H"
							[attr.width]="SENSOR_W"
							[attr.x]="getSensorPosition()[0]"
							[attr.y]="getSensorPosition()[1]"
							[attr.fill]="sensorColor"
							[attr.transform]="'translate(-'+ SENSOR_W / 2 +', '+ SENSOR_H / 4 +') ' + getSensorRotation()"></rect>
					</ng-template>
				</ng-container>
			</g>
		</svg>
	</g>
</svg>
