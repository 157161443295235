import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {Tracker3DStageBase} from '../../../../../base-classes/Tracker3DStageBase';
import {Settings3DStage} from './Settings3DStage';
import {SensorSocket} from '../../../../../services/system/sensor-socket';
import {ConnectionStatus} from '../../../../../services/system/connection';
import {ThreeJSLayerComponent} from '../../../../../components/layers/ThreeJSLayer';

/**
 * Component renders 3D scene with targets.
 * Used for Tracker app.
 */
@Component({
	selector: 'app-targets-3d',
	templateUrl: './targets-3d.component.html',
	styleUrls: ['./targets-3d.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class Targets3DComponent extends ThreeJSLayerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

	@Input() reflect = false;
	@Input() postureValue = false;
	@Input() data: any = {};
	@Input() parameters = {};
	@Input() sensorSocket: SensorSocket;
	@Input() isSensorVisible = true;
	@Input() settingsMode = false;
	@Input() isMultipleSensors = false;
	@Input() connectedSensorsSettings = [];
	@Input() StandingHuman3DModelOriginal;
	@Input() SittingHuman3DModelOriginal;
	@Input() LyingHuman3DModelOriginal;
	@Input() WalkingHuman3DModelOriginal;

	@Output() flip = new EventEmitter();
	@Output() posture = new EventEmitter;

	@ViewChild('div', {static: true}) div: ElementRef;

	localData: any = {};

	// TODO change it to true after display buttons for it
	isDirectionEnabled = false;

	private StandingHuman3DModelSaved = false;
	private SittingHuman3DModelSaved = false;
	private LyingHuman3DModelSaved = false;
	private WalkingHuman3DModelSaved = false;

	ngOnInit() {
		let arena = this.getArena();

		let connectionStatus = this.sensorSocket && this.sensorSocket.connectionStatus || ConnectionStatus.CONFIGURING;

		if (this.settingsMode) {
			this.pixDensity = new Settings3DStage(this.div.nativeElement, arena, connectionStatus, this.parameters);
		} else {
			this.pixDensity = new Tracker3DStageBase(this.div.nativeElement, arena, connectionStatus, this.parameters);
		}
		this.pixDensity.isDirectionEnabled = this.isDirectionEnabled;
		this.pixDensity.updateSensor(this.isMultipleSensors ? this.connectedSensorsSettings : this.parameters);
		this.saveAllHumanModels();
		if (this.sensorSocket && this.sensorSocket.connectionStatus !== 'IMAGING') {
			if (this.localData.locationData) {
				this.pixDensity.updateOnInit(
					this.localData,
					arena,
					this.isSensorVisible,
					this.reflect,
					false
					// this.postureValue - Currently disabled
				); // Update data
			}
		}
		if (this.sensorSocket) {
			this.sensorStatusSubscription = this.sensorSocket.status.subscribe(status => {
				this.pixDensity.updateStatus(status);
			});
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.onResize();
		});
	}

	ngOnChanges(c: SimpleChanges) {
		if (this.pixDensity) {
			if ('data' in c) {
				if (this.isMultipleSensors) {
					this.localData = this.concatCombinedData(this.data);
				} else {
					this.localData = this.data;
				}
			}
			this.saveAllHumanModels();
			if ('postureValue' in c) {
				this.pixDensity.onViewSelectionChange();
			}

			let arena = this.getArena();
			if ('parameters' in c || 'connectedSensorsSettings' in c) {
				this.pixDensity.updateScene(arena, this.isMultipleSensors ? this.connectedSensorsSettings : this.parameters);
			}
			this.updatePixDensity(arena, ('postureValue' in c) ? ConnectionStatus.IMAGING : this.sensorSocket && this.sensorSocket.connectionStatus || ConnectionStatus.CONFIGURING);
			if ('reflect' in c && this.sensorSocket.connectionStatus !== ConnectionStatus.IMAGING) {
				this.updatePixDensity(arena, ConnectionStatus.IMAGING);
			}
		}
	}

	ngOnDestroy() {
		if (this.pixDensity) {
			this.pixDensity.cleanup();
		}
		if (this.sensorStatusSubscription) {
			this.sensorStatusSubscription.unsubscribe();
		}
	}

	toggleFlip() {
		this.flip.emit(!this.reflect);
	}

	toggleDirection() {
		this.isDirectionEnabled = !this.isDirectionEnabled;
		this.pixDensity.isDirectionEnabled = this.isDirectionEnabled;
	}

	togglePosture() {
		this.posture.emit();
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event?: Event) {
		if (this.pixDensity) {
			this.pixDensity.onResize();
		}
	}

	private updatePixDensity(arena, status) {
		this.pixDensity.update(
			this.localData,
			arena,
			status,
			this.isSensorVisible,
			this.reflect,
			false
			// this.postureValue - Currently disabled
		);
	}

	private saveHumanModel3D(name) {
		this[`${name}saved`] = true;
		this.pixDensity.saveHumanModel3D(name, this[`${name}Original`].clone());
	}

	private saveAllHumanModels() {
		if (this.StandingHuman3DModelOriginal && !this.StandingHuman3DModelSaved) {
			this.saveHumanModel3D('StandingHuman3DModel');
		}
		if (this.SittingHuman3DModelOriginal && !this.SittingHuman3DModelSaved) {
			this.saveHumanModel3D('SittingHuman3DModel');
		}
		if (this.LyingHuman3DModelOriginal && !this.LyingHuman3DModelSaved) {
			this.saveHumanModel3D('LyingHuman3DModel');
		}
		if (this.WalkingHuman3DModelOriginal && !this.WalkingHuman3DModelSaved) {
			this.saveHumanModel3D('WalkingHuman3DModel');
		}
	}

	private concatCombinedData(data: any = {}) {
		let ret = {};

		Object.keys(data).forEach(output => {
			data[output].forEach(v1 => {
				v1.forEach(v2 => {
					if (typeof v2 === 'object') {
						v2.index = v1.index;
					}
				});
			});
			ret[output] = [].concat(...data[output]);
		});
		return ret;
	}
}
