import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {PointCloudStageBase} from '../../../../../base-classes/PointCloudStageBase';
import {SensorSocket} from '../../../../../services/system/sensor-socket';
import {SmartTailorStage} from './SmartTailorStage';
import {generateUIDFromCharCodes} from '../../../../../utils/utils';
import {ConnectionStatus} from '../../../../../services/system/connection';
import {ThreeJSLayerComponent} from '../../../../../components/layers/ThreeJSLayer';
import {BusEventService} from '../../../../../services/bus-event.service';

/**
 * Component renders 3D scene with point cloud.
 * Used for Tracker app.
 */
@Component({
	selector: 'app-point-cloud-3d',
	templateUrl: './point-cloud-3d.component.html',
	styleUrls: ['./point-cloud-3d.component.scss']
})
export class PointCloud3dComponent extends ThreeJSLayerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

	@Input() isRaw = false;
	@Input() isSmartTailor = false;
	@Input() data: any = {};
	@Input() parameters = {};
	@Input() connectedSensorsSettings = [];
	@Input() sensorSocket: SensorSocket;
	@Input() isSensorVisible = true;
	@Input() isMultipleSensors = false;

	@ViewChild('div', {static: true}) div: ElementRef;

	localData: any = {};
	pixDensity: PointCloudStageBase;

	isDisplayCenterTarget = false;
	isDisplayPlaneShadow = false;
	isDisplayFloorShadow = false;

	private UIDs: Array<string> = [];

	constructor(private busEventService: BusEventService) {
		super();
	}

	ngOnInit() {
		if (!this.isRaw) {
			this.isDisplayCenterTarget = true;
		}
		let arena = this.getArena();

		if (this.isSmartTailor) {
			this.pixDensity = new SmartTailorStage(this.div.nativeElement, arena, this.sensorSocket.connectionStatus);
		} else {
			this.pixDensity = new PointCloudStageBase(this.div.nativeElement, arena, this.sensorSocket.connectionStatus, this.parameters);
		}
		this.pixDensity.updateSensor(this.isMultipleSensors ? this.connectedSensorsSettings : this.parameters);
		if (this.sensorSocket.connectionStatus !== 'IMAGING') {
			let data = (this.isSmartTailor ? this.localData.pcd : this.localData.data) || [];
			if (data) {
				this.pixDensity.updateOnInit(
					data,
					this.isRaw ? null : this.localData.targetCenterData,
					arena,
					this.isDisplayPlaneShadow,
					!this.isRaw && this.isDisplayCenterTarget,
					this.isDisplayFloorShadow,
					this.isSensorVisible
				); // Update data
			}
		}
		this.sensorStatusSubscription = this.sensorSocket.status.subscribe(status => {
			this.pixDensity.updateStatus(status);
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.onResize();
		});
	}

	ngOnChanges(c: SimpleChanges) {
		if (this.pixDensity) {
			if ('data' in c) {
				if (this.isMultipleSensors) {
					this.localData = this.concatCombinedData(this.data);
				} else {
					this.localData = this.data;
				}
			}
			let arena = this.getArena();
			if ('parameters' in c || 'connectedSensorsSettings' in c) {
				this.pixDensity.updateScene(arena, this.isMultipleSensors ? this.connectedSensorsSettings : this.parameters);
			}
			if (this.sensorSocket.connectionStatus === ConnectionStatus.IMAGING) {
				this.updatePixDensity(arena, this.sensorSocket.connectionStatus);
			} else {
				let data = (this.isSmartTailor ? this.localData.pcd : this.localData.data) || [];
				if (data && data instanceof Array) {
					this.pixDensity.updateOnInit(
						data,
						this.isRaw ? null : this.localData.targetCenterData,
						arena,
						this.isDisplayPlaneShadow,
						!this.isRaw && this.isDisplayCenterTarget,
						this.isDisplayFloorShadow,
						this.isSensorVisible
					); // Update data
				} else {
					this.updatePixDensity(arena, this.sensorSocket.connectionStatus);
				}
			}
		}
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event?: Event) {
		if (this.pixDensity) {
			this.pixDensity.onResize();
		}
	}

	toggleTargetCenter() {
		this.isDisplayCenterTarget = !this.isDisplayCenterTarget;
	}

	togglePlaneShadow() {
		this.isDisplayPlaneShadow = !this.isDisplayPlaneShadow;
	}

	toggleFloorShadow() {
		this.isDisplayFloorShadow = !this.isDisplayFloorShadow;
	}

	private updatePixDensity(arena, status) {
		let data = (this.isSmartTailor ? this.localData.pcd : this.localData.data) || [];
		this.pixDensity.update(
			data,
			this.isRaw ? null : this.localData.targetCenterData,
			arena,
			status,
			this.isDisplayPlaneShadow,
			!this.isRaw && this.isDisplayCenterTarget,
			this.isDisplayFloorShadow,
			this.isSensorVisible
		);
	}

	private concatCombinedData(aggregatedData: any = {}) {
		let ret = {};
		Object.keys(aggregatedData).forEach(outputName => {
			// Handle cases where outputData is actually a single point.
			// Meaning it's comiong as a one-dimensional array.
			// In this case wrap it with another array.
			aggregatedData[outputName].forEach((outputData, i) => {
				if (!(outputData[0] instanceof Array) && outputData.length) {
					aggregatedData[outputName][i] = [outputData];
				}
			});
		});
		Object.keys(aggregatedData).forEach(outputName => {
			aggregatedData[outputName].forEach(outputData => {
				outputData.forEach(point => {
					if (typeof point === 'object') {
						point.index = outputData.index + 1;
						/**
						 * Original point structure is:
						 *	0, 1, 2 - x, y, z coordinates
						 *	3 - target ID
						 *  4 till the end - char codes that make up the string UID
						 *  ---
						 * Generate UID from char codes
						 * Add numeric unique target ID
						 * So point values is going to be:
						 * 0, 1, 2 - x, y, z coordinates
						 * 3 - connection index + target ID
						 * 4 - numeric UID
						 */
						point[3] = point.index * 1000 + point[3];
						let UID = generateUIDFromCharCodes(point.slice(4));
						if (!this.UIDs.includes(UID)) {
							this.UIDs.push(UID);
						}
						point[4] = this.UIDs.indexOf(UID) + 1;
					}
				});
			});
			ret[outputName] = [].concat(...aggregatedData[outputName]);
		});

		return ret;
	}
}
