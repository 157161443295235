import * as THREE from 'three';
import {DEFAULT_POINT_SIZE, PointCloudStageBase} from './PointCloudStageBase';
import {HEATMAP_COLORS, HEATMAP_RESOLUTION, makeGetColor} from '../utils/ImageUtils';
import {ic} from '../utils/ColorTablesUtils';

/**
 * Base class for InCar app.
 */
export class PointCloudStage_InCarBase extends PointCloudStageBase {

	isInitializedWithRealArenaSettings = false;
	cabinPlaneColor;
	inCarCabinPlanes;
	inCarCabinVertexs;
	car;
	isCarUpdatedOnImaging;
	isCabinUpdatedOnImaging;
	isCabinUpdated;
	isCarAndCabinUpdated;
	isCeilingEnabled = false;
	carSeatsIndexes;

	constructor(div, arena, status, parameters, shouldUpdateArenaPlanesAccordingToPOV, isRaw, cabinPlaneColor, protected pointSize = DEFAULT_POINT_SIZE) {
		super(div, arena, status, parameters, shouldUpdateArenaPlanesAccordingToPOV, undefined, pointSize);
		this.status = status;
		this.isRaw = isRaw;
		this.cabinPlaneColor = cabinPlaneColor;
		this.createCabinPlanes(this.PLANE_GEOMETRY);

		let colors = HEATMAP_COLORS;
		this.getColor = makeGetColor(
			this.targetCenterColor,
			value => {
				let index = value === 1 ? colors.length - 1 : Math.floor(value * HEATMAP_RESOLUTION);
				if (index >= colors.length) {
					index = colors.length - 1;
				}
				return colors[index];
			},
			ic
		);
	}

	init(arena, status) {
		super.init(arena, status);
		this.mainGroup.remove(this.arenaPlanes[6]);
	}

	/**
	 * This function is the main function of this class.
	 * It updates the point cloud in the arena according to data given from Matlab.
	 * We're giving the user the control over showing shadows and targets` centers
	 */
	update(data, arena, status, inCarCabinVertexs, frontMostOfRear_rearMostOfFront, isRaw, isDisplaySensor) {
	}

	updateCabinOnImaging(arena, frontMostOfRear_rearMostOfFront, inCarCabinVertexs) {
	}

	initCamera() {
	}

	updateInCarCabin(frontMostOfRear_rearMostOfFront, InCarCabinVertexs) {
	}

	getCabinDefaultDims(liftAboveGround): any {
	}

	updateDefaultCabinAccordingToArena(liftAboveGround) {
	}

	updateArenaPlanesAccordingToPOV(arena) {
		if (this.shouldUpdateArenaPlanesAccordingToPOV) {
			if (this.isInitializedWithRealArenaSettings) {
				if (!this.mainGroup.children.includes(this.arenaPlanes[5])) {
					this.mainGroup.add(this.arenaPlanes[5]);
				}
				super.updateArenaPlanesAccordingToPOV(arena);
			}
		}
	}

	updateSceneWithRealArenaSettings(parameters, arena) {
		let mainArena = arena,
			mainParameters = parameters,
			isMultipleSensors = arena[0] instanceof Array;

		if (isMultipleSensors) {
			mainArena = arena[0];
		}
		if (parameters instanceof Array) {
			this.connectedSensorsParameters = parameters as any;
			mainParameters = parameters[0];
		}
		this.oldArena = this.arena;
		this.arena = mainArena;
		this.parameters = mainParameters;
		this.isInitializedWithRealArenaSettings = true;
		this.setScale(mainArena);
		this.updateArenaPlanesAccordingToPOV(mainArena);
	}

	// Build InCar planes representing the car seats
	createCabinPlanes(planeGeometry) {
		let carPlaneMaterial = new THREE.MeshStandardMaterial({
			color: this.cabinPlaneColor,
			roughness: 0.05,
			transparent: true,
			opacity: 0.5,
		});
		// Outer
		let inCarCabinFrontPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);
		let inCarCabinBackPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);
		let inCarCabinLeftPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);
		let inCarCabinRightPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);
		// Inner
		let x_midFrontPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);
		let x_midBackPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);
		let z_frontSeatMidPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);
		let z_backSeatMidLeftPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);
		let z_backSeatMidRightPlane = new THREE.Mesh(planeGeometry, carPlaneMaterial);

		this.inCarCabinPlanes = {
			inCarCabinFrontPlane,
			inCarCabinBackPlane,
			inCarCabinLeftPlane,
			inCarCabinRightPlane,
			x_midFrontPlane,
			x_midBackPlane,
			z_frontSeatMidPlane,
			z_backSeatMidLeftPlane,
			z_backSeatMidRightPlane,
		};
		this.mainGroup.add(
			x_midFrontPlane,
			x_midBackPlane,
			z_frontSeatMidPlane,
			z_backSeatMidLeftPlane,
			z_backSeatMidRightPlane);
	}

	updateCabinInnerPlanes(frontMostOfRear_rearMostOfFront, InCarCabinVertexs) {
		const frontMostOfRear = frontMostOfRear_rearMostOfFront[0] * this.SCALING_FACTOR;
		const rearMostOfFront = frontMostOfRear_rearMostOfFront[1] * this.SCALING_FACTOR;
		const {
			x_midFrontPlane,
			x_midBackPlane,
			z_frontSeatMidPlane,
			z_backSeatMidLeftPlane,
			z_backSeatMidRightPlane,
		} = this.inCarCabinPlanes;
		const {aboveGround, inCarPlaneHeight, thickness} = this.getCabinDefaultDims(
			this.getLiftAboveGround(this.arena)
		);
		const z_marginMidFrontPlane = rearMostOfFront;
		const z_marginMidBackPlane = frontMostOfRear;

		const leftBottom = InCarCabinVertexs[0];
		const rightBottom = InCarCabinVertexs[1];
		const rightTop = InCarCabinVertexs[2];
		// const leftTop = InCarCabinVertexs[3]

		const inCarCabinFrontAndBackPlaneWidth = (rightBottom[0] - leftBottom[0]) * this.SCALING_FACTOR;
		const inCarCabinRightAndLeftPlaneWidth = (rightTop[1] - rightBottom[1]) * this.SCALING_FACTOR;
		// Inner planes
		x_midFrontPlane.position.set(0, aboveGround, -z_marginMidFrontPlane);
		x_midFrontPlane.scale.set(inCarCabinFrontAndBackPlaneWidth, inCarPlaneHeight, thickness);

		x_midBackPlane.position.set(0, aboveGround, z_marginMidBackPlane);
		x_midBackPlane.scale.set(inCarCabinFrontAndBackPlaneWidth, inCarPlaneHeight, thickness);

		z_frontSeatMidPlane.position.set(
			0,
			aboveGround,
			-inCarCabinRightAndLeftPlaneWidth / 4 - z_marginMidFrontPlane / 2
		);
		z_frontSeatMidPlane.scale.set(
			thickness,
			inCarPlaneHeight,
			inCarCabinRightAndLeftPlaneWidth / 2 - z_marginMidFrontPlane
		);

		const backSeatXPos = inCarCabinFrontAndBackPlaneWidth / 3;
		z_backSeatMidLeftPlane.position.set(
			backSeatXPos / 2,
			aboveGround,
			inCarCabinRightAndLeftPlaneWidth / 4 + z_marginMidBackPlane / 2
		);
		z_backSeatMidLeftPlane.scale.set(
			thickness,
			inCarPlaneHeight,
			inCarCabinRightAndLeftPlaneWidth / 2 - z_marginMidBackPlane
		);

		z_backSeatMidRightPlane.position.set(
			-backSeatXPos / 2,
			aboveGround,
			inCarCabinRightAndLeftPlaneWidth / 4 + z_marginMidBackPlane / 2
		);
		z_backSeatMidRightPlane.scale.set(
			thickness,
			inCarPlaneHeight,
			inCarCabinRightAndLeftPlaneWidth / 2 - z_marginMidBackPlane
		);
	}

	updateDefaultCabinInner(inCarCabinFrontAndBackPlaneWidth,
							inCarCabinRightAndLeftPlaneWidth,
							aboveGround,
							inCarPlaneHeight,
							thickness) {
		let {
			x_midFrontPlane,
			x_midBackPlane,
			z_frontSeatMidPlane,
			z_backSeatMidLeftPlane,
			z_backSeatMidRightPlane,
		} = this.inCarCabinPlanes;
		const z_marginMidFrontPlane = 10; // Should come from Matlab
		const z_marginMidBackPlane = 10; // Should come from Matlab

		// Inner planes
		x_midFrontPlane.position.set(0, aboveGround, -z_marginMidFrontPlane);
		x_midFrontPlane.scale.set(inCarCabinFrontAndBackPlaneWidth, inCarPlaneHeight, thickness);

		x_midBackPlane.position.set(0, aboveGround, z_marginMidBackPlane);
		x_midBackPlane.scale.set(inCarCabinFrontAndBackPlaneWidth, inCarPlaneHeight, thickness);

		z_frontSeatMidPlane.position.set(
			0,
			aboveGround,
			-inCarCabinRightAndLeftPlaneWidth / 4 - z_marginMidFrontPlane / 2
		);
		z_frontSeatMidPlane.scale.set(
			thickness,
			inCarPlaneHeight,
			inCarCabinRightAndLeftPlaneWidth / 2 - z_marginMidFrontPlane
		);

		const backSeatXPos = inCarCabinFrontAndBackPlaneWidth / 3;
		z_backSeatMidLeftPlane.position.set(
			backSeatXPos / 2,
			aboveGround,
			inCarCabinRightAndLeftPlaneWidth / 4 + z_marginMidBackPlane / 2
		);
		z_backSeatMidLeftPlane.scale.set(
			thickness,
			inCarPlaneHeight,
			inCarCabinRightAndLeftPlaneWidth / 2 - z_marginMidBackPlane
		);

		z_backSeatMidRightPlane.position.set(
			-backSeatXPos / 2,
			aboveGround,
			inCarCabinRightAndLeftPlaneWidth / 4 + z_marginMidBackPlane / 2
		);
		z_backSeatMidRightPlane.scale.set(
			thickness,
			inCarPlaneHeight,
			inCarCabinRightAndLeftPlaneWidth / 2 - z_marginMidBackPlane
		);
	}

	/**
	 * Add car seats indexes on the floor plane
	 *  */

	addCarSeatsIndexes(inCarCabinFrontAndBackPlaneWidth, inCarCabinRightAndLeftPlaneWidth, defaultMargin) {
		let canvas = document.createElement('canvas');
		canvas.width = this.scale[0];
		canvas.height = this.scale[2];
		let ctx = canvas.getContext('2d', {alpha: true})!;
		ctx.fillStyle = this.getFloorColor(true);
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.font = '35pt Arial';
		ctx.fillStyle = 'red';
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';

		const x1 = inCarCabinFrontAndBackPlaneWidth / 4 + defaultMargin;
		const yFrontRow = inCarCabinRightAndLeftPlaneWidth / 4 + defaultMargin;
		const x2 = inCarCabinFrontAndBackPlaneWidth / 2 + inCarCabinFrontAndBackPlaneWidth / 4 + defaultMargin;
		const yBackRow = inCarCabinRightAndLeftPlaneWidth / 2 + inCarCabinRightAndLeftPlaneWidth / 4 + defaultMargin;
		const x3 = inCarCabinFrontAndBackPlaneWidth / 3 / 2 + defaultMargin;
		const x4 = inCarCabinFrontAndBackPlaneWidth / 3 + inCarCabinFrontAndBackPlaneWidth / 3 / 2 + defaultMargin;
		const x5 = 2 * (inCarCabinFrontAndBackPlaneWidth / 3) + inCarCabinFrontAndBackPlaneWidth / 3 / 2 + defaultMargin;
		ctx.font = '20pt Arial';
		ctx.fillText('1', x1, yFrontRow);
		ctx.fillText('2', x2, yFrontRow);
		ctx.fillText('3', x3, yBackRow);
		ctx.fillText('4', x4, yBackRow);
		ctx.fillText('5', x5, yBackRow);

		let texture = new THREE.Texture(canvas);
		var textPlaneMaterial = new THREE.MeshBasicMaterial({map: texture});

		let textPlaneGeometry = new THREE.BoxGeometry(this.scale[0], 0.1, this.scale[2]);
		let carSeatsIndexes = new THREE.Mesh(textPlaneGeometry, textPlaneMaterial);
		carSeatsIndexes.position.set(0, this.FLOOR_Y_POSITION, 0);
		texture.needsUpdate = true;
		this.carSeatsIndexes = carSeatsIndexes;
		this.mainGroup.add(carSeatsIndexes);
	}

	/**
	 * Update car seats indexes according to dimensions coming from Matlab while IMAGING
	 *  */

	updateCarSeatsIndexes(InCarCabinVertexs) {
		let canvas = document.createElement('canvas');
		canvas.width = this.scale[0];
		canvas.height = this.scale[2];
		let ctx = canvas.getContext('2d', {alpha: true})!;
		ctx.fillStyle = this.getFloorColor(true);
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.font = '35pt Arial';
		ctx.fillStyle = 'red';
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';

		const leftBottom = InCarCabinVertexs[0];
		const rightBottom = InCarCabinVertexs[1];
		const rightTop = InCarCabinVertexs[2];
		const leftTop = InCarCabinVertexs[3];

		const inCarCabinFrontAndBackPlaneWidth = (rightBottom[0] - leftBottom[0]) * this.SCALING_FACTOR;
		const inCarCabinRightAndLeftPlaneWidth = (rightTop[1] - rightBottom[1]) * this.SCALING_FACTOR;
		const x_distFromArenaLeft = this.scale[0] / 2 - Math.abs(leftTop[0]) * this.SCALING_FACTOR;
		const y_distFromArenaTop = this.scale[1] / 2 - Math.abs(leftTop[1]) * this.SCALING_FACTOR;

		const x1 = x_distFromArenaLeft + inCarCabinFrontAndBackPlaneWidth * (1 / 4);
		const yFrontRow = y_distFromArenaTop + inCarCabinRightAndLeftPlaneWidth * (1 / 4);
		const x2 = x_distFromArenaLeft + inCarCabinFrontAndBackPlaneWidth * (3 / 4);
		const yBackRow = y_distFromArenaTop + inCarCabinRightAndLeftPlaneWidth * (3 / 4);
		const x3 = x_distFromArenaLeft + inCarCabinFrontAndBackPlaneWidth * (1 / 3) * (1 / 2);
		const x4 =
			x_distFromArenaLeft +
			inCarCabinFrontAndBackPlaneWidth * (1 / 3) +
			inCarCabinFrontAndBackPlaneWidth * (1 / 3) * (1 / 2);
		const x5 =
			x_distFromArenaLeft +
			2 * inCarCabinFrontAndBackPlaneWidth * (1 / 3) +
			inCarCabinFrontAndBackPlaneWidth * (1 / 3) * (1 / 2);
		ctx.fillText('1', x1, yFrontRow);
		ctx.fillText('2', x2, yFrontRow);
		ctx.font = '20pt Arial';
		ctx.fillText('3', x3, yBackRow);
		ctx.fillText('4', x4, yBackRow);
		ctx.fillText('5', x5, yBackRow);

		let texture = new THREE.Texture(canvas);
		var textPlaneMaterial = new THREE.MeshBasicMaterial({map: texture});
		let textPlaneGeometry = new THREE.BoxGeometry(this.scale[0], 0.1, this.scale[2]);
		let carSeatsIndexes = new THREE.Mesh(textPlaneGeometry, textPlaneMaterial);
		carSeatsIndexes.position.set(0, this.FLOOR_Y_POSITION, 0);
		texture.needsUpdate = true;
		// remove old indexes
		this.mainGroup.remove(this.carSeatsIndexes);
		this.mainGroup.add(carSeatsIndexes);
	}
}
