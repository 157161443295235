import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import * as L from 'leaflet';
import {Subscription} from 'rxjs';
import {axesSystem2D, rotateLeafletGeometry} from '../../../../../utils/utils';
import {colorscale, makeGetColor, TARGET_CENTER_COLOR} from '../../../../../utils/ImageUtils';
import {ic, updateTargetColorAllocationDataStructures} from '../../../../../utils/ColorTablesUtils';
import {ModalService} from '../../../../../services/modal.service';

require('../../../../../../assets/js/leaflet.latlng-graticule');

@Component({
	selector: 'app-pose-landmark2d',
	templateUrl: './pose-landmark2d.component.html',
	styleUrls: ['./pose-landmark2d.component.scss']
})
export class PoseLandmark2dComponent implements AfterViewInit, OnChanges, OnDestroy {

	@Input() data: any = {};
	@Input() showGrid = true;
	@Input() rotateValue = 0;
	@Input() parameters: any = {};
	@Input() reflect = false;
	@Input() staticDataDict: any = {};

	@ViewChild('map') mapElement: ElementRef;

	displayAxes = 0;

	private map: L.Map;
	private grid: L.LatLngGraticule;
	private sides = ['-', '', '-', ''];
	private subscriptions: Array<Subscription> = [];
	private isDestroyed = false;
	private currentArenas: Array<L.Rectangle> = [];
	private polyLines: Array<L.Polyline> = [];
	private circles: L.FeatureGroup;
	private rectangleVertices: L.FeatureGroup;
	private edges = [
		[11, 12], [11, 13], [13, 15], [12, 14], [14, 16], [12, 24], [24, 23], [11, 23], [23, 25], [24, 26], [25, 27], [26, 28]
	];
	private coordinatesMap = [0, 11, 12, 13, 14, 15, 16, 23, 24, 25, 26, 27, 28];

	private getColor = (function (colorGetter) {
		return (...a) => {
			// @ts-ignore
			let color = colorGetter(...a);
			return typeof color === 'string' ? color : `rgb(${color.r}, ${color.g}, ${color.b})`;
		};
	}(makeGetColor(TARGET_CENTER_COLOR, colorscale, ic)));

	constructor(private ngZone: NgZone,
				private ref: ChangeDetectorRef,
				private modalService: ModalService) {
	}

	async ngAfterViewInit() {
		await Promise.resolve();
		this.ngZone.runOutsideAngular(() => {
			this.map = L.map(this.mapElement.nativeElement, {
				attributionControl: false,
				zoom: 1,
				center: [0, 0],
				minZoom: -1,
				crs: L.CRS.Simple,
				zoomSnap: 0.1,
				zoomControl: false
			} as any);
			setTimeout(() => {
				this.grid = L.latlngGraticule({
					showLabel: true,
					dashArray: [2, 2],
					sides: this.sides
				});
				this.updateGrid();
				this.updateRectangles();
			});
		});
	}

	ngOnChanges(c: SimpleChanges) {
		if ('parameters' in c || 'connectedSensorsSettings' in c || 'rotateValue' in c || 'reflect' in c) {
			setTimeout(() => {
				if (!this.isDestroyed) {
					if ('rotateValue' in c) {
						this.updateGridSides();
					}
					this.updateArenas();
					this.ref.detectChanges();
				}
			});
		}
		if ('data' in c) {
			this.updateRectangles(false);
			this.updateTargetColorAllocationDataStructures(this.data.targetsIdData);
			this.updateData();
		}
		if ('showGrid' in c) {
			this.updateGrid();
		}
		if ('rotateValue' in c) {
			this.updateRectangles(false);
		}
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	private updateGrid() {
		if (this.map) {
			if (this.showGrid && !this.map.hasLayer(this.grid)) {
				this.map.addLayer(this.grid);
			} else if (!this.showGrid) {
				this.map.removeLayer(this.grid);
			}
		}
	}

	private updateGridSides() {
		if (this.grid) {
			let sides;
			switch (this.rotateValue) {
				case 0:
					sides = ['-', '', '-', ''];
					break;
				case 1:
					sides = ['-', '', '', '-'];
					break;
				case 2:
					sides = ['', '-', '', '-'];
					break;
				case 3:
					sides = ['', '-', '-', ''];
					break;
			}
			// @ts-ignore
			this.grid.options.sides = sides;
		}
	}

	private updateArenas() {
		this.ngZone.runOutsideAngular(() => {
			this.removeOldArenas();
			this.addNewArenas();
		});
	}

	private removeOldArenas() {
		this.currentArenas.forEach(arena => {
			this.map.removeLayer(arena);
		});
	}

	private addNewArenas() {
		if (this.map) {
			let rectangleOptions = {
					color: 'rgb(59, 177, 205)',
					opacity: .2,
					stroke: false
				},
				bounds;
			bounds = rotateLeafletGeometry(
				this.getRotationDegree(),
				this.getArenaGeometry()
			);
			let leafletArena = L.rectangle(bounds, rectangleOptions);
			this.currentArenas.push(leafletArena);
			this.map.addLayer(leafletArena);

			this.fitBounds();
		}
	}

	private getRotationDegree() {
		let rotationDegree = 0;

		switch (this.rotateValue) {
			case 3:
				rotationDegree = 90;
				break;
			case 2:
				rotationDegree = 180;
				break;
			case 1:
				rotationDegree = 270;
				break;
		}

		return rotationDegree;
	}

	private getArenaGeometry() {
		let arena = this.getArena();

		return [
			[-arena[1], -arena[3]],
			[-arena[0], -arena[2]]
		];
	}

	private getArena() {
		let arena = this.parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'];

		return arena;
	}

	updateData() {
		if (this.map) {
			this.polyLines.forEach(polyLine => {
				this.map.removeLayer(polyLine);
			});
			if (this.circles) {
				this.map.removeLayer(this.circles);
			}
			let axes = this.getAxes();
			this.data.joints.forEach((person, personIndex) => {
				let latlngs = this.edges.map(edge => {
					return edge.map(vertex => {
						let index = this.coordinatesMap.indexOf(vertex);
						return person[index];
					});
				});
				let color = this.getColor(false, this.data.targetsIdData[personIndex]),
					polyLine = L.polyline(latlngs.map(l => l.map(k => this.getPosition(k[1], k[0], 0, axes))) as any, {color});
				this.polyLines.push(polyLine);
				this.map.addLayer(polyLine);
			});
			this.circles = L.featureGroup();
			this.data.joints.forEach((person, personIndex) => {
				let color = this.getColor(false, this.data.targetsIdData[personIndex]);
				person.forEach((o, i) => {
					let circle = L.circle(this.getPosition(o[1], o[0], 0, axes) as any, {radius: 0.05, color});
					this.circles.addLayer(circle);
				});
			});
			this.map.addLayer(this.circles);
		}
	}

	private fitBounds() {
		let group = L.featureGroup(this.currentArenas);

		this.map.fitBounds(group.getBounds());
	}

	getPosition(x0, y0, z0, axes, fromEVK = true) {
		if (x0 === 'NaN') {
			return [0, 0];
		}
		let x, y;
		if (fromEVK) {
			[x, y] = [[x0, y0, z0][axes[0]], [x0, y0, z0][axes[1]]];
		} else {
			[x, y] = [x0, y0];
		}

		let _x = x;
		let _y = -y;

		if ([axesSystem2D.xy, axesSystem2D.xz].includes(this.displayAxes)) {
			_x = -_x;
		}

		if (this.shouldInvertXAxis()) {
			_x = 1 - _x;
		}

		[_x, _y] = [_x, _y].map(v => isFinite(v) ? v : 0);

		return [_x, _y];
	}

	/**
	 * By default, x axis goes from right to left (from the user point of view)
	 * "Invert" means to change x axis to start from left to right
	 */
	private shouldInvertXAxis() {
		return this.reflect;
	}

	getAxes() {
		let axes;

		switch (this.displayAxes) {
			case axesSystem2D.xy:
				axes = [0, 1];
				break;
			case axesSystem2D.xz:
				axes = [0, 2];
				break;
			case axesSystem2D.yz:
				axes = [1, 2];
				break;
		}

		return axes;
	}

	private updateRectangles(showErrorMessage = true) {
		if (this.rectangleVertices) {
			this.map.removeLayer(this.rectangleVertices);
		}
		if (this.map && this.staticDataDict.rectangleVertices) {
			let rectangleVertices = L.featureGroup();
			this.staticDataDict.rectangleVertices.forEach((rectangle, i) => {
				if (rectangle[0] < rectangle[2] || rectangle[1] < rectangle[3]) {
					if (showErrorMessage) {
						this.ngZone.run(() => {
							this.modalService.showError(`Zone with coordinates: [${rectangle.join(', ')}] is invalid.\n Check that coordinates provided in following format: [zone1leftBottomX, zone1leftBottomY, zone1topRightX, zone1topRightY]`);
						});
					}
					return;
				}
				let color = this.data.joints?.some((vertices) => {
						return vertices.some(vertex => {
							return rectangle[0] > vertex[0] && vertex[0] > rectangle[2] &&
									rectangle[1] > vertex[1] && vertex[1] > rectangle[3];
						});
					}) ? 'rgba(255, 0, 0, .8)' : 'rgba(59, 177, 205, .8)',
					rectangleOptions = {
						color: color,
						opacity: .6,
						stroke: true
					},
					bounds = rotateLeafletGeometry(
						this.getRotationDegree(),
						this.getRectanglePoints(i)
					),
					rectangleLayer = L.rectangle(bounds, rectangleOptions);

				rectangleVertices.addLayer(rectangleLayer);
			});
			this.rectangleVertices = rectangleVertices;
			this.map.addLayer(rectangleVertices);
		}
	}

	private getRectanglePoints(index) {
		return [
			[-this.staticDataDict.rectangleVertices[index][0], -this.staticDataDict.rectangleVertices[index][1]],
			[-this.staticDataDict.rectangleVertices[index][2], -this.staticDataDict.rectangleVertices[index][3]]
		];
	}

	private updateTargetColorAllocationDataStructures(data: any[] = []) {
		updateTargetColorAllocationDataStructures(data, target => target);
	}
}
