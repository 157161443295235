import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {SensorSocket} from '../../../../../services/system/sensor-socket';
import {ModalService} from '../../../../../services/modal.service';
import {checkInCarAggregatedData, transformArena} from '../../../../../utils/utils';
import {PointCloudStage_SSR_Car} from './PointCloudStage_SSR_Car';
import {environment} from '../../../../../../environments/environment';

/**
 * Component render point cloud outside of the car.
 * PointCloudStage_SSR_Car class used as renderer.
 */
@Component({
	selector: 'app-srr-cloud-point',
	templateUrl: './srr-cloud-point.component.html',
	styleUrls: ['./srr-cloud-point.component.scss']
})
export class SrrCloudPointComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

	@Input() isRaw = false;
	@Input() data: any = {};
	@Input() parameters = {};
	@Input() sensorSocket: SensorSocket;
	@Input() isSensorVisible = true;
	@Input() isMultipleSensors = false;
	@Input() connectedSensorsSettings = [];
	@ViewChild('div', {static: true}) div: ElementRef;
	localData: any = {};
	pixDensity;
	loadingPopup;

	private sensorStatusSubscription;
	private isDestroyed = false;

	constructor(private modalService: ModalService) {
	}

	ngOnInit() {
		setTimeout(() => {
			if (!this.isDestroyed) {
				this.loadingPopup = this.modalService.showLoadingPopup('Loading car model');
				setTimeout(() => {
					if (!this.isDestroyed) {
						let arena = this.getArena();
						if (this.isMultipleSensors) {
							arena = arena[0];
						}
						this.pixDensity = new PointCloudStage_SSR_Car(this.div.nativeElement, arena, this.sensorSocket.connectionStatus, this.parameters, this.isRaw);
						this.pixDensity.updateSensor(this.isMultipleSensors ? this.connectedSensorsSettings : this.parameters);
						setTimeout(() => {
							this.loadingPopup.close();
						});
						if (this.sensorSocket.connectionStatus !== 'IMAGING') {
							if (this.localData.data) {
								this.pixDensity.updateSceneWithRealArenaSettings(this.parameters, arena);
								this.pixDensity.updateOnInit(
									this.localData.data,
									arena,
									this.localData.InCarCabinVertexs,
									this.localData.frontMostOfRear_rearMostOfFront,
									this.isRaw,
									this.isSensorVisible
								); // Update data
							}
						}
						this.sensorStatusSubscription = this.sensorSocket.status.subscribe(status => {
							this.pixDensity.updateStatus(status);
						});
					} else {
						setTimeout(() => {
							this.loadingPopup.close();
						});
					}
				}, 200);
			}
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.onResize();
		});
	}

	ngOnChanges(c: SimpleChanges) {
		if (this.pixDensity) {
			let arena = this.getArena();
			if ('data' in c) {
				if (this.isMultipleSensors) {
					if (Object.keys(this.data).length) {
						let {valid, message} = checkInCarAggregatedData(this.data, environment.shouldValidateUniqueMonitoredSeats);
						if (valid) {
							this.localData = this.concatCombinedData(this.data);
						} else {
							this.sensorSocket.stop();
							this.modalService.showError(`Check that ${message}`);
						}
					} else {
						this.localData = {};
						this.pixDensity.updatePoints([]);
					}
				} else {
					this.localData = this.data;
				}
			}
			if ('parameters' in c || 'connectedSensorsSettings' in c) {
				this.pixDensity.updateSceneWithRealArenaSettings(this.isMultipleSensors ? this.connectedSensorsSettings : this.parameters, arena);
			}

			if ('data' in c || 'isSensorVisible' in c) {
				this.updatePixDensity(arena, this.sensorSocket.connectionStatus);
			}
			if ('isRaw' in c) {
				this.updateData();
			}
		}
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		if (this.pixDensity) {
			this.pixDensity.cleanup();
		}
		if (this.sensorStatusSubscription) {
			this.sensorStatusSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event?: Event) {
		if (this.pixDensity) {
			this.pixDensity.onResize();
		}
	}

	private updateData() {
		this.pixDensity.updateData(this.localData.data, this.isRaw);
	}

	private updatePixDensity(arena, status) {
		this.pixDensity.update(
			this.localData.data,
			arena,
			status,
			this.localData.InCarCabinVertexs,
			this.localData.frontMostOfRear_rearMostOfFront,
			this.isRaw,
			this.isSensorVisible
		);
	}

	private getArena() {
		let cb = (parameters) => {
			let arena: any = transformArena(
				parameters['sensorParameters']['ProcessorCfg.ExternalGUI.displayedArena'] ?? parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'],
				parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.userToWebGUITransMat']
			);
			arena.sensorPlane = parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'];

			return arena;
		};

		if (this.isMultipleSensors) {
			return this.connectedSensorsSettings.map(parameters => cb(parameters));
		} else {
			return cb(this.parameters);
		}
	}

	private concatCombinedData(aggregatedData: any = {}) {
		let ret = {};
		Object.keys(aggregatedData).forEach(outputName => {
			switch (outputName) {
				case 'data':
					if (aggregatedData[outputName].length && aggregatedData[outputName][0].length) {
						aggregatedData[outputName].forEach((outputData, i) => {
							if (!(outputData[0] instanceof Array)) {
								aggregatedData[outputName][i] = [outputData];
							}
						});
						ret[outputName] = [].concat(...aggregatedData[outputName]);
					}
					break;
				default:
					ret[outputName] = aggregatedData[outputName][0];
					break;
			}
		});

		return ret;
	}
}
