<div class="buttons">
	<ng-content></ng-content>
</div>
<ng-container *ngIf="aggregatedData?.roomId?.length">
	<div *ngFor="let roomId of aggregatedData?.roomId; let i = index;" class="room">
		<span class="title">Room #{{roomId}}</span>
		<app-number-of-people [data]="{data: aggregatedData?.roomNumOfPeople[i]}"></app-number-of-people>
	</div>
</ng-container>

<ng-container *ngIf="!aggregatedData?.roomId?.length">
	<app-number-of-people [data]="{data: 0}"></app-number-of-people>
</ng-container>
