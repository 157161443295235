import {Injector, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../modules/material/material.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SharedModule} from '../../modules/shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import {MODULE_PATH} from '../../app.module';
import {BackendConnectorEvkComponent} from '../../components/shared/backend-connector/backend-connector-evk/backend-connector-evk.component';
import {environment} from '../../../environments/environment';
import {ToolbarEvkComponent} from '../../components/shared/toolbars/toolbar-evk/toolbar-evk.component';
import {PageGuard} from '../../guards/page.guard';
import {SmartHomeComponent} from './components/base-view/smart-home.component';
import {SmartHomeDashboardComponent} from './components/layers/smart-home-dashboard/smart-home-dashboard.component';
import {SmartHomeAcComponent} from './components/layers/smart-home-ac/smart-home-ac.component';
import {LayersContainerComponent} from './components/layers/layers-container.component';
import {RoomOccupancyComponent} from './components/base-view/room-occupancy/room-occupancy.component';
import {RoomOccupancyLayerComponent} from './components/layers/room-occupancy/room-occupancy.component';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../../../assets/i18n/${lang}.json`));
	}
}

export let InjectorInstance: Injector;

const routes: Routes = [
	{
		path: '',
		component: SmartHomeComponent,
		canActivate: [PageGuard],
		data: {
			isSmartHome: true,
			toolbar: ToolbarEvkComponent,
			sensorConnector: BackendConnectorEvkComponent
		}
	}, {
		path: 'room-occupancy',
		component: RoomOccupancyComponent,
		canActivate: [PageGuard],
		data: {
			isRoomOccupancy: true,
			toolbar: ToolbarEvkComponent
		}
	}, {
		path: '**',
		pathMatch: 'full',
		redirectTo: ''
	}
];

@NgModule({
	declarations: [
		SmartHomeComponent,
		LayersContainerComponent,
		SmartHomeDashboardComponent,
		SmartHomeAcComponent,
		RoomOccupancyComponent,
		RoomOccupancyLayerComponent,
	],
	providers: [
		{
			provide: MODULE_PATH,
			useValue: environment.SMART_HOME_MODULE_PATH
		},
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		MaterialModule,
		PerfectScrollbarModule,
		SharedModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		})
	]
})
export class SmartHomeModule {
	constructor(private injector: Injector) {
		InjectorInstance = this.injector;
	}
}
