import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-toolbar-button',
	templateUrl: './toolbar-button.component.html',
	styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent implements OnInit {

	@Input() disabled: boolean;
	@Input() icon: string;
	@Input() label: string;
	@Input() dataSelector: string;
	@Input() cssClass: string;
	@Output() onClick = new EventEmitter();

	rippleColor = getComputedStyle(document.documentElement).getPropertyValue('--button-ripple-color');

	constructor() {
	}

	ngOnInit(): void {
	}

}
