import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {tableFix} from '../../../utils/utils';
import {SettingsService} from '../../../services/settings.service';
import {StorageService} from '../../../services/storage.service';
import {Subscription} from 'rxjs';

/**
 * Component represents add new connections modal window.
 * Contains buttons and table of new connections (columns: ip and name).
 * A few connections can be added.
 */
@Component({
	selector: 'app-add-new-connections',
	templateUrl: './add-new-connections.component.html',
	styleUrls: ['./add-new-connections.component.scss']
})
export class AddNewConnectionsComponent implements OnInit, OnDestroy {

	@ViewChild('wrap', {static: true}) wrap: ElementRef;

	sensor: any = {ip: ''};
	sensorsList: Array<any> = [];
	isEdit: boolean;
	isDestroyed = false;
	existingRoomsNames: Array<string> = [];
	existingAddresses: string[] = [];
	disabledScroll = true;

	private subscriptions: Array<Subscription> = [];

	constructor(private storageService: StorageService,
				private settingsService: SettingsService,
				private dialogRef: MatDialogRef<AddNewConnectionsComponent>) {
	}

	ngOnInit() {
		this.storageService.getItem('socketPool').then(socketPool => {
			this.existingAddresses = socketPool;
			if (!this.isDestroyed) {
				// Connect last used sensor
				if (socketPool.length) {
					var pool = (socketPool || []).map(url => {
						return this.settingsService.getSocketInfo(url);
					});
					Promise.all(pool).then((socketInfo: any) => {
						this.existingRoomsNames = socketInfo.map(info => info.name);
					});
				}
			}
		});
		this.addSensor(this.sensor);
		window.addEventListener('resize', tableFix);
		setTimeout(() => {
			tableFix();
		});
		this.subscriptions.push(this.dialogRef.afterOpened().subscribe(() => this.disabledScroll = false));
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		window.removeEventListener('resize', tableFix);
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	onClose() {
		this.dialogRef.close(false);
	}

	connectToNewSensor() {
		if (this.connectEnabled()) {
			let c = true;
			this.sensorsList.forEach(item => {
				const ip = item.ip.indexOf(':') === -1 ? `${item.ip}:1234` : item.ip;
				if (this.existingRoomsNames.includes(item.name)) {
					item.errors.name = 'NAME_ALREADY_IN_USE';
					c = false;
				}
				if (this.existingAddresses.includes(ip)) {
					item.errors.ip = 'IP_ALREADY_IN_USE';
					c = false;
				}
			});
			if (c) {
				this.dialogRef.close(this.sensorsList);
			}
		}
	}

	addSensor(sensor) {
		this.sensorsList.push({
			isEdit: false,
			name: sensor.name,
			ip: sensor.ip,
			id: this.sensorsList.length + 1,
			errors: {
				ip: '',
				name: ''
			}
		});
		this.sensor = {
			name: '',
			ip: ''
		};
		setTimeout(() => {
			var input = (document.querySelector('.add-sensor__table tbody tr:last-child .ip-input') as any);
			if (input) {
				input.focus();
			}
		});
	}

	removeSensor(i) {
		this.sensorsList.splice(i, 1);
		if (!this.sensorsList.length) {
			this.addSensor(this.sensor);
		}
	}

	connectEnabled() {
		return this.sensorsList.every(item => item.ip && item.ip.length && item.name && item.name.length);
	}

	blurInput(e, item, key) {
		if (e.key === 'Enter') {
			e.target.blur();
		} else {
			item.errors[key] = '';
		}
	}
}
