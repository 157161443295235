<div class="settings" data-selector="settings-container">
	<div class="settings-sidebar" [ngStyle]="{'padding-left': isSmartHome ? '20px' : '', 'visibility': isPatientForm ? 'hidden' : 'visible'}">
		<nav class="h-100">
			<div *ngIf="isUserSettings">
				<button tabindex="-1" class="zones" (click)="setActiveMenuItem(SETTINS_TABS.SPACES)"
						[class.active]="SETTINS_TABS.SPACES == activeMenuItem">{{'SPACES' | translate}}
				</button>
			</div>
			<div *ngIf="isSmartHome" class="h-100 position-relative" perfectScrollbar>
				<button tabindex="-1" *ngFor="let room of rooms" (click)="selectRoom(room)" [class.active]="room === activeRoom">
					{{room.roomName}}
				</button>
				<!--<button [disabled]="loading" class="active-viewer" (click)="setActiveMenuItem(SETTINS_TABS.DEVICES)" [class.active]="SETTINS_TABS.DEVICES == activeMenuItem">{{'DEVICES' | translate}}</button>
				<button [disabled]="loading" class="rules" (click)="setActiveMenuItem(SETTINS_TABS.RULES)" [class.active]="SETTINS_TABS.RULES == activeMenuItem">{{'RULES' | translate}}</button>-->
			</div>
			<div *ngIf="(sensorSocket?.isMultipleSensors || data?.isFloorPlan) && !isUserSettings">
				<button tabindex="-1" class="zones" (click)="setActiveMenuItem(SETTINS_TABS.FLOOR_PLAN)" data-selector="settings-tab_floor-plan"
						[class.active]="SETTINS_TABS.FLOOR_PLAN == activeMenuItem" class="material-button">
					<span class="material-icons md-18">
						aspect_ratio
					</span>
					<span>{{(data?.isFloorPlan ? 'FLOOR_PLAN' : 'MULTIPLE_ROOMS') | translate}}</span>
				</button>
			</div>
			<!-- Currently Zones not used (only in SmartHome) -->
			<div *ngIf="data?.isFloorPlan">
				<button class="zones" (click)="setActiveMenuItem(SETTINS_TABS.LOCATIONS)" data-selector="settings-tab_locations"
						[class.active]="SETTINS_TABS.LOCATIONS == activeMenuItem"  class="material-button">
					<span class="material-icons md-18">
						picture_in_picture
					</span>
					<span>{{'LOCATIONS' | translate}}</span>
				</button>
			</div>
			<div *ngIf="!sensorSocket?.isMultipleSensors && !data?.isFloorPlan && !isSmartHome && !isUserSettings">
				<button tabindex="-1" class="sensor" (click)="setActiveMenuItem(SETTINS_TABS.SENSOR)"
						[class.active]="SETTINS_TABS.SENSOR == activeMenuItem">{{'ARENA' | translate}}
				</button>
				<button tabindex="-1" class="targets" (click)="setActiveMenuItem(SETTINS_TABS.TARGETS)"
						[class.active]="SETTINS_TABS.TARGETS == activeMenuItem"
						data-selector="settingsMenuTargetsButtton">{{'TARGETS' | translate}}
				</button>
				<button *ngIf="environment.fallDetectionEnabled" tabindex="-1" class="fall-detection" (click)="setActiveMenuItem(SETTINS_TABS.FALL_DETECTION)"
						[class.active]="SETTINS_TABS.FALL_DETECTION == activeMenuItem">{{'FALL_DETECTION'
					| translate}}
				</button>
				<button tabindex="-1" (click)="setActiveMenuItem(SETTINS_TABS.RECORDING)"
						[class.active]="SETTINS_TABS.RECORDING == activeMenuItem"
						data-selector="settingsMenuRecordingButtton" class="material-button">
					<span class="material-icons md-18">
						save
					</span>
					<span>{{'SAVE_DATA' | translate}}</span>
				</button>
				<button tabindex="-1" class="work-mode" (click)="setActiveMenuItem(SETTINS_TABS.WORK_MODE)"
						[class.active]="SETTINS_TABS.WORK_MODE == activeMenuItem"
						data-selector="settingsMenuWorkModeButtton">{{'WORK_MODE' | translate}}
				</button>
				<button *ngIf="sensorSocket.isCovidDetection" class="developer" (click)="setActiveMenuItem(SETTINS_TABS.DEVELOPER)"
						[class.active]="SETTINS_TABS.DEVELOPER == activeMenuItem">{{'DEVELOPER' | translate}}
				</button>
				<button *ngIf="initialSettings?.sensorParameters['ProcessorCfg.ExternalGUI.DevCfg.enable']" tabindex="-1" class="advanced" (click)="setActiveMenuItem(SETTINS_TABS.ADVANCED)"
						[class.active]="SETTINS_TABS.ADVANCED == activeMenuItem">{{'ADVANCED' | translate}}
				</button>
			</div>
		</nav>
	</div>
	<div *ngIf="isSmartHome" class="settings-container">
		<div class="container smart-home-container">
			<div class="progress-bar">
				<div class="d-flex">

					<div class="progress-check" [class.clickable]="activeTab > 0" (click)="setActiveTab(0, true)">
						<span class="progress-check-title">{{'DEVICES' | translate}}</span>
						<span *ngIf="activeTab === 0" class="smart-home-active-icon"><img
							src="assets/images/svg/settings/second-stage-checkbox.png"></span>
						<span *ngIf="activeTab > 0" class="smart-home-active-icon"><img
							src="assets/images/svg/settings/checked-checkbox.png"></span>
					</div>

					<div class="progress-line">
						<div *ngIf="activeTab === 0" class="no-filled-progress-line"></div>
						<div *ngIf="activeTab > 0" class="filled-progress-line"></div>
					</div>

					<div class="progress-check" [class.clickable]="activeTab > 1" (click)="setActiveTab(1, true)">
						<span class="progress-check-title">{{'LOCATIONS' | translate}}</span>
						<span *ngIf="activeTab < 1" class="smart-home-active-icon"><img
							src="assets/images/svg/settings/first-stage-checkbox.png"></span>
						<span *ngIf="activeTab === 1" class="smart-home-active-icon"><img
							src="assets/images/svg/settings/second-stage-checkbox.png"></span>
						<span *ngIf="activeTab > 1" class="smart-home-active-icon"><img
							src="assets/images/svg/settings/checked-checkbox.png"></span>
					</div>

					<div class="progress-line">
						<div *ngIf="activeTab < 2" class="no-filled-progress-line"></div>
						<div *ngIf="activeTab === 2" class="filled-progress-line"></div>
					</div>

					<div class="progress-check">
						<span class="progress-check-title">{{'SCENARIOS' | translate}}</span>
						<span *ngIf="activeTab < 2" class="smart-home-active-icon"><img
							src="assets/images/svg/settings/first-stage-checkbox.png"></span>
						<span *ngIf="activeTab === 2" class="smart-home-active-icon"><img
							src="assets/images/svg/settings/checked-checkbox.png"></span>
					</div>

				</div>
			</div>
			<ng-container *ngIf="activeTab === 0 && activeRoom">
				<h2 class="smart-home-scenarios-title">{{'SCENARIOS_CHOOSE_DEVICES' | translate}}:</h2>
				<div class="smart-home-scenarios-body" perfectScrollbar>
					<div *ngFor="let device of devices" class="device" [class.disabled]="device.disabled">
						<img [src]="getDeviceImage(device)"
							 [ngStyle]="{cursor: device.disabled ? 'default' : 'pointer'}"
							 (click)="clickOnDevice(device)">
						<br>
						{{device.name}}
						<br>
						<mat-checkbox [disableRipple]="true" [disabled]="device.disabled" [(ngModel)]="device.selected"
									  (change)="applyDeviceToRoom(device)"></mat-checkbox>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="activeTab === 1 && activeRoom">
				<app-locations [settingForm]="settingForm" [initialSettings]="activeRoom.parameters" [isSmartHome]="true"
							   (close)="saveZones()">
				</app-locations>
			</ng-container>
			<ng-container *ngIf="activeTab === 2">
				<app-rules [settingForm]="settingForm" [devices]="devices" [zones]="zones"></app-rules>
			</ng-container>
		</div>
		<div class="default-panel decide-panel">
			<div class="decide-panel-buttons">
				<button class="g-btn" mat-raised-button (click)="onCancel()">{{'CANCEL' | translate}}</button>
				<div class="smart-home-buttons">
					<button *ngIf="activeTab === 1" class="g-btn smart-home-back-button" mat-raised-button (click)="setActiveTab(0)">
						{{'BACK' | translate}}
					</button>
					<button *ngIf="activeTab === 0" class="g-btn smart-home-next-button" mat-raised-button (click)="setActiveTab(1)">
						{{'NEXT' | translate}}
					</button>
					<button *ngIf="activeTab === 2" class="g-btn smart-home-back-button" mat-raised-button (click)="setActiveTab(1)">
						{{'BACK' | translate}}
					</button>
					<button *ngIf="activeTab === 1" class="g-btn smart-home-next-button" mat-raised-button (click)="setActiveTab(2)">
						{{'NEXT' | translate}}
					</button>
					<button *ngIf="activeTab === 2" class="g-btn smart-home-save-button" mat-raised-button (click)="onSaveSmartHome()">
						{{'SAVE' | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!isSmartHome" class="settings-container">
		<div class="container">
			<ng-container *ngIf="!loading">
				<ng-container *ngIf="isUserSettings">
					<app-spaces *ngIf="activeMenuItem === SETTINS_TABS.SPACES" [spaces]="spaces"></app-spaces>
				</ng-container>
				<ng-container *ngIf="sensorSocket?.isMultipleSensors || data?.isFloorPlan">
					<ng-container *ngIf="activeMenuItem === SETTINS_TABS.FLOOR_PLAN">
						<app-settings-floor-plan *ngIf="settingForm; else elseBlock"
												 [sensorSocket]="sensorSocket"
												 [settingForm]="settingForm"
												 [initialSettings]="initialSettings"
												 [buildings]="data.buildings"
						></app-settings-floor-plan>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="data?.isFloorPlan">
					<ng-container *ngIf="activeMenuItem === SETTINS_TABS.LOCATIONS">
						<app-locations *ngIf="locationsTypes; else elseBlock"
									   [settingForm]="settingForm" [showSensor]="false" [data]="data"
									   [sensorSocket]="sensorSocket" [initialSettings]="initialSettings"
									   [locationsTypes]="locationsTypes"></app-locations>
						<ng-template #elseBlock>
							<app-loading></app-loading>
						</ng-template>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="!sensorSocket?.isMultipleSensors && !data?.isFloorPlan && settingForm; else elseBlock"
							  [formGroup]="settingForm">
					<app-locations *ngIf="activeMenuItem === SETTINS_TABS.LOCATIONS" [settingForm]="settingForm"
						   [sensorSocket]="sensorSocket" [initialSettings]="initialSettings"></app-locations>
				</ng-container>
				<ng-template #elseBlock>
					<ng-container *ngIf="!sensorSocket?.isMultipleSensors && !data?.isFloorPlan && !isUserSettings && !isPatientForm">
						<app-loading></app-loading>
					</ng-container>
					<ng-container *ngIf="isPatientForm">
						<h2 class="patient-data">{{'PATIENT_DATA' | translate}}</h2>
						<span class="error" [ngStyle]="{'visibility': measurementsForm.invalid && (measurementsForm.dirty || measurementsForm.touched) ? 'visible' : 'hidden' }">{{'FIELDS_ARE_REQUIRED' | translate}}</span>
						<div class="new-measurements" perfectScrollbar>
							<form [formGroup]="measurementsForm">
								<table>
									<tr>
										<td>{{'PATIENT_ID' | translate}}:</td>
										<td>
											{{patientId}}
										</td>
									</tr>
									<tr>
										<td class="col-gender-label">{{'GENDER' | translate}}:</td>
										<td class="col-gender-value">
											<mat-form-field class="gender-select">
												<mat-select formControlName="gender" panelClass="testPanelClass">
													<mat-option value="male">
														{{'MALE' | translate}}
													</mat-option>
													<mat-option value="female">
														{{'FEMALE' | translate}}
													</mat-option>
													<mat-option value="other">
														{{'OTHER' | translate}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</td>
									</tr>
									<tr>
										<td>{{'AGE' | translate}} <span class="hint">[5 - 100]</span>:</td>
										<td class="col-age-value">
											<input formControlName="age" [class.error]="measurementsForm.controls.age.invalid && (measurementsForm.controls.age.dirty || measurementsForm.controls.age.touched)" type="number">
										</td>
										<td>yo</td>
									</tr>
									<tr>
										<td>{{'HEIGHT' | translate}} <span class="hint">[1.4 - 2.2]</span>:</td>
										<td>
											<input formControlName="height" [class.error]="measurementsForm.controls.height.invalid && (measurementsForm.controls.height.dirty || measurementsForm.controls.height.touched)" type="number">
										</td>
										<td>m</td>
									</tr>
									<tr>
										<td>{{'WEIGHT' | translate}} <span class="hint">[30 - 200]</span>:</td>
										<td>
											<input formControlName="weight" [class.error]="measurementsForm.controls.weight.invalid && (measurementsForm.controls.weight.dirty || measurementsForm.controls.weight.touched)" type="number">
										</td>
										<td>kg</td>
									</tr>
									<tr>
										<td>{{'IS_CORONA_POSITIVE' | translate}}
										<td>
											<mat-button-toggle-group formControlName="isCoronaPositive">
												<mat-button-toggle [value]="true">
												  {{'YES' | translate}}
												</mat-button-toggle>
												<mat-button-toggle [value]="false">
													{{'NO' | translate}}
												</mat-button-toggle>
											  </mat-button-toggle-group>
										</td>
										<td></td>
									</tr>
									<tr>
										<td>{{'IS_PERSON_COUGHING' | translate}}
										<td>
											<mat-button-toggle-group formControlName="isPersonCoughing">
												<mat-button-toggle [value]="true">
												  {{'YES' | translate}}
												</mat-button-toggle>
												<mat-button-toggle [value]="false">
													{{'NO' | translate}}
												</mat-button-toggle>
											  </mat-button-toggle-group>
										</td>
										<td></td>
									</tr>
									<tr>
										<td>{{'HAS_HEART_PACER' | translate}}
										<td>
											<mat-button-toggle-group formControlName="hasHeartPacer">
												<mat-button-toggle [value]="true">
												  {{'YES' | translate}}
												</mat-button-toggle>
												<mat-button-toggle [value]="false">
													{{'NO' | translate}}
												</mat-button-toggle>
											  </mat-button-toggle-group>
										</td>
										<td></td>
									</tr>
									<tr>
										<td>{{'HAS_ASTHMA' | translate}}
										<td>
											<mat-button-toggle-group formControlName="hasAsthma">
												<mat-button-toggle [value]="true">
												  {{'YES' | translate}}
												</mat-button-toggle>
												<mat-button-toggle [value]="false">
													{{'NO' | translate}}
												</mat-button-toggle>
											  </mat-button-toggle-group>
										</td>
										<td></td>
									</tr>
									<tr>
										<td>{{'HAS_COPD' | translate}}
										<td>
											<mat-button-toggle-group formControlName="hasCOPD">
												<mat-button-toggle [value]="true">
												  {{'YES' | translate}}
												</mat-button-toggle>
												<mat-button-toggle [value]="false">
													{{'NO' | translate}}
												</mat-button-toggle>
											  </mat-button-toggle-group>
										</td>
										<td></td>
									</tr>
									<tr>
										<td>{{'REASON_FOR_ARRIVAL' | translate}}:</td>
										<td colspan="2">
											<textarea formControlName="reasonForArrival" class="w-100"></textarea>
										</td>
									</tr>
									<tr>
										<td>{{'OTHER_COMMENTS' | translate}}:</td>
										<td colspan="2">
											<textarea formControlName="mainHealthCondition" class="w-100"></textarea>
										</td>
									</tr>
									<tr>
										<td>{{'BODY_TEMPERATURE_METHOD' | translate}}
										<td>
											<mat-radio-group formControlName="bodyTemperatureMethod" [class.error]="measurementsForm.controls.bodyTemperatureMethod.invalid && (measurementsForm.controls.bodyTemperatureMethod.dirty || measurementsForm.controls.bodyTemperatureMethod.touched)"
															class="flex-column d-flex">
												<mat-radio-button value="mouth" class="body-temperature-method-option">{{'MOUTH' |	translate}}</mat-radio-button>
												<mat-radio-button value="rectal" class="body-temperature-method-option">{{'RECTAL' | translate}}</mat-radio-button>
												<mat-radio-button value="remote" class="body-temperature-method-option">{{'REMOTE' | translate}}</mat-radio-button>
											</mat-radio-group>
										</td>
										<td></td>
									</tr>
									<tr>
										<td class="col-body-temperature">{{'BODY_TEMPERATURE' | translate}} <span class="hint">[35.0 - 42.0]</span>:</td>
										<td>
											<input formControlName="temperature" [class.error]="measurementsForm.controls.temperature.invalid && (measurementsForm.controls.temperature.dirty || measurementsForm.controls.temperature.touched)" type="number">
										</td>
										<td>°C</td>
									</tr>

									<tr>
										<td>{{'SATURATION' | translate}}:</td>
										<td>
											<input formControlName="saturation" [class.error]="measurementsForm.controls.saturation.invalid && (measurementsForm.controls.saturation.dirty || measurementsForm.controls.saturation.touched)" type="number">
										</td>
										<td></td>
									</tr>
									<tr>
										<td colspan="3">{{'BLOOD_PRESSURE' | translate}}</td>
									</tr>
									<tr [formGroup]="measurementsForm.controls.bloodPressure">
										<td class="col-blood-pressure-param">{{'DIASTOLIC' | translate}} <span class="hint">[60 - 140]</span>:</td>
										<td>
											<input formControlName="diastolic" [class.error]="measurementsForm.controls.bloodPressure.controls.diastolic.invalid && (measurementsForm.controls.bloodPressure.controls.diastolic.dirty || measurementsForm.controls.bloodPressure.controls.diastolic.touched)" type="number">
										</td>
										<td>mmHg</td>
									</tr>
									<tr [formGroup]="measurementsForm.controls.bloodPressure">
										<td class="col-blood-pressure-param">{{'SYSTOLIC' | translate}} <span class="hint">[90 - 250]</span>:</td>
										<td>
											<input formControlName="systolic" [class.error]="measurementsForm.controls.bloodPressure.controls.systolic.invalid && (measurementsForm.controls.bloodPressure.controls.systolic.dirty || measurementsForm.controls.bloodPressure.controls.diastolic.touched)" type="number">
										</td>
										<td>mmHg</td>
									</tr>
									<tr>
										<td colspan="3">{{'HEART_RATE' | translate}}</td>
									</tr>
									<tr>
										<td class="col-blood-pressure-param">{{'MIN' | translate}} <span class="hint">[40 - 180]</span>:</td>
										<td>
											<input formControlName="heartRate_min" [class.error]="measurementsForm.controls.heartRate_min.invalid && (measurementsForm.controls.heartRate_min.dirty || measurementsForm.controls.heartRate_min.touched)" type="number">
										</td>
										<td>bpm</td>
									</tr>
									<tr>
										<td class="col-blood-pressure-param">{{'MAX' | translate}} <span class="hint">[40 - 180]</span>:</td>
										<td>
											<input formControlName="heartRate_max" [class.error]="measurementsForm.controls.heartRate_max.invalid && (measurementsForm.controls.heartRate_max.dirty || measurementsForm.controls.heartRate_max.touched)" type="number">
										</td>
										<td>bpm</td>
									</tr>
									<tr>
										<td class="col-blood-pressure-param">{{'VALUE' | translate}} <span class="hint">[40 - 180]</span>:</td>
										<td>
											<input formControlName="heartRate_value" [class.error]="measurementsForm.controls.heartRate_value.invalid && (measurementsForm.controls.heartRate_value.dirty || measurementsForm.controls.heartRate_value.touched)" type="number">
										</td>
										<td>bpm</td>
									</tr>
									<tr>
										<td>{{'IS_SENSOR_IN_POSITION' | translate}}
										<td>
											<mat-button-toggle-group formControlName="isSensorInPosition">
												<mat-button-toggle [value]="true">
												  {{'YES' | translate}}
												</mat-button-toggle>
												<mat-button-toggle [value]="false">
													{{'NO' | translate}}
												</mat-button-toggle>
											  </mat-button-toggle-group>
										</td>
										<td></td>
									</tr>
									<tr>
										<td>{{'IS_CHAIR_PLACED_CORRECTLY' | translate}}
										<td>
											<mat-button-toggle-group formControlName="isPersonInPosition">
												<mat-button-toggle [value]="true">
												  {{'YES' | translate}}
												</mat-button-toggle>
												<mat-button-toggle [value]="false">
													{{'NO' | translate}}
												</mat-button-toggle>
											  </mat-button-toggle-group>
										</td>
										<td></td>
									</tr>
								</table>
							</form>
						</div>
					</ng-container>
				</ng-template>
			</ng-container>
			<ng-container *ngIf="loading">
				<app-loading></app-loading>
			</ng-container>
		</div>
		<div class="default-panel decide-panel">
			<div class="decide-panel-buttons">
				<button tabindex="-1" class="g-btn" mat-raised-button (click)="onCancel()"
						data-selector="cancel-settings-button">{{'CANCEL' | translate}}</button>

				<button class="g-btn slc-btn" mat-raised-button (click)="onSave()"
						data-selector="save-settings-button">{{(isPatientForm ? 'SAVE' : 'OK') | translate}}
				</button>
			</div>
		</div>
	</div>
</div>
