<div class="buttons">
	<ng-content></ng-content>
</div>
<div class="row">
	<div class="slots">
		<div class="slot">
			<h5 class="analysis {{analysisClass | lowercase}}">{{'HEART_RATE' | translate}}</h5>
			<span class="value">{{bpm | number:'1.0-0'}}</span>
			<div class="slot-right">
				<img class="icon" src="assets/images/svg/health/cardiogram.svg">
				<span class="label">BPM</span>
			</div>
		</div>
		<div class="slot">
			<h5>{{'HEART_RATE_VARIABILITY' | translate}}</h5>
			<span class="value">{{hrv | number:'1.0-0'}}</span>
			<div class="slot-right">
				<img class="icon ic-life" src="assets/images/svg/health/life.svg">
				<span class="label">ms</span>
			</div>
		</div>
	</div>
	<app-vitals
            [data]="heartRateData"
            [parameters]="parameters"
            [selectedModelIds]="selectedModelIds"
            [sensorSocket]="sensorSocket"
            [status]="sensorSocket?.connectionStatus" perfectScrollbar></app-vitals>
</div>
<div class="row">
	<div class="slots">
		<div class="slot">
			<h5 class="analysis {{analysisClass | lowercase}}">{{'RESPIRATION_RATE' | translate}}</h5>
			<span class="value">{{rpm | number:'1.0-0'}}</span>
			<div class="slot-right">
				<img class="icon" src="assets/images/svg/health/lung.svg">
				<span class="label">RPM</span>
			</div>
		</div>
	</div>
	<app-vitals
            [data]="respirationData"
            [parameters]="parameters"
            [selectedModelIds]="selectedModelIds"
            [sensorSocket]="sensorSocket"
            [status]="sensorSocket?.connectionStatus" perfectScrollbar></app-vitals>
</div>
