import {Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import * as d3 from 'd3';

/**
 * Component renders scanned silhouette line in 2D.
 * Used for Smart Tailor app.
 */
@Component({
	selector: 'app-smart-tailor-silhouette',
	templateUrl: './smart-tailor-silhouette.component.html',
	styleUrls: ['./smart-tailor-silhouette.component.scss']
})
export class SmartTailorSilhouetteComponent implements OnInit, OnChanges {

	@Input() data: any = {};

	paths = [];

	VIEWBOX_W = 100;
	VIEWBOX_H = 100;
	clientHeight = 0;
	clientWidth = 0;

	private paddingW = 50;
	private paddingH = 50;

	constructor(private hostElement: ElementRef) {
	}

	ngOnInit() {
		setTimeout(() => {
			this.resizeViewBox();
		});
	}

	ngOnChanges(c: SimpleChanges) {
		this.draw();
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event: Event) {
		setTimeout(() => {
			this.resizeViewBox();
		});
	}

	private resizeViewBox() {
		this.clientHeight = this.hostElement.nativeElement.clientHeight;
		this.clientWidth = this.hostElement.nativeElement.clientWidth;
		var aspect = this.clientWidth / this.clientHeight;

		this.VIEWBOX_H = +(this.VIEWBOX_W / aspect).toFixed();
		this.paddingH = this.paddingW / aspect;
		this.draw();
	}

	private draw() {
		if (this.data.silhouette) {
			let xS = this.data.silhouette.reduce((v, c) => v.concat(c.map(p => p[0])), []),
				yS = this.data.silhouette.reduce((v, c) => v.concat(c.map(p => p[1])), []),
				minX = Math.min(...xS),
				minY = Math.min(...yS),
				maxX = Math.max(...xS) + minX,
				maxY = Math.max(...yS) + minY;

			this.paths = this.data.silhouette.map(l => {
				return d3.line().curve(d3.curveBasis)(l.map(p => this.scaleAndRotate(p, maxX, maxY, minX, minY)));
			});
		}
	}

	private scaleAndRotate(point, maxX, maxY, minX, minY) {
		let angle = -90,
			cX = (minX + maxX) / 2,
			cY = (minY + maxY) / 2,
			x = point[0] - cX,
			y = point[1] - cY,
			sin = Math.sin(angle * (Math.PI / 180)),
			cos = Math.cos(angle * (Math.PI / 180));

		return [((x * cos - y * sin) + cX) * this.VIEWBOX_W / maxX, ((x * sin + y * cos) - (maxY / 4) + cY) * this.VIEWBOX_H / maxY];
	}
}
