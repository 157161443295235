<div class="buttons">
	<ng-content></ng-content>
</div>
<div class="left">
	<h2 class="title">{{'SALES_FUNNEL' | translate}}</h2>
	<div class="trapezoid">
		<svg [attr.viewBox]="'0 0 ' + VIEWBOX_W + ' ' + VIEWBOX_H" preserveAspectRatio="none">
			<path [attr.d]="path" fill="transparent" stroke-width="1" stroke-dasharray="20"
				  vector-effect="non-scaling-stroke"></path>
		</svg>
		<h2>{{'TRAFFIC' | translate}}</h2>
		<h1>{{data?.totalTraffic || 0 | number:'1.0-0'}}</h1>
	</div>
	<img class="ic-chevron" src="assets/images/svg/keyboard_arrow_down-24px.svg">
	<div class="trapezoid">
		<svg [attr.viewBox]="'0 0 ' + VIEWBOX_W + ' ' + VIEWBOX_H" preserveAspectRatio="none">
			<path [attr.d]="path" fill="transparent" stroke-width="1" stroke-dasharray="20"
				  vector-effect="non-scaling-stroke"></path>
		</svg>
		<h2>{{'STOP' | translate}}</h2>
		<h1>{{data?.totalStops || 0 | number:'1.0-0'}}</h1>
	</div>
	<img class="ic-chevron" src="assets/images/svg/keyboard_arrow_down-24px.svg">
	<div class="trapezoid">
		<svg [attr.viewBox]="'0 0 ' + VIEWBOX_W + ' ' + VIEWBOX_H" preserveAspectRatio="none">
			<path [attr.d]="path" fill="transparent" stroke-width="1" stroke-dasharray="20"
				  vector-effect="non-scaling-stroke"></path>
		</svg>
		<h2>{{'SHOP' | translate}}</h2>
		<h1>{{data?.totalBuyers || 0 | number:'1.0-0'}}</h1>
	</div>
</div>
<div class="delimiter"></div>
<div class="right">
	<div class="circle">
		<div class="circle-wrapper">
			<svg
				class="progress-ring"
				width="136"
				height="136">
				<circle
					class="progress-ring__circle"
					stroke="#c9efe2"
					stroke-width="11"
					fill="transparent"
					r="57"
					cx="68"
					cy="68"></circle>
				<circle
					[attr.stroke-dashoffset]="setConversionRate(100 - data?.buyerConversion, 57)"
					[attr.stroke-dasharray]="57 * 2 * Math.PI"
					class="progress-ring__circle"
					stroke="#fffdfa"
					stroke-width="11"
					fill="transparent"
					r="57"
					cx="68"
					cy="68"></circle>

			</svg>
			<span>{{data?.BuyerConversion || 0 | number:'1.0-0'}}%</span>
		</div>
		<h2 [innerHTML]="'CONVERSION_RATE' | translate | htmlNewLine"></h2>
	</div>
	<hr>
	<div class="circle">
		<div class="circle-wrapper">
			<svg
				class="progress-ring"
				width="130"
				height="130">
				<circle
					class="progress-ring__circle"
					stroke="#c9efe2"
					stroke-width="1"
					fill="transparent"
					r="57"
					cx="65"
					cy="65"></circle>
				<circle
					stroke-dasharray="5"
					class="progress-ring__circle"
					stroke="#fffdfa"
					stroke-width="1"
					fill="transparent"
					r="53"
					cx="65"
					cy="65"></circle>

			</svg>
			<span>{{data?.avgDwellTime * 1000 | duration}}</span>
		</div>
		<h2 [innerHTML]="'DWELL_TIME' | translate | htmlNewLine"></h2>
	</div>
	<hr>
	<div class="circle">
		<div class="circle-wrapper">
			<svg
				class="progress-ring"
				width="130"
				height="130">
				<circle
					stroke-dasharray="12"
					class="progress-ring__circle"
					stroke="#fffdfa"
					stroke-width="1"
					fill="transparent"
					r="57"
					cx="65"
					cy="65"></circle>

			</svg>
			<span>{{data?.missedPercentage || 0 | number:'1.0-0'}}%</span>
		</div>
		<h2 [innerHTML]="'MISSED_OPPORTUNITY' | translate | htmlNewLine"></h2>
	</div>
</div>
