<div class="buttons">
	<ng-content></ng-content>
</div>
<h2>Please, select your gender:</h2>
<div class="gender-checkboxes">
	<div class="male" [class.active]="gender === 'male'" (click)="selectGender('male')"
		 [class.disabled]="isDisableState">
		{{'MALE' | translate}}
	</div>
	<div class="female" [class.active]="gender === 'female'" (click)="selectGender('female')"
		 [class.disabled]="isDisableState">
		{{'FEMALE' | translate}}
	</div>
</div>
<button mat-raised-button class="scan" [class.disabled]="gender === '' || isDisableState" (click)="setReadyForScanning()">
	{{'SCAN' | translate}}
</button>
