import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackendConnectorBaseComponent} from '../backend-connector.base.component';
import {ConnectionType} from '../../../../consts';
import {RestService} from '../../../../services/system/rest.service';
import {BusEventService} from '../../../../services/bus-event.service';
import {ToolbarService} from '../../../../services/toolbar.service';
import {StorageService} from '../../../../services/storage.service';
import {environment} from '../../../../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {ConnectionStatus} from '../../../../services/system/connection';
import {RestConnection} from '../../../../services/system/rest-connection';

/**
 * Backend connector to connect to REST API.
 */
@Component({
	selector: 'app-backend-connector-rest',
	templateUrl: '../backend-connector.base.component.html',
	styleUrls: ['../backend-connector.base.component.scss']
})
export class BackendConnectorRestComponent extends BackendConnectorBaseComponent implements OnInit, OnDestroy {

	type = ConnectionType.REST;
	connection: RestConnection;

	constructor(public busEventService: BusEventService,
				private restService: RestService,
				private toolbarService: ToolbarService,
				private storageService: StorageService,
				private router: Router) {
		super(busEventService);
	}

	async ngOnInit() {
		this.initConnection();
		this.subscriptions.push(this.router.events.subscribe(async (event) => {
			if (event instanceof NavigationEnd) {
				setTimeout(() => {
					this.initConnection();
				});
			}
		}));
	}

	private async initConnection() {
		let analyticsServerAddress;
		if (environment.useAzure) {
			analyticsServerAddress = environment.azure.telemetryFunctionAppURL;
		} else {
			if (environment.disableRemoteConnToRESTServer) {
				analyticsServerAddress = environment.analyticsRESTserverAddress;
			} else {
				let tmp = await this.storageService.getItem('analyticsServerAddress');
				analyticsServerAddress = (tmp || {}).analyticsServerAddress;
			}
		}
		if (analyticsServerAddress) {
			this.ip = analyticsServerAddress;
			this.connect();
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	public async connect() {
		let url = this.ip + (environment.useAzure ? '' : `:${this.restService.retailAnalyticsAPIserverPort}`);
		if (!url.startsWith('http')) {
			url = 'http://' + url;
		}
		this.connection = this.restService.getConnection(url) || this.restService.createConnection(this.ip, environment.useAzure ? '' : undefined);
		this.toolbarService.analyticsServerAddress = this.ip;
		this.storageService.setItem('analyticsServerAddress', {analyticsServerAddress: this.ip});
		this.busEventService.analyticsServerAddress.emit(this.ip);
		this.restService.setServerAddress(this.ip);
		this.connectEvent = {
			type: this.type,
			url: this.connection.url
		};

		this.setConnectionStatus('STATUS_CONNECTING');
		try {
			await Promise.resolve();
			if (this.restService.isConnected) {
				this.connection.connectionStatus = ConnectionStatus.CONNECTED;
			} else {
				await this.connection.connect();
				this.restService.isConnected = true;
			}
			this.setConnectionStatus('STATUS_CONNECTED');
			super.connect();
		} catch (e) {
			console.error(e);
			this.setConnectionStatus('NOT_CONNECTED');
			return;
		}
	}

	public disconnect() {
		this.disconnectEvent = {
			url: this.connection.url,
			type: this.type
		};
		this.restService.setServerAddress(undefined);
		this.setConnectionStatus('NOT_CONNECTED');
		this.connection.disconnect();
		super.disconnect();
	}

	public updateIP() {
	}

	public getConnectionStatus(): ConnectionStatus {
		if (this.connection) {
			if (this.connection.isConnecting()) {
				return ConnectionStatus.CONNECTING;
			} else {
				return this.connection.isConnected() ? ConnectionStatus.CONNECTED : ConnectionStatus.DISCONNECTED;
			}
		} else {
			return ConnectionStatus.DISCONNECTED;
		}
	}
}
