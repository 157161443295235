import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {HEATMAP_COLORS, HEATMAP_RESOLUTION, rotateAndReflect} from '../../../../../utils/ImageUtils';

@Component({
	selector: 'app-point-cloud-raw2d',
	templateUrl: './point-cloud-raw2d.component.html',
	styleUrls: ['./point-cloud-raw2d.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointCloudRaw2dComponent implements OnChanges {

	@Input() data: any;
	@ViewChild('canvas', {static: true}) canvas: ElementRef;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
		changeDetectorRef.detach();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.data?.data?.[0]) {
			// Scale intensity
			// TODO setup 30ms
			let data: Array<Array<number>> = rotateAndReflect(this.data.data, false, 1),
				data1d = data.flat(),
				maxIntensity = Math.max(...data1d),
				minIntensity = Math.min(...data1d),
				rangeIntensity = maxIntensity === minIntensity ? 1 : maxIntensity - minIntensity;

			for (let i = 0; i < data.length; i++) {
				for (let j = 0; j < data[0].length; j++) {
					data[i][j] = (data[i][j] - minIntensity) / rangeIntensity;
				}
			}

			let canvas = document.createElement('canvas');
			canvas.width = this.canvas.nativeElement.width = data[0].length;
			canvas.height = this.canvas.nativeElement.height = data.length;
			let ctx = canvas.getContext('2d', {alpha: true}) as CanvasRenderingContext2D,
				imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
			for (let i = 0; i < data.length; i++) {
				for (let j = 0; j < data[0].length; j++) {
					let color = this.getColor(data[i][j]),
						redIndex = ((i * (imageData.width * 4)) + (j * 4)),
						greenIndex = ((i * (imageData.width * 4)) + (j * 4)) + 1,
						blueIndex = ((i * (imageData.width * 4)) + (j * 4)) + 2;

					imageData.data[redIndex] = color.r;
					imageData.data[greenIndex] = color.g;
					imageData.data[blueIndex] = color.b;
					imageData.data[blueIndex + 1] = 255;
				}
			}
			this.canvas.nativeElement.getContext('2d').putImageData(imageData, 0, 0);
		}
	}

	private getColor(value) {
		let index = value === 1 ? HEATMAP_COLORS.length - 1 : Math.floor(value * HEATMAP_RESOLUTION);
		if (index >= HEATMAP_COLORS.length) {
			index = HEATMAP_COLORS.length - 1;
		}
		return HEATMAP_COLORS[index];
	}
}
