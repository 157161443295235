<div class="side-panel-bar">
	<button mat-icon-button [matMenuTriggerFor]="menu" (menuClosed)="onMenuClosed()" (menuOpened)="onMenuOpened()"
			disableRipple>
		<div class="hmb-container">
			<span class="hmb-item"></span>
			<span class="hmb-item"></span>
			<span class="hmb-item"></span>
		</div>
	</button>
	<mat-menu #menu="matMenu" [hasBackdrop]="false" class="view-menu">
		<div #buttonWrap>
			<button mat-icon-button>
				<div class="hmb-container">
					<span class="hmb-item"></span>
					<span class="hmb-item"></span>
					<span class="hmb-item"></span>
				</div>
			</button>

			<button *ngIf="!hideViewButton" (click)="toggleLayersPanel($event)" class="side-view" mat-menu-item disableRipple
					[matTooltip]="'VIEW' | translate"
					data-selector="layers-panel-button"
					[disabled]="isViewDisabled"
					[ngClass]="{'side-view-selected': isViewShow}"></button>

			<div class="radio-button-panel radio-button-panel-view" [style.display]="isViewShow ? 'block' : 'none' "
				 (click)="$event.stopPropagation()">

				<ng-container *ngFor="let layers of layersByCategory">
					<div>{{(layers[0].staticDataDict?.category || 'OTHER_CATEGORY') | translate}}</div>
					<div *ngFor="let layer of layers">
						<label [attr.data-selector]="layer.displayName">
							<input type="checkbox" [(ngModel)]="layer.selected" (change)="onToggleLayer(layer)">
							{{layer.displayName}}
						</label>
					</div>
					<hr>
				</ng-container>
			</div>
			<button *ngIf="!hideSensorButton" class="side-sensor" (click)="onClickSensorBtn($event)" [matTooltip]="'SENSOR' | translate"
					disableRipple
					[class.side-sensor-selected]="sensorValue && isSensorEnable"
					mat-menu-item
					[disabled]="isDisabled || !isSensorEnable"></button>

			<button *ngIf="!hideFlipButton" class="side-flip" [class.side-flip-selected]="reflect && isFlipEnable"
					[matTooltip]="'FLIP' | translate"
					disableRipple
					mat-menu-item (click)="onClickFlipBtn($event)"
					[disabled]="isDisabled || !isFlipEnable"></button>

			<button *ngIf="!hideRotateButton" [attr.test]="rotateValue" class="side-rotate" mat-menu-item [matTooltip]="'ROTATE' | translate"
					disableRipple
					data-selector="layer-controls_rotate_button"
					[disabled]="isDisabled || !isRotateEnable"
					[ngClass]="{'side-rotate-90': rotateValue === 3 && isRotateEnable, 'side-rotate-180': rotateValue === 2 && isRotateEnable, 'side-rotate-270': rotateValue === 1 && isRotateEnable}"
					(click)="onClickRotateBtn($event)"></button>

			<button *ngIf="!hideResetViewButton" class="side-reset-3d-view" (click)="onReset3dViewClick($event)"
					[matTooltip]="'RESET_3D_VIEW' | translate"
					disableRipple
					mat-menu-item
					[disabled]="isDisabled || !isReset3dEnable"></button>

			<button
				*ngIf="!hideRulerButton"
				[ngClass]="{'button-ruler-selected': rulerValue && isRulerEnable}"
				class="side-ruler" mat-menu-item (click)="onClickRulerBtn($event)"
				[matTooltip]="'RULER' | translate" disableRipple
				[disabled]="isDisabled || !isRulerEnable"></button>


			<button *ngIf="!hideSensitivityButton" class="side-sensitivity" [class.side-sensitivity-selected]="isSensitivityPanelShow"
					[matTooltip]="'SENSITIVITY' | translate"
					(click)="onClickSensitivity($event)" mat-menu-item disableRipple
					[disabled]=" isDisabled || !isSensitivityEnable || isSettingInReadOnly"></button>

			<div class="sensitiviti-panel" *ngIf="isSensitivityPanelShow" (click)="$event.stopPropagation()">
				<div class="sensitivity-label-container">
						<span class="sensitivity-label"
							  [ngStyle]="{'left.%': sensitivityLabelPosition}">{{sensitivityValueSelect}}</span>
				</div>
				<nouislider (update)="sensitivityUpdateVal($event)"
							(change)="sensitivityChangeVal($event)"
							[connect]="connect"
							[min]="sensitivityMin"
							[max]="sensitivityMax"
							[step]="0.1"
							[(ngModel)]="sensitivityValueSelect"
							class="sensitivity-range"
				></nouislider>
			</div>

			<button class="camera-list" [class.camera-list-selected]="isCameraListShow"
					[matTooltip]="'CAMERA_LIST' | translate"
					(click)="onClickCamera($event)" mat-menu-item disableRipple
					[disabled]=" isDisabled || !isCameraListEnable || isSettingInReadOnly"></button>

			<div *ngIf="isCameraListShow" #cameraPanel [ngStyle]="{'top.px': cameraPanelTop}"
				 class="radio-button-panel radio-button-panel-view" (click)="$event.stopPropagation()">
				<div *ngFor="let camera of cameraList; let i = index">
					<label [attr.data-deviceId]="camera.deviceId" [attr.data-groupId]="camera.groupId">
						<input type="checkbox" [checked]="camera.deviceId === cameraValue" (change)="onToggleCamera(camera)">
						{{camera.label || 'Camera ' + i}}
					</label>
				</div>
			</div>
		</div>
	</mat-menu>
</div>
