import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {environment} from '../../environments/environment';
import {VALUE_TO_DELETE} from './settings.service';
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

/**
 * Service for working with Firebase.
 */
@Injectable({
	providedIn: 'root'
})
export class FirebaseService {

	loggedInUserName = '';
	private firebaseApp: firebase.app.App;
	private initialized = false;
	private loggedInUser: QueryDocumentSnapshot | null;

	constructor() {
	}

	isLoggedIn() {
		return !!this.loggedInUser;
	}

	init() {
		if (!this.initialized) {
			this.firebaseApp = firebase.initializeApp(environment.firebase);
			const firestore = firebase.firestore();
			firestore.enablePersistence().catch(function (err) {
				console.warn(err);
				if (err.code === 'failed-precondition') {
					// Multiple tabs open, persistence can only be enabled
					// in one tab at a a time.
					// ...
				} else if (err.code === 'unimplemented') {
					// The current browser does not support all of the
					// features required to enable persistence
					// ...
				}
			});
			this.initialized = true;
		}
	}

	signUp(userName) {
		return this.firebaseApp.firestore().collection('users').where('name', '==', userName).get().then(user => {
			if (user.empty) {
				return this.firebaseApp.firestore().collection('users').add({
					name: userName
				}).then(newUser => {
					return newUser.collection('spaces').add({
						name: 'Default',
						devices: {},
						rules: []
					}).then(space => {
						return newUser.update({
							currentSpace: space.id
						});
					});
				}).then(() => {
					return true;
				});
			} else {
				return false;
			}
		});
	}

	signIn(userName) {
		return this.firebaseApp.firestore().collection('users').where('name', '==', userName).get().then(user => {
			if (user.empty) {
				return false;
			} else {
				this.loggedInUser = user.docs[0];
				this.loggedInUserName = user.docs[0].get('name');
				return true;
			}
		});
	}

	signOut() {
		this.loggedInUser = null;
	}

	getRooms() {
		return this.getCurrentSpace().then(space => {
			return space.ref.collection('rooms').get().then(rooms => {
				if (rooms.empty) {
					return [];
				} else {
					return rooms.docs.map(room => {
						return Object.assign({}, room.data(), {
							settings: this.convertParametersFromFirebaseFormat(room.data()['settings'])
						});
					});
				}
			});
		});
	}

	getSpaces() {
		return Promise.all([this.loggedInUser!.ref.collection('spaces').get(), this.getCurrentSpace()]).then(res => {
			let [spaces, currentSpace] = res;
			return spaces.docs.map(doc => Object.assign({
				id: doc.id,
				current: doc.get('name') === currentSpace.get('name')
			}, doc.data()));
		});
	}

	setSpaces(spaces) {
		let spacesToUpdate: Array<any> = [],
			spacesToAdd: Array<any> = [],
			pool: Array<any> = [];

		spaces.forEach(space => {
			if (space.id) {
				spacesToUpdate.push(space);
			} else {
				spacesToAdd.push(space);
			}
		});
		spacesToUpdate.forEach(space => {
			pool.push(this.loggedInUser!.ref.collection('spaces').doc(space.id).update('name', space.name));
		});
		spacesToAdd.forEach(space => {
			pool.push(this.loggedInUser!.ref.collection('spaces').add({
				name: space.name
			}));
		});
		return Promise.all(pool).then(async () => {
			let currentSpace = (await this.loggedInUser!.ref.collection('spaces').where('name', '==', spaces.find(s => s.current).name).get()).docs[0].id;
			return this.loggedInUser!.ref.set({
				currentSpace
			}, {
				merge: true
			}).then(() => {
				return this.loggedInUser!.ref.get().then(user => {
					this.loggedInUser = user as any;
					return true;
				});
			});
		});
	}

	addRoom(ip, port) {
		return this.getCurrentSpace().then(space => {
			let rooms = space.ref.collection('rooms');
			return rooms.where('engine.ip', '==', ip).where('engine.port', '==', Number(port)).get().then(room => {
				if (room.empty) {
					return rooms.add({
						engine: {
							ip,
							port: Number(port)
						}
					});
				} else {
					return Promise.resolve(room.docs[0].ref);
				}
			});
		});
	}

	removeRoom(ip, port) {
		return this.getCurrentSpace().then(space => {
			return space.ref.collection('rooms').where('engine.ip', '==', ip).where('engine.port', '==', Number(port)).get().then(room => {
				if (room.empty) {
					return null;
				} else {
					return room.docs[0].ref.delete();
				}
			});
		});
	}

	updateSpaceInfo(data) {
		return this.getCurrentSpace().then(space => {
			return space.ref.set(data, {
				merge: true
			}).then(() => {
				return true;
			});
		});
	}

	getSpaceInfo() {
		return this.getCurrentSpace().then(space => {
			return space.data();
		});
	}

	getParameters(ip, port) {
		return this.getCurrentSpace().then(space => {
			if (ip === 'multi') {
				return this.convertParametersFromFirebaseFormat(space.data()!['settings']);
			} else {
				return space.ref.collection('rooms').where('engine.ip', '==', ip).where('engine.port', '==', Number(port)).get().then(room => {
					if (room.empty) {
						return null;
					} else {
						return this.convertParametersFromFirebaseFormat(room.docs[0].data()['settings']);
					}
				});
			}
		});
	}

	setParameters(ip, port, parameters): any {
		return this.getCurrentSpace().then(space => {
			if (ip === 'multi') {
				return space.ref.set(this.convertParametersToFirebaseFormat(parameters), {
					merge: true
				}).then(() => {
					return parameters;
				});
			} else {
				return space.ref.collection('rooms').where('engine.ip', '==', ip).where('engine.port', '==', Number(port)).get().then(room => {
					return new Promise((resolve, reject) => {
						if (room.empty) {
							return space.ref.collection('rooms').add(Object.assign({
								engine: {
									ip,
									port: Number(port)
								}
							}, this.convertParametersToFirebaseFormat(parameters))).then(async (newRoom) => {
								resolve(this.convertParametersFromFirebaseFormat((await newRoom.get()).data()!['settings']));
							}).catch(reject);
						} else {
							return room.docs[0].ref.set(this.convertParametersToFirebaseFormat(parameters), {
								merge: true
							}).then(() => {
								resolve(this.convertParametersFromFirebaseFormat(room.docs[0].data()['settings']));
							}).catch(reject);
						}
					});
				});
			}
		});
	}

	private convertParametersToFirebaseFormat(parameters) {
		let ret = {
			settings: {}
		};

		Object.values(parameters).forEach((params: any) => {
			if (typeof params === 'object') {
				Object.keys(params).forEach(parameter => {
					if (params[parameter] === VALUE_TO_DELETE) {
						params[parameter] = firebase.firestore.FieldValue.delete();
					}
				});
			}
		});
		if (parameters['sensorParameters']) {
			Object.keys(parameters['sensorParameters']).forEach(key => {
				switch (key) {
					case 'ProcessorCfg.MonitoredRoomDims':
						if (parameters['sensorParameters'][key] && parameters['sensorParameters'][key].length) {
							if (!ret['settings']['room']) {
								ret['settings']['room'] = {};
							}
							ret['settings']['room']['MonitoredRoomDims'] = {
								xMin: parameters['sensorParameters'][key][0],
								xMax: parameters['sensorParameters'][key][1],
								yMin: parameters['sensorParameters'][key][2],
								yMax: parameters['sensorParameters'][key][3],
								zMin: parameters['sensorParameters'][key][4],
								zMax: parameters['sensorParameters'][key][5],
							};
						}
						break;
					case 'ProcessorCfg.Common.sensorOrientation.mountPlane':
						if (!ret['settings']['room']) {
							ret['settings']['room'] = {};
						}
						ret['settings']['room']['mountPlane'] = parameters['sensorParameters'][key];
						break;
					case 'ProcessorCfg.Common.sensorOrientation.transVec':
						if (parameters['sensorParameters'][key] && parameters['sensorParameters'][key].length) {
							if (!ret['settings']['room']) {
								ret['settings']['room'] = {};
							}
							ret['settings']['room']['sensorLocation'] = {
								local: {
									x: parameters['sensorParameters'][key][0],
									y: parameters['sensorParameters'][key][1],
									z: parameters['sensorParameters'][key][2]
								}
							};
						}
						break;
					// Targets
					case 'ProcessorCfg.TargetProperties.MaxPersonsInArena':
						if (!ret['settings']['targets']) {
							ret['settings']['targets'] = {};
						}
						ret['settings']['targets']['MaxPersonsInArena'] = parameters['sensorParameters'][key];
						break;
					/*case 'ProcessorCfg.TargetProperties.SittingMinHeight':
						if (!ret['settings']['targets']) {
							ret['settings']['targets'] = {};
						}
						if (!ret['settings']['targets']['postureThreshold']) {
							ret['settings']['targets']['postureThreshold'] = {};
						}
						ret['settings']['targets']['postureThreshold']['SittingMinHeight'] = parameters['sensorParameters'][key];
						break;
					case 'ProcessorCfg.TargetProperties.StandingMinHeight':
						if (!ret['settings']['targets']) {
							ret['settings']['targets'] = {};
						}
						if (!ret['settings']['targets']['postureThreshold']) {
							ret['settings']['targets']['postureThreshold'] = {};
						}
						ret['settings']['targets']['postureThreshold']['StandingMinHeight'] = parameters['sensorParameters'][key];
						break;*/
					// Recording
					case 'FlowCfg.save_dir_base':
						if (!ret['settings']['recording']) {
							ret['settings']['recording'] = {};
						}
						ret['settings']['recording']['save_dir_base'] = parameters['sensorParameters'][key];
						break;
					case 'FlowCfg.save_dir':
						if (!ret['settings']['recording']) {
							ret['settings']['recording'] = {};
						}
						ret['settings']['recording']['save_dir'] = parameters['sensorParameters'][key];
						break;
					case 'FlowCfg.allow_savedData_override':
						if (!ret['settings']['recording']) {
							ret['settings']['recording'] = {};
						}
						ret['settings']['recording']['allow_savedData_override'] = parameters['sensorParameters'][key];
						break;
					case 'ProcessorCfg.imgProcessing.substractionMode':
						ret['settings']['substractionMode'] = parameters['sensorParameters'][key];
						break;
					case 'ProcessorCfg.ExternalGUI.Zones.interestArea':
						if (parameters['sensorParameters'][key] && parameters['sensorParameters'][key].length) {
							ret['settings']['interestArea'] = JSON.stringify(parameters['sensorParameters'][key]);
						} else {
							ret['settings']['interestArea'] = firebase.firestore.FieldValue.delete();
						}
						break;
					case 'ProcessorCfg.ExternalGUI.Zones.names':
						if (parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'] && parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'].length) {
							ret['settings']['zonesNames'] = JSON.stringify(parameters['sensorParameters'][key]);
						} else {
							ret['settings']['zonesNames'] = firebase.firestore.FieldValue.delete();
						}
						break;
				}
			});
		}

		if (parameters['guiParameters']) {
			if ('selectedLayers' in parameters['guiParameters']) {
				ret['settings']['guiParameters'] = Object.assign({}, parameters['guiParameters'], {
					selectedLayers: JSON.stringify(parameters['guiParameters']['selectedLayers'])
				});
			} else {
				ret['settings']['guiParameters'] = parameters['guiParameters'];
			}
		}

		if (parameters['socketInfo']) {
			ret['settings']['socketInfo'] = parameters['socketInfo'];
		}

		return ret;
	}

	private convertParametersFromFirebaseFormat(parameters) {
		let ret = {};

		if (parameters) {
			Object.keys(parameters).forEach(key => {
				if (!ret['sensorParameters']) {
					ret['sensorParameters'] = {};
				}
				switch (key) {
					case 'interestArea':
						ret['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'] = JSON.parse(parameters[key]);
						break;
					case 'zonesNames':
						ret['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'] = JSON.parse(parameters[key]);
						break;
					case 'room':
						Object.keys(parameters[key]).forEach(key2 => {
							switch (key2) {
								case 'MonitoredRoomDims':
									ret['sensorParameters']['ProcessorCfg.MonitoredRoomDims'] = [
										parameters[key][key2]['xMin'],
										parameters[key][key2]['xMax'],
										parameters[key][key2]['yMin'],
										parameters[key][key2]['yMax'],
										parameters[key][key2]['zMin'],
										parameters[key][key2]['zMax'],
									];
									break;
								case 'mountPlane':
									ret['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'] = parameters[key][key2];
									break;
								case 'sensorLocation':
									Object.keys(parameters[key][key2]).forEach(key3 => {
										switch (key3) {
											case 'local':
												ret['sensorParameters']['ProcessorCfg.Common.sensorOrientation.transVec'] = [
													parameters[key][key2][key3]['x'],
													parameters[key][key2][key3]['y'],
													parameters[key][key2][key3]['z'],
												];
												break;
										}
									});
									break;
							}
						});
						break;
					case 'targets':
						Object.keys(parameters[key]).forEach(key2 => {
							switch (key2) {
								case 'MaxPersonsInArena':
									ret['sensorParameters']['ProcessorCfg.TargetProperties.MaxPersonsInArena'] = parameters[key][key2];
									break;
								/*case 'postureThreshold':
									Object.keys(parameters[key][key2]).forEach(key3 => {
										switch (key3) {
											case 'SittingMinHeight':
												ret['sensorParameters']['ProcessorCfg.TargetProperties.SittingMinHeight'] = parameters[key][key2][key3];
												break;
											case 'StandingMinHeight':
												ret['sensorParameters']['ProcessorCfg.TargetProperties.StandingMinHeight'] = parameters[key][key2][key3];
												break;
										}
									});
									break;*/
							}
						});
						break;
					case 'recording':
						Object.keys(parameters[key]).forEach(key2 => {
							switch (key2) {
								case 'save_dir_base':
									ret['sensorParameters']['FlowCfg.save_dir_base'] = parameters[key][key2];
									break;
								case 'save_dir':
									ret['sensorParameters']['FlowCfg.save_dir'] = parameters[key][key2];
									break;
								case 'allow_savedData_override':
									ret['sensorParameters']['FlowCfg.allow_savedData_override'] = parameters[key][key2];
									break;
							}
						});
						break;
					case 'substractionMode':
						ret['sensorParameters']['ProcessorCfg.imgProcessing.substractionMode'] = parameters[key];
						break;
					case 'guiParameters':
						if ('selectedLayers' in parameters[key]) {
							try {
								ret['guiParameters'] = Object.assign({}, parameters[key], {
									selectedLayers: JSON.parse(parameters[key]['selectedLayers'])
								});
							} catch (e) {
								console.error(e);
							}
						} else {
							ret['guiParameters'] = parameters[key];
						}
						break;
					case 'socketInfo':
						ret['socketInfo'] = parameters[key];
						break;
				}
			});
		}

		return ret;
	}

	private getCurrentSpace() {
		return this.loggedInUser!.ref.collection('spaces').doc(this.loggedInUser!.get('currentSpace')).get();
	}
}
