import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	NgZone,
	OnChanges, OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import * as L from 'leaflet';
import {Subscription} from 'rxjs';
import {BusEventService} from '../../../../services/bus-event.service';
import {rotateBase64Image, rotateLeafletGeometry} from '../../../../utils/utils';
require('../../../../../assets/js/leaflet.latlng-graticule');

/**
 * Base class for Leaflet-based layers.
 */
@Component({
	templateUrl: './leaflet.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeafletLayerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

	@Input() data: any = {};
	@Input() parameters: any = {};
	@Input() isSensorVisible = true;

	@Output() rendered = new EventEmitter();

	@ViewChild('map') mapElement: ElementRef;

	localData: any = {};

	protected map: L.Map;
	protected imageOverlay: L.ImageOverlay;
	protected imageSrc;
	protected arena: any = {};
	protected subscriptions: Array<Subscription> = [];
	protected busEventService: BusEventService;
	protected ngZone: NgZone;
	protected zoomPromise: Promise<any>;
	protected zoomPromiseResolver;

	protected roomHeight = 0;
	protected roomWidth = 0;
	public rotationDegree = 0;

	constructor(protected injector: Injector) {
		this.busEventService = injector.get(BusEventService);
		this.ngZone = injector.get(NgZone);
		this.zoomPromise = new Promise<any>(resolve => {
			this.zoomPromiseResolver = resolve;
		});
	}

	ngOnInit() {
		this.subscriptions.push(this.busEventService.multipleRoomsParamsChanged.subscribe(info => {
			this.parameters = Object.assign({}, this.parameters, {socketInfo: info});
			this.updateGlobalArena();
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	async ngAfterViewInit() {
		await Promise.resolve();
		this.ngZone.runOutsideAngular(() => {
			this.map = L.map(this.mapElement.nativeElement, {
				attributionControl: false,
				zoom: 1,
				center: [0, 0],
				minZoom: -1,
				crs: L.CRS.Simple,
				zoomControl: false
			} as any);
			L.latlngGraticule({
				showLabel: true,
				dashArray: [2, 2],
				sides: ['', '-', '', '-']
			}).addTo(this.map);
		});
		this.updateGlobalArena();
		setTimeout(() => {
			this.rendered.emit();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('data' in changes) {
			this.localData = this.concatCombinedData(this.data);
			setTimeout(() => {
				this.rendered.emit();
			});
		}
	}

	public rotate(degree, absolute = false) {
		this.rotationDegree = absolute ? degree : (this.rotationDegree + 90) % 360;
		this.updateGlobalArena();
	}

	protected updateGlobalArena() {
		this.ngZone.runOutsideAngular(async () => {
			if (this.imageOverlay) {
				this.map.removeLayer(this.imageOverlay);
			}
			if (this.parameters['socketInfo']['backgroundImage']) {
				let previousImage = this.imageSrc;
				if (this.parameters['socketInfo']['backgroundImage']) {
					this.imageSrc = await rotateBase64Image(this.parameters['socketInfo']['backgroundImage'], this.rotationDegree);
				}
				this.arena = [0, +this.parameters['socketInfo']['globalRoomWidth'] || 0, 0, +this.parameters['socketInfo']['globalRoomHeight'] || 0, 0, 0];
				let {globalRoomWidth, globalRoomHeight} = this.parameters['socketInfo'];

				if (this.imageSrc) {
					let img = new Image();

					let promise = new Promise(resolve => {
						img.onload = () => {
							if (!+globalRoomHeight) {
								globalRoomHeight = 1;
							}
							if (!+globalRoomWidth) {
								globalRoomWidth = img.naturalWidth / img.naturalHeight;
							}
							if (previousImage && previousImage !== this.imageSrc && !this.rotationDegree) {
								let c = img.naturalWidth / img.naturalHeight;
								globalRoomWidth = globalRoomHeight * c;
								this.busEventService.newFloorDimensions.next({
									max_x: globalRoomWidth,
									max_y: globalRoomHeight
								});
							}
							resolve();
						};
					});
					img.src = this.imageSrc;
					await promise;
					this.roomHeight = globalRoomHeight;
					this.roomWidth = globalRoomWidth;
				} else {
					this.roomHeight = 0;
					this.roomWidth = 0;
				}
				let bounds: L.LatLngBoundsLiteral = rotateLeafletGeometry(
					this.rotationDegree,
					[
						[0, 0],
						[+globalRoomWidth || 1, +globalRoomHeight || 1]
					]
				);
				this.map.fitBounds(bounds);
				this.map.on('zoomend', () => {
					this.zoomPromiseResolver.call(this);
				});
				if (this.parameters['socketInfo']['backgroundImage'] && !this.map.hasLayer(this.imageOverlay)) {
					this.imageOverlay = L.imageOverlay(this.imageSrc, bounds).addTo(this.map);
				}
			}
		});
	}

	protected concatCombinedData(data = {}) {
		let ret = {};
		Object.keys(data).forEach(output => {
			ret[output] = [].concat(...data[output]);
		});

		return ret;
	}
}
