import {EventEmitter, Injectable, Injector} from '@angular/core';
import {StorageService} from '../storage.service';
import {DataService} from './data.service';
import {PlaybackState, RunMode, SensorSocket} from './sensor-socket';
import {SettingsService} from '../settings.service';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {
	configurationHasControl,
	getChangedParameters,
	getNonCacheableSensorParameters,
	isSensorInLoadAndSaveDataMode,
	isSensorInRecordingRawDataMode,
	isSensorInReprocessMode,
	makeDeepCopy
} from '../../utils/utils';
import {BusEventService} from '../bus-event.service';
import {MultiSensorsSocket} from './multi-sensors-socket';
import {HomeAutomationServerSocket} from './ham-socket';
import {FirebaseService} from '../firebase.service';
import {ModalService} from '../modal.service';
import {MqttSocket} from './mqtt-socket';
import {ConfigurablePageControlType, ConfigurationService, Layer} from '../configuration.service';

import {ConnectionStatus} from './connection';
import {SignalrConnection} from './signalr-connection';
import {ToolbarService} from '../toolbar.service';
import {OutputType} from '../../models/models';

/**
 * Service for working with sensors.
 * Creates, connect, disconnect etc to socket.
 */
@Injectable({
	providedIn: 'root'
})
export class SensorsService {

	readonly sensorSockets: BehaviorSubject<Array<SensorSocket>> = new BehaviorSubject([]);
	readonly mqttSockets: BehaviorSubject<Array<MqttSocket>> = new BehaviorSubject([]);
	readonly parametersChange = new EventEmitter();
	readonly anotherClientParametersChanged = new EventEmitter();
	readonly anotherClientSensorLocationDataChanged = new EventEmitter();
	readonly connect: EventEmitter<SensorSocket> = new EventEmitter();
	readonly disConnect: EventEmitter<SensorSocket> = new EventEmitter();
	readonly hamDevices = new BehaviorSubject({
		devices: []
	});
	readonly eventListeners = {
		statusChange: {},
		dataRetrieve: {},
		metaDataRetrieve: {},
		parametersChange: {}
	};

	private socketPool = {};
	private mqttPool = {};
	private signalrPool = {};
	private multiSensorSocket = new MultiSensorsSocket(this);
	private homeAutomationServerSocket;
	private tmpSocketSettings = {};
	private parametersCache = {};
	private cleanColorTableIntervalIDs = new Map();

	private restoreCurrentSettingsAfterPlayback = async () => {
		if (Object.keys(this.tmpSocketSettings).length) {
			Object.keys(this.tmpSocketSettings).forEach(url => {
				this.settingsService.saveSensorParameters(url, JSON.parse(this.tmpSocketSettings[url]));
				delete this.tmpSocketSettings[url];
			});
		}
	};

	constructor(private storageService: StorageService,
				private dataService: DataService,
				private settingsService: SettingsService,
				private firebaseService: FirebaseService,
				private modalService: ModalService,
				private inject: Injector,
				private toolbarService: ToolbarService) {

		window.addEventListener('beforeunload', async () => {
			await this.restoreCurrentSettingsAfterPlayback();
		});
	}

	triggerParametersChange(url, parameters?) {
		this.parametersChange.emit({url, parameters});
		if (parameters) {
			let t = {};
			let socket = this.getSensorSocket(url);

			getNonCacheableSensorParameters(socket).forEach(parameter => {
				if (parameter in parameters) {
					t[parameter] = parameters[parameter];
				} else if (this.parametersCache[url] && parameter in this.parametersCache[url]) {
					t[parameter] = this.parametersCache[url][parameter];
				}
			});
			this.parametersCache[url] = Object.assign({}, parameters, t);
		}
		if (this.eventListeners.parametersChange[url]) {
			this.eventListeners.parametersChange[url].next(this.parametersCache[url]);
		}
	}

	// create a new sensor socket object and adds it to the pool
	createSensorSocket(ip, port): Promise<SensorSocket> {
		let socket = new SensorSocket(ip, port);
		this.socketPool[socket.url] = socket;
		let configurationService = this.inject.get(ConfigurationService);
		let onParametersChange = async (p) => {
			if ('ProcessorCfg.ExternalGUI.SensorsNetwork.enable' in p && p['ProcessorCfg.ExternalGUI.SensorsNetwork.enable']) {
				socket.markThisAsSensorsNetworkEnabled();
			}
			if ('ProcessorCfg.ExternalGUI.Covid' in p && p['ProcessorCfg.ExternalGUI.Covid']) {
				socket.markThisAsCovidDetection();
			}
			if ('ProcessorCfg.ExternalGUI.InventoryDemo' in p && p['ProcessorCfg.ExternalGUI.InventoryDemo']) {
				socket.markThisAsInventoryDemo();
			}
			if ('ProcessorCfg.ExternalGUI.RoomOccupancy' in p && p['ProcessorCfg.ExternalGUI.RoomOccupancy']) {
				socket.markThisAsRoomOccupancy();
			}
			if ('ProcessorCfg.ExternalGUI.SmartHome' in p && p['ProcessorCfg.ExternalGUI.SmartHome']) {
				socket.markThisAsSmartHome();
			}

			if ('ProcessorCfg.ExternalGUI.OutputsDataType' in p) {
				let outputsType: OutputType;
				switch (p['ProcessorCfg.ExternalGUI.OutputsDataType']) {
					case 'BINARY_DATA':
						outputsType = 'binary_outputs';
						break;
					case 'JSON_DATA':
						outputsType = 'json_outputs';
						break;
					default:
						outputsType = environment.outputsType as OutputType;
						break;
				}
				environment.outputsType = outputsType;
			}

			// Check arrays
			['ProcessorCfg.ExternalGUI.Zones.interestArea', 'ProcessorCfg.ExternalGUI.Zones.names'].forEach(parameter => {
				if (parameter in p) {
					if (p[parameter]) {
						if (!(p[parameter][0] instanceof Array)) {
							p[parameter] = [p[parameter]];
						}
					} else {
						p[parameter] = [];
					}
				}
			});

			if ('ProcessorCfg.MonitoredRoomDims(1)' in p && 'ProcessorCfg.MonitoredRoomDims(6)' in p) {
				p['ProcessorCfg.MonitoredRoomDims'] = [
					p['ProcessorCfg.MonitoredRoomDims(1)'],
					p['ProcessorCfg.MonitoredRoomDims(2)'],
					p['ProcessorCfg.MonitoredRoomDims(3)'],
					p['ProcessorCfg.MonitoredRoomDims(4)'],
					p['ProcessorCfg.MonitoredRoomDims(5)'],
					p['ProcessorCfg.MonitoredRoomDims(6)']
				];
				delete p['ProcessorCfg.MonitoredRoomDims(1)'];
				delete p['ProcessorCfg.MonitoredRoomDims(2)'];
				delete p['ProcessorCfg.MonitoredRoomDims(3)'];
				delete p['ProcessorCfg.MonitoredRoomDims(4)'];
				delete p['ProcessorCfg.MonitoredRoomDims(5)'];
				delete p['ProcessorCfg.MonitoredRoomDims(6)'];
			}

			let cachedParameters = await this.settingsService.getSensorParameters(socket.url);
			if (cachedParameters) {
				Object.keys(p).forEach(parameter => {
					if (socket.isCacheableParameter(parameter) && parameter in cachedParameters!) {
						p[parameter] = cachedParameters![parameter];
					}
				});
			}

			return this.settingsService.saveSensorParameters(socket.url, p).then(parameters => {
				let t = {};
				getNonCacheableSensorParameters(socket).forEach(parameter => {
					if (parameter in p) {
						t[parameter] = p[parameter];
					} else if (this.parametersCache[socket.url] && parameter in this.parametersCache[socket.url]) {
						t[parameter] = this.parametersCache[socket.url][parameter];
					}
				});
				this.triggerParametersChange(socket.url, Object.assign({}, t, parameters));
			});
		};
		this.updateSubject();
		this.saveSensorSocket(socket);
		this.eventListeners.parametersChange[socket.url] = new BehaviorSubject(this.parametersCache[socket.url]);
		this.eventListeners.dataRetrieve[socket.url] = new BehaviorSubject({});
		this.eventListeners.metaDataRetrieve[socket.url] = new BehaviorSubject({});
		socket.data.subscribe(e => {
			let pageName = this.toolbarService.getRoute().data.pageName || 'Sensor',
				layers = ([] as Array<any>).concat(configurationService.pageMap[pageName].layers as Array<Layer>, environment.floorPlanLayers, environment.roomOccupancyLayers),
				parameters = Object.assign({}, this.parametersCache[socket.url], {
					'ProcessorCfg.ExternalGUI.Layers': layers
				});

			this.dataService.updateData(socket.url, e.data, parameters);
			this.eventListeners.dataRetrieve[socket.url].next(e.data);
			this.multiSensorSocket.updateAverageFPS();
		});
		socket.close.subscribe(byTimeout => {
			this.disConnect.emit(socket);
		});
		socket.message.subscribe(e => {
			this.modalService.onServerSideMessage(e);
		});
		socket.status.subscribe(status => {
			this.updateMultiSensorSocketStatus();
			let listeners = this.eventListeners.statusChange[socket.url];

			if (listeners && listeners.length) {
				listeners.forEach(listener => listener(status));
			}
		});
		socket.parameters.subscribe(e => {
			if (socket.isParametersRetrieved()) {
				onParametersChange(e.data);
			}
		});
		socket.configuration.subscribe(configuration => {
			let pages: any = [];
			try {
				pages = JSON.parse(configuration);
			} catch (e) {
				console.error(e);
			}
			configurationService
				.removeConfiguration(socket.url)
				.then(() => {
					return configurationService.addConfiguration(socket.url, pages, environment.FLOW_PROCESSOR_MODULE_PATH);
				})
				.then(() => {
					this.triggerParametersChange(socket.url);
				});
		});
		socket.anotherClientParametersChanged.subscribe(parameters => {
			this.modalService.showMessage('ANOTHER_CLIENT_PARAMETERS_CHANGE_NOTIFICATION', undefined, 'RELOAD_SETTINGS').afterClosed().toPromise().then(() => {
				onParametersChange(parameters).then(() => {
					this.anotherClientParametersChanged.emit(parameters);
				});
			});
		});
		socket.anotherClientSensorLocationDataChanged.subscribe(async (data) => {
			let newSensorNetworkParameters = {
					globalSensorLocation: data['ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.position'],
					globalSensorOrientation: data['ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.orientation']
				},
				settings = await this.settingsService.getAllSettings(socket.url),
				diff = getChangedParameters(newSensorNetworkParameters, {
					globalSensorLocation: settings['sensorNetworkParameters']['globalSensorLocation'],
					globalSensorOrientation: settings['sensorNetworkParameters']['globalSensorOrientation']
				});
			if (Object.keys(diff).length) {
				this.modalService.showMessage('ANOTHER_CLIENT_PARAMETERS_CHANGE_NOTIFICATION', undefined, 'RELOAD_SETTINGS').afterClosed().toPromise().then(() => {
					this.settingsService.saveAllSettings(socket.url, newSensorNetworkParameters).then(() => {
						this.anotherClientSensorLocationDataChanged.emit(data);
					});
				});
			}
		});
		socket.metadata.subscribe(metadata => {
			this.settingsService.getSensorParameters(socket.url).then(settings => {
				var parameters = this.settingsService.extractSettingsFromMetadata(metadata);
				if (settings) {
					parameters['FlowCfg.save_dir'] = settings['FlowCfg.save_dir'];
					parameters['FlowCfg.read_from_file'] = settings['FlowCfg.read_from_file'];
					parameters['FlowCfg.allow_savedData_override'] = settings['FlowCfg.allow_savedData_override'];
				}
				this.tmpSocketSettings[socket.url] = JSON.stringify(settings);
				this.settingsService.saveSensorParameters(socket.url, parameters).then(() => {
					this.triggerParametersChange(socket.url, parameters);
				});
			});
			this.eventListeners.metaDataRetrieve[socket.url].next(metadata);
		});
		socket.sensorLocationData.subscribe(data => {
			this.settingsService.saveAllSettings(socket.url, {
				globalSensorLocation: data['ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.position'],
				globalSensorOrientation: data['ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.orientation']
			});
		});

		return new Promise(resolve => {
			if (environment.offline) {
				resolve(socket);
			} else {
				this.firebaseService.addRoom(ip, port).then(() => {
					resolve(socket);
				});
			}
		});
	}

	createMqttSocket(ip, port): MqttSocket {
		let socket = new MqttSocket(ip, port);
		this.mqttPool[socket.url] = socket;
		this.eventListeners.dataRetrieve[socket.url] = new BehaviorSubject({});
		socket.data.subscribe(e => {
			let data: any = this.dataService.prepareDataFromMqttBroker(e.data, e.topic);
			this.eventListeners.dataRetrieve[socket.url].next(data);
			this.dataService.updateData(socket.url, data, {});
		});
		return socket;
	}

	createSignalrSocket(ip): SignalrConnection {
		let socket = new SignalrConnection(ip);
		this.signalrPool[socket.url] = socket;
		this.eventListeners.dataRetrieve[socket.url] = new BehaviorSubject({});
		socket.data.subscribe(e => {
			let data: any = this.dataService.prepareDataFromSignalr(e.data);
			this.eventListeners.dataRetrieve[socket.url].next(data);
			this.dataService.updateData(socket.url, data, {});
		});
		return socket;
	}

	createHomeAutomationServerSocket(ip, port) {
		let busEventService = this.inject.get(BusEventService);
		var socket = new HomeAutomationServerSocket(ip, port);
		this.homeAutomationServerSocket = socket;
		this.homeAutomationServerSocket.devicesLocations.subscribe(devicesLocations => {
			this.storageService.getItem('spaceInfo').then(spaceInfo => {
				let devices = {};
				(devicesLocations || []).forEach(device => {
					devices[device.name] = {
						id: device['room_ip'],
						name: device['room_name']
					};
				});
				let diff = getChangedParameters(spaceInfo.devices, devices);
				if (Object.keys(diff).length) {
					this.modalService.showMessage('ANOTHER_CLIENT_PARAMETERS_CHANGE_NOTIFICATION', undefined, 'RELOAD_SETTINGS').afterClosed().toPromise().then(() => {
						this.storageService.setItem('spaceInfo', {devices}).then(() => {
							this.storageService.getItem('spaceInfo').then(s => {
								busEventService.smartHomeParamsChanged.emit(s);
							});
						});
					});
				}
			});
		});
		this.homeAutomationServerSocket.rules.subscribe(rules => {
			this.storageService.getItem('spaceInfo').then(spaceInfo => {
				let diff = getChangedParameters({
					rules: spaceInfo.rules
				}, {
					rules: rules.rules
				});
				if (Object.keys(diff).length) {
					this.modalService.showMessage('ANOTHER_CLIENT_PARAMETERS_CHANGE_NOTIFICATION', undefined, 'RELOAD_SETTINGS').afterClosed().toPromise().then(() => {
						this.storageService.setItem('spaceInfo', rules).then(() => {
							this.storageService.getItem('spaceInfo').then(s => {
								busEventService.smartHomeParamsChanged.emit(s);
							});
						});
					});
				}
			});

		});
		this.saveHomeAutomationServerSocket();
		return socket as any;
	}

	// remove sensor socket by url
	removeSensorSocket(url, permanently = true): Promise<any> {
		var socket = this.socketPool[url];
		if (socket) {
			this.disconnectSocket(socket);
			delete this.socketPool[url];
			this.updateSubject();
			// also remove from storage
			if (permanently) {
				this.settingsService.clearAllSetting(url);
				return this.storageService.setItem('socketPool', Object.keys(this.socketPool));
			} else {
				return this.storageService.setItem('socketPool', Object.keys(this.socketPool));
			}
		} else {
			return Promise.resolve();
		}
	}

	removeMqttSocket(url): Promise<any> {
		var socket = this.socketPool[url];
		if (socket) {
			this.disconnectSocket(socket);
			delete this.socketPool[url];
		}
		return Promise.resolve();
	}

	removeSensorSocketsAll(permanently = true): Promise<any> {
		let pool: Array<any> = [];
		Object.keys(this.socketPool).forEach(url => {
			pool.push(this.removeSensorSocket(url, permanently));
		});
		return Promise.all(pool);
	}

	// get sensor socket by url
	getSensorSocket(url): SensorSocket {
		return this.socketPool[url];
	}

	getMultiSensorSocket() {
		return this.multiSensorSocket;
	}

	getHomeAutomationServerSocket() {
		return this.homeAutomationServerSocket;
	}

	getMqttSocket(url): MqttSocket {
		return this.mqttPool[url];
	}

	getSignalrConnection(url): SignalrConnection {
		return this.signalrPool[url];
	}

	// connect to specific sensor
	connectSocket(socket: SensorSocket, callback?) {

		/* Steps to take when connecting:
			- Connect to when socket and wait for callback
			- Set default json & binary outputs
			- Call the "START" command
			- Retrieve the sensor current status
			- Start querying the sensor for json & binary data in a loop till asked to stop or disconnected
		*/

		var connectedCallback = () => {
			this.setRoomsState();
			socket.getSensorParameters({
				[environment.supportedSensorParametersListParameterName]: null,
				[environment.configurablePagesParameterName]: null
			}).then(payload => {
				let pages: any = [];
				let configurationService = this.inject.get(ConfigurationService);
				try {
					pages = JSON.parse(payload[environment.configurablePagesParameterName]);
				} catch (e) {
					console.error(e);
				}

				let parameters: any = [];
				try {
					parameters = JSON.parse(payload[environment.supportedSensorParametersListParameterName]);
				} catch (e) {
					console.error(e);
				}
				let parametersToAsk = Object.fromEntries(parameters.map(parameter => [parameter.name, null]));
				parameters.forEach(parameter => {
					socket.addSupportedParameter(parameter);
				});
				return socket.getSensorParameters(parametersToAsk).then(() => {
					return configurationService.addConfiguration(socket.url, pages, environment.FLOW_PROCESSOR_MODULE_PATH);
				});
			}).then(() => {
				if (socket.isSensorsNetworkEnabled) {
					socket.getNetworkSensorLocationData();
				}

				this.processSocketStatuses(socket);
				socket.retrieveStatus();

				if (callback) {
					callback(true);
					callback = null;
				}
				this.connect.emit(socket);
				// socket.queryBinaryData();
			}).catch(error => {
				console.error(error);
				if (callback) {
					callback(false);
					callback = null;
				}
			});
		};

		socket.connect().then(connectedCallback, canceledByUser => {
			// If not canceled by user - it's network error.
			if (!(typeof canceledByUser === 'boolean' && canceledByUser)) {
				if (callback) {
					callback(false);
					callback = null;
				}
			}
		});

		this.settingsService.saveSocketInfo(socket.url, {
			lastUsed: +(new Date)
		});
	}

	connectHomeAutomationServerSocket(socket) {
		socket.connect().then(() => {
			this.setRoomsState();
			socket.devicesStatus.subscribe(devices => {
				this.hamDevices.next(devices);
			});
			this.storageService.getItem('socketPool').then(socketPool => {
				var pool = (socketPool || []).map(url => {
					return this.settingsService.getAllSettings(url);
				});
				Promise.all(pool).then((settings: any) => {
					socketPool.forEach((url, i) => {
						let roomName = settings[i]['socketInfo'] && settings[i]['socketInfo']['name'] || url,
							zones = this.settingsService.prepareZonesToSocket(settings[i]['sensorParameters'], roomName);

						if (zones.length) {
							socket.setZones(zones);
						}
					});
					socket.getDevices().then(devices => {
						this.hamDevices.next(devices);
						this.storageService.getItem('spaceInfo').then(spaceInfo => {
							let devicesNames = devices.devices.map(d => d.name);
							let socketsNames = socketPool.map((url, i) => settings[i]['socketInfo'].name || url);
							(socket as any).setDevidesLocation(Object.keys(spaceInfo.devices).filter(device => devicesNames.includes(device)).map(device => {
								return {
									name: device,
									room_name: socketsNames.includes(spaceInfo.devices[device].name) ? spaceInfo.devices[device].name : '',
									room_ip: socketsNames.includes(spaceInfo.devices[device].name) ? spaceInfo.devices[device].ip : ''
								};
							}));
							if (spaceInfo.rules) {
								let zonesNamesByRooms = {};

								settings.forEach((parameters, i) => {
									let roomName = parameters['socketInfo'] && parameters['socketInfo'].name || this.socketPool[i];
									if (parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names']) {
										zonesNamesByRooms[roomName] = parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'];
									}
								});
								let rules = (spaceInfo.rules || []).filter(rule => {
									let triggersIsValid = rule.rule.triggers.every(t => socketsNames.includes(t.roomName) && zonesNamesByRooms[t.roomName] && zonesNamesByRooms[t.roomName].includes(t.zoneName)),
										actionsIsValid = rule.rule.actions.every(a => socketsNames.includes(a.roomName));

									return triggersIsValid && actionsIsValid;
								});
								rules.forEach(r => {
									if (!('isActive' in r)) {
										r['isActive'] = true;
									}
								});
								(socket as any).setRules({
									rules
								});
							}
						});
					});
				});
			});

		}, error => {
			// Canceled by user
			if (!(typeof error === 'boolean' && error)) {
				console.log(error);
			}
		});
	}

	// disconnect from sensor
	disconnectSocket(socket: SensorSocket | MqttSocket) {
		socket.disconnect();
	}

	sendSettings(socket: SensorSocket, notify: boolean, settings?) {
		var resolve,
			promise = new Promise((r) => {
				resolve = r;
			}),
			cb = async (settingsToSend) => {
				let filteredSettingsToSend = {},
					notSended: any[] = [];
				Object.keys(settingsToSend).forEach(parameter => {
					let isEditable = socket.isEditableParameter(parameter);
					if (isEditable) {
						filteredSettingsToSend[parameter] = settingsToSend[parameter];
					} else {
						notSended.push(parameter);
					}
				});
				settingsToSend = filteredSettingsToSend;
				this.parametersCache[socket.url] = await this.settingsService.getSensorParameters(socket.url);
				if (Object.keys(settingsToSend).length) {
					try {
						await socket.setSensorParameters(settingsToSend, notify);
					} catch (e) {
						console.error(e);
					}
				} else {
					this.triggerParametersChange(socket.url, this.parametersCache[socket.url]);
				}
				if (notSended.length) {
					console.warn('These settings will not be sent to socket: ' + notSended);
				}
				resolve();
			};

		if (settings && socket.isConnected()) {
			cb(settings);
		} else {
			this.settingsService.getSensorParameters(socket.url).then(data => {
				if (socket.isConnected()) {
					cb(data);
				}
			});
		}

		return promise;
	}

	updateSensorRunMode(socket: SensorSocket, mode, parameters) {
		let cb = async (sensorSocket: SensorSocket) => {
			sensorSocket.stopQueryData();
			sensorSocket.disposeRecordingInfo();
			sensorSocket.createRecordingInfo(mode, parameters);
		};
		if (socket.isMultipleSensors) {
			this.sensorSockets.value
				.filter(sensorSocket => sensorSocket.isConnected() && sensorSocket.isSensorsNetworkEnabled)
				.forEach(sensorSocket => cb(sensorSocket));
		} else {
			cb(socket);
		}
	}

	async runSensor(socket: SensorSocket | MqttSocket | SignalrConnection, runMode?: RunMode, recordingParameters?) {
		if (socket instanceof SensorSocket) {
			let busEventService = this.inject.get(BusEventService),
				setNetworkSensorLocationDataAndZones = async () => {
					if (socket.isSensorsNetworkEnabled) {
						return this.settingsService.getAllSettings(socket.url).then(settings => {
							let pool: Array<any> = [];

							pool.push(socket.setNetworkSensorLocationData({
								'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.position': settings['sensorNetworkParameters']['globalSensorLocation'],
								'ProcessorCfg.ExternalGUI.SensorsNetwork.LocalSensor.GlobalLocation.orientation': settings['sensorNetworkParameters']['globalSensorOrientation']
							}).catch(console.error.bind(console)));

							return Promise.all(pool);
						});
					} else {
						return Promise.resolve();
					}
				};

			busEventService.run.emit(socket.url);
			this.updateSensorRunMode(socket, runMode, recordingParameters);

			if (socket.isRunned()) {
				socket.stop();
			}
			let parameters;

			switch (socket.recordingInfo.mode) {
				case RunMode.LIVE:
					parameters = await this.getParameterForLiveMode(socket);
					break;
				case RunMode.REPROCESS_IQ_DATA:
					parameters = await this.getParameterForReprocessMode(socket);
					break;
				case RunMode.RECORD:
					parameters = await this.getParameterForPlayAndRecordMode(socket);
					break;
				case RunMode.REPROCESS_AND_RECORD:
					parameters = await this.getParameterForReprocessAndRecordMode(socket);
					break;
				case RunMode.PLAYBACK:
					parameters = await this.getParameterForPlaybackMode(socket);
					break;
			}

			let parametersToSave = makeDeepCopy(parameters),
				cleanColorTableInterval = parameters['Cfg.Tracker.PointTracker.TargetsGraves.MaximumGraveDuration'];

			this.stopCleanColorTableInterval(socket);
			if (socket.recordingInfo.isPlayback()) {
				this.settingsService
					.saveSensorParameters(socket.url, parametersToSave)
					.then(() => this.sendSettings(socket, false, parameters))
					.then(() => socket.getRecMetadata())
					.then(() => {
						socket.setOutputs();
						socket.recordingInfo.play();
					})
					.then(() => {
						if (cleanColorTableInterval) {
							this.cleanColorTableIntervalIDs.set(socket, setInterval(() => {
								busEventService.cleanColorTable.next(cleanColorTableInterval);
							}, cleanColorTableInterval));
						}
					});
			} else {
				this.settingsService
					.saveSensorParameters(socket.url, parametersToSave)
					.then(setNetworkSensorLocationDataAndZones)
					.then(() => this.sendSettings(socket, false, parameters))
					.then(() => socket.start())
					.then(() => {
						if (cleanColorTableInterval) {
							this.cleanColorTableIntervalIDs.set(socket, setInterval(() => {
								busEventService.cleanColorTable.next(cleanColorTableInterval);
							}, cleanColorTableInterval));
						}
					});
			}
		} else {
			if (!socket.isRunned()) {
				socket.start();
			}
		}
	}

	stopSensor(socket: SensorSocket | MqttSocket | SignalrConnection, showRecordingSavedPopup = true) {
		if (socket instanceof SensorSocket) {
			let busEventService = this.inject.get(BusEventService);

			busEventService.stop.emit(socket.url);
			this.setRoomsState();
			if (socket.recordingInfo.isRecording() && showRecordingSavedPopup) {
				busEventService.sensorRecordingSaved.emit();
			}
			socket.stopQueryData();
			switch (socket.recordingInfo.mode) {
				case RunMode.RECORD:
				case RunMode.REPROCESS_AND_RECORD:
					socket.recordingInfo.stopTimer();
					socket.recordingInfo.reset();
					break;
				case RunMode.PLAYBACK:
					if (socket.recordingInfo.state === PlaybackState.PLAYING) {
						socket.recordingInfo.stop(true);
					}
					let parametersToSave = JSON.parse(this.tmpSocketSettings[socket.url]);
					this.settingsService.saveSensorParameters(socket.url, parametersToSave).then(() => {
						delete this.tmpSocketSettings[socket.url];
						socket.disposeRecordingInfo();
					});
					break;
			}
			this.updateSensorRunMode(socket, RunMode.LIVE, {path: ''});
		} else {
			socket.disconnect();
		}
	}


	getRealSettings(url) {
		return this.tmpSocketSettings[url] ? JSON.parse(this.tmpSocketSettings[url]) : void 0;
	}


	validateAndUpdateRules() {
		let busEventService = this.inject.get(BusEventService);
		this.storageService.getItem('socketPool').then(socketPool => {
			this.storageService.getItem('spaceInfo').then(spaceInfo => {
				var pool2 = (socketPool || []).map(url => {
					return this.settingsService.getAllSettings(url);
				});
				Promise.all(pool2).then((settings: any) => {
					let socketsNames = settings.map((parameters: any, i) => parameters['socketInfo'] && parameters['socketInfo'].name || socketPool[i]),
						zonesNamesByRooms = {};

					settings.forEach((parameters, i) => {
						let roomName2 = parameters['socketInfo'] && parameters['socketInfo'].name || socketPool[i];
						if (parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names']) {
							zonesNamesByRooms[roomName2] = parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'];
						}
					});
					let rules = (spaceInfo.rules || []).filter(rule => {
						let triggersIsValid = rule.rule.triggers.every(t => socketsNames.includes(t.roomName) && zonesNamesByRooms[t.roomName] && zonesNamesByRooms[t.roomName].includes(t.zoneName)),
							actionsIsValid = rule.rule.actions.every(a => socketsNames.includes(a.roomName));

						return triggersIsValid && actionsIsValid;
					});

					this.storageService.setItem('spaceInfo', {rules}).then(() => {
						this.storageService.getItem('spaceInfo').then(spaceInfo2 => {
							busEventService.smartHomeParamsChanged.emit(spaceInfo2);
						});
					});

					let has = this.getHomeAutomationServerSocket();
					if (has && has.isConnected()) {
						// SET_RULES
						has.setRules({
							rules
						});
					}
				});
			});
		});
	}

	private async getParameterForLiveMode(socket) {
		let sensorParameters = await this.settingsService.getSensorParameters(socket.url);

		return this.prepareParametersBeforeImaging(sensorParameters);
	}

	private async getParameterForReprocessMode(socket) {
		let sensorParameters = await this.settingsService.getSensorParameters(socket.url);

		return this.prepareParametersBeforeImaging(sensorParameters, 1, 0, 0, 0, 0, 0, socket.recordingInfo.path);
	}

	private async getParameterForPlaybackMode(socket) {
		let sensorParameters = await this.settingsService.getSensorParameters(socket.url);

		return this.prepareParametersBeforeImaging(sensorParameters, 1, 0, 0, 0, 0, 0, socket.recordingInfo.path);
	}

	private async getParameterForPlayAndRecordMode(socket) {
		let settings = await this.settingsService.getAllSettings(socket.url);

		return this.prepareParametersBeforeImaging(settings['sensorParameters'], 0,
			settings['sensorParameters']['FlowCfg.save_to_file'],
			settings['sensorParameters']['FlowCfg.save_image_to_file'],
			settings['sensorParameters']['FlowCfg.save_pointCloud_to_file'],
			settings['sensorParameters']['ProcessorCfg.OutputData.save_to_file'],
			settings['sensorParameters']['FlowCfg.save_outputs_to_file'], socket.recordingInfo.path);
	}

	private async getParameterForReprocessAndRecordMode(socket) {
		let settings = await this.settingsService.getAllSettings(socket.url);

		return this.prepareParametersBeforeImaging(settings['sensorParameters'], 1,
			0,
			settings['sensorParameters']['FlowCfg.save_image_to_file'],
			settings['sensorParameters']['FlowCfg.save_pointCloud_to_file'],
			settings['sensorParameters']['ProcessorCfg.OutputData.save_to_file'],
			settings['sensorParameters']['FlowCfg.save_outputs_to_file'], socket.recordingInfo.path);
	}

	private processSocketStatuses(socket: SensorSocket) {
		let previousStatus = socket.connectionStatus,
			lastParameters = socket.lastParameters,
			configurationService = this.inject.get(ConfigurationService),
			sub = socket.status.subscribe(async (status) => {
				let
					pages = configurationService.configurationByUrl[socket.url].pages,
					// "Record" button was clicked
					sensorInRecordingRawDataMode = configurationHasControl(pages, ConfigurablePageControlType.PAGE_RECORD) && isSensorInRecordingRawDataMode(lastParameters),
					// "Loading recording" or "Load & Save" was clicked
					sensorInReprocessMode = (
						configurationHasControl(pages, ConfigurablePageControlType.PAGE_LOAD_RECORDING) ||
						configurationHasControl(pages, ConfigurablePageControlType.PAGE_LOAD_AND_SAVE)
					) && isSensorInReprocessMode(lastParameters),
					//  Additional save data switches were turned on
					sensorInLoadAndSaveDataMode = configurationHasControl(pages, ConfigurablePageControlType.PAGE_LOAD_AND_SAVE) && isSensorInLoadAndSaveDataMode(lastParameters, socket),
					runMode;

				switch (status) {
					case ConnectionStatus.INITIALIZING:
						socket.setOutputs();
						break;
					case ConnectionStatus.IMAGING:
						this.setRoomsState(); // TODO Check if we are in Smart Home application and only then call this function;

						/**
						 * Bring the state of the toolbar page controls (buttons Record, Load & Save, Play...)
						 * to correspond to the actual run mode
						 */
						if (sensorInReprocessMode) {
							if (sensorInLoadAndSaveDataMode) { // Sensor is in "Load & Save" mode
								runMode = RunMode.REPROCESS_AND_RECORD;
							} else { // Sensor is only in "Load recording" mode
								runMode = RunMode.REPROCESS_IQ_DATA;
							}
						} else if (sensorInRecordingRawDataMode || sensorInLoadAndSaveDataMode) {
							runMode = RunMode.RECORD;
						} else {
							runMode = RunMode.LIVE;
						}

						if (socket.recordingInfo.mode !== runMode) {
							this.updateSensorRunMode(socket, runMode, {
								path: this.parametersCache[socket.url]['FlowCfg.save_dir']
							});
						}

						// end Restoring RunMode after reload.

						// Reload page in LIVE mode OR it is start of reprocessing
						if (socket.isRunned()) {
							if (environment.outputsType === 'json_outputs' && !socket.queryJsonData()) {
								socket.queryJsonData();
							} else if (environment.outputsType === 'binary_outputs' && !socket.isQueringBinaryData()) {
								socket.queryBinaryData();
							}
						}

						// Reload page in RECORDING mode
						if (socket.recordingInfo.isRecording() && !socket.recordingInfo.timerIsStarted) {
							socket.recordingInfo.startTimer();
						}
						break;
					case ConnectionStatus.ERROR:
						this.stopSensor(socket, false);
						break;
					case ConnectionStatus.CONFIGURING:
						if (socket.recordingInfo.mode === RunMode.REPROCESS_IQ_DATA) {
							this.modalService.showMessage('PLAYING_RECORDING_HAS_FINISHED');
						}
						if ([ConnectionStatus.IMAGING, ConnectionStatus.STOPPED].includes(previousStatus)) {
							this.stopSensor(socket, true);
						}
						break;
					case ConnectionStatus.ANOTHER_CLIENT_CONNECTED:
						if (socket.isRunned()) {
							if (environment.outputsType === 'json_outputs' && !socket.queryJsonData()) {
								socket.queryJsonData();
							} else if (environment.outputsType === 'binary_outputs' && !socket.isQueringBinaryData()) {
								socket.queryBinaryData();
							}
						}
						break;
					case ConnectionStatus.DISCONNECTED:
						switch (socket.recordingInfo.mode) {
							case RunMode.RECORD:
								let t = await this.storageService.getItem('alert_on_disconnections'),
									alert_on_disconnections = t ? t.alert_on_disconnections : true,
									isCovidDetection = this.sensorSockets.value.some(sensorSocket => sensorSocket.isConnected() && sensorSocket.isCovidDetection);

								socket.recordingInfo.stopTimer();
								if (isCovidDetection && alert_on_disconnections) {
									this.modalService.checkAllSensorAreConnected();
								}
								break;
							case RunMode.PLAYBACK:
								socket.recordingInfo.pause();
								break;
						}
						if (this.tmpSocketSettings[socket.url]) {
							this.settingsService.saveSensorParameters(socket.url, JSON.parse(this.tmpSocketSettings[socket.url])).then(() => {
								delete this.tmpSocketSettings[socket.url];
							});
						}
						sub.unsubscribe();
						this.onDisconnect(socket);
						break;
					case ConnectionStatus.STOPPED:
						this.stopCleanColorTableInterval(socket);
						break;
				}

				previousStatus = status;
			});
	}

	private onDisconnect(socket) {
		let busEventService = this.inject.get(BusEventService);
		let configurationService = this.inject.get(ConfigurationService);
		delete this.parametersCache[socket.url];
		this.settingsService.clearLocalSettings(socket.url);
		busEventService.disconnect.emit(socket.url);
		this.updateMultiSensorSocketStatus();
		if (socket instanceof HomeAutomationServerSocket) {
			this.hamDevices.next({devices: []});
		}
		this.eventListeners.dataRetrieve[socket.url].next({});
		this.setRoomsState();
		configurationService.removeConfiguration(socket.url);
		this.stopCleanColorTableInterval(socket);
	}

	private saveSensorSocket(socket) {
		this.storageService.getItem('socketPool').then(socketPool => {
			this.storageService.setItem('socketPool', (socketPool || []).concat([socket.url]).filter((v, i, a) => a.indexOf(v) === i));
		});
	}

	private saveHomeAutomationServerSocket() {
		return this.storageService.setItem('spaceInfo', {
			homeAutomationServerSocketURL: this.homeAutomationServerSocket.url
		});
	}

	private updateSubject() {
		this.sensorSockets.next(Object.keys(this.socketPool).map(url => this.socketPool[url]));
	}

	private updateMultiSensorSocketStatus() {
		// Order is important
		if (this.sensorSockets.value.some(sensorSocket => sensorSocket.isConnected() && sensorSocket.isSensorsNetworkEnabled)) {
			let statuses = [ConnectionStatus.ERROR, ConnectionStatus.IMAGING, ConnectionStatus.CONFIGURING, ConnectionStatus.CALIBRATING, ConnectionStatus.INITIALIZING];
			for (let status of statuses) {
				let socket = this.sensorSockets.value.find(sensorSocket => sensorSocket.isSensorsNetworkEnabled && sensorSocket.connectionStatus === status);
				if (socket) {
					this.multiSensorSocket.connectionStatus = status;
					if (status === ConnectionStatus.ERROR) {
						this.multiSensorSocket.lastError = socket.lastErrorMessage;
					}
					break;
				}
			}
		} else {
			this.multiSensorSocket.connectionStatus = ConnectionStatus.CONFIGURING;
		}
	}

	private setRoomsState() {
		if (this.homeAutomationServerSocket && this.homeAutomationServerSocket.isConnected()) {
			this.storageService.getItem('socketPool').then(socketPool => {
				var pool = socketPool.map(url => {
					return this.settingsService.getSocketInfo(url);
				});

				Promise.all(pool).then(socketInfo => {
					this.homeAutomationServerSocket.setRoomsState(socketInfo.map((info: any, i) => {
						return {
							name: info.name || socketPool[i],
							ip: socketPool[i].split(':')[0],
							connected_to_evk_engine: (socketPool[i] in this.socketPool) ? this.socketPool[socketPool[i]].isConnected() : false,
							is_imaging: (socketPool[i] in this.socketPool) ? this.socketPool[socketPool[i]].isRunned() : false
						};
					}));
				});
			});
		}
	}

	private prepareParametersBeforeImaging(sensorParameters,
										   read_from_file = 0,
										   save_to_file = 0,
										   save_image_to_file = 0,
										   save_pointCloud_to_file = 0,
										   save_log = 0,
										   save_outputs_to_file = 0,
										   save_dir?) {
		return Object.assign(sensorParameters, {
			'FlowCfg.read_from_file': +read_from_file,
			'FlowCfg.save_to_file': +save_to_file,
			'FlowCfg.save_outputs_to_file': +save_outputs_to_file,
			'FlowCfg.save_image_to_file': +save_image_to_file,
			'FlowCfg.save_pointCloud_to_file': +save_pointCloud_to_file,
			'ProcessorCfg.OutputData.save_to_file': +save_log,
			'FlowCfg.save_dir': save_dir || sensorParameters['FlowCfg.save_dir']
		});
	}

	private stopCleanColorTableInterval(socket) {
		if (this.cleanColorTableIntervalIDs.has(socket)) {
			clearInterval(this.cleanColorTableIntervalIDs.get(socket));
		}
	}
}
