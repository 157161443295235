import {Component, Input, OnChanges, OnInit} from '@angular/core';

/**
 * Component renders an icon with monitored number of people for each connected sensor.
 * Used for Tracker app.
 */
@Component({
	selector: 'app-room-occupancy-layer',
	templateUrl: './room-occupancy.component.html',
	styleUrls: ['./room-occupancy.component.scss']
})
export class RoomOccupancyLayerComponent implements OnInit, OnChanges {

	@Input() data: any = {};

	aggregatedData = {
		roomId: [],
		roomNumOfPeople: []
	};

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		if (this.data && this.data.roomId && this.data.roomNumOfPeople) {
			let newAggregatedData: any = {
				roomId: [],
				roomNumOfPeople: []
			},
			roomId = this.data.roomId.flat(),
			roomNumOfPeople = this.data.roomNumOfPeople.flat();

			roomId.forEach((roomId, index) => {
				if (!newAggregatedData.roomId.includes(roomId)) {
					newAggregatedData.roomId.push(roomId);
				}
				newAggregatedData.roomNumOfPeople[newAggregatedData.roomId.indexOf(roomId)] = (newAggregatedData.roomNumOfPeople[newAggregatedData.roomId.indexOf(roomId)] || 0) + roomNumOfPeople[index];
			});
			this.aggregatedData = newAggregatedData;
		}
	}
}
