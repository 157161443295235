import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-modal-prompt',
	templateUrl: './modal-prompt.component.html',
	styleUrls: ['./modal-prompt.component.scss']
})
export class ModalPromptComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('input', { static: true }) input: ElementRef;
	disabledScroll = true;
	value;

	private subscriptions: Array<Subscription> = [];

	constructor(private dialogRef: MatDialogRef<ModalPromptComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit(): void {
		this.subscriptions.push(this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
			switch (e.key) {
				case 'Enter':
					if (document.activeElement) {
						document.activeElement['blur']();
					}
					setTimeout(() => {
						this.resolve();
					});
					break;
				case 'Escape':
					this.reject();
					break;
			}
		}));
		this.subscriptions.push(this.dialogRef.afterOpened().subscribe(() => this.disabledScroll = false));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	ngAfterViewInit() {
		if (this.input.nativeElement) {
			this.input.nativeElement.focus();
		}
	}

	resolve() {
		this.dialogRef.close(this.value);
	}

	reject() {
		this.dialogRef.close(false);
	}
}
