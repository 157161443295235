<div class="line-area">
    <app-number-input [label]="label" [class.changed]="isChanged" [readonly]="readonly" buttonsPosition="left"
					  [control]="controlMin" [min]="rangeMin" [max]="rangeMax" [step]="step"
					  (change)="onValueChange($event, controlMin)"></app-number-input>
    <div class="slider" [class.disabled]="readonly">
        <div class="noUi-target dummy-target">
            <div class="noUi-connect" [ngStyle]="{'left.%': arenaSliderPosition, 'width.%': arenaSliderWidth}"></div>
        </div>
    </div>
    <app-number-input [label]="label" [class.changed]="isChanged" [readonly]="readonly" buttonsPosition="right"
					  [control]="controlMax" [min]="rangeMin" [max]="rangeMax" [step]="step"
					  (change)="onValueChange($event, controlMax)"></app-number-input>
</div>
<div class="sensor" [class.disabled]="readonly" #wrapper> <!-- sensor X icon -->
    <div class="grab" [ngStyle]="{'left.%': sensorPosition}" (mousedown)="onStartDrag()">
        <img class="handle-arrow" draggable="false" src="assets/images/png/settings-sensor/arrow-top.png" alt="">
        <span class="handle-label">{{'SENSOR_SMALL_TEXT' | translate}}</span>
    </div>
</div>
