import {EventEmitter, Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Connection} from './system/connection';
import {ActivatedRouteSnapshot} from '@angular/router';
import {RestService} from './system/rest.service';
import {StorageService} from './storage.service';
import {LS_SmartRetailTimezone} from '../consts';
import * as ct from 'countries-and-timezones';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ToolbarService {

	floorId: number;
	connectionSubject = new Subject<Connection>();

	private toolbarComponent;

	private connectionStatus = 'NOT_CONNECTED';
	private connection: Connection;
	private isDuplicateViewFlag: boolean;
	private sensorSocketSettings: any;
	private isMultipleSensors: boolean;
	private isRoomOccupancy: boolean;
	private isSmartCooler: boolean;
	private isSmartBuildings: boolean;
	private isSmartHome: boolean;
	private isCovidDetection: boolean;
	private isFloorPlan: boolean;
	private disconnectReason: string;
	private route: ActivatedRouteSnapshot;

	public floorPlanRulerLocation: { name: string; points: any[], building_id: number, floor_id: number };
	public startFloorPlanLiveData = new EventEmitter();
	public stopFloorPlanLiveData = new EventEmitter();
	public sensorRun = new EventEmitter();
	public sensorStop = new EventEmitter();
	public runRecord = new EventEmitter();
	public loadAndSave = new EventEmitter();
	public duplicateView = new EventEmitter();
	public recordingPlayingToggle = new EventEmitter();

	public onRefreshClick = new EventEmitter();

	// Analytics
	public analyticsServerAddress;
	public analyticsForm: FormGroup;
	public floorPlanFiltersForm: FormGroup;
	public isAnalyticsFormShown = false;
	public isAnalyticsTotalTrafficShown = false;
	public analyticsTotalTraffic = 0;
	public isAnalyticsGeoFencesFilterShown = false;
	public isAnalyticsLive = false;
	public analyticsSelectedGraph;
	public analyticsShowRefreshButton;

	// Sensor list
	private socketPool;

	constructor(private rest: RestService,
				private storageService: StorageService) {
	}

	public setConnection(value: Connection): void {
		this.connection = value;
		this.connectionSubject.next(this.connection);
	}

	public getConnection(): Connection {
		return this.connection;
	}

	public setRoute(value: ActivatedRouteSnapshot): void {
		this.route = value;
	}

	public getRoute(): ActivatedRouteSnapshot {
		return this.route;
	}

	public setIsDuplicateViewFlag(value: boolean): void {
		this.isDuplicateViewFlag = value;
	}

	public getIsDuplicateViewFlag(): boolean {
		return this.isDuplicateViewFlag;
	}

	public setSensorSocketSettings(value: any): void {
		this.sensorSocketSettings = value;
	}

	public getSensorSocketSettings(): any {
		return this.sensorSocketSettings;
	}

	public setIsMultipleSensors(value: boolean): void {
		this.resetArenaModes();
		this.isMultipleSensors = value;
	}

	public getIsMultipleSensors(): boolean {
		return this.isMultipleSensors;
	}

	public setIsSmartHome(value: boolean): void {
		this.resetArenaModes();
		this.isSmartHome = value;
		this.isSmartBuildings = value;
	}

	public getIsSmartHome(): boolean {
		return this.isSmartHome;
	}

	public setIsRoomOccupancy(value: boolean): void {
		this.resetArenaModes();
		this.isRoomOccupancy = value;
	}

	public getIsRoomOccupancy(): boolean {
		return this.isRoomOccupancy;
	}

	public setIsSmartCooler(value: boolean): void {
		this.resetArenaModes();
		this.isSmartCooler = value;
	}

	public getIsSmartCooler(): boolean {
		return this.isSmartCooler;
	}

	public setIsSmartBuildings(value: boolean): void {
		this.resetArenaModes();
		this.isSmartBuildings = value;
	}

	public getIsSmartBuildings(): boolean {
		return this.isSmartBuildings;
	}

	public setIsCovidDetection(value: boolean): void {
		this.resetArenaModes();
		this.isCovidDetection = value;
	}

	public getIsCovidDetection(): boolean {
		return this.isCovidDetection;
	}

	public setIsFloorPlan(value: boolean): void {
		this.resetArenaModes();
		this.isFloorPlan = value;
	}

	public getIsFloorPlan(): boolean {
		return this.isFloorPlan;
	}

	public resetArenaModes(): void {
		this.isMultipleSensors = false;
		this.isRoomOccupancy = false;
		this.isSmartCooler = false;
		this.isSmartBuildings = false;
		this.isCovidDetection = false;
		this.isFloorPlan = false;
	}

	public setToolbarComponent(component): void {
		this.toolbarComponent = component;
	}

	public getToolbarComponent(): any {
		return this.toolbarComponent;
	}

	public setConnectionStatus(value: string): void {
		this.connectionStatus = value;
	}

	public getConnectionStatus(): string {
		return this.connectionStatus;
	}

	public setDisconnectReason(value: string): void {
		this.disconnectReason = value;
	}

	public getDisconnectReason(): string {
		return this.disconnectReason;
	}

	public setSocketPool(value): void {
		this.socketPool = value;
	}

	public getConnectedSensors(): number {
		return this.socketPool ? this.socketPool.filter(item => item.isConnected).length : 0;
	}

	public setAnalyticsShowRefreshButton(value): void {
		this.analyticsShowRefreshButton = value;
	}

	public getAnalyticsShowRefreshButton(): boolean {
		return this.analyticsShowRefreshButton;
	}

	public reset(): void {
		this.toolbarComponent = null;
		this.connectionStatus = '';
		this.connection = null as any;
		this.isDuplicateViewFlag = false;
		this.sensorSocketSettings = null;
		this.isMultipleSensors = false;
		this.isRoomOccupancy = false;
		this.isSmartCooler = false;
		this.isSmartBuildings = false;
		this.isSmartHome = false;
		this.isCovidDetection = false;
		this.isFloorPlan = false;
		this.disconnectReason = '';

		// this.analyticsServerAddress = null;
		this.isAnalyticsFormShown = false;
		this.isAnalyticsLive = false;
		this.analyticsSelectedGraph = null;
		this.analyticsShowRefreshButton = null;
		this.socketPool = null;

		this.analyticsForm = null as any;
		this.floorPlanFiltersForm = null as any;
	}

	public initAnalyticsForm(): void {
		this.analyticsForm = new FormGroup({
			start_date: new FormControl(new Date),
			end_date: new FormControl(new Date),
			start_time: new FormControl(0, [Validators.min(0), Validators.max(24)]),
			end_time: new FormControl(24, [Validators.min(0), Validators.max(24)]),
			location_ids: new FormControl([]),
			floor_ids: new FormControl([]),
			floor_id: new FormControl(null),
			building_id: new FormControl(null),
			timezone: new FormControl('', [Validators.required, this._timezoneValidator])
		});
	}

	private _timezoneValidator(control: FormControl) {
		return ct.getTimezone(control.value) ? null : {invalidTimezone: true};
	}

	public initFloorPlanFiltersForm(): void {
		this.floorPlanFiltersForm = new FormGroup({
			building_id: new FormControl(null, Validators.required),
			floor_id: new FormControl(null, Validators.required)
		});
	}

	public setFloorPlanLocation(data: { name: string; points: any[], building_id: number, floor_id: number }): void {
		this.floorPlanRulerLocation = data;
	}

	public getFloorPlanLocation(): { name: string; points: any[] } {
		return this.floorPlanRulerLocation;
	}

	public resetAnalyticsForm(): void {
		this.rest.initFilters();
		this.analyticsForm.setValue(this.rest.filter);
		this.storageService.removeItem(LS_SmartRetailTimezone);
	}
}
