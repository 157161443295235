import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ContainerComponent} from '../../components/container/container.component';
import {AuthGuard} from '../../guards/auth.guard';
import {getDefaultPage} from 'src/app/utils/utils';
import {LoginComponent} from '../../components/login/login.component';
import {environment} from '../../../environments/environment';

const pages: Routes = [
	{
		path: '**',
		redirectTo: getDefaultPage(),
		pathMatch: 'full'
	}
];

const appRoutes: Routes = [
	{
		path: 'login',
		component: LoginComponent
	}, {
		path: '',
		component: ContainerComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: environment.FLOW_PROCESSOR_MODULE_PATH,
				loadChildren: () => import('../../applications/flow-processor/flow-processor.module').then(m => m.FlowProcessorModule)
			}, {
				path: environment.SMART_BUILDINGS_MODULE_PATH,
				loadChildren: () => import('../../applications/smart-building/smart-building.module').then(m => m.SmartBuildingModule)
			}, {
				path: environment.SMART_HOME_MODULE_PATH,
				loadChildren: () => import('../../applications/smart-home/smart-home.module').then(m => m.SmartHomeModule)
			}, {
				path: environment.VITALS_MODULE_PATH,
				loadChildren: () => import('../../applications/vitals/vitals.module').then(m => m.VitalsModule)
			}, {
				path: environment.SMART_COOLER_MODULE_PATH,
				loadChildren: () => import('../../applications/smart-cooler/smart-cooler.module').then(m => m.SmartCoolerModule)
			}, {
				path: environment.SMART_TAILOR_MODULE_PATH,
				loadChildren: () => import('../../applications/smart-tailor/smart-tailor.module').then(m => m.SmartTailorModule)
			},
			...pages
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot(appRoutes, {useHash: true})
	],
	exports: [RouterModule]
})
export class RoutingModule {
}
