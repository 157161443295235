<div class="layer-tools buttons">
	<button *ngIf="layer.name !== 'HeatmapContourWand'" [matTooltip]="'Change Axes'" mat-raised-button class="axes" (click)="toggleAxes()" [disabled]="!axesEnabled"
			[attr.axes]="getAxesAttr()"></button>
	<ng-content></ng-content>
</div>
<svg [attr.viewBox]="'0 0 ' + VIEWBOX_W + ' ' + VIEWBOX_H"
	 preserveAspectRatio="none"
	 height="100%"
	 width="100%">
	<ng-container *ngIf="isFloorPlan && data?.locationData">
		<ng-container *ngFor="let location of data.locationData; trackBy: trackByRPMdataId">
			<svg
				*ngIf="location[0] && location[0] !== 'NaN' && location[1] && location[1] !== 'NaN' && location[2] && location[2] !== 'NaN'"
				[attr.height]="ICON_H / yScaleCoefficient"
				[attr.width]="ICON_W / xScaleCoefficient"
				[attr.x]="getPosition(location[0], location[1], location[2],  getAxes(), [ICON_W / xScaleCoefficient, ICON_H / yScaleCoefficient])[0]"
				[attr.y]="getPosition(location[0], location[1], location[2], getAxes(), [ICON_W / xScaleCoefficient, ICON_H / yScaleCoefficient])[1]"
				class="overflow-visible">
				<image transform="scale(0.8)"
					   class="target"
					   [attr.height]="ICON_H / yScaleCoefficient"
					   [attr.width]="ICON_W / xScaleCoefficient"
					   [attr.href]="getImageSrc(location)"></image>
			</svg>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!isFloorPlan">
		<ng-container *ngFor="let location of data?.locationData; let i = index; trackBy: trackByRPMdataId">
			<svg
				*ngIf="location[0] && location[0] !== 'NaN' && location[1] && location[1] !== 'NaN' && location[2] && location[2] !== 'NaN'"
				[attr.height]="ICON_H / yScaleCoefficient"
				[attr.width]="ICON_W / xScaleCoefficient"
				[attr.x]="getPosition(location[0], location[1], location[2], getAxes(), [ICON_W / xScaleCoefficient, ICON_H / yScaleCoefficient])[0]"
				[attr.y]="getPosition(location[0], location[1], location[2], getAxes(), [ICON_W / xScaleCoefficient, ICON_H / yScaleCoefficient])[1]"
				class="overflow-visible"
				[style.cursor]="isTargetsSelectable ? 'pointer' : 'default'"
				(click)="onMouseDown(data?.targetsIdData[i])">
				<image [attr.height]="ICON_H / yScaleCoefficient"
					   [attr.width]="ICON_W / xScaleCoefficient"
					   [attr.href]="getHubCircleImageSrc(i)"></image>
				<image transform="scale(0.8)"
					   class="target"
					   [attr.height]="ICON_H / yScaleCoefficient"
					   [attr.width]="ICON_W / xScaleCoefficient"
					   [attr.href]="getImageSrc(i)"></image>
			</svg>
		</ng-container>
	</ng-container>
</svg>

<!-- Sensor -->
<svg *ngIf="isSensorVisible"
	 [attr.viewBox]="'0 0 ' + VIEWBOX_W + ' ' + VIEWBOX_H"
	 preserveAspectRatio="none"
	 height="100%"
	 width="100%">
	<svg [attr.height]="SENSOR_H"
		 [attr.width]="SENSOR_W"
		 [attr.x]="getSensorPosition()[0]"
		 [attr.y]="getSensorPosition()[1]"
		 class="overflow-visible">
		<rect [attr.height]="SENSOR_H"
			  [attr.width]="SENSOR_W"
			  [attr.transform]="getSensorRotation()"
			  x="0" y="0" fill="rgba(59, 177, 205, .9)"></rect>
	</svg>
</svg>

<!-- Ruler -->
<div *ngIf="ruler" class="ruler">
	<svg #rulerEl preserveAspectRatio="none"></svg>
	<span class="ruler-x-axis-label {{originPosition}}">{{rulersLabels[displayAxes][0]}}</span>
	<span class="ruler-y-axis-label {{originPosition}}">{{rulersLabels[displayAxes][1]}}</span>
</div>
