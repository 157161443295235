import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SensorsService} from '../../../../../../services/system/sensors.service';
import {checkInCarAggregatedData} from 'src/app/utils/utils';
import {SensorSocket} from 'src/app/services/system/sensor-socket';
import {ModalService} from 'src/app/services/modal.service';
import {environment} from 'src/environments/environment';
import {SelectingTargetDataGraphBehavior} from '../../../../../../consts';
import {Subscription} from 'rxjs';

const DEVICE_COLOR_ON_CONFIG = '#3bb1cd';
const DEVICE_COLOR_ON_IMAGING = '#8a8a8a';

/**
 * seatIndications data structure
 	[
		seat_i, = 0
		seat_x_location_i, = 1
		seat_y_location_i, = 2
		seat_z_location_i, = 3
		seat_x_length_i, = 4
		seat_y_length_i, = 5
		indication_type, = 6
		is_monitored = 7
	]
 */

/**
 * Component renders 2D image of car with passengers inside.
 * Used for InCar app.
 */
@Component({
	selector: 'app-in-car-occupancy-base',
	templateUrl: './in-car-occupancy-base.component.html',
	styleUrls: ['./in-car-occupancy-base.component.scss']
})
export class InCarOccupancyBaseComponent implements OnInit, OnDestroy, OnChanges {

	isTargetsSelectable = false;

	@Input() rotate = 0;
	@Input() data: any;
	@Input() parameters: any = {};
	@Input() sensorSocket: SensorSocket;
	@Input() selectedModelIds;
	@Input() metadata;
	@Input() cfgDict;
	@Input() isSensorVisible = true;
	@Input() isMultipleSensors;
	@Input() connectedSensorsSettings = [];
	@Input() isTrackerEnabled = false;

	localData: any = {};

	sensorColor = DEVICE_COLOR_ON_CONFIG;

	messageHeaderDataKeyName = 'messageHeader';
	messageContentDataKeyName = 'messageContent';
	messageHeaderColorKeyName = 'messageHeaderColor';
	messageHeaderBackgroundColorKeyName = 'messageHeaderBackgroundColor';
	messageBoundingColorKeyName = 'messageBoundingColor';
	messageBoundingFrequencyKeyName = 'messageBoundingFrequency';

	protected subscriptions: Array<Subscription> = [];

	constructor(protected sensorsService: SensorsService,
				protected modalService: ModalService) {
	}

	ngOnInit() {
		this.updateSensor(this.sensorSocket.connectionStatus);
		this.subscriptions.push(this.sensorSocket.status.subscribe(status => {
			this.updateSensor(status);
		}));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => {
			subscription.unsubscribe();
		});
	}

	ngOnChanges(c: SimpleChanges) {
		if (('parameters' in c || 'metadata' in c || 'cfgDict' in c) && this.parameters) {
			this.isTargetsSelectable = this.parameters['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.UponTargetSelection;
		}
		if ('data' in c) {
			if (this.isMultipleSensors) {
				if (Object.keys(this.data).length) {
					let {valid, message} = checkInCarAggregatedData(this.data, environment.shouldValidateUniqueMonitoredSeats);
					if (valid) {
						this.localData = this.concatCombinedData(this.data);
					} else {
						this.sensorSocket.stop();
						this.modalService.showError(`Check that ${message}`);
					}
				} else {
					this.localData = {};
				}
			} else {
				this.localData = this.data;
			}
		}
	}

	protected updateSensor(status) {
		if (status === 'CONFIGURING') {
			this.sensorColor = DEVICE_COLOR_ON_CONFIG;
		} else if (status === 'IMAGING') {
			this.sensorColor = DEVICE_COLOR_ON_IMAGING;
		}
	}

	protected concatCombinedData(data: any = {}) {
		let ret = {};
		Object.keys(data).forEach(output => {
			switch (output) {
				case 'cabinArena':
				case 'targetsID':
					ret[output] = data[output][0];
					break;
				case 'seatIndications':
					ret[output] = data[output][0].map((s, i) => {
						let o: Array<any> = [].concat(s);
						o[7] = data.seatIndications.some(r => (r[i] && +r[i][7] === 1)) ? 1 : 0;
						// Take personType from sensor, where corresponded seat is monitored.
						let r = data.seatIndications.find(r => (r[i] && +r[i][7] === 1));
						o[6] = r ? r[i][6] : 0;
						return o;
					});
					break;
				case this.messageHeaderDataKeyName:
					let d = {
						[this.messageHeaderDataKeyName]: '',
						[this.messageContentDataKeyName]: ''
					};
					for (let i = 0; i < data[this.messageHeaderDataKeyName].length; i++) {
						if (data[this.messageHeaderDataKeyName][i]?.length && data[this.messageContentDataKeyName][i]?.length) {
							d[this.messageHeaderDataKeyName] = data[this.messageHeaderDataKeyName][i];
							d[this.messageContentDataKeyName] = data[this.messageContentDataKeyName][i];
						}
					}
					ret[this.messageHeaderDataKeyName] = d[this.messageHeaderDataKeyName];
					ret[this.messageContentDataKeyName] = d[this.messageContentDataKeyName];
					break;
			}
		});

		return ret;
	}
}
