import {Component, Injector, OnInit} from '@angular/core';
import {MultiSensorsSocket} from '../../../../../services/system/multi-sensors-socket';
import {SingleSensorComponent} from '../single-sensor/single-sensor.component';
import {SelectingTargetDataGraphBehavior} from '../../../../../consts';
import {environment} from '../../../../../../environments/environment';
import {OutputType} from '../../../../../models/models';

/**
 * Component is designed for showing scene with multiple sensors.
 * In parent component (base-view.component) it set flag isMultipleSensors=true.
 * Used for Tracker app.
 */
@Component({
	selector: 'app-multi-sensors',
	templateUrl: '../../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../../components/base-view/base-view.component.scss']
})
export class MultiSensorsComponent extends SingleSensorComponent implements OnInit {

	connection: MultiSensorsSocket;

	constructor(protected injector: Injector) {
		super(injector);
	}


	ngOnInit() {
		this.isMultipleSensors = true;
		this.subscribeToToolbarControls();
		this.toolbarService.setIsMultipleSensors(this.isMultipleSensors);

		this.connection = this.sensorsService.getMultiSensorSocket();
		this.toolbarService.setConnection(this.connection);
		this.registerSocketEvents();
		this.onParametersChange();
		this.connection.retrieveStatus();

		this.initColorTables();
		this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
			this.combinedData = data;
		}));
		this.subscriptions.push(this.busEventService.multipleRoomsParamsChanged.subscribe(info => {
			this.connectionSettings = Object.assign({}, this.connectionSettings, {socketInfo: info});
		}));
	}


	async updateWithSettings(initialParameters?) {
		initialParameters = {};
		super.updateWithSettings(initialParameters);
	}


	protected async onParametersChange(initialParameters?) {
		let url = this.connection.url;
		let connectedSocket = this.sensorsService.sensorSockets.value.find(socket => socket.isConnected());
		if (connectedSocket) {
			url = connectedSocket.url;
		}

		const connectedSockets = this.sensorsService.sensorSockets.value
		.filter(socket => socket.isConnected())
		.map(socket => this.settingsService.getAllSettings(socket.url));
		this.connectedSensorsSettings = await Promise.all(connectedSockets);

		const usedDefaultSettings = true;
		// Get settings from connection or localStorage
		const parameters = await this.settingsService.getAllSettings(url, usedDefaultSettings);

		// In case we retrieve sensorParameters from another client
		if (!('guiParameters' in parameters)) {
			parameters['guiParameters'] = this.settingsService.getDefaultSettings()['guiParameters'];
		}
		this.connectionSettings = parameters;
		this.toolbarService.setSensorSocketSettings(this.connectionSettings);
		if (this.connectionSettings['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.Automatically) {
			this.selectedModelIds = [];
		}
		if (!this.Standing3DModel) {
			setTimeout(() => {
				if (!this.isDestroyed) {
					this.preloadModelsResources();
				}
			});
		}

		this.updateWithSettings(initialParameters);
	}


	protected sendOutputsToSocket(outputs) {
		this.connection.setOutputs(environment.outputsType as OutputType, outputs);
	}
}
