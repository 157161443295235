<input #input [(ngModel)]="value" [type]="type"
                                 (blur)="onBlur($event)"
                                 (focus)="onFocus()"
								 (keyup)="onKeyUp($event)" [matTooltip]="title" [placeholder]="placeholder"
	     						 (change)="onChange()">
<div *ngIf="showEditButton" class="sub-sett">
    <button mat-raised-button class="host-table_cancel" [matTooltip]="tooltip" (click)="onEdit($event)">
        <img src="assets/images/svg/toolbar/Edit.svg" alt="">
    </button>
</div>
