import {SensorSocket} from '../services/system/sensor-socket';
import {environment} from '../../environments/environment';
import * as THREE from 'three';
import {SensorMountPlane} from '../consts';
import {ActivatedRoute} from '@angular/router';
import {RetailGraph} from '../models/models';
import {LatLngBoundsLiteral} from 'leaflet';
import * as ct from 'countries-and-timezones';
import { ConfigurablePageControlType, IConfigurablePage } from '../services/configuration.service';

export type matrix3 = [number, number, number, number, number, number, number, number, number];
export type matrix4 = [number, number, number, number, number, number, number, number, number, number, number, number, number, number, number, number];

export enum axesSystem2D {xy = 0, xz = 1, yz = 2}

export type originPosition = 'leftTop' | 'leftBottom';

export enum LatLngType {
	NorthEast,
	SouthEast,
	SouthWest,
	NorthWest
}

/**
 * General helper functions.
 */

export var meterToFeet = (value, r = true) => {
	let v = value / 0.3048;
	return r ? round(v, 2) : v;
};

export var feetToMeter = (value, r = true) => {
	let v = value * 0.3048;
	return r ? round(v, 2) : v;
};

export var cmToInches = value => {
	return (value || 0) * environment.cmToInches * 100;
};

export var round = (n, d = 1) => {
	let result = Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
	return (isNaN(result) ? 0 : result);
};

export var prepareRecordingPath = (path, base) => {
	var fileSchema = 'file:///';

	return decodeURI(new URL(path, fileSchema + prepareRecordingDir(base)).href).replace(fileSchema, '');
};

export var prepareRecordingDir = (recordingDir = '') => {
	if (recordingDir) {
		if (recordingDir[recordingDir.length - 1] !== '\\' && recordingDir[recordingDir.length - 1] !== '/') {
			recordingDir = recordingDir + '\\';
		}
	} else {
		recordingDir = '';
	}
	return recordingDir;
};

export var isSettingInReadOnly = (sensorSocket): boolean => {
	return sensorSocket?.recordingInfo.isPlayback() || (sensorSocket?.recordingInfo.isRecording() && sensorSocket?.recordingInfo.timerIsStarted);
};

export var tableFix = () => {
	Array.from(document.querySelectorAll('table thead tr + tr th')).forEach((th, i) => {
		document.querySelectorAll('table thead tr:first-child th')[i]['width'] = th['offsetWidth'];
	});
};

export var validateNumberInputKeyDown = (e, confirmForm = false, allowNegativeValues = true) => {
	if (!['Enter', 'Escape'].includes(e.key) || (e.key === 'Enter' && !confirmForm)) {
		e.stopPropagation();
	}
	var key = e.charCode || e.keyCode || e.shiftKey || 0;
	if (key === 13) {
		e.target.blur();
	}
	if (e.ctrlKey) {
		return ['a', 'z', 'c'].includes(e.key);
	}
	// We allow backspace, tab, delete, arrows, numbers on the additional keyboard
	let isNumber = (key >= 48 && key <= 57) || (key >= 96 && key <= 105)/*numpad numbers*/,
		allowed = (key === 190 /*.*/ || key === 110 /*.*/ || key === 9 /*Tab*/
			|| key === 8 /*BS*/ || key === 37 /*left arrow*/ || key === 39 /*right arrow*/
			|| key === 36 /*Home*/ || key === 35 /*End*/ || key === 46 /*del*/
			|| (allowNegativeValues && key === 189 /*minus*/)
			|| (allowNegativeValues && key === 109 /*minus*/) || isNumber);

	if (!allowed) {
		return false;
	}
	if (isNumber && !window.getSelection()!.toString()) {
		let value = String(e.target.value);
		if (value.split('.')[1] && value.split('.')[1].length >= 2) {
			return false;
		}
	}

	return allowed;
};

/**
 * Replace __ -> _
 * Replace _ -> .
 * @param name
 */
export var getLayerCfgName = (name = '') => {
	return name.replace(/([^_])_([^_])/g, '$1.$2').replace(/__/g, '_');
};

export var getSensorPosition2D = (arena, axis, displayAxes, parameters) => {
	let translation = getSensorTranslation(parameters),
		_x1 = arena[axis[0] * 2],
		_y1 = arena[axis[1] * 2],
		_x2 = arena[axis[0] * 2 + 1],
		_y2 = arena[axis[1] * 2 + 1],
		x = _x2 < 0 ? _x2 : (_x1 > 0 ? _x1 : (translation[0] > _x2 ? _x2 : (translation[0] < _x1 ? _x1 : translation[0]))),
		y = _y2 < 0 ? _y2 : (_y1 > 0 ? _y1 : (translation[2] > _y2 ? _y2 : (translation[2] < _y1 ? _y1 : translation[2])));

	// XZ or YZ
	if (['xz', 'yz'].includes(displayAxes)) {
		y = translation[1];
		if (y > arena[axis[1] * 2 + 1]) {
			y = arena[axis[1] * 2 + 1];
		} else if (y < arena[axis[1] * 2]) {
			y = arena[axis[1] * 2];
		}
	}

	return [x, y];
};

export var getNonCacheableSensorParameters = (sensorSocket: SensorSocket) => {
	return Object.values(sensorSocket.supportedParameters).filter(parameter => !parameter.isCacheable).map(parameter => parameter.name);
};

export function makeDeepCopy<T> (o: T): T {
	return o ? JSON.parse(JSON.stringify(o)) : o;
}

export var checkInCarAggregatedData = (data, shouldValidateUniqueMonitoredSeats) => {
	let check = {valid: true, message: ''};

	// Cabin arena should be the same from all sensors.
	/*if(!data.cabinArena.every(cabinArena => {
		return data.cabinArena[0].every((v,i)=>v === cabinArena[i]);
	})) {
		check.valid = false;
		check.message = 'cabinArena are equal';
	}

	// Seat positions should be the same from all sensors.
	if(check.valid) {
		if(!data.seatIndications.every(seatIndication => {
			return data.seatIndications[0].every((v,i)=> {
				return [0, 1, 2, 3, 4, 5].every(l => {
					return v[l] === seatIndication[i][l];
				});
			});
		})) {
			check.valid = false;
			check.message = 'seatIndications are equal';
		}
	}

	// Seats that are monitored in a sensor are unique
	if(check.valid && shouldValidateUniqueMonitoredSeats) {
		if(data.seatIndications.some(seatIndication => {
			let seatIndications = data.seatIndications.filter(s => s !== seatIndication);

			return seatIndication.some((s, i) => {
				return s[7] == 1 && seatIndications.some(r => (r[i] && r[i][7] == 1));
			});
		})) {
			check.valid = false;
			check.message = 'monitored seats are unique';
		}
	}*/

	return check;
};

let availableColors = [
	{r: 255, g: 255, b: 255}, // White - shouldn't used
	{r: 0, g: 190, b: 212}, // Light blue - 1
	{r: 140, g: 194, b: 74}, // Green - 2
	{r: 255, g: 193, b: 8}, // Yellow - 3
	{r: 156, g: 40, b: 176}, // Purple - 4
	{r: 255, g: 127, b: 39}, // Orange - 5
	{r: 255, g: 174, b: 200}, // Pink - 6
	{r: 63, g: 72, b: 204}, // Blue - 7
	{r: 120, g: 84, b: 72}, // Light Brown - 8
	{r: 0, g: 214, b: 20}, // Light Green - 9
	{r: 213, g: 81, b: 236}, // Light purple - 10
	{r: 215, g: 88, b: 0}, // Dark Orange - 11
	{r: 103, g: 103, b: 0}, // Dark green - 12
	{r: 164, g: 133, b: 47}, // Dark Yellow - 13
	{r: 255, g: 226, b: 120}, // Light Yellow - 14
	{r: 243, g: 157, b: 127}, // Brown - 15
].map(color => `rgb(${color.r},${color.g},${color.b})`);

export var getColor = targetId => {
	return availableColors[targetId % availableColors.length] || '#FFFFFF';
};

/**
 * Used to check in which mode the sensor is in.
 * In case the received flags (modeParameters) given are true, then the sensor is in the mode in question.
 * We also support a case where the predicate for defining the mode is different then having all of modeParameters true.
 * @see isSensorInRecordingRawDataMode
 * @see isSensorInReprocessMode
 * @see isSensorInRecordingAdditionalDataMode
 *
 * @param sensorParameters - All sensor parameters
 * @param modeParameters - If these parameters are true then then the sensor is indeed in the mode in question
 * @param modeParametersPredicateFunc - An alternative predicate (other than all modeParameters are true) over modeParameters for defining the mode
 */
export var isSensorInMode = (sensorParameters, modeParameters, modeParametersPredicateFunc?: Function) => {
	return sensorParameters &&
		(modeParametersPredicateFunc ?
			modeParametersPredicateFunc(modeParameters) :
			modeParameters.every(parameter => sensorParameters[parameter]));
};


/**
 * This function checkes if 'FlowCfg.save_to_file' == true
 * @param sensorParameters
 */
export var isSensorInRecordingRawDataMode = sensorParameters => {
	return isSensorInMode(sensorParameters, ['FlowCfg.save_to_file']);
};

/**
 * This function checkes if 'FlowCfg.read_from_file' == true
 * @param sensorParameters
 */
export var isSensorInReprocessMode = sensorParameters => {
	return isSensorInMode(sensorParameters, ['FlowCfg.read_from_file']);
};

/**
 * This function check if we're in recording mode with saving additional data
 * @param sensorParameters
 * @param sensorSocket
 */
export var isSensorInRecordingAdditionalDataMode = (sensorParameters, sensorSocket: SensorSocket) => {
	let isSavingAdditionalData = isSensorInMode(sensorParameters,
		['FlowCfg.save_image_to_file', 'ProcessorCfg.OutputData.save_to_file', 'FlowCfg.save_pointCloud_to_file', 'FlowCfg.save_outputs_to_file'],
		params => params.some(parameter => sensorSocket.isSupportedParameter(parameter) && sensorParameters[parameter]));

	return isSensorInRecordingRawDataMode(sensorParameters) && isSavingAdditionalData;
};

/**
 * This function check if we're in recording mode with saving additional data
 * @param sensorParameters
 * @param sensorSocket
 */
export var isSensorInLoadAndSaveDataMode = (sensorParameters, sensorSocket: SensorSocket) => {
	let isSavingAdditionalData = isSensorInMode(sensorParameters,
		['FlowCfg.save_image_to_file', 'ProcessorCfg.OutputData.save_to_file', 'FlowCfg.save_pointCloud_to_file', 'FlowCfg.save_outputs_to_file'],
		params => params.some(parameter => sensorSocket.isSupportedParameter(parameter) && sensorParameters[parameter]))

	return isSavingAdditionalData;
};

export var degToRad = value => value * Math.PI / 180;

export var radToDeg = value => value * 180 / Math.PI;

export var getRotationMatrixFromMatrix4 = (userToWebGUITransMat): Array<Array<number>> => {
	return userToWebGUITransMat.map(row => [row[0], row[1], row[2]]).filter((v, i) => i < 3);
};

// webgui_point = rotationMatrix * evk_engine_point + translationVector
export var mapEVKaxesToThreeJS = (value: [number, number, number], parameters, translate = true, scale = true, SCALING_FACTOR = 1): [number, number, number] => {
	let vector = new THREE.Vector3(...value),
		matrix = new THREE.Matrix3(),
		userToWebGUITransMat = parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.userToWebGUITransMat'],
		rotationMatrix = getRotationMatrixFromMatrix4(userToWebGUITransMat),
		translationVector: [number, number, number] = [userToWebGUITransMat[0][3], userToWebGUITransMat[1][3], userToWebGUITransMat[2][3]];

	if (scale) {
		vector.multiplyScalar(SCALING_FACTOR);
	}

	if (rotationMatrix) {
		rotationMatrix.flat();
		matrix.set(...(rotationMatrix.flat() as matrix3));
		vector.applyMatrix3(matrix);
	}
	if (translate && translationVector) {
		vector.add(new THREE.Vector3(...translationVector.map(c => c * SCALING_FACTOR)));
	}

	return vector.toArray() as [number, number, number];
};

export var getThreeJsScale = (ThreeJsArena, scalingFactor) => {
	return [
		Math.abs(scalingFactor * (ThreeJsArena[1] - ThreeJsArena[0])),
		Math.abs(scalingFactor * (ThreeJsArena[3] - ThreeJsArena[2])),
		Math.abs(scalingFactor * (ThreeJsArena[5] - ThreeJsArena[4]))
	];

};

/**
 * For boardToWebGUITransMat structure
 * @see getSensorTranslation
 * @param params
 * @param isAngleInDeg
 */
export var getSensorRotation = (params, isAngleInDeg = false): [number, number, number] => {
	let r = {x: 0, y: 0, z: 0},
		rotation;


	if (params['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'] && params['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'].length) {
		let euler = new THREE.Euler(),
			matrix = new THREE.Matrix4(),
			boardToWebGUITransMat: matrix4 = params['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'];

		boardToWebGUITransMat = boardToWebGUITransMat.flat() as matrix4;
		matrix.set(...boardToWebGUITransMat);
		euler.setFromRotationMatrix(matrix, 'ZYX');
		rotation = [euler.x, euler.y, euler.z];
	} else {
		if (params['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'] === SensorMountPlane.BACKWALL) {
			r.x = degToRad(90);
		}
		rotation = [r.x, r.y, r.z];
	}
	return <[number, number, number]>(isAngleInDeg ? rotation.map(radToDeg) : rotation);
};

export var getSensorRotation2D = (params, pivotX, pivotY, axes) => {
	let rotation = getSensorRotation(params, true),
		index;

	switch (axes) {
		case 'xy':
		default:
			index = 1;
			break;
		case 'xz':
			index = 2;
			break;
		case 'yz':
			index = 0;
			break;
	}

	// In 2D we rotate sensor only around "height" axis
	// Currently disabled. To restore change return value to `rotate(${rotation[index]}, ${pivotX}, ${pivotY})`
	return `rotate(0, ${pivotX}, ${pivotY})`;
};

/**
 * boardToWebGUITransMat structure:
 * 	0	1	2	3
 * 	4	5	6	7
 * 	8	9	10	11
 * 	12	13	14	15
 * where:
 *  - upper 3x3 of matrix is a rotation matrix
 * 	0	1	2
 * 	4	5	6
 * 	8	9	10
 *  - 4th column (3, 7, 11) is a translation vector
 *  - 4th row unused
 */
export var getSensorTranslation = (params) => {
	let translation = [0, 0, 0],
		boardToWebGUITransMat = params['sensorParameters']['ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat'];

	if (boardToWebGUITransMat) {
		boardToWebGUITransMat = boardToWebGUITransMat.flat() as matrix4;
		translation = [boardToWebGUITransMat[3], boardToWebGUITransMat[7], boardToWebGUITransMat[11]];
	}

	return translation;
};

export var getSensorTranslation2D = (params) => {
	let translation = getSensorTranslation(params);

	return [translation[0], translation[2], translation[1]];
};

export var transformArena = (arena, userToWebGUITransMat) => {
	let minVec = new THREE.Vector3(arena[0], arena[2], arena[4]),
		maxVec = new THREE.Vector3(arena[1], arena[3], arena[5]),
		matrix = new THREE.Matrix3(),
		newArena,
		userToWebgui_rotMat = getRotationMatrixFromMatrix4(userToWebGUITransMat);

	matrix.set(...(userToWebgui_rotMat.flat() as matrix3));
	minVec.applyMatrix3(matrix);
	maxVec.applyMatrix3(matrix);

	newArena = [minVec.x, maxVec.x, minVec.y, maxVec.y, minVec.z, maxVec.z];

	return newArena;
};

export var getDefaultPage = () => {
	let modules = [
		{
			moduleEnabled: environment.isFlowProcessorModuleEnabled,
			modulePath: environment.FLOW_PROCESSOR_MODULE_PATH
		}, {
			moduleEnabled: environment.isSmartBuildingsModuleEnabled,
			modulePath: environment.SMART_BUILDINGS_MODULE_PATH
		}, {
			moduleEnabled: environment.isSmartHomeModuleEnabled,
			modulePath: environment.SMART_HOME_MODULE_PATH
		}, {
			moduleEnabled: environment.isVitalsModuleEnabled,
			modulePath: environment.VITALS_MODULE_PATH
		}, {
			moduleEnabled: environment.isSmartCoolerModuleEnabled,
			modulePath: environment.SMART_COOLER_MODULE_PATH
		}, {
			moduleEnabled: environment.isSmartTailorModuleEnabled,
			modulePath: environment.SMART_TAILOR_MODULE_PATH
		}
	];

	return modules.find(m => m.moduleEnabled)?.modulePath;
};

export var isContainedIn = (a, b) => {
	if (typeof a !== typeof b) {
		return false;
	}
	if (Array.isArray(a) && Array.isArray(b)) {
		// assuming same order at least
		for (var i = 0, j = 0, la = a.length, lb = b.length; i < la && j < lb; j++) {
			if (isContainedIn(a[i], b[j])) {
				i++;
			}
		}
		return i === la;
	} else if (Object(a) === a) {
		for (var p in a) {
			if (!(p in b && isContainedIn(a[p], b[p]))) {
				return false;
			}
		}
		return true;
	} else {
		return a === b;
	}
};

export var getCurrentActivatedRoute = (route: ActivatedRoute): ActivatedRoute => {
	return route.firstChild ? getCurrentActivatedRoute(route.firstChild) : route;
};

export var getChangedParameters = (obj1, obj2) => {
	if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
		return obj1;
	}
	let diffs = {};
	let key;

	let arraysMatch = (arr1, arr2) => {
		if (arr1.length !== arr2.length) return false;
		for (let i = 0; i < arr1.length; i++) {
			let type1 = Object.prototype.toString.call(arr1[i]);
			let type2 = Object.prototype.toString.call(arr2[i]);
			if (type1 !== type2) {
				return false;
			}
			if (type1 === '[object Object]') {
				let diff = getChangedParameters(arr1[i], arr2[i]);
				if (Object.keys(diff).length) return false;
			} else if (type1 === '[object Array]') {
				if (!arraysMatch(arr1[i], arr2[i])) {
					return false;
				}
			} else {
				if (arr1[i] !== arr2[i]) return false;
			}
		}
		return true;
	};

	let compare = (item1, item2, key2) => {
		let type1 = Object.prototype.toString.call(item1);
		let type2 = Object.prototype.toString.call(item2);
		if (type2 === '[object Undefined]') {
			diffs[key2] = null;
			return;
		}
		if (typeof item1 === 'object' && typeof item2 === 'object' && type1 !== type2) {
			diffs[key2] = item2;
			return;
		}
		if (type1 === '[object Object]') {
			let objDiff = getChangedParameters(item1, item2);
			if (Object.keys(objDiff).length > 1) {
				diffs[key2] = objDiff;
			}
			return;
		}
		if (type1 === '[object Array]') {
			if (!arraysMatch(item1, item2)) {
				diffs[key2] = item2;
			}
			return;
		}
		if (type1 === '[object Function]') {
			if (item1.toString() !== item2.toString()) {
				diffs[key2] = item2;
			}
		} else {
			if (item1 != item2) {
				diffs[key2] = item2;
			}
		}
	};
	for (key in obj1) {
		if (obj1.hasOwnProperty(key)) {
			compare(obj1[key], obj2[key], key);
		}
	}
	for (key in obj2) {
		if (obj2.hasOwnProperty(key)) {
			if (!obj1[key] && obj1[key] != obj2[key]) {
				diffs[key] = obj2[key];
			}
		}
	}
	return diffs;
};

export function prepareHttpParamsForRetailGraph(graph: RetailGraph, filterValue: object, isLive = false, numOfResults?): object {
	let params = {};

	if (this.isLive) {
		// In live mode bring the last results regardless of time stamps
		params['num_of_results'] = Number(numOfResults).toString();
	}

	// PATCH - The addition of start_time and end_time should be removed
	// Please see https://action-item.atlassian.net/browse/VE-1656
	let [start_date, end_date] = prepareRetailFilterDates(filterValue);
	let timezone = ct.getTimezone(filterValue['timezone']);
	params['dates'] = [toISODateString(start_date).split('T')[0], toISODateString(end_date).split('T')[0]];
	params['times'] = [
		+toISODateString(filterValue['start_time']).split('T')[1].slice(0, 2),
		+toISODateString(filterValue['end_time']).split('T')[1].slice(0, 2)
	];
	params['utc_timezone_offset'] = timezone.dstOffset / 60;

	if (graph.queryParams) {
		Object.keys(graph.queryParams).forEach(p => {
			params[p] = (<Object>graph.queryParams)[p];
		});
	}

	if (filterValue['location_ids'].length) {
		params['location_ids'] = filterValue['location_ids'];
	} else if (filterValue['floor_ids'].length) {
		params['floor_ids'] = filterValue['floor_ids'];
	}

	return params;
}

export function toISODateString(date: Date, withoutTimezone = true): string {
	if (withoutTimezone) {
		date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ));
	}
	return date.toISOString();
}

export function prepareRetailFilterDates(filterValue) {
	let start_date: any = new Date( +filterValue['start_date']),
		end_date: any = new Date(+filterValue['end_date']);

	end_date.setDate(end_date.getDate() + 1);

	return [start_date, end_date];
}

/**
 * Generate text for loading popup in format: Request name - Status
 * Status could be one of these: Done, Failed, In process, In queue
 * @param values
 * @param i
 * @param statuses
 */
export function generateLoadingPopupStatusText(values: Array<string>, i: number, statuses?: Array<boolean>) {
	let lines = values.map((v, j) => {
		let status = 'In process',
			isCurrentRequest = true;
		if (j < i) {
			status = statuses ? (statuses[j] ? 'Done' : 'Failed') : 'Done';
			isCurrentRequest = false;
		} else if (j > i) {
			status = 'In queue';
			isCurrentRequest = false;
		}
		return `<span ${isCurrentRequest ? 'class="active"' : ''}>` + `${v}: ` + status + '</span>';
	});

	return lines.join('<br>');
}

export function rotateLeafletGeometry(degree, geometry): LatLngBoundsLiteral {
	const angle = degToRad(degree) * -1;
	return geometry.map(latLng => {
		return [ Math.cos(angle) * latLng[0] - Math.sin(angle) * latLng[1], Math.sin(angle) * latLng[0] + Math.cos(angle) * latLng[1]];
	}).map(latLng => [latLng[1], latLng[0]]);
}

export function rotateBase64Image(srcBase64, degrees): Promise<string> {
	return new Promise((resolve => {
		const canvas = document.createElement('canvas');
		let ctx = canvas.getContext('2d') as any;
		let image = new Image();

		image.onload = function () {
			canvas.width = degrees % 180 === 0 ? image.width : image.height;
			canvas.height = degrees % 180 === 0 ? image.height : image.width;

			ctx.translate(canvas.width / 2, canvas.height / 2);
			ctx.rotate(degrees * Math.PI / 180);
			ctx.drawImage(image, image.width / -2, image.height / -2);

			resolve(canvas.toDataURL());
		};
		image.src = srcBase64;
	}));
}

export function getMax(arr: Array<number>) {
	let len = arr.length;
	let max = -Infinity;

	while (len--) {
		max = arr[len] > max ? arr[len] : max;
	}
	return max;
}

export function getMin(arr: Array<number>) {
	let len = arr.length;
	let min = Infinity;

	while (len--) {
		min = arr[len] < min ? arr[len] : min;
	}
	return min;
}

export function generateUIDFromCharCodes(charCodes: Array<number> = []) {
	return charCodes.map(charCode => String.fromCharCode(charCode)).join('');
}

/**
 * Check that CoCo includes specified control (and it's enabled)
 */
export function configurationHasControl(pages: Array<IConfigurablePage>, controlType: ConfigurablePageControlType) {
	return pages.some(page => page.controls?.some(control => control.enable && control.type === controlType));
}