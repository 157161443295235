import {AfterViewInit, Component, ElementRef, HostListener, OnInit} from '@angular/core';

/**
 * Base class for 2D layers.
 */
@Component({
	template: ''
})
export class SVGLayerComponent implements OnInit, AfterViewInit {

	VIEWBOX_W = 100;
	VIEWBOX_H = 100;

	protected hostElement: ElementRef;

	constructor() {
	}

	ngOnInit() {
		setTimeout(() => {
			this.resizeViewBox();
		});
	}

	ngAfterViewInit() {
		// Update sensor size
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		});
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event: Event) {
		setTimeout(() => {
			this.resizeViewBox();
		});
	}

	protected resizeViewBox() {
		let clientHeight = this.hostElement.nativeElement.clientHeight,
			clientWidth = this.hostElement.nativeElement.clientWidth,
			aspect = clientWidth / clientHeight;

		this.VIEWBOX_H = +(this.VIEWBOX_W / aspect).toFixed();
	}
}
