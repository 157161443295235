import {Connection, ConnectionStatus} from './connection';
import {HttpTransportType, HubConnection, HubConnectionBuilder, ILogger, LogLevel} from '@microsoft/signalr';
import {environment} from '../../../environments/environment';
import {FetchHttpClient} from '@microsoft/signalr/dist/esm/FetchHttpClient';

class SignalrLogger implements ILogger {
	log(logLevel: LogLevel, message: string) {}
}

export class SignalrConnection extends Connection {

	private hubConnection: HubConnection;

	async connect() {
		if (this.canDisconnect()) {
			return this.hubConnection.stop();
		}

		const httpClient = new FetchHttpClient(new SignalrLogger());
		// The negotiate request is done manually instead of using @microsoft/signalr
		// Once we realize how to handle the response with using @microsoft/signalr we will refactor
		try {
			const response = await httpClient.post(this.ip + '/negotiate');

			const body = JSON.parse(response.content as string);
			this.hubConnection = new HubConnectionBuilder()
				.withUrl(body.url, {
					transport: HttpTransportType.WebSockets,
					accessTokenFactory: () => body.accessToken,
					skipNegotiation: true
				})
				.build();

			this.disconnectReason = null;
			this._lastErrorMessage = null;
			this.connectionStatus = ConnectionStatus.CONNECTING;

			try {
				await this.hubConnection.start();
				this.connectionStatus = ConnectionStatus.CONNECTED;
			} catch (error) {
				this.connectionStatus = ConnectionStatus.DISCONNECTED;
				throw {err: error, message: 'FAILED_TO_CONNECT_TO_SIGNALR'};
			}
		} catch (err) {
			throw {err, message: 'FAILED_TO_NEGOTIATE_WITH_SIGNALR'};
		}
	}

	disconnect() {
		this.connectionStatus = ConnectionStatus.DISCONNECTED;
		return this.hubConnection?.stop();
	}

	isConnected(): boolean {
		return [ConnectionStatus.STOPPED, ConnectionStatus.CONNECTED, ConnectionStatus.IMAGING].includes(this.connectionStatus);
	}

	isConnecting(): boolean {
		return this.connectionStatus === ConnectionStatus.CONNECTING;
	}

	isRunned() {
		return [ConnectionStatus.IMAGING].includes(this.connectionStatus);
	}

	public retrieveStatus() {
		return true;
	}

	public start() {
		this.registerFloorsLiveDataListener();
		this.connectionStatus = ConnectionStatus.IMAGING;
	}

	public stop() {
		this.unregisterFloorsLiveDataListener();
		this.connectionStatus = ConnectionStatus.STOPPED;
	}

	public registerFloorsLiveDataListener() {
		this.registerEventListener(environment.azure.realTimeTargetName);
	}

	public unregisterFloorsLiveDataListener() {
		this.unregisterEventListener(environment.azure.realTimeTargetName);
	}

	private unregisterEventListener(method) {
		this.hubConnection.off(method);
	}

	private registerEventListener(method) {
		this.hubConnection.on(method, (data) => {
			this.data.emit({
				method,
				data
			});
		});
	}
}
