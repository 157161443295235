import {
	AfterViewInit,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	Injector,
	OnDestroy,
	OnInit,
	Type,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import {ToolbarService} from '../../../../services/toolbar.service';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, ActivationStart, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {BackendConnectorBaseComponent} from '../../backend-connector/backend-connector.base.component';
import {getCurrentActivatedRoute} from '../../../../utils/utils';
import {ToolbarEvkComponent} from '../toolbar-evk/toolbar-evk.component';
import {BackendConnectorEvkComponent} from '../../backend-connector/backend-connector-evk/backend-connector-evk.component';

/**
 * Component represents top panel of page with additional controls.
 */
@Component({
	selector: 'app-dynamic-toolbar',
	templateUrl: './dynamic-toolbar.component.html',
	styleUrls: ['./dynamic-toolbar.component.scss']
})
export class DynamicToolbarComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('toolbarContainer', {read: ViewContainerRef}) toolbarContainer: ViewContainerRef;
	@ViewChild('connectorContainer', {read: ViewContainerRef}) connectorContainer: ViewContainerRef;

	sensorConnectorComponent: Type<BackendConnectorBaseComponent>;
	useCustomToolbarTemplate: boolean;
	showBackendConnector = true;
	environment = environment;

	private routerSubscription: Subscription;
	private toolbarComponent;
	private sensorConnectorComponentRef: ComponentRef<BackendConnectorBaseComponent> | null;
	private updateConnectorTemplatePromise;

	constructor(private resolver: ComponentFactoryResolver,
				private toolbarService: ToolbarService,
				private router: Router,
				private route: ActivatedRoute,
				private injector: Injector) {
	}

	ngOnInit() {
		let currentRoute = getCurrentActivatedRoute(this.route);
		this.toolbarService.setRoute(currentRoute.snapshot);
		this.toolbarComponent = currentRoute.snapshot.data.toolbar;
		this.updateBackendConnectorVisible(currentRoute);
		this.sensorConnectorComponent = currentRoute.snapshot.data.sensorConnector;
		this.useCustomToolbarTemplate = currentRoute.snapshot.data.useCustomToolbarTemplate;
		this.routerSubscription = this.router.events.subscribe(async (event) => {
			if (event instanceof ActivationStart) {
				this.useCustomToolbarTemplate = event.snapshot.data.useCustomToolbarTemplate;
				this.toolbarService.setRoute(event.snapshot);
				if (event.snapshot.data.sensorConnector !== this.sensorConnectorComponent) {
					this.sensorConnectorComponent = event.snapshot.data.sensorConnector;
					this.updateBackendConnectorVisible(event);
					this.connectorContainer.clear();
					this.updateConnectorTemplatePromise = this.updateConnectorTemplate();
				}
				if (event.snapshot.data.toolbar !== this.toolbarComponent) {
					this.toolbarComponent = event.snapshot.data.toolbar;
					this.toolbarContainer.clear();
					this.updateToolbarTemplate();
				}
			}
			if (event instanceof NavigationEnd) {
				if (this.updateConnectorTemplatePromise) {
					await this.updateConnectorTemplatePromise;
				}
				if (this.sensorConnectorComponentRef) {
					let snap = this.route.snapshot,
						url = (snap.firstChild!.firstChild || snap.firstChild!).paramMap.get('url');

					this.sensorConnectorComponentRef.instance.connectToURL(url as string);
				}
			}
		});
	}

	ngAfterViewInit() {
		this.updateToolbarTemplate();
		this.updateConnectorTemplate();
	}

	protected updateToolbarTemplate(): void {
		if (this.toolbarComponent) {
			// fix ExpressionChangedAfterItHasBeenCheckedError with debugger
			setTimeout(async () => {
				let factory = this.resolver.resolveComponentFactory(this.toolbarComponent),
					componentRef,
					injector;

				switch (this.toolbarComponent) {
					case ToolbarEvkComponent:
						injector = (await import('../../../../applications/flow-processor/flow-processor.module')).InjectorInstance;
						componentRef = this.toolbarContainer.createComponent(factory, undefined, injector, undefined, (injector as any).parent);
						break;
					default:
						componentRef = this.toolbarContainer.createComponent(factory);
						break;
				}
				this.toolbarService.setToolbarComponent(componentRef.instance);
			});
		} else {
			this.toolbarService.setToolbarComponent(null);
		}
	}

	protected async updateConnectorTemplate() {
		if (this.sensorConnectorComponent) {
			return new Promise(resolve => {
				// fix ExpressionChangedAfterItHasBeenCheckedError with debugger
				setTimeout(async () => {
					let factory = this.resolver.resolveComponentFactory<BackendConnectorBaseComponent>(this.sensorConnectorComponent),
						injector;

					switch (this.sensorConnectorComponent) {
						case BackendConnectorEvkComponent:
							injector = (await import('../../../../applications/flow-processor/flow-processor.module')).InjectorInstance;
							this.sensorConnectorComponentRef = this.connectorContainer.createComponent(factory, undefined, injector, undefined, (injector as any).parent);
							break;
						default:
							this.sensorConnectorComponentRef = this.connectorContainer.createComponent(factory);
							break;
					}
					resolve();
				});
			});
		} else {
			this.sensorConnectorComponentRef = null;
		}
	}

	ngOnDestroy() {
		if (this.routerSubscription) {
			this.routerSubscription.unsubscribe();
		}
	}

	private updateBackendConnectorVisible(route) {
		this.showBackendConnector = typeof route?.snapshot.data.showBackendConnector === 'boolean' ?
			route?.snapshot.data.showBackendConnector :
			true;
	}
}
