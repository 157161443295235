import {Injectable, Injector} from '@angular/core';
import {BusEventService} from '../bus-event.service';
import {BehaviorSubject} from 'rxjs';
import {SensorsService} from './sensors.service';
import {makeDeepCopy} from '../../utils/utils';

type GraphMatrix = [number/*targetId*/, number | 'NaN' | boolean/*breathingValue*/, number | 'NaN'/*rpm*/, number | boolean/*time*/];
type HealthData = [number /*time*/, number/*value*/];

/**
 * Preprocessing data from sensor.
 */
@Injectable({
	providedIn: 'root'
})
export class DataService {
	readonly combinedData = new BehaviorSubject({});
	readonly savedOutputs: {
		[sensorSocketURL: string]: {
			[output: string]: Array<any>;
		};
	} = {};

	private activeOutputs: {
		[sensorSocketURL: string]: Array<string>;
	} = {};
	private data = {};
	private dataByTopic = {};
	private dataBySensorId = {};

	constructor(private busEventService: BusEventService,
				private inject: Injector) {

		this.busEventService.run.subscribe(url => {
			this.clearData(url);
		});
		this.busEventService.stop.subscribe(url => {
			this.clearData(url);
		});
		this.busEventService.disconnect.subscribe(url => {
			this.clearData(url);
			this.updateCombinedData();
		});
		this.busEventService.outputsChanged.subscribe(p => {
			let activeOutputs: Array<string> = [];
			if (p.isBreathingActive) {
				activeOutputs.push(p.breathingOutput as string);
			}
			if (p.isActivityActive) {
				activeOutputs.push(p.activityOutput as string);
			}
			this.activeOutputs[p.url] = activeOutputs;
			if (p.needBreathingGraphGap && !p.isBreathingActive && this.savedOutputs[p.url] && this.savedOutputs[p.url][p.breathingOutput]) {
				let targetIDs: Array<number> = Array.from(new Set([].concat(...this.savedOutputs[p.url][p.breathingOutput].map(a => a.map(b => b[0])))));
				let row: Array<GraphMatrix> = [];
				targetIDs.forEach(targetID => {
					row.push([targetID, false, 'NaN', false]);
				});
				this.savedOutputs[p.url][p.breathingOutput].push(row);
			}
			if (p.needActivityGraphGap && !p.isActivityActive && this.savedOutputs[p.url] && this.savedOutputs[p.url][p.activityOutput]) {
				let targetIDs: Array<number> = Array.from(new Set([].concat(...this.savedOutputs[p.url][p.activityOutput].map(a => a.map(b => b[0])))));
				let row: Array<GraphMatrix> = [];
				targetIDs.forEach(targetID => {
					row.push([targetID, false, 'NaN', false]);
				});
				this.savedOutputs[p.url][p.activityOutput].push(row);
			}
		});
	}

	updateData(url, data, sensorParameters) {
		this.data[url] = this.prepareDataFromSensor(url, data, sensorParameters);
		this.updateCombinedData();
	}

	isBreathingLayerEnabled(sensorParameters) {
		return this.isLayerEnabled(sensorParameters, ['BreathingGraphs']);
	}

	isCountingLayerEnabled(sensorParameters) {
		return this.isLayerEnabled(sensorParameters, ['Number']);
	}

	prepareDataFromMqttBroker(data, topic) {
		return this.prepareDataFromMqtt(data, this.dataByTopic, topic);
	}

	prepareDataFromSignalr(data) {
		return this.prepareDataFromMqtt(data, this.dataBySensorId, data.device_id);
	}

	clearMqttStorage(url: string) {
		this.dataByTopic = {};
		this.dataBySensorId = {};
		this.clearData(url);
		this.updateCombinedData();
	}

	private isLayerEnabled(sensorParameters, componentNames: string[], dataDict?) {
		if (sensorParameters && 'ProcessorCfg.ExternalGUI.Layers' in sensorParameters) {
			var layers = sensorParameters['ProcessorCfg.ExternalGUI.Layers'] || [];
			return !!layers.find(l => {
				return componentNames.includes(l.componentName) && (dataDict ? (dataDict in l.dataDict) : true);
			});
		}
		return false;
	}

	private clearData(url: string) {
		delete this.data[url];
		delete this.savedOutputs[url];
		delete this.activeOutputs[url];
	}

	private updateCombinedData() {
		let newCombinedData = {};
		Object.values(makeDeepCopy(this.data)).forEach((dataFromSensor: any, i) => {
			Object.keys(dataFromSensor).forEach(output => {
				if (dataFromSensor[output] !== null) {
					if (typeof dataFromSensor[output] === 'object') {
						dataFromSensor[output].index = i;
					}
					if (output in newCombinedData) {
						newCombinedData[output].push(dataFromSensor[output]);
					} else {
						newCombinedData[output] = [dataFromSensor[output]];
					}
				}
			});
		});
		this.combinedData.next(newCombinedData);
	}

	private prepareDataFromSensor(url, data, sensorParameters) {
		let layers = [];
		if (sensorParameters && 'ProcessorCfg.ExternalGUI.Layers' in sensorParameters) {
			layers = sensorParameters['ProcessorCfg.ExternalGUI.Layers'] || [];
		}

		let graphsMatchingLayers = layers.filter((layer: any) => ['BreathingGraphs', 'ActivityGraphs'].includes(layer.componentName));
		if (graphsMatchingLayers.length > 0) {
			Object.keys(data).forEach(output => {
				let shouldBeSaved = graphsMatchingLayers.some((layer: any) => Object.values(layer.dataDict).includes(output));

				if (shouldBeSaved) {
					if (data[output] && data[output] instanceof Array) {
						if (!this.savedOutputs[url]) {
							this.savedOutputs[url] = {};
						}
						if (!(output in this.savedOutputs[url])) {
							this.savedOutputs[url][output] = [];
						}
						if (data[output][0] && !(data[output][0] instanceof Array)) {
							data[output] = [data[output]];
						}
						if (data[output].length) {
							if (!(url in this.activeOutputs) || this.activeOutputs[url].includes(output)) {
								this.savedOutputs[url][output].push(data[output]);
							}
						}
					}
				}
			});
		}

		let bottlesOccupancy: any = layers.find((layer: any) => {
			return layer.componentName === 'CoolerInventory' && layer.dataDict.bottlesOccupancy in data;
		});
		if (bottlesOccupancy) {
			data['shelfIndex'] = +bottlesOccupancy.staticDataDict.shelfIndex - 1;
		}

		return data;
	}

	prepareDataFromMqtt(data, storage, key) {
		let combinedData = {
			locations: []
		};
		storage[key] = data;
		Object.keys(storage).forEach((t: string) => {
			let d: any = storage[t];
			// Make the same target ID from different sensors unique in combined data
			combinedData.locations = combinedData.locations.concat((d.locations || []).map((c, i) => [c[0], c[1], c[2], t + (d.target_ids || [])[i]]));
		});

		return combinedData;
	}
}
