import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

/**
 * Component presents a modal window which warning about an established connection.
 * It proposes to load record or connect to local host.
 * If saved connection exists this window will be shown on page load.
 */
@Component({
	selector: 'app-connection-status',
	templateUrl: './connection-status.component.html',
	styleUrls: ['./connection-status.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectionStatusComponent implements OnInit {

	doNotShowWelcomeFlow = false;
	remotely = 'REMOTELY';
	locally = 'LOCALLY';
	remoteHost = 'REMOTE_HOST';
	remoteHostToLowerCase = 'REMOTE_HOST_TO_LOWER_CASE';
	localHost = 'LOCAL_HOST';
	localHostToLowerCase = 'LOCAL_HOST_TO_LOWER_CASE';
	defineNewArenaSetttingsText = 'DEFINE_NEW_ARENA_SETTINGS';
	defineArenaSettingsText = 'DEFINE_ARENA_SETTINGS';

	constructor(private dialogRef: MatDialogRef<ConnectionStatusComponent>,
				private translate: TranslateService,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit() {
	}

	openSettings() {
		this.data.callback('new-settings');
	}

	loadSettings() {
		this.data.callback('load-settings');
	}

	switchHost() {
		this.data.callback('switch-host');
	}

	tryAgain() {
		this.data.callback('try-again');
	}

	loadRecording() {
		this.data.callback('load-recording');
	}

	cancel() {
		this.data.callback('cancel');
	}

	doNotShowWelcomeFlowChange() {
		this.data.callback('doNotShowWelcomeFlowChange', !this.doNotShowWelcomeFlow);
	}
}
