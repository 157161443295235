import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {SensorSocket} from '../../../../../services/system/sensor-socket';
import {PointCloudStage_InCar_Boxes} from './PointCloudStage_InCar_Boxes';
import {checkInCarAggregatedData, transformArena} from 'src/app/utils/utils';
import {environment} from 'src/environments/environment';
import {ModalService} from 'src/app/services/modal.service';
import {DEFAULT_POINT_SIZE} from '../../../../../base-classes/PointCloudStageBase';

/**
 * Component renders each seat in car as box and renders point cloud.
 * PointCloudStage_InCar_Boxes class used as renderer.
 * Used for InCar app.
 */
@Component({
	selector: 'app-boxes-point-cloud',
	templateUrl: './boxes-point-cloud.component.html',
	styleUrls: ['./boxes-point-cloud.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxesPointCloudComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

	@Input() isRaw = false;
	@Input() data: any = {};
	@Input() parameters = {};
	@Input() sensorSocket: SensorSocket;
	@Input() isSensorVisible = true;
	@Input() isMultipleSensors = false;
	@Input() connectedSensorsSettings = [];
	@Input() metadata;
	@Input() cfgDict;

	@ViewChild('div', {static: true}) div: ElementRef;

	localData: any = {};
	pixDensity;

	private sensorStatusSubscription;

	constructor(private modalService: ModalService) {
	}

	async ngOnInit() {
		let arena = this.getArena(),
			pointSize;
		if (this.isMultipleSensors) {
			arena = arena[0];
		}
		if (this.metadata?.names?.pointSizeMultiplier && this.cfgDict?.[this.metadata.names.pointSizeMultiplier]) {
			pointSize = DEFAULT_POINT_SIZE * this.cfgDict[this.metadata.names.pointSizeMultiplier];
		}
		this.pixDensity = new PointCloudStage_InCar_Boxes(this.div.nativeElement, arena, this.sensorSocket.connectionStatus, this.parameters, this.isRaw, pointSize);
		this.pixDensity.updateSensor(this.isMultipleSensors ? this.connectedSensorsSettings : this.parameters);
		if (this.sensorSocket.connectionStatus !== 'IMAGING') {
			this.pixDensity.updateSceneWithRealArenaSettings(this.parameters, this.getArena());
			if (this.localData.data) {
				this.pixDensity.updateOnInit(
					this.localData.data,
					arena,
					this.localData.cabinArena,
					this.isRaw,
					this.isSensorVisible,
					this.localData.seatIndications
				); // Update data
			}
		}
		this.sensorStatusSubscription = this.sensorSocket.status.subscribe(status => {
			this.pixDensity.updateStatus(status);
		});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.onResize();
		});
	}

	ngOnChanges(c: SimpleChanges) {
		if (this.pixDensity) {
			let arena = this.getArena();
			if ('data' in c) {
				if (this.isMultipleSensors) {
					if (Object.keys(this.data).length) {
						let {valid, message} = checkInCarAggregatedData(this.data, environment.shouldValidateUniqueMonitoredSeats);
						if (valid) {
							this.localData = this.concatCombinedData(this.data);
						} else {
							this.sensorSocket.stop();
							this.modalService.showError(`Check that ${message}`);
						}
					} else {
						this.localData = {};
						this.pixDensity.updatePoints([]);
					}
				} else {
					this.localData = this.data;
				}
			}
			if ('parameters' in c || 'connectedSensorsSettings' in c) {
				this.pixDensity.updateSceneWithRealArenaSettings(this.isMultipleSensors ? this.connectedSensorsSettings : this.parameters, arena);
			}

			if ('data' in c || 'isSensorVisible' in c) {
				this.updatePixDensity(arena, this.sensorSocket.connectionStatus);
			}
			if ('isRaw' in c) {
				this.updateData();
			}
		}
	}

	ngOnDestroy() {
		this.pixDensity.cleanup();
		if (this.sensorStatusSubscription) {
			this.sensorStatusSubscription.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event?: Event) {
		if (this.pixDensity) {
			this.pixDensity.onResize();
		}
	}

	private updateData() {
		this.pixDensity.updateData(this.localData.data, this.isRaw);
	}

	private updatePixDensity(arena, status) {
		this.pixDensity.update(
			this.localData.data,
			arena,
			status,
			this.localData.cabinArena,
			this.isRaw,
			this.isSensorVisible,
			this.localData.seatIndications
		);
	}

	private getArena() {
		let cb = (parameters) => {
			let arena: any = transformArena(
				parameters['sensorParameters']['ProcessorCfg.ExternalGUI.displayedArena'] ?? parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'],
				parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.userToWebGUITransMat']
			);
			arena.sensorPlane = parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane'];

			return arena;
		};

		if (this.isMultipleSensors) {
			return this.connectedSensorsSettings.map(parameters => cb(parameters));
		} else {
			return cb(this.parameters);
		}
	}

	private concatCombinedData(aggregatedData: any = {}) {
		let ret = {};
		Object.keys(aggregatedData).forEach(outputName => {
			switch (outputName) {
				case 'sensorPosition':
				case 'sensorRotation':
					ret[outputName] = aggregatedData[outputName];
					break;
				case 'cabinArena':
					ret[outputName] = aggregatedData[outputName][0];
					break;
				case 'seatIndications':
					//TODO: Change these variables` names to something more coherent
					ret[outputName] = aggregatedData[outputName][0].map((s, i) => {
						let o: Array<any> = [].concat(s);
						o[7] = aggregatedData.seatIndications.some(r => (r[i] && +r[i][7] === 1)) ? 1 : 0;
						return o;
					});
					break;
				case 'data':
					if (aggregatedData[outputName].length && aggregatedData[outputName][0].length) {
						aggregatedData[outputName].forEach((outputData, i) => {
							if(!(outputData[0] instanceof Array)) {
								aggregatedData[outputName][i] = [outputData];
							}
						});
						ret[outputName] = [].concat(...aggregatedData[outputName]);
					}
					break;
			}
		});

		return ret;
	}
}
