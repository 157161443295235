import {Component, Injector, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {MultiSensorsSocket} from '../../../../../services/system/multi-sensors-socket';
import {SingleSensorComponent} from '../../../../flow-processor/components/base-view/single-sensor/single-sensor.component';
import {SelectingTargetDataGraphBehavior} from '../../../../../consts';

/**
 * Component is designed for showing scene with number of people.
 * Used for Tracker app.
 */
@Component({
	selector: 'app-room-occupancy',
	templateUrl: '../../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../../components/base-view/base-view.component.scss']
})
export class RoomOccupancyComponent extends SingleSensorComponent implements OnInit {

	connection: MultiSensorsSocket;

	constructor(protected injector: Injector) {
		super(injector);
	}


	ngOnInit(): void {
		this.subscribeToToolbarControls();
		this.toolbarService.setIsRoomOccupancy(true);
		this.connection = this.sensorsService.getMultiSensorSocket();
		this.toolbarService.setConnection(this.connection);
		this.registerSocketEvents();
		this.onParametersChange();
		this.connection.retrieveStatus();
		this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
			this.combinedData = data;
		}));
	}


	async updateWithSettings(initialParameters?) {
		initialParameters = {};
		initialParameters['ProcessorCfg.ExternalGUI.Layers'] = environment.roomOccupancyLayers;
		super.updateWithSettings(initialParameters);
	}


	protected async onParametersChange(initialParameters?) {
		const usedDefaultSettings = true;
		// Get settings from connection or localStorage
		const parameters = await this.settingsService.getAllSettings(this.connection.url, usedDefaultSettings);

		// In case we retrieve sensorParameters from another client
		if (!('guiParameters' in parameters)) {
			parameters['guiParameters'] = this.settingsService.getDefaultSettings()['guiParameters'];
		}
		parameters['sensorParameters']['ProcessorCfg.ExternalGUI.Layers'] = environment.roomOccupancyLayers;
		this.connectionSettings = parameters;
		this.toolbarService.setSensorSocketSettings(this.connectionSettings);
		if (this.connectionSettings['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.Automatically) {
			this.selectedModelIds = [];
		}
		if (!this.Standing3DModel) {
			setTimeout(() => {
				if (!this.isDestroyed) {
					this.preloadModelsResources();
				}
			});
		}

		this.updateWithSettings(initialParameters);
	}


	protected sendOutputsToSocket(outputs) {
		this.connection.setOutputs('json_outputs', outputs);
	}
}
