import {NgxMatDateFormats} from '@angular-material-components/datetime-picker';

export enum ConnectionType {
	Unknown,
	REST,
	MQTT,
	WS,
	SIGNALR
}

export enum SensorMountPlane {
	CEILING = 'xy',
	BACKWALL = 'xz'
}

export enum SelectingTargetDataGraphBehavior {
	UponTargetSelection,
	Automatically
}

export interface ITestableComponent {
	dataSelector: string;
}

export const LS_SmartRetailTimezone = 'smartRetailTimezone';

const INTL_DATE_INPUT_FORMAT = {
	// year: 'numeric',
	// month: 'numeric',
	// day: 'numeric',
	hourCycle: 'h23',
	hour: '2-digit',
	minute: '2-digit',
};

export const MAT_DATE_FORMATS: NgxMatDateFormats = {
	parse: {
		dateInput: INTL_DATE_INPUT_FORMAT,
	},
	display: {
		dateInput: INTL_DATE_INPUT_FORMAT,
		monthYearLabel: { year: 'numeric', month: 'short' },
		dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'long' },
	},
};
