import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {validateNumberInputKeyDown} from '../../../utils/utils';
import {ITestableComponent} from '../../../consts';

/**
 * Component for fast changing input value.
 * Component renders number input with arrows down\up on the right\left side and label.
 */
@Component({
	selector: 'app-number-input',
	templateUrl: './number-input.component.html',
	styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit, ITestableComponent {

	@Input() control;
	@Input() dataSelector;
	// TODO implement ControlValueAccessor interface
	@Input() readonly = false;
	@Input() step = 0.1;
	@Input() buttonsPosition = 'left';
	@Input() precision = 2;
	@Input() label = 'M';
	@Input() min;
	@Input() max;
	@Output() change = new EventEmitter();
	@ViewChild('input') input: ElementRef;

	private intervalId;
	private timeoutId;

	constructor() {
	}

	ngOnInit(): void {
	}

	changeFieldFast(step: number) {
		this.timeoutId = setTimeout(() => {
			this.intervalId = setInterval(() => {
				this.changeField(step);
			}, 100);
		}, 300);
	}


	changeFieldFastStop() {
		clearTimeout(this.timeoutId);
		clearInterval(this.intervalId);
	}

	changeField(step: number) {
		let previousValue = +this.control.value,
			newValue = previousValue + step;

		if (typeof this.min === 'number' && this.round(newValue, this.precision, this.min) < this.min) {
			/**
			 * When current value < min and user increases value - set min value to field
			 */
			if (step > 0) {
				newValue = this.min;
			} else {
				return;
			}
		}
		if (typeof this.max === 'number' && this.round(newValue, this.precision, this.max) > this.max) {
			/**
			 * When current value > max and user decreases value - set max value to field
			 */
			if (step < 0) {
				newValue = this.max;
			} else {
				return;
			}
		}

		this.control.setValue(this.round(newValue, this.precision, step < 0 ? this.min : this.max));
		this.emitChange(+this.control.value, previousValue);
	}

	updateControlValue() {
		if (typeof this.min === 'number' && this.round(+this.input.nativeElement.value, this.precision) < this.min) {
			if (typeof this.control.value === 'number') {
				this.input.nativeElement.value = +this.control.value;
				return;
			} else {
				this.input.nativeElement.value = this.min;
			}
		}
		if (typeof this.max === 'number' && this.round(+this.input.nativeElement.value, this.precision) > this.max) {
			if (typeof this.control.value === 'number') {
				this.input.nativeElement.value = +this.control.value;
				return;
			} else {
				this.input.nativeElement.value = this.max;
			}
		}
		let previousValue = +this.control.value;
		this.control.setValue(this.round(+this.input.nativeElement.value, this.precision));
		this.emitChange(+this.control.value, previousValue);
	}

	validateKeyDown(e) {
		return validateNumberInputKeyDown(e);
	}

	private round(n, d = 1, NaNReplace = 0) {
		let result = Math.round(n * Math.pow(10, d)) / Math.pow(10, d);
		return (isNaN(result) ? NaNReplace : result);
	}

	private emitChange(newValue, previousValue) {
		if (previousValue !== newValue) {
			this.change.emit({newValue, previousValue});
		}
	}
}
