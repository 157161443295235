import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ToolbarService} from '../../../../../services/toolbar.service';
import {RestService} from '../../../../../services/system/rest.service';
import {BusEventService} from '../../../../../services/bus-event.service';
import {ToolbarBaseComponent} from '../../../../../components/shared/toolbars/toolbar.base.component';
import { environment } from '../../../../../../environments/environment';
import {ConnectionType} from '../../../../../consts';
import {SensorSocket} from '../../../../../services/system/sensor-socket';
import {ModalService} from '../../../../../services/modal.service';
import {RetailLocation, RetailFloor} from '../../../../../models/models';
import {generateLoadingPopupStatusText} from '../../../../../utils/utils';

@Component({
	selector: 'app-toolbar-floor-plan',
	templateUrl: './toolbar-floor-plan.component.html',
	styleUrls: ['./toolbar-floor-plan.component.scss']
})
export class ToolbarFloorPlanComponent extends ToolbarBaseComponent implements OnInit, OnDestroy {
	environment = environment;
	locations: Array<RetailLocation> = [];
	allFloors: Array<RetailFloor> = [];
	floors: Array<RetailFloor> = [];
	rippleColor = getComputedStyle(document.documentElement).getPropertyValue('--button-ripple-color');

	private isDestroyed = false;

	private loadingState = {
		labels: ['Stores', 'Floors', 'Locations', 'Location types'],
		statuses: [true, true, true, true]
	};

	constructor(public toolbarService: ToolbarService,
				public rest: RestService,
				public busEventService: BusEventService,
				protected injector: Injector,
				protected modalService: ModalService) {
		super(injector);
	}

	async ngOnInit() {
		if (environment.useAzure) {
			await Promise.resolve();
			this.busEventService.restIsLoading.next(true);
			this.loadFiltersValues().then(filterFormValues => {
				this.busEventService.restLoadingStatus.next(this.generateLoadingPopupStatusText(2));
				this.rest.getGeoFences()
					.catch(() => {
						this.loadingState.statuses[2] = false;
						return [];
					})
					.then(() => {
					this.busEventService.restLoadingStatus.next(this.generateLoadingPopupStatusText(3));
					this.rest.getGeoFencesTypes()
						.catch(() => {
							this.loadingState.statuses[3] = false;
							return [];
						})
						.then(() => {
						this.busEventService.restIsLoading.next(!this.toolbarService.getConnection()?.isConnected());
						if (!filterFormValues && !this.isDestroyed) {
							this.busEventService.smartBuildingPageIsReady.emit();
						}
					});
				});
			});
			this.subscriptions.push(this.busEventService.reloadFloors.subscribe((result) => {
				this.loadFiltersValues(false)
					.then(() => {
						this.refreshFloors(result);
					});
			}));
			this.subscriptions.push(this.busEventService.reloadFiltersValues.subscribe((result) => {
				this.loadFiltersValues(true);
			}));
		}
	}

	ngOnDestroy() {
		this.isDestroyed = true;
	}

	clearAll() {
		this.storageService.removeItem('floorPlanFilter');
	}

	private async loadFiltersValues(restoreSelected = true) {
		const analyticsServerAddress = await this.getServerAddress();
		if (analyticsServerAddress) {
			this.rest.setServerAddress(analyticsServerAddress);
			return this.rest.connect().then(() => {
				this.busEventService.restLoadingStatus.next(this.generateLoadingPopupStatusText(0));
				return this.rest.getLocations()
					.catch(() => {
						this.loadingState.statuses[0] = false;
						return [];
					})
					.then(locations => {
					this.locations = locations.sort((a: any, b: any) => a.building_name.localeCompare(b.building_name));
					this.busEventService.restLoadingStatus.next(this.generateLoadingPopupStatusText(1));
					return this.rest.getFloors()
						.catch(() => {
							this.loadingState.statuses[1] = false;
							return [];
						})
						.then(floors => {
						this.allFloors = floors;
						if (restoreSelected) {
							return this.storageService.getItem('floorPlanFilter').then(filterFormValues => {
								if (filterFormValues) {
									if (this.allFloors.find(floor => floor.id === filterFormValues.floor_id)) {
										this.toolbarService.floorPlanFiltersForm.patchValue(filterFormValues);
										this.refreshFloors();
									} else if (this.allFloors.length) {
										let floor = this.allFloors[0];
										this.toolbarService.floorPlanFiltersForm.patchValue({
											building_id: floor.parent_building,
											floor_id: floor.id
										});
										this.refreshFloors();
									}
								} else if (this.allFloors.length) {
									let floor = this.allFloors[0];
									this.toolbarService.floorPlanFiltersForm.patchValue({
										building_id: floor.parent_building,
										floor_id: floor.id
									});
									this.refreshFloors();
								}
								return filterFormValues;
							});
						}
					});
				});
			});
		}
	}

	private async getServerAddress(): Promise<string> {
		if (environment.useAzure) {
			return environment.azure.telemetryFunctionAppURL;
		} else {
			if (environment.disableRemoteConnToRESTServer) {
				return environment.analyticsRESTserverAddress;
			} else {
				let tmp = await this.storageService.getItem('analyticsServerAddress');
				return (tmp || {}).analyticsServerAddress;
			}
		}
	}

	public startLiveData(): void {
		this.toolbarService.startFloorPlanLiveData.emit({ type: ConnectionType.SIGNALR, url: environment.azure.realTimeFunctionAppURL});
	}
	public stopLiveData(): void {
		this.toolbarService.stopFloorPlanLiveData.emit({ type: ConnectionType.SIGNALR, url: environment.azure.realTimeFunctionAppURL});
	}

	public refreshFloors(floor?: RetailFloor) {
		if (floor) {
			this.floors = this.allFloors.filter(f => f.parent_building === floor.parent_building);
			let floorId = this.floors.find(f => f.floor_name === floor.floor_name)?.id;
			this.busEventService.uploadGeoFencesForNewFloor.emit(floorId);
			this.toolbarService.floorPlanFiltersForm.patchValue({
				building_id: floor.parent_building,
				floor_id: floorId
			});
		} else {
			const building_id = this.toolbarService.floorPlanFiltersForm.getRawValue().building_id;
			if (typeof building_id === 'number') {
				this.floors = this.allFloors.filter(f => f.parent_building === building_id);
			}
		}
	}

	public onBuildingChanged(building_id): void {
		this.floors = this.allFloors.filter(floor => floor.parent_building === building_id);
		this.toolbarService.floorPlanFiltersForm.get('floor_id')?.reset();
	}

	isConnectionConnected(enabledWhenRunned = false) {
		let connection = this.toolbarService.getConnection() as SensorSocket,
			isRunned = connection?.isRunned(),
			isConnected = connection?.isConnected();
		return !isConnected || (enabledWhenRunned ? !isRunned : isRunned);
	}

	// @ts-ignore
	public onClickShowSettings(mode, type) {
		let showSubToolbar = this.busEventService.showSubToolbar;
		if (showSubToolbar.value.mode === mode && showSubToolbar.value.type === type) {
			showSubToolbar.next({open: !showSubToolbar.value.open, mode, type, cancel: true});
		} else {
			if (showSubToolbar.value.open) {
				showSubToolbar.next({open: false, mode, cancel: mode !== 'add'});
				setTimeout(() => {
					showSubToolbar.next({open: true, mode, type});
				}, 500);
			} else {
				showSubToolbar.next({open: true, mode, type});
			}
		}
	}

	private generateLoadingPopupStatusText(i) {
		return generateLoadingPopupStatusText(this.loadingState.labels, i, this.loadingState.statuses);
	}
}
