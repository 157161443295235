import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConnectionType} from '../../../../consts';
import {BackendConnectorBaseComponent} from '../backend-connector.base.component';
import {BusEventService} from '../../../../services/bus-event.service';
import {environment} from '../../../../../environments/environment';
import {StorageService} from '../../../../services/storage.service';
import {MqttSocket} from '../../../../services/system/mqtt-socket';
import {SensorsService} from '../../../../services/system/sensors.service';
import {ConnectionData} from '../../../../models/models';
import {ConnectionStatus} from '../../../../services/system/connection';

/**
 * Backend connector to connect to MQTT broker.
 */
@Component({
	selector: 'app-backend-connector-mqtt',
	templateUrl: '../backend-connector.base.component.html',
	styleUrls: ['../backend-connector.base.component.scss']
})
export class BackendConnectorMqttComponent extends BackendConnectorBaseComponent implements OnInit, OnDestroy {

	type = ConnectionType.MQTT;
	connection: MqttSocket;

	constructor(public busEventService: BusEventService,
				private storageService: StorageService,
				private sensorsService: SensorsService) {
		super(busEventService);
	}

	async ngOnInit() {
		let analyticsLiveServerAddress;
		let tmp = await this.storageService.getItem('analyticsLiveServerAddress');
		analyticsLiveServerAddress = (tmp || {}).analyticsLiveServerAddress;
		if (analyticsLiveServerAddress) {
			this.updateSocket(analyticsLiveServerAddress);
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	onConnectInConnectionList(data: ConnectionData) {
		let url = data.url;
		this.storageService.setItem('analyticsLiveServerAddress', {
			analyticsLiveServerAddress: url
		});
		this.updateSocket(url);
	}

	public connect() {
		this.connection.connect();
		this.sensorsService.runSensor(this.connection);
		this.connectEvent = {
			url: this.connection.ip,
			type: this.type
		};
		super.connect();
	}

	public disconnect() {
		this.disconnectEvent = {
			url: this.connection.url,
			type: this.type
		};
		this.connection.disconnect();
		super.disconnect();
	}

	public getConnectionStatus(): ConnectionStatus {
		if (this.connection) {
			if (this.connection.isConnecting()) {
				return ConnectionStatus.CONNECTING;
			} else {
				return this.connection.isConnected() ? ConnectionStatus.CONNECTED : ConnectionStatus.DISCONNECTED;
			}
		} else {
			return ConnectionStatus.DISCONNECTED;
		}
	}

	public updateIP() {
		if (this.connection) {
			this.ip = this.connection.ip;
		}
	}

	private subscribeToSocketEvents() {
		this.subscriptions.push(this.connection.status.subscribe((status: ConnectionStatus) => {
			this.updateStatus();
		}));
		this.subscriptions.push(this.connection.close.subscribe(disconnectReason => {
			this.disconnectReason = disconnectReason;
		}));
		this.updateStatus();
	}

	private async updateSocket(analyticsLiveServerAddress) {
		this.connection = this.sensorsService.getMqttSocket(`${analyticsLiveServerAddress}:${environment.mqttBrokerWebsocketPort}`) || await this.sensorsService.createMqttSocket(analyticsLiveServerAddress, environment.mqttBrokerWebsocketPort);

		if (this.connection) {
			this.subscribeToSocketEvents();
			this.connect();
			this.updateIP();
		}
	}

	private updateStatus() {
		if (this.connection) {
			let status;
			switch (this.connection.connectionStatus) {
				case ConnectionStatus.CONNECTING:
					status = `STATUS_OF_BROKER_CONNECTING`;
					break;
				case ConnectionStatus.CONNECTED:
				case ConnectionStatus.STOPPED:
				case ConnectionStatus.INITIALIZING:
				case ConnectionStatus.IMAGING:
				case ConnectionStatus.CALIBRATING:
				case ConnectionStatus.CONFIGURING:
					status = 'STATUS_OF_BROKER_CONNECTED';
					break;
				case ConnectionStatus.ERROR:
					status = `STATUS_OF_BROKER_ERROR`;
					break;
				case ConnectionStatus.DISCONNECTED:
					status = `STATUS_OF_BROKER_DISCONNECTED`;
					break;
				case ConnectionStatus.NOT_CONNECTED:
					status = `STATUS_OF_BROKER_NO_SENSOR`;
					break;
			}
			this.setConnectionStatus(status);
		}
	}
}
