import {Component} from '@angular/core';
import {LayersContainerComponentBase} from '../../../../components/layers/layers-container-component-base.component';

@Component({
	selector: 'app-layers-container',
	templateUrl: '../../../../components/layers/layers-container-component-base.component.html',
	styleUrls: ['../../../../components/layers/layers-container-component-base.component.scss']
})
export class LayersContainerComponent extends LayersContainerComponentBase {
}
