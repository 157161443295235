import {Socket} from './socket';
import {MqttClient} from 'mqtt';
import {environment} from '../../../environments/environment';
import {ConnectionStatus} from './connection';

declare const require: any;
let mqtt = require('./mqtt.min');

/**
 * Class for working with MQTT broker.
 */
export class MqttSocket extends Socket {

	private client: MqttClient;
	private isSubscribed = false;

	isRunned() {
		return this.isSubscribed;
	}

	isConnected(): boolean {
		return !!(this.client && this.client.connected);
	}

	isConnecting(): boolean {
		return !!(this.client && !this.client.connected && !this.client.disconnected);
	}

	connect() {
		if (this.canDisconnect()) {
			return Promise.resolve();
		}

		this.disconnectReason = null;
		this._lastErrorMessage = null;
		this.connectionStatus = ConnectionStatus.CONNECTING;

		this.client = mqtt.connect(`mqtt://${this.url}`, environment.analyticsMqttOptions);

		this.client.on('connect', () => {
			this.connectionStatus = ConnectionStatus.CONNECTED;
			this.open.emit();
			this.registerSocketEvent();
			if (this.connectResolve) {
				this.connectResolve();
				this.connectResolve = null;
				this.connectReject = null;
			}
		});

		this.client.on('close', e => {
			this.connectionStatus = ConnectionStatus.DISCONNECTED;
			this.client.disconnected = true;
		});

		return new Promise((resolve, reject) => {
			this.connectResolve = resolve;
			this.connectReject = reject;
		});
	}

	disconnect() {
		super.disconnect();
		if (this.client) {
			this.stop();
			this.client.end();
		}
	}

	start() {
		this.client.subscribe(environment.analyticsDataTopic, (err) => {
			if (!err) {
				this.isSubscribed = true;
			}
		});
	}

	stop() {
		this.isSubscribed = false;
		this.client.unsubscribe(environment.analyticsDataTopic);
	}

	retrieveStatus() {
		return true;
	}

	protected onMessage(jsonPayload, topic?) {
		this.data.emit({
			type: 'json',
			topic,
			data: jsonPayload
		});
		super.onMessage(jsonPayload);
	}

	protected registerSocketEvent() {
		if (this.client) {
			this.client.on('message', (topic, payload: Buffer) => {
				try {
					this.onMessage(JSON.parse(payload.toString()), topic);
				} catch (e) {
					this._lastErrorMessage = e.message;
					console.error(e);
				}
			});
		} else {
			console.warn('WebSocket object is absent');
		}
	}
}
