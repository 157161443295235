// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environmentBase = {
	production: false,
	// Cache: offline - localStorage, online - Firebase
	offline: true,
	// Smartbuilding
	useAzure: true,
	shouldValidateUniqueMonitoredSeats: true,
	showWelcomeFlow: false,

	supportedSensorParametersListParameterName: 'FlowCfg.ExternalGUI.parameters',
	configurablePagesParameterName: 'FlowCfg.ExternalGUI.pages',
	/**
	 * Modules
	 */
	isFlowProcessorModuleEnabled: true,
	FLOW_PROCESSOR_MODULE_PATH: 'fp',
	isSmartRetailEnabled: false,
	/**
	 * Should be overwritten in inherited object
	 */
	get isSmartBuildingsModuleEnabled() {
		return this.isSmartRetailEnabled;
	},
	SMART_BUILDINGS_MODULE_PATH: 'smart-buildings',
	isSmartHomeModuleEnabled: false,
	SMART_HOME_MODULE_PATH: 'smart-home',
	isVitalsModuleEnabled: false,
	VITALS_MODULE_PATH: 'covid',
	isSmartCoolerModuleEnabled: false,
	SMART_COOLER_MODULE_PATH: 'smart-cooler',
	isSmartTailorModuleEnabled: false,
	SMART_TAILOR_MODULE_PATH: 'smart-tailor',

	//
	isFloorPlanPageEnabled: true,
	analyticsRESTserverTimeout: 2000,
	analyticsRESTserverLiveDataInterval: 10 * 1000,
	disableRemoteConnToRESTServer: false,
	analyticsRESTserverAddress: '127.0.0.1',
	mqttBrokerWebsocketPort: '8080', // 8000
	analyticsDataTopic: 'xyz_locations/#',
	analyticsMqttOptions: {
		reconnectPeriod: 0
	}, // path: '/mqtt'

	// sensor
	localHostSensorIp: '127.0.0.1',
	defaultSensorPort: '1234',
	// used as fallback when ProcessorCfg.ExternalGUI.OutputsDataType is not present
	outputsType: 'json_outputs',
	max3DRoomHeight: 2.5, // in meters
	sensitivityMin: 0,
	sensitivityMax: 10,
	sensorImagingDataTimeout: -1, // disabled
	maxConnectionAttempts: 4,
	threeDRenderFPS: 15,
	sensorDistanceFromCenter: 0.0,
	rpmEnabled: true,
	fallDetectionEnabled: false,
	minimumZoneWidth: 0.5, // in meters
	minimumZoneHeight: 0.5, // in meters
	parametersNamePrefix: 'parameters_',
	targetDirectionCalculationThreshold: 0.3, // in meters
	cmToInches: 0.39370079,
	defaultGuiParameters: {
		'isDuplicateView': false,
		'isCoverageFeet': false,
		'selectedLayers': [[], []],
		'isBreathingMetric': false,
		'isCountingMetric': false,
		'isRpmMetric': false,
		'selectingTargetDataGraphBehavior': 1, // Breathing Graphs enabled automatically
	},
	defaultSocketInfo: {
		name: '',
		showWelcomeFlow: true,
		showReprocessSettingsWindow: true,
	},
	defaultSensorNetworkParameters: {
		globalSensorLocation: [0, 0],
		globalSensorOrientation: 0,
		showWelcomeFlow: true,
		showReprocessSettingsWindow: true
	},
	defaultParameterConfiguration: {
		isEditable: true,
		isCacheable: false,
		isSavable: true
	},
	floorPlanLayers: [
		{
			'componentName': 'MultipleRooms',
			'displayName': 'Floor plan',
			'dataDict': {
				'targetCenterData': 'GlobalLocationMatrix'
			}
		}
	],
	smartBuildingsLayers: [
		{
			'componentName': 'MultipleRooms',
			'displayName': 'Floor plan',
			'dataDict': {
				'locations': 'locations'
			}
		}
	],
	roomOccupancyLayers: [
		{
			'componentName': 'RoomOccupancy',
			'displayName': 'Room Occupancy',
			'dataDict': {
				'roomId': 'roomId',
				'roomNumOfPeople': 'roomNumOfPeople'
			}
		}
	],
	smartHomeLayers: [
		{
			'componentName': 'SmartHomeDashboard',
			'displayName': 'Dashboard',
			'dataDict': {},
			'staticDataDict': {}
		}, {
			'componentName': 'SmartHomeAc',
			'displayName': 'AC Monitoring',
			'dataDict': {},
			'staticDataDict': {}
		}
	],
	smartCoolerLayers: [
		{
			'componentName': 'CoolerInventory',
			'displayName': 'Cooler Inventory',
			'dataDict': {
				'bottlesOccupancy': 'bottlesOccupancy'
			},
			'staticDataDict': {
				'bottlesRowsColumnsLevel': [3, 3],
				'shelfIndex': 0
			}
		}, {
			'componentName': 'CoolerTopView',
			'displayName': 'Top View',
			'dataDict': {
				'bottlesOccupancy': 'bottlesOccupancy'
			},
			'staticDataDict': {
				'bottlesRowsColumnsLevel': [3, 3],
				'shelfIndex': 0
			}
		}
	],
	mqttBrokerURL: 'https://evk-test.azurewebsites.net/api',
	firebase: {
		apiKey: 'AIzaSyAgrxOTp3rt_9H8kRNR5tktr511FKbfkz0',
		authDomain: 'vayyar-evk.firebaseapp.com',
		databaseURL: 'https://vayyar-evk.firebaseio.com',
		projectId: 'vayyar-evk',
		storageBucket: 'vayyar-evk.appspot.com',
		messagingSenderId: '511094601423',
		appId: '1:511094601423:web:bf7f71b81f1f79c44fa9ba'
	},
	azure: {
		metadataFunctionAppURL: 'https://func-vayyar-metadata-vfloors.azurewebsites.net/metadata',
        commonAnalyticsFunctionAppURL: 'https://func-vayyar-common-analytics-vfloors.azurewebsites.net/common-analytics',
        telemetryFunctionAppURL: 'https://func-vayyar-retail-analytics-vfloors.azurewebsites.net/smart-retail', // For analytics, upload floor plan, upload new building...
        realTimeFunctionAppURL: 'https://func-vayyar-real-time-view-vfloors.azurewebsites.net/api',
		signalrKeepAliveInterval: 60 * 1000,
		inactivityTimeout: 5 * 60 * 1000,
		noReactionOnInactivityMessageTimeout: 30 * 1000,
		retailInactivityTimeout: 2 * 60 * 1000,
		realTimeTargetName: 'live'
	}
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
