import {Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RunMode, SensorSocket} from '../../../../services/system/sensor-socket';
import {environment} from '../../../../../environments/environment';
import {ToolbarBaseComponent} from '../toolbar.base.component';
import {ConnectionStatus} from '../../../../services/system/connection';
import {ConfigurablePageControlType, IConfigurablePageControl} from '../../../../services/configuration.service';
import {
	TabbedFormControlComponent
} from '../../../../applications/flow-processor/components/tabbed-form-control/tabbed-form-control.component';
import {MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

/**
 * Component renders a block with buttons and info on the top of BaseViewComponent.
 */
@Component({
	selector: 'app-toolbar-evk',
	templateUrl: './toolbar-evk.component.html',
	styleUrls: ['./toolbar-evk.component.scss']
})
export class ToolbarEvkComponent extends ToolbarBaseComponent implements OnInit, OnDestroy {

	@ViewChild('fileuploadSettings') fileuploadSettingsInput: ElementRef;

	environment = environment;
	ConnectionStatus = ConnectionStatus;
	ConfigurablePageControlType = ConfigurablePageControlType;
	parameters: any = {};

	// TODO remove when it's will be implemented in configuration
	rightSideControls = [
		ConfigurablePageControlType.PAGE_LOAD_AND_SAVE,
		ConfigurablePageControlType.PAGE_STOP_SENSOR,
		ConfigurablePageControlType.PAGE_RUN_SENSOR,
		ConfigurablePageControlType.PAGE_RECORD,
		ConfigurablePageControlType.PAGE_LOAD_RECORDING
	];

	private parametersChangeSubscription: Subscription;

	constructor(protected injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		this.subscriptions.push(this.toolbarService.connectionSubject.subscribe(connection => {
			if (this.parametersChangeSubscription) {
				this.parametersChangeSubscription.unsubscribe();
			}
			this.parametersChangeSubscription = this.sensorsService.eventListeners.parametersChange[connection.url].subscribe(parameters => {
				this.parameters = parameters || {};
			});
		}));
	}

	ngOnDestroy() {
		if (this.parametersChangeSubscription) {
			this.parametersChangeSubscription.unsubscribe();
		}
		super.ngOnDestroy();
	}

	onAction(control: IConfigurablePageControl) {
		let connection = this.toolbarService.getConnection() as SensorSocket;

		switch (control.type) {
			case ConfigurablePageControlType.PAGE_LOAD_AND_SAVE:
				if (connection.recordingInfo.mode === RunMode.REPROCESS_AND_RECORD) {
					this.stopSensor();
				} else {
					this.loadAndSave(control);
				}
				break;
			case ConfigurablePageControlType.PAGE_LOAD_RECORDING:
				if (connection.recordingInfo.mode === RunMode.REPROCESS_IQ_DATA) {
					this.stopSensor();
				} else {
					this.loadRecording(control);
				}
				break;
			case ConfigurablePageControlType.PAGE_RECORD:
				if (connection.recordingInfo.mode === RunMode.RECORD) {
					this.stopSensor();
				} else {
					this.runRecord(control);
				}
				break;
			case ConfigurablePageControlType.PAGE_STOP_SENSOR:
				this.stopSensor();
				break;
			case ConfigurablePageControlType.PAGE_RUN_SENSOR:
				if (connection.recordingInfo.mode === RunMode.LIVE && connection.isRunned()) {
					this.stopSensor();
				} else {
					this.runSensor();
				}
				break;
			case ConfigurablePageControlType.PAGE_TABBED_FORM_CONTROL:
				this.onClickShowSettings(control);
				break;
			case ConfigurablePageControlType.PAGE_DUPLICATE:
				this.onClickDuplicateBtn();
				break;
			case ConfigurablePageControlType.PAGE_SET_VALUE_CONTROL:
				this.onClickSetValueBtn(control);
				break;
			case ConfigurablePageControlType.PAGE_SWITCH_CONTROL:
				this.onClickSwitchBtn(control);
				break;
			case ConfigurablePageControlType.PAGE_CLEAR_CACHE:
				this.clearAll();
				break;
			case ConfigurablePageControlType.PAGE_SWITCH_MULTIPLE_CONTROL:
				this.onClickSwitchMultipleBtn(control);
				break;
		}
	}

	runSensor() {
		this.toolbarService.sensorRun.emit();
	}

	stopSensor() {
		this.toolbarService.sensorStop.emit();
	}

	runRecord(control: IConfigurablePageControl) {
		this.toolbarService.runRecord.emit({control});
	}

	loadAndSave(control: IConfigurablePageControl) {
		this.toolbarService.loadAndSave.emit({control});
	}

	loadRecording(control: IConfigurablePageControl) {
		this.toolbarService.recordingPlayingToggle.emit({control});
	}

	clearAll() {
		this.storageService.clearStorage();
		document.location.reload(true);
	}

	keys(o: Object) {
		var keys = Object.keys(o);
		return keys.slice(keys.length / 2);
	}


	onShowSettings(control: IConfigurablePageControl): MatDialogRef<any> {
		let isFloorPlan = environment.isSmartBuildingsModuleEnabled && this.toolbarService.getIsSmartBuildings();

		let dialogRef = this.dialog.open(TabbedFormControlComponent, {
			autoFocus: false,
			width: '1104px',
			disableClose: true,
			panelClass: 'modal-popup',
			minHeight: 632,
			data: {
				sensorSocket: isFloorPlan ? undefined : this.toolbarService.getConnection(),
				isFloorPlan: isFloorPlan,
				isUserSettings: false,
				page: this.toolbarService.getRoute().data.pageName,
				tabs: control ? control.tabs : []
			}
		});

		return dialogRef;
	}

	onClickShowSettings(control: IConfigurablePageControl) {
		this.onShowSettings(control);
	}

	onClickDuplicateBtn() {
		this.toolbarService.duplicateView.emit();
	}

	onClickSetValueBtn(control: IConfigurablePageControl) {
		this.sensorsService.sendSettings(this.toolbarService.getConnection() as SensorSocket, true, {
			[control.param as string]: control.value,
		});
	}

	onClickSwitchMultipleBtn(control: IConfigurablePageControl) {
		const value = control.active ? false : true;
		const message = {};
		(control.param as Array<string>).forEach((el) => {
			message[el] = value;
		});
		this.sensorsService.sendSettings(this.toolbarService.getConnection() as SensorSocket, true,
			message,
		);
		control.active = value;
	}

	onClickSwitchBtn(control: IConfigurablePageControl) {
		this.sensorsService.sendSettings(this.toolbarService.getConnection() as SensorSocket, true, {
			[control.param as string]: !this.parameters[control.param as string],
		});
	}

	isControlDisabled(control: IConfigurablePageControl) {
		let disabled = false,
			connection = this.toolbarService.getConnection() as SensorSocket,
			isRunned = connection?.isRunned();
		switch (control.type) {
			case ConfigurablePageControlType.PAGE_LOAD_AND_SAVE:
				disabled = isRunned && connection.recordingInfo.mode !== RunMode.REPROCESS_AND_RECORD;
				break;
			case ConfigurablePageControlType.PAGE_LOAD_RECORDING:
				disabled = isRunned && connection.recordingInfo.mode !== RunMode.REPROCESS_IQ_DATA;
				break;
			case ConfigurablePageControlType.PAGE_RECORD:
				disabled = (isRunned && connection.recordingInfo.mode !== RunMode.RECORD);
				break;
			case ConfigurablePageControlType.PAGE_STOP_SENSOR:
				disabled = !isRunned;
				break;
			case ConfigurablePageControlType.PAGE_RUN_SENSOR:
				disabled = isRunned && connection.recordingInfo.mode !== RunMode.LIVE;
				break;
			case ConfigurablePageControlType.PAGE_TABBED_FORM_CONTROL:
				disabled = isRunned;
				break;
		}
		return disabled;
	}
}
