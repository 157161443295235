import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FirebaseService} from '../services/firebase.service';
import {StorageService} from '../services/storage.service';
import {environment} from '../../environments/environment';

/**
 * Guard checks user's authorization.
 * If firebase login enabled and localStorage has lastLoggedUser then it will silently tried to log in.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	redirectURL;

	constructor(private firebaseService: FirebaseService,
				private router: Router,
				private storageService: StorageService) {

	}

	canActivate(next: ActivatedRouteSnapshot,
				state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

		if (environment.offline || this.firebaseService.isLoggedIn()) {
			return true;
		} else {
			return this.storageService.getItem('lastLoggedInUser').then(lastLoggedInUser => {
				if (lastLoggedInUser) {
					return this.firebaseService.signIn(lastLoggedInUser).then(success => {
						if (success) {
							return true;
						} else {
							this.router.navigate(['/login']);
							this.redirectURL = state.url;
							return false;
						}
					});
				} else {
					this.router.navigate(['/login']);
					this.redirectURL = state.url;
					return false;
				}
			});
		}
	}
}
