import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe converts number to 'HH:mm:ss' or 'mm:ss' format.
 */
@Pipe({
	name: 'duration'
})
export class DurationPipe implements PipeTransform {

	transform(value: number, hours = false): any {
		if(value < 0) {
			return '00:00';
		}
		var s = Math.floor(value / 1000),
			m = Math.floor(s / 60),
			h = Math.floor(m / 60),
			ret = '';

		if (hours) {
			let remainingMins = m - +h * 60;
			ret = h.toString().padStart(2, '0') + ':';
			ret += remainingMins.toString().padStart(2, '0') + ':';
			ret += (s - h * 3600 - remainingMins * 60).toString().padStart(2, '0');
		} else {
			ret = m.toString().padStart(2, '0') + ':';
			ret += (s - +m * 60).toString().padStart(2, '0');
		}

		return ret.replace(/NaN/g, '00');
	}

}
