<div class="toolbar-wrapper">
	<div class="toolbar-block toolbar-block__left">
		<ng-container *ngIf="toolbarService.isAnalyticsFormShown">
			<div class="container d-flex h-100" [style.justify-content]="!toolbarService.isAnalyticsFormShown && 'flex-end'"
				 *ngVar="!toolbarService.getConnection()?.isConnected() || (toolbarService.isAnalyticsLive && toolbarService.analyticsSelectedGraph.liveModeSupport) || (busEventService.restIsLoading | async) as disabled">
				<form class="form-group-left" [formGroup]="toolbarService.analyticsForm">
					<div class="d-flex align-items-center">
						<ng-container *ngTemplateOutlet="buttons"></ng-container>
					</div>
					<div class="d-flex toolbar-title">
						<ng-container *ngIf="toolbarService.isAnalyticsTotalTrafficShown; else elseBlock">
							<span>Total Traffic: {{toolbarService.analyticsTotalTraffic}} <span class="material-icons people-icon">people</span></span>
						</ng-container>
						<ng-template #elseBlock>
							<span *ngIf="toolbarService.analyticsForm.controls.location_ids.value.length; else floorName">
								{{geoFencesNames}}
							</span>
							<ng-template #floorName>
								<span *ngIf="locationNames[toolbarService.analyticsForm.controls.building_id.value]">
									{{locationNames[toolbarService.analyticsForm.controls.building_id.value]}}
								</span>
							</ng-template>
						</ng-template>
					</div>
					<div class="d-flex align-items-center toolbar-panel">
						<div class="toolbar-panel">
							<mat-form-field [attr.data-value]="toolbarService.analyticsForm.get('building_id').value" matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor" appMatSelectAutoWidth floatLabel="never" [class.mat-form-field-disabled]="disabled" data-selector="toolbar_locations_select">
								<mat-label>{{'LOCATIONS' | translate }}</mat-label>
								<mat-select formControlName="building_id" [disableControl]="disabled">
									<div>
										<mat-option *ngFor="let location of locations" [value]="location.id">
											{{location.building_name}}
										</mat-option>
									</div>
								</mat-select>
							</mat-form-field>
						</div>
						<div *ngIf="toolbarService.isAnalyticsGeoFencesFilterShown" class="toolbar-panel">
							<mat-form-field [attr.data-value]="toolbarService.analyticsForm.get('location_ids').value" matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor" appMatSelectAutoWidth floatLabel="never" [class.mat-form-field-disabled]="disabled" data-selector="toolbar_geo_fences_select">
								<mat-label>{{'GEO_FENCES' | translate }}</mat-label>
								<mat-select formControlName="location_ids"
											[disableControl]="disabled" multiple>
									<div>
										<mat-option *ngFor="let location of geoFences | filter : 'parent_floor' : toolbarService.floorId" [value]="location.id">
											{{location.location_name}}
										</mat-option>
									</div>
								</mat-select>
							</mat-form-field>
						</div>

						<mat-form-field [attr.data-value]="toolbarService.analyticsForm.get('timezone').value" class="timezone-field" matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor" appMatSelectAutoWidth floatLabel="never" [class.mat-form-field-disabled]="disabled" data-selector="toolbar_timezone_field">
							<mat-label>{{'TIMEZONE' | translate }}</mat-label>

							<input type="text"
								   [placeholder]="'TIMEZONE' | translate"
								   matInput
								   #input
								   #autoCompleteTrigger="matAutocompleteTrigger"
								   [disableControl]="disabled"
								   formControlName="timezone"
								   (focus)="onTimezoneInputFocus(input)"
								   (keydown)="onTimezoneInputKeydown(input)"
								   [matAutocomplete]="auto">
							<mat-autocomplete #auto="matAutocomplete" (closed)="onTimezoneInputBlur($event, input)" (optionSelected)="onTimezoneChanged($event)" class="timezone-autoselect-panel">
								<mat-option *ngFor="let timezone of timezones | filter : 'name' : input.value : false" [value]="timezone.id">
									{{timezone.name}}
								</mat-option>
							</mat-autocomplete>
						</mat-form-field>

						<mat-form-field matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor" (click)="picker.open()" class="date" floatLabel="never" [class.mat-form-field-disabled]="disabled">
							<mat-label>Enter a date range</mat-label>
							<mat-date-range-input data-selector="toolbar_date-range_select" [attr.disabled]="disabled" [max]="endDateMax" [rangePicker]="picker">
								<input matStartDate (ngModelChange)="onDateChanged()" [formControl]="toolbarService.analyticsForm.controls.start_date" [placeholder]="'START_DATE' | translate">
								<input matEndDate (ngModelChange)="onDateChanged()" [formControl]="toolbarService.analyticsForm.controls.end_date" [placeholder]="'END_DATE' | translate">
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
						<mat-form-field #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" matRipple matRippleUnbounded="true" matRippleRadius="30" [matRippleColor]="rippleColor"
										(click)="$any(menuTrigger).openMenu()" class="time" floatLabel="never" [class.mat-form-field-disabled]="disabled" data-selector="toolbar_time-range_select">
							<input matInput [value]="(toolbarService.analyticsForm.controls.start_time.value | date:'HH:mm') + ' - ' + (toolbarService.analyticsForm.controls.end_time.value | date:'HH:mm')">
							<mat-icon class="fs-13" matSuffix>query_builder</mat-icon>
						</mat-form-field>
						<mat-menu #menu="matMenu" class="time-popover" >
							<div (click)="$event.stopPropagation()">
								<div class="timepickers" >
									<div class="timepicker timepicker-hidden-minutes">
										<label>Start time</label>
										<ngx-mat-timepicker
											data-selector="toolbar_timepicker_time-from"
											[(ngModel)]="timePopover.start_time"
											[ngModelOptions]="{standalone: true}"
											[disabled]="disabled"
											[showSeconds]="false">
										</ngx-mat-timepicker>
									</div>
									<div class="timepicker timepicker-hidden-minutes">
										<label>End time</label>
										<ngx-mat-timepicker
											data-selector="toolbar_timepicker_time-to"
											[(ngModel)]="timePopover.end_time"
											[ngModelOptions]="{standalone: true}"
											[disabled]="disabled"
											[showSeconds]="false">
										</ngx-mat-timepicker>
									</div>
								</div>
								<div class="actions">
									<button mat-stroked-button (click)="saveTime(menuTrigger)"
											data-selector="toolbar_timepicker_button-ok"
											[disabled]="timePopover.end_time < timePopover.start_time">OK</button>
								</div>
							</div>
						</mat-menu>
					</div>
				</form>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #buttons>
	<div class="button-container">
		<app-toolbar-button
			(onClick)="onResetFilters()"
			dataSelector="toolbar_reset-filters_button"
			[label]="'RESET_FILTERS' | translate"
			icon="settings_backup_restore"
			class="toolbar-button"
		></app-toolbar-button>
		<app-toolbar-button
			(onClick)="onRefreshClick()"
			dataSelector="toolbar_refresh_button"
			[disabled]="!toolbarService.getConnection()?.isConnected() || (busEventService.restIsLoading | async)"
			[label]="'REFRESH' | translate"
			icon="cached"
			class="toolbar-button"
		></app-toolbar-button>
	</div>
</ng-template>
