<div class="modal-default" data-selector="popup_message">
	<h3 [innerHTML]="data.title | translate:data.translateData | htmlNewLine"></h3>
	<div class="message" perfectScrollbar [disabled]="disabledScroll">
		<ng-container *ngIf="data.message">
			<p [innerHTML]="data.message | translate:data.translateData | htmlNewLine | safe:'html'"></p>
		</ng-container>
		<ng-container *ngIf="data.showLoading">
			<app-loading></app-loading>
		</ng-container>
	</div>
	<div class="default-panel" *ngIf="data.showCancel || data.showOk || data.additionalButtons?.length">
		<button data-selector="modal-message_cancel_button" mat-button (click)="reject()" class="g-btn reject float-left"
				[disabled]="cancelButtonDisabled"
				*ngIf="data.showCancel">{{(data.cancelText || 'Cancel') | translate}}
		</button>
		<button mat-button (click)="resolve()" class="g-btn reject button-right" *ngIf="data.showOk">
			{{(data.okText || 'OK') | translate}}
		</button>
		<ng-container *ngIf="data.additionalButtons">
			<button *ngFor="let button of data.additionalButtons" mat-button (click)="resolve(button.key)"
					class="g-btn reject button-right">{{button.text | translate}}
			</button>
		</ng-container>
	</div>
</div>



