import {Component, Injector, OnInit} from '@angular/core';
import {MultiSensorsSocket} from '../../../../../services/system/multi-sensors-socket';
import {SingleSensorComponent} from '../single-sensor/single-sensor.component';
import {SelectingTargetDataGraphBehavior} from '../../../../../consts';
import {environment} from '../../../../../../environments/environment';
import {ConnectionStatus} from '../../../../../services/system/connection';
import {MODAL_TYPE} from '../../../../../services/modal.service';

/**
 * Component is designed for showing scheme of car with multiple sensors.
 * Used for InCar app.
 */
@Component({
	selector: 'app-incar-multi-sensors',
	templateUrl: '../../../../../components/base-view/base-view.component.html',
	styleUrls: ['../../../../../components/base-view/base-view.component.scss']
})
export class IncarMultiSensorsComponent extends SingleSensorComponent implements OnInit {

	connection: MultiSensorsSocket;

	constructor(protected injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		this.isMultipleSensors = true;
		this.subscribeToToolbarControls();
		this.toolbarService.setIsMultipleSensors(this.isMultipleSensors);
		this.connection = this.sensorsService.getMultiSensorSocket();
		this.toolbarService.setConnection(this.connection);
		this.registerSocketEvents();
		this.onParametersChange();
		this.connection.retrieveStatus();
		this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
			this.combinedData = data;
		}));
	}

	async updateWithSettings(initialParameters?) {
		let url = this.connection.url;
		let connectedSocket = this.sensorsService.sensorSockets.value.find(socket => socket.isConnected());
		if (connectedSocket) {
			url = connectedSocket.url;
		}
		const parameters = await this.settingsService.getAllSettings(url);
		this.toolbarService.setIsDuplicateViewFlag(this.isDuplicateView);
		this.sensitivityValue = parameters.sensorParameters['ProcessorCfg.ExternalGUI.FilterImage.numOfSd'];

		// Get layers from first connected sensor, but selected layers - from multisensor settings
		let inCarMultiSensorSettings = await this.settingsService.getAllSettings(this.connection.url);
		parameters.guiParameters = inCarMultiSensorSettings.guiParameters;

		this.isDuplicateView = parameters.guiParameters.isDuplicateView;

		if (initialParameters) {
			this.generateLayers({
				sensorParameters: Object.assign({}, initialParameters, {
					'ProcessorCfg.ExternalGUI.Layers': this.configurationService.pageMap['Sensor'].layers
				}),
				guiParameters: parameters.guiParameters
			});
			this.setOutputs(false);
		}
	}


	protected async onParametersChange(initialParameters?) {
		let url = this.connection.url;
		let connectedSocket = this.sensorsService.sensorSockets.value.find(socket => socket.isConnected());
		if (connectedSocket) {
			url = connectedSocket.url;
		}

		const connectedSockets = this.sensorsService.sensorSockets.value
		.filter(socket => socket.isConnected())
		.map(socket => this.settingsService.getAllSettings(socket.url));
		this.connectedSensorsSettings = await Promise.all(connectedSockets);

		const usedDefaultSettings = true;
		// Get settings from connection or localStorage
		const parameters = await this.settingsService.getAllSettings(url, usedDefaultSettings);

		// In case we retrieve sensorParameters from another client
		if (!('guiParameters' in parameters)) {
			parameters['guiParameters'] = this.settingsService.getDefaultSettings()['guiParameters'];
		}

		this.connectionSettings = parameters;
		this.toolbarService.setSensorSocketSettings(this.connectionSettings);
		if (this.connectionSettings['guiParameters']['selectingTargetDataGraphBehavior'] === SelectingTargetDataGraphBehavior.Automatically) {
			this.selectedModelIds = [];
		}

		initialParameters = parameters['sensorParameters'];
		this.updateWithSettings(initialParameters);
	}

	/**
	 * Subscribe on status changes, close, parametersChange, dataRetrieve, metaDataRetrieve, combined data in connection.
	 */
	protected registerSocketEvents() {
		var previousStatus;
		if (this.statusSub) {
			this.statusSub.unsubscribe();
		}
		this.statusSub = this.connection.status.subscribe((status: ConnectionStatus) => {
			if (this.connection) {
				switch (status) {
					case ConnectionStatus.SAVING_DATA:
						if (this.isNeedToPlayBeepSound) {
							this.playBeepSound();
						}
						this.isNeedToPlayBeepSound = true;
						break;
					case ConnectionStatus.ERROR:
						let errorMessage = this.connection.lastErrorMessage,
							showOk = true;
						/**
						 * We want to close all opened modal windows except errors
						 */
						this.modalService.close(MODAL_TYPE.CONFIRM, MODAL_TYPE.INPUT, MODAL_TYPE.MESSAGE);
						if (!errorMessage) {
							/**
							 * If this is unhandled exception:
							 * 1. Show "Unhandled exception!" message
							 * 2. Ask for the sensor status 1 time:
							 * 	> if status is still ERROR - ask user to restart EVK and reconnect (popup will be closed after disconnect)
							 */
							if (!this.isStatusAfterErrorAsked) {
								errorMessage = 'UNHANDLED_EXCEPTION_ERROR';
								this.connection.retrieveStatus();
								this.isStatusAfterErrorAsked = true;
							} else {
								/**
								 * At this stage we have another "Unhandled exception" without an error message
								 */
								if (this.lastErrorWindow) {
									/**
									 * Since we got another "Unhandled exception" we can close the previous one
									 */
									this.lastErrorWindow.close();
								}
								errorMessage = 'UNHANDLED_EXCEPTION_ERROR_WITH_RECONNECT';
								this.isStatusAfterErrorAsked = false;
								showOk = false;
							}
						}
						this.lastErrorWindow = this.modalService.showError(errorMessage, showOk);
						this.lastErrorWindow.afterClosed().toPromise().then(() => {
							this.lastErrorWindow = null;
						});
						break;
					case ConnectionStatus.DISCONNECTED:
						this.modalService.close(MODAL_TYPE.ALL);
						this.data = {};
						this.combinedData = {};
						break;
				}
				previousStatus = status;
			}
		});
		if (this.closeSub) {
			this.closeSub.unsubscribe();
		}
		this.closeSub = this.connection.close.subscribe(disconnectReason => {
			this.toolbarService.setDisconnectReason(disconnectReason);
		});
		this.subscriptions.push(this.sensorsService.parametersChange.subscribe(e => {
			/**
			 * Subscription is BehaviorSubject and after re-connect to socket we could call onParametersChange until socket is not
			 * connected. So we should check, that socket is connected.
			 */
			if (e && this.connection.isConnected()) {
				this.onParametersChange(e.parameters);
			}
		}));
		if (this.sensorsService.eventListeners.dataRetrieve[this.connection.url]) {
			this.subscriptions.push(this.sensorsService.eventListeners.dataRetrieve[this.connection.url].subscribe(data => {
				this.data = data;
			}));
		}
		this.subscriptions.push(this.dataService.combinedData.subscribe(data => {
			this.combinedData = data;
		}));
	}
}
