import {Injectable, Injector} from '@angular/core';
import {StorageService} from './storage.service';
import {environment} from '../../environments/environment';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
	feetToMeter, getChangedParameters,
	getNonCacheableSensorParameters,
	makeDeepCopy,
	meterToFeet
} from '../utils/utils';
import {SensorSocket} from './system/sensor-socket';
import {ModalService} from './modal.service';
import {Tabs} from '../applications/flow-processor/components/tabbed-form-control/arena/arena.component';
import {SensorMountPlane, SelectingTargetDataGraphBehavior} from '../consts';
import {SensorsService} from './system/sensors.service';
import {RetailGeoFence} from '../models/models';

declare const require: any;
const merge = require('deepmerge');
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
const overwriteOptions = {arrayMerge: overwriteMerge};

export const VALUE_TO_DELETE = '__VALUE_TO_DELETE__';

/**
 * Service for working with configuration.
 */
@Injectable({
	providedIn: 'root'
})
export class SettingsService {

	environment = environment;

	// TODO refactor it
	sensorHeightParameter = 'ProcessorCfg.Common.sensorOrientation.transVec(3)';

	private localSettings = {};

	constructor(private storageService: StorageService,
				private modalService: ModalService,
				private fb: FormBuilder,
				private inject: Injector) {
	}

	getSensorParameters(url): Promise<object | null> {
		return this.getParameters(url).then(parameters => {
			if (parameters && 'sensorParameters' in parameters) {
				return Object.assign({}, parameters['sensorParameters']);
			} else {
				return null;
			}
		});
	}


	/**
	 * Save used by sensor parameters
	 * @param url
	 * @param parameters
	 */
	saveSensorParameters(url, parameters): Promise<object> {
		return this._saveSensorParameters(url, parameters);
	}


	getUIParameters(url) {
		return this.storageService.getItem(`${environment.parametersNamePrefix}${url}`).then(parameters => {
			if (!parameters) {
				return environment.defaultGuiParameters;
			} else {
				let p = {
					'guiParameters': Object.assign({}, environment.defaultGuiParameters, parameters['guiParameters']),
					'sensorParameters': parameters['sensorParameters']
				};
				this.prepareUIParameters(p);
				return p['guiParameters'];
			}
		});
	}


	saveUIParameters(url, parameters) {
		return this._saveUIParameters(url, parameters);
	}


	getSocketInfo(url) {
		return this.storageService.getItem(`${environment.parametersNamePrefix}${url}`).then(parameters => {
			if (!parameters) {
				return Object.assign({}, environment.defaultSocketInfo);
			} else {
				return parameters['socketInfo'] || {};
			}
		});
	}


	saveSocketInfo(url, info) {
		return this._saveSocketInfo(url, info);
	}


	getAllSettings(url, useDefault = true) {
		return this.getParameters(url).then(settings => {
			if (!settings && useDefault) {
				return this.getDefaultSettings();
			} else if (settings) {
				let ret = {};

				if ('sensorParameters' in settings) {
					ret['sensorParameters'] = Object.assign({}, settings['sensorParameters']);
				} else if (useDefault) {
					ret['sensorParameters'] = this.getDefaultSettings()['sensorParameters'];
				}
				if ('guiParameters' in settings) {
					ret['guiParameters'] = Object.assign({}, (useDefault ? environment.defaultGuiParameters : {}), settings['guiParameters']);
				} else if (useDefault) {
					ret['guiParameters'] = this.getDefaultSettings()['guiParameters'];
				}
				if ('socketInfo' in settings) {
					ret['socketInfo'] = settings['socketInfo'];
				} else if (useDefault) {
					ret['socketInfo'] = this.getDefaultSettings()['socketInfo'];
				}
				if ('sensorNetworkParameters' in settings) {
					ret['sensorNetworkParameters'] = settings['sensorNetworkParameters'];
				} else if (useDefault) {
					ret['sensorNetworkParameters'] = this.getDefaultSettings()['sensorNetworkParameters'];
				}

				return ret;
			} else {
				return settings;
			}
		});
	}


	getDefaultSettings() {
		return {
			sensorParameters: {},
			guiParameters: Object.assign({}, environment.defaultGuiParameters),
			socketInfo: Object.assign({}, environment.defaultSocketInfo),
			sensorNetworkParameters: Object.assign({}, environment.defaultSensorNetworkParameters),
			zones: []
		};
	}


	clearAllSetting(url) {
		this.clearLocalSettings(url);
		return this.storageService.removeItem(`${environment.parametersNamePrefix}${url}`);
	}


	getEmpty(socket: SensorSocket): FormGroup {
		let monitoredRoomDimsDisabled = !socket.isEditableParameter('ProcessorCfg.MonitoredRoomDims');
		return this.fb.group({
			'isCoverageFeet': new FormControl(false),
			'MonitoredRoomDimsXMin': new FormControl({
				disabled: monitoredRoomDimsDisabled
			}),
			'MonitoredRoomDimsXMax': new FormControl({
				disabled: monitoredRoomDimsDisabled
			}),
			'MonitoredRoomDimsYMin': new FormControl({
				disabled: monitoredRoomDimsDisabled
			}),
			'MonitoredRoomDimsYMax': new FormControl({
				disabled: monitoredRoomDimsDisabled
			}),
			'MonitoredRoomDimsZMin': new FormControl({
				disabled: monitoredRoomDimsDisabled
			}),
			'MonitoredRoomDimsZMax': new FormControl({
				disabled: monitoredRoomDimsDisabled
			}),
			// Don't save below fields to anywhere
			'activeTab': new FormControl(Tabs.ARENA), // activeTab in Settings.sensor
			'scene': new FormControl(null),
			'isSensorLocationDisabled': new FormControl(true),
			'isSensorMonitoringDisabled': new FormControl(true),
			'isCoverageVerified': new FormControl(false),
			'isLocationVerified': new FormControl(false),
			'isMonitoringVerified': new FormControl(false),
			'isChanged': new FormControl(false), // flag of changes, set manually to true
			'roomName': new FormControl(''),
			'globalSensorX': new FormControl(0),
			'globalSensorY': new FormControl(0),
			'globalSensorOrientation': new FormControl(0),
			'alert_on_disconnections': new FormControl({
				value: true
			}),
			'get_default_patient_form': new FormControl({
				value: false
			}),
			sensorParameters: new FormGroup({}),
			// Must be last in this structure
			'__lastField4Subscribe': new FormControl(false) // Must be last in this structure
		});
	}


	async getSettingsForm(socket: SensorSocket, settings): Promise<FormGroup> {
		var settingForm = this.getEmpty(socket);

		this.setSettingsForm(settings, settingForm);

		return settingForm;
	}


	async resetSettingsForm(settingForm, socket: SensorSocket) {
		settingForm.controls.activeTab.setValue(Tabs.ARENA);

		try {
			var sensorParameters = await socket.resetSensorParameters();
			if (!sensorParameters['ProcessorCfg.MonitoredRoomDims']) {
				sensorParameters = this.getDefaultSettings()['ProcessorCfg.MonitoredRoomDims'];
			}
			Object.keys(sensorParameters).forEach(param => {
				if (settingForm.controls.sensorParameters.controls[param]?._configurableControl.multiple && !(sensorParameters[param] instanceof Array)) {
					sensorParameters[param] = [sensorParameters[param]];
				}
			});
			var settings = Object.assign({}, this.getDefaultSettings(), {sensorParameters: sensorParameters});
			this.setSettingsForm(settings, settingForm);
		} catch (e) {
			console.error(e);
		}
	}


	/**
	 *
	 * @param settings
	 * @param settingForm
	 */
	async setSettingsForm(settings, settingForm: FormGroup) {
		let currentValue = settingForm.getRawValue(),
			isFeet = currentValue['isCoverageFeet'],
			newValue = {
				'activeTab': settingForm.controls.activeTab.value, // no need to change (because of Reset button in Settings form)
				'scene': settingForm.controls.scene.value,
				'isSensorLocationDisabled': settingForm.controls.isSensorLocationDisabled.value,
				'isSensorMonitoringDisabled': settingForm.controls.isSensorMonitoringDisabled.value,
				'isCoverageVerified': settingForm.controls.isCoverageVerified.value,
				'isLocationVerified': settingForm.controls.isLocationVerified.value,
				'isMonitoringVerified': settingForm.controls.isMonitoringVerified.value,
				'isChanged': settingForm.controls.isChanged.value,
			},
			sensorParameters = {};

		if (settings['sensorParameters']) {
			Object.assign(sensorParameters, settings['sensorParameters']);
		}
		if (settings['guiParameters']) {
			if ('isCoverageFeet' in settings['guiParameters']) {
				isFeet = settings['guiParameters']['isCoverageFeet'];
				newValue['isCoverageFeet'] = isFeet;
			}
			if ('selectingTargetDataGraphBehavior' in settings['guiParameters']) {
				newValue['selectingTargetDataGraphBehavior'] = settings['guiParameters']['selectingTargetDataGraphBehavior'];
			}
			if ('getDefaultPatientForm' in settings['guiParameters']) {
				newValue['get_default_patient_form'] = settings['guiParameters']['getDefaultPatientForm'];
			}
		}
		if (isFeet) {
			// TODO refactor it
			sensorParameters[this.sensorHeightParameter] = meterToFeet(sensorParameters[this.sensorHeightParameter]);
		}
		if (settings['socketInfo']) {
			if ('name' in settings['socketInfo']) {
				newValue['roomName'] = settings['socketInfo']['name'] || '';
			}
		}
		if (settings['sensorNetworkParameters']) {
			if ('globalSensorLocation' in settings['sensorNetworkParameters']) {
				newValue['globalSensorX'] = settings['sensorNetworkParameters']['globalSensorLocation'] ? settings['sensorNetworkParameters']['globalSensorLocation'][0] : 0;
				newValue['globalSensorY'] = settings['sensorNetworkParameters']['globalSensorLocation'] ? settings['sensorNetworkParameters']['globalSensorLocation'][1] : 0;
			}
			if ('globalSensorOrientation' in settings['sensorNetworkParameters']) {
				newValue['globalSensorOrientation'] = settings['sensorNetworkParameters']['globalSensorOrientation'] || 0;
			}
		}

		let params = ['alert_on_disconnections'];
		for (let i = 0; i < params.length; i++) {
			let t = await this.storageService.getItem(params[i]),
				v = t ? t[params[i]] : (params[i] === 'alert_on_disconnections');
			newValue[params[i]] = v;
		}

		newValue['__lastField4Subscribe'] = true;
		/**
		 * First update dynamic parameters and let value changes subscriptions runs.
		 */
		settingForm.patchValue({sensorParameters});
		/**
		 * Second update other parameters.
		 */
		settingForm.patchValue(newValue, {emitEvent: false});
	}


	saveSettingsForm(url, settingForm: FormGroup) {
		return this.getAllSettings(url).then(initialSettings => {
			let settings = this.prepareSettingsToSave(settingForm.getRawValue(), makeDeepCopy(initialSettings));
			this.saveAllSettings(url, settings);
			this.storageService.setItem('alert_on_disconnections', {
				alert_on_disconnections: settingForm.getRawValue()['alert_on_disconnections']
			});

			return this.getChangedParameters(url, settingForm, initialSettings);
		});
	}


	getChangedParameters(url, settingForm: FormGroup, initialSettings) {
		let settings = this.prepareSettingsToSave(settingForm.getRawValue(), makeDeepCopy(initialSettings)),
			changedParameters = getChangedParameters(makeDeepCopy(Object.assign({}, initialSettings['sensorParameters'])), makeDeepCopy(settings['sensorParameters']));
		/**
		 * We should send arena parameters to EVK when changing mount plane or sensor height
		 * So EVK can send us correct 'ProcessorCfg.Common.sensorOrientation.boardToWebGUITransMat' back
		 */
		if ('ProcessorCfg.Common.sensorOrientation.mountPlane' in changedParameters || this.sensorHeightParameter in changedParameters) {
			changedParameters[this.sensorHeightParameter] = settings['sensorParameters'][this.sensorHeightParameter];
			changedParameters['ProcessorCfg.MonitoredRoomDims'] = settings['sensorParameters']['ProcessorCfg.MonitoredRoomDims'];
		}
		return changedParameters;
	}


	prepareSettingsToSave(settings, initialSettings) {
		let isFeet = settings['isCoverageFeet'];

		let ret = {
			sensorParameters: Object.assign({}, initialSettings['sensorParameters'], {}),
			guiParameters: Object.assign({}, initialSettings['guiParameters'], {
				'isCoverageFeet': isFeet,
				'selectingTargetDataGraphBehavior': settings['selectingTargetDataGraphBehavior'],
				'getDefaultPatientForm': settings['get_default_patient_form']
			}),
			socketInfo: Object.assign({}, initialSettings['socketInfo'], {
				name: settings['roomName']
			}),
			sensorNetworkParameters: Object.assign({}, initialSettings['sensorNetworkParameters'], {
				globalSensorLocation: [+settings['globalSensorX'], +settings['globalSensorY']],
				globalSensorOrientation: +settings['globalSensorOrientation']
			})
		};

		Object.keys(settings.sensorParameters).forEach(parameter => {
			ret['sensorParameters'][parameter] = settings['sensorParameters'][parameter];
		});

		// TODO refactor it
		if (isFeet) {
			ret['sensorParameters'][this.sensorHeightParameter] = feetToMeter(ret['sensorParameters'][this.sensorHeightParameter]);
		}

		return ret;
	}


	extractSettingsFromMetadata(metadata) {
		var ret = {
			'FlowCfg.allow_savedData_override': null
		};

		if ('ExternalGUI' in metadata) {
			if (metadata['ExternalGUI']['FilterImage'] && 'numOfSd' in metadata['ExternalGUI']['FilterImage']) {
				ret['ProcessorCfg.ExternalGUI.FilterImage.numOfSd'] = metadata['ExternalGUI']['FilterImage']['numOfSd'];
			}
			if ('Layers' in metadata['ExternalGUI']) {
				ret['ProcessorCfg.ExternalGUI.Layers'] = metadata['ExternalGUI']['Layers'];
			}
		}
		if ('MonitoredRoomDims' in metadata) {
			ret['ProcessorCfg.MonitoredRoomDims'] = metadata['MonitoredRoomDims'];
		}
		if ('imgProcessing' in metadata) {
			ret['ProcessorCfg.imgProcessing.substractionMode'] = metadata['imgProcessing']['substractionMode'];
		}
		if (metadata['Common']['sensorOrientation']) {
			if ('mountPlane' in metadata['Common']['sensorOrientation']) {
				ret['ProcessorCfg.Common.sensorOrientation.mountPlane'] = metadata['Common']['sensorOrientation']['mountPlane'];
			}
			if ('transVec' in metadata['Common']['sensorOrientation']) {
				ret['ProcessorCfg.Common.sensorOrientation.transVec'] = metadata['Common']['sensorOrientation']['transVec'];
			}
		}
		if (metadata['TargetProperties']) {
			if ('MaxPersonsInArena' in metadata['TargetProperties']) {
				ret['ProcessorCfg.TargetProperties.MaxPersonsInArena'] = metadata['TargetProperties']['MaxPersonsInArena'];
			}
			if ('StandingMinHeight' in metadata['TargetProperties']) {
				ret['ProcessorCfg.TargetProperties.StandingMinHeight'] = metadata['TargetProperties']['StandingMinHeight'];
			}
			if ('SittingMinHeight' in metadata['TargetProperties']) {
				ret['ProcessorCfg.TargetProperties.SittingMinHeight'] = metadata['TargetProperties']['SittingMinHeight'];
			}
			if ('PersonRadius' in metadata['TargetProperties']) {
				ret['ProcessorCfg.TargetProperties.PersonRadius'] = metadata['TargetProperties']['PersonRadius'];
			}
		}

		return ret;
	}


	clearLocalSettings(url) {
		delete this.localSettings[url];
	}





	/**
	 * Save all settings, including sensor parameters and rendering settings
	 * @param url
	 * @param settings
	 */
	async saveAllSettings(url: string, _settings, waitForFirebase = false): Promise<any> {
		let socket = this.inject.get(SensorsService).getSensorSocket(url),
			settings = makeDeepCopy(_settings);

		if (!settings['guiParameters']) {
			let currentSettings = await this.getAllSettings(url, false);
			if (currentSettings && !currentSettings['guiParameters']) {
				settings['guiParameters'] = this.getDefaultSettings()['guiParameters'];
			}
		}
		if (!this.localSettings[url]) {
			this.localSettings[url] = {};
		}
		let merged = merge(this.localSettings[url], settings, overwriteOptions);
		if (merged && merged['sensorParameters'] && 'ProcessorCfg.ExternalGUI.Zones.interestArea' in merged['sensorParameters'] && (!merged['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'] ||
			!merged['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'].length)) {
			delete merged['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.interestArea'];
			delete merged['sensorParameters']['ProcessorCfg.ExternalGUI.Zones.names'];
		}
		Object.values(merged).forEach((parameters: any) => {
			if (typeof parameters === 'object') {
				Object.keys(parameters).forEach(parameter => {
					if (parameters[parameter] === VALUE_TO_DELETE) {
						delete parameters[parameter];
					}
				});
			}
		});
		this.localSettings[url] = merged;
		if (settings['sensorParameters'] && socket) {
			getNonCacheableSensorParameters(socket).forEach(parameter => {
				delete settings['sensorParameters'][parameter];
			});
		}
		return this.storageService.setItem(`${environment.parametersNamePrefix}${url}`, settings, waitForFirebase);
	}


	prepareZonesToSocket(parameters, roomName) {
		let areas = parameters['ProcessorCfg.ExternalGUI.Zones.interestArea'];
		if (areas && areas.length) {
			let names = parameters['ProcessorCfg.ExternalGUI.Zones.names'] ? parameters['ProcessorCfg.ExternalGUI.Zones.names'] : [];
			return areas.map((zone, i) => {
				return {
					ZoneName: names[i] || '',
					RoomName: roomName,
					zoneDims: zone
				};
			});
		}
		return [];
	}


	prepareLocalZonesToSocket(zones, roomName) {
		return zones.map((zone, i) => {
			return {
				ZoneName: zone.name,
				RoomName: roomName,
				zoneDims: [
					zone.coordinates[0][0],
					zone.coordinates[1][0],
					zone.coordinates[0][1],
					zone.coordinates[2][1],
					'-inf',
					'inf'
				]
			};
		});
	}

	prepareLocalZonesAsLocations(zones, globalRoomHeight, floorId): Array<RetailGeoFence> {
		return zones.map((zone, i) => {
			return {
				id: zone.id,
				location_name: zone.name,
				location_type: zone.locationType,
				parent_floor: floorId,
				geometry: [...zone.coordinates, zone.coordinates[0]]
			} as RetailGeoFence;
		});
	}


	generateLocalZonesFromLocations(locations: Array<RetailGeoFence>, roomDims) {
		return locations.map((location, i) => {
			return {
				id: location.id,
				name: location.location_name,
				locationType: location.location_type,
				type: 0,
				svg: null,
				x: 0,
				y: 0,
				width: 0,
				height: 0,
				coordinates: location.geometry
			};
		});
	}


	generateLocalZonesFromStored(parameters) {
		let areas = parameters['ProcessorCfg.ExternalGUI.Zones.interestArea'];
		if (areas && areas.length) {
			let zones: Array<any> = [],
				names = parameters['ProcessorCfg.ExternalGUI.Zones.names'] ? parameters['ProcessorCfg.ExternalGUI.Zones.names'] : [];
			if (!(areas[0] instanceof Array)) {
				areas = [areas];
			}
			areas.forEach((area, i) => {
				zones.push({
					id: i,
					name: names.length ? names[i] : '',
					type: 0,
					svg: null,
					x: 0,
					y: 0,
					width: 0,
					height: 0,
					coordinates: [
						[area[0], area[2]],
						[area[1], area[2]],
						[area[0], area[3]],
						[area[1], area[3]]
					]
				});
			});

			return zones;
		}
		return [];
	}


	checkUploadedParametersAreValid(parameters, socket: SensorSocket) {
		let ret = true,
			modalService = this.inject.get(ModalService);

		if (!parameters || !('sensorParameters' in parameters)) {
			ret = false;
			modalService.showError('Settings could not be loaded.');
		}

		let nonEditableParameters = Object.keys(parameters['sensorParameters']).filter(parameter => !socket.isEditableParameter(parameter));

		if (nonEditableParameters.length) {
			ret = false;
			modalService.showError(`These parameters are not editable:\n${nonEditableParameters.join(', ')}`);
		}
		if (ret && 'ProcessorCfg.Common.sensorOrientation.mountPlane' in parameters['sensorParameters']) {
			ret = ['xz', 'xy'].includes(parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane']);
			if (!ret) {
				modalService.showError(`Settings could not be loaded.\nCheck that ProcessorCfg.Common.sensorOrientation.mountPlane is equal to 'xz' or 'xy'`);
			}

			if (ret) {
				// TODO should take these rules from configuration
				switch (parameters['sensorParameters']['ProcessorCfg.Common.sensorOrientation.mountPlane']) {
					case SensorMountPlane.CEILING:
						ret = parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'][5] <= parameters['sensorParameters'][this.sensorHeightParameter];
						if (!ret) {
							this.modalService.showError('SENSOR_HEIGHT_CAN_NOT_BE_LOWER_THAN_Z_MAX');
						}
						break;
					case SensorMountPlane.BACKWALL:
						ret = parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'][2] >= 0;
						if (!ret) {
							this.modalService.showError('SENSOR_Y_COULD_NOT_BE_LESS_THAN_ZERO');
						}
						break;
				}
			}
		}
		if (ret && this.sensorHeightParameter in parameters['sensorParameters']) {
			if (parameters['sensorParameters'][this.sensorHeightParameter] < 0) {
				ret = false;
				this.modalService.showError(`Settings could not be loaded.\nCheck that sensor height is more than 0.`);
			}
		}
		/*if (ret) {
			ret = parameters['sensorParameters']['ProcessorCfg.TargetProperties.SittingMinHeight'] <= parameters['sensorParameters']['ProcessorCfg.TargetProperties.StandingMinHeight'];
			if (!ret) {
				modalService.showError(`Settings could not be loaded.\nCheck that ProcessorCfg.TargetProperties.SittingMinHeight is less than or equal to ProcessorCfg.TargetProperties.StandingMinHeight.`);
			}
		}
		if (ret) {
			ret = parameters['sensorParameters']['ProcessorCfg.TargetProperties.StandingMinHeight'] <= parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'][5];
			if (!ret) {
				modalService.showError(`Settings could not be loaded.\nCheck that ProcessorCfg.TargetProperties.StandingMinHeight is less than or equal to ProcessorCfg.MonitoredRoomDims[5].`);
			}
		}
		if (ret) {
			ret = parameters['sensorParameters']['ProcessorCfg.MonitoredRoomDims'][4] <= parameters['sensorParameters']['ProcessorCfg.TargetProperties.SittingMinHeight'];
			if (!ret) {
				modalService.showError(`Settings could not be loaded.\nCheck that ProcessorCfg.TargetProperties.SittingMinHeight is more than or equal to ProcessorCfg.MonitoredRoomDims[4].`);
			}
		}*/

		return ret;
	}

	private _saveSocketInfo(url, info) {
		return new Promise((resolve, reject) => {
			let settings = {};
			settings['socketInfo'] = info;
			this.saveAllSettings(url, settings).then(async (successfully) => {
				if (successfully) {
					resolve((await this.getAllSettings(url))['socketInfo']);
				} else {
					reject();
				}
			});
		});
	}


	private _saveSensorParameters(url, parameters): Promise<object> {
		return new Promise((resolve, reject) => {
			let settings = {};
			settings['sensorParameters'] = makeDeepCopy(parameters);
			this.markSettingsAsUnsaved(settings);
			this.saveAllSettings(url, settings).then(async (successfully) => {
				if (successfully) {
					resolve((await this.getAllSettings(url))['sensorParameters']);
				} else {
					reject();
				}
			});
		});
	}


	private _saveUIParameters(url, parameters) {
		return new Promise((resolve, reject) => {
			let settings = {};
			settings['guiParameters'] = parameters;
			this.markSettingsAsUnsaved(settings);
			this.saveAllSettings(url, settings).then(async (successfully) => {
				if (successfully) {
					resolve((await this.getAllSettings(url))['guiParameters']);
				} else {
					reject();
				}
			});
		});
	}


	private markSettingsAsUnsaved(settings) {
		if (!('socketInfo' in settings)) {
			settings['socketInfo'] = {};
		}
		settings['socketInfo']['settingsSaved'] = false;
	}

	private getParameters(url) {
		if (this.localSettings[url]) {
			return Promise.resolve(makeDeepCopy(this.localSettings[url]));
		}
		return this.storageService.getItem(`${environment.parametersNamePrefix}${url}`).then(parameters => {
			this.prepareUIParameters(parameters);
			if (parameters && !this.localSettings[url]) {
				this.localSettings[url] = makeDeepCopy(parameters);
			}
			return parameters;
		});
	}

	private prepareUIParameters(parameters) {
		if (parameters && parameters['sensorParameters'] && parameters['guiParameters']) {
			if (!parameters['sensorParameters']['ProcessorCfg.ExternalGUI.enable_click_on_target_for_breathing_option']) {
				parameters['guiParameters']['selectingTargetDataGraphBehavior'] = SelectingTargetDataGraphBehavior.Automatically;
			}
		}
	}
}
