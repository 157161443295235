<canvas #rawCanvas [ngStyle]="{
			'background': 'transparent',
			'boxSizing': 'content-box',
			'marginLeft.px': xScalePadding * clientWidth / 100,
			'marginTop.px': yScalePadding * clientHeight / 100,
			'imageRendering': 'pixelated',
			'visibility': isRaw ? 'visible' : 'hidden',
			'width.%': 100,
			'height.%': 100
		}">
</canvas>
<canvas #canvas
		[attr.width]="clientWidth * xScaleCoefficient + 'px'"
		[attr.height]="clientHeight * yScaleCoefficient + 'px'"
		[ngStyle]="{
			'background': 'transparent',
			'boxSizing': 'content-box',
			'visibility': isRaw ? 'hidden' : 'visible',
			'marginLeft.px': xScalePadding * clientWidth / 100,
			'marginTop.px': yScalePadding * clientHeight / 100
		}">
</canvas>
