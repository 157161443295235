import {Connection, ConnectionStatus} from './connection';
import {reshape} from 'mathjs';

declare var TextDecoder: any;

/**
 * Base class for sockets.
 */
export class Socket extends Connection {
	lastUsedOutputs: {
		[key: string]: Array<string>
	} = {};

	protected socket: WebSocket | null;
	protected unknownParametersCountSymbol = Symbol('unknownParametersCount');

	private decoder = new TextDecoder('utf-8');
	private typedType = {
		0: Int8Array,
		1: Uint8Array,
		2: Int16Array,
		3: Uint16Array,
		4: Int32Array,
		5: Uint32Array,
		6: Float32Array,
		7: Float64Array
	};
	private typedMethod = {
		0: DataView.prototype.getInt8,
		1: DataView.prototype.getUint8,
		2: DataView.prototype.getInt16,
		3: DataView.prototype.getUint16,
		4: DataView.prototype.getInt32,
		5: DataView.prototype.getUint32,
		6: DataView.prototype.getFloat32,
		7: DataView.prototype.getFloat64,
	};

	isConnected(): boolean {
		return !!(this.socket && this.socket.readyState === WebSocket.OPEN);
	}

	isConnecting(): boolean {
		return !!(this.socket && this.socket.readyState === WebSocket.CONNECTING);
	}

	isRunned() {
		return this.isConnected();
	}

	connect() {
		if (this.canDisconnect()) {
			return Promise.resolve();
		}

		this.disconnectReason = null;
		this._lastErrorMessage = null;
		this.connectionStatus = ConnectionStatus.CONNECTING;

		try {
			this.socket = new WebSocket(`ws://${this.url}`);
		} catch (e) {
			this._lastErrorMessage = e.message;
			console.error(e);
			return Promise.reject(e);
		}
		this.socket.binaryType = 'arraybuffer';

		this.socket.onopen = e => {
			this.connectionStatus = ConnectionStatus.CONNECTED;
			this.open.emit(e);
			this.registerSocketEvent();
			if (this.connectResolve) {
				this.connectResolve(e);
				this.connectResolve = null;
				this.connectReject = null;
			}
		};

		// Will only be called becouse of network problem, disconnect from socket side, timeout
		this.socket.onclose = e => {
			console.log(e, e.code);
			switch (e.code) {
				case 1000:
					// Normal close
					break;
				case 1006:
					// Disconnected while connecting
					// Closed by websocket side
					if (this.connectionStatus === ConnectionStatus.CONNECTING) {
						// timeout
						this.disconnectReason = 'timeout';
					} else {
						// connection lost
						this._lastErrorMessage = `Socket Error - A connection was closed abnormally`;
						this.disconnectReason = 'socketerror';
					}
					break;
				default:
					this._lastErrorMessage = `Socket Error - Connectivity issue occurred (${e.code})`;
					this.disconnectReason = 'socketerror';
					this.connectionStatus = ConnectionStatus.ERROR; // first fire the ERROR status and then the DISCONNECTED STATUS
					break;
			}


			this.connectionStatus = ConnectionStatus.DISCONNECTED;
			this.close.emit(this.disconnectReason);
			this.subscriptions.forEach(subscription => {
				subscription.unsubscribe();
			});
			this.socket = null;
			if (this.connectReject) {
				this.connectReject();
				this.connectReject = null;
				this.connectResolve = null;
			}
		};

		return new Promise((resolve, reject) => {
			this.connectResolve = resolve;
			this.connectReject = reject;
		});
	}

	disconnect() {
		this.connectionStatus = ConnectionStatus.DISCONNECTED;
		if (this.socket) {
			this.socket.onclose = null;
			this.socket.close();
			this.socket = null;
			this.close.emit(this.disconnectReason);
		}
		if (this.connectReject) {
			this.connectReject(true);
			this.connectReject = null;
			this.connectResolve = null;
		}
	}

	sendCommand(commandId, payload = {}) {
		return this.sendRawData({
			'Type': 'COMMAND',
			'ID': commandId,
			'Payload': payload
		});
	}

	sendRawData(data): boolean {
		// If the data can't be sent (for example, because it needs to be buffered but the buffer is full), the socket is closed automatically.
		if (this.socket) {
			try {
				this.socket.send(JSON.stringify(data));
				return true;
			} catch (e) {
				console.error('sendRawData: Error ' + e);
				return false;
			}
		} else {
			return false;
		}
	}

	protected onMessage(json) {
		if (this.promiseCallbacks[json.ID] && this.promiseCallbacks[json.ID].length) {
			let resolve;
			if (this.promiseCallbacks[json.ID].find(c => c.payload)) {
				let responsePayloadKeys = Object.keys(json.Payload),
					/**
					 * Found resolve promise callback by:
					 * 1. The same number of keys in payloads -
					 *    number of keys in response + number of unknown keys (they were removed in sensor-socket:onMessage) === number of keys in request
					 * 2. All keys in response and request payload are the same
					 */
					callback = this.promiseCallbacks[json.ID].find(c => {
						let requestPayloadKeys = Object.keys(c.payload);
						return c.payload && requestPayloadKeys.length === (responsePayloadKeys.length +
							(json[this.unknownParametersCountSymbol] || 0)) &&
							requestPayloadKeys.every(key => {
								return responsePayloadKeys.includes(key) || typeof json.Payload[key] === 'undefined';
							});
					});

				if (callback) {
					resolve = callback.resolve;
					this.promiseCallbacks[json.ID].splice(this.promiseCallbacks[json.ID].indexOf(callback), 1);
					resolve(json.Payload);
				}
			} else {
				resolve = this.promiseCallbacks[json.ID].shift()?.resolve;
				resolve(json.Payload);
			}
		}
	}

	protected registerSocketEvent() {
		if (this.socket) {
			this.socket.onmessage = (e: MessageEvent) => {
				if (e.data instanceof ArrayBuffer) {
					this.onMessage(this.buffer(e.data));
				} else {
					this.onMessage(JSON.parse(e.data));
				}
			};
		} else {
			console.warn('WebSocket object is absent');
		}
	}

	protected buffer(buffer: ArrayBuffer) {
		const headerSize = new Int32Array(buffer, 4, 1)[0];
		const headerStr = this.decoder.decode(new Uint8Array(buffer, 8, headerSize));
		const [msgID, keysStr] = headerStr.split(String.fromCharCode(30));
		const keys = keysStr.split(String.fromCharCode(31));
		const msg = {ID: msgID, Payload: {}};
		let seek = 8 + headerSize;
		keys.forEach(key => {
			seek += Int32Array.BYTES_PER_ELEMENT;
			const dType = new DataView(buffer, seek).getInt32(0, true);
			seek += Int32Array.BYTES_PER_ELEMENT;
			const nDims = new DataView(buffer, seek).getInt32(0, true);
			seek += Int32Array.BYTES_PER_ELEMENT;
			let dims: any = [];
			for (let i = 0; i < nDims; i++) {
				dims.push(
					new DataView(buffer, seek + i * Int32Array.BYTES_PER_ELEMENT).getInt32(0, true)
				);
			}
			seek += Int32Array.BYTES_PER_ELEMENT * nDims;
			const numElem = dims.reduce((acc, i) => (acc = acc * i), 1);
			if ((this.lastUsedOutputs['binary_outputs'] || []).includes(key)) {
				let data: any = [];
				for (let i = 0; i < numElem; i++) {
					let dataView = new DataView(buffer, seek + i * this.typedType[dType].BYTES_PER_ELEMENT);
					data.push(
						Reflect.apply(this.typedMethod[dType], dataView, [0, true])
					);
				}
				msg.Payload[key] = reshape(data, dims);
			}
			seek += numElem * this.typedType[dType].BYTES_PER_ELEMENT;
		});
		return msg;
	}

	protected isUnknownParam(parameter) {
		return typeof parameter === 'string' && parameter.toLowerCase() === 'unknown param';
	}
}
